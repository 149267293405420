import light from "src/styles/themes/light/lightMuiTheme";
import dark from "src/styles/themes/dark/darkMuiTheme";
import orange_light from "src/styles/themes/orangeLight/lightMuiTheme";
import orange_dark from "src/styles/themes/orangeDark/darkMuiTheme";
import green_light from "src/styles/themes/greenLight/lightMuiTheme";
import green_dark from "src/styles/themes/greenDark/darkMuiTheme";
import purple_light from "src/styles/themes/purpleLight/lightMuiTheme";
import purple_dark from "src/styles/themes/purpleDark/darkMuiTheme";

const themes = {
  light,
  dark,
  orange_light,
  orange_dark,
  green_light,
  green_dark,
  purple_light,
  purple_dark,
};

export default function getTheme(theme) {
  return themes[theme];
}
