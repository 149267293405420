import GAxios from "src/util/GAxios";
import {CHAT_API} from "./index";

/**
 * Grant a new chat token
 */
export const getChatToken = async () => {
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/${CHAT_API}/token`,
    ignoreErrors: true,
  };

  let res = null;
  await GAxios(config, (response) => {
    if (response?.data?.result) {
      res = response.data.result;
    }
  });

  return res;
};
