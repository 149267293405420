import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {SearchApi} from "../api";
import {trim, uniqBy, isNumber} from "lodash-es";
import {
  MAX_LIST_ITEMS,
  NEW_LOCAL_STORAGE_RECENT_SEARCH,
  SUGGESTION_MODES,
  SUGGESTION_TYPES,
  SEARCH_TYPES,
} from "../_constant";
import {v4 as uuid} from "uuid";
import {transformSearchChoices} from "../api/transformSearchChoices";

const NS = "search";

let initRecentSearchList;

try {
  initRecentSearchList =
    JSON.parse(localStorage.getItem(NEW_LOCAL_STORAGE_RECENT_SEARCH)) || [];
} catch (error) {
  initRecentSearchList = [];
}
const initialState = {
  hashtagList: [],
  userList: [],
  suggestion: [],
  recentSearchList: initRecentSearchList,
  selectedIndex: -1,
  currentSuggestionMode: SUGGESTION_MODES.RECENT,
  searchLoading: "",
  recoSearchLoading: "",
  selectedSuggestion: null,
  popList: [],
  popListStatus: "",
  searchQuery: null,
  cursors: {
    [SEARCH_TYPES.TOP]: null,
    [SEARCH_TYPES.LATEST]: null,
  },
};

export const fetchSearchChoices = createAsyncThunk(
  "search/fetchSearchChoices",
  SearchApi.fetchSearchChoices,
);
export const fetchRecommendSearch = createAsyncThunk(
  "search/fetchRecommendSearch",
  SearchApi.fetchRecommendSearch,
);
export const fetchPopSearch = createAsyncThunk(
  "search/fetchPopSearch",
  SearchApi.fetchPopSearch,
);

export const searchSlice = createSlice({
  name: NS,
  initialState,
  reducers: {
    resetSearch: (state) => {
      state.hashtagList = initialState.hashtagList;
      state.userList = initialState.userList;
      state.suggestion = initialState.suggestion;
      state.searchLoading = initialState.searchLoading;
      state.selectedIndex = initialState.selectedIndex;
      state.currentSuggestionMode = initialState.currentSuggestionMode;
      state.selectedSuggestion = initialState.selectedSuggestion;
    },
    setSelectedIndex: (state, {payload}) => {
      let list =
        state.currentSuggestionMode === SUGGESTION_MODES.RECENT
          ? state.recentSearchList
          : [...state.suggestion, ...state.hashtagList, ...state.userList];

      if (
        state.currentSuggestionMode === SUGGESTION_MODES.RESULT &&
        list?.length
      )
        list.push({
          id: list?.length,
          type: SUGGESTION_TYPES.ALL,
        });

      const maxIndex = list?.length - 1;

      if (maxIndex < 0) {
        state.selectedIndex = initialState.selectedIndex;
      } else {
        const nextIndex = isNumber(payload?.id)
          ? payload.id
          : state.selectedIndex + payload;
        if (nextIndex < 0) state.selectedIndex = maxIndex;
        if (nextIndex > maxIndex) state.selectedIndex = 0;
        if (nextIndex >= 0 && nextIndex <= maxIndex)
          state.selectedIndex = nextIndex;
      }
      const selectedItem = list?.[state.selectedIndex] || null;

      if (selectedItem) {
        state.selectedSuggestion = {
          type: selectedItem?.type,
          phrase: selectedItem?.phrase,
        };
      } else {
        state.selectedSuggestion = initialState.selectedSuggestion;
        state.selectedIndex = initialState.selectedIndex;
      }
    },
    resetSelectedIndex: (state) => {
      state.selectedIndex = initialState.selectedIndex;
      state.selectedSuggestion = initialState.selectedSuggestion;
    },
    setRecentSearchList: (state, {payload}) => {
      const list = [
        {
          phrase: trim(payload),
          uuid: uuid(),
          date: new Date().toISOString(),
          type: SUGGESTION_TYPES.RECENT,
        },
        ...state.recentSearchList,
      ]?.filter((item) => !!item);

      const uniqList = (
        uniqBy(list, "phrase")?.slice(0, MAX_LIST_ITEMS) || []
      )?.map((item, index) => ({
        ...item,
        id: index,
      }));

      state.recentSearchList = uniqList;

      localStorage.setItem(
        NEW_LOCAL_STORAGE_RECENT_SEARCH,
        JSON.stringify(uniqList),
      );
    },
    resetRecentSearchList: (state) => {
      state.recentSearchList = [];
      localStorage.setItem(NEW_LOCAL_STORAGE_RECENT_SEARCH, null);
      state.selectedIndex = initialState.selectedIndex;
      state.selectedSuggestion = initialState.selectedSuggestion;
    },
    updateSearchQuery: (state, {payload}) => {
      state.searchQuery = payload;
    },
    updateCursors: (state, {payload}) => {
      state.cursors = payload;
    },
  },
  extraReducers: (builder) => {
    // Doc: https://redux-toolkit.js.org/usage/usage-with-typescript#type-safety-with-extrareducers
    builder.addCase(fetchSearchChoices.pending, (state, {payload}) => {
      state.currentSuggestionMode = SUGGESTION_MODES.RESULT;
      state.selectedIndex = initialState.selectedIndex;
      state.searchLoading = "pending";
    });
    builder.addCase(fetchSearchChoices.fulfilled, (state, {payload, meta}) => {
      state.searchLoading = "fulfilled";
      const {suggestion, hashtagList, userList} = transformSearchChoices(
        payload?.response,
      );
      state.suggestion = suggestion;
      state.hashtagList = hashtagList;
      state.userList = userList;
    });
    builder.addCase(fetchSearchChoices.rejected, (state, {payload}) => {
      state.searchLoading = "rejected";
      state.hashtagList = initialState.hashtagList;
      state.userList = initialState.userList;
      state.suggestion = initialState.suggestion;
    });
    builder.addCase(fetchPopSearch.pending, (state, {payload}) => {
      state.popListStatus = "pending";
    });
    builder.addCase(fetchPopSearch.fulfilled, (state, {payload}) => {
      state.popListStatus = "fulfilled";
      state.popList = payload?.slice(0, 5);
    });
    builder.addCase(fetchPopSearch.rejected, (state, {payload}) => {
      state.popListStatus = "rejected";
    });
  },
});

export const {
  resetSearch,
  setSelectedIndex,
  resetSelectedIndex,
  setRecentSearchList,
  resetRecentSearchList,
  updateSearchQuery,
  updateCursors,
} = searchSlice.actions;
