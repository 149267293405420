import {getLinkByChannelId} from "./getLinkByChannelId";
import {getLinkExistByInviteCode} from "./getLinkExistByInviteCode";
import {getLinkInfoByInviteCode} from "./getLinkInfoByInviteCode";
import {postNewLinkWithChannelId} from "./postNewLinkWithChannelId";
import {postLinkJoinByInviteCode} from "./postLinkJoinByInviteCode";

export const InviteLinkApi = {
  getLinkByChannelId,
  getLinkExistByInviteCode,
  getLinkInfoByInviteCode,
  postNewLinkWithChannelId,
  postLinkJoinByInviteCode,
};
