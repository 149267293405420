import {socialConstants, userConstants} from "../_constants";
import GAxios from "src/util/GAxios";
import {t} from "src/i18n/utils";
import {TimeUtil} from "../../../../util/TimeUtil";
import {showToast} from "../../../../util/showToast";
import {setMultiAccount} from "src/util/userUtil";
import {signUpEventTrack} from "src/util/trackEvent";

export const socialSignIn = async (params) => {
  if (!params) return;

  const {code, state, id_token, user, pvrinfo, authinfo} = params;
  const pvr_key = (pvrinfo?.pvr_key || "").toLowerCase();
  const auth0 = localStorage.getItem(socialConstants.LOCAL_STORAGE_AUTH0_INFO);
  const isIdToken = auth0 === socialConstants.LOCAL_STORAGE_AUTH0_LOGIN;
  let data = null;
  let url = null;
  if (isIdToken) {
    url = process.env.REACT_APP_API_URL + "/u/user/v2/idtokenlogin";
    data = {
      content: {
        idtoken: authinfo.a0_token,
      },
    };
    sessionStorage.removeItem("clickLoginSocial");
  } else {
    url = process.env.REACT_APP_API_URL + "/v1/s/social/sign_in";
    data = {
      content: {
        ...params,
      },
    };
    signUpEventTrack(pvr_key, 1);
  }

  const config = {
    method: "post",
    url,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  };

  let loginData = {
    pt: "",
    payload: null,
    authenticated: false,
    userinfo: null,
    err: null,
  };
  const callback = (err, res) => {
    if (err) {
      let errorCode = err?.code;
      let untilTime = TimeUtil.ErrorMsgUntilTime(err?.args[0]);
      loginData.err = err;
      let table = {
        E_USER_BANNED: t("getter_fe.auth.errors.E_USER_BANNED2", {
          time: untilTime,
        }),
        E_USER_BANNED_LOGIN: t("getter_fe.auth.errors.suspendedUserLogin2", {
          time: untilTime,
        }),
        E_USER_SUSPENDED: t("getter_fe.auth.errors.E_USER_SUSPENDED"),
      };
      for (const key in table) {
        if (errorCode === key) {
          let toastMessage = table[key];
          showToast("warning", toastMessage, {msgShort: true, autoClose: 7000});
          return;
        }
      }
    } else {
      loginData.authenticated = !err;
      loginData.userinfo = res;

      if (loginData.authenticated && loginData.userinfo) {
        const sessionInfo = {
          authenticated: loginData.authenticated,
          userinfo: loginData.userinfo,
        };
        setMultiAccount(sessionInfo);
      }
    }
  };
  const processResult = (result) => {
    let err = null;
    let {pt, payload} = result;

    if (result.success) {
      loginData.pt = socialConstants.SIGN_IN_PAYLOAD_TYPE.SI_NEW;
      loginData.payload = {};
      loginData.payload.idtoken = authinfo.a0_token;
    } else {
      if (isIdToken) {
        payload = result;
        pt = socialConstants.SIGN_IN_PAYLOAD_TYPE.SI_OK;
      }
      loginData.pt = pt;
      loginData.payload = payload;

      let authenticated = payload["token"];
      let loadedUserInfo = payload["user"];

      if (authenticated && loadedUserInfo) {
        loadedUserInfo.token = authenticated;
      } else if (authenticated && !loadedUserInfo) {
        // pass
      } else {
        err = new Error("Auth failed");
      }

      callback(err, loadedUserInfo);
    }
  };

  await GAxios(
    config,
    (response) => {
      processResult(response?.data?.result);
    },
    (err) => {
      callback(err?.response?.data?.error);
    },
  );

  return loginData;
};
