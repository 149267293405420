import {CustomColors} from "../../../types/theme";

export const darkThemePalette_v2: CustomColors = {
  accent: "#8447FF",
  accentHover: "#532CA2",
  accentLink: "#8447FF",
  element: {
    [20]: "#6F6C6C",
    [40]: "#9C9797",
    [60]: "#AFACAC",
    [80]: "#E7E8EA",
    [100]: "#FFFFFF",
  },
  base: {
    [0]: "#000000",
    [20]: "#121212",
    [40]: "#191919",
    [60]: "#242323",
    [80]: "#373636",
  },
  bg: "#0F0F0F",
  modalWindowBg: "#242323",
  bannerOverlay: "#0F0F0FCC",
};
