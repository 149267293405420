import GAxios from "src/util/GAxios";

export const getMutedUsers = async ({userId, max}) => {
  if (!userId) {
    return;
  }
  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/u/user/${userId}/mutes/?offset=0&max=${max}&incl=userstats|userinfo`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    let res;
    await GAxios(config, (response) => {
      res = response.data?.result?.data?.list;
    });
    return res;
  } catch (err) {
    return null;
  }
};
