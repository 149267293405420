import {timeFormatter} from "src/util/time";

export const formatLongNumber = (num, negative = false) => {
  if (!num || (!negative && num <= 0)) return 0;
  let val = Math.abs(parseInt(num));
  if (val < 1000) return val;
  // thousands, millions, billions etc..
  let s = ["", "K", "M", "B", "T"];
  let sNum = Math.floor(String(val).length / 3);
  let sVal = parseFloat(sNum !== 0 ? val / Math.pow(1000, sNum) : val);

  if (sNum > 1 && sNum < 5 && sVal < 1) {
    sNum = sNum - 1;
    sVal = sVal * 1000;
  } else if (sNum >= 5) {
    sVal = sVal * Math.pow(1000, sNum - 4);
    sNum = 4;
  }
  if (sVal % 1 !== 0) {
    sVal = String(Math.floor(sVal * 10) / 10);
  }

  // handle long decimal values
  if (String(sVal).split(".")[1]?.length > 2) {
    sVal = Math.floor(Number(sVal) * 10) / 10;
  }

  return (negative ? "-" : "") + sVal + s[sNum];
};

export const positiveSignFormatLongNumber = (num, positive = false) => {
  if (!num) return 0;
  let val = Math.abs(parseInt(num));
  if (val < 1000) return (positive ? "+" : "-") + val;
  // thousands, millions, billions etc..
  let s = ["", "K", "M", "B", "T"];
  let sNum = Math.floor(String(val).length / 3);
  let sVal = parseFloat(sNum !== 0 ? val / Math.pow(1000, sNum) : val);

  if (sNum > 1 && sNum < 5 && sVal < 1) {
    sNum = sNum - 1;
    sVal = sVal * 1000;
  } else if (sNum >= 5) {
    sVal = sVal * Math.pow(1000, sNum - 4);
    sNum = 4;
  }
  if (sVal % 1 !== 0) {
    sVal = String(Math.floor(sVal * 10) / 10);
  }

  // handle long decimal values
  if (String(sVal).split(".")[1]?.length > 2) {
    sVal = Math.floor(Number(sVal) * 10) / 10;
  }

  return (positive ? "+" : "-") + sVal + s[sNum];
};

export const shortNum = (num, negative = false) => {
  // Set default sigfigs to 1
  // sigfigs_opt = typeof sigfigs_opt === "undefined" ? 1 : sigfigs_opt;
  if (!negative && num <= 0) return 0;
  // Only assigns sig figs and suffixes for numbers > 1
  num = Math.abs(num);
  if (num < 10000) return num;
  // if (num <= 1) return num.toPrecision(sigfigs_opt);
  // Calculate for numbers > 1
  let power10 = Math.floor((Math.log(num) / Math.LN10) * 1e6) / 1e6;
  // let power10ceiling = Math.floor(power10) + 1;
  // 0 = '', 1 = 'K', 2 = 'M', 3 = 'B', 4 = 'T'
  let SUFFIXES = ["", "K", "M", "B", "T"];
  // 100: power10 = 2, suffixNum = 0, suffix = ''
  // 1000: power10 = 3, suffixNum = 1, suffix = 'K'
  let suffixNum = Math.floor(power10 / 3);
  let suffix = SUFFIXES[suffixNum];
  // Would be 1 for '', 1000 for 'K', 1000000 for 'M', etc.
  let suffixPower10 = Math.pow(10, suffixNum * 3);

  let base = num / suffixPower10;
  let baseRound = Math.floor(base * 10) / 10;

  return (negative ? "-" : "") + baseRound + suffix;
};

export const formatBalance = (num) => {
  if (num <= 0 || !num) return 0;
  return Number(num).toLocaleString("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
};

/**
 *
 * @param {string} result
 * @returns {string}
 */
export const secondsToHms = (value) => {
  const sec = parseInt(value, 10);
  return timeFormatter(sec);
};

export const toNumber = (value, defaultVal = null) => {
  if (value === null || value === undefined) {
    return defaultVal;
  }
  if (typeof value === "number") {
    return value;
  }
  try {
    if (typeof value === "string") {
      value = Number(value);
    } else {
      value = Number(String(value));
    } // justin case
    return typeof value === "number" ? value : defaultVal;
  } catch (e) {
    return defaultVal;
  }
};

//the username have changed xx times within 30 days.
export const getCount = (usernameHistoryArray) => {
  if (
    !usernameHistoryArray ||
    (usernameHistoryArray && usernameHistoryArray.length === 0)
  )
    return 0;
  let changeUsernameArr = usernameHistoryArray.filter((username) => {
    const daysLater = username.retired + 30 * 24 * 60 * 60 * 1000;
    const now = Date.now();
    return daysLater > now;
  });
  return changeUsernameArr.length;
};

export const hashCode = (str) =>
  str.split("").reduce((s, c) => (Math.imul(31, s) + c.charCodeAt(0)) | 0, 0);

export const _color_0 = "rgba(242, 5, 5, 1.0)";
export const _color_1 = "rgba(236, 48, 69, 1.0)";
export const _color_2 = "rgba(241, 93, 109, 1.0)";
export const _color_3 = "rgba(245, 152, 162, 1.0)";
export const _color_4 = "rgba(173, 27, 120, 1.0)";
export const _color_5 = "rgba(234, 44, 165, 1.0)";
export const _color_6 = "rgba(225, 107, 182, 1.0)";
export const _color_7 = "rgba(152, 100, 133, 1.0)";
export const _color_8 = "rgba(201, 153, 183, 1.0)";
export const _color_9 = "rgba(122, 103, 64, 1.0)";
export const _color_10 = "rgba(164, 148, 116, 1.0)";
export const _color_11 = "rgba(192, 179, 152, 1.0)";
export const _color_12 = "rgba(255, 77, 0, 1.0)";
export const _color_13 = "rgba(164, 69, 29, 1.0)";
export const _color_14 = "rgba(185, 86, 43, 1.0)";
export const _color_15 = "rgba(235, 114, 63, 1.0)";
export const _color_16 = "rgba(236, 147, 109, 1.0)";
export const _color_17 = "rgba(189, 137, 32, 1.0)";
export const _color_18 = "rgba(226, 231, 20, 1.0)";
export const _color_19 = "rgba(253, 188, 56, 1.0)";
export const _color_20 = "rgba(254, 209, 121, 1.0)";
export const _color_21 = "rgba(10, 97, 41, 1.0)";
export const _color_22 = "rgba(11, 138, 56, 1.0)";
export const _color_23 = "rgba(165, 180, 55, 1.0)";
export const _color_24 = "rgba(36, 213, 99, 1.0)";
export const _color_25 = "rgba(0, 119, 109, 1.0)";
export const _color_26 = "rgba(2, 165, 151, 1.0)";
export const _color_27 = "rgba(16, 204, 158, 1.0)";
export const _color_28 = "rgba(57, 194, 203, 1.0)";
export const _color_29 = "rgba(25, 88, 211, 1.0)";
export const _color_30 = "rgba(79, 129, 227, 1.0)";
export const _color_31 = "rgba(127, 166, 241, 1.0)";
export const _color_32 = "rgba(59, 97, 114, 1.0)";
export const _color_33 = "rgba(8, 73, 103, 1.0)";
export const _color_34 = "rgba(12, 105, 147, 1.0)";
export const _color_35 = "rgba(20, 145, 202, 1.0)";
export const _color_36 = "rgba(85, 189, 236, 1.0)";
export const _color_37 = "rgba(167, 143, 254, 1.0)";
export const _color_38 = "rgba(127, 101, 249, 1.0)";
export const _color_39 = "rgba(78, 46, 229, 1.0)";

export const _palette = [
  _color_0,
  _color_1,
  _color_2,
  _color_3,
  _color_4,
  _color_5,
  _color_6,
  _color_7,
  _color_8,
  _color_9,
  _color_10,
  _color_11,
  _color_12,
  _color_13,
  _color_14,
  _color_15,
  _color_16,
  _color_17,
  _color_18,
  _color_19,
  _color_20,
  _color_21,
  _color_22,
  _color_23,
  _color_24,
  _color_25,
  _color_26,
  _color_27,
  _color_28,
  _color_29,
  _color_30,
  _color_31,
  _color_32,
  _color_33,
  _color_34,
  _color_35,
  _color_36,
  _color_37,
  _color_38,
  _color_39,
];
