import {createSlice, createAsyncThunk, createAction} from "@reduxjs/toolkit";
const NS = "embedPost";
const initialState = {
  itemId: null,
  video: {
    streamId: null,
    itemId: null,
  },
  selectedTheme: null,
  selectedLang: {
    code: "en_us",
    name: "English",
    nativeName: "English",
  },
  customOption: false,
  postHt: "600px",
  embPostObj: {
    postHeight: 0,
    embedloader: true,
  },
  postInfo: {},
  userInfo: {},
};

export const setEmbedPostContent = createAction(`${NS}/setEmbedPostContent`);
export const setEmbedPlayerContent = createAction(
  `${NS}/setEmbedPlayerContent`,
);
export const closeEmbedPostContent = createAction(
  `${NS}/closeEmbedPostContent`,
);
export const closeEmbedPlayerContent = createAction(
  `${NS}/closeEmbedPlayerContent`,
);
export const setSelectedTheme = createAction(`${NS}/setSelectedTheme`);
export const setSelectedlang = createAction(`${NS}/setSelectedlang`);
export const setCustomOptionDialog = createAction(
  `${NS}/setCustomOptionDialog`,
);
export const setpostHt = createAction(`${NS}/setpostHt`);
export const setEmbedLoader = createAction(`${NS}/setEmbedLoader`);
export const embedPost = createSlice({
  name: NS,
  initialState,
  reducers: {
    setEmbedPostContent: (state, {payload}) => {
      state.itemId = payload;
    },
    closeEmbedPostContent: (state) => {
      state.itemId = null;
    },
    setEmbedPlayerContent: (state, {payload}) => {
      state.video = payload;
    },
    closeEmbedPlayerContent: (state) => {
      state.video = null;
    },
    setSelectedTheme: (state, {payload}) => {
      state.selectedTheme = payload;
    },
    setSelectedlang: (state, {payload}) => {
      state.selectedLang = payload;
    },
    setCustomOptionDialog: (state, {payload}) => {
      state.customOption = payload;
    },
    setpostHt: (state, {payload}) => {
      state.postHt = payload;
    },
    setEmbedLoader: (state, {payload}) => {
      state.embPostObj.embedloader = payload.embedloader;
      state.embPostObj.postHeight = payload.postHeight;
    },
  },
});
