import GAxios from "src/util/GAxios";
import {isResponseOk, parseItemStats} from "src/util/FeedUtils";
import {
  setboardCastEnd,
  setStreamData,
  setCategories,
  setLangTab,
} from "src/app/components/livestream_poc/store/index";
import {
  addLikedPosts,
  addSharedPosts,
  setPostsStats,
} from "src/store/modules/postStats";

export const getStreamLivenow = (dispatch, {getState}) => {
  const {livestream_poc} = getState() ?? {};
  const {offset, maxCount, langTab, lastOffset} = livestream_poc ?? {};
  if (lastOffset === offset) return;
  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/u/posts/v2/livenow`,
    params: {
      lang: langTab?.id || "all",
      offset: offset,
      max: maxCount,
      incl: "posts|stats|userinfo|shared|liked",
    },
  };
  GAxios(
    config,
    (response) => {
      if (isResponseOk(response)) {
        let responseData = response?.data?.result?.aux?.post;
        if (Object.values(responseData).length === 0) {
          dispatch(setboardCastEnd());
        }

        let listData = response?.data?.result?.data?.list;
        let userData = response?.data?.result?.aux?.uinf;
        let data_arr = [];
        listData.forEach((a1) => {
          let pstId = a1.activity.pstid;
          let userId = a1.activity.src_id;
          let post = responseData[pstId];
          let user = userData[userId];
          data_arr.push({
            ...post,
            post_id: pstId,
            user_id: userId,
            ...user,
            post_cdate: post?.cdate,
            post_dsc: post?.dsc || "",
            type: post?._t,
          });
        });
        const aux = response?.data?.result?.aux;
        if (Array.isArray(aux?.lks)) {
          dispatch(addLikedPosts(aux?.lks));
        }
        const postStats = parseItemStats(response?.data?.result?.aux);
        dispatch(setPostsStats(postStats));
        dispatch(addSharedPosts(response?.data?.result?.aux?.shrs));
        dispatch(setStreamData(data_arr));
      }
    },
    (err) => {
      console.log(err);
    },
  );
};

export const getCategories = (dispatch, {getState}) => {
  const {livestream_poc} = getState() ?? {};
  const {langTab} = livestream_poc ?? {};
  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/u/posts/v2/livenow/categories`,
  };
  GAxios(
    config,
    (response) => {
      if (isResponseOk(response)) {
        let listData = Object.values(response?.data?.result);
        let catObj = [{id: "all", name: "All"}, ...listData];
        dispatch(setCategories(Object.values(catObj)));
        if (!langTab) {
          dispatch(setLangTab(Object.values(catObj)[0]));
        }
      }
    },
    (err) => {
      console.log(err);
    },
  );
};
