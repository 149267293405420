import {getStreamLivenow, getCategories} from "./getStreamLivenow";
import {getStreamBatch} from "./getStreamBatch";
import {getMaestroToken} from "./getMaestroToken";
import {getMaestroHostsV1} from "./getMeastroHosts";

export const LivenowStreamApi = {
  getStreamLivenow,
  getStreamBatch,
  getCategories,
  getMaestroToken,
  getMaestroHostsV1,
};
