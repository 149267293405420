import GAxios from "src/util/GAxios";

export const getUserDataByMonth = async (params) => {
  const {
    userId,
    token,
    startMonth = null,
    endMonth = null,
    errorCallback,
  } = params;

  let xAppAuth =
    userId === null
      ? `{"user": null, "token": null}`
      : `{"user": "${userId}", "token": "${token}"}`;
  const config = {
    method: "get",
    headers: {
      "x-app-auth": xAppAuth,
    },
    url: `${process.env.REACT_APP_IMPRESSION_API_URL}/api/user_monthly`,
    params: {
      startMonth: startMonth,
      endMonth: endMonth,
    },
  };

  let res = {response: null, err: null};

  await GAxios(
    config,
    (response) => {
      if (response?.data?.summary) {
        res.response = response.data.summary;
      }
    },
    (err) => {
      res.err = true;
      if (errorCallback) errorCallback(err);
    },
  );
  return res;
};
