import GAxios from "src/util/GAxios";
import {userAccessStatus} from "src/store/modules/status";
import {getStore} from "src/store";
import {
  removePostsForBanAndMute,
  removePostsFromProfileForBanAndMute,
} from "src/app/components/timeline/store";

export const toggleBlock = async (
  {targetId, successCallback, errorCallback},
  {getState},
) => {
  const store = await getStore();
  const {status, auth} = getState() ?? {};
  const isBlocked = status?.blocked && status?.blocked[targetId];
  const config = {
    method: isBlocked ? "delete" : "post",
    url: `${process.env.REACT_APP_API_URL}/u/user/${auth.session?.userinfo?.ousername}/blocks/${targetId}`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    await GAxios(
      config,
      (response) => {
        if (response === null) {
          errorCallback && errorCallback();
          return null;
        }
        store.dispatch(
          userAccessStatus({
            userId: targetId,
            status: {blocking: !isBlocked, followed: false, following: false},
          }),
        );
        store.dispatch(removePostsForBanAndMute({uid: targetId}));
        store.dispatch(removePostsFromProfileForBanAndMute({uid: targetId}));
        successCallback(response);
        return [!isBlocked, targetId];
      },
      (error) => {
        console.error(error);
        errorCallback && errorCallback(error);
        return null;
      },
    );
  } catch (err) {
    console.error(err);
    errorCallback && errorCallback(err);
    return null;
  }
};
