import {toast} from "react-toastify";
import {decode} from "html-entities";
import {makeStyles, Typography} from "@material-ui/core";
import clsx from "clsx";

import AppConsts from "src/app/AppConsts";

import {ReactComponent as CheckRound} from "src/assets/icons/checkRound.svg";
import {ReactComponent as EnvelopeSent} from "src/assets/icons/envelopeSent.svg";
import {ReactComponent as IconWarning} from "src/assets/icons/basic_warning.svg";
import {ReactComponent as ToastWarningIcon} from "src/assets/icons/feature/toast_warning.svg";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: theme.spacing(2, 2.5),
    color: theme.palette.newColors.systemBlue[500],
    backgroundColor: theme.palette.newColors.systemBlue[50],
    border: `0.5px solid ${theme.palette.newColors.systemBlue[500]}`,
    borderRadius: 10,
    "&.align-center": {
      justifyContent: "center",
      alignItems: "center",
      "& svg": {
        marginTop: 1,
      },
    },
  },
  wrapperShort: {
    maxWidth: 400,
  },
  iconWrapper: {
    display: "inline-flex",
    marginLeft: theme.spacing(0),
    height: "100%",
    "& svg": {
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
    },
  },
  iconWrapperMessage: {
    height: theme.spacing(3.5),
    "& svg": {
      width: theme.spacing(3.5),
      height: theme.spacing(3.5),
    },
  },
  message: {
    paddingLeft: theme.spacing(0.75),
    fontSize: 16,
    // lineHeight: "20.8px",
    fontWeight: 500,
    verticalAlign: "baseline",
    position: "relative",
  },
  firstLetterCapitalize: {
    "&::first-letter": {
      textTransform: "capitalize",
    },
  },
  wrapperError: {
    backgroundColor: theme.palette.newColors.unique.red50,
    borderColor: theme.palette.newColors.unique.red600,
    boxShadow: "0px 0px 6px rgba(150, 150, 150, 0.25)",
    "& > p, & > div": {
      color: theme.palette.newColors.unique.red600,
    },
  },
  warningIcon: {
    width: 20,
    height: 20,
  },
  liveEndText: {
    color: `${theme.palette.utility.white} !important`,
  },
  liveEndWrapper: {
    backgroundColor: theme.palette.background.orangeToast,
    border: `1px solid ${theme.palette.line.orange}`,
  },
  CheckRoundIcon: {
    minWidth: "18px !important",
    color: theme.palette.newColors.systemBlue[500],
  },
  signUpverification: {
    top: "4px !important",
  },
  loginSuccess: {
    display: "-webkit-box",
    overflow: "hidden",
    whiteSpace: "normal",
    textOverflow: "ellipsis",
    wordWrap: "break-word",
    wordBreak: "break-all",
    "-webkit-line-clamp": "1",
    "-webkit-box-orient": "vertical",
  },
  nowrap: {
    whiteSpace: "nowrap",
  },
}));

/**
 * TODO: ADD Info and Warning types once the design is ready
 * @param {Object} props toast props
 * @param [props.toastProps] toast props
 * @param {string} props.message message to display in the notification
 * @param {boolean=} props.msgShort toast maxWidth: 400
 * @param {boolean=} props.firstLetterCapitalize Capitalize the first letter
 * @param {string=} props.scene scene
 * @param {boolean=} props.nowrap no warp for text content
 * @param {boolean=} props.alignCenter center align text content
 */

export const NotifMessage = ({
  toastProps,
  message,
  msgShort,
  firstLetterCapitalize,
  scene,
  nowrap,
  alignCenter,
}) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.wrapper,
        toastProps.type === toast.TYPE.ERROR ||
          toastProps.type === toast.TYPE.WARNING ||
          toastProps.type === AppConsts.NOTIF_MESSAGE_ERROR
          ? classes.wrapperError
          : "",
        toastProps.type === AppConsts.NOTIF_MESSAGE_LIVE_END
          ? classes.liveEndWrapper
          : "",
        {[classes.wrapperShort]: msgShort},
        nowrap && classes.nowrap,
        alignCenter && "align-center",
      )}
    >
      <div
        className={clsx(
          classes.iconWrapper,
          toastProps.type === toast.TYPE.ERROR ||
            toastProps.type === toast.TYPE.WARNING ||
            toastProps.type === AppConsts.NOTIF_MESSAGE_ERROR
            ? classes.iconWrapperError
            : "",
          toastProps.type === AppConsts.NOTIF_MESSAGE_SUCCESS
            ? classes.iconWrapperMessage
            : "",
        )}
      >
        {toastProps.type === toast.TYPE.SUCCESS && (
          <CheckRound className={classes.CheckRoundIcon} />
        )}
        {/**
         * Will use CheckRound for error type temporary
         * TODO: Replace CheckRound with the error icon once available
         */}
        {/*{toastProps.type === toast.TYPE.ERROR && <CheckRound />}*/}
        {(toastProps.type === toast.TYPE.ERROR ||
          toastProps.type === toast.TYPE.WARNING) && (
          <IconWarning className={classes.warningIcon} />
        )}
        {toastProps.type === AppConsts.NOTIF_MESSAGE_LIVE_END && (
          <ToastWarningIcon />
        )}
        {toastProps.type === AppConsts.NOTIF_MESSAGE_SUCCESS && (
          <EnvelopeSent />
        )}

        <Typography
          component={"div"}
          variant="body1"
          className={clsx(
            classes.message,
            {[classes.firstLetterCapitalize]: firstLetterCapitalize !== false}, // if it's undefined or null, consider it as true
            toastProps.type === AppConsts.NOTIF_MESSAGE_LIVE_END
              ? classes.liveEndText
              : "",
            scene === "signUp" ? classes.signUpverification : "",
            scene === "login" ? classes.loginSuccess : "",
          )}
        >
          {decode(message)}
        </Typography>
      </div>
    </div>
  );
};
