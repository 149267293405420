import {socialConstants} from "../_constants";
import GAxios from "src/util/GAxios";

let loginData = {
  pt: "",
  payload: null,
  err: null,
};

export const socialResendVerifyCode = async (params) => {
  if (!params) return;
  const {token, pvrinfo, callback} = params;
  const pvr_key = (pvrinfo?.pvr_key || "").toLowerCase();
  let authinfo = null;

  switch (pvr_key) {
    case socialConstants.GOOGLE_PVR_KEY:
      authinfo = {gg_token: token};
      break;
    case socialConstants.APPLE_PVR_KEY:
      authinfo = {ap_token: token};
      break;
    case socialConstants.TWITTER_PVR_KEY:
      authinfo = {tw_token: token};
      break;
    case socialConstants.FACEBOOK_PVR_KEY:
      authinfo = {fb_token: token};
      break;
  }

  if (authinfo) {
    const data = {
      content: {
        pvrinfo: pvrinfo,
        authinfo: authinfo,
      },
    };
    const config = {
      method: "post",
      url: process.env.REACT_APP_API_URL + "/v1/s/social/resend_vcode",
      headers: {
        "Content-Type": "application/json",
      },
      data,
    };

    const errCallback = (err) => {
      const {response} = err;
      loginData.err = response?.data?.error
        ? response?.data?.error?.code
        : err.toString();
    };
    const processResult = (result) => {
      const {pt, payload} = result;

      if (pt && payload) {
        loginData.err = null;
        loginData.pt = pt;
        loginData.payload = payload;
        callback && callback();
      } else {
        loginData.err = "Failed response";
      }
    };

    await GAxios(
      config,
      (response) => {
        processResult(response.data.result);
      },
      (err) => {
        console.error(err);
        errCallback(err);
      },
    );
  } else {
    loginData.err = "Missing auth token";
  }

  return loginData;
};
