import {createAction, createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {StatusApi} from "./api";

export const getBlockedUsers = createAsyncThunk(
  "status/getBlockedUsers",
  StatusApi.getBlockedUsers,
);

export const getMutedUsers = createAsyncThunk(
  "status/getMutedUsers",
  StatusApi.getMutedUsers,
);

export const currentUserBlockedUser = createAsyncThunk(
  "status/currentUserBlockedUser",
  StatusApi.currentUserBlockedUser,
);

export const currentUserMutesUser = createAsyncThunk(
  "status/currentUserMutesUser",
  StatusApi.currentUserMutesUser,
);

export const currentUserFollowsUser = createAsyncThunk(
  "status/currentUserFollowsUser",
  StatusApi.currentUserFollowsUser,
);

export const currentUserRemoveUser = createAsyncThunk(
  "status/currentUserRemoveUser",
  StatusApi.currentUserRemoveUser,
);

export const userTurnedOffLiveNotificationCurrentUser = createAsyncThunk(
  "status/userTurnedOffLiveNotificationCurrentUser",
  StatusApi.turnedOffLiveNotification,
);

export const userTurnedOnLiveNotificationCurrentUser = createAsyncThunk(
  "status/userTurnedOnLiveNotificationCurrentUser",
  StatusApi.turnedOffLiveNotification,
);

export const toggleMute = createAsyncThunk(
  "status/toggleMute",
  StatusApi.toggleMute,
);

export const toggleFollowing = createAsyncThunk(
  "status/toggleFollowing",
  StatusApi.toggleFollowing,
);

export const follow = createAsyncThunk("status/follow", StatusApi.follow);

export const unfollow = createAsyncThunk("status/unfollow", StatusApi.unfollow);

export const toggleBlock = createAsyncThunk(
  "status/toggleBlock",
  StatusApi.toggleBlock,
);

export const userAccessStatus = createAction("status/userAccessStatus");

export const statusSlice = createSlice({
  name: "status",
  initialState: {
    following: {},
    targetId: null,
    sendgtr: {},
    blacklistgtr: {},
    muted: {},
    blocked: {},
    followed: {},
    dm: {},
    mutedFetchLoading: false,
    blockedFetchLoading: false,
    turnedOffLiveNotificationUsers: {},
    turnedOnLiveNotificationUsers: {},
    gConfirmAlert: {},
    confirmationAlert: {},
    closeAllDropDown: false,
    stopAllPlayer: false,
    gGlobalDialog: {},
  },
  reducers: {
    userAccessStatus: (state, action) => {
      const {userId, status} = action.payload;
      const {following, muting, blocking, followed, dm, sendgtr, blacklistgtr} =
        status || {};
      state.following[userId] =
        following === undefined ? state.following[userId] : following;
      state.muted[userId] = muting === undefined ? state.muted[userId] : muting;
      state.blocked[userId] =
        blocking === undefined ? state.blocked[userId] : blocking;
      state.followed[userId] =
        followed === undefined ? state.followed[userId] : followed;
      state.dm[userId] = dm === undefined ? state.dm[userId] : dm;
      state.sendgtr[userId] =
        sendgtr === undefined ? state.sendgtr[userId] : sendgtr;
      state.blacklistgtr[userId] =
        blacklistgtr === undefined ? state.blacklistgtr[userId] : blacklistgtr;
      state.isLoading = false;
    },
    handleStatusLoader: (state, action) => {
      state.isLoading = action?.payload;
    },
    changeFollowingStatus(state, action) {
      const {userId, status} = action.payload;
      state.following[userId] = status;
      state.targetId = userId;
    },
    addFollowingUserIds(state, action) {
      const followingUserIds = action.payload;
      followingUserIds.forEach((userId) => {
        state.following[userId] = true;
      });
    },
    setFollowingStatus(state, action) {
      const {status} = action.payload;
      state.following = status;
    },
    updateFollowingStatusInBatch(state, action) {
      const {following, followed} = action.payload;
      state.following = {
        ...state.following,
        ...following,
      };
      state.followed = {
        ...state.followed,
        ...followed,
      };
    },
    changeMutedStatus(state, action) {
      const {userId, status} = action.payload;
      state.muted[userId] = status;
    },
    setMutedStatus(state, action) {
      const {status} = action.payload;
      state.muted = status;
    },
    changeBlockedStatus(state, action) {
      const {userId, status} = action.payload;
      state.blocked[userId] = status;
    },
    setBlockedStatus(state, action) {
      const {status} = action.payload;
      state.blocked = status;
    },
    resetUsersStatus(state) {
      state.following = {};
      state.muted = {};
      state.blocked = {};
    },
    addTurnOffNotification(state, action) {
      if (action.payload) {
        const {tname, turnOff, turnOn} = action.payload;
        state.turnedOffLiveNotificationUsers[tname] = turnOff;
        state.turnedOnLiveNotificationUsers[tname] = turnOn;
      }
    },
    addTurnOnNotification(state, action) {
      if (action.payload) {
        const {tname, turnOn, turnOff} = action.payload;
        state.turnedOnLiveNotificationUsers[tname] = turnOn;
        state.turnedOffLiveNotificationUsers[tname] = turnOff;
      }
    },
    setGConfirmAlert(state, action) {
      state.gConfirmAlert = {
        ...action.payload,
        open: action.payload.open ?? true,
      };
    },
    setConfirmationAlert(state, action) {
      state.confirmationAlert = {
        ...action.payload,
        open: action.payload.open ?? true,
      };
    },
    toggleCloseAllDropDown(state, action) {
      state.closeAllDropDown = action.payload;
    },
    toggleStopAllPlayer(state, action) {
      state.stopAllPlayer = action.payload;
    },
    setGGlobalDialog(state, action) {
      state.gGlobalDialog = {
        ...action.payload,
        open: action.payload.open ?? true,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBlockedUsers.fulfilled, (state, {payload}) => {
      payload?.forEach((userId) => (state.blocked[userId] = true));
    });

    builder.addCase(getMutedUsers.fulfilled, (state, {payload}) => {
      payload?.forEach((userId) => (state.muted[userId] = true));
    });
    builder.addCase(currentUserBlockedUser.pending, (state, {payload}) => {
      state.blockedFetchLoading = true;
    });
    builder.addCase(currentUserBlockedUser.fulfilled, (state, {payload}) => {
      if (payload) {
        const [blocked, targetId] = payload;
        state.blocked[targetId] = blocked;
        state.blockedFetchLoading = false;
      } else {
        state.blockedFetchLoading = false;
      }
    });
    builder.addCase(currentUserBlockedUser.rejected, (state, {payload}) => {
      state.blockedFetchLoading = false;
    });
    builder.addCase(currentUserMutesUser.pending, (state, {payload}) => {
      state.mutedFetchLoading = true;
    });
    builder.addCase(currentUserMutesUser.fulfilled, (state, {payload}) => {
      if (payload) {
        const [muted, targetId] = payload;
        state.muted[targetId] = muted;
        state.mutedFetchLoading = false;
      } else {
        state.mutedFetchLoading = false;
      }
    });
    builder.addCase(currentUserMutesUser.rejected, (state, {payload}) => {
      state.mutedFetchLoading = false;
    });
    builder.addCase(currentUserFollowsUser.fulfilled, (state, {payload}) => {
      if (payload) {
        const [following, targetId] = payload;
        state.following[targetId] = following;
      }
    });
    builder.addCase(
      userTurnedOffLiveNotificationCurrentUser.fulfilled,
      (state, {payload}) => {
        if (payload) {
          const {ringme_ls, tname} = payload;
          state.turnedOffLiveNotificationUsers[tname] = ringme_ls === 0;
        }
      },
    );
    builder.addCase(
      userTurnedOnLiveNotificationCurrentUser.fulfilled,
      (state, {payload}) => {
        if (payload) {
          const {ringme_ls, tname} = payload;
          state.turnedOnLiveNotificationUsers[tname] = ringme_ls === 0;
        }
      },
    );
    builder.addCase(toggleMute.fulfilled, (state, {payload}) => {
      if (payload) {
        const [muted, targetId] = payload;
        state.muted[targetId] = muted;
      }
    });
    builder.addCase(toggleFollowing.fulfilled, (state, {payload}) => {
      if (payload) {
        const [following, targetId] = payload;
        state.following[targetId] = following;
      }
    });
    builder.addCase(toggleBlock.fulfilled, (state, {payload}) => {
      if (payload) {
        const [blocked, targetId] = payload;
        state.blocked[targetId] = blocked;
        if (blocked) {
          state.following[targetId] = false;
        }
      }
    });
  },
});

export const {
  changeFollowingStatus,
  addFollowingUserIds,
  setFollowingStatus,
  updateFollowingStatusInBatch,
  changeMutedStatus,
  setMutedStatus,
  changeBlockedStatus,
  setBlockedStatus,
  resetUsersStatus,
  resetMutedFetchLoading,
  resetBlockedFetchLoading,
  addTurnOffNotification,
  addTurnOnNotification,
  handleStatusLoader,
  setGConfirmAlert,
  setConfirmationAlert,
  toggleCloseAllDropDown,
  toggleStopAllPlayer,
  setGGlobalDialog,
} = statusSlice.actions;
