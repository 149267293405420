import GAxios from "src/util/GAxios";

export const fetchSuggestions = async (
  offset,
  max,
  callback,
  errorCallback,
) => {
  await GAxios(
    {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/s/usertag/suggest`,
      params: {
        offset: offset,
        max,
        incl: "userinfo|followings",
      },
    },
    (res) => {
      callback(res);
    },
    (res) => {
      if (errorCallback) errorCallback(res);
    },
  );
};
