import i18next from "i18next";
import {
  createContext,
  FunctionComponent,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import {IntlProvider} from "react-intl";
import {useDispatch} from "react-redux";
import {useSearchParams} from "react-router-dom";
import AppConsts from "src/app/AppConsts";
import {SupportedLanguageList} from "src/app/AppMessages";
import {setSessionUserLanguage} from "src/app/components/auth/store";
import {setLang} from "src/i18n/utils";
import {getRequestedLanguage} from "src/util/language";

export interface LanguageContextType {
  /** internal language code */
  language: string;
  /** standard language code */
  locale: string;
  /** standard language code split by underscore */
  _locale: string;
  setLanguage: (language: string) => void;
}

const defaultLanguage = "en_us";

const languageMap = {
  // English
  en_us: "en",
  // Portuguese
  pt_br: "pt",
  // Spanish
  es_es: "es",
  // German
  de_de: "de",
  // Japanese
  ja_jp: "ja",
  // Chinese Simplified
  zh_cn: "zh",
  // Chinese Traditional
  zh_hant_tw: "zh-TW",
  // hindi
  hi_in: "hi",
};

const initial = {
  language: defaultLanguage,
  locale: defaultLanguage,
  _locale: defaultLanguage,
  setLanguage: () => {
    if (process.env.NODE_ENV === "development") {
      console.warn("LanguageProvider not found");
    }
  },
};

const LanguageContext = createContext<LanguageContextType>(initial);

export const LanguageProvider: FunctionComponent = ({children}) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [language, setLanguage] = useState(() => {
    // get a supported language from the query params, local storage, or browser
    const supportedLangs = SupportedLanguageList.map(
      ({code}: {code: string}) => code,
    );
    const requestedLangs: string[] = [];
    const queryLang = searchParams.get("lang")?.replace(/_/g, "-");
    if (queryLang) requestedLangs.push(queryLang);
    const localStorageLang = localStorage
      .getItem(AppConsts.LOCAL_STORAGE_LAST_BROWSER_LANG)
      ?.replace(/_/g, "-");
    if (localStorageLang) requestedLangs.push(localStorageLang);

    const browserLangs =
      window.navigator.languages || [window.navigator.language] || [];
    requestedLangs.push(...browserLangs);
    const code = getRequestedLanguage(supportedLangs, requestedLangs);
    // set the language in local storage if it's not already there
    if (localStorageLang !== code)
      localStorage.setItem(AppConsts.LOCAL_STORAGE_LAST_BROWSER_LANG, code);
    // set the language in the redux store
    dispatch(setSessionUserLanguage(code as any));
    // set i18next language
    i18next.changeLanguage(code);
    return code;
  });

  const handleSetLanguage = useCallback(
    (code: string) => {
      if (language === code) return;
      dispatch(setSessionUserLanguage(code as any));
      setLanguage(code);
      setLang(code);
    },
    [language],
  );

  const {locale, _locale} = useMemo(() => {
    const locale =
      languageMap[language as keyof typeof languageMap] || defaultLanguage;
    const _locale = locale.replace(/-/g, "_");
    return {
      locale,
      _locale,
    };
  }, [language]);

  return (
    <LanguageContext.Provider
      value={{language, locale, _locale, setLanguage: handleSetLanguage}}
    >
      <IntlProvider locale={locale}>{children}</IntlProvider>
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  return useContext(LanguageContext);
};
