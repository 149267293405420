import {createAction, createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {userConstants} from "src/app/components/auth/_constants";
import {
  genericConstants,
  getValueInSession,
  storeDataInSession,
} from "src/util/GettrPayUtils";
import {GettrPayApi} from "./api";

const NS = "pay";

export const createWallet = createAsyncThunk(
  `${NS}/wallet`,
  GettrPayApi.createWallet,
);
export const getWalletBalance = createAsyncThunk(
  `${NS}/balance`,
  GettrPayApi.getBalance,
);
export const getConfigs = createAsyncThunk(
  `${NS}/configs`,
  GettrPayApi.getConfigDetails,
);
export const getConversionRate = createAsyncThunk(
  `${NS}/conversion-rate`,
  GettrPayApi.getConversionRate,
);
export const getWalletUserDetails = createAsyncThunk(
  `${NS}/wallet`,
  GettrPayApi.getWalletUserDetails,
);
export const resetWalletState = createAction(`${NS}/resetWalletState`);
export const updateTimeoutId = createAction(`${NS}/updateTimeoutId`);
export const updatePendingTxnTab = createAction(`${NS}/updatePendingTxnTab`);
export const setBalanceLoading = createAction(`${NS}/setBalanceLoading`);
export const updateRedemptionHistoryTab = createAction(
  `${NS}/updateRedemptionHistoryTab`,
);
export const updateTransactionFilters = createAction(
  `${NS}/updateTransactionFilters`,
);
export const updateTxnReload = createAction(`${NS}/updateTxnReload`);
export const openPointsActivationMsg = createAction(
  `${NS}/openPointsActivationMsg`,
);
export const closePointsActivationMsg = createAction(
  `${NS}/closePointsActivationMsg`,
);
let userInfo = localStorage.getItem(userConstants.LS_SESSION_INFO);

userInfo = userInfo
  ? JSON.parse(localStorage.getItem(userConstants.LS_SESSION_INFO))
  : {};

userInfo =
  userInfo?.authenticated && userInfo.userinfo ? userInfo.userinfo : {};

// currency
const currency =
  localStorage.getItem(`${genericConstants.CURRENCY}_${userInfo._id}`) || "USD";

// timeout
const timeout =
  localStorage.getItem(`${genericConstants.TIMEOUT}_${userInfo._id}`) || 0;

// timeoutId
const timeoutId = getValueInSession(genericConstants.TIMEOUTID, "string");

// walletDetails
let walletDetails = getValueInSession(genericConstants.WALLET, "object");
walletDetails = walletDetails ? walletDetails : {publicKey: "", user: ""};

const initialState = {
  loading: false,
  isAccessable: true,
  currency,
  walletDetails,
  timeout,
  balance: 0,
  loadingBalance: false,
  conversions: {
    [`gtrTo${currency}`]: 0.01,
  },
  transactionFilters: {
    event: [],
    status: [],
    tab: 0,
    txnPage: 0,
  },
  pendingTxnTab: 0,
  redemptionHistoryTab: 0,
  requester: {
    name: "",
    picture: "",
    user: "",
    userHandle: "",
    bgImg: "",
  },
  timeoutId,
  isTxnReload: false,
  configs: {},
  pointsActivationMsg: false,
  sendReceive: {
    open: false,
    send: false,
    receive: false,
    step: 1,
    requestId: "",
  },
};

export const gettrPay = createSlice({
  name: NS,
  initialState,
  reducers: {
    loading: (state, action) => {
      const {data} = action.payload;
      state.loading = data;
    },
    updateTxnReload: (state, action) => {
      const {data} = action.payload;
      state.isTxnReload = data;
    },
    updateTimeoutId: (state, action) => {
      const {data} = action.payload;
      state.timeoutId = data;
      storeDataInSession(genericConstants.TIMEOUTID, data);
    },
    updateCurrency: (state, action) => {
      const {data} = action.payload;
      localStorage.setItem(
        `${genericConstants.CURRENCY}_${data.user}`,
        data.currency,
      );
      state.currency = data.currency;
    },
    updateTimeout: (state, action) => {
      const {data} = action.payload;
      state.timeoutId = null;
      state.timeout = data.timeout;
      localStorage.setItem(
        `${genericConstants.TIMEOUT}_${data.user}`,
        data.timeout,
      );
    },
    updateWalletDetails: (state, action) => {
      const {data} = action.payload;
      state.walletDetails = data;
      storeDataInSession(genericConstants.WALLET, JSON.stringify(data));
    },
    updatePendingTxnTab: (state, action) => {
      const {data} = action.payload;
      state.pendingTxnTab = data;
    },
    updateRedemptionHistoryTab: (state, action) => {
      const {data} = action.payload;
      state.redemptionHistoryTab = data;
    },
    setBalanceLoading: (state, action) => {
      const {data} = action.payload;
      state.loadingBalance = data;
    },
    updateRequester: (state, action) => {
      const {data} = action.payload;
      state.requester = data;
    },
    resetWalletState: (state) => {
      state.loading = false;
      state.walletDetails = {
        publicKey: "",
        user: "",
      };
      state.balance = 0;
      state.pendingTxnTab = 0;
      state.redemptionHistoryTab = 0;
      state.transactionFilters = {
        event: [],
        status: [],
        tab: 0,
        txnPage: 0,
      };
      state.requester = {
        name: "",
        picture: "",
        user: "",
        userHandle: "",
        bgImg: "",
      };
      state.timeoutId = null;
    },
    transactionFilters(state, action) {
      const {value, key, clear} = action.payload;
      if (clear) {
        state.transactionFilters = {
          status: [],
          event: [],
          tab: 0,
        };
        return;
      }
      state.transactionFilters[key] = value;
    },

    updateTransactionFilters(state, action) {
      const {data} = action.payload;
      state.transactionFilters = data;
    },
    openPointsActivationMsg(state) {
      state.pointsActivationMsg = true;
    },
    closePointsActivationMsg(state) {
      state.pointsActivationMsg = false;
    },
    updateSendReceivePopup(state, action) {
      const {value, key, reset} = action.payload;
      reset
        ? (state.sendReceive = {
            open: false,
            send: false,
            receive: false,
            step: 1,
            requestId: "",
          })
        : (state.sendReceive[key] = value);
    },
    setSendReceiveStep: (state, {payload}) => {
      state.sendReceive.step = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getWalletBalance.fulfilled, (state, {payload}) => {
      state.loadingBalance = false;
      state.balance = payload ? payload : 0;
    });
    builder.addCase(getWalletBalance.rejected, (state, {payload}) => {
      state.loadingBalance = false;
      state.balance = 0;
    });
    builder.addCase(getConversionRate.fulfilled, (state, {payload}) => {
      if (payload) {
        state.currency = payload.currency;
        state.conversions = payload;
      }
    });
    builder.addCase(getConfigs.fulfilled, (state, {payload}) => {
      state.configs = payload;
    });
  },
});

export const {
  transactionFilters,
  loading,
  updateRequester,
  updateCurrency,
  updateTimeout,
  updateWalletDetails,
  updateSendReceivePopup,
  setSendReceiveStep,
} = gettrPay.actions;
