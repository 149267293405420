import GAxios from "src/util/GAxios";

export const changeGTok = async (data) => {
  if (!data) return;

  let params = {
    content: {
      ...data,
      incl: "userinfo|postinfo",
    },
  };

  params = JSON.stringify(params);

  const config = {
    method: "post",
    url: process.env.REACT_APP_API_URL + "/u/post/v2/change/vision",
    data: params,
  };

  let res = {err: null, response: null};

  await GAxios(
    config,
    (response) => {
      if (response.data.result) {
        res.response = response.data.result;
      } else {
        res.err = true;
      }
    },
    (err) => {
      res.err = err;
    },
  );

  return res;
};
