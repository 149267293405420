import React from "react";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";
import {ButtonBase, capitalize} from "@material-ui/core";
import {GLoader} from "./GLoader";

const useStyles = makeStyles(
  (theme) => ({
    gbutton: {
      ...theme.typography.button,
      borderRadius: "32px",
      boxSizing: "border-box",
      minWidth: 64,
      padding: theme.spacing(0.75, 2),
      boxShadow: "none",
      wordBreak: "normal",
      whiteSpace: "nowrap",
      color: theme.palette.newColors.neutral[900],
      textTransform: "capitalize",
      transition: theme.transitions.create(
        ["background-color", "box-shadow", "border"],
        {
          duration: theme.transitions.duration.short,
        },
      ),
      "&:disabled": {
        color: "transparent",
      },
      "&:not(:disabled):hover": {
        textDecoration: "none",
        backgroundColor: theme.palette.newColors.neutral[50],
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
      "& span": {
        display: "flex",
        alignItems: "center",
      },
    },
    /* Styles applied to the root element if `variant="text"`. */
    text: {},
    /* Styles applied to the root element if `variant="text"` and `color="primary"`. */
    textPrimary: {},
    /* Styles applied to the root element if `variant="outlined"`. */
    outlined: {
      border: `1px solid ${theme.palette.newColors.neutral[900]}`,
      "&:not(:disabled):hover": {
        backgroundColor: theme.palette.newColors.neutral[100],
      },
      "&:disabled": {
        border: `1px solid ${theme.palette.newColors.neutral[200]}`,
      },
    },
    /* Styles applied to the root element if `variant="outlined"` and `color="primary"`. */
    outlinedPrimary: {
      border: `1px solid ${theme.palette.newColors.neutral[900]}`,
      "&:not(:disabled):hover": {
        backgroundColor: theme.palette.newColors.neutral[100],
      },
      "&:disabled": {
        border: `1px solid ${theme.palette.newColors.neutral[200]}`,
      },
    },
    /* Styles applied to the root element if `variant="outlined"` and `color="error"`. */
    outlinedError: {
      color: theme.palette.newColors.red[700],
      border: `1px solid ${theme.palette.newColors.red[700]}`,
      "&:not(:disabled):hover": {
        backgroundColor: theme.palette.newColors.red[50],
      },
      "&:disabled": {
        border: `1px solid ${theme.palette.newColors.red[100]}`,
      },
    },
    outlinedStatic: {
      color: theme.palette.newColors.white,
      border: `1px solid ${theme.palette.newColors.white}`,
      fontWeight: "bold",
      fontSize: theme.spacing(1.875),
      "&:not(:disabled):hover": {
        backgroundColor: theme.palette.newColors.overlayWhite[24],
      },
      "&:disabled": {
        border: `1px solid ${theme.palette.newColors.neutral[400]}`,
      },
    },
    contained: {},
    /* Styles applied to the root element if `variant="contained"` and `color="primary"`. */
    containedPrimary: {
      color: theme.palette.newColors.neutral[25],
      backgroundColor: ["light", "dark"].includes(theme.type)
        ? theme.palette.newColors.neutral[900]
        : theme.palette.newColors.red[600],
      "&:not(:disabled):hover": {
        backgroundColor: ["light", "dark"].includes(theme.type)
          ? theme.palette.newColors.neutral[700]
          : theme.palette.newColors.red[700],
      },
      "&:disabled": {
        color: ["light", "dark"].includes(theme.type)
          ? theme.palette.newColors.neutral[400]
          : theme.palette.newColors.red[400],
        //backgroundColor: theme.palette.newColors.neutral[200],
        backgroundColor: ["light", "dark"].includes(theme.type)
          ? theme.palette.newColors.neutral[200]
          : theme.palette.newColors.red[300],
      },
    },
    /* Styles applied to the root element if `variant="contained"` and `color="secondary"`. */
    containedSecondary: {
      backgroundColor: theme.palette.newColors.neutral[100],
      "&:not(:disabled):hover": {
        backgroundColor: theme.palette.newColors.neutral[200],
      },
      "&:disabled": {
        color: theme.palette.newColors.neutral[300],
        backgroundColor: theme.palette.newColors.neutral[100],
      },
    },
    /* Styles applied to the root element if `variant="contained"` and `color="error"`. */
    containedError: {
      color: theme.palette.utility.white,
      backgroundColor: theme.palette.newColors.red[600],
      "&:not(:disabled):hover": {
        backgroundColor: theme.palette.newColors.red[700],
      },
      "&:disabled": {
        color: theme.palette.newColors.neutral[200],
        backgroundColor: theme.palette.newColors.neutral[100],
      },
    },
    containedStatic: {
      color: theme.palette.newColors.neutral[900],
      backgroundColor: theme.palette.newColors.neutral[100],
      fontWeight: "bold",
      fontSize: theme.spacing(1.875),
      "&:not(:disabled):hover": {
        backgroundColor: theme.palette.newColors.backgroundSbg[9],
      },
      "&:disabled": {
        color: theme.palette.newColors.neutral[600],
        backgroundColor: theme.palette.newColors.neutral[400],
      },
    },
    containedStaticError: {
      color: theme.palette.utility.white,
      backgroundColor: theme.palette.newColors.red[600],
      "&:not(:disabled):hover": {
        backgroundColor: theme.palette.newColors.red[700],
      },
      "&:disabled": {
        color: theme.palette.newColors.neutral[200],
        backgroundColor: theme.palette.newColors.neutral[100],
      },
    },
    /* Styles applied to the root element if `fullWidth={true}`. */
    fullWidth: {
      width: "100%",
    },
    bold: {
      fontWeight: "bold",
    },
  }),
  {name: "Gettr"},
);

export const GButton = React.forwardRef(
  /**
   * A button clearly communicates what action will occur when the user touches it.
   * It consists of text, an image, or both, designed in accordance with your app’s color theme.
   * @param {object} props
   * @param {import("react").ReactNode} [props.children] - The content of the button.
   * @param {string} [props.className] - The className of the button.
   * @param {boolean} [props.loading] - If `true`, the button will be disabled.
   * @param {boolean} [props.bold] - If `true`, the button will be bold.
   * @param {string} [props.color] - The color of the component. It supports those theme colors that make sense for this component.
   * @param {import("react").ElementType} [props.component] - The component used for the root node. Either a string to use a HTML element or a component.
   * @param {boolean} [props.disabled] - If `true`, the button will be disabled.
   * @param {boolean} [props.fullWidth] - If `true`, the button will take up the full width of its container.
   * @param {ReactNode} [props.startIcon] - Element placed before the children.
   * @param {string} [props.type] - The type of button.
   * @param {string} [props.variant] - The variant to use.
   * @param {Function} [props.onClick] - The onClick function.
   * @returns {import("react").ReactElement} - A button clearly communicates what action will occur when the user touches it.
   */
  function Button(props, ref) {
    const {
      children,
      className,
      loading,
      bold,
      color = "default",
      component = "button",
      disabled = false,
      fullWidth = false,
      startIcon: startIconProp,
      type = "button",
      variant = "text",
      ...other
    } = props;
    const classes = useStyles();
    const wrapperStyle = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%", // handle if the screen height is small
    };
    return (
      <ButtonBase
        className={clsx(
          classes.gbutton,
          classes[variant],
          {
            [classes[`${variant}${capitalize(color)}`]]:
              color !== "default" && color !== "inherit",
            [classes.fullWidth]: fullWidth,
            [classes.bold]: bold,
          },
          className,
        )}
        disableRipple={true}
        component={component}
        disabled={loading || disabled}
        ref={ref}
        type={type}
        {...other}
      >
        {loading ? (
          <GLoader wrapperStyle={wrapperStyle} isButton color="#fff" />
        ) : (
          <span>{children}</span>
        )}
      </ButtonBase>
    );
  },
);
