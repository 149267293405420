import GAxios from "src/util/GAxios";

export const translatePost = async (postId, targetLang) => {
  if (!postId || !targetLang) return;

  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/u/translate/post/${postId}/target/${targetLang}`,
  };

  let res = {response: null, err: null};

  await GAxios(
    config,
    (response) => {
      if (response?.data?.result) {
        res.response = response.data.result;
      }
    },
    (err) => {
      res.err = err;
    },
  );
  if (res.err) {
    throw new Error(res.err);
  }

  return res;
};
