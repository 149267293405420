import GAxios from "src/util/GAxios";
import {userAccessStatus} from "src/store/modules/status";
import {getStore} from "src/store";
import {removePostsForBanAndMute} from "src/app/components/timeline/store";

export const toggleMute = async (
  {targetId, successCallback, errorCallback},
  {getState},
) => {
  try {
    const store = await getStore();
    const {status, auth} = getState() ?? {};
    const isMuted = status?.muted && status?.muted[targetId];
    const config = {
      method: isMuted ? "delete" : "post",
      url: `${process.env.REACT_APP_API_URL}/u/user/${auth.session?.userinfo?.ousername}/mutes/${targetId}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    await GAxios(
      config,
      (response) => {
        if (response === null) {
          errorCallback && errorCallback();
          return null;
        }
        store.dispatch(
          userAccessStatus({userId: targetId, status: {muting: !isMuted}}),
        );
        store.dispatch(removePostsForBanAndMute({uid: targetId}));
        successCallback(response);
        return [!isMuted, targetId];
      },
      (error) => {
        console.error(error);
        errorCallback && errorCallback(error);
        return null;
      },
    );
  } catch (err) {
    console.error(err);
    errorCallback && errorCallback(err);
    return null;
  }
};
