import {
  createSlice,
  createAsyncThunk,
  createAction,
  dispatch,
} from "@reduxjs/toolkit";
import {StickerApi} from "../api";

const NS = "sticker";
export const IMAGE_BASE_URL = process.env.REACT_APP_STICKER_API_CDN
  ? process.env.REACT_APP_STICKER_API_CDN + `/stickers/v1`
  : "https://stickers.gettr.com/stickers/v1";

const recentCate = [
  {
    ariaLabel: "Recent",
    title: "Recent",
    type: "button",
    ariaSelected: "false",
    image: null,
    icon: (
      <svg
        style={{
          width: "20px!important",
          height: "20px!important",
        }}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.675 5.75001V5.70001H12.625H11.375H11.325V5.75001V12.2588V12.2795L11.3396 12.2941L15.2727 16.2272L15.3081 16.2626L15.3435 16.2272L16.2272 15.3435L16.2626 15.3081L16.2272 15.2727L12.675 11.7205V5.75001ZM12 1.95C6.45848 1.95 1.95 6.45848 1.95 12C1.95 17.5415 6.45848 22.05 12 22.05C17.5415 22.05 22.05 17.5415 22.05 12C22.05 6.45848 17.5415 1.95 12 1.95ZM12 20.7C7.20278 20.7 3.30001 16.7972 3.30001 12C3.30001 7.20278 7.20278 3.30001 12 3.30001C16.7972 3.30001 20.7 7.20278 20.7 12C20.7 16.7972 16.7972 20.7 12 20.7Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="0.1"
        />
      </svg>
    ),
  },
];

const initialState = {
  list: null,
  category: null,
  loading: false,
  recent: {
    list: [],
    category: [],
    loading: false,
    recentLen: 0,
  },
  default: {
    list: [],
    category: [],
    loading: false,
  },
  search: {
    list: [],
    searchText: "",
    loading: false,
  },
  currentSticker: null,
};

export const selectedSticker = createAction(`${NS}/selectedSticker`);

export const fetchData = createAsyncThunk(
  `${NS}/fetchData`,
  async (_, thunkAPI) => {
    const data = thunkAPI.getState();
    if (
      data?.sticker?.list?.length > 0 &&
      data?.sticker?.category?.length > 0
    ) {
      return {
        list: data.sticker.list,
        category: data.sticker.category,
        recentLen: data.sticker.recentLen,
      };
    }

    const result = await StickerApi.fetchStickersDefault();
    const recentResult = await StickerApi.fetchStickerRecent();
    const _list = result.response.packets.map((packet) => {
      return {
        categoryName: packet.author_name.value,
        categoryFullName: packet.name.value,
        list: packet.stickers.map((sticker) => {
          return {
            ariaLabel: "",
            image: {
              pc: `${IMAGE_BASE_URL}/${sticker.packet_id}/${sticker.sticker_id}/main_pc.webp`.toLowerCase(),
              mobile:
                `${IMAGE_BASE_URL}/${sticker.packet_id}/${sticker.sticker_id}/main_mobile.webp`.toLowerCase(),
              thumb:
                `${IMAGE_BASE_URL}/${sticker.packet_id}/${sticker.sticker_id}/thumbnail_mobile.webp`.toLowerCase(),
            },
            icon: null,
            title: "",
            type: "",
            hints: sticker.hints,
            height: sticker.height,
            width: sticker.width,
            packet_id: sticker.packet_id,
            sticker_id: sticker.sticker_id,
            categoryTier: packet.tier,
          };
        }),
      };
    });

    const recentList = [
      {
        categoryName: "Recent",
        categoryFullName: "Recent",
        list:
          recentResult?.response?.stickers
            .map((sticker) => {
              return {
                ariaLabel: "",
                image: {
                  pc: `${IMAGE_BASE_URL}/${sticker.packet_id}/${sticker.sticker_id}/main_pc.webp`.toLowerCase(),
                  mobile:
                    `${IMAGE_BASE_URL}/${sticker.packet_id}/${sticker.sticker_id}/main_mobile.webp`.toLowerCase(),
                  thumb:
                    `${IMAGE_BASE_URL}/${sticker.packet_id}/${sticker.sticker_id}/thumbnail_mobile.webp`.toLowerCase(),
                },
                icon: null,
                title: "",
                type: "",
                hints: sticker.hints,
                height: sticker.height,
                width: sticker.width,
                packet_id: sticker.packet_id,
                sticker_id: sticker.sticker_id,
              };
            })
            .slice(0, 20) || [],
      },
    ];

    const list = recentList.concat(_list);

    const _category = result.response.packets.map((packet) => {
      return {
        ariaLabel: packet.name.value,
        title: packet.author_name.value,
        type: packet.type,
        ariaSelected: true,
        image: {
          pc: `${IMAGE_BASE_URL}/${packet.packet_id}/main_pc.webp`.toLowerCase(),
          mobile:
            `${IMAGE_BASE_URL}/${packet.packet_id}/main_mobile.webp`.toLowerCase(),
          thumb:
            `${IMAGE_BASE_URL}/${packet.packet_id}/thumbnail_mobile.webp`.toLowerCase(),
        },
        icon: null,
        packet_id: packet.packet_id,
        sticker_id: null,
        categoryTier: packet.tier,
      };
    });

    const category = recentCate.concat(_category);

    thunkAPI.dispatch(
      setDefault({
        list: _list,
        category: _category,
      }),
    );

    thunkAPI.dispatch(
      setRecent({
        list: recentList,
        category: recentCate,
      }),
    );

    return {
      list,
      category,
      recentLen: recentResult?.response?.stickers?.length,
    };
  },
);

export const fetchUseSticker = createAsyncThunk(
  `${NS}/fetchUseSticker`,
  async (_, thunkAPI) => {
    const currentSticker = thunkAPI.getState().sticker.currentSticker;
    if (currentSticker) {
      await StickerApi.fetchStickersUse(currentSticker);
      thunkAPI.dispatch(selectedSticker(null));
      thunkAPI.dispatch(fetchStickerRecent());
    } else {
      return false;
    }
    return true;
  },
);

export const fetchStickersSearch = createAsyncThunk(
  `${NS}/fetchStickersSearch`,
  async (searchText, thunkAPI) => {
    const result = await StickerApi.fetchStickersSearch(searchText);
    let list = result?.response?.stickers;
    list = list?.map((sticker) => {
      return {
        ariaLabel: "",
        icon: null,
        title: "",
        image: {
          pc: `${IMAGE_BASE_URL}/${sticker.packet_id}/${sticker.sticker_id}/main_pc.webp`.toLowerCase(),
          mobile:
            `${IMAGE_BASE_URL}/${sticker.packet_id}/${sticker.sticker_id}/main_mobile.webp`.toLowerCase(),
          thumb:
            `${IMAGE_BASE_URL}/${sticker.packet_id}/${sticker.sticker_id}/thumbnail_mobile.webp`.toLowerCase(),
        },
        type: sticker.type,
        version: sticker.version,
        hints: sticker.hints,
        height: sticker.height,
        width: sticker.width,
        packet_id: sticker.packet_id,
        sticker_id: sticker.sticker_id,
      };
    });
    return list;
  },
);
export const fetchStickersDefault = createAsyncThunk(
  `${NS}/fetchStickersDefault`,
  async (_, thunkAPI) => {
    const result = await StickerApi.fetchStickersDefault();
    const _list = result.response.packets.map((packet) => {
      return {
        categoryName: packet.author_name.value,
        categoryFullName: packet.name.value,
        list: packet.stickers.map((sticker) => {
          return {
            ariaLabel: "",
            image: {
              pc: `${IMAGE_BASE_URL}/${sticker.packet_id}/${sticker.sticker_id}/main_pc.webp`.toLowerCase(),
              mobile:
                `${IMAGE_BASE_URL}/${sticker.packet_id}/${sticker.sticker_id}/main_mobile.webp`.toLowerCase(),
              thumb:
                `${IMAGE_BASE_URL}/${sticker.packet_id}/${sticker.sticker_id}/thumbnail_mobile.webp`.toLowerCase(),
            },
            icon: null,
            title: "",
            type: "",
            hints: sticker.hints,
            height: sticker.height,
            width: sticker.width,
            packet_id: sticker.packet_id,
            sticker_id: sticker.sticker_id,
          };
        }),
      };
    });

    const _category = result.response.packets.map((packet) => {
      return {
        ariaLabel: packet.name.value,
        title: packet.author_name.value,
        type: packet.type,
        ariaSelected: true,
        image: {
          pc: `${IMAGE_BASE_URL}/${packet.packet_id}/main_pc.webp`.toLowerCase(),
          mobile:
            `${IMAGE_BASE_URL}/${packet.packet_id}/main_mobile.webp`.toLowerCase(),
          thumb:
            `${IMAGE_BASE_URL}/${packet.packet_id}/thumbnail_mobile.webp`.toLowerCase(),
        },
        icon: null,
        packet_id: packet.packet_id,
        sticker_id: null,
        categoryTier: packet.tier,
      };
    });

    return {
      list: _list,
      category: _category,
    };
  },
);

export const updateRecent = createAction(`${NS}/updateRecent`);
export const setDefault = createAction(`${NS}/setDefault`);
export const setRecent = createAction(`${NS}/setRecent`);

export const fetchStickerRecent = createAsyncThunk(
  `${NS}/fetchStickerRecent`,
  async (_, thunkAPI) => {
    const result = await StickerApi.fetchStickerRecent();

    const list = [
      {
        categoryName: "Recent",
        categoryFullName: "Recent",
        list:
          result?.response?.stickers
            ?.map((sticker) => {
              return {
                ariaLabel: "",
                image: {
                  pc: `${IMAGE_BASE_URL}/${sticker.packet_id}/${sticker.sticker_id}/main_pc.webp`.toLowerCase(),
                  mobile:
                    `${IMAGE_BASE_URL}/${sticker.packet_id}/${sticker.sticker_id}/main_mobile.webp`.toLowerCase(),
                  thumb:
                    `${IMAGE_BASE_URL}/${sticker.packet_id}/${sticker.sticker_id}/thumbnail_mobile.webp`.toLowerCase(),
                },
                icon: null,
                title: "",
                type: "",
                hints: sticker.hints,
                height: sticker.height,
                width: sticker.width,
                packet_id: sticker.packet_id,
                sticker_id: sticker.sticker_id,
              };
            })
            .slice(0, 20) || [],
      },
    ];
    const len = result?.response?.stickers?.length;

    thunkAPI.dispatch(
      updateRecent({
        list,
        recentLen: len,
      }),
    );

    return {
      list,
      recentLen: len,
    };
  },
);

export const stickerSlice = createSlice({
  name: NS,
  initialState,
  reducers: {
    selectedSticker: (state, {payload}) => {
      if (!payload) {
        state.currentSticker = null;
      } else {
        state.currentSticker = {
          packet_id: payload.packet_id,
          sticker_id: payload.sticker_id,
        };
      }
    },
    setSearchText: (state, {payload}) => {
      state.search.searchText = payload;
    },
    updateRecent: (state, {payload}) => {
      const {list, recentLen} = payload;
      state.list = [...list, ...state.default.list];
    },
    setDefault: (state, {payload}) => {
      const {list, category} = payload;
      state.default.list = list;
      state.default.category = category;
    },
    setRecent: (state, {payload}) => {
      const {list, category} = payload;
      state.recent.list = list;
      state.recent.category = category;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.pending, (state, {payload}) => {
      state.loading = true;
    });
    builder.addCase(fetchData.fulfilled, (state, {payload}) => {
      state.loading = false;
      state.list = payload.list;
      state.category = payload.category;
      state.recentLen = payload.recentLen;
    });

    builder.addCase(fetchStickersSearch.pending, (state, {payload}) => {
      state.search.loading = true;
    });

    builder.addCase(fetchStickersSearch.fulfilled, (state, {payload}) => {
      state.search.list = payload;
      state.search.loading = false;
    });

    builder.addCase(fetchStickersDefault.pending, (state, {payload}) => {
      state.default.loading = true;
    });
    builder.addCase(fetchStickersDefault.fulfilled, (state, {payload}) => {
      const {list, category} = payload;
      state.default.list = list;
      state.default.category = category;
      state.default.loading = false;
    });

    builder.addCase(fetchStickerRecent.pending, (state, {payload}) => {
      state.recent.loading = true;
    });

    builder.addCase(fetchStickerRecent.fulfilled, (state, {payload}) => {
      const {list} = payload;
      state.recent.list = list;
      state.recent.loading = false;
    });
  },
});

export const {setSearchText} = stickerSlice.actions;
