import {getRecommend} from "./getRecommend";
// import {getSearch} from "./getSearch";

// let fallback = false;
export const recommendFallback = async (
  switcher = {fallback: true, autoCompletion: true},
  thunkAPI,
) => {
  try {
    // if (fallback) return await getSearch("", thunkAPI, switcher);
    const res = await getRecommend(null, thunkAPI, switcher);
    // if (res?.posts?.length && !res?.cursor) {
    //   fallback = true;
    // }
    // if (!res?.posts?.length) {
    //   fallback = true;
    //   return await getSearch("", thunkAPI, switcher);
    // }
    return res;
  } catch (error) {
    console.error(error);
  }
};
