import {LinearProgress} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {useNavigate} from "react-router-dom";
import {PopupDialogV3} from "src/app/components/PopupDialogV3";

import Spinner from "src/styles/components/Spinner/Spinner";

const useStyles = makeStyles((theme) => ({
  defaultLoaderWrapper: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.newColors.overlayWhite[90]
        : theme.palette.newColors.overlayBlack[90],
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    zIndex: 100,
  },
  embeddedLoaderWrapper: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    textAlign: "center",
  },
  inlineEmbeddedLoaderWrapper: {
    display: "inline-block",
    verticalAlign: "top",
    margin: theme.spacing(0, 0.5),
  },
  loader: {
    margin: "auto",
    height: 24,
    width: 24,
    "& circle:nth-child(odd)": {
      stroke: theme.palette.newColors.red[200],
    },
    "& circle:nth-child(even)": {
      stroke: theme.palette.newColors.red[500],
    },
  },
  linearProgress: {
    margin: "auto",
    height: 4,
    width: 320,
    backgroundColor: theme.palette.newColors.backgroundBg[5],
    borderRadius: 8,
    "& .MuiLinearProgress-barColorPrimary": {
      borderRadius: 8,
      backgroundColor: theme.palette.newColors.red[600],
    },
  },
  popupLoaderWrapper: {
    "& .popup-content": {
      width: "394px !important",
      minWidth: 554,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& .header": {
        border: "none",
        "& .icon.cancel": {
          top: "18px",
          right: "18px",
        },
      },
      "& .content": {
        textAlign: "center",
        padding: "0 0 36px !important",
        "& .tips": {
          fontSize: "20px",
          fontWeight: 800,
          padding: theme.spacing(5),
          color: theme.palette.text.primary,
        },
        "& .MuiLinearProgress-root": {
          margin: "8px auto",
          height: "4px !important",
        },
        "& svg": {
          color: ["light", "dark"].includes(theme.type)
            ? theme.palette.newColors.systemBlue[500]
            : theme.palette.newColors.red[600],
          "& path": {
            fill: ["light", "dark"].includes(theme.type)
              ? theme.palette.newColors.systemBlue[500]
              : theme.palette.newColors.red[600],
          },
        },
      },
      [theme.breakpoints.only("xs")]: {
        minWidth: 365,
        width: "100% !important",
        "& .tips": {
          padding: "36px 36px 32px 36px !important",
        },
      },
    },
  },
  loaderWrapper: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: 0,
    "& svg": {
      fill: "none !important",
    },
    "& .tips": {
      fontSize: "20px",
      fontWeight: 800,
      padding: "24px",
      color: theme.palette.text.primary,
      width: "100%",
      position: "absolute",
      top: "40%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    "& .MuiLinearProgress-root": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
  buttonLoadingColor: {
    alignItems: "center",
    "& svg": {
      color: theme.palette.utility.white,
      "& path": {
        fill: ["light", "dark"].includes(theme.type)
          ? theme.palette.newColors.systemBlue[500]
          : theme.palette.newColors.red[600],
      },
    },
  },
  loadingText: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.31,
    marginTop: 16,
    textAlign: "center",
  },
}));

/**
 *
 * @param {*} param0
 * @returns
 */
export const GLoader = ({
  progress,
  isPopup,
  isEmbedded,
  tips,
  wrapperStyle,
  notCloseOnDocumentClick,
  withBorderRadius,
  isButton,
  color,
  size = 20,
  text = null,
  popupRootClassName = "",
  inline,
}) => {
  const classes = useStyles({isButton});
  const navigate = useNavigate();
  const loaderContent = (
    <>
      {progress > -1 ? (
        <>
          <LinearProgress
            variant="determinate"
            value={progress}
            className={classes.linearProgress}
            size={41}
            thickness={2}
          />
          {text && <p className={classes.loadingText}>{text}</p>}
        </>
      ) : isButton ? (
        <Spinner
          className={classes.loader}
          style={{
            width: size || 60,
            height: size || 60,
          }}
          tabIndex={0}
        />
      ) : (
        <>
          <Spinner
            className={classes.loader}
            style={{
              width: size || 60,
              height: size || 60,
            }}
            tabIndex={0}
          />
          {text && <p className={classes.loadingText}>{text}</p>}
        </>
      )}
    </>
  );

  return isEmbedded ? (
    <div
      className={clsx(
        inline
          ? classes.inlineEmbeddedLoaderWrapper
          : classes.embeddedLoaderWrapper,
      )}
    >
      {color ? (
        <>
          <Spinner
            className={classes.loader}
            style={inline ? {width: `${size}px`, height: `${size}px`} : {}}
            tabIndex={0}
          />
          {text && <p className={classes.loadingText}>{text}</p>}
        </>
      ) : (
        <>
          <Spinner
            className={classes.loader}
            style={{
              width: size || 60,
              height: size || 60,
            }}
            tabIndex={0}
          />
          {text && <p className={classes.loadingText}>{text}</p>}
        </>
      )}
    </div>
  ) : !isPopup ? (
    <div
      style={{...wrapperStyle, borderRadius: withBorderRadius ? 6 : 0}}
      className={clsx(
        "defaultLoaderWrapper",
        !wrapperStyle ? classes.defaultLoaderWrapper : "",
      )}
    >
      <div className={clsx(classes.loaderWrapper, classes.buttonLoadingColor)}>
        {tips ? <h5 className="tips">{tips}</h5> : null}
        {loaderContent}
      </div>
    </div>
  ) : (
    <div className={clsx(classes.popupLoaderWrapper, popupRootClassName)}>
      <PopupDialogV3
        nested={true}
        open={true}
        // hasOK={true}
        navigate={navigate}
        // hasCancel={true}
        // needDoNothing={true}
        parentComp={this}
        trigger={<div />}
        closeOnDocumentClick={!notCloseOnDocumentClick}
        classes={{
          root: clsx(classes.popupLoaderWrapper, popupRootClassName),
        }}
      >
        <h5 className="tips">{tips}</h5>
        {loaderContent}
      </PopupDialogV3>
    </div>
  );
};
