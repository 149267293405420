import {v4 as uuid} from "uuid";
import AppConsts from "src/app/AppConsts";
import GAxios from "src/util/GAxios";
import {isResponseOk} from "src/util/FeedUtils";
import {isEmpty} from "lodash-es";
import {renderToStaticMarkup} from "react-dom/server";
import {imgMaxHeight} from "src/app/components/post/comps/FeedItemContent/FeedItemImageContent";

export const blobToBase64 = (value) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      if (typeof reader.result === "string") {
        resolve(reader.result);
      }
    };
    reader.readAsDataURL(value);
  });

export const b64toBlob = (dataURI, type) => {
  const byteString = atob(dataURI.split(",")[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([ab], {type});
  return URL.createObjectURL(blob);
};

export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const readImgWidthHeight = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      let image = new Image();
      image.src = e.target.result;
      image.onload = () => {
        resolve([image.width, image.height]);
      };
      image.onerror = () => {
        resolve([image.width, image.height]);
      };
    };
    reader.onerror = (error) => reject(error);
  });
};

export function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

export function zoomImage(url = "", width = 768, height = 0) {
  if (
    typeof url !== "string" ||
    !url ||
    !(url.startsWith && url.startsWith("group"))
  ) {
    return url;
  }
  return url
    .replace(/_[0-9]{1,4}x[0-9]{1,4}(\.[a-z]{3,4})$/i, "$1")
    .replace(
      /(\.[^\.]+$)/,
      `_${Number(width).toFixed()}x${Number(height).toFixed()}$1`,
    );
}

export function checkMediaUrl(url) {
  let newURL;
  try {
    newURL = new URL(url);
    newURL = newURL.pathname + newURL.search;
    return newURL;
  } catch (error) {
    return url;
  }
}

export function handleMediaUrl(server, url, allowExternal = false) {
  if (!url || typeof url !== "string") {
    return undefined;
  }
  if (url.startsWith("/")) {
    return server + url;
  }
  if (url.startsWith("https://") || url.startsWith("http://")) {
    try {
      const hostname = new URL(url).hostname;
      if (
        allowExternal ||
        hostname.endsWith("giphy.com") ||
        hostname.endsWith("twimg.com") ||
        //Note: made the media URL compatible with the third party transcode way using by the media server
        hostname.endsWith("getter-dev.tk") ||
        // new added for the dm's avatar images(Env: prod)
        hostname.endsWith("getter.com") ||
        hostname.endsWith("gettr.com") ||
        // new added for the dm's avatar images(Env: qa4/qa3)
        hostname.endsWith("getter-sg.tk") ||
        // Maestro media
        hostname.endsWith("videostreamer.net") ||
        hostname.endsWith("gettr-qa.com") ||
        // dm media
        hostname.endsWith("stream-io-cdn.com")
      ) {
        return url;
      }
      return undefined;
    } catch (e) {
      return undefined;
    }
  }
  return server + "/" + url;
}
export function handleMediaUrlFromStreamId(streamId, callback) {
  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/u/post/live/${streamId}`,
  };
  GAxios(
    config,
    (res) => {
      if (isResponseOk(res)) {
        callback(res?.data?.result?.url);
      } else {
        callback(null);
      }
    },
    () => {
      callback(null);
    },
  );
}
/**
 *
 * @param {string} dataurl
 * @param {string} filename
 * @returns {File} file
 */
export async function dataURLtoFile(dataurl, filename = "base64image.png") {
  if (/^http/.test(dataurl)) {
    let response = await fetch(dataurl);
    let data = await response.blob();
    const name = dataurl.match(/\/([^/]+)$/)[1];
    let metadata = {
      type: data.type,
    };
    return new File([data], name, metadata);
  } else if (/;base64,/.test(dataurl)) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], `${uuid()}-${filename}`, {type: mime});
  }
  return dataurl;
}

export function handleImgOnError(e) {
  const img = e.target;
  if (img.src.indexOf(AppConsts.URL_UNKNOWN_USER) === -1) {
    img.src = AppConsts.URL_UNKNOWN_USER;
  }
}

export function getVideoDuration(videoFile) {
  return new Promise((resolve) => {
    const video = document.createElement("video");
    video.preload = "metadata";
    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      resolve(video.duration);
    };
    video.onerror = (err) => {
      resolve(0);
    };
    video.src = window.URL.createObjectURL(videoFile?.handle || videoFile);
  });
}

/**
 * Compress Image
 * @param {File} file
 * @param {Number} quality
 * @param {Number} maxSideLength
 * @param {Number} maxFileSize
 * @return {Promise<Blob>}
 */
export const compressImage = ({
  file,
  quality = 0.95,
  maxSideLength = 1200,
  maxFileSize = 5 * 1024 * 1024,
}) => {
  return new Promise((resolve, reject) => {
    if (!/image\/(jpeg|png|bmp|jpg)/.test(file.type)) {
      resolve(file);
      return;
    }
    const name = file.name;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      const src = e.target.result;
      const img = new Image();
      img.src = src;
      img.onload = () => {
        const w = img.width;
        const h = img.height;
        if (file.size < maxFileSize && Math.min(w, h) < maxSideLength) {
          resolve(file);
          return;
        }
        const whRatio = w / h;
        let newW;
        let newH;
        if (w < h) {
          newW = Math.min(maxSideLength, w);
          newH = newW / whRatio;
        } else {
          newH = Math.min(maxSideLength, h);
          newW = newH * whRatio;
        }

        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = newW;
        canvas.height = newH;

        // After PNG is compressed, the background will become black, so need to set white.
        ctx.fillStyle = "#fff";
        ctx.fillRect(0, 0, newW, newH);
        ctx.drawImage(img, 0, 0, newW, newH);
        const base64 = canvas.toDataURL(file.type, quality);
        const bytes = window.atob(base64.split(",")[1]);
        // Handle exceptions and convert the ascii code less than 0 to greater than 0
        const ab = new ArrayBuffer(bytes.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < bytes.length; i++) {
          ia[i] = bytes.charCodeAt(i);
        }
        file = new Blob([ab], {type: file.type});
        file = new window.File([file], name, {type: file.type});
        resolve(file);
      };
      img.onerror = (e) => {
        reject(e);
      };
    };
    reader.onerror = (e) => {
      reject(e);
    };
  });
};

/**
 * Change url to short one
 * @param {Object} options
 * @param {Array}  options.urls
 * @param {Number} options.width
 * @param {Number} options.height
 * @param {Number} options.maxLength
 * @returns short urls
 */
export const getShortUrls = ({
  urls = [],
  width = 0,
  height = 0,
  maxLength = 30,
}) => {
  const result = urls.slice(0, 1).map((url) => {
    let fullUrl = url;
    if (!/^https?:\/\//.test(url)) {
      fullUrl = handleMediaUrl(
        process.env.REACT_APP_MEDIA_BASE,
        /\.(mp4|m3u8)$/.test(url) ? url : zoomImage(url, width, height),
      );
    }
    return (
      fullUrl.slice(0, maxLength) + (fullUrl.length > maxLength ? "..." : "")
    );
  });
  return result;
};

export const getCommentMedaiUrl = ({
  url,
  width = 0,
  height = 0,
  maxLength = 30,
}) => {
  let fullUrl = url;
  if (!/^https?:\/\//.test(url)) {
    fullUrl = handleMediaUrl(
      "media.gettr.com",
      /\.(mp4|m3u8)$/.test(url) ? url : zoomImage(url, width, height),
    );
  } else {
    fullUrl = handleMediaUrl("media.gettr.com", url && url.slice(8));
  }
  return (
    fullUrl.slice(0, maxLength) + (fullUrl.length > maxLength ? "..." : "")
  );
};

/**
 * Get images from a video
 * @param {String} url video url
 * @param {Number} count image count
 * @param {Number} width image width, 0 means original width
 * @param {Number} height image height, 0 means original height
 * @returns {Promise<Array>}
 */
export const getVideoImages = async (url, count = 5, width = 0, height = 0) => {
  return new Promise((resolve) => {
    const video = document.createElement("video");
    video.setAttribute("crossorigin", "anonymous");
    video.muted = true;

    video.onloadedmetadata = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = width || video.videoWidth;
      canvas.height = height || video.videoHeight;
      const images = [];
      const duration = video.duration;
      const step = duration / count;
      let time = 0.01;
      const draw = () => {
        setTimeout(() => {
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          images.push(canvas.toDataURL("image/jpeg"));
          time += step;
          if (time < duration) {
            video.currentTime = time.toFixed(1);
          } else {
            resolve(images);
          }
        }, 10);
      };
      video.onseeked = draw;
      video.onended = draw;
      video.currentTime = time;
      video.onerror = (error) => {
        console.error(error);
        resolve([]);
      };
    };

    video.src = url;
  });
};

/**
 * Get poster of video
 * @param {String} path
 * @param {Number | Function} secs
 * @param {Function} callback
 */
export const getVideoImage = (path, secs = 0) => {
  return new Promise((resolve) => {
    var me = this,
      video = document.createElement("video");
    video.setAttribute("crossorigin", "anonymous");
    video.onloadedmetadata = function () {
      if ("function" === typeof secs) {
        secs = secs(this.duration);
      }
      this.currentTime = Math.min(
        Math.max(0, (secs < 0 ? this.duration : 0) + secs),
        this.duration,
      );
    };
    video.onseeked = function (e) {
      var canvas = document.createElement("canvas");
      if (video.videoWidth > 3840) {
        canvas.height = (3840 * video.videoHeight) / video.videoWidth;
        canvas.width = 3840;
      }
      if (video.videoHeight > 2160) {
        canvas.width = (2160 * video.videoWidth) / video.videoHeight;
        canvas.height = 2160;
      } else {
        canvas.height = video.videoHeight;
        canvas.width = video.videoWidth;
      }
      var ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      var img = new Image();
      img.src = canvas.toDataURL();
      resolve(img.src);
    };
    video.onerror = function (e) {
      console.error(e);
      resolve(undefined);
    };
    video.src = path;
  });
};

export const getImageStyleFromMetadata = (
  imageMetadata,
  containerWidth,
  containerHeight,
) => {
  const {
    wid,
    hgt,
    meta: {heads},
  } = imageMetadata;

  if (!containerWidth || !containerHeight || !heads?.length) {
    return {objectPosition: "50% 50%"};
  }
  containerWidth -= 2; // 2px border
  containerHeight -= 2; // 2px border

  const getRealDimensions = (imageWidth, imageHeight) => {
    return [containerWidth, (containerWidth / imageWidth) * imageHeight];
  };

  const availableSpaceToMove = (realImageHeight) => {
    return (realImageHeight - containerHeight) / 2;
  };

  const pixelsToCenter = (
    headTop,
    headBottom,
    imageHeight,
    realImageHeight,
  ) => {
    const headCenter = (headTop + headBottom) / 2 / imageHeight; // decimal value relative head center
    const headPositionInRealImage = headCenter * realImageHeight;
    return realImageHeight / 2 - headPositionInRealImage;
  };

  const [x, headTop, x2, headBottom] = heads[0].box;

  const [_, realHeight] = getRealDimensions(wid, hgt);

  const toCenter = pixelsToCenter(headTop, headBottom, hgt, realHeight);
  const availableSpace = availableSpaceToMove(realHeight);

  let pixels = 0;

  if (availableSpace <= 0) {
    return {objectPosition: "50% 50%"};
  }

  if (toCenter >= 0) {
    pixels = Math.min(availableSpace, toCenter);
  } else {
    pixels = Math.max(availableSpace * -1, toCenter);
  }

  return {objectPosition: `50% ${pixels}px`};
};

export const getTiledImageStyleFromMetadata = (
  imageMetadata,
  containerWidth,
  containerHeight,
) => {
  if (!imageMetadata || isEmpty(imageMetadata)) {
    return {height: "100%"};
  }

  const {wid, hgt, meta} = imageMetadata;
  const {heads} = meta ?? [];

  if (!containerWidth || !containerHeight || !heads?.length) {
    return {height: "100%"};
  }

  const getRealDimensions = (imageWidth, imageHeight) => {
    return [containerWidth, (containerWidth / imageWidth) * imageHeight];
  };

  const availableSpaceToMove = (realImageHeight) => {
    return containerHeight - realImageHeight;
  };

  const pixelsToCenter = (
    headTop,
    headBottom,
    imageHeight,
    realImageHeight,
  ) => {
    const headCenter = (headTop + headBottom) / 2 / imageHeight; // decimal value relative head center
    const headPositionInRealImage = headCenter * realImageHeight;
    const containerCenter = containerHeight / 2;
    return containerCenter - headPositionInRealImage;
  };

  const [x, headTop, x2, headBottom] = heads[0].box;

  const [_, realHeight] = getRealDimensions(wid, hgt);

  if (realHeight < containerHeight) {
    return {height: "100%"};
  }

  const toCenter = pixelsToCenter(headTop, headBottom, hgt, realHeight);
  const availableSpace = availableSpaceToMove(realHeight);

  if (toCenter >= 0) {
    return {
      objectPosition: `50% 0px`,
    };
  }

  return {
    objectPosition: `50% ${Math.max(toCenter, availableSpace)}px`,
  };
};

export const getImageInfo = (file) => {
  return new Promise((resolve) => {
    var _URL = window.URL || window.webkitURL;
    var objectUrl =
      typeof file === "string" ? file : _URL.createObjectURL(file);
    var img = new Image();
    img.onload = function () {
      resolve({
        width: this.width,
        height: this.height,
      });
      _URL.revokeObjectURL(objectUrl);
    };
    img.onerror = function () {
      resolve({
        width: this.width,
        height: this.height,
      });
      _URL.revokeObjectURL(objectUrl);
    };
    img.src = objectUrl;
  });
};

export const getNewImageDimensions = (ref, oWidth, oHeight) => {
  if (!ref?.current || !oHeight) return "";
  const {clientWidth} = ref.current.wrapper;

  const r = oWidth / oHeight;
  let newHeight = clientWidth / r;

  newHeight = newHeight >= imgMaxHeight ? imgMaxHeight : newHeight;

  return newHeight;
};

export const preloadImage = (() => {
  const cacheImage = {};

  function createImageToCache(url, isGTokPost) {
    if (url && !cacheImage[url]) {
      cacheImage[url] = document.createElement("img");
      cacheImage[url].src = url;
      cacheImage[url].style.width = "100%";
      cacheImage[url].style.position = !isGTokPost && "absolute";
      cacheImage[url].style.zIndex = 1;
      cacheImage[url].style.display = "block";
      cacheImage[url].style.borderRadius = !isGTokPost && "10px";
    }
  }

  function getCacheImage(url) {
    return cacheImage[url];
  }

  return {
    createImageToCache,
    getCacheImage,
  };
})();

export const changeSvgToBase = (iconComp) => {
  return renderToStaticMarkup(iconComp)
    .replace(/</g, "%3C")
    .replace(/>/g, "%3E")
    .replace(/#/g, "%23");
};
