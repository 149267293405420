import {
  isResponseOk,
  parseItemStats,
  parseGTokFeed,
  parseUser,
} from "src/util/FeedUtils";
import GAxios from "src/util/GAxios";
import {updateTrendingGTok} from "../store";
import {addFollowingUserIds} from "src/store/modules/status";
import {
  addLikedPosts,
  addSharedPosts,
  setPostsStats,
} from "src/store/modules/postStats";

const fetchTrendingGTok = async (queryParams = {}) => {
  let resp = null;
  try {
    queryParams = queryParams || {};
    const params = {
      // the max number of items to be returned.
      max: 10,
      offset: 0,
      feedType: "f_tr_vi",
      //  the include options specifies what information should be preloaded before returning result to front end. It is also used as flags in some scenarios to tell backend what type of data to return.
      incl: "visions|posts|postinfo|userinfo|stats|shared|liked|followings|followers",
      ...queryParams,
    };

    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/u/post/v2/trending`,
      params,
    };

    await GAxios(
      config,
      (res) => {
        if (isResponseOk(res)) {
          const {aux, data} = res?.data?.result ?? {};
          const {list} = data ?? {};
          const {lks: likedPosts, removed, shrs: sharedPosts} = aux ?? {};
          const users = parseUser(aux);

          let posts = parseGTokFeed(list, aux);

          let count = posts?.length ?? 0;
          posts = posts?.filter((post) => post != null);
          count = count - (posts?.length ?? 0);
          const postStats = parseItemStats(aux);

          resp = {
            likedPosts,
            posts,
            removed: removed + count,
            users,
            sharedPosts,
            // offset: offset + timelineConstants.MAX_BATCH_SIZE,
            postStats,
            cursor: aux?.cursor || 0,
            fws: aux?.fws ?? [],
            fwr: aux?.fwr ?? [],
          };
        }
      },
      (err) => {
        console.error(err);
      },
    );
  } catch (error) {
    console.error(error);
  }
  return resp;
};

export const getTrendingGTok = async (queryParams = {}, {dispatch}) => {
  queryParams = queryParams || {};

  let totalPosts = [];

  // while (totalPosts?.length < timelineConstants?.AUTO_COMPLETION_SIZE) {
  const res = await fetchTrendingGTok({
    ...queryParams,
  });
  const {posts = [], postStats = {}, cursor: resCursor, removed} = res ?? {};

  // if (posts.length === 0 && !removed) {
  //   break;
  // }

  dispatch(updateTrendingGTok(posts.length ? res : null));

  Object.keys(postStats).length && dispatch(setPostsStats(postStats));
  const {likedPosts, fws, sharedPosts} = res;
  fws?.length && dispatch(addFollowingUserIds(res?.fws));
  likedPosts?.length && dispatch(addLikedPosts(likedPosts));
  sharedPosts?.length && dispatch(addSharedPosts(sharedPosts));

  totalPosts = totalPosts?.concat(posts);
  // }
  return {
    posts: totalPosts,
    cursor: resCursor,
  };
};
