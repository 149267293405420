import {userConstants} from "../_constants";
import GAxios from "src/util/GAxios";

export const logout = async ({userId, token}) => {
  if (!userId) return;

  const data = {
    userId,
  };

  const config = {
    method: "post",
    url: userConstants.API_LOGOUT,
    headers: {
      "Content-Type": "application/json",
    },
    data,
    userId,
    token,
  };

  GAxios(config);
};
