import GAxios from "src/util/GAxios";

export const reportPost = async (data) => {
  const {userId, postId, reasonId} = data;

  if (!userId || !postId || !reasonId) return;

  const reason = `rsn${reasonId}`;

  let params = {content: {}};
  params = JSON.stringify(params);

  const config = {
    ignoreTips: {t429: true},
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/u/user/${userId}/report/post/${postId}/${reason}`,

    data: params,
  };

  let res = null;

  await GAxios(config, (response) => {
    if (response?.data?.result) {
      res = {response};
    } else {
      res = response;
    }
  });

  return res;
};
