import {userConstants} from "../_constants";
import GAxios from "src/util/GAxios";
import {toast} from "react-toastify";
import {NotifMessage} from "src/app/components/notifications/NotifMessage";
import {t} from "src/i18n/utils";
import {setMultiAccount} from "src/util/userUtil";

export const getUUID = async ({successCallback, errorCallback}) => {
  let result = null;
  const config = {
    method: "get",
    url: userConstants.API_QR_GET_UUID,
    headers: {
      "Content-Type": "application/json",
    },
  };

  await GAxios(
    config,
    (response) => {
      successCallback && successCallback(response);
      result = response?.data?.result;
    },
    (err) => {
      errorCallback && errorCallback(err);
      result = null;
    },
  );

  return result;
};

export const getQrImg = async (uuid) => {
  let result = null;
  const config = {
    method: "get",
    url: userConstants.API_QR_GET_IMG + uuid,
    headers: {
      "Content-Type": "image/jpeg",
    },
  };

  await GAxios(
    config,
    (response) => {
      result = response?.data?.result;
    },
    (err) => {
      result = null;
    },
  );

  return result;
};

export const getQrLoginStatus = async (uuid) => {
  const config = {
    method: "get",
    url: userConstants.API_QR_LOGIN + "?uuid=" + uuid,
    headers: {
      "Content-Type": "application/json",
    },
    timeout: 30000,
    // ignoreErrors: true,
  };

  let loginData = {
    authenticated: false,
    userinfo: null,
    uuid: uuid,
    err: {
      code: "E_QR_AUTH_TIMEOUT",
    },
  };

  const callback = async (err, res) => {
    if (err) {
      let errorCode = err?.code;
      loginData.err = err;
      if (["E_QR_AUTH_TIMEOUT"].includes(errorCode)) {
        return "E_QR_AUTH_TIMEOUT";
      } else if (["E_QR_EXPIRED"].includes(errorCode)) {
        return "E_QR_EXPIRED";
      }
    } else if (res) {
      loginData.authenticated = !err;
      loginData.userinfo = res;
      toast(
        <NotifMessage
          msgShort
          message={t("getter_fe.auth.notification.welcome", {
            username: res?.ousername || "",
          })}
          scene="login"
        />,
        {
          position: toast.POSITION.TOP_CENTER,
          type: toast.TYPE.SUCCESS,
        },
      );

      if (loginData.authenticated && res) {
        setMultiAccount(loginData);
      }

      return loginData;
    }
  };

  const processResult = async (result) => {
    let err = null;
    loginData.err = null;

    let authenticated = result["token"];
    let loadedUserInfo = result["user"];

    if (!authenticated) {
      err = new Error("Auth failed");
    } else {
      loadedUserInfo.token = authenticated;
    }
    await callback(err, loadedUserInfo);
  };

  await GAxios(
    config,
    (response) => {
      processResult(response?.data?.result);
    },
    (err) => {
      callback(err?.response?.data?.error);
    },
  );

  return loginData;
};

export const appLogin = async (uuid) => {
  let result = null;
  const config = {
    method: "get",
    url: userConstants.API_QR_APP_LOGIN + uuid,
    headers: {
      "Content-Type": "application/json",
    },
  };

  await GAxios(
    config,
    (response) => {
      result = response?.data?.result;
    },
    (err) => {
      result = null;
    },
  );

  return result;
};
