export const HASHTAG_SPECIAL_SYMBOL = [
  "!",
  '"',
  "#",
  "$",
  "%",
  "&",
  "'",
  "(",
  ")",
  "+",
  ",",
  "-",
  ".",
  "/",
  ":",
  ";",
  "<",
  "=",
  ">",
  "?",
  "@",
  "\\[",
  "\\]",
  "^",
  "_",
  "`",
  "{",
  "|",
  "}",
  "~",
];
export const SPECIAL_SYMBOL = [
  "\\s",
  "&",
  "'",
  "!",
  '"',
  "#",
  "$",
  "%",
  "(",
  ")",
  "*",
  "+",
  ",",
  "\\",
  ".",
  "/",
  ":",
  ";",
  "<",
  "=",
  ">",
  "?",
  "@",
  "\\[",
  "\\]",
  "^",
  "`",
  "{",
  "|",
  "}",
  "~",
  "\\-",
  "\u3002",
  "\uff1f",
  "\uff01",
  "\uff0c",
  "\u3001",
  "\uff1b",
  "\uff1a",
  "\u201c",
  "\u201d",
  "\u2018",
  "\u2019",
  "\uff08",
  "\uff09",
  "\u300a",
  "\u300b",
  "\u3008",
  "\u3009",
  "\u3010",
  "\u3011",
  "\u300e",
  "\u300f",
  "\u300c",
  "\u300d",
  "\ufe43",
  "\ufe44",
  "\u3014",
  "\u3015",
  "\u2026",
  "\u2014",
  "\uff5e",
  "\ufe4f",
  "\uffe5",
];
export const GETTR_EMOJI_SYMBOL = "﹌";
export const NON_SPECIAL_SYMBOL_REG = `[^${SPECIAL_SYMBOL.join("|")}]`;
export const HASHTAG_SPECIAL_SYMBOL_REG = `[^${HASHTAG_SPECIAL_SYMBOL.join(
  "|",
)}]`;
export const HASH_TAG_MATCH_PATTERN = new RegExp(
  `(\\s|^|</emoji>|/>)(#${NON_SPECIAL_SYMBOL_REG}{1,100})`,
  "g",
);
export const SINGLE_HASH_TAG_MATCH_PATTERN = new RegExp(
  `(\\s|^)(#${NON_SPECIAL_SYMBOL_REG}{1,100})`,
);
export const MENTION_MATCH_PATTERN = new RegExp(
  `(</emoji>|[^/]|^|/>)(@${NON_SPECIAL_SYMBOL_REG}+)`,
  "g",
);
export const SINGLE_MENTION_MATCH_PATTERN = new RegExp(
  `(\\s|^)(@${NON_SPECIAL_SYMBOL_REG}+)`,
);
export const GETTR_EMOJI_PATTERN = /\[([a-zA-Z0-9-]+)\]/g;
export const GETTR_EMOJI_PATTERN_SYMBOL_MIXTURE = new RegExp(
  `(\\[([a-zA-Z0-9-]+)\\])|(${GETTR_EMOJI_SYMBOL})`,
  "g",
);
export const GETTR_EMOJI_PATTERN_SYMBOL_MIXTURE_SINGLE = new RegExp(
  `(\\[([a-zA-Z0-9-]+)\\])|(${GETTR_EMOJI_SYMBOL})`,
);
export const GETTR_EMOJI_COPY_STORAGE = "GETTR_EMOJI_COPY_STORAGE";
export const HASHTAG_AND_MENTION_TEST_PATTERN = new RegExp(
  `^(#|@)${NON_SPECIAL_SYMBOL_REG}+$`,
);
