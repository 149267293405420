import {createAction, createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {MaEligibilityCheckApi} from "../api";

const NS = "maEligibilityCheck";

const initialState = {
  showMaLivePEP: null,
  clickStudio: false,
  step: "PEP",
  maestro_pep: {
    maestro_pep_selections: [null, null, null, null],
    maestro_pep_udate: Date.now(),
    maestro_disclaimer_selections: [null],
    maestro_disclaimer_udate: Date.now(),
  },
  showMaNewDot: false,
  saveCountryRegionLoading: false,
  PEPLoading: false,
  streamingMini: null,
};

export const saveCountryRegion = createAsyncThunk(
  `${NS}/saveCountryRegion`,
  MaEligibilityCheckApi.saveCountryRegion,
);

export const setPEPPopConfig = createAsyncThunk(
  `${NS}/setPEPPopConfig`,
  MaEligibilityCheckApi.setPEPPopConfig,
);

export const setMaEligibilityStep = createAction(`${NS}/setMaEligibilityStep`);
export const setMaestroPep = createAction(`${NS}/setMaestroPep`);
export const setShowMaLivePEP = createAction(`${NS}/setShowMaLivePEP`);
export const setClickStudio = createAction(`${NS}/setClickStudio`);
export const setShowMaNewDot = createAction(`${NS}/setShowMaNewDot`);
export const setStreamingMini = createAction(`${NS}/setStreamingMini`);

export const maEligibilityCheckSlice = createSlice({
  name: NS,
  initialState,
  reducers: {
    setMaEligibilityStep: (state, {payload}) => {
      state.step = payload;
    },
    setMaestroPep: (state, {payload}) => {
      state.maestro_pep = payload;
    },
    setShowMaLivePEP: (state, {payload}) => {
      state.showMaLivePEP = payload;
    },
    setClickStudio: (state, {payload}) => {
      state.clickStudio = payload;
    },
    setShowMaNewDot: (state, {payload}) => {
      state.showMaNewDot = payload;
    },
    setStreamingMini: (state, {payload}) => {
      state.streamingMini = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveCountryRegion.pending, (state, {payload}) => {
      state.saveCountryRegionLoading = true;
    });
    builder.addCase(saveCountryRegion.fulfilled, (state, {payload}) => {
      state.saveCountryRegionLoading = false;
    });
    builder.addCase(saveCountryRegion.rejected, (state, {payload}) => {
      state.saveCountryRegionLoading = false;
    });
    builder.addCase(setPEPPopConfig.pending, (state, {payload}) => {
      state.PEPLoading = true;
    });
    builder.addCase(setPEPPopConfig.fulfilled, (state, {payload}) => {
      state.PEPLoading = false;
    });
    builder.addCase(setPEPPopConfig.rejected, (state, {payload}) => {
      state.PEPLoading = false;
    });
  },
});
