import GAxios from "src/util/GAxios";
import {userConstants} from "../_constants";
import parsePhoneNumber from "libphonenumber-js";

export const bindSms = async (params) => {
  const {phone, code, callback} = params;
  if (!code || !phone) return;

  const whatsapp = localStorage.getItem("Whatsapp");
  const isWhatsapp = whatsapp === "true";
  const smsParam = !isWhatsapp ? {sms: "+" + phone} : {whatsapp: "+" + phone};

  const data = JSON.stringify({
    content: {
      ...smsParam,
      countrycode: "+" + parsePhoneNumber("+" + phone).countryCallingCode,
      code,
    },
  });

  const config = {
    method: "post",
    url: userConstants.API_RECOVERY_SMS,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  let res = false;

  await GAxios(config, (response) => {
    if (response && response.data?.result?.success) {
      res = code;
    } else {
      res = false;
    }
    callback(response);
  });

  return res;
};

export const bindSmsV2 = async (params) => {
  const {phone, code, successCallback, errorCallback} = params;
  if (!code || !phone) return;

  const whatsapp = localStorage.getItem("Whatsapp");
  const isWhatsapp = whatsapp === "true";
  const smsParam = !isWhatsapp ? {sms: "+" + phone} : {whatsapp: "+" + phone};

  const data = JSON.stringify({
    content: {
      ...smsParam,
      // countrycode: "+" + parsePhoneNumber("+"+phone).countryCallingCode,
      code,
    },
  });

  const config = {
    method: "post",
    url: userConstants.API_RECOVERY_SMS_V2,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  let res = false;

  await GAxios(
    config,
    (response) => {
      if (response && response.data?.result) {
        res = response.data?.result.vtoken;
      } else {
        res = false;
      }
      localStorage.removeItem("Whatsapp");
      successCallback && successCallback(response);
    },
    (err) => {
      // res = verifCodeErrorCallback(err);
      localStorage.removeItem("Whatsapp");
      errorCallback && errorCallback(err);
    },
  );

  return res;
};
