import GAxios from "src/util/GAxios";
import {getLang} from "src/i18n/utils";

export const fetchLiveSearchChoices = async (data) => {
  let {max, phrase} = data;

  if (!max || !phrase) return;

  if (phrase.startsWith("#") || phrase.startsWith("@")) {
    phrase = phrase.substring(1);
  }
  if (phrase.startsWith("%23") || phrase.startsWith("%40")) {
    phrase = phrase.substring(3);
  }

  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/u/search/v2/stream/choices`,
    data: {
      content: {
        incl: "poststats|shared|liked|posts|userinfo|followings|followers|islive|hasnewlive",
        phrase: phrase,
        count: max,
        lang: getLang(),
      },
    },
    ignoreTips: {t429: true},
  };

  let res = {err: null, response: null};

  await GAxios(
    config,
    (response) => {
      if (response?.data?.result) {
        res = {
          list: response?.data?.result?.data?.list,
        };
      }
    },
    (err) => {
      res = err;
    },
  );

  return res;
};
