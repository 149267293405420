import GAxios from "src/util/GAxios";
import {convertSearchFilters} from "../../SearchFilter/utils";
import {SEARCH_APIS} from "../_constant";

export const fetchPhotoPostListItems = (data, cursor, max) => {
  const q = data.q;
  const filter = convertSearchFilters(data);
  const content = {
    incl: "posts|stats|userinfo|images|shared|liked",
    q,
    filter,
    offset: 0,
    max,
  };

  if (cursor) {
    content.cursor = cursor;
  }

  return new Promise((resolve, reject) => {
    GAxios(
      {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}${SEARCH_APIS.POST}`,
        data: {
          content,
        },
      },
      (res) => {
        resolve(res);
      },
      (err) => {
        reject(err);
      },
    );
  });
};
