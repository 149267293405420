import GAxios from "src/util/GAxios";

export const endorsementSize = async (data) => {
  const {userId} = data;
  if (!userId) return;

  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/gid/endorsements/${userId}/size`,
    ignoreErrors: true,
  };

  let res = null;

  await GAxios(
    config,
    (response) => {
      res = response?.data?.result || null;
    },
    (err) => {
      res = null;
      console.log(err?.response?.data?.error?.code);
    },
  );

  return res;
};
