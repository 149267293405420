export const SUGGESTION_MODES = {
  RECENT: 1,
  RESULT: 2,
};

export const MAX_LIST_ITEMS = 5;

export const SUGGESTION_TYPES = {
  ALL: 0,
  RECENT: 1,
  HASHTAG: 2,
  USER: 3,
  SUGGESTION: 4,
};

export const NEW_LOCAL_STORAGE_RECENT_SEARCH = "newLocalStorageRecentSearch";

export const SEARCH_APIS = {
  POST: "/u/search/v2/post",
  POST_V1: "/u/posts/srch/phrase",
  PROFILE_V2: "/u/search/v2/profile",
  PROFILE_V3: "/u/search/v3/profile",
};

export const SEARCH_TYPES = {
  TOP: "top",
  LATEST: "latest",
  PEOPLE: "people",
  VISIONS: "visions",
  PHOTOS: "photos",
  VIDEOS: "videos",
  PROFILE: "profile",
  HASHTAG: "hashtag",
};
