import {t} from "src/i18n/utils";
import {showToast} from "./showToast";
import {TimeUtil} from "./TimeUtil";

export const errorCodeTable = (error) => {
  let untilTime = TimeUtil.ErrorMsgUntilTime(
    error && error?.args && error?.args.length ? error?.args[0] : Date.now(),
  );
  return {
    // error.code: toastMessage
    E_BAD_DATA: t("getter_fe.auth.errors.E_BAD_DATA"),
    E_BAD_VCODE: t("getter_fe.auth.errors.E_BAD_VCODE"),
    E_BAD_TOKEN: t("getter_fe.auth.errors.E_BAD_TOKEN"),
    E_BAD_USERNAME: t("getter_fe.auth.errors.E_BAD_USERNAME"),
    E_CAPTCHA_ERROR: t("getter_fe.auth.errors.recaptchaFailed"),
    E_AUTH: t("getter_fe.auth.errors.E_AUTH"),
    E_USER_NOTFOUND: t("getter_fe.auth.errors.E_USER_NOTFOUND"),
    E_OVERLIMIT_VCODE: t("getter_fe.auth.errors.E_OVERLIMIT_VCODE"),
    E_METER_LIMIT_EXCEEDED: t("getter_fe.auth.errors.E_METER_LIMIT_EXCEEDED"),
    E_EMAIL_EXISTS: t("getter_fe.auth.errors.emailExist"),
    E_USER_BOUND_EMAIL: t("getter_fe.auth.errors.E_USER_BOUND_EMAIL"),
    E_SMS_EXISTS: t("getter_fe.auth.errors.phoneExist"),
    E_USER_BOUND_SMS: t("getter_fe.auth.errors.E_USER_BOUND_SMS"),
    E_EMAIL_PROVIDER_BLACKLISTED: t(
      "getter_fe.auth.errors.E_EMAIL_PROVIDER_BLACKLISTED",
    ),
    E_EMAIL_NOTFOUND: t("getter_fe.auth.errors.E_EMAIL_NOTFOUND"),
    E_BAD_INPUT: t("getter_fe.auth.errors.E_BAD_INPUT"),
    E_BAD_ARGS: t("getter_fe.auth.errors.emailInvalid"),
    E_BAD_EMAIL: t("getter_fe.auth.errors.emailInvalid"),
    E_BAD_SMS: t("getter_fe.auth.errors.phoneInvalid"),
    E_SYSERR: t("getter_fe.auth.errors.E_SYSERR"),
    E_USER_BANNED_LOGIN: t("getter_fe.auth.errors.suspendedUserLogin2", {
      time: untilTime,
    }),
    E_USER_SUSPENDED: t("getter_fe.auth.errors.accountBanned"),
    E_USER_BANNED: t("getter_fe.auth.errors.E_USER_BANNED2", {
      time: untilTime,
    }),
    E_POST_EMPTY_CONTENT: t("getter_fe.auth.errors.E_POST_EMPTY_CONTENT"),
    E_RES_NOTFOUND: t("getter_fe.auth.errors.E_RES_NOTFOUND"),
    E_POST_OWNER_NO_MATCH: t("getter_fe.auth.errors.E_POST_OWNER_NO_MATCH"),
    E_POST_NO_VISION_CONTENT: t(
      "getter_fe.auth.errors.E_POST_NO_VISION_CONTENT",
    ),
    E_POST_CONTENT_EXCEEDING_LIMIT: t(
      "getter_fe.auth.errors.E_POST_CONTENT_EXCEEDING_LIMIT",
    ),
    E_POST_CONTENT_MODERATION_FAIL: t(
      "getter_fe.auth.errors.E_POST_CONTENT_MODERATION_FAIL",
    ),
    E_W1: t("getter_fe.auth.errors.E_W1"),
    E_BAD_PARAMS: t("getter_fe.auth.errors.E_BAD_PARAMS"),
    E_POST_DELETED: t("getter_fe.auth.errors.E_POST_DELETED"),
    E_COMMENT_DELETED: t("getter_fe.auth.errors.E_COMMENT_DELETED"),
    E_CHAT_FORBIDDEN: t("getter_fe.auth.errors.E_CHAT_FORBIDDEN"),
    E_CHAT_INVALID_PARAMS: t("getter_fe.auth.errors.E_CHAT_FORBIDDEN"),
    E_USER_BLOCKED: t("getter_fe.common.errorBlockedMaLive1"),
    E_PWD_BAD: t("getter_fe.settings.deleteAccount.invalidPwd"),
    // gettrPayError
    NO_RECENT_TRANSACTION: t("getter_fe.gettrPay.errors.NO_RECENT_TRANSACTION"),
    WALLET_INFO_NOT_FOUND: t("getter_fe.gettrPay.errors.WALLET_INFO_NOT_FOUND"),
    INSUFFICIENT_BALANCE: t("getter_fe.gettrPay.errors.INSUFFICIENT_BALANCE"),
    FAILED_TO_SEND_TOKEN: t("getter_fe.gettrPay.errors.FAILED_TO_SEND_TOKEN"),
    FAILED_TO_GENERATE_PAYMENT_LINK: t(
      "getter_fe.gettrPay.errors.FAILED_TO_GENERATE_PAYMENT_LINK",
    ),
    DAILY_LIMIT_REACHED: t("getter_fe.gettrPay.errors.DAILY_LIMIT_REACHED"),
    TXN_AMOUNT_LIMIT_REACHED: t(
      "getter_fe.gettrPay.errors.TXN_AMOUNT_LIMIT_REACHED",
    ),
    RPC_NOT_WORKING_FINE: t("getter_fe.gettrPay.errors.RPC_NOT_WORKING_FINE"),
    FAILED_TO_GET_PAYMENT_REQUEST_DETAILS: t(
      "getter_fe.gettrPay.errors.FAILED_TO_GET_PAYMENT_REQUEST_DETAILS",
    ),

    INVALID_PASSCODE: t("getter_fe.gettrPay.errors.INVALID_PASSCODE"),
    INVALID_REQUEST: t("getter_fe.gettrPay.errors.INVALID_REQUEST"),
    CREATE_WALLET_FAILED: t("getter_fe.gettrPay.errors.CREATE_WALLET_FAILED"),
    WALLET_DETAILS_NOT_FOUND: t(
      "getter_fe.gettrPay.errors.WALLET_DETAILS_NOT_FOUND",
    ),
    FAILED_TO_FETCH_WALLET_BALANCE: t(
      "getter_fe.gettrPay.errors.FAILED_TO_FETCH_WALLET_BALANCE",
    ),
    FAILED_TO_FETCH_WALLETS: t(
      "getter_fe.gettrPay.errors.FAILED_TO_FETCH_WALLETS",
    ),
    FAILED_TO_GET_KEYS: t("getter_fe.gettrPay.errors.FAILED_TO_GET_KEYS"),
    FAILED_TO_SEND_TECHNICAL_ISSUE: t(
      "getter_fe.gettrPay.errors.FAILED_TO_SEND_TECHNICAL_ISSUE",
    ),
    FAILED_TO_GET_CONVERSION_RATE: t(
      "getter_fe.gettrPay.errors.FAILED_TO_GET_CONVERSION_RATE",
    ),
    // E_NOT_ALLOWED: t("getter_fe.gettrPay.errors.WALLET_INFO_NOT_FOUND"),
    INVALID_AMOUNT: t("getter_fe.gettrPay.errors.INVALID_AMOUNT"),
    INVALID_PAYMENT_REQUEST: t(
      "getter_fe.gettrPay.errors.INVALID_PAYMENT_REQUEST",
    ),
    SESSION_EXPIRED: t("getter_fe.gettrPay.errors.WALLET_SESSION_EXPIRED"),
    SENDER_DAILY_LIMIT_REACHED: t(
      "getter_fe.gettrPay.errors.SENDER_LIMIT_REACHED",
    ),
    E_NOT_ALLOWED: t("getter_fe.gettrPay.errors.E_NOT_ALLOWED"),
  };
};

export const handleErrorCodes = (error) => {
  let table = {};
  let err;
  if (error?.response?.data?.error?.code) {
    err = error?.response?.data?.error;
  } else if (error?.code) {
    err = error;
  }
  if (err?.code === "E_BAD_PARAMS" && err?.emsg === "Empty user ID") {
    showToast("warning", t("getter_fe.auth.errors.Empty_user_ID"));
    return true;
  }
  if (err?.code === "WALLET_ALREADY_LOGGED_OUT") {
    return true;
  }
  table = errorCodeTable(err);
  for (const key in table) {
    if (err?.code === key) {
      let toastMessage = table[key];
      showToast("warning", toastMessage, {
        autoClose: ["E_USER_BANNED"].includes(err?.code) ? 7000 : 5000,
      });
      return true;
    }
  }
  showToast("warning", t("getter_fe.auth.errors.somethingWentWrong"));
  return false;
};
