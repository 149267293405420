import {aiAddImages} from "./aiAddImages";
import {aiGetCredits} from "./aiGetCredits";
import {aiDeleteImages} from "./aiDeleteImages";
import {aiFeedback} from "./aiFeedback";
import {aiGenerate} from "./aiGenerate";
import {aiGetImages} from "./aiGetImages";
import {aiGetOriginalImages} from "./aiGetOriginalImages";
import {aiVerifyImage} from "./aiVerifyImage";
import {aiCheckAllowed} from "./aiCheckAllowed";
import {aiRegenerate} from "./aiRegenerate";
import {aiGetRegenerateLimit} from "./aiGetRegenerateLimit";

export const AIAvatarApi = {
  aiAddImages,
  aiGetCredits,
  aiDeleteImages,
  aiFeedback,
  aiGenerate,
  aiGetImages,
  aiGetOriginalImages,
  aiVerifyImage,
  aiCheckAllowed,
  aiRegenerate,
  aiGetRegenerateLimit,
};
