import GAxios from "src/util/GAxios";

export const getStream = async (postId) => {
  if (!postId) return null;
  if (postId === "null") return null;
  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/u/live/stream/${postId}`,
    ignoreTips: {t429: true},
  };

  let res = {response: null, err: null};

  await GAxios(
    config,
    (response) => {
      if (response?.data?.result) {
        res.response = response.data.result;
      }
    },
    (err) => {
      res.err = true;
    },
  );

  return res;
};

export const getMaStream = async (postId) => {
  if (!postId) return null;
  if (postId === "null") return null;

  const data = JSON.stringify({
    content: {
      postIds: [postId],
    },
  });

  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/u/live/v2/ma/batch`,
    data,
  };

  let res = {response: null, err: null};

  await GAxios(
    config,
    (response) => {
      if (response?.data?.result) {
        res.response = response?.data?.result;
      }
    },
    (err) => {
      console.log("err", err);
      res.err = true;
    },
  );

  return res;
};

export const getStreams = async (streamIds) => {
  if (!streamIds) return null;
  if (streamIds === "null") return null;

  // streamIds = streamIds.splice(0, 10);

  const data = JSON.stringify({
    content: {
      streamIds,
    },
  });

  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/u/live/stream/batch`,
    data,
  };

  let res = {response: null, err: null};

  await GAxios(
    config,
    (response) => {
      if (response?.data?.result) {
        res.response = response?.data?.result?.data?.cats;
      }
    },
    (err) => {
      res.err = true;
    },
  );

  return res;
};
