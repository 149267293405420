import GAxios from "src/util/GAxios";
import {CHAT_API} from "./index";

/**
 * If the given users are not the chat users, this will upgrade users to chat users.
 * @param {string[]} targets list of target users
 * @param {boolean} isChatId true by default, set false for gettr id
 * @param {string} from profile or dmsearch
 */
export const createChannel = async (
  {targets, isChatId = true, from = "dmsearch"},
  {ignoreErrors},
) => {
  if (!Array.isArray(targets) || targets.length === 0) {
    return;
  }

  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/${CHAT_API}/channel`,
    data: {
      content: {
        targets,
        is_chat_id: isChatId,
        from,
      },
    },
    ignoreErrors,
  };

  let res = null;

  await GAxios(config, (response) => {
    if (response?.data?.result) {
      res = response.data.result;
    }
  });

  return res;
};
