import GAxios from "src/util/GAxios";
import {userConstants} from "../_constants";
import {verifCodeErrorCallback} from "../../../../util/ErrorCallback";

export const bindEmail = async (params) => {
  const {email, code, callback} = params;
  if (!code || !email) return;

  const data = JSON.stringify({
    content: {
      email: email,
      code: code,
    },
  });

  const config = {
    method: "post",
    url: userConstants.API_RECOVERY_EMAIL,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  let res = false;

  await GAxios(config, (response) => {
    if (response && response.data?.result?.success) {
      res = code;
    } else {
      res = false;
    }
    callback(response);
  });

  return res;
};

export const bindEmailV2 = async (params) => {
  const {email, code, successCallback, errorCallback} = params;
  if (!code || !email) return;

  const data = JSON.stringify({
    content: {
      email: email,
      code: code,
    },
  });

  const config = {
    method: "post",
    url: userConstants.API_RECOVERY_EMAIL_V2,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  let res = false;

  await GAxios(
    config,
    (response) => {
      if (response && response.data?.result) {
        res = response.data?.result.vtoken;
      } else {
        res = false;
      }
      successCallback && successCallback(response);
    },
    (err) => {
      // res = verifCodeErrorCallback(err);
      errorCallback && errorCallback(err);
    },
  );

  return res;
};
