import GAxios from "src/util/GAxios";

/**
 * {
 *   "packet_id": "01GJAY5K60NF0BX29W80QZTKM6",
 *   "sticker_id": "01GJTEVF6NMC6H12PYHZ5YSNAJ"
 * }
 *
 * @param {*} data
 * @returns
 */
export const fetchStickersUse = async (data) => {
  const param = {
    content: data,
  };

  const config = {
    method: "post",
    url: process.env.REACT_APP_API_URL + `/v1/stickers/use`,
    data: param,
    ignoreErrors: true,
  };

  let res = {err: null, response: null};

  await GAxios(
    config,
    (response) => {
      if (response) {
        res.response = response.data.result;
      } else {
        res.err = true;
      }
    },
    (err) => {
      res.err = true;
    },
  );

  return res;
};
