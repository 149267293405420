import GAxios from "src/util/GAxios";

export const removeAllHistory = async () => {
  const config = {
    method: "delete",
    url: `${process.env.REACT_APP_API_URL}/u/live/v2/watch/all`,
  };

  let res = {};

  await GAxios(
    config,
    (response) => {
      if (response?.data?.result) {
        res = response?.data?.result;
      }
    },
    (err) => {
      res = err;
    },
  );

  return res;
};
