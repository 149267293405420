import {isResponseOk, parseUser} from "src/util/FeedUtils";
import GAxios from "src/util/GAxios";
import {getRecommendUsers} from "./getRecommendUser";

export const getSuggestUsers = async ({userId, token}) => {
  const xAuth =
    userId === null
      ? `{"user": null, "token": null}`
      : `{"user": "${userId}", "token": "${token}"}`;

  const config = {
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/s/usertag/suggest?&max=20&incl=userinfo|followings`,
    headers: {
      "x-app-auth": xAuth,
    },
  };

  let resp = null;
  try {
    await GAxios(
      config,
      (res) => {
        const followings = res.data?.result?.aux?.fws;
        resp = {
          data: parseUser(res.data?.result?.aux),
          fws: followings,
        };
        if (resp.data && Object.keys(resp.data).length > 0) {
          return resp;
        } else {
          // fallback to the recommend users
          return getRecommendUsers({userId, token}).then((resX) => {
            resp = resX;
          });
        }
      },
      (err) => {
        console.error(err);
      },
    );
  } catch (error) {
    console.error(error);
  }
  if (resp?.err) {
    console.error(resp?.err);
  }
  return resp;
};
