import GAxios from "src/util/GAxios";
import {userConstants} from "../_constants";
import parsePhoneNumber from "libphonenumber-js";
import {emailSmsErrorCallback} from "../../../../util/ErrorCallback";

export const sendVerifCodeRecoveryPhone = async (params) => {
  const {phone, password, token, hasSms, callback, errorCallback} = params;

  if (!phone) return;

  let data = null;
  if (password) {
    data = JSON.stringify({
      content: {
        sms: phone,
        countrycode: "+" + parsePhoneNumber("+" + phone).countryCallingCode,
        password: password,
        lang: "en", // [TODO]: will be replace when the current lang will be available in the Redux Store.
        reqtype: userConstants.REQUEST_TYPE_CHANGE_SMS,
        token: token,
      },
    });
  } else {
    data = JSON.stringify({
      content: {
        sms: phone,
        countrycode: "+" + parsePhoneNumber("+" + phone).countryCallingCode,
        lang: "en", // [TODO]: will be replace when the current lang will be available in the Redux Store.
        reqtype: userConstants.VERIF_CODE_REQ_TYPE_PHONE,
        token: token,
      },
    });
  }

  const config = {
    method: "post",
    url: userConstants.API_SEND_VERIF_CODE_SMS,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  let res;

  await GAxios(
    config,
    (response) => {
      if (response && response.data?.result?.success) {
        res = params;
      } else {
        res = null;
      }
      callback(response);
    },
    (err) => {
      res = emailSmsErrorCallback(err, "phone");
      if (errorCallback) errorCallback(err);
    },
  );

  return res;
};
