import GAxios from "src/util/GAxios";
import {CHAT_API} from "./index";

/**
 * Remove the channel from coversation list and clear all messages in the channel for the user.
 * @param {string} channleId channel id to remove
 */
export const removeChannel = async ({channelId}) => {
  const config = {
    method: "DELETE",
    url: `${process.env.REACT_APP_API_URL}/${CHAT_API}/channel/remove`,
    data: {
      content: {
        channel_id: channelId,
      },
    },
  };

  let res = null;

  await GAxios(config, (response) => {
    if (response?.data?.result) {
      res = response.data.result;
    }
  });

  return res;
};
