import GAxios from "src/util/GAxios";
import {CHAT_API} from "../index";

export const getLinkInfoByInviteCode = async (invitation_code) => {
  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/${CHAT_API}/channel/group/link/info?code=${invitation_code}`,
    ignoreErrors: true,
  };

  let res = null;

  await GAxios(
    config,
    (response) => {
      if (response?.data?.result) {
        res = response.data.result;
      }
    },
    (error) => {
      res = error;
    },
  );

  return res;
};
