import {useCallback} from "react";
import {Helmet} from "react-helmet";
import {useLocation} from "react-router-dom";
import {handleMediaUrl, zoomImage} from "src/util/imageUtils";
import {useLanguage} from "src/contexts/LanguageContext/LanguageContext";

export const GHelmet = ({data, user, type}) => {
  const location = useLocation();
  const {language} = useLanguage();
  let ogTitle = "";
  // global
  let imageURL =
    data && data?.imgs && Array.isArray(data.imgs)
      ? data.imgs[0]
      : data?.main
      ? data?.main
      : data?.previmg
      ? data?.previmg
      : data?.owner?.ico
      ? data?.owner?.ico
      : "https://gettr.com/gettr.jpg";

  let videoURL =
    data?.p_type === "stream"
      ? data?.vid
      : handleMediaUrl(
          process.env.REACT_APP_MEDIA_BASE,
          data?.pvid || data?.vid || data?.ovid,
        );

  let description = data?.dsc || data?.txt;
  let height = data?.vid_hgt;
  let width = data?.vid_wid;
  let originalUsername = data?.owner?.ousername;
  let parentUser = data?.parent?.user;
  let skipZoom =
    imageURL === data?.main ||
    imageURL === data?.previmg ||
    imageURL === "https://gettr.com/gettr.jpg";
  const loadDeepAR = location.pathname.startsWith("/streamHostSide");
  const load_CMP_ONE_TRUST = [
    "/terms-app",
    "/coin-terms-app",
    "/privacy-app",
    "/pep-popup-app",
    "/premium-terms-app",
    "/live-policy-app",
  ].includes(window.location.pathname);

  // post , history , vision
  if (type === "post" || type === "history" || type === "vision") {
    originalUsername = user?.nickname;
  }

  // profile
  if (type === "profile") {
    description = user?.dsc;
    imageURL = user?.ico ? user.ico : "https://gettr.com/gettr.jpg";
    originalUsername = user?.nickname;
  }

  // streaming
  if (type === "streaming") {
    imageURL = data?.imgs && data?.imgs?.length > 0 && data?.imgs[0];
    ogTitle = (data && data.ttl) || "";
  }

  /**
   * api return txt                   type          originalUsername
   *                                  profile:      username    on   Gettr
   *                                  post   :      username    on   Gettr    : “txt”
   *                                  livestream :  username    on   Gettr    : “txt”
   *                                  history :     username    on   Gettr    : "txt"
   * txt is not null                  comment:      username    on   Gettr    : “@parentUser txt”     eg:https://gettr.com/comment/c1evoj9f2a2
   * only reply+gif,response no text  comment:      username    on   Gettr                           eg:https://gettr.com/comment/c1ewlb1ee83
   *                                  vision:       username    on   Gettr    : "txt"
   *                                  script
   *                                  global
   * parameter           meta
   * ogTitle             og:title
   *                     twitter:title
   *
   * ogDescription       og:description
   *                     description
   *                     twitter:description
   *
   * ogURL               og:url
   *
   * ogType              og:type
   *
   * twitterImageAlt     twitter:image:alt
   *
   * twitterCard         twitter:card
   *
   * og:image
   * twitter:image
   * og:image:width
   * og:image:height
   *
   * article:author      "http://examples.opengraphprotocol.us/profile.html
   * article:tag
   */

  const default_title = "GETTR - The Marketplace of Ideas";
  const default_description =
    "Social Media is BETTR with GETTR. BETTR community. BETTR technology. BETTR opportunity. ";

  //   let ogTitle = "";
  let ogDescription = "";
  let ogURL = document.location.origin + location.pathname;
  let ogType = "article";
  let articleAuthor = "";
  let articleTag = [];
  let twitterImageAlt = "";
  let twitterCard =
    imageURL && imageURL !== "https://gettr.com/gettr.jpg"
      ? "summary_large_image"
      : "summary";

  switch (type) {
    case "vision":
    case "post":
      if (description) {
        ogTitle = originalUsername
          ? `${originalUsername} on GETTR: ${description}`
          : default_title;
        ogDescription = description;
      } else {
        ogTitle = originalUsername
          ? `${originalUsername} on GETTR`
          : default_title;
        ogDescription = default_description;
      }
      ogType = "article";
      articleAuthor =
        "https://gettr.com/user/" +
        (user?.ousername || user?.username || user?._id);
      articleTag = data?.tags ?? [];
      twitterImageAlt = originalUsername
        ? `${originalUsername} on GETTR`
        : default_title;
      break;
    case "profile":
      ogTitle = originalUsername ? `${originalUsername} on GETTR` : "Gettr";
      ogDescription = description || default_title;
      ogType = "profile";
      twitterImageAlt = originalUsername
        ? `${originalUsername} on GETTR`
        : default_title;
      break;
    case "streaming":
      ogTitle =
        `${user?.nickname || user?.ousername || user?.username} on GETTR : ${
          data?.ttl
        }` || default_title;
      ogDescription = description;
      ogType = "";
      twitterImageAlt = "GETTR";
      break;
    case "history":
      ogTitle = originalUsername
        ? `${originalUsername} on GETTR: ${description}`
        : default_title;
      ogDescription = description;
      ogType = "article";
      articleAuthor =
        "https://gettr.com/user/" +
        (user?.ousername || user?.username || user?._id);
      articleTag = data?.tags ?? [];
      twitterImageAlt = originalUsername
        ? `${originalUsername} on GETTR`
        : default_title;
      break;
    case "comment":
      if (description) {
        ogTitle = originalUsername
          ? `${originalUsername} on GETTR: ${
              parentUser ? `@${parentUser?.ousername}` : ""
            } ${description}`
          : default_title;
        ogDescription = description;
        ogType = "article";
        articleAuthor =
          "https://gettr.com/user/" +
          (user?.ousername || user?.username || user?._id);
        articleTag = data?.tags ?? [];
        twitterImageAlt = originalUsername
          ? `${originalUsername} on GETTR`
          : default_title;
        break;
      } else {
        ogTitle = originalUsername
          ? `${originalUsername} on GETTR`
          : default_title;
        ogDescription = originalUsername
          ? `${originalUsername} replying to ${`${
              parentUser?.ousername ? `@${parentUser?.ousername}` : ""
            } on GETTR`}`
          : default_description;
        ogType = "article";
        twitterImageAlt = originalUsername
          ? `${originalUsername} on GETTR`
          : default_title;
      }
      break;
    case "global":
    case "script":
      ogTitle = default_title;
      ogDescription = default_description;
      ogType = "website";
      twitterImageAlt = "GETTR";
      break;
    default:
      break;
  }

  // truncate
  if (ogTitle?.length > 155) {
    ogTitle = ogTitle?.substring(0, 155) + "...";
  }
  if (ogDescription?.length > 155 && ogDescription !== default_description) {
    ogDescription = ogDescription?.substring(0, 155) + "...";
  }

  const og = [
    {property: "og:title", content: ogTitle},
    {property: "og:type", content: ogType},
    {property: "og:url", content: ogURL},
    {property: "og:description", content: ogDescription},
    {name: "description", content: ogDescription},
  ];

  const ogImage = [
    ...(imageURL
      ? [
          {
            property: "og:image",
            content: handleMediaUrl(
              process.env.REACT_APP_MEDIA_BASE,
              skipZoom ? imageURL : zoomImage(imageURL, 500, 0),
              true,
            ),
          },
        ]
      : []),
    ...(imageURL && width
      ? [
          {
            property: "og:image:width",
            content: width,
          },
        ]
      : []),
    ...(imageURL && height
      ? [
          {
            property: "og:image:height",
            content: height,
          },
        ]
      : []),
    ...(videoURL
      ? [
          {
            property: "og:video",
            content: videoURL,
          },
        ]
      : []),
    ...(videoURL && width
      ? [
          {
            property: "og:video:width",
            content: width,
          },
        ]
      : []),
    ...(videoURL && height
      ? [
          {
            property: "og:video:height",
            content: height,
          },
        ]
      : []),
  ];

  const article = [{property: "article:author", content: articleAuthor}];
  articleTag?.map((tag) => {
    article.push({property: "article:tag", content: tag});
  });

  // See https://developer.twitter.com/en/docs/twitter-for-websites/cards/guides/getting-started
  const twitterCards = [
    {
      name: "twitter:title",
      content: ogTitle,
    },
    {
      name: "twitter:description",
      content: ogDescription,
    },
    {
      name: "twitter:image",
      content: handleMediaUrl(
        process.env.REACT_APP_MEDIA_BASE,
        skipZoom ? imageURL : zoomImage(imageURL, 500, 0),
        true,
      ),
    },
    {
      name: "twitter:image:alt",
      content: twitterImageAlt,
    },
    {
      name: "twitter:site",
      content: "@GETTRofficial",
    },
    {
      name: "twitter:card",
      content: twitterCard,
    },
  ];

  const ADS_SCRIPT = {
    type: "text/javascript",
    innerHTML: `window.googletag = window.googletag || {cmd: []};
      googletag.cmd.push(function() {
        googletag.pubads().enableSingleRequest();
        googletag.pubads().collapseEmptyDivs();
        googletag.pubads().setCentering(true);
        googletag.enableServices();
      });`,
  };

  const INTERCOM_SCRIPT = {
    type: "text/javascript",
    innerHTML: `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/hpjylscn';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`,
  };

  const DEEP_AR = {
    type: "text/javascript",
    src: "/lib/deepar.js",
    async: false,
  };

  const CMP_ONE_TRUST = {
    type: "text/javascript",
    src: "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js",
    charset: "UTF-8",
    ["data-domain-script"]: process.env.REACT_APP_CMP_ONE_TRUST_KEY,
  };

  const Optanon_Wrapper = {
    type: "text/javascript",
    innerHTML: `function OptanonWrapper() {}`,
  };

  const buildScripts = useCallback(() => {
    const scripts = [];
    if (loadDeepAR) {
      scripts.push(DEEP_AR);
    }
    if (!load_CMP_ONE_TRUST) {
      scripts.push(CMP_ONE_TRUST);
      scripts.push(Optanon_Wrapper);
    }
    scripts.push(ADS_SCRIPT);
    scripts.push(INTERCOM_SCRIPT);
    return scripts;
  }, [loadDeepAR, load_CMP_ONE_TRUST]);

  return (
    <Helmet
      htmlAttributes={{lang: language}}
      title={ogTitle}
      link={[
        {rel: "canonical", href: document.location.origin + location.pathname},
      ]}
      meta={[...og, ...ogImage, ...article, ...twitterCards]}
      script={buildScripts()}
    />
  );
};
