import GAxios from "src/util/GAxios";

const ReferEarnApi = {
  getReferredUsers,
  updateVerifyData,
  getReferConfig,
  updateOnboardingData,
  getOnboardingStatus,
  sendCode,
  verifyCode,
};

export default ReferEarnApi;

async function getReferredUsers() {
  const config = {
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/gc/u/referred-users`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  let res = null;
  await GAxios(config, ({data}) => {
    res = data?.result?.data;
  });
  return res;
}

async function updateVerifyData(data) {
  const config = {
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/referred-user/verify`,
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
    ignoreErrors: true,
  };

  let res = null;
  await GAxios(config, ({data}) => {
    res = data?.result?.data;
  });
  return res;
}

async function getReferConfig(data) {
  const config = {
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/gc/u/config`,
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  };

  let res = null;
  await GAxios(config, ({data}) => {
    res = data?.result?.data;
  });
  return res;
}

async function updateOnboardingData(data) {
  const config = {
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/gc/u/basic-verify`,
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  };

  let res = null;
  await GAxios(config, ({data}) => {
    res = data?.result?.data;
  });
  return res;
}

async function getOnboardingStatus() {
  const config = {
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/gc/u/basic-verify/status`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  let res = null;
  await GAxios(
    config,
    ({data}) => {
      res = data?.result?.data;
    },
    (error) => {
      if (error?.response?.data?.error?.code) {
        res = error?.response?.data?.error;
      } else if (error?.code) {
        res = error;
      }
      throw res;
    },
  );
  return res;
}
async function sendCode(data, errorCallback) {
  const config = {
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/u/user/v2/verifycode/email`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {content: data},
  };

  let res = null;
  try {
    await GAxios(
      config,
      ({data}) => {
        res = data?.result?.data;
      },
      (err) => {
        res = null;
        if (errorCallback) errorCallback(err.response.data.error);
      },
    );
  } catch (error) {
    return error;
  }

  return res;
}

async function verifyCode(data) {
  const config = {
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/u/user/v2/verifycode/verify`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {content: data},
  };
  let res = null;
  await GAxios(config, ({data}) => {
    res = data?.result?.success;
  });
  return res;
}
