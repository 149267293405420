import {isBoolean, isPlainObject} from "lodash-es";
import {useSelector} from "react-redux";

export const useUserSession = () => {
  const {authenticated, userinfo: userInfo} = useSelector(
    (state) => state.auth?.session,
  );

  if (!isPlainObject(userInfo) || !isBoolean(authenticated)) {
    return {authenticated: false, userInfo: {}};
  }

  return {authenticated, userInfo};
};
