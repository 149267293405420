import {postConstants} from "src/app/components/post/_constants";
import GAxios from "src/util/GAxios";
import {likeRepostErrorMessage} from "src/util/ErrorCallback";

export const repostSubmit = async (data) => {
  if (!data) return;

  let params = JSON.stringify(data);

  const dataNew = new FormData();
  dataNew.append("content", params);

  const config = {
    method: "post",
    url: postConstants.SUBMIT_REPOST_URL,
    headers: {
      "Content-Type": "multipart/form-data",
      enctype: "multipart/form-data",
    },
    data: dataNew,
  };

  let payload = {response: null, err: true};

  await GAxios(
    config,
    (response) => {
      if (response?.data?.result) {
        payload.response = response.data.result;
        payload.err = false;
      }
    },
    (err) => {
      payload.err = err;
      likeRepostErrorMessage(err);
    },
  );

  return payload;
};
