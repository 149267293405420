import GAxios from "src/util/GAxios";

export const fetchHashtagSuggestions = async (
  hashtagOffset,
  max,
  callback,
  errorCallback,
) => {
  await GAxios(
    {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/s/hashtag/suggest`,
      params: {
        offset: hashtagOffset,
        max,
      },
    },
    (res) => {
      callback(res);
    },
    (err) => {
      if (errorCallback) errorCallback(err);
    },
  );
};
