import {userConstants} from "../_constants";
import GAxios from "src/util/GAxios";
import {getLang, t} from "src/i18n/utils";
import {showToast} from "../../../../util/showToast";
import {handleErrorCodes} from "../../../../util/handleErrorCode";
import NodeRSA from "node-rsa";
import {setMultiAccount} from "src/util/userUtil";
import {signUpEventTrack, unauthenticatedEventTrack} from "src/util/trackEvent";
import {resetSendVerifCodeStatus} from "../store";
import {getStore} from "src/store";

export const getKeys = async () => {
  const config = {
    method: "get",
    url: userConstants.API_GET_KEYS,
    headers: {
      "Content-Type": "application/json",
    },
  };

  let ret = null;
  await GAxios(
    config,
    (response) => {
      ret = response?.data?.result?.k;
      // return response?.data?.result;
    },
    (err) => {
      console.error(err);
      return null;
    },
  );

  return ret;
};

export const signup = async (params) => {
  const store = await getStore();
  if (!params) return;
  const {pwd, username, email, phone, code, birthyear, birthday} = params;
  const type = phone?.length > 0 ? "phone" : "email";
  const inviter = localStorage.getItem(userConstants.INVITE_UN);
  const invitecode = localStorage.getItem(userConstants.INVITE_CODE);
  const lang = getLang();

  const whatsapp = localStorage.getItem("Whatsapp");
  const isWhatsapp = whatsapp === "true";

  const smsParam = !isWhatsapp ? {sms: phone} : {whatsapp: "+" + phone};

  const _userinfo = inviter
    ? {
        username,
        email,
        ...smsParam,
        birthyear,
        birthday,
        inviter,
      }
    : {
        username,
        email,
        ...smsParam,
        lang,
        birthyear,
        birthday,
      };
  const content = invitecode
    ? {
        userinfo: _userinfo,
        authinfo: {
          pwd,
        },
        verifycode: code,
        invitecode: invitecode,
      }
    : {
        userinfo: _userinfo,
        authinfo: {
          pwd,
        },
        verifycode: code,
      };

  const data = {
    content,
  };

  const config = {
    method: "post",
    url: userConstants.API_SIGNUP,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  let loginData = {
    authenticated: false,
    userinfo: null,
    err: null,
  };

  const callback = (err, res) => {
    localStorage.removeItem("Whatsapp");
    loginData.authenticated = !err;
    loginData.userinfo = res;
    loginData.err = err?.data || err?.error;

    if (loginData.authenticated && loginData.userinfo) {
      const sessionInfo = {
        authenticated: loginData.authenticated,
        userinfo: loginData.userinfo,
      };
      setMultiAccount(sessionInfo);
    }
  };

  const processResult = (result) => {
    let err = null;

    let authenticated = result["token"];
    let loadedUserInfo = result["user"];

    if (!authenticated) {
      err = new Error("Auth failed");
    } else {
      loadedUserInfo.token = authenticated;
      sessionStorage.setItem("showTour", true);
      unauthenticatedEventTrack(3);
      signUpEventTrack(type, 4);
    }
    callback(err, loadedUserInfo);
  };

  await GAxios(
    config,
    (response) => {
      processResult(response.data.result);
      store.dispatch(resetSendVerifCodeStatus());
    },
    (err) => {
      let errorCode = err?.response?.data?.error?.code;
      if (["E_BAD_DATA"].includes(errorCode)) {
        showToast("warning", t("getter_fe.auth.errors.E_BAD_USERNAME"));
      } else {
        handleErrorCodes(err);
      }
      signUpEventTrack(type, 4, errorCode);
      callback(err?.response?.data);
    },
  );

  return loginData;
};

export const expressSignup = async (params) => {
  if (!params) return;
  const {pwd, username, birthyear} = params;
  const inviter = localStorage.getItem(userConstants.INVITE_UN);
  const lang = getLang();
  const _userinfo = inviter
    ? {
        username,
        lang,
        birthyear,
        // inviter,
      }
    : {
        username,
        lang,
        birthyear,
      };
  const content = {
    userinfo: _userinfo,
    authinfo: {
      pwd,
    },
  };

  let loginData = {
    authenticated: false,
    userinfo: null,
    err: null,
  };

  const callback = (err, res) => {
    loginData.authenticated = !err;
    loginData.userinfo = res;
    loginData.err = err?.data || err?.error;
    if (loginData.authenticated && loginData.userinfo) {
      const sessionInfo = {
        authenticated: loginData.authenticated,
        userinfo: loginData.userinfo,
      };
      setMultiAccount(sessionInfo);
    }
  };

  const keys = await getKeys();
  if (!keys) {
    callback(true, null);
    return null;
  }
  const keyRSA = new NodeRSA(keys);
  const encryptedData = keyRSA.encrypt(JSON.stringify(content), "base64");

  const data = {
    content: encryptedData,
  };

  const config = {
    method: "post",
    url: userConstants.API_EXPRESS_SIGNUP,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  const processResult = (result) => {
    let err = null;

    let authenticated = result["token"];
    let loadedUserInfo = result["user"];

    if (!authenticated) {
      err = new Error("Auth failed");
    } else {
      loadedUserInfo.token = authenticated;
      sessionStorage.setItem("showTour", true);
      unauthenticatedEventTrack(3);
    }
    callback(err, loadedUserInfo);
  };

  await GAxios(
    config,
    (response) => {
      processResult(response.data.result);
    },
    (err) => {
      console.error(err);
      callback(err?.response?.data);
    },
  );

  return loginData;
};
