export const USER_GUIDES_CACHE_KEY = "USER_GUIDES";
export const INIT_GUIDE_SHOW_TIMES = 1;
export const SECOND_GUIDE_SHOW_TIMES = 2;

export const USER_GUIDE_TYPES = {
  AI_COPILOT: "AI_COPILOT",
  DM_RECOVER: "DM_RECOVER",
};

export const USER_GUIDES = {
  [USER_GUIDE_TYPES.AI_COPILOT]: {
    content: "getter_fe.post.tips.aiWriteAtLeast3Words",
  },
  [USER_GUIDE_TYPES.DM_RECOVER]: {
    content: "getter_fe.directMessage.recover",
  },
};
