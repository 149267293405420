import {postConstants} from "src/app/components/post/_constants";
import GAxios from "src/util/GAxios";
import {commentErrorMessage} from "src/util/ErrorCallback";
import {postCommentErrorEventTrack} from "src/util/trackEvent";

export const commentSubmit = async (data) => {
  if (!data) return;

  let params = {
    data: {
      acl: {
        _t: "acl",
      },
      _t: postConstants.COMMENT_TYPE,
      ...data,
    },
    aux: null,
    serial: postConstants.COMMENT_TYPE,
  };

  params = JSON.stringify(params);

  var dataNew = new FormData();
  dataNew.append("content", params);

  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/u/post/${data?.pid}/comment`,
    headers: {
      "Content-Type": "multipart/form-data",
      enctype: "multipart/form-data",
    },
    data: dataNew,
    activity: true,
  };

  let res = {err: true, response: null};

  await GAxios(
    config,
    (response) => {
      if (response?.data?.result) {
        res.response = response.data.result;
        res.err = false;
      } else {
        res.err = true;
        postCommentErrorEventTrack("COMMENT", response);
      }
    },
    (err) => {
      res.err = err;
      commentErrorMessage(err);
      postCommentErrorEventTrack("COMMENT", err);
    },
  );

  return res;
};
