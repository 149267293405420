import GAxios from "src/util/GAxios";

export const userFollowStatus = async (data) => {
  const {userId, userIdTarget} = data;
  if (!userId || !userIdTarget) return;

  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/u/user/${userId}/follows/${userIdTarget}`,
  };

  let res = null;

  await GAxios(config, (response) => {
    if (response?.data?.result) {
      res = response.data.result;
    } else {
      res = null;
    }
  });

  return res;
};
