import GAxios from "src/util/GAxios";

export const aiDeleteImages = async (data) => {
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/aiavatar/v1/delete-images`,
    timeout: 60000,
    data,
    ignoreErrors: true,
    ignoreImperva: true,
  };

  let res = {response: null, error: null};

  await GAxios(
    config,
    (response) => {
      if (response?.data) {
        res = {
          response: response?.data,
        };
      }
    },
    (err) => {
      res = {
        error: err?.response?.data?.error,
      };
    },
  );

  return res;
};
