import GAxios from "src/util/GAxios";
import {userConstants} from "../_constants";
import {t} from "src/i18n/utils";
import {toast} from "react-toastify";
import {NotifMessage} from "../../notifications/NotifMessage";
import AppConst from "../../../AppConsts";
import parsePhoneNumber from "libphonenumber-js";

export const changeSms = async (params) => {
  const {phone, password, code, callback, errorCallback} = params;
  if (!code || !phone) return;

  const data = JSON.stringify({
    content: {
      sms: phone,
      countrycode: "+" + parsePhoneNumber("+" + phone).countryCallingCode,
      code,
      password,
    },
  });

  const config = {
    method: "post",
    url: userConstants.API_CHANGE_SMS,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  let res = false;

  await GAxios(
    config,
    (response) => {
      if (response && response.data?.result?.success) {
        res = code;
      } else {
        res = false;
      }
      localStorage.removeItem("Whatsapp");
      callback(response);
    },
    (err) => {
      res = null;
      localStorage.removeItem("Whatsapp");
      if (errorCallback) errorCallback(err);
    },
  );

  return res;
};

export const changeSmsV2 = async (params) => {
  const {phone, code, vtoken, password, successCallback, errorCallback} =
    params;
  if (!code || !phone || (!vtoken && !password)) return;

  const whatsapp = localStorage.getItem("Whatsapp");
  const isWhatsapp = whatsapp === "true";

  const smsParam = !isWhatsapp ? {sms: "+" + phone} : {whatsapp: "+" + phone};

  const data = JSON.stringify({
    content: {
      ...smsParam,
      // countrycode: "+" + parsePhoneNumber("+" + phone).countryCallingCode,
      code,
      vtoken,
      password,
    },
  });

  const config = {
    method: "post",
    url: userConstants.API_CHANGE_SMS_V2,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  let res = false;

  await GAxios(
    config,
    (response) => {
      if (response && response.data?.result?.success) {
        res = code;
      } else {
        res = false;
      }
      localStorage.removeItem("Whatsapp");
      successCallback && successCallback(response);
    },
    (err) => {
      res = null;
      localStorage.removeItem("Whatsapp");
      errorCallback && errorCallback(err);
    },
  );

  return res;
};
