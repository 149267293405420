import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  getAdsConfig,
  getPublicGlobals,
  getSocialConnections,
} from "src/app/components/auth/store";
import {AnalyticsClient, AnalyticsState} from "src/util/AnalyticsUtils";

function useCommonConfigs() {
  const authenticated = useSelector(
    (state) => state.auth?.session?.authenticated,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!AnalyticsState.client) {
      const analyticsHandler = new AnalyticsClient();
      AnalyticsState.setAnalyticsClient(analyticsHandler);
    } else {
      AnalyticsState.client.getConfiguration();
    }
    setTimeout(() => {
      AnalyticsState.client.startListener();
    }, 2000);
  }, [authenticated, AnalyticsState.client]);

  useEffect(() => {
    // handle public globals
    dispatch(getAdsConfig());
    dispatch(getPublicGlobals());
    dispatch(getSocialConnections());
  }, []);
}

export default useCommonConfigs;
