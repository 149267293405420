import GAxios from "src/util/GAxios";

export const checkEarlyAccess = async () => {
  const config = {
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/gc/early-access`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  let res = null;
  await GAxios(config, ({data}) => {
    res = data?.result;
  });
  return res;
};

export const createWallet = async (data) => {
  let content = {
    user: data.user,
    passcode: data.passcode,
  };

  if (!data.user || !data.passcode) {
    return null;
  }

  if (data.updatePEP) {
    content.pep = data.isPEP;
  }

  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/gc/wallets`,
    data: {content: content},
    headers: {
      "Content-Type": "application/json",
    },
  };

  let res = null;
  await GAxios(config, ({data}) => {
    res = data?.result?.data;
  });
  return res;
};

export const getBalance = async ({userId, errorCallback}) => {
  if (!userId) {
    return null;
  }

  const config = {
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/gc/wallets/balance`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  let res = null;
  try {
    await GAxios(
      config,
      ({data}) => {
        res = data?.result?.data;
      },
      (err) => {
        res = null;
        if (errorCallback) errorCallback(err.response.data.error);
        throw err.response.data.error;
      },
    );
  } catch (error) {
    return error;
  }
  return res.balance ? res.balance : 0;
};

export const getConversionRate = async (currency) => {
  if (!currency) {
    return null;
  }

  const config = {
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/gc/conversion-rate?currency=${currency}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  let res = null;
  await GAxios(config, ({data}) => {
    res = data?.result?.data;
  });
  return {...res, currency};
};

export const resetWalletPasscode = async (data) => {
  if (!data || !data.user || !data.currentCode || !data.newPasscode) {
    return null;
  }

  const config = {
    method: "PATCH",
    url: `${process.env.REACT_APP_API_URL}/gc/reset-passcode`,
    data: {content: data},
    headers: {
      "Content-Type": "application/json",
    },
  };

  let res = null;
  await GAxios(config, ({data}) => {
    res = data?.result?.data;
  });
  return res;
};

export const getWalletUserDetails = async ({userId, ignoreErrors = false}) => {
  if (!userId) {
    return null;
  }

  const config = {
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/gc/wallets/${userId}`,
    headers: {
      "Content-Type": "application/json",
    },
    ignoreErrors,
  };

  let res = null;
  await GAxios(
    config,
    ({data}) => {
      res = data?.result?.data;
    },
    (error) => {
      if (error?.response?.data?.error?.code) {
        res = error?.response?.data?.error?.code;
      } else if (error?.code) {
        res = error?.code;
      }
      throw res;
    },
  );
  return res;
};

export const setPopConfig = async (pop) => {
  let content = {
    content: {
      pop_configs: {
        coin_disclaimer: {
          saved_selections: [true],
          pop_type: "coin_disclaimer",
          expiry: 0,
        },
      },
    },
  };

  if (pop) {
    const {q1, q2, q3, q4} = pop;
    content.content.pop_configs.pep = {
      saved_selections: [q1, q2, q3, q4],
      pop_type: "pep",
      version: 2,
      expiry: 0,
    };
  }

  const data = JSON.stringify(content);

  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/u/user/v2/pop_config`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  let res = false;
  await GAxios(config, (response) => {
    if (response) {
      res = response.data.result.data;
    } else {
      res = false;
    }
  });
  return res;
};

export const fetchUsersList = async (
  phrase,
  userOffset,
  max,
  callback,
  errorCallback,
  incl = "userinfo",
  ignoreErrors = false,
) => {
  try {
    const decodedPhrase = decodeURIComponent(phrase);
    await GAxios(
      {
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/gc/suggest/users?incl=${incl}&phrase=${decodedPhrase}&offset=${userOffset}&max=${max}`,
        headers: {
          "Content-Type": "application/json",
        },
        ignoreErrors,
      },
      (res) => {
        callback(res);
      },
      (err) => {
        if (errorCallback) errorCallback(err);
      },
    );
    return;
  } catch (err) {
    return;
  }
};

export const forgotWalletPasscode = async (data) => {
  if (!data || !data.user || !data.passcode || !data.token) {
    return null;
  }

  const config = {
    method: "PATCH",
    url: `${process.env.REACT_APP_API_URL}/gc/forgot-passcode`,
    // url: "http://localhost:3001/forgot-passcode",
    data: {content: data},
    // data,
    headers: {
      "Content-Type": "application/json",
    },
  };

  let res = null;
  await GAxios(config, ({data}) => {
    res = data?.result?.data;
  });
  return res;
};

export const logoutWalletSession = async ({userId}) => {
  if (!userId) {
    return null;
  }

  const config = {
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/gc/wallet-logout`,

    headers: {
      "Content-Type": "application/json",
    },
  };

  let res = null;
  await GAxios(config, ({data}) => {
    res = data?.result?.data;
  });
  return res;
};
export const getTopTippers = async ({contentId, ignoreErrors = false}) => {
  if (!contentId) {
    return null;
  }

  const config = {
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/gc/top-tipper/${contentId}`,
    headers: {
      "Content-Type": "application/json",
    },
    ignoreErrors,
  };

  let res = null;
  await GAxios(
    config,
    ({data}) => {
      res = data?.result?.data;
    },
    (error) => {
      if (error?.response?.data?.error?.code) {
        res = error?.response?.data?.error?.code;
      } else if (error?.code) {
        res = error?.code;
      }
      throw res;
    },
  );
  return res;
};
