import {t} from "src/i18n/utils";
import {handleErrorCodes} from "./handleErrorCode";
import {showToast} from "./showToast";
import {handleMediaUrl, zoomImage} from "src/util/imageUtils";

export const emailSmsErrorCallback = (err, type) => {
  let errorCode = err?.response?.data?.error?.code;
  if (errorCode) {
    if (errorCode === "E_USER_EXISTS") {
      showToast("error", t(`getter_fe.auth.errors.${type}Exist`));
      return "alreadyShowToast";
    } else if (errorCode === "E_API_SEND_SMS_FAIL" && type === "phone") {
      showToast("error", t("getter_fe.auth.errors.E_API_SEND_SMS_FAIL"));
      return "alreadyShowToast";
    } else if (errorCode === "E_SMS_EXISTS") {
      showToast("error", t("getter_fe.auth.errors.E_SMS_EXISTS"));
      return "alreadyShowToast";
    } else if (errorCode === "E_EMAIL_EXISTS") {
      showToast("error", t("getter_fe.auth.errors.emailExist"));
      return "alreadyShowToast";
    } else if (errorCode === "E_EMAIL_PROVIDER_BLACKLISTED") {
      showToast(
        "error",
        t("getter_fe.auth.errors.E_EMAIL_PROVIDER_BLACKLISTED"),
      );
      return "alreadyShowToast";
    }
  }
  return null;
};

export const verifCodeErrorCallback = (err) => {
  return err?.response?.data?.error?.code;
};

export const loginStatusErrorMessage = (err, username) => {
  let errorCode = err?.code;
  if (errorCode === "E_USER_RESERVED") {
    showToast(
      "warning",
      t("getter_fe.auth.errors.usernameReserved", {
        username: username,
      }),
    );
  } else {
    handleErrorCodes(err);
  }
};

export const commentErrorMessage = (err) => {
  if (!err?.response || err?.response?.status !== 429) {
    let errorCode = err?.response?.data?.error?.code;
    if (errorCode === "E_W1") {
      showToast("warning", t("getter_fe.auth.errors.E_W1"));
    } else if (
      ["E_POST_DELETED", "E_COMMENT_DELETED", "E_USER_BANNED"].includes(
        errorCode,
      )
    ) {
      handleErrorCodes(err);
    } else {
      showToast("error", t("getter_fe.post.errors.submitPost"));
    }
  }
};

export const likeRepostErrorMessage = (err) => {
  let errorCode = err?.response?.data?.error?.code;
  if (
    ["E_POST_DELETED", "E_COMMENT_DELETED", "E_USER_BANNED"].includes(errorCode)
  ) {
    handleErrorCodes(err);
  }
};

export const postRepostErrorMessage = (err) => {
  if (!err?.response || err?.response?.status !== 429) {
    let errorCode = err?.response?.data?.error?.code;
    if (errorCode === "E_W1") {
      showToast("warning", t("getter_fe.auth.errors.E_W1"));
    } else if (errorCode === "E_USER_BANNED") {
      handleErrorCodes(err);
    } else if (!["E_POST_DELETED", "E_COMMENT_DELETED"].includes(errorCode)) {
      showToast("error", t("getter_fe.post.errors.submitPost"));
    }
  }
};

export const editPostErrorMessage = (err) => {
  if (!err?.response || err?.response?.status !== 429) {
    let errorCode = err?.response?.data?.error?.code;
    if (errorCode === "E_W1") {
      showToast("warning", t("getter_fe.auth.errors.E_W1"));
    } else if (errorCode === "E_CHANGE_POST_TEXT_EXCEED_LIMITS") {
      showToast("error", t("getter_fe.post.errors.editPostExceedLimit"));
    }
  }
};

const FALLBACK_IMG = "https://gettr.com/media/livestream.jpg";
/**
 *
 * @param {React.SyntheticEvent<HTMLImageElement, Event>} event
 */
export const livestreamCoverImgOnError = (event) => {
  const src = event.currentTarget.src;
  const originalSrc = src.replace(/_\d+x\d+/, "");
  if (originalSrc !== src && src !== FALLBACK_IMG) {
    event.target.src = originalSrc;
  } else {
    if (window.navigator.onLine && src !== FALLBACK_IMG) {
      event.target.src = FALLBACK_IMG;
    } else {
      event.target.classList.add("error");
    }
  }
};

export const imgOnError = (event, isResizeSrc, imgURL) => {
  if (isResizeSrc) {
    event.target.src = handleMediaUrl(process.env.REACT_APP_MEDIA_BASE, imgURL);
  } else {
    event.target.classList.add("error");
  }
};
