import GAxios from "src/util/GAxios";

export const checkUsername = async (username) => {
  if (!username) return;

  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/s/user/${username}/exist`,
  };

  let res = null;

  await GAxios(config, (response) => {
    if (response.data.result) {
      res = response.data.result;
    } else {
      console.error(response);
      res = null;
    }
  });

  return res;
};

export const checkUsernameSrc = async (username) => {
  if (!username) return;

  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/s/srcfrm/${username}`,
  };

  let res = null;

  await GAxios(config, (response) => {
    if (response.data.result) {
      res = response.data.result;
    } else {
      console.error(response);
      res = null;
    }
  });

  return res;
};

export const checkUsernameExist = async (username) => {
  if (!username) return;

  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/u/user/v2/username/${username}?incl=alt&max=30`,
  };

  let res = null;

  await GAxios(
    config,
    (response) => {
      if (response.data.result) {
        res = response.data.result;
      } else {
        console.error(response);
        res = null;
      }
    },
    () => {},
  );

  return res;
};
