import GAxios from "src/util/GAxios";
import {adaptList} from "src/app/components/LiveList/LiveListSearch/utils";

export const fetchBigV = async (data) => {
  let {phrase} = data;

  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/u/search/v2/stream/bigv`,
    data: {
      content: {
        phrase: phrase,
        incl: "userstats|userinfo|followings|followers|islive|hasnewlive|posts|poststats|postInfo",
      },
    },
    ignoreTips: {t429: true},
  };

  let res = {};

  await GAxios(
    config,
    (response) => {
      if (response?.data?.result) {
        res = {
          user: {
            ...response?.data?.result?.aux?.uinf[
              response?.data?.result?.data?.bigvid
            ],
            isLive: response?.data?.result?.aux?.islive?.includes(
              response?.data?.result?.data?.bigvid,
            ),
          },
          list: adaptList(response?.data?.result),
          phrase,
        };
      }
    },
    (err) => {
      res = err;
    },
  );

  return res;
};
