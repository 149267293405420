import {createSlice, createAsyncThunk, createAction} from "@reduxjs/toolkit";
import {find} from "lodash-es";
import {AIAvatarApi} from "../api";

const NS = "aiAvatar";

const initialState = {
  aiAllowed: null,
  open: false,
  status: "",
  scenes: ["intro"],
  credits: "",
  check: {
    open: false,
    isLoading: false,
    isValid: false,
  },
  originalImages: [],
  images: [],
  currentOriginalImage: "",
  data: null,
  regenerateRemain: 0,
  regenerateLimit: 20,
  regenerate: {
    isLoading: false,
    isSuccess: null,
  },
  setAvatarCallback: null,
  generating: false,
  regenerateImages: [],
  generate: {
    isLoading: false,
    isSuccess: null,
  },
  currentUserId: "",
};

export const aiCheckAllowed = createAsyncThunk(
  "aiAvatar/aiCheckAllowed",
  AIAvatarApi.aiCheckAllowed,
);
export const aiGetOriginalImages = createAsyncThunk(
  "aiAvatar/aiGetOriginalImages",
  AIAvatarApi.aiGetOriginalImages,
);
export const aiGetImages = createAsyncThunk(
  "aiAvatar/aiGetImages",
  AIAvatarApi.aiGetImages,
);
export const aiGetCredits = createAsyncThunk(
  "aiAvatar/aiGetCredits",
  AIAvatarApi.aiGetCredits,
);
export const aiVerifyImage = createAsyncThunk(
  "aiAvatar/aiVerifyImage",
  AIAvatarApi.aiVerifyImage,
);
export const aiGenerate = createAsyncThunk(
  "aiAvatar/aiGenerate",
  AIAvatarApi.aiGenerate,
);
export const aiRegenerate = createAsyncThunk(
  "aiAvatar/aiRegenerate",
  AIAvatarApi.aiRegenerate,
);
export const aiFeedback = createAsyncThunk(
  "aiAvatar/aiFeedback",
  AIAvatarApi.aiFeedback,
);
export const aiDeleteImages = createAsyncThunk(
  "aiAvatar/aiDeleteImages",
  AIAvatarApi.aiDeleteImages,
);
export const aiGetRegenerateLimit = createAsyncThunk(
  "aiAvatar/aiGetRegenerateLimit",
  AIAvatarApi.aiGetRegenerateLimit,
);

export const toggleDialog = createAction(`${NS}/toggleDialog`);
export const sceneNavigateTo = createAction(`${NS}/sceneNavigateTo`);
export const sceneGoBack = createAction(`${NS}/sceneGoBack`);
export const toggleCheck = createAction(`${NS}/toggleCheck`);
export const setRegenerateImage = createAction(`${NS}/setRegenerateImage`);
export const setCurrentUserId = createAction(`${NS}/setCurrentUserId`);

export const aiAvatarSlice = createSlice({
  name: NS,
  initialState,
  reducers: {
    toggleDialog: (state, {payload}) => {
      const {open, scenes, setAvatarCallback, data} = payload;
      state.scenes =
        scenes ||
        (open ? (state.images?.length ? ["list"] : ["intro"]) : state.scenes);
      state.open = open;
      state.setAvatarCallback = setAvatarCallback;
      state.data = data;
    },
    sceneNavigateTo: (state, {payload = {}}) => {
      const {scene, data} = payload;
      state.scenes = [...state.scenes, scene];
      state.data = data;
    },
    sceneGoBack: (state, {payload = ""}) => {
      const index = state.scenes.indexOf(payload) + 1 || -1;
      if (payload && index === -1) {
        state.scenes = [...state.scenes, payload];
      } else {
        state.scenes = state.scenes.slice(0, index);
      }
    },
    toggleCheck: (state, {payload}) => {
      state.check.open = payload;
      state.check.isLoading = payload;
    },
    setRegenerateImage: (state, {payload}) => {
      state.regenerateImages = [...state.regenerateImages, payload];
    },
    setCurrentUserId: (state, {payload}) => {
      state.currentUserId = payload;
      state.aiAllowed = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(aiCheckAllowed.fulfilled, (state, {payload}) => {
      const {aiAllowed} = payload;
      state.aiAllowed = aiAllowed;
    });
    builder.addCase(aiGetOriginalImages.fulfilled, (state, {payload}) => {
      state.originalImages = payload.response?.result?.original_images;
    });
    builder.addCase(aiGetImages.fulfilled, (state, {payload}) => {
      state.images = (payload.response?.result?.images || []).reverse();
      if (state.scenes[0] === "loading") {
        state.scenes = state.images.length ? ["list"] : ["intro"];
      }
    });

    builder.addCase(aiGetCredits.fulfilled, (state, {payload}) => {
      state.credits = payload?.response?.result?.credits;
    });

    builder.addCase(aiVerifyImage.pending, (state) => {
      state.check.isLoading = true;
      state.check.open = true;
    });
    builder.addCase(aiVerifyImage.fulfilled, (state, {payload}) => {
      const {valid} = payload.response?.result || {};
      if (valid) {
        state.check.isValid = true;
        state.check.open = false;
        state.scenes.push("generate");
        state.currentOriginalImage = payload.response?.image_url;
      } else {
        state.check.isValid = false;
      }
      state.check.isLoading = false;
    });
    builder.addCase(aiVerifyImage.rejected, (state) => {
      state.check.isLoading = false;
    });

    builder.addCase(aiGenerate.pending, (state) => {
      state.generate.isSuccess = null;
      state.generate.isLoading = true;
    });
    builder.addCase(aiGenerate.fulfilled, (state, {payload}) => {
      state.generate.isSuccess = payload.response?.rc === "OK";
      state.generate.isLoading = false;
    });
    builder.addCase(aiGenerate.rejected, (state) => {
      state.generate.isSuccess = false;
      state.generate.isLoading = false;
    });
    builder.addCase(aiRegenerate.pending, (state) => {
      state.regenerate.isSuccess = null;
      state.regenerate.isLoading = true;
    });
    builder.addCase(aiRegenerate.fulfilled, (state, {payload}) => {
      state.regenerate.isSuccess = payload.response?.rc === "OK";
      state.regenerate.isLoading = false;
    });
    builder.addCase(aiRegenerate.rejected, (state) => {
      state.regenerate.isSuccess = false;
      state.regenerate.isLoading = false;
    });
    builder.addCase(aiFeedback.fulfilled, (state, {payload}) => {
      if (payload.response?.rc === "OK") {
        /* TODO: empty */
      }
    });
    builder.addCase(aiGetRegenerateLimit.fulfilled, (state, {payload}) => {
      if (payload.response?.rc === "OK") {
        state.regenerateRemain = payload.response?.result?.remaining;
        state.regenerateLimit = payload.response?.result?.limit;
      }
    });
  },
});
