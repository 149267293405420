// https://github.com/reduxjs/redux-toolkit/blob/2afd0f9/docs/usage/usage-with-typescript.md#getting-the-state-type
import {combineReducers} from "@reduxjs/toolkit";
import {authSlice} from "src/app/components/auth/store";
import {chatSlice} from "src/app/components/chat/store";
import {postSlice} from "src/app/components/post/store";
import {timelineSliceNew} from "src/app/components/timeline/store";
import {livestream} from "src/app/components/live/store";
import {settingsSlice} from "src/store/modules/settings";
import {statusSlice} from "src/store/modules/status";
import {gettrPay} from "src/store/modules/pay";
import {notificationSlice} from "src/app/components/notifications/store";
import {searchSlice} from "src/app/components/search/store";
import {stickerSlice} from "src/app/components/StickerPanel/store";
import {visionSlice} from "src/app/components/gtok/store";
import {livestream_poc} from "src/app/components/livestream_poc/store";
import {analyticsSlice} from "src/app/components/analytics/store";
import {reducer as DMFileUploader} from "src/app/components/DMFileUploader/reducer";
import {userGuidesSlice} from "src/app/components/global/UserGuide/store";
import {embedPost} from "src/app/components/embedPost/store";
import {liveListSearchSlice} from "src/app/components/LiveList/LiveListSearch/store";
import {liveListHistorySlice} from "src/app/components/LiveList/LiveListHistory/store";
import {tippingSlice} from "src/app/components/gettrTip/store";
import {maEligibilityCheckSlice} from "src/app/components/navigation/MaEligibilityCheck/store";
import {referSlice} from "src/app/components/referAndEarn/store";
import {aiCopilotSlice} from "src/app/components/aiCopilot/store";
import {postStatsSlice} from "src/store/modules/postStats";
import {referVerifySlice} from "src/app/components/referAndVerify/store";
import {stickerEmojiSettingSlice} from "src/app/components/StickerEmojiSetting/store";
import {gettrEmojiSlice} from "src/app/components/GettrEmoji/store";
import {stickerV2Slice} from "src/app/components/StickerV2/store";
import {aiAvatarSlice} from "src/app/components/aiAvatar/store";
import {dashboardSlice} from "src/app/pages/dashboard/store";
import {liveDashboardSlice} from "src/app/components/LiveList/store";

export const rootReducer = combineReducers({
  [authSlice.name]: authSlice.reducer,
  [chatSlice.name]: chatSlice.reducer,
  [statusSlice.name]: statusSlice.reducer,
  [postSlice.name]: postSlice.reducer,
  [timelineSliceNew.name]: timelineSliceNew.reducer,
  [livestream.name]: livestream.reducer,
  [notificationSlice.name]: notificationSlice.reducer,
  [settingsSlice.name]: settingsSlice.reducer,
  [searchSlice.name]: searchSlice.reducer,
  [visionSlice.name]: visionSlice.reducer,
  [livestream_poc.name]: livestream_poc.reducer,
  [analyticsSlice.name]: analyticsSlice.reducer,
  [gettrPay.name]: gettrPay.reducer,
  [stickerSlice.name]: stickerSlice.reducer,
  uploader: DMFileUploader,
  [userGuidesSlice.name]: userGuidesSlice.reducer,
  [embedPost.name]: embedPost.reducer,
  [referSlice.name]: referSlice.reducer,
  [tippingSlice.name]: tippingSlice.reducer,
  [referVerifySlice.name]: referVerifySlice.reducer,
  [maEligibilityCheckSlice.name]: maEligibilityCheckSlice.reducer,
  [aiCopilotSlice.name]: aiCopilotSlice.reducer,
  [liveListSearchSlice.name]: liveListSearchSlice.reducer,
  [liveListHistorySlice.name]: liveListHistorySlice.reducer,
  [postStatsSlice.name]: postStatsSlice.reducer,
  [gettrEmojiSlice.name]: gettrEmojiSlice.reducer,
  [stickerV2Slice.name]: stickerV2Slice.reducer,
  [stickerEmojiSettingSlice.name]: stickerEmojiSettingSlice.reducer,
  [aiAvatarSlice.name]: aiAvatarSlice.reducer,
  [dashboardSlice.name]: dashboardSlice.reducer,
  [liveDashboardSlice.name]: liveDashboardSlice.reducer,
});
