export const tippingErrorConsts = {
  SENDER_PEP: "SENDER_IS_A_PEP_USER",
  RECEIVER_PEP: "RECEIVER_IS_A_PEP_USER",
  SENDER_WALLET_NOT_FOUND: "SENDER_WALLET_NOT_FOUND",
  RECEIVER_WALLET_NOT_FOUND: "RECEIVER_WALLET_NOT_FOUND",
  INSUFFICIENT_BALANCE_FOR_TIPPING: "INSUFFICIENT_BALANCE_FOR_TIPPING",
  SENDER_DAILY_LIMIT_REACHED: "SENDER_DAILY_LIMIT_REACHED",
  CONTENT_DAILY_LIMIT_REACHED: "CONTENT_DAILY_LIMIT_REACHED",
  USER_BLOCKED: "USER_BLOCKED",
};
export const tippingContentType = {
  GTOK: "gtok",
  STREAMING: "streaming",
  POST: "post",
  PROFILE: "profile",
};
export const storeConstants = {
  EMBEDDED_POST: "embeddedPost",
  LOADING: "loading",
  TAB: "tab",
  OPEN: "open",
  ORIGINAL_USER: "originalUser",
  ITEM: "item",
  RECEIVE_RESPONSE: "receiveResponse",
  CUSTOM_AMOUNT: "customAmount",
  ISSENDERALLOWED: "isSenderAllowed",
};

export const tipTypeConstants = {
  AMOUNT_TIP: "AmountTipWithPoints",
  STICKERS_TIP: "StickersTipWithPoints",
};

export const stickersType = {
  thumbnailMobile: "thumbnail_mobile.webp",
  mainMobile: "main_mobile.webp",
};

export const stringConstants = {
  NICE_POST: "nice post",
};
