import GAxios from "src/util/GAxios";
import {settingsConstants} from "src/app/pages/settings/_constants";

export const getNotifSettings = async () => {
  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/${settingsConstants.API_GET_NOTIF_SETTING}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  let res = null;

  await GAxios(
    config,
    (response) => {
      if (response?.data?.result?.data) {
        res = response.data.result.data;
      }
    },
    (err) => {},
  );

  return res;
};
