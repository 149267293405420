import GAxios from "src/util/GAxios";
import {CHAT_API} from "./index";

/**
 * Update group chat metadata
 * @param {string} channelId group chat id
 * @param {string} name channel name
 * @param {string} description channel description
 */
export const updateGroupChat = async ({
  channelId,
  name,
  description,
  image,
  rules,
}) => {
  const data = {
    channel_id: channelId,
    name,
    description,
    image,
  };
  if (rules !== undefined) {
    data.rules = rules;
  }

  const config = {
    method: "patch",
    url: `${process.env.REACT_APP_API_URL}/${CHAT_API}/channel/group/update`,
    data: {
      content: data,
    },
  };

  let res = null;

  await GAxios(config, (response) => {
    if (response?.data?.result) {
      res = response.data.result;
    }
  });

  return res;
};
