import GAxios from "src/util/GAxios";
import {SEARCH_APIS} from "../_constant";

export const fetchProfileSearchResult = async (
  phrase,
  userId,
  cursor,
  max,
  callback,
  errorCallback,
  api = SEARCH_APIS.PROFILE_V3,
) => {
  const decodedPhrase = decodeURIComponent(phrase);
  const content = {
    incl: "poststats|shared|liked|posts|userinfo|followings|followers",
    q: decodedPhrase,
    offset: 0,
    cursor,
    max,
    userId,
  };

  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}${api}`,
    data: {
      content,
    },
    ignoreErrors: api === SEARCH_APIS.PROFILE_V3,
  };
  await GAxios(
    config,
    (res) => {
      res.api = api;
      callback?.(res);
    },
    async (err) => {
      if (api === SEARCH_APIS.PROFILE_V3)
        await fetchProfileSearchResult(
          phrase,
          userId,
          cursor,
          max,
          callback,
          errorCallback,
          SEARCH_APIS.PROFILE_V2,
        );
      if (api === SEARCH_APIS.PROFILE_V2 && errorCallback) errorCallback();
    },
  );
};
