import GAxios from "src/util/GAxios";

export const fetchPopSearch = async () => {
  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/u/search/v2/pop?max=5`,
    ignoreErrors: true,
  };
  return await new Promise((resolve, reject) => {
    GAxios(
      config,
      (response) => {
        const {result = []} = response?.data || {};
        return resolve(result);
      },
      (error) => {
        return reject(error);
      },
    );
  });
};
