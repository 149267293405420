import {
  getStreamBatch,
  getMaStreamBatch,
} from "src/app/components/livestream_poc/api/getStreamBatch";
import {POST_TYPES} from "src/constants/live";

export const fetchStreamData = async (data) => {
  let res = null;
  const streamList =
    data?.filter((item) => item.p_type === POST_TYPES.STREAM) || [];
  const streamsInfo = streamList?.length
    ? await getStreamBatch(streamList)
    : null;

  const maStreamList =
    data?.filter((item) => item.p_type === POST_TYPES.MAESTRO) || [];
  const maStreamsInfo = maStreamList?.length
    ? await getMaStreamBatch(maStreamList)
    : null;
  res = {
    streamsInfo,
    maStreamsInfo,
  };
  return res;
};
