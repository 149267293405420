import GAxios from "src/util/GAxios";

export const getNewsTopics = async () => {
  let result = null;

  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/s/hashtag/suggest?max=15`,
  };

  await GAxios(
    config,
    (res) => {
      result = res;
    },
    (err) => {
      result = null;
    },
  );
  return result;
};
