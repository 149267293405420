import GAxios from "src/util/GAxios";

export const currentUserRemoveUser = async ({
  userId,
  successCallback,
  errorCallback,
}) => {
  if (!userId) {
    return null;
  }

  const config = {
    method: "DELETE",
    url: `${process.env.REACT_APP_API_URL}/u/user/v2/follower/${userId}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    await GAxios(
      config,
      (response) => {
        if (response === null) {
          errorCallback && errorCallback();
          return null;
        }
        successCallback(response);
        const {data} = response;
        return data;
      },
      (error) => {
        console.error(error);
        errorCallback && errorCallback(error);
        return null;
      },
    );
  } catch (err) {
    console.error(err);
    errorCallback && errorCallback(err);
    return null;
  }
};
