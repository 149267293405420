import twemoji from "twemoji";

export function matchAmazonUrl(url) {
  // As of September 17, 2021（UTC+8）, 21 countries in total
  // amazon.com.tr        Turkey
  // amazon.cn            China
  // amazon.com.br        Brazil
  // amazon.co.jp         Japan
  // amazon.ca            Canada
  // amazon.in            India
  // amazon.es            Spain
  // amazon.sa            Saudi Arabia
  // amazon.fr            France
  // amazon.pl            Poland
  // amazon.ae            UAE
  // amazon.com/amzn.to   America
  // amazon.co.uk         U.K
  // amazon.eg            Egypt
  // amazon.nl            Netherlands
  // amazon.sg            Singapore
  // amazon.se            Sweden
  // amazon.it            Italy
  // amazon.de            Germany
  // amazon.com.mx        Mexico
  // amazon.com.au        Australia

  let urlArr = [
    "amazon.com.tr",
    "amazon.cn",
    "amazon.com.br",
    "amazon.co.jp",
    "amazon.ca",
    "amazon.in",
    "amazon.es",
    "amazon.sa",
    "amazon.fr",
    "amazon.pl",
    "amazon.ae",
    "amazon.com",
    "amzn.to",
    "amazon.co.uk",
    "amazon.eg",
    "amazon.nl",
    "amazon.sg",
    "amazon.se",
    "amazon.it",
    "amazon.de",
    "amazon.com.mx",
    "amazon.com.au",
  ];
  for (let item of urlArr) {
    if (url.includes(`//${item}`) || url.includes(`//www.${item}`)) {
      return true;
    }
  }
  return false;
}

export const isImageUrl = (url) => {
  return /\.(xbm|tif|pjp|svgz|jpg|jpeg|ico|tiff|gif|svg|jfif|webp|png|bmp|pjpeg|avif)(\?.*)?$/.test(
    url,
  );
};

export const formatQuery = (query) => {
  if (!query) {
    return null;
  }
  return query;
};

export const invitationFormat = (url) => {
  const re = /\/messages\/invitations\?code=/g;
  if (url.match(re)) {
    return true;
  } else {
    return false;
  }
};

export const groupInvite = (url) => {
  const re = /\/messages\/invitations\?code=/g;
  if (url.match(re)) {
    return true;
  } else {
    return false;
  }
};

export const fileExists = (url) => {
  var http = new XMLHttpRequest();
  http.open("HEAD", url, false);
  http.send();
  return http.status != 404;
};

export const getStickerAndEmojiUrl = (data) => {
  if (!data || data.emoji_id === 0) return "";
  const {packet_id, emoji_id, sticker_id, device = "pc", type} = data;
  const _type = type || (emoji_id ? "emojis" : "stickers");
  const sub_id = emoji_id || sticker_id || "";
  const IMAGE_BASE_URL = process.env.REACT_APP_STICKER_API_CDN
    ? process.env.REACT_APP_STICKER_API_CDN + `/${_type}/v1`
    : `https://stickers.gettr.com/${_type}/v1`;
  const deviceAlias = {
    pc: "main_pc",
    mobile: "main_mobile",
    thumb: "thumbnail_mobile",
  };
  return `${IMAGE_BASE_URL}/${packet_id}/${sub_id ? `${sub_id}/` : ""}${
    deviceAlias[device]
  }.webp`.toLowerCase();
};

export const adaptUrlForRegExp = (url) => {
  const result = url.replace("?", "\\?").replace("/", "\\/");
  return result;
};

export const getLinkHostWithoutWWW = (url) => {
  const domainRegex = /^(?:https?:\/\/)?(?:www\.)?([^/?]+)/i;
  const matches = url.match(domainRegex);
  let result = "";
  if (matches && matches.length >= 2) {
    const domain = matches[1];
    result = domain.startsWith("www.") ? domain.slice(4) : domain;
  }
  return result;
};

export const checkIfThereIsASpecificURL = (text) => {
  if (!text) return text;
  if (!text.startsWith("x.com") && !text.startsWith("www.x.com")) {
    return text;
  }
  const replaceRules = {
    "x.com": "twitter.com",
  };
  let newText = text;
  let hasSpecificURL = false;
  Object.keys(replaceRules).map((key) => {
    const keyReg = new RegExp(key.replace(/[.*?+^$[\]|]/g, "\\$&"), "gi");
    const valueReg = new RegExp(
      replaceRules[key].replace(/[.*?+^$[\]|]/g, "\\$&"),
      "gi",
    );
    newText = newText.replace(keyReg, replaceRules[key]);
    hasSpecificURL = valueReg.test(newText);
  });
  return hasSpecificURL ? newText : "";
};

export const isWebsiteUrl = (url) => {
  const pageFileRegex =
    /\.(htm|html|php|asp|aspx|jsp|cgi|xml|xhtml|shtml)(\?|$)/i;
  const regex = /^(https?:\/\/)?[^\/]+(\/[^\/]+)*\/?$/i;
  return pageFileRegex.test(url) || regex.test(url);
};

const SP_EMOJIS = ["®️", "©️"];
const CDNJS_NOT_SUPPORT_EMOJIS = [
  "#️⃣",
  "*️⃣",
  "0️⃣",
  "1️⃣",
  "2️⃣",
  "3️⃣",
  "4️⃣",
  "5️⃣",
  "6️⃣",
  "7️⃣",
  "8️⃣",
  "9️⃣",
  "®️",
  "©️",
];
export const getEmojiUrl = (emoji) => {
  const isSp = SP_EMOJIS.includes(emoji);
  let code = twemoji?.convert.toCodePoint(emoji);
  code = isSp
    ? code.split("-")[0]
    : code.replace(/^([^-]+)-([^-]+)-([^-]+)$/, "$1-$3");
  return CDNJS_NOT_SUPPORT_EMOJIS.includes(emoji)
    ? `https://cdn.jsdelivr.net/gh/twitter/twemoji@latest/assets/72x72/${code}.png`
    : `https://cdnjs.cloudflare.com/ajax/libs/emoji-datasource-twitter/15.0.1/img/twitter/64/${code}.png`;
};
