import GAxios from "src/util/GAxios";

export const getRingMeState = async (params) => {
  const {uname, tnames, errorCallback} = params;
  if (!uname) return;
  if (!tnames?.length) return;

  const data = JSON.stringify({
    content: {
      uname: uname,
      tnames: tnames,
    },
  });

  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/u/notif/subscribe/status`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
    ignoreErrors: true,
  };

  let res = false;

  await GAxios(config, (response) => {
    if (response && response.data?.result?.message === "success") {
      const {data} = response.data.result;
      if (data && Object.prototype.hasOwnProperty.call(data, tnames[0])) {
        res = data[tnames[0]];
      }
    }
  });

  return res;
};
