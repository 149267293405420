import GAxios from "src/util/GAxios";
import axios from "axios";

let cancelTokenSourceForSearchEmojis;

export const fetchSearch = async (keyword) => {
  if (cancelTokenSourceForSearchEmojis) {
    cancelTokenSourceForSearchEmojis.cancel();
  }
  cancelTokenSourceForSearchEmojis = axios.CancelToken.source();
  const config = {
    method: "get",
    url: process.env.REACT_APP_API_URL + `/v1/emojis/search?keyword=${keyword}`,
    cancelToken: cancelTokenSourceForSearchEmojis.token,
    ignoreErrors: true,
  };

  let res = {err: null, response: null};

  await GAxios(
    config,
    (response) => {
      if (response) {
        res.response = {...response.data.result, keyword};
      } else {
        res.err = true;
      }
    },
    (err) => {
      res.err = true;
    },
  );

  return res;
};
