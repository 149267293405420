import GAxios from "src/util/GAxios";

export const updateConfig = async (data) => {
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/v1/stickers/configurations`,
    data: {
      content: data,
    },
    ignoreErrors: true,
  };
  let res = {};
  await GAxios(
    config,
    (response) => {
      const {rc, result} = response?.data ?? {};

      if (rc === "OK") {
        res = {result, ...data};
      }
    },
    (err) => {
      return console.log(err);
    },
  );
  return res;
};
