import {userConstants} from "../_constants";
import GAxios from "src/util/GAxios";

export const quickSignup = async (params) => {
  if (!params) return;
  const {pwd, username, lang, token, birthyear} = params;
  const _userinfo = {
    username: username,
    lang: lang,
    birthyear: birthyear,
  };

  const content = {
    userinfo: _userinfo,
    authinfo: {
      pwd,
    },
    token,
  };

  const data = {
    content,
  };

  const config = {
    method: "post",
    url: userConstants.API_QUICK_SIGNUP,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  let loginData = {
    authenticated: false,
    userinfo: null,
    err: null,
  };

  const callback = (err, res) => {
    loginData.authenticated = !err;
    loginData.userinfo = res;
    loginData.err = err?.data?.code;

    localStorage.setItem(
      userConstants.LS_SESSION_INFO,
      JSON.stringify(loginData),
    );
  };

  const processResult = (result) => {
    let err = null;

    let authenticated = result["token"];
    let loadedUserInfo = result["user"];

    if (!authenticated) {
      err = new Error("Auth failed");
    } else {
      loadedUserInfo.token = authenticated;
    }
    callback(err, loadedUserInfo);
  };

  await GAxios(
    config,
    (response) => {
      processResult(response.data.result);
    },
    (err) => {
      console.error(err);
      callback(err);
    },
  );

  return loginData;
};
