import GAxios from "src/util/GAxios";

export const fetchDefault = async () => {
  const config = {
    method: "get",
    url: process.env.REACT_APP_API_URL + `/v1/stickers/default`,
  };

  let res = {err: null, response: null};

  await GAxios(
    config,
    (response) => {
      if (response) {
        res.response = response.data.result;
      } else {
        res.err = true;
      }
    },
    (err) => {
      res.err = true;
    },
  );

  return res;
};
