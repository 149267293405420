import GAxios from "src/util/GAxios";
import {showToast} from "src/util/showToast";
import {t} from "src/i18n/utils";

export const getMaestroToken = async (data) => {
  const {hostUserId, userId, token} = data;

  let params = {
    content: {
      hostUserId: hostUserId,
    },
  };
  params = JSON.stringify(params);
  const config = {
    ignoreErrors: true,
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/u/user/v2/matoken`,
    userId: userId,
    token: token,
    data: params,
    timeout: 90000,
  };

  let res = null;

  await GAxios(
    config,
    (response) => {
      if (response?.data?.result) {
        res = {response};
      } else {
        res = response;
      }
    },
    (err) => {
      if (err?.response?.data?.error?.code === "E_METER_LIMIT_EXCEEDED") {
        showToast("warning", t("getter_fe.auth.errors.E_METER_LIMIT_EXCEEDED"));
      }
      res = err;
    },
  );

  return res;
};
