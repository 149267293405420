/**
 * References:
 * - https://react.i18next.com/guides/quick-start#configure-i-18-next
 * - https://react.i18next.com/latest/using-with-hooks#configure-i-18-next
 * - https://react.i18next.com/latest/i18next-instance
 */
import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import resourcesToBackend from "i18next-resources-to-backend";
import {getLang} from "./utils";
import en_us from "src/assets/locales/en_us/translation.json";

export const DEFAULT_LANG = "en_us";

i18next
  .use(
    resourcesToBackend((language, namespace, callback) => {
      import(`../assets/locales/${language}/${namespace}.json`)
        .then((resources) => {
          callback(null, resources);
        })
        .catch((error) => {
          callback(error, null);
        });
    }),
  )
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    partialBundledLanguages: true,
    resources: {
      en_us: {
        translation: en_us,
      },
    },
    lng: getLang(),
    fallbackLng: DEFAULT_LANG,
    react: {
      useSuspense: false,
    },
    parseMissingKeyHandler: (key) => {
      // return empty space if missing keys or loading translation
      if (key.includes("getter.") || key.includes("getter_fe.")) {
        return "\u00A0";
      } else {
        return key;
      }
    },
  });

export {i18next};
