import GAxios from "src/util/GAxios";

export const saveCountryRegion = async (params) => {
  const {country = "", state = "", errorCallback} = params;
  const config = {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    url: `${process.env.REACT_APP_API_URL}/u/user/v2/locale`,
    data: {
      content: {country: country, state: state},
    },
  };

  let res = {response: null, err: null};

  await GAxios(
    config,
    (response) => {
      res.response = response;
    },
    (err) => {
      res.err = true;
      if (errorCallback) errorCallback(err);
    },
  );
  return res;
};
