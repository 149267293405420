import {getSummaryByDate} from "./getSummaryByDate";
import {getUserDataByDate} from "./getUserDataByDate";
import {getPostDataByDate} from "./getPostDataByDate";
import {getUserDataByMonth} from "./getUserDataByMonth";

export const AnalyticsApi = {
  getSummaryByDate,
  getUserDataByDate,
  getUserDataByMonth,
  getPostDataByDate,
};
