import {createSlice, createAction} from "@reduxjs/toolkit";

const NS = "referEarn";

const initialState = {
  referEarnPopup: {
    loading: false,
    tab: 0,
    open: false,
    onboardingForm: false,
    referredUsers: [],
  },
  referralStatus: {
    tab: 0,
    listPage: 0,
  },
};

export const updateReferPopupState = createAction(
  `${NS}/updateReferPopupState`,
);
export const handleReferPopupClose = createAction(
  `${NS}/handleReferPopupClose`,
);
export const handleReferPopupOpen = createAction(`${NS}/handleReferPopupOpen`);
export const handleOnboardingFormClose = createAction(
  `${NS}/handleOnboardingFormClose`,
);
export const handleOnboardingFormOpen = createAction(
  `${NS}/handleOnboardingFormOpen`,
);

export const updateReferralStatus = createAction(`${NS}/updateReferralStatus`);
export const referSlice = createSlice({
  name: NS,
  initialState,
  reducers: {
    updateReferPopupState(state, action) {
      const {value, key} = action.payload;
      state.referEarnPopup[key] = value;
    },
    updateReferralStatus(state, action) {
      const {value, key} = action.payload;
      state.referralStatus[key] = value;
    },
    handleReferPopupOpen(state) {
      state.referEarnPopup.open = true;
    },
    handleReferPopupClose(state) {
      state.referEarnPopup.open = false;
    },
    handleOnboardingFormOpen(state) {
      state.referEarnPopup.onboardingForm = true;
    },
    handleOnboardingFormClose(state) {
      state.referEarnPopup.onboardingForm = false;
    },
  },
});
