import {createSlice, createAsyncThunk, createAction} from "@reduxjs/toolkit";
import {ChatApi} from "../api";
import {InviteLinkApi} from "../api/invite_link";
import {uniq, keys, values, findIndex, sortBy, uniqBy} from "lodash-es";
import AppConsts from "src/app/AppConsts";

const NS = "chat";
const MASSIVE_GROUP_TIPS_OPEN = "MASSIVE_GROUP_TIPS_OPEN";

const draft = JSON.parse(sessionStorage.getItem(AppConsts.LOCAL_DM_DRAFT));

const initialState = {
  availabilities: {}, // {userId1: false, userId2: true, ...}
  chatUsers: {},
  chatUsers_Loading: false,
  chatClient: null,
  unreadCount: 0,
  activeChannel: null,
  activeChannels: [],
  activeChannelId: null,
  activeChannelList: {
    //
    // [channelId]: {
    //    [invitation_code]: "",
    //  [expired]: true / false
    // }
  },
  chatListSort: {},
  isGroupInfoPanelOpen: false,
  isGroupListInfoPanelOpen: false,
  groupListInfoSwitch: "admin", // user
  removedFromChannels: [],
  followersInfo: {
    followers: [],
    reachEnd: false,
    cursor: 0,
  },
  followingsInfo: {
    followings: [],
    reachEnd: false,
    cursor: 0,
  },
  channelLastMsg: {},
  invite_link: {
    url: "",
    loading: false,
  },
  searchMemberText: "",
  openInviteLink: false,
  openInviteLinkTips: {
    open: false,
    code: "",
  },
  openInviteLinkErrorTips: false,
  openInviteLinkSuccessTips: false,
  actionAfterLogin: {
    invitation_code: "",
    data: null,
  },
  inviteLinkStep: {
    /**
     *  1 login not expired (<ChatInvitePopup />) 1 -> 0 ---> link to /messages
     *  2 login expired  (Couldn't join the group <ChatErrorMessageTips /> ) --> click ok --> not jump 2 -> 0 link to /message
     *  3 not login not expired  (Gettr Group Chat Invite, join the Group)   --> click ok --> 3 -> 1  jump (<ChatInvitePopup />)
     *  4 not login expired (Couldn't join the group) ---> click ok --> not jump  4 -> 0
     *  5 not login -> loggin and path === messages jump to home page  <InviteLinkTipsDialogWitchChat />
     */
    status: 0,
  },
  channelWidth: 0,
  addGroupAdmin: {
    isLoading: false,
  },
  removeGroupAdmin: {
    isLoading: false,
  },
  adminsObj: null,
  currentAdmins: {},
  groupChannel: {
    isLoading: false,
  },
  modalOpen: false,
  createChatOptions: {
    open: false,
  },
  groupRuleOptions: {
    open: false,
    operateType: "watch",
    channelId: "",
  },
  forwardMessage: {
    open: false,
    messages: [],
  },
  scopeUsers: {
    isLoading: false,
  },
  originalChannels: [],
  postUsers: {},
  messageCheckbox: {
    open: false,
    messages: [],
  },
  currentThreadMessage: null,
  draft: draft || {},
  tempDraft: "",
  compressNumber: 0,
  massiveGroupTipsOpen: !!localStorage.getItem(MASSIVE_GROUP_TIPS_OPEN),
  isSuper: null,
  leaveChannel: null,
  leavedChannelKeys: [],
  mediaSearch: {
    filterType: "all",
    filterTypeText: "getter_fe.livestream.categories.all",
    showSearch: false,
    result: [],
    filterOpen: false,
    filterList: [],
    initalMessageId: "",
    filterUsers: [],
    filterChannels: [],
    keyword: "",
    searching: false,
    resultChannels: [],
    searchChannels: [],
    parentMessageId: "",
    currentParentMessageId: "",
  },
  showReactionSelector: false,
  lastMessageId: "",
  isMassiveGroup: false,
  cacheChannels: {
    cacheChannelsString: "",
    cacheChannelsResult: null,
  },
  deletedMessages: {},
  bottomOffset: 0,
  isCloseMobileNav: false,
  createdChannelId: "",
  streamChatStatus: "disconnected", // disconnected, connecting, connected
  postTextMetaData: {},
  resetChannelsList: false,
  currentChannelMembers: [],
};

export const getLinkInfoByInviteCode = createAsyncThunk(
  `${NS}/getLinkInfoByInviteCode`,
  async ({invitation_code, authenticated}, thunkAPI) => {
    const data = thunkAPI.getState();

    const userId = data?.auth?.session?.userinfo?._id;
    const result = await InviteLinkApi.getLinkInfoByInviteCode(invitation_code);
    const expired =
      result?.response?.data?.error?.code === "E_CHAT_INVITATION_EXPIRED";

    if (authenticated && !expired && userId) {
      const res1 = await InviteLinkApi.getLinkExistByInviteCode(
        invitation_code,
      );
      if (res1[userId]) {
        thunkAPI.dispatch(setInviteLinkStep(6));
      } else {
        result.userId = userId;
        thunkAPI.dispatch(setInviteLinkStep(5));
      }
    } else {
      if (data.chat.inviteLinkStep.status !== 5) {
        // login , not expired
        if (
          authenticated !== undefined &&
          authenticated &&
          result?.channel_id
        ) {
          thunkAPI.dispatch(setInviteLinkStep(1));
        }
        // login , expired
        if (authenticated !== undefined && authenticated && expired) {
          thunkAPI.dispatch(setInviteLinkStep(2));
        }
        // not login , not expired
        if (
          authenticated !== undefined &&
          !authenticated &&
          result?.channel_id
        ) {
          thunkAPI.dispatch(setInviteLinkStep(3));
        }
        // not login , expired
        if (authenticated !== undefined && !authenticated && expired) {
          thunkAPI.dispatch(setInviteLinkStep(4));
        }
      }
    }

    if (result && result?.channel_id && result?.members?.length >= 1) {
      thunkAPI.dispatch(
        setActionAfterLogin({
          invitation_code: invitation_code,
          data: result,
        }),
      );
      thunkAPI.dispatch(setActiveChannelId({channel: result.channel_id}));
      thunkAPI.dispatch(
        setActiveChannelList({
          channelId: result.channel_id,
          invitation_code: invitation_code,
          expired: false,
        }),
      );
      setTimeout(() => {
        thunkAPI.dispatch(setActiveChannelId({channel: ""}));
      }, 300);
    }
    return result;
  },
);

export const getLinkInfoByInviteCodeNative = createAsyncThunk(
  `${NS}/getLinkInfoByInviteCodeNative`,
  InviteLinkApi.getLinkInfoByInviteCode,
);

export const setInviteLinkStep = createAction(`${NS}/setInviteLinkStep`);
export const setInviteLinkCode = createAction(`${NS}/setInviteLinkCode`);
export const setUnreadFirst = createAction(`${NS}/setUnreadFirst`);
export const setLatestFirst = createAction(`${NS}/setLatestFirst`);

export const getLinkByChannelIdNative = createAsyncThunk(
  `${NS}/getLinkByChannelIdNative`,
  InviteLinkApi.getLinkByChannelId,
);

export const getLinkByChannelId = createAsyncThunk(
  `${NS}/getLinkByChannelId`,
  async (channel_id, thunkAPI) => {
    const result = await InviteLinkApi.getLinkByChannelId(channel_id);
    if (result?.response?.data?.error?.code) {
      thunkAPI.dispatch(
        setinvite_link({
          url: "",
          loading: false,
        }),
      );
      return result;
    } else {
      thunkAPI.dispatch(
        setinvite_link({
          url: result?.link,
          loading: false,
        }),
      );
      return result;
    }
  },
);

export const getLinkExistByInviteCode = createAsyncThunk(
  `${NS}/getLinkExistByInviteCode`,
  async (invitation_code, thunkAPI) => {
    const result = await InviteLinkApi.getLinkExistByInviteCode(
      invitation_code,
    );
    return result;
  },
);

export const setinvite_link = createAction(`${NS}/setinvite_link`);

export const openInviteLink = createAction(`${NS}/openInviteLink`);

export const openInviteLinkTips = createAction(`${NS}/openInviteLinkTips`);

export const setInviteLinkAndChannelInfo = createAction(
  `${NS}/setInviteLinkAndChannelInfo`,
);

/**
 * @typedef {import("@reduxjs/toolkit")} ActionCreatorWithPayload
 */

/**
 * @typedef {Object} ActionAfterLogin
 * @property {string} invitation_code
 */

/**
 * @type {ActionCreatorWithPayload<ActionAfterLogin>}
 */
export const setActionAfterLogin = createAction(`${NS}/setActionAfterLogin`);

export const actionInviteLinkErrorTips = createAction(
  `${NS}/actionInviteLinkErrorTips`,
);

export const openInviteLinkSuccessTips = createAction(
  `${NS}/openInviteLinkSuccessTips`,
);

export const openInviteLinkErrorTips = createAction(
  `${NS}/openInviteLinkErrorTips`,
);

export const postNewLinkWithChannelId = createAsyncThunk(
  `${NS}/postLink`,
  async (channel_id, thunkAPI) => {
    thunkAPI.dispatch(
      setinvite_link({
        url: "",
        loading: true,
      }),
    );
    const result = await InviteLinkApi.postNewLinkWithChannelId(channel_id);
    thunkAPI.dispatch(
      setinvite_link({
        url: result.link,
        loading: false,
      }),
    );

    const reg = /code=(\d|[a-z]){32}/g;
    const invitation_code = reg.exec(result.link) && reg.exec(result.link)[1];
    thunkAPI.dispatch(
      setActiveChannelList({
        channelId: channel_id,
        invitation_code: invitation_code,
        expired: false,
      }),
    );
    return result;
  },
);

export const postLinkJoinByInviteCode = createAsyncThunk(
  `${NS}/postLinkJoinByInviteCode`,
  async (invitation_code, thunkAPI) => {
    const result = await InviteLinkApi.postLinkJoinByInviteCode(
      invitation_code,
    );
    return result;
  },
);

export const getGroupChatByRole = createAsyncThunk(
  `${NS}/getGroupChatByRole`,
  ChatApi.getGroupChatByRole,
);

export const getGroupChatAdmin = createAsyncThunk(
  `${NS}/getGroupChatAdmin`,
  async ({channel_id}, thunkAPI) => {
    const result = await ChatApi.getGroupChatAdmin({channel_id});
    thunkAPI.dispatch(setGroupAdmins(result));
    return result;
  },
);

export const getGroupChatUser = createAsyncThunk(
  `${NS}/getGroupChatUser`,
  ChatApi.getGroupChatUser,
);

export const addGroupAdmin = createAsyncThunk(
  `${NS}/addGroupAdmin`,
  ChatApi.addGroupAdmin,
);

export const removeGroupAdmin = createAsyncThunk(
  `${NS}/removeGroupAdmin`,
  ChatApi.removeGroupAdmin,
);

export const checkAvailability = createAsyncThunk(
  `${NS}/checkAvailability`,
  ChatApi.available,
);

export const createChannel = createAsyncThunk(
  `${NS}/createChannel`,
  ChatApi.createChannel,
);

export const removeChannel = createAsyncThunk(
  `${NS}/removeChannel`,
  ChatApi.removeChannel,
);

export const getChatToken = createAsyncThunk(
  `${NS}/getChatToken`,
  ChatApi.getChatToken,
);

export const setActiveChannelList = createAction(`${NS}/setActiveChannelList`);

export const getChatUsers = createAsyncThunk(
  `${NS}/getChatUsers`,
  ChatApi.getChatUsers,
);

export const createGroupChat = createAsyncThunk(
  `${NS}/createGroupChat`,
  ChatApi.createGroupChat,
);

export const addGroupChatMembers = createAsyncThunk(
  `${NS}/addGroupChatMembers`,
  ChatApi.addGroupChatMembers,
);

export const removeGroupChatMembers = createAsyncThunk(
  `${NS}/removeGroupChatMembers`,
  ChatApi.removeGroupChatMembers,
);

export const updateGroupChat = createAsyncThunk(
  `${NS}/updateGroupChat`,
  ChatApi.updateGroupChat,
);

export const leaveGroupChat = createAsyncThunk(
  `${NS}/leaveGroupChat`,
  ChatApi.leaveGroupChat,
);

export const getScopeUsers = createAsyncThunk(
  `${NS}/getScopeUsers`,
  ChatApi.getScopeUsers,
);
export const getChatConfig = createAsyncThunk(
  `${NS}/getChatConfig`,
  ChatApi.getChatConfig,
);
export const deleteMessageById = createAsyncThunk(
  `${NS}/deleteMessageById`,
  ChatApi.deleteMessageById,
);

export const clearChat = createAction(`${NS}/clearChat`);
export const setChatClient = createAction(`${NS}/setChatClient`);
export const setUnreadCount = createAction(`${NS}/setUnreadCount`);
export const setActiveChannel = createAction(`${NS}/setActiveChannel`);
export const setActiveChannelId = createAction(`${NS}/setActiveChannelId`);
export const mutateChatUser = createAction(`${NS}/mutateChatUser`);
export const setIsGroupInfoPanelOpen = createAction(
  `${NS}/setIsGroupInfoPanelOpen`,
);
export const setIsGroupListInfoPanelOpen = createAction(
  `${NS}/setIsGroupListInfoPanelOpen`,
);
export const addRemovedFromChannel = createAction(
  `${NS}/addRemovedFromChannel`,
);
export const deleteRemovedFromChannel = createAction(
  `${NS}/deleteRemovedFromChannel`,
);
export const updateFollowingsInfo = createAction(`${NS}/updateFollowingsInfo`);
export const setChannelLastMsg = createAction(`${NS}/setChannelLastMsg`);
export const searchMemberText = createAction(`${NS}/searchMemberText`);
export const setChannelWidth = createAction(`${NS}/setChannelWidth`);
export const setModalOpen = createAction(`${NS}/setModalOpen`);
export const setGroupAdmins = createAction(`${NS}/setGroupAdmins`);
export const setCurrentAdmins = createAction(`${NS}/setCurrentAdmins`);
export const setCreateChatOptions = createAction(`${NS}/setCreateChatOptions`);
export const openForwardMessage = createAction(`${NS}/openForwardMessage`);
export const closeForwardMessage = createAction(`${NS}/closeForwardMessage`);
export const resetForwardMessage = createAction(`${NS}/resetForwardMessage`);
export const setOriginalChannels = createAction(`${NS}/setOriginalChannels`);
export const addOriginalChannels = createAction(`${NS}/addOriginalChannels`);
export const removeOriginalChannelById = createAction(
  `${NS}/removeOriginalChannelById`,
);
export const toggleMessageCheckbox = createAction(
  `${NS}/toggleMessageCheckbox`,
);
export const toggleSelectMessage = createAction(`${NS}/toggleSelectMessage`);
export const setForwardChannelId = createAction(`${NS}/setForwardChannelId`);
export const setGroupRuleOptions = createAction(`${NS}/setGroupRuleOptions`);
export const setCurrentThreadMessage = createAction(
  `${NS}/setCurrentThreadMessage`,
);
export const setDraft = createAction(`${NS}/setDraft`);
export const setTempDraft = createAction(`${NS}/setTempDraft`);
export const removeDraft = createAction(`${NS}/removeDraft`);
export const setCompressNumber = createAction(`${NS}/setCompressNumber`);
export const setLeaveChannel = createAction(`${NS}/setLeaveChannel`);
export const addLeavedChannelKey = createAction(`${NS}/addLeavedChannelKey`);
export const setMediaSearch = createAction(`${NS}/setMediaSearch`);
export const setShowReactionSelector = createAction(
  `${NS}/setShowReactionSelector`,
);
export const setLastMessageId = createAction(`${NS}/setLastMessageId`);
export const setIsMassiveGroup = createAction(`${NS}/setIsMassiveGroup`);
export const setCacheChannels = createAction(`${NS}/setCacheChannels`);
export const addDeletedMessages = createAction(`${NS}/addDeletedMessages`);
export const setBottomOffset = createAction(`${NS}/setBottomOffset`);
export const closeMobileNav = createAction(`${NS}/closeMobileNav`);
export const setCreatedChannelId = createAction(`${NS}/setCreatedChannelId`);
export const setStreamChatStatus = createAction(`${NS}/setStreamChatStatus`);
export const setPostTextMetaData = createAction(`${NS}/setPostTextMetaData`);
export const setResetChannelsList = createAction(`${NS}/setResetChannelsList`);
export const setCurrentChannelMembers = createAction(
  `${NS}/setCurrentChannelMembers`,
);

export const chatSlice = createSlice({
  name: NS,
  initialState,
  reducers: {
    setUnreadFirst: (state, {payload}) => {
      state.chatListSort = {
        has_unread: -1,
      };
    },
    setLatestFirst: (state, {payload}) => {
      state.chatListSort = {
        last_updated: -1,
      };
    },
    setInviteLinkAndChannelInfo: (state, {payload}) => {
      const {invitation_code} = payload;
      state.actionAfterLogin.invitation_code = invitation_code;
    },
    setinvite_link: (state, {payload}) => {
      const host = window.location.host;
      state.invite_link.url = payload.url.replace(
        /^(https?:\/\/)[^/]+/,
        `$1${host}`,
      );
      state.invite_link.loading = payload.loading;
    },
    openInviteLink: (state, {payload}) => {
      state.openInviteLink = payload;
    },
    openInviteLinkTips: (state, {payload}) => {
      state.openInviteLinkTips = payload;
    },
    clearChat: (state) => {
      state.availabilities = {};
      state.chatUsers = {};
      state.chatClient = null;
      state.unreadCount = 0;
      state.activeChannel = null;
      state.followersInfo = {
        followers: [],
        reachEnd: false,
        cursor: 0,
      };
      state.activeChannels = [];
      state.inviteLinkStep = {
        status: 0,
      };
      state.actionAfterLogin = {
        invitation_code: "",
        data: null,
      };
      state.openInviteLinkTips = {
        open: false,
        code: "",
      };
      state.openInviteLinkErrorTips = false;
      state.openInviteLinkSuccessTips = false;
      state.cacheChannels = {};
    },
    setChatClient: (state, action) => {
      const {client, unreadCount} = action.payload;
      state.chatClient = client;
      state.unreadCount = unreadCount;
      state.activeChannels = keys(client.activeChannels);
    },
    setUnreadCount: (state, action) => {
      const {unreadCount} = action.payload;
      state.unreadCount = unreadCount;
    },
    setActiveChannel: (state, action) => {
      const {channel} = action.payload;
      state.activeChannel = channel;
    },
    setActiveChannelId: (state, action) => {
      const {channel} = action.payload;
      state.activeChannelId = channel;
    },
    mutateChatUser: (state, action) => {
      const {payload} = action;
      if (payload) {
        const originUser = state.chatUsers[payload._id];
        state.chatUsers = {
          ...state.chatUsers,
          [payload._id]: {
            ...originUser,
            nickname: payload.nickname,
            ico: payload.ico,
            username: payload.username,
            original_name: payload.ousername,
          },
        };
      }
    },
    setIsGroupInfoPanelOpen: (state, action) => {
      const {isOpen} = action.payload;
      state.isGroupInfoPanelOpen = isOpen;
    },
    setIsGroupListInfoPanelOpen: (state, action) => {
      const {isOpen, active} = action.payload;
      state.isGroupListInfoPanelOpen = isOpen;
      state.groupListInfoSwitch = active;
    },
    searchMemberText: (state, {payload}) => {
      state.searchMemberText = payload;
    },
    addRemovedFromChannel(state, action) {
      const channelCId = action.payload;
      if (channelCId) {
        state.removedFromChannels = uniq([
          ...state.removedFromChannels,
          channelCId,
        ]);
      }
    },
    deleteRemovedFromChannel(state, action) {
      const channelCId = action.payload;
      state.removedFromChannels = state.removedFromChannels.filter(
        (cId) => cId !== channelCId,
      );
    },
    updateFollowingsInfo(state, action) {
      const {followings, reachEnd, cursor} = action.payload;
      state.followingsInfo = {
        followings,
        reachEnd,
        cursor,
      };
    },
    setChannelLastMsg: (state, {payload}) => {
      state.channelLastMsg = {...state.channelLastMsg, ...payload};
    },
    setActiveChannelList: (state, {payload}) => {
      const {channelId, invitation_code, expired} = payload;
      if (state.activeChannelList[channelId]) {
        state.activeChannelList[channelId] = {
          ...state.activeChannelList[channelId],
          invitation_code: invitation_code,
          expired: expired,
        };
      } else {
        state.activeChannelList = {
          ...state.activeChannelList,
          [channelId]: {
            invitation_code: invitation_code,
            expired: expired,
          },
        };
      }
    },
    setActionAfterLogin: (state, {payload}) => {
      if (!payload) {
        state.actionAfterLogin = {
          invitation_code: "",
          data: null,
        };
        return;
      }
      const {invitation_code, data} = payload;
      state.actionAfterLogin = {
        invitation_code: invitation_code,
        data: data,
      };
    },
    openInviteLinkErrorTips: (state, {payload}) => {
      state.openInviteLinkErrorTips = payload;
    },
    openInviteLinkSuccessTips: (state, {payload}) => {
      state.openInviteLinkSuccessTips = payload;
    },
    setInviteLinkStep: (state, {payload}) => {
      state.inviteLinkStep.status = payload;
    },
    setChannelWidth: (state, {payload}) => {
      state.channelWidth = payload;
    },
    setModalOpen: (state, {payload}) => {
      state.modalOpen = payload;
    },
    setGroupAdmins: (state, {payload}) => {
      state.adminsObj = payload;
    },
    setCurrentAdmins: (state, {payload}) => {
      state.currentAdmins = {
        ...state.currentAdmins,
        ...payload,
      };
    },
    setCreateChatOptions: (state, {payload}) => {
      const {channelId, operate} = payload;
      if (operate === "add") {
        const scopeUsers = {...state.scopeUsers};
        Object.keys(scopeUsers).map((item) => {
          if (item.indexOf(`_${channelId}_`) !== -1) {
            delete scopeUsers[item];
          }
        });
        state.scopeUsers = scopeUsers;
      }
      state.createChatOptions = payload;
    },
    openForwardMessage: (state, {payload}) => {
      const {open, messages, type, post, user, forwardUser} = payload;
      state.forwardMessage = {
        open: open === undefined ? true : open,
        messages,
        type,
        post,
        user,
        forwardUser,
      };
    },
    closeForwardMessage: (state) => {
      state.forwardMessage.open = false;
    },
    resetForwardMessage: (state) => {
      state.forwardMessage = {
        open: false,
        messages: [],
      };
    },
    setOriginalChannels: (state, {payload}) => {
      state.originalChannels = payload;
    },
    addOriginalChannels: (state, {payload = []}) => {
      if (!state.originalChannels?.length) return;
      const newOriginalChannels = uniqBy(
        [...payload.reverse(), ...state.originalChannels],
        "id",
      );
      state.originalChannels = newOriginalChannels;
    },
    removeOriginalChannelById: (state, {payload}) => {
      const newOriginalChannels = [...state.originalChannels];
      newOriginalChannels.splice(
        findIndex(newOriginalChannels, (c) => c.id === payload),
        1,
      );
      state.originalChannels = newOriginalChannels;
    },
    toggleMessageCheckbox: (state, {payload}) => {
      state.messageCheckbox = {
        open: payload === undefined ? !state.messageCheckbox.open : payload,
      };
    },
    toggleSelectMessage: (state, {payload}) => {
      const messages = [...(state.messageCheckbox.messages || [])];
      const index = findIndex(messages, (item) => item.id === payload.id);
      if (index !== -1) {
        messages.splice(index, 1);
      } else {
        messages.push({...payload});
      }
      state.messageCheckbox.messages = sortBy(messages, ["created_at"]);
    },
    setGroupRuleOptions: (state, {payload}) => {
      state.groupRuleOptions = payload;
    },
    setCurrentThreadMessage: (state, {payload}) => {
      state.currentThreadMessage = payload;
    },
    setDraft: (state, {payload}) => {
      const draft = {
        ...state.draft,
        ...payload,
      };
      state.draft = draft;
      sessionStorage.setItem(AppConsts.LOCAL_DM_DRAFT, JSON.stringify(draft));
    },
    setTempDraft: (state, {payload}) => {
      state.tempDraft = payload;
    },
    removeDraft: (state, {payload}) => {
      const draft = {...state.draft};
      delete draft[payload];
      state.draft = draft;
      sessionStorage.setItem(AppConsts.LOCAL_DM_DRAFT, JSON.stringify(draft));
    },
    setCompressNumber: (state, {payload}) => {
      state.compressNumber = payload;
    },
    setLeaveChannel: (state, {payload}) => {
      state.leaveChannel = payload;
    },
    addLeavedChannelKey: (state, {payload}) => {
      state.leavedChannelKeys = [...state.leavedChannelKeys, payload];
    },
    setMediaSearch: (state, {payload = {}}) => {
      const {toggleChannel, toggleUser} = payload;
      if (toggleChannel) {
        const filterChannels = [...(state.mediaSearch.filterChannels || [])];
        const index = findIndex(
          filterChannels,
          (item) => item.channel.cid === toggleChannel.channel.cid,
        );
        index === -1
          ? filterChannels.push(toggleChannel)
          : filterChannels.splice(index, 1);
        state.mediaSearch = {
          ...state.mediaSearch,
          keyword: "",
          filterChannels,
          resultChannels: [],
          searchChannels: [],
        };
      } else if (toggleUser) {
        const filterUsers = [...(state.mediaSearch.filterUsers || [])];
        const index = findIndex(
          filterUsers,
          (item) => item.id === toggleUser.id,
        );
        index === -1
          ? filterUsers.push(toggleUser)
          : filterUsers.splice(index, 1);
        state.mediaSearch = {
          ...state.mediaSearch,
          keyword: "",
          filterUsers,
          resultChannels: [],
          searchChannels: [],
        };
      } else {
        state.mediaSearch = {
          ...state.mediaSearch,
          ...payload,
        };
      }
    },
    setShowReactionSelector: (state, {payload}) => {
      state.showReactionSelector = payload;
    },
    setLastMessageId: (state, {payload}) => {
      state.lastMessageId = payload;
    },
    setIsMassiveGroup: (state, {payload}) => {
      state.isMassiveGroup = payload;
    },
    setCacheChannels: (state, {payload}) => {
      state.cacheChannels = payload;
    },
    addDeletedMessages: (state, {payload}) => {
      state.deletedMessages = {
        ...state.deletedMessages,
        ...payload,
      };
    },
    setBottomOffset: (state, {payload}) => {
      state.bottomOffset = payload;
    },
    closeMobileNav: (state, {payload}) => {
      state.isCloseMobileNav = payload;
    },
    setCreatedChannelId: (state, {payload}) => {
      state.createdChannelId = payload;
    },
    setStreamChatStatus: (state, {payload}) => {
      state.streamChatStatus = payload;
    },
    setPostTextMetaData: (state, {payload}) => {
      const {key, value} = payload;
      state.postTextMetaData[key] = value;
    },
    setResetChannelsList: (state, {payload}) => {
      state.resetChannelsList = payload;
    },
    setCurrentChannelMembers: (state, {payload}) => {
      state.currentChannelMembers = payload;
    },
  },
  extraReducers: (builder) => {
    // Doc: https://redux-toolkit.js.org/usage/usage-with-typescript#type-safety-with-extrareducers

    builder.addCase(checkAvailability.fulfilled, (state, {payload}) => {
      if (payload) {
        state.availabilities = {
          ...state.availabilities,
          ...payload,
        };
      }
    });

    builder.addCase(getChatUsers.pending, (state, {payload}) => {
      state.chatUsers_Loading = true;
    });

    builder.addCase(getChatUsers.fulfilled, (state, {payload}) => {
      if (payload) {
        state.chatUsers = {
          ...state.chatUsers,
          ...payload,
        };
      }
      state.chatUsers_Loading = false;
    });

    builder.addCase(postNewLinkWithChannelId.pending, (state, {payload}) => {
      state.invite_link.loading = true;
    });

    builder.addCase(postNewLinkWithChannelId.fulfilled, (state, {payload}) => {
      state.invite_link.url = payload.link;
      state.invite_link.loading = false;
    });
    builder.addCase(addGroupAdmin.pending, (state, {payload}) => {
      state.addGroupAdmin.isLoading = true;
    });
    builder.addCase(addGroupAdmin.fulfilled, (state, {payload}) => {
      state.addGroupAdmin.isLoading = false;
    });
    builder.addCase(removeGroupAdmin.pending, (state, {payload}) => {
      state.removeGroupAdmin.isLoading = true;
    });
    builder.addCase(removeGroupAdmin.fulfilled, (state, {payload}) => {
      state.removeGroupAdmin.isLoading = false;
    });
    builder.addCase(updateGroupChat.pending, (state, {payload}) => {
      state.groupChannel.isLoading = true;
    });
    builder.addCase(updateGroupChat.fulfilled, (state, {payload}) => {
      state.groupChannel.isLoading = false;
    });
    builder.addCase(updateGroupChat.rejected, (state, {payload}) => {
      state.groupChannel.isLoading = false;
    });
    builder.addCase(getScopeUsers.pending, (state, {payload}) => {
      state.scopeUsers.isLoading = true;
    });
    builder.addCase(getScopeUsers.fulfilled, (state, {payload}) => {
      const {list, scope, channelId = "", keyword = ""} = payload || {};
      state.scopeUsers[`${scope}_${channelId}_${keyword}`] = values(list);
      state.scopeUsers.isLoading = false;
    });
    builder.addCase(getScopeUsers.rejected, (state, {payload}) => {
      state.scopeUsers.isLoading = false;
    });
    builder.addCase(getChatConfig.fulfilled, (state, {payload}) => {
      state.isSuper = payload.is_super;
    });
    builder.addCase(removeChannel.fulfilled, (state, {payload}) => {
      if (state.createdChannelId === payload.channel_id) {
        state.createdChannelId = "";
      }
    });
  },
});
