import GAxios from "src/util/GAxios";

let socialStatus = {
  apple: false,
  google: false,
};

export const getSocialStatus = async () => {
  const config = {
    method: "get",
    url: process.env.REACT_APP_API_URL + "/v1/s/social/status",
    headers: {
      "Content-Type": "application/json",
    },
  };

  await GAxios(
    config,
    (response) => {
      if (response?.data?.result) {
        socialStatus = response?.data?.result;
      }
    },
    (err) => {
      console.error(err);
    },
  );

  return socialStatus;
};

export const getSocialConnections = async () => {
  const config = {
    method: "get",
    url: process.env.REACT_APP_API_URL + "/u/user/v2/connections",
    headers: {
      "Content-Type": "application/json",
    },
  };

  await GAxios(
    config,
    (response) => {
      if (response?.data?.result) {
        socialStatus = response?.data?.result;
      }
    },
    (err) => {
      console.error(err);
    },
  );

  return socialStatus;
};
