import {
  isResponseOk,
  parseItemStats,
  parseTimelineFeed,
  parseUser,
} from "src/util/FeedUtils";
import GAxios from "src/util/GAxios";
import {timelineConstants} from "../_constants";

export const getTrendingFeed = ({lang, max = 30}) => {
  const offset = 0;
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/u/post/v2/trending`,
      params: {
        lang,
        offset,
        max,
        incl: "posts|stats|userinfo|shared|liked",
        feedType: "f_tr",
      },
    };
    GAxios(
      config,
      (res) => {
        if (isResponseOk(res)) {
          const {aux, data} = res?.data?.result ?? {};
          const {list} = data ?? {};
          const {lks: likedPosts, removed, shrs: sharedPosts} = aux ?? {};
          const users = parseUser(aux);
          let posts = parseTimelineFeed(list, aux, "trending");
          let count = posts?.length ?? 0;
          count = count - (posts?.length ?? 0);
          const postStats = parseItemStats(aux);

          return resolve({
            likedPosts,
            posts,
            removed: removed + count,
            users,
            sharedPosts,
            offset: offset + timelineConstants.MAX_BATCH_SIZE,
            postStats,
          });
        }

        return resolve(null);
      },
      (err) => {
        return reject({err});
      },
    );
  });
};
