import {aiAddText} from "./aiAddText";
import {aiReduceText} from "./aiReduceText";
import {aiCorrectGrammar} from "./aiCorrectGrammar";
import {aiAddRelatedHashtags} from "./aiAddRelatedHashtags";
import {aiRephrase} from "./aiRephrase";
import {aiFeedback} from "./aiFeedback";
import {aiVersion} from "./aiVersion";
import {aiCheckPermission} from "./aiCheckPermission";

export const AICopilotApi = {
  aiAddText,
  aiReduceText,
  aiCorrectGrammar,
  aiAddRelatedHashtags,
  aiRephrase,
  aiFeedback,
  aiVersion,
  aiCheckPermission,
};
