import {isResponseOk} from "src/util/FeedUtils";
import GAxios from "src/util/GAxios";

export const putGTokViewed = async ({
  postId,
  viewDur,
  playDur,
  act_type,
  view_location,
}) => {
  let resp = null;
  let viewDuration = viewDur;
  let playDuration = playDur;

  if (!playDur) {
    return null;
  }
  if (!viewDur) {
    viewDuration = playDur;
  }

  viewDuration = Math.floor(viewDuration);
  playDuration = Math.floor(playDuration);

  try {
    const config = {
      method: "PUT",
      url: process.env.REACT_APP_API_URL + `/u/post/${postId}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        content: {
          view_dur: viewDuration,
          play_dur: playDuration,
          act_type: act_type,
        },
      },
      ignoreErrors: true,
    };

    if (view_location) {
      config.data.content.view_location = view_location;
    }

    await GAxios(
      config,
      (res) => {
        if (isResponseOk(res)) {
          resp = res?.data?.result;
        }
      },
      (err) => {
        console.error(err);
      },
    );
  } catch (error) {
    console.error(error);
  }

  return resp;
};
