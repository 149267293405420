import {fetchDefault} from "./fetchDefault";
import {fetchRecent} from "./fetchRecent";
import {fetchSearch} from "./fetchSearch";
import {fetchUse} from "./fetchUse";

export const StickerV2Api = {
  fetchDefault,
  fetchRecent,
  fetchSearch,
  fetchUse,
};
