import {SharedColors} from "../../../types/theme";

export const sharedThemePalette_v2: SharedColors = {
  universal: {
    white: "#FFFFFF",
    black: "#000000",
    text: {
      [80]: "#E7E8EA",
    },
  },
};
