import {PropsWithChildren, useMemo} from "react";
import {unstable_HistoryRouter as OriginHistoryRouter} from "react-router-dom";
import {history} from "src/configs/history";
import HistoryRouterContext from "./HistoryRouterContext";

export interface HistoryRouterProps {}

function HistoryRouter(props: PropsWithChildren<HistoryRouterProps>) {
  const map = useMemo(() => new Map<string, Record<string, any>>(), []);
  return (
    <OriginHistoryRouter history={history}>
      <HistoryRouterContext.Provider value={map}>
        {props.children}
      </HistoryRouterContext.Provider>
    </OriginHistoryRouter>
  );
}

export default HistoryRouter;
