import {createTheme} from "@material-ui/core/styles";
import {darkThemePalette_v2} from "src/styles/themes/purpleDark/darkThemePalette_v2";
import {
  newStaticColors,
  sharedThemePalette as sharedPalette,
} from "src/styles/themes/shared/sharedThemePalette";
import {sharedThemePalette_v2} from "src/styles/themes/shared/sharedThemePalette_v2";
import {HEADER_HEIGHT} from "src/util/navigation";
import {darkThemePalette as palette, newDarkColors} from "./darkThemePalette";

const theme = createTheme({
  type: "purple_dark",
  breakpoints: {
    values: {
      xl: 1920,
      lg: 1336,
      md: 1024,
      sm: 800,
      xs: 0,
    },
  },
  palette: {
    type: "dark",
    primary: {
      main: palette.darkThemePrimary,
    },
    secondary: {
      main: palette.darkThemeSecondary,
    },
    lightBlue: {
      main: palette.darkThemeLightblue,
    },
    green: {
      main: palette.darkThemeGreen,
    },
    // redLabel: {
    //   main: palette.darkThemeRedLabel,
    // },

    text: {
      primary: palette.darkTextPrimary,
      secondary: palette.darkTextSecondary,
      grey: palette.darkTextGrey,
      grey2: palette.darkTextGrey2,
      grey3: palette.darkTextSecondary,
      grey4: palette.darkTextGrey4,
      disabled: palette.darkButtonTextWhiteDisabled,
      disabled2: palette.darkButtonTextWhiteDisabled2,
      link: palette.darkThemeLightblue,
      lightGrey: palette.darkTextLightGrey,
      ntfGrey: palette.darkTextNtfGrey,
      // linkClicked: palette.darkTextLinkClicked,
      keywordMatched: palette.darkKeywordMatched,
    },

    button: {
      primary: {
        main: palette.darkButtonPrimary,
        contrast: palette.darkButtonTextWhite,
        dark: palette.darkButtonPrimaryHover,
        light: palette.darkBgBlueToast,
        disabled: palette.darkButtonPrimaryDisabled,
        outlinedHover: palette.darkButtonRedOutlibeBgHover,
        tab: palette.darkButtonTab,
        tabdisable: palette.darkButtonTabDisable,
        tabBtnDistext: palette.tabBtnDistext,
        tabBtntext: palette.darkButtonTextBk,
      },
      secondary: {
        main: palette.darkButtonRed, // used for both border & bg
        contrast: palette.darkButtonTextWhite,
        dark: palette.darkButtonRedHover, // used for hover
        disabled: palette.darkButtonRedDisable,
      },
      grey: {
        main: palette.darkButtonGrey,
        contrast: palette.darkTextPrimary,
        dark: palette.darkButtonGreyHover,
        disabled: palette.darkButtonGrey, // Why not darkButtonGreyDisabled?
      },
    },

    background: {
      white: palette.darkBgWhite,
      default: palette.darkBgDefault,
      light: palette.darkBgLight1,
      light2: palette.darkBgLight2,
      dark: palette.darkBgWhiteHover,
      tooltip: "#666",
      transparent: "transparent",
      notif: palette.darkBgBlueToast,
      hover: palette.darkButtonPrimaryHover,
      black: sharedPalette.utilityBlack,
      red: palette.darkBgRed,
      grey: palette.darkBgGrey,
      grey3: palette.darkBgGrey3,
      grey4: palette.darkBgGrey4,
      lightBlueHover: palette.darkBgBlueHover,
      lightBlueHover1: palette.darkBgBlueHover1,
      whiteOutlineBgHover: palette.darkButtonWhiteOutlineBgHover,
      timelineHover: palette.darkBgTimelineHover,
      iconHover: palette.darkBgBlueHover,
      green: palette.darkBgGreen,
      popupOverlay: palette.darkPopupOverlayBackground,
      popupOverlay2: palette.darkPopupOverlayBackground2,
      popupOverlay3: palette.darkPopupOverlayBackground3,
      rightPanel: palette.darkBgRightPanel,
      orangeToast: palette.darkBgOrangeToast,
      blackbg: palette.darkButtonTextBk,
      moreIconHover: palette.darkBgTransIconHover,
    },

    line: {
      grey1: palette.darkLineGrey1,
      grey2: palette.darkLineGrey2,
      grey3: palette.darkLineGrey3,
      blue: palette.darkLineBlue,
      orange: palette.darkLineOrange,
    },

    icon: {
      primary: palette.darkIconPrimary,
      grey: palette.darkIconGrey,
      bgGrey: palette.darkIconBgGrey,
      bgGrey2: palette.darkIconBgGrey2,
      bgGrey2Hover: palette.darkIconBgGrey2Hover,
      bgDark: palette.darkIconBgDark,
      disabled: palette.darkIconDisabled,
      moreLink: palette.darkThemeWhite,
    },

    overlay: {
      dark: palette.darkOverlayDark1,
      light: palette.darkOverlayLight1,
      grey: palette.darkOverlayGray,
    },

    utility: {
      warning: sharedPalette.utilityWarning,
      white: sharedPalette.utilityWhite,
      black: sharedPalette.utilityBlack,
      darkGrey: sharedPalette.utilityBlkLighten,
      grey: sharedPalette.utilityGray,
      grey2: sharedPalette.utilityGray2,
      green: sharedPalette.utilityGreen,
      avatarBorder: palette.darkLineGrey2,
      redHoverBg: sharedPalette.utilityRedHoverBg,
      blueHoverBg: sharedPalette.utilityBlueHoverBg,
      lightboxIconBgHover: sharedPalette.utilityLightboxIconBgHover,
      greenHoverBg: sharedPalette.utilityGreenHoverBg,
      iconBgHover: palette.darkUniqIconBtnHighlight,
    },

    unique: {
      navText: palette.darkUniqNavText,
      headerIconPrimary: palette.darkUniqHeaderIconPrimary,
      bannerBlack: palette.darkUniqBannerBlack,
      toasterTextBlue: palette.darkUniqToasterTextBlue,
      bannerPrimary: palette.darkUniqBannerPrimary,
      toasterRedBg: palette.darkUniqToasterRedBg,
      textErrorBg: palette.darkUniqTextErrorBg,
      textHighlight: palette.darkUniqTextHighlight,
      iconBtnHighlight: palette.darkUniqIconBtnHighlight,
      chatBubble: palette.darkUniqChatBubble,
      chatBubble2: palette.darkUniqChatBubble2,
    },
    scrollbar: {
      background: palette.darkScrollbarBackground,
      thumb: palette.darkScrollbarThumb,
    },
    newColors: {
      ...newStaticColors,
      ...newDarkColors,
    },
    colors_v2: {...darkThemePalette_v2, ...sharedThemePalette_v2},
  },
  typography: {
    h1: {
      fontSize: "22px",
      fontWeight: 900,
      lineHeight: "28.25px",
    },
    h2: {
      fontSize: "18px",
      fontWeight: 800,
      lineHeight: "21.48px",
    },
    body1: {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "22.4px",
    },
    body2: {
      fontSize: "14px",
      fontWeight: 400,
    },
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      "Helvetica",
      "Arial",
      "sans-serif",
    ].join(","),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
    fontWeightBolder: 800,
  },
  mixins: {
    app: {
      maxWidth: 1400,
    },
    header: {
      height: HEADER_HEIGHT, // 60px + 1px border, due to box-sizing: border-box
    },
    ellipsis: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    dropdownMenu: {
      width: 240,
      widthL: 358,
    },
    post: {
      shadow: "0px 0px 7px 6px rgba(0, 0, 0, 0.02)",
      0: "0 0 2px 2px rgba(239, 239, 239, 0.08)",
      shadow1: "0px 0px 7px 6px rgba(0, 0, 0, 0.03)",
    },
    boxShadow: {
      primary: "0 0 4px 1px rgba(239, 239, 239, 0.06)",
      menu: "0 0 2px 2px rgba(239, 239, 239, 0.08)",
      0: "0 0 8px 2px rgba(239, 239, 239, 0.06)",
      1: "0 0 10px 4px rgba(239, 239, 239, 0.06)",
      2: "0 0 14px 8px rgba(239, 239, 239, 0.06)",
      3: "0 0 20px 14px rgba(239, 239, 239, 0.06)",
      4: "0 0 7px 6px rgba(0, 0, 0, 0.03)",
      5: "0 0 4px 0 rgba(0, 0, 0, 0.33)",
    },
    gradient: {
      1: "linear-gradient(90deg, #282a2a 0%, #282a2a 58%, rgba(40, 42, 42, 0.28) 87%)",
      2: "linear-gradient(90deg, rgba(40, 42, 42, 0.28) 13%, #282a2a 42%, #282a2a 100%);",
      sliderNext:
        "linear-gradient(to left, #0F0F0F 0%, rgba(15, 15, 15, 0.98) 10.88%, rgba(15, 15, 15, 0.87) 19.43%, rgba(15, 15, 15, 0.81) 27.2%, rgba(15, 15, 15, 0.63) 35.74%, rgba(15, 15, 15, 0.21) 48.95%, rgba(15, 15, 15, 0.00) 60.22%)",
      sliderPrev:
        "linear-gradient(to right, #0F0F0F 0%, rgba(15, 15, 15, 0.98) 10.88%, rgba(15, 15, 15, 0.87) 19.43%, rgba(15, 15, 15, 0.81) 27.2%, rgba(15, 15, 15, 0.63) 35.74%, rgba(15, 15, 15, 0.21) 48.95%, rgba(15, 15, 15, 0.00) 60.22%)",
    },
  },
  zIndex: {
    header: 1000,
  },
  overrides: {
    MuiAvatar: {
      root: {
        textTransform: "uppercase",
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: darkThemePalette_v2.bannerOverlay,
      },
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
});

export default theme;
