import GAxios from "src/util/GAxios";
import {CHAT_API} from "../index";

export const getLinkExistByInviteCode = async (invitation_code) => {
  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/${CHAT_API}/channel/group/link/exist?code=${invitation_code}`,
    ignoreErrors: true,
  };

  let res = null;
  try {
    await GAxios(
      config,
      (response) => {
        if (response?.data?.result) {
          res = response.data.result;
        }
      },
      (error) => {
        res = error;
      },
    );
  } catch (error) {
    return false;
  }

  return res;
};
