export const userConstants = {
  LS_SESSION_INFO: "LS_SESSION_INFO",
  LS_SESSION_INFOS: "LS_SESSION_INFOS",
  API_LOGIN_URL: `${process.env.REACT_APP_API_URL}/u/user/v2/login`,
  API_SEND_VERIF_CODE_EMAIL: `${process.env.REACT_APP_API_URL}/u/user/v2/verifycode/email`,
  API_SEND_VERIF_LINK_EMAIL: `${process.env.REACT_APP_API_URL}/u/user/v2/magic/email`,
  API_CONFIRM_VERIF_LINK_EMAIL: `${process.env.REACT_APP_API_URL}/u/user/v2/magic/confirmemail`,
  API_SEND_VERIF_CODE_SMS: `${process.env.REACT_APP_API_URL}/u/user/v2/verifycode/sms`,
  API_SEND_VERIF_CODE_WHATSAPP: `${process.env.REACT_APP_API_URL}/u/user/v2/verifycode/whatsapp`,
  API_SELF_SEND_VERIF_CODE: `${process.env.REACT_APP_API_URL}/u/user/v2/verifycode/user`,
  API_VERIF_CODE: `${process.env.REACT_APP_API_URL}/u/user/v2/verifycode/verify`,
  API_CHANGE_PWD: `${process.env.REACT_APP_API_URL}/u/user/v2/password/reset`,
  API_CREATE_PWD: `${process.env.REACT_APP_API_URL}/u/user/v2/password/create`,
  API_SIGNUP: `${process.env.REACT_APP_API_URL}/u/user/v2/signup`,
  API_EXPRESS_SIGNUP: `${process.env.REACT_APP_API_URL}/u/user/v2/signup/express`,
  API_GET_KEYS: `${process.env.REACT_APP_API_URL}/s/v1/keys`,
  API_CHANGE_USERNAME: `${process.env.REACT_APP_API_URL}/u/user/v2/change_username`,
  API_QUICK_SIGNUP: `${process.env.REACT_APP_API_URL}/u/quick_signup`,
  API_RECOVERY_EMAIL: `${process.env.REACT_APP_API_URL}/u/bind_email`,
  API_RECOVERY_EMAIL_V2: `${process.env.REACT_APP_API_URL}/u/user/v2/email/bind`,
  API_RECOVERY_SMS: `${process.env.REACT_APP_API_URL}/u/bind_sms`,
  API_RECOVERY_SMS_V2: `${process.env.REACT_APP_API_URL}/u/user/v2/sms/bind`,
  API_LOGOUT: `${process.env.REACT_APP_API_URL}/s/logout`,
  API_CHANGE_EMAIL: `${process.env.REACT_APP_API_URL}/u/change_email`,
  API_CHANGE_EMAIL_V2: `${process.env.REACT_APP_API_URL}/u/user/v2/email/change`,
  API_CHANGE_SMS: `${process.env.REACT_APP_API_URL}/u/change_sms`,
  API_CHANGE_SMS_V2: `${process.env.REACT_APP_API_URL}/u/user/v2/sms/change`,
  API_CHANGE_PWD_V2: `${process.env.REACT_APP_API_URL}/u/user/v2/password/change`,
  API_QR_GET_UUID: `${process.env.REACT_APP_API_URL}/u/auth/v2/qrlogin/uuid`,
  API_QR_GET_IMG: `${process.env.REACT_APP_API_URL}/u/auth/v2/qrcode/`,
  API_QR_LOGIN: `${process.env.REACT_APP_API_URL}/u/auth/v2/qrlogin`,
  API_QR_APP_LOGIN: `${process.env.REACT_APP_API_URL}/u/auth/v2/qrcode/s/`,
  API_GET_INTERESTS: `${process.env.REACT_APP_API_URL}/u/user/v2/interests`,
  API_UPDATE_INTERESTS: `${process.env.REACT_APP_API_URL}/u/user/v2/profile/update`,
  API_SHOW_INTEREST: `${process.env.REACT_APP_API_URL}/u/user/v2/pop_config`,
  API_GET_USER_PREMIUM_STATUS: `${process.env.REACT_APP_API_URL}/gp/api/subscription/status`,
  API_GET_USER_PREMIUM_PRICES: `${process.env.REACT_APP_API_URL}/gp/api/stripe/products/default/prices`,
  API_GET_USER_PREMIUM_URL: `${process.env.REACT_APP_API_URL}/gp/api/transactions/subscriptions`,
  SEND_VERIF_CODE_REQ_TYPE: "pwdreset",
  VERIF_CODE_REQ_TYPE: "pwdreset",
  VERIF_CODE_REQ_TYPE_SIGNUP: "signup",
  VERIF_CODE_REQ_TYPE_LOGIN: "otplogin",
  VERIF_CODE_REQ_TYPE_OTPVERIFY: "otpverify",
  VERIF_CODE_REQ_TYPE_CLAIM: "claim",
  VERIF_CODE_REQ_TYPE_EMAIL: "email",
  VERIF_CODE_REQ_TYPE_PHONE: "sms",
  REQUEST_TYPE_CHANGE_EMAIL: "chgemail",
  REQUEST_TYPE_CHANGE_SMS: "chgsms",
  FAKE_REQUEST: "fake_request",
  STEP_LOGIN: "step_login",
  STEP_LOGIN_PASSWORD: "step_login_password",
  STEP_SEND_CODE: "step_send_code",
  STEP_VERIF_CODE: "step_verif_code",
  STEP_CHANGE_PASSWORD: "step_change_password",
  STEP_CHANGE_USERNAME: "step_change_username",
  STEP_FIRST_TYPING: "step_first_typing",
  STEP_VERIFY_CODE: "step_verify_code",
  STEP_FORM: "step_form",
  CHANGE_USERNAME_ALERT: "change_username_alert",
  CHANGE_USERNAME_ADD_PHONE_EMAIL: "change_username_add_phone_email",
  CHANGE_USERNAME_LINK: "change_username_link",
  CHANGE_USERNAME_CHOOSE: "change_username_choose",
  CHANGE_USERNAME_FORM: "change_username_form",
  STEP_FIRST_LOGIN: "step_first_login",
  STEP_SUGGESTION: "step_suggestion",
  STEP_VERIF_ERROR: "step_verif_error",
  ERROR_TYPE_DEFAULT: "error_type_default",
  ERROR_TYPE_SERVER: "error_type_server",
  STEP_BUTTONS: "step_buttons",
  STEP_EXPRESS: "step_express",
  STEP_SIGNUP: "step_signup",
  STEP_USERNAME: "step_username",
  CLAIM_SIGNUP: "claim_signup",
  CLAIM_VERIFY: "claim_verify",
  CLAIM_VERIFY_IG: "claim_verify_ig",
  VERIF_CODE_TYPE_F_PWD: "verif_code_type_f_pwd",
  VERIF_CODE_TYPE_SIGNUP: "verif_code_type_signup",
  VERIF_CODE_TYPE_LOGIN: "verif_code_type_login",
  VERIF_CHANGE_USERNAME: "verif_change_username",
  DISCOVER_PEOPLE_TYPE_SIGNUP: "discover_people_type_signup",
  E_METER_LIMIT_EXCEEDED: "E_METER_LIMIT_EXCEEDED",
  THEME: "theme",
  INVITE_UN: "invite_un",
  INVITE_CODE: "invitecode",
  MODE_EMAIL: "email",
  MODE_PHONE: "phone",
  LINK_PHONE_EMAIL: "link_phone_email",
};
