import GAxios from "src/util/GAxios";
import {CHAT_API} from "./index";

/**
 * Leave the group chat.
 * If the user is the owner of the group chat(who created the group chat), the group chat will be disabled.
 * @param {string} channelId group chat id
 */
export const leaveGroupChat = async ({channelId}) => {
  const config = {
    method: "delete",
    url: `${process.env.REACT_APP_API_URL}/${CHAT_API}/channel/group/leave`,
    data: {
      content: {
        channel_id: channelId,
      },
    },
    ignoreErrors: true,
  };

  let res = null;

  await GAxios(
    config,
    (response) => {
      if (response?.data?.result) {
        res = response.data.result;
      }
    },
    (error) => {
      res = error;
    },
  );

  return res;
};
