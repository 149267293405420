import GAxios from "src/util/GAxios";

export const fetchSearchChoices = async (data) => {
  let {max, phrase, pmax, query} = data;

  if (!max || (!phrase && !query)) return;

  // Below-mentioned commented lines are removing the # and @ key  in the API payload
  // if (phrase.startsWith("#") || phrase.startsWith("@")) {
  //   phrase = phrase.substring(1);
  // }
  if (!phrase) phrase = query;
  if (phrase.startsWith("%23") || phrase.startsWith("%40")) {
    phrase = phrase.substring(3);
  }

  const config = {
    method: "get",
    url: `${
      process.env.REACT_APP_API_URL
    }/u/posts/srch/choices?phrase=${encodeURIComponent(
      phrase,
    )}&max=${max}&incl=userinfo|followings|followers&pmax=${pmax}`,
  };

  let res = {err: null, response: null};

  await GAxios(
    config,
    (response) => {
      if (response?.data?.result) {
        res.response = response.data.result;
      } else {
        res.err = true;
      }
    },
    (err) => {
      res.err = true;
    },
  );

  return res;
};
