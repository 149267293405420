export const settingsConstants = {
  DELETE_ACCOUNT_INFO: "delete_account_info",
  DELETE_ACCOUNT_FORM: "delete_account_form",
  NOTIF_TYPE_LIST: "notif_type_list",
  NOTIF_TYPE_CHECKBOX: "notif_type_checkbox",
  API_GET_NOTIF_SETTING: "u/notif/settings",
  API_UPDATE_NOTIF_SETTING: "u/notif/settings/update",
  API_UPDATE_VISION_SETTING: "u/vision/v2/update/configs",
  NOTIF_NAME_GLOBAL: "global",
  NOTIF_NAME_R: "r",
  NOTIF_NAME_M: "m",
  NOTIF_NAME_L: "l",
  NOTIF_NAME_F: "f",
  NOTIF_NAME_C: "c",
};
