import GAxios from "src/util/GAxios";

export const getPeopleToFollow = async ({max = 100, cursor = null}) => {
  let result = null;

  let params = {
    max: max,
    incl: "userinfo|followings|followers",
  };

  if (cursor) {
    params = {...params, ...{cursor}};
  }

  const config = {
    method: "get",
    url: process.env.REACT_APP_API_URL + "/s/usertag/recommend",
    params,
    headers: {
      "Content-Type": "application/json",
    },
  };

  await GAxios(
    config,
    (res) => {
      if (res) {
        result = res;
      }
    },
    (err) => {
      result = null;
    },
  );

  return result;
};
