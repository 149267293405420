import {available} from "./available";
import {createChannel} from "./createChannel";
import {getChatToken} from "./getChatToken";
import {getChatUsers} from "./getChatUsers";
import {removeChannel} from "./removeChannel";
import {createGroupChat} from "./createGroupChat";
import {addGroupChatMembers} from "./addGroupChatMembers";
import {removeGroupChatMembers} from "./removeGroupChatMembers";
import {updateGroupChat} from "./updateGroupChat";
import {leaveGroupChat} from "./leaveGroupChat";
import {getFollowers} from "./getFollowers";
import {addGroupAdmin} from "./addGroupAdmin";
import {removeGroupAdmin} from "./removeGroupAdmin";
import {getGroupChatAdmin} from "./getGroupChatAdmin";
import {getGroupChatUser} from "./getGroupChatUser";
import {getGroupChatByRole} from "./getGroupChatByRole";
import {getScopeUsers} from "./getScopeUsers";
import {getChatConfig} from "./getChatConfig";
import {deleteMessageById} from "./deleteMessageById";
import {createDMContact} from "./createDMContact";

export const CHAT_API = "v1/chat";

export const ChatApi = {
  available,
  createChannel,
  getChatToken,
  getChatUsers,
  removeChannel,
  createGroupChat,
  addGroupChatMembers,
  removeGroupChatMembers,
  updateGroupChat,
  leaveGroupChat,
  getFollowers,
  addGroupAdmin,
  removeGroupAdmin,
  getGroupChatAdmin,
  getGroupChatUser,
  getGroupChatByRole,
  getScopeUsers,
  getChatConfig,
  deleteMessageById,
  createDMContact,
};
