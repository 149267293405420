import {createStyles, makeStyles} from "@material-ui/core";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      "@global": {
        ".popup-overlay": {
          zIndex: "1000 !important",
          background: theme.palette.colors_v2.bannerOverlay,
          overflowY: "auto",
          "& .popup-repost-content": {
            cursor: "default",
            minWidth: 700,
          },
          "& .popup-reply-content": {
            minWidth: 700,
          },
          "& .popup-post-content": {
            minWidth: 700,
          },
          "& .popup-post": {
            minWidth: 700,
          },
          "& .popup-content": {
            width: "auto !important",
            backgroundColor: theme.palette.background.white,
            border: `none !important`,
            //#e3e9ee
            padding: "0px 0px !important",

            "& > div > .content": {
              padding: "0px 18px",
              "&.quotePost, &.commentPost": {
                maxHeight: "calc(100vh - 100px - var(--header-height))",
                overflowY: "auto",
                overflowX: "hidden",
              },
              "& .action-bar": {
                borderTop: `1px solid ${theme.palette.colors_v2.base[80]}`,
                margin: theme.spacing(0, -2.25),
                padding: theme.spacing(2, 2.5),
              },
            },

            [theme.breakpoints.down("xs")]: {
              minWidth: "100%",
              maxWidth: "100%",
              height: "100%",
              margin: "0 !important",
              borderRadius: "0 !important",
              "& > div > div > .content": {
                padding: "12px 18px 0",
                "& .action-bar": {
                  borderColor: theme.palette.colors_v2.base[60],
                  backgroundColor: theme.palette.colors_v2.base[80],
                },
              },
            },

            "& .header": {
              fontSize: 20,
              padding: "15px 18px",
              borderBottom: `1px solid ${theme.palette.newColors.backgroundBg[3]}`,
              display: "flex",
              "& .title": {
                height: 30,
                lineHeight: "30px",
                fontSize: 19,
                fontWeight: 700,
                color: theme.palette.newColors.neutral[900],
              },
              "& .cancel": {
                width: 30,
                height: 30,
                marginRight: 15,
                backgroundColor: theme.palette.newColors.backgroundBg[2],
                borderRadius: "50%",
                "&:hover": {
                  background: theme.palette.newColors.neutral[200],
                  "& svg path:first-child": {
                    fill: theme.palette.newColors.neutral[200],
                  },
                },
                "& svg": {
                  color: theme.palette.text.primary,
                  "& path:first-child": {
                    fill: theme.palette.newColors.backgroundBg[2],
                  },
                  "& path:nth-child(2)": {
                    fill: theme.palette.newColors.neutral[900],
                  },
                },
              },
            },
            "& .action-groups": {
              borderTop: "1px solid #e3e9ee",
              padding: "10px 10px",
            },
          },
          "&.popup-reply-overlay .popup-content, &.popup-repost-overlay .popup-content, &.popup-post-overlay .popup-content":
            {
              width: "700px",
              cursor: "default",
              [theme.breakpoints.down("xs")]: {
                width: "100% !important",
              },
            },
        },
      },
    }),
  {
    name: "PopupDialogStyles",
  },
);

export const PopupDialogStyles = () => {
  useStyles();
  return null;
};
