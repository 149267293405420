import GAxios from "src/util/GAxios";
import {CHAT_API} from "./index";

/**
 * Check if the user can chat with the target users
 * @param {string[]} targetUserIds target user ids
 */
export const available = async ({targetUserIds}) => {
  if (!Array.isArray(targetUserIds) || targetUserIds.length === 0) {
    return;
  }

  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/${CHAT_API}/verify_dm`,
    params: {
      targets: targetUserIds.join(","),
    },
    ignoreErrors: true,
  };

  let res = null;

  await GAxios(config, (response) => {
    if (response?.data?.result) {
      res = response.data.result;
    }
  });

  return res;
};
