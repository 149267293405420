import GAxios from "src/util/GAxios";

export const ESTATUS = {
  1: "enabled",
  0: "disabled",
};

/**
 * status 0 disabled 1 enabled
 * @param {*} status
 * @param {*} param1
 * @returns
 */
export const fetchStickersStatus = async (status, {packet_id, value}) => {
  const param = {
    content: {
      [packet_id]: value,
    },
  };

  const config = {
    method: "post",
    url:
      process.env.REACT_APP_API_URL + `/v1/stickers/packet/${ESTATUS[status]}`,
    data: param,
    ignoreErrors: true,
  };

  let res = {err: null, response: null};

  await GAxios(
    config,
    (response) => {
      if (response) {
        res.response = response.data.result;
      } else {
        res.err = true;
      }
    },
    (err) => {
      res.err = true;
    },
  );

  return res;
};
