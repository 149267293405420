export const SUGGESTION_MODES = {
  RECENT: 1,
  RESULT: 2,
};

export const MAX_LIST_ITEMS = 5;

export const SUGGESTION_TYPES = {
  ALL: 0,
  RECENT: 1,
  HASHTAG: 2,
  USER: 3,
  SUGGESTION: 4,
  LIVE_SUGGESTION: 5,
};

export const NEW_LOCAL_STORAGE_LIVE_RECENT_SEARCH =
  "newLocalStorageLiveRecentSearch";

export const UPDATE_DATE_TYPES = {
  // the value is also used in TimeUtil.GetRangeDate() table
  ANY_DATE: "any_date",
  TODAY: "today",
  THIS_WEEK: "this_week",
  THIS_MONTH: "this_month",
  THIS_YEAR: "this_year",
};

export const DURATION_TYPES = {
  ANY_TYPE: "any_type",
  UNDER_30: "under_30_minutes",
  RANGE_30_60: "30_60_minutes",
  OVER_60: "over_60_minutes",
};

export const FEATURES_TYPES = {
  // the value is also used in <adaptFeatures/> props "src/app/components/LiveList/LiveListSearch/utils/index.js"
  FEATURES_ANY: "any",
  LIVE_NOW: "live_now",
  // RESOLUTION_4K: "4k",
  // RESOLUTION_HD: "hd",
  // SUBTITLES_CC: "subtitles_cc",
};
