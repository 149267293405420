import type {ReactNode} from "react";
import {useDispatch} from "react-redux";
import {Navigate, Outlet, useLocation} from "react-router-dom";
import AppConsts from "src/app/AppConsts";
import {userConstants} from "src/app/components/auth/_constants";
import {setActionAfterLogin} from "src/app/components/chat/store";
import {hasMultiAccount} from "src/util/userUtil";
import {useUserSession} from "src/util/useUserSession";

interface PrivateRouteProps {
  children?: ReactNode;
  fallbackPath?: string;
}

/**
 * This is a private route component,
 * which will redirect to onboarding page if user is not authenticated.
 */
function PrivateRoute({
  children,
  fallbackPath = AppConsts.URL_ONBOARDING,
}: PrivateRouteProps) {
  const location = useLocation();
  const dispatch = useDispatch();

  const {authenticated} = useUserSession();

  const searchParams = new URLSearchParams(location.search);
  const invitation_code = searchParams.get("code");

  if (location.pathname === "/messages/invitations" && invitation_code) {
    dispatch(
      setActionAfterLogin({
        invitation_code,
      }),
    );
  }

  if (!(authenticated || hasMultiAccount())) {
    localStorage.removeItem(userConstants.LS_SESSION_INFO);
    return <Navigate to={fallbackPath} />;
  }

  return children ? children : <Outlet />;
}

export default PrivateRoute;
