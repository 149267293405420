import {createTheme} from "@material-ui/core/styles";
import {lightThemePalette_v2} from "src/styles/themes/purpleLight/lightThemePalette_v2";
import {
  newStaticColors,
  sharedThemePalette as sharedPalette,
} from "src/styles/themes/shared/sharedThemePalette";
import {sharedThemePalette_v2} from "src/styles/themes/shared/sharedThemePalette_v2";
import {HEADER_HEIGHT} from "src/util/navigation";
import {
  lightThemePalette as palette,
  newLightColors,
} from "./lightThemePalette";

const theme = createTheme({
  type: "purple_light",
  breakpoints: {
    values: {
      xl: 1920,
      lg: 1336,
      md: 1024,
      sm: 800,
      xs: 0,
    },
  },
  palette: {
    type: "light",
    primary: {
      main: palette.lightThemePrimary,
    },
    secondary: {
      main: palette.lightThemeSecondary,
    },
    lightBlue: {
      main: palette.lightThemeLightblue,
    },
    green: {
      main: palette.lightThemeGreen,
    },

    text: {
      primary: palette.lightTextPrimary,
      secondary: palette.lightTextSecondary,
      grey: palette.lightTextGrey,
      grey2: palette.lightTextGrey2,
      grey3: "#4d6384",
      disabled: palette.lightTextDisabled,
      disabled2: palette.lightButtonTextWhiteDisabled2,
      link: palette.lightThemeLightblue,
      lightGrey: palette.lightTextLightGrey,
      ntfGrey: palette.lightTextNtfGrey,
      // linkClicked: palette.lightTextLinkClicked,
      keywordMatched: palette.lightTextPrimary,
    },

    button: {
      primary: {
        main: palette.lightButtonPrimary,
        contrast: palette.lightTextTextWhite,
        dark: palette.lightButtonPrimaryHover,
        light: palette.lightBgBlueToast,
        disabled: palette.lightButtonPrimaryDisabled,
        outlinedHover: palette.lightButtonRedOutlibeBgHover,
        tab: palette.lightButtonTab,
        tabdisable: palette.lightButtonTabDisable,
        tabBtnDistext: palette.lightButtonTextBk,
        tabBtntext: palette.lightButtonTabDisable,
      },
      secondary: {
        main: palette.lightButtonRed, // used for both border & bg
        contrast: palette.lightTextTextWhite,
        dark: palette.lightButtonRedHover, // used for hover
        disabled: palette.lightButtonRedDisable,
      },
      grey: {
        main: palette.lightButtonGrey,
        contrast: palette.lightTextPrimary,
        dark: palette.lightButtonGreyHover,
        disabled: palette.lightButtonGrey, // Why not lightButtonGreyDisabled?
      },
    },

    background: {
      white: palette.lightBgWhite,
      default: palette.lightBgDefault,
      light: palette.lightBgLight1,
      light2: palette.lightBgLight2,
      dark: palette.lightBgWhiteHover,
      tooltip: "#666",
      transparent: "transparent",
      notif: palette.lightBgBlueToast,
      hover: palette.lightButtonPrimaryHover,
      black: sharedPalette.utilityBlack,
      red: palette.lightBgRed,
      grey: palette.lightBgGrey,
      grey3: palette.lightBgGrey3,
      grey4: palette.lightBgGrey4,
      lightBlueHover: palette.lightBgBlueHover,
      lightBlueHover1: palette.lightBgBlueHover1,
      whiteOutlineBgHover: palette.lightButtonWhiteOutlineBgHover,
      timelineHover: palette.lightBgTimelineHover,
      iconHover: palette.lightBgBlueHover,
      green: palette.lightBgGreen,
      popupOverlay: palette.lightPopupOverlayBackground,
      popupOverlay2: palette.lightPopupOverlayBackground2,
      popupOverlay3: palette.lightPopupOverlayBackground3,
      rightPanel: palette.lightBgRightPanel,
      orangeToast: palette.lightBgOrangeToast,
      blackbg: palette.lightButtonBkBg,
      // moreIconHover: palette.lightBgTransIconHover,
    },

    line: {
      grey1: palette.lightLineGrey1,
      grey2: palette.lightLineGrey2,
      grey3: palette.lightLineGrey3,
      blue: palette.lightLineBlue,
      orange: palette.lightLineOrange,
    },

    icon: {
      primary: palette.lightIconPrimary,
      grey: palette.lightIconGrey,
      bgGrey: palette.lightIconBgGrey,
      bgGrey2: palette.lightIconBgGrey2,
      bgGrey2Hover: palette.lightIconBgGrey2Hover,
      bgDark: palette.lightIconBgDark,
      disabled: palette.lightIconDisabled,
      moreLink: palette.lightThemeWhite,
    },

    overlay: {
      dark: palette.lightOverlayDark1,
      darkGrey: palette.lightOverlayDark2,
      light: palette.lightOverlayLight1,
      grey: palette.lightOverlayGray,
    },

    utility: {
      warning: sharedPalette.utilityWarning,
      white: sharedPalette.utilityWhite,
      black: sharedPalette.utilityBlack,
      darkGrey: sharedPalette.utilityBlkLighten,
      grey: sharedPalette.utilityGray,
      grey2: sharedPalette.utilityGray2,
      green: sharedPalette.utilityGreen,
      avatarBorder: sharedPalette.utilityAvatarBorder,
      redHoverBg: sharedPalette.utilityRedHoverBg,
      blueHoverBg: sharedPalette.utilityBlueHoverBg,
      lightboxIconBgHover: sharedPalette.utilityLightboxIconBgHover,
      greenHoverBg: sharedPalette.utilityGreenHoverBg,
      iconBgHover: palette.lightUniqIconBtnHighlight,
    },

    unique: {
      navText: palette.lightUniqNavText,
      headerIconPrimary: palette.lightUniqHeaderIconPrimary,
      bannerBlack: palette.lightUniqBannerBlack,
      toasterTextBlue: palette.lightUniqToasterTextBlue,
      bannerPrimary: palette.lightUniqBannerPrimary,
      toasterRedBg: palette.lightUniqToasterRedBg,
      textErrorBg: palette.lightUniqTextErrorBg,
      textHighlight: palette.lightUniqTextHighlight,
      iconBtnHighlight: palette.lightUniqIconBtnHighlight,
      chatBubble: palette.lightUniqChatBubble,
      chatBubble2: palette.lightUniqChatBubble2,
    },
    scrollbar: {
      background: palette.lightScrollbarBackground,
      thumb: palette.lightScrollbarThumb,
    },
    newColors: {
      ...newStaticColors,
      ...newLightColors,
    },
    colors_v2: {...lightThemePalette_v2, ...sharedThemePalette_v2},
  },
  typography: {
    h1: {
      fontSize: "22px",
      fontWeight: 900,
      lineHeight: "28.25px",
    },
    h2: {
      fontSize: "18px",
      fontWeight: 800,
      lineHeight: "21.48px",
    },
    body1: {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "22.4px",
    },
    body2: {
      fontSize: "14px",
      fontWeight: 400,
    },
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      "Helvetica",
      "Arial",
      "sans-serif",
    ].join(","),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
    fontWeightBolder: 800,
  },
  mixins: {
    app: {
      maxWidth: 1400,
    },
    header: {
      height: HEADER_HEIGHT, // 60px + 1px border, due to box-sizing: border-box
    },
    ellipsis: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    dropdownMenu: {
      width: 240,
      widthL: 358,
    },
    post: {
      shadow: "0px 0px 7px 6px rgba(0, 0, 0, 0.02)",
      0: "0 0 2px 2px rgba(19, 19, 19, 0.08)",
      shadow1: "0px 0px 7px 6px rgba(0, 0, 0, 0.03)",
    },
    boxShadow: {
      primary: "0 0 4px 1px rgba(19, 19, 19, 0.06)",
      menu: "0 0 2px 2px rgba(19, 19, 19, 0.08)",
      0: "0 0 8px 2px rgba(19, 19, 19, 0.06)",
      1: "0 0 10px 4px rgba(19, 19, 19, 0.06)",
      2: "0 0 14px 8px rgba(19, 19, 19, 0.06)",
      3: "0 0 20px 14px rgba(19, 19, 19, 0.06)",
      4: "0 0 7px 6px rgba(0, 0, 0, 0.03)",
      5: "0 0 4px 0 rgba(0, 0, 0, 0.33)",
    },
    gradient: {
      1: "linear-gradient(90deg, rgba(247, 248, 249, 1) 0%, rgba(247, 248, 249, 1) 58%, rgba(247, 248, 249, 0.28) 87%)",
      2: "linear-gradient(90deg, rgba(247, 248, 249, 0.28) 13%, rgba(247, 248, 249, 1) 42%, rgba(247, 248, 249, 1) 100%)",
      sliderNext:
        "linear-gradient(to left, #FFF 0%, rgba(255, 255, 255, 0.98) 10.88%, rgba(255, 255, 255, 0.87) 19.43%, rgba(255, 255, 255, 0.81) 27.2%, rgba(255, 255, 255, 0.63) 35.74%, rgba(255, 255, 255, 0.21) 48.95%, rgba(255, 255, 255, 0.00) 60.22%)",
      sliderPrev:
        "linear-gradient(to right, #FFF 0%, rgba(255, 255, 255, 0.98) 10.88%, rgba(255, 255, 255, 0.87) 19.43%, rgba(255, 255, 255, 0.81) 27.2%, rgba(255, 255, 255, 0.63) 35.74%, rgba(255, 255, 255, 0.21) 48.95%, rgba(255, 255, 255, 0.00) 60.22%)",
    },
  },
  zIndex: {
    header: 1000,
  },
  overrides: {
    MuiAvatar: {
      root: {
        textTransform: "uppercase",
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: lightThemePalette_v2.bannerOverlay,
      },
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
});

export default theme;
