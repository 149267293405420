import React, {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import {
  ClickAwayListener,
  createStyles,
  Divider,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import clsx from "clsx";

import {isString} from "src/util/TextUtil";
import {reduceTopIndex} from "src/util/BrowserUtils";
import {GButton} from "src/styles/components/GButtonV6";
import {Popup} from "src/app/components/GReactjsPopup";

import {ReactComponent as IconBack} from "src/assets/icons/basic/back_arrow.svg";
import {ReactComponent as IconClose} from "src/assets/icons/icon-vclose.svg";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      dialogContainer: {
        backgroundColor: theme.palette.newColors.backgroundBg[0],
        borderRadius: ({scene}) => (scene === "quotePost" ? 16 : 10),
        "& .header span": {
          "& svg path": {
            fill: `${theme.palette.newColors.neutral[900]} !important`,
          },
        },
        [theme.breakpoints.down("xs")]: {
          borderRadius: 0,
        },
      },
      head: {
        color: theme.palette.text.primary,
      },
      content: {
        position: "relative",
        [theme.breakpoints.down("xs")]: {
          height: "100%",
          borderRadius: "0 !important",
        },
      },
      buttonPadding: {
        padding: 4,
      },
      link: {
        cursor: "pointer",
      },
      dividerLine: {
        height: 1,
        backgroundColor: theme.palette.newColors.neutral[100],
      },
      contentBottom: {
        fontSize: 15,
        fontWeight: "bold",
        color: theme.palette.text.primary,
        textAlign: "right",
        margin: theme.spacing(18 / 8),
        letterSpacing: "0.01em",
      },
      closeIcon: {
        width: "100%",
        height: "100%",
        "& rect": {
          fill: theme.palette.newColors.backgroundBg[2],
        },
        "& path": {
          fill: theme.palette.newColors.neutral[900],
        },
      },
      cancelButton: {
        "& span": {
          fontSize: "15px",
          fontWeight: "bold",
          lineHeight: 1.33,
          letterSpacing: "-0.23px",
          textAlign: "center",
        },
      },
      tooltipHigherZIndex: {
        zIndex: 99999,
      },
      closeButton: {
        "& path:first-child": {
          fill: `${theme.palette.newColors.backgroundBg[2]} !important`,
        },
        "&:hover": {
          "& path:first-child": {
            fill: `${theme.palette.newColors.backgroundBg[4]} !important`,
          },
        },
      },
      disableButton: {
        cursor: "not-allowed",
        background: `#D3D7DC !important`,
        "&:hover": {
          background: "#D3D7DC",
        },
        "& svg": {
          stroke: "#aaaaaa !important",
        },
      },
      postDetailPopup: {
        backgroundColor: theme.palette.newColors.backgroundBg[0],
        borderRadius: 10,
      },
    }),
  {
    name: "popupDialogV3",
  },
);

export const PopupDialogV3 = (props) => {
  const {
    trigger,
    open,
    headerContent,
    showCloseIcon,
    showBackIcon,
    children,
    contentStyle,
    onOpen,
    onCancel,
    onBack,
    setCloseFunc,
    onClose,
    closeOnEscape = false,
    className = "popup-post",
    closeOnDocumentClick = false,
    customHeaderClass,
    showFooter,
    showHeader,
    lockScroll,
    iconClose,
    clickDialog,
    notPaddingRight,
    preventClickEvent = true,
    position,
    customHeader, // from PopupDialogNew
    isLoading, // from PopupDialogNew
    scene, // from PopupDialogNew
    customDialogStyle, // from PopupDialogNew
    ...rest
  } = props;
  const classes = useStyles({scene});
  const {t} = useTranslation();
  const [hasSetCloseFunc, setHasSetCloseFunc] = useState(false);

  const handleOpen = useCallback(() => {
    if (typeof onOpen === "function") {
      onOpen();
    }
    if (notPaddingRight) return;
    const headerEl = document.querySelector("header");
    if (headerEl) headerEl.style.paddingRight = "15px";
    // document.body.style.paddingRight = "15px";
  }, [onOpen, notPaddingRight]);

  const handleClose = useCallback(() => {
    if (typeof onClose === "function") {
      onClose();
    }
    reduceTopIndex();

    const headerEl = document.querySelector("header");
    if (headerEl) headerEl.style.removeProperty("padding-right");
    document.body.style.removeProperty("padding-right");
  }, [onClose]);

  let WrappedTrigger = null;
  if (typeof trigger === "object" && typeof trigger.type === "function") {
    WrappedTrigger =
      trigger &&
      React.forwardRef((props, ref) => (
        <span {...props} ref={ref}>
          {trigger}
        </span>
      ));
  }

  // NOTE: follow example in: https://react-popup.elazizi.com/react-modal/
  // open state doesn't work. But using the close() function passed into
  // the children works!
  return (
    <ClickAwayListener
      onClickAway={(e) => {
        preventClickEvent && e.preventDefault();
        e.stopPropagation();
        let targetClassList = e.target.classList.value;

        if (targetClassList.includes("popup-overlay", "popup-post-overlay")) {
          onCancel?.();
        }
      }}
    >
      <Popup
        trigger={WrappedTrigger ? <WrappedTrigger /> : trigger}
        className={className}
        contentStyle={contentStyle}
        open={Boolean(open)}
        closeOnDocumentClick={closeOnDocumentClick}
        closeOnEscape={closeOnEscape}
        modal
        lockScroll={lockScroll ?? true}
        position={position}
        onOpen={handleOpen}
        onClose={handleClose}
        // overlayStyle={{
        //   zIndex: open && getTopIndex(),
        //   backgroundColor: theme.palette.colors_v2.bannerOverlay,
        // }}
        {...rest}
      >
        {(close) => {
          if (typeof setCloseFunc === "function" && !hasSetCloseFunc) {
            setCloseFunc(() => close);
            setHasSetCloseFunc(true);
          }

          return (
            <div
              className={clsx(
                customDialogStyle,
                classes.dialogContainer,
                "dialogContainer",
                scene === "post-detail" ? classes.postDetailPopup : "",
              )}
              onClick={clickDialog ? clickDialog : null}
              onKeyUp={(e) => {
                if (
                  e?.keyCode === 27 &&
                  typeof onCancel === "function" &&
                  closeOnEscape
                ) {
                  e.preventDefault();
                  e.stopPropagation();
                  onCancel?.("esc");
                }
              }}
            >
              {customHeader ||
                (showHeader ? (
                  <div
                    className={clsx(classes.head, "header", customHeaderClass)}
                  >
                    {showCloseIcon && (
                      <span
                        className={clsx(
                          classes.closeButton,
                          "icon cancel close",
                          isLoading && classes.disableButton,
                        )}
                        onClick={() => onCancel?.()}
                        onTouchStart={() => onCancel?.()}
                        style={{backgroundColor: iconClose && "transparent"}}
                      >
                        {iconClose ? (
                          iconClose
                        ) : (
                          <Tooltip
                            title={t("getter_fe.gettrPay.title.close")}
                            classes={{
                              popper: classes.tooltipHigherZIndex,
                            }}
                            PopperProps={{style: {marginTop: -10}}}
                          >
                            <IconClose className={classes.closeIcon} />
                          </Tooltip>
                        )}
                      </span>
                    )}
                    {showBackIcon && (
                      <span
                        className={clsx(
                          "icon cancel back",
                          isLoading && classes.disableButton,
                          classes.buttonPadding,
                        )}
                        onClick={() => onBack?.()}
                        onTouchStart={() => onBack?.()}
                      >
                        <IconBack />
                      </span>
                    )}
                    {isString(headerContent) ? (
                      <div className="title">{headerContent}</div>
                    ) : (
                      headerContent
                    )}
                  </div>
                ) : null)}

              <div className={clsx("content", classes.content)}>{children}</div>
              {showFooter && (
                <div className={classes.footer}>
                  <Divider className={classes.dividerLine} />
                  <div className={classes.contentBottom}>
                    <GButton
                      variant="text"
                      className={clsx(
                        classes.link,
                        classes.cancelButton,
                        "cancel",
                      )}
                      onClick={() => onCancel?.()}
                    >
                      <span className="cancelText">
                        {t("getter_fe.post.button.cancel")}
                      </span>
                    </GButton>
                  </div>
                </div>
              )}
            </div>
          );
        }}
      </Popup>
    </ClickAwayListener>
  );
};
