import {v4 as uuid} from "uuid";
import {WEB_VERSION} from "src/constants/api";
import {getStore} from "src/store";
import GAxios from "src/util/GAxios";
// https://gettreng.atlassian.net/wiki/spaces/GT/pages/20146290315/Event+tracking+API+doc
import {generateCipherText} from "src/util/trackEvent";

export const judgeAuthenticated = async () => {
  const store = await getStore();
  const state = store?.getState();
  const session = state?.auth?.session;
  return session?.authenticated && session?.userinfo?._id;
};

export const getUserId = async () => {
  const store = await getStore();
  const state = store?.getState();
  const session = state?.auth?.session;
  return session?.userinfo?._id;
};

const setClientId = () => {
  let clientId = JSON.stringify(localStorage.getItem("clientId"));
  if (clientId && clientId?.length === 158) {
    return clientId;
  } else {
    clientId = `${generateCipherText()}|${Date.now()}|${uuid()}`;
    localStorage.setItem("clientId", clientId);
    return clientId;
  }
};

export const startAnalyticsDate = "2022-04-01 00:00:00";

export const AnalyticsConf = {
  enable: !!process.env.REACT_APP_IMPRESSION_API_URL,
  timingMS: 30000,
  sentLimit: 100,
  impression: {
    event: {
      IMPRESSION: "i",
      MESSAGE: "m",
      CREATE_VISION: "cv",
      NEW_USER_GUIDE_ENTER: "nuget",
      NEW_USER_GUIDE_END: "nuged",
      NEW_USER_GUIDE_NEXT: "nugn",
      NEW_USER_GUIDE_CANCEL: "nugc",
      NORMAL_CLICK: "nc",
      PAGE_VIEW: "pv",
    },
    target: {
      POST: "p",
      COMMENT: "c",
      USER: "u",
      VISION: "v",
      BUTTON: "btn",
      PAGE: "pg",
      NONE: "n",
    },
    location: {
      TIMELINE: "tl",
      SEARCH_RESULT: "sr",
      USER_PROFILE: "up",
      POST_DETAIL: "pd",
      GVISION: "gv",
      TRENDING: "td",
      PEOPLE2_FOLLOW: "p2f",
      FOR_YOU_TIMELINE: "fytl",
      RECOMMENDATION_TIMELINE: "retl",
      MESSAGES: "msg",
      CREATE_VISION: "cv",
      NEW_USER_GUIDE: "nug",
      LIVE_NOW: "ln",
      LIVE_VIDEO: "lv",
      VISION_EXPLORE: "ve",
      VISION_FOLLOWING: "vf",
      NONE: "n",
    },
  },
};

export class AnalyticsState {
  static client = null;
  static setAnalyticsClient = (val) => {
    this.client = val;
  };

  static eventIds = [];
  static eventQueue = [];
  static trendingImpressionsIds = [];

  static setEventQueue = (val = []) => {
    this.eventQueue = val;
    this.eventIds = this.eventQueue.map((obj) => {
      return obj.event_id;
    });
  };
  static pushEvent = (val) => {
    if (!this.eventIds.includes(val?.event_id)) {
      this.eventQueue.push(val);
      this.eventIds.push(val?.event_id);
    }
  };
  static getSentList = () => {
    let sl = this.eventQueue.splice(0, AnalyticsConf.sentLimit);
    this.eventIds = this.eventQueue.map((obj) => {
      return obj.event_id;
    });
    return sl;
  };

  static listenerId = null;
  static setListenerId = (val) => {
    this.listenerId = val;
  };

  static impressionSecond = 1;
  static impressionMillisecond = this.impressionSecond * 1000;
  static setImpressionSecond = (val = 1) => {
    this.impressionSecond = val;
  };

  static impressionVisiblePercentage = 90;
  static setImpressionVp = (val = 90) => {
    this.impressionVisiblePercentage = val;
  };

  static impressionIo = null;
  static setImpressionIo = (val) => {
    this.impressionIo = val;
  };

  static setTrendingImpressionsIds = (val) => {
    this.trendingImpressionsIds = val;
  };

  static reset = () => {
    this.handler = null;
    this.eventQueue = [];
    this.listenerId = null;
    this.impressionIo = null;
  };
}

export class AnalyticsClient {
  constructor() {
    if (AnalyticsConf.enable) {
      this.startImpressionIo();
    }
  }

  async getConfiguration() {
    // get configuration from analytics service
    if (!AnalyticsConf.enable) return;
    const isAuthenticated = await judgeAuthenticated();
    const config = {
      ignoreErrors: true,
      method: "get",
      url: `${process.env.REACT_APP_IMPRESSION_API_URL}/api/events/configuration`,
      headers: isAuthenticated
        ? {"Content-Type": "application/json"}
        : {
            Authorization: generateCipherText(),
          },
    };
    const callback = (response) => {
      const {data} = response || {};
      if (data && data?.impression_time) {
        AnalyticsState.setImpressionSecond(data?.impression_time);
      }
      if (data && data?.impression_visible_percentage) {
        AnalyticsState.setImpressionVp(
          response?.data?.impression_visible_percentage,
        );
      }
    };
    const errorCallback = () => {
      // pass
    };

    await GAxios(config, callback, errorCallback);
  }

  async startImpressionIo() {
    const eventsMap = new Map();
    const ioCallback = (entries) => {
      entries.forEach((entry) => {
        const target_sence = entry?.target.getAttribute("data-sence");
        const target_id = entry?.target.getAttribute("data-id");
        const target_type = entry?.target.getAttribute("data-tp");
        const location = entry?.target.getAttribute("data-lt");
        const extra_info = JSON.parse(entry?.target.getAttribute("data-ei"));
        const event_id = `${target_id}_${target_type}_${location}`;
        if (target_id) {
          const event = {
            target_sence,
            event_id,
            event_type: AnalyticsConf.impression.event.IMPRESSION,
            target_id,
            target_type,
            location,
            extra_info,
            startts: null,
            endts: null,
          };

          if (entry.isIntersecting) {
            // mark the component to enter the screen
            event.startts = new Date().toISOString();
            setTimeout(() => {
              const elBounding = entry?.target.getBoundingClientRect();
              if (
                elBounding.bottom > 10 &&
                elBounding.top < window.innerHeight
              ) {
                event.endts = new Date().toISOString();
                AnalyticsState.pushEvent(event);
                eventsMap.delete(event.event_id);
              }
            }, AnalyticsState.impressionMillisecond);
          } else {
            // mark the component out of the screen
            if (event?.startts) {
              const currentDate = new Date();
              event.endts = currentDate.toISOString();
              if (
                currentDate - new Date(event.startts).valueOf() >
                AnalyticsState.impressionMillisecond
              ) {
                if (!AnalyticsState.eventIds.includes(event.event_id)) {
                  AnalyticsState.pushEvent(event);
                }
                eventsMap.delete(event.event_id);
              } else {
                eventsMap.delete(event.event_id);
              }
            } else {
              eventsMap.set(event.event_id, event);
            }
          }
        }
      });
    };
    AnalyticsState.setImpressionIo(new IntersectionObserver(ioCallback));
  }

  async stopImpressionIo() {
    if (AnalyticsState.impressionIo) {
      AnalyticsState.impressionIo.disconnect();
    }
  }

  async startListener() {
    const listenerId = setInterval(() => {
      // call eventTracking method per 30 seconds
      this.eventTracking();
    }, AnalyticsConf.timingMS);
    AnalyticsState.setListenerId(listenerId);
  }

  async stopListener() {
    if (AnalyticsState.listenerId) {
      clearInterval(AnalyticsState.listenerId);
    }
  }

  async sentEvents(events = []) {
    // sent events info to analytics service
    const _events = [];
    events.forEach((event) => {
      const {
        event_type,
        target_id,
        target_type,
        location,
        extra_info,
        startts, // Number or ISO string
        endts, // Number or ISO string
      } = event;
      const hasExtraInfo =
        Object.prototype.toString.call(extra_info) === "[object Object]";
      // The impression of GETTR News on the current page is only sent once.
      if (location === AnalyticsConf.impression.location.TRENDING) {
        if (AnalyticsState.trendingImpressionsIds.includes(target_id)) {
          return false;
        } else {
          AnalyticsState.setTrendingImpressionsIds(target_id);
        }
      }
      let obj = {
        event_type,
        target_id,
        client_info: {
          platform: "w", // ("w" | "WEB")
          more: JSON.stringify({
            version: WEB_VERSION,
            client_id: setClientId(),
          }),
        },
        target_type,
        location,
        extra_info: hasExtraInfo ? extra_info : {},
        startts,
        endts,
      };
      if (
        !Object.values(AnalyticsConf.impression.location).includes(location)
      ) {
        if (obj.extra_info?.normal_details) {
          obj.extra_info.normal_details["location"] = location;
        } else {
          obj.extra_info = {
            ...obj.extra_info,
            normal_details: {location},
          };
        }
        obj.location = AnalyticsConf.impression.location.NONE;
      }
      _events.push(obj);
    });

    const isAuthenticated = await judgeAuthenticated();
    const data = {
      msgs: _events,
    };
    const config = {
      ignoreErrors: true,
      activity: true,
      method: "post",
      url: `${process.env.REACT_APP_IMPRESSION_API_URL}/api/v2/events/activity`,
      headers: isAuthenticated
        ? {"Content-Type": "application/json"}
        : {
            Authorization: generateCipherText(),
          },
      data,
    };
    const successCallback = () => {
      // sent events tracking successful.
      sessionStorage.removeItem("pageTrack");
      sessionStorage.removeItem("dialogTrack");
      sessionStorage.removeItem("eventTrack");
    };
    const errorCallback = () => {
      console.error("sent events tracking failed.");
    };

    await GAxios(config, successCallback, errorCallback);
  }

  async eventTracking(sentArr = AnalyticsState.getSentList()) {
    if (sentArr.length > 0) {
      await this.sentEvents(sentArr);
    }
  }

  destroy() {
    if (AnalyticsConf.enable) {
      this.stopImpressionIo();
      this.stopListener();
      AnalyticsState.reset();
    }
  }
}
