import GAxios from "src/util/GAxios";
import {CHAT_API} from "./index";

/**
 * This will create Group channel for given users.
 * If the target users are not allowed, this API will failed with an E_CHAT_FORBIDDEN error.
 * The invalidUsernameList will contains invalid user's username.
 * @param {string[]} targets list of target users
 * @param {boolean} isChatId true by default, set false for gettr id
 * @param {string} name channel name
 * @param {string} description channel description
 */
export const createGroupChat = async ({
  targets,
  isChatId = true,
  name,
  description,
  is_super,
}) => {
  if (!Array.isArray(targets) || targets.length === 0) {
    return;
  }

  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/${CHAT_API}/channel/group`,
    ignoreError: true,
    data: {
      content: {
        targets,
        is_chat_id: isChatId,
        name,
        description,
        is_super,
      },
    },
  };

  let res = null;

  await GAxios(
    config,
    (response) => {
      if (response?.data?.result) {
        res = response.data.result;
      }
    },
    (error) => {
      res = error?.response || null;
    },
  );

  return res;
};
