import GAxios from "src/util/GAxios";

export const createDMContact = async (data, {ignoreErrors}) => {
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/v1/chat/one_contact/channel`,
    data: {
      content: data,
    },
    ignoreErrors,
  };
  let res = null;
  await GAxios(config, (response) => {
    if (response?.data) {
      res = response?.data;
    }
  });

  return res;
};
