import {parseUser} from "src/util/FeedUtils";
import GAxios from "src/util/GAxios";
const PEOPLE_MAX = 40;
export const getRecommendUsers = async ({userId, token}) => {
  const reqUrl =
    process.env.REACT_APP_API_URL +
    `/s/usertag/recommend?max=${PEOPLE_MAX}&incl=userinfo|followings|followers`;
  const xAuth =
    userId === null
      ? `{"user": null, "token": null}`
      : `{"user": "${userId}", "token": "${token}"}`;
  const config = {
    method: "GET",
    url: reqUrl,
    headers: {
      "Content-Type": "application/json",
    },
  };

  let resp = null;
  try {
    await GAxios(
      config,
      (res) => {
        const followings = res.data?.result?.aux?.fws;
        const cats = res.data?.result?.data?.cats;
        const users = parseUser(res.data?.result?.aux);
        const whoToFollowUsers = {};
        if (cats) {
          Object.keys(cats).forEach((key) => {
            whoToFollowUsers[key] = users[key];
          });
        }
        resp = {
          data: whoToFollowUsers,
          fws: followings,
        };
        return resp;
      },
      (err) => {
        console.error(err);
      },
    );
  } catch (error) {
    console.error(error);
  }
  return resp;
};
