import GAxios from "src/util/GAxios";

export const updateRingMeState = async (params) => {
  const {uname, tname, notifSwitch, notifState, errorCallback} = params;
  if (!uname) return;

  const data = JSON.stringify({
    content: {
      uname: uname,
      tname: tname,
      switch: notifSwitch,
      state: notifState,
    },
  });

  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/u/notif/subscribe`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  let res = false;

  await GAxios(
    config,
    (response) => {
      if (response && response.data?.result?.data?.result === "success") {
        res = {
          notifSwitch: notifSwitch,
          notifState: notifState,
        };
      }
    },
    (err) => {
      res = null;
      if (errorCallback) errorCallback(err);
    },
  );

  return res;
};
