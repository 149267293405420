import {de} from "date-fns/locale";
import GAxios from "src/util/GAxios";

export const getTopicData = async (data) => {
  const config = {
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/u/topic/v2/topics`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  let res = null;

  await GAxios(config, ({data}) => {
    res = data?.result?.data;
  });

  return res;
};

export const getTopic = async ({name}) => {
  const config = {
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/u/topic/v2/topic?name=${name}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  let res = null;

  await GAxios(config, ({data}) => {
    res = data?.result?.data;
  });

  return res;
};
