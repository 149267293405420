import GAxios from "src/util/GAxios";
import {userConstants} from "../_constants";
import {verifCodeErrorCallback} from "../../../../util/ErrorCallback";

export const verifCode = async (params) => {
  let {
    data: {email, phone, code} = {},
    type,
    reqtype,
    callback,
    errorCallback,
  } = params || {};

  if (!code || !(email || phone)) return;

  if (!reqtype) {
    reqtype =
      type === userConstants.VERIF_CODE_TYPE_F_PWD
        ? userConstants.VERIF_CODE_REQ_TYPE
        : userConstants.VERIF_CODE_REQ_TYPE_SIGNUP;
  }

  const whatsapp = localStorage.getItem("Whatsapp");
  const isWhatsapp = whatsapp === "true";
  const smsParam = !isWhatsapp ? {sms: phone} : {whatsapp: "+" + phone};

  const data = JSON.stringify({
    content: {
      email,
      ...smsParam,
      verifycode: code,
      reqtype,
    },
  });

  const config = {
    method: "post",
    url: userConstants.API_VERIF_CODE,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  let res = false;

  await GAxios(
    config,
    (response) => {
      if (response && response.data?.result?.success) {
        res = code;
      } else {
        res = false;
      }
      if (callback) callback(response);
    },
    (err) => {
      res = false;
      if (errorCallback) errorCallback(err);
    },
  );

  return res;
};

export const selfVerifCode = async (params) => {
  // const email = params.data.email;
  // const phone = params.data.phone;
  const {
    data: {email, phone, code} = {},
    type,
    callback,
    errorCallback,
  } = params || {};

  if (!code || !(email || phone)) return;

  const whatsapp = localStorage.getItem("Whatsapp");
  const isWhatsapp = whatsapp === "true";
  const smsParam = !isWhatsapp ? {sms: phone} : {whatsapp: "+" + phone};
  const contactType = email ? "email" : !isWhatsapp ? "sms" : "whatsapp";

  const reqType = userConstants.VERIF_CODE_REQ_TYPE_OTPVERIFY;

  const data = JSON.stringify({
    content: {
      email,
      ...smsParam,
      verifycode: code,
      reqtype: reqType,
      contactType,
    },
  });

  const config = {
    method: "post",
    url: userConstants.API_VERIF_CODE,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  let res = false;

  await GAxios(
    config,
    (response) => {
      if (response && response.data?.result?.success) {
        res = response.data.result.vtoken;
      } else {
        res = false;
      }
      if (callback) callback(response);
    },
    (err) => {
      res = verifCodeErrorCallback(err);
      if (errorCallback) errorCallback(err);
    },
  );

  return res;
};
