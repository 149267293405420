import {makeStyles, useMediaQuery, useTheme} from "@material-ui/core";
import clsx from "clsx";
import {useEffect, useMemo, useRef, useState} from "react";
import {useDispatch} from "react-redux";

import {useLocation} from "react-use";
import {GifImage} from "src/app/components/GifImage";
import {IMAGE_BASE_URL} from "src/app/components/StickerPanel/store";
import TiledImages from "src/app/components/TiledImages";
import {closePopupImages, setPopupImages} from "src/app/components/post/store";
import {getDeviceType} from "src/util/BrowserUtils";
import {handleMediaUrl, zoomImage} from "src/util/imageUtils";

const {isSafari} = getDeviceType();

const REPOST_MEDIA_SIZE = 88;

const useStyles = makeStyles(
  (theme) => ({
    imageContainer: {
      display: "flex",
      justifyContent: "flex-start",
      maxHeight: ({imgMaxHeight}) => imgMaxHeight,
      position: "relative",
      marginRight: ({isFromReplies}) =>
        isFromReplies ? theme.spacing(2.25) : theme.spacing(0),
      "&.image-c-has-parent-media": {
        "& .sticker_wrapper": {
          borderRadius: 0,
          border: "none",
        },
      },
      "& .sticker_wrapper": {
        borderRadius: theme.spacing(1),
        margin: "0 0 9px",
        overflow: "hidden",
        "& .sticker_img": {
          maxWidth: "100%",
          maxHeight: ({stickerMaxHeight}) => stickerMaxHeight,
          "&.error": {
            height: "100%",
            borderRadius: 8,
          },
        },
        "& img": {
          borderRadius: theme.spacing(1),
        },
      },
      "& img:not(.singleImage)": {
        position: "relative",
        borderRadius: ({isFromReplies}) =>
          isFromReplies ? theme.spacing(1.75) : theme.spacing(0),
      },
      [theme.breakpoints.down("xs")]: {
        marginRight: ({isFromReplies}) =>
          isFromReplies ? theme.spacing(1) : theme.spacing(0),
      },
    },
    mediaContainer: {
      marginTop: ({isEmbedded, scene}) =>
        isEmbedded
          ? 0
          : scene === "post-detail"
          ? theme.spacing(1.25)
          : theme.spacing(1.25),
      marginLeft: ({scene}) => scene === "comment-detail" && 18,
      width: ({isEmbedded}) => (isEmbedded ? REPOST_MEDIA_SIZE : null),
      overflow: "hidden",
      "-webkit-mask-image": ({isSafari}) =>
        isSafari ? "-webkit-radial-gradient(white, black)" : "unset",
      "& .video-js": {
        height: "100%",
      },
      "&.imageContainer": {
        borderRadius: ({scene}) => (scene === "hashtag" ? "0 !important" : ""),
      },
      "&.image-c-has-parent-media": {
        width: "100%",
        margin: theme.spacing(1.5, 0),
      },
      "&.media-c-has-parent-media": {
        width: "100%",
        height: "auto",
      },
    },
    sticker: {
      width: "100%",
      textAlign: "center",
      position: "relative",
    },
    singleImage: {
      position: "relative",
      display: "block",
      // width: "100%",
      height: "100%",
      minWidth: 382.5,
      minHeight: ({isEmbedded, imgMinHeight}) =>
        isEmbedded ? 0 : imgMinHeight,
      maxHeight: 510,
      objectFit: "cover",
      borderRadius: theme.spacing(1.75),
      cursor: "pointer",
      "&.error": {
        height: "102px !important",
        minHeight: 102,
      },
      [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
      },
      "&.cursor-default": {
        cursor: "default",
      },
    },
    sticker_img: {
      width: 200,
      height: 200,
    },
    sticker_root: {
      justifyContent: "center",
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      position: "relative",
    },
    sticker_group: {
      position: "relative",
      maxWidth: 55,
      maxHeight: 55,
      "@media (min-width: 375px)": {
        maxWidth: 68,
        maxHeight: 68,
      },
      "@media (min-width: 600px)": {
        maxWidth: 141,
        maxHeight: 141,
      },
    },
    stickers: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr 1fr",
    },
  }),
  {name: "FeedItemImageContent"},
);
export const imgMaxHeight = 510;

export const FeedItemImageContent = ({
  imgs,
  isEmbedded,
  mediaHeight,
  mediaWidth,
  item,
  hidePostStatLine,
  hasParentMedia,
  user,
  insideComposer,
  scene,
}) => {
  const {pathname} = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const {stickers} = item || {};
  let sticker =
    (imgs?.length > 0 && imgs[0].includes("stickers/v1")) ||
    stickers?.length > 0;
  const isHashtag = pathname.includes("/hashtag");
  const imgMinHeight = 102.5;
  const imageContainerWidth = 564;
  const stickerMaxHeight = 212;
  const stickerWrapperPadding = 16;
  const dispatch = useDispatch();
  const isFromReplies = scene === "comment-detail";
  const classes = useStyles({
    isEmbedded,
    isSafari,
    scene,
    imgMaxHeight,
    stickerMaxHeight,
    stickerWrapperPadding,
    isFromReplies,
    imgMinHeight,
  });
  const [imgError, setImgError] = useState(false);
  const [imageMaxHeight, setImageMaxHeight] = useState(null);
  const [imageHeight, setImageHeight] = useState(null);
  const hasImageContainerHeight =
    (!imgError && !stickers) ||
    (!imgError && stickers && stickers?.length === 0);
  const imgRef = useRef();

  const imgList = useMemo(() => {
    return (imgs || []).filter((img) => {
      return img !== undefined && img !== null;
    });
  }, [imgs]);

  const isSingleImage = imgList?.length === 1 || stickers?.length > 0;

  useEffect(() => {
    return () => dispatch(closePopupImages());
  }, []);

  useEffect(() => {
    if (isSingleImage && imgRef.current) {
      const {width} = imgRef.current.getBoundingClientRect();
      const maxHeight = (width / 9) * 16;
      const stickerHeight = stickerMaxHeight + stickerWrapperPadding * 2;
      setImageMaxHeight(
        sticker
          ? stickerHeight
          : maxHeight > imgMaxHeight
          ? imgMaxHeight
          : maxHeight,
      );
      if (mediaWidth && mediaHeight) {
        const mW = Number(mediaWidth);
        const mH = Number(mediaHeight);
        let Img500Width = 500;
        let postWidth = isFromReplies ? Img500Width : imageContainerWidth;
        let stickerBoxWidth = postWidth - stickerWrapperPadding * 2;
        if (isMobile || isHashtag) {
          stickerBoxWidth = width - stickerWrapperPadding * 2;
          Img500Width = width;
          postWidth = width;
        }
        if (sticker) {
          // vertical img
          if (mW <= mH) {
            if (mH <= stickerMaxHeight) {
              setImageHeight(stickerHeight);
            } else {
              if (mW * (mH / stickerMaxHeight) < stickerBoxWidth) {
                setImageHeight(stickerHeight);
              } else {
                setImageHeight(mH * (mW / stickerBoxWidth));
              }
            }
          }
        } else {
          const imgHeight = (postWidth / mW) * mH;
          setImageHeight(imgHeight < imgMinHeight ? imgMinHeight : imgHeight);
        }
      }
    }
  }, [isMobile, isSingleImage]);

  const handlePopupImages = (currentIndex) => {
    dispatch(
      setPopupImages({
        imageURLs: imgList,
        item,
        open: Number.isInteger(currentIndex),
        onClose: () => handlePopupImages(null),
        currentIndex,
        hidePostStatLine,
        user,
      }),
    );
  };

  if (!imgs?.length && !stickers?.length) {
    return null;
  }

  let imageContentBody = null;
  if (isSingleImage || (isEmbedded && hasParentMedia) || stickers?.length > 0) {
    const [img] = imgList;
    const isGif = img && img.toLowerCase().includes(".gif");

    let url =
      handleMediaUrl(
        process.env.REACT_APP_MEDIA_BASE,
        isGif ? img : zoomImage(img, 768, 0),
      ) || "";

    const isSticker = url.includes("stickers/v1/") || stickers?.length > 0;
    if (stickers?.length > 0) {
      url =
        `${IMAGE_BASE_URL}/${stickers[0].pid}/${stickers[0].id}/main_mobile.webp`.toLowerCase();
    }

    const singleImageProps = {height: mediaHeight, width: mediaWidth};

    if (isGif) {
      imageContentBody = (
        <GifImage
          handleClick={(e) => {
            e.stopPropagation();
            handlePopupImages(0);
          }}
          imageClassName={`${classes.singleImage} singleImage`}
          imageUrl={url}
          setImgError={setImgError}
          {...singleImageProps}
        />
      );
    } else {
      if (stickers && stickers.length > 0) {
        if (hasParentMedia) {
          imageContentBody = (
            <img
              className={clsx(classes.singleImage, "singleImage")}
              src={`${IMAGE_BASE_URL}/${stickers[0].pid}/${stickers[0].id}/main_mobile.webp`?.toLowerCase()}
              alt={"sticker"}
            />
          );
        } else {
          // const groups = chunk(stickers, stickers.length <= 4 ? 2 : 3);
          imageContentBody = (
            <div
              className={`sticker_wrapper ${
                (classes.sticker, classes.sticker_root)
              }`}
            >
              <div className={classes.stickers}>
                {stickers.map((sticker, index) => {
                  return (
                    <div
                      className={classes.sticker_group}
                      key={index}
                      data-id={index}
                    >
                      <img
                        width={"100%"}
                        height={"auto"}
                        src={`${IMAGE_BASE_URL}/${sticker.pid}/${sticker.id}/main_mobile.webp`.toLowerCase()}
                        alt={"sticker"}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        }
      } else {
        imageContentBody = (
          <img
            className={clsx(
              classes.singleImage,
              isSticker && !isEmbedded ? classes.sticker_img : "",
              "singleImage",
              insideComposer && "cursor-default",
            )}
            onClick={(e) => {
              e.stopPropagation();
              !insideComposer && url && handlePopupImages(0);
            }}
            crossOrigin="anonymous"
            src={url}
            alt={`${item?.txt?.substring(0, 155) || "image"}`}
            onError={(event) => {
              let isResizeSrc =
                event.target.src ===
                handleMediaUrl(
                  process.env.REACT_APP_MEDIA_BASE,
                  zoomImage(img, 500, 0),
                );
              if (isResizeSrc) {
                event.target.src = handleMediaUrl(
                  process.env.REACT_APP_MEDIA_BASE,
                  img,
                );
              } else {
                event.target.classList.add("error");
                setImgError(true);
              }
            }}
            draggable="false"
          />
        );
      }
    }
  } else {
    const getMeta = (meta) => {
      if (!meta) return null;
      if (Array.isArray(meta)) return meta;

      try {
        return JSON.prase(meta);
      } catch {
        return null;
      }
    };
    imageContentBody = (
      <TiledImages
        handleClick={(index) => {
          !insideComposer && handlePopupImages(index);
        }}
        imageURLs={imgList}
        imgData={getMeta(item?.meta)}
        isFromReplies={isFromReplies}
      />
    );
  }

  return (
    <div
      className={clsx(
        classes.imageContainer,
        "imageContainer",
        classes.mediaContainer,
        isEmbedded && !hasParentMedia && "image-c-has-parent-media",
      )}
      style={{
        maxHeight: hasImageContainerHeight ? imageMaxHeight : null,
        height: hasImageContainerHeight ? imageHeight : null,
      }}
      ref={imgRef}
    >
      {imageContentBody}
    </div>
  );
};
