import GAxios from "src/util/GAxios";
import {CHAT_API} from "./index";
import {getStore} from "src/store";
import {updateFollowingStatusInBatch} from "src/store/modules/status";

/**
 * Get user information releated by chat system.
 * @param {string[]} targets target user id's (max 10 users)
 * @param {string[]} includes extra info to include, such as "following", "followed", etc.
 */
export const getChatUsers = async ({
  targets,
  includes = ["following", "followed"],
}) => {
  if (!Array.isArray(targets) || targets.length === 0) {
    return;
  }

  const store = await getStore();
  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/${CHAT_API}/users`,
    params: {
      targets: targets.slice(0, 10).join(","),
      includes: includes.join(","),
    },
  };

  let res = null;

  await GAxios(config, (response) => {
    if (response?.data?.result) {
      res = response.data.result;
    }
    const following = {};
    const followed = {};
    Object.keys(res).forEach((key) => {
      following[key] = res[key].following;
      followed[key] = res[key].followed;
    });
    store.dispatch(
      updateFollowingStatusInBatch({
        following,
        followed,
      }),
    );
  });

  return res;
};
