import {CustomColors} from "../../../types/theme";

export const lightThemePalette_v2: CustomColors = {
  accent: "#8447FF",
  accentHover: "#532CA2",
  accentLink: "#8447FF",
  element: {
    [20]: "#576369",
    [40]: "#758185",
    [60]: "#6B7072",
    [80]: "#16171A",
    [100]: "#0F0F0F",
  },
  base: {
    [0]: "#FFFFFF",
    [20]: "#FAFBFC",
    [40]: "#F7F7F8",
    [60]: "#E8EAEB",
    [80]: "#EBEEEF",
  },
  bg: "#FFFFFF",
  modalWindowBg: "#FFFFFF",
  bannerOverlay: "#0F0F0FEB",
};
