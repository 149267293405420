import GAxios from "src/util/GAxios";
import {CHAT_API} from "./index";

/**
 * Add new members to a group chat. Only group chat owner(who created the group chat) is allowed to using this api.
 * If the target users are not allowed, this API will failed with an E_CHAT_FORBIDDEN error.
 * The invalidUsernameList will contains invalid user's username.
 * @param {string[]} targets list of target users
 * @param {string} channelId group chat id
 */
export const addGroupChatMembers = async ({targets, channelId}) => {
  if (!Array.isArray(targets) || targets.length === 0) {
    return;
  }

  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/${CHAT_API}/channel/group/members/add`,
    ignoreError: true,
    data: {
      content: {
        targets,
        channel_id: channelId,
      },
    },
  };

  let res = null;

  await GAxios(
    config,
    (response) => {
      if (response?.data?.result) {
        res = response.data.result;
      }
    },
    (error) => {
      res = error?.response || null;
    },
  );

  return res;
};
