import GAxios from "src/util/GAxios";

export const getPostDataByDate = async (params) => {
  const {
    userId,
    postId,
    token,
    startDate = null,
    endDate = null,
    errorCallback,
  } = params;

  let xAppAuth =
    userId === null
      ? `{"user": null, "token": null}`
      : `{"user": "${userId}", "token": "${token}"}`;
  const config = {
    method: "get",
    headers: {
      "x-app-auth": xAppAuth,
    },
    url: `${process.env.REACT_APP_IMPRESSION_API_URL}/api/post_daily`,
    params: {
      post_id: postId,
      startDate: startDate, // default: 28 days ago
      endDate: endDate, // default: today
    },
  };

  let res = {response: null, err: null};

  await GAxios(
    config,
    (response) => {
      let {engagements, impressions, view_details, likes, replies, shares} =
        response?.data?.summary ?? {};
      let arr = [
        engagements || 0,
        impressions || 0,
        view_details || 0,
        likes || 0,
        replies || 0,
        shares || 0,
      ];
      let sum = arr.reduce((acr, cur) => {
        return acr + cur;
      });
      if (sum && response?.data?.summary) {
        res.response = response.data.summary;
      } else {
        res.response = false;
      }
    },
    (err) => {
      res.err = true;
      if (errorCallback) errorCallback(err);
    },
  );
  return res;
};
