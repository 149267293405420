import GAxios from "src/util/GAxios";
import {adaptList} from "src/app/components/LiveList/LiveListSearch/utils";

export const fetchHistory = async (data) => {
  let {userId, cursor, count = 10, checkNew} = data;
  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/u/user/${userId}/posts`,
    params: {
      incl: "userstats|userinfo|followings|followers|islive|hasnewlive|posts|poststats|progress",
      ...(cursor ? {cursor} : {}),
      max: count,
      fp: "f_uwl",
      dir: "fwd",
    },
    ignoreTips: {t429: true},
  };

  let res = {};

  await GAxios(
    config,
    (response) => {
      if (response?.data?.result) {
        res = {
          checkNew,
          list: adaptList(response?.data?.result),
          reset: !cursor,
          cursor:
            !response?.data?.result?.data?.list?.length ||
            /^0$/.test(response?.data?.result?.aux?.cursor)
              ? ""
              : response?.data?.result?.aux?.cursor,
        };
      }
    },
    (err) => {
      res = err;
    },
  );

  return res;
};
