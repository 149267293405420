import * as moment from "moment";
// https://github.com/moment/moment/tree/develop/locale
import "moment/locale/zh-cn";
import "moment/locale/zh-tw";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/hi";
import "moment/locale/ja";
import "moment/locale/pt-br";
import "moment/locale/de";
import {t, returnMomentLocale, getLang} from "src/i18n/utils";

const getLatestDateText = (latest, md, locale) => {
  let latestDateText = "";
  let latestStartDate = latest?.from;
  let latestEndDate = latest?.to;
  // let latestStartDate = latest[0]?.date;
  // let latestEndDate = latest[latest?.length - 1]?.date;
  if (latest?.length === 1) {
    latestDateText = moment(latestStartDate)
      .locale(locale)
      .format(`${md}, YYYY`);
    if (moment(latestStartDate).format("YYYY") === moment().format("YYYY")) {
      latestDateText = moment(latestStartDate).locale(locale).format(md);
    }
  } else {
    latestDateText = `${moment(latestStartDate)
      .locale(locale)
      .format(md)} - ${moment(latestEndDate).locale(locale).format(md)}`;
  }
  if (
    moment(latestStartDate).format("YYYY") !==
    moment(latestEndDate).format("YYYY")
  ) {
    latestDateText = `${moment(latestStartDate)
      .locale(locale)
      .format(`${md}, YYYY`)} - ${moment(latestEndDate)
      .locale(locale)
      .format(`${md}, YYYY`)}`;
  }
  return latestDateText;
};

const getLastDateText = (last, md, locale) => {
  let lastDateText = "";
  let lastStartDate = last?.from;
  let lastEndDate = last?.to;
  // let lastStartDate = last[0]?.date;
  // let lastEndDate = last[last?.length - 1]?.date;
  if (last?.length === 1) {
    lastDateText = moment(lastStartDate).locale(locale).format(`${md}, YYYY`);
    if (moment(lastStartDate).format("YYYY") === moment().format("YYYY")) {
      lastDateText = moment(lastStartDate).locale(locale).format(md);
    }
  } else {
    lastDateText = `${moment(lastStartDate)
      .locale(locale)
      .format(md)} - ${moment(lastEndDate).locale(locale).format(md)}`;
  }
  if (
    moment(lastStartDate).format("YYYY") !== moment(lastEndDate).format("YYYY")
  ) {
    lastDateText = `${moment(lastStartDate)
      .locale(locale)
      .format(`${md}, YYYY`)} - ${moment(lastEndDate)
      .locale(locale)
      .format(`${md}, YYYY`)}`;
  }
  return lastDateText;
};

export class TimeUtil {
  static Ts2MMYYYY(ts, locale = "en") {
    let newLocale = returnMomentLocale(locale);
    return moment(ts).locale(newLocale).format("MMMM YYYY");
  }

  static Ts2MMMDDYYYY(ts, locale = "en") {
    if (locale !== "en") {
      locale = returnMomentLocale(locale);
    }
    const out = moment(ts).locale(locale).format("MMM DD, YYYY");
    return out;
  }

  static Ts2hhmm(ts, locale = "en") {
    if (locale !== "en") {
      locale = returnMomentLocale(locale);
    }
    var today = moment();
    var compareDaday = moment(ts);
    if (today.format("YYYYMMDD") === compareDaday.format("YYYYMMDD")) {
      // today\
      return moment(ts).locale(locale).format("H:mm A");
    } else if (
      today.subtract(1, "days").format("YYYYMMDD") ===
      compareDaday.format("YYYYMMDD")
    ) {
      return "Yesterday " + moment(ts).locale(locale).format("H:mm A");
    } else {
      return moment(ts).locale(locale).format("Mo MM H:mm");
    }
  }

  static Ts2hh_mm_MM_DD(ts = Date.now(), locale = "en") {
    if (locale !== "en") {
      locale = returnMomentLocale(locale);
    }
    let out = "";
    if (moment(ts).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
      out = moment(ts).locale(locale).format("h:mm A");
    } else {
      out = moment(ts).locale(locale).format("h:mm A · MMM Do");
    }
    return out;
  }

  static Ts2hh_mm_MM_DD_YYYY(ts = Date.now(), locale = "en") {
    if (locale !== "en") {
      locale = returnMomentLocale(locale);
    }
    const out = moment(ts).locale(locale).format("h:mm A · MMM Do, YYYY");
    return out;
  }
  static MM_DD_YYYY_Ts2hh_mm(ts = Date.now(), locale = "en") {
    if (locale !== "en") {
      locale = returnMomentLocale(locale);
    }
    const out = moment(ts).locale(locale).format("MMM Do, YYYY · h:mm A");
    return out;
  }

  static Ts2DD_MMMM_YYYY_h_mm(ts = Date.now(), locale = "en") {
    if (locale !== "en") {
      locale = returnMomentLocale(locale);
    }
    const out = moment(ts).locale(locale).format("DD MMMM YYYY h:mm A");
    return out;
  }

  static Ts2DD_MMMM_YYYY_h_mmAt(ts = Date.now(), locale = "en", replaceToday) {
    if (locale !== "en") {
      locale = returnMomentLocale(locale);
    }
    const out =
      (replaceToday &&
      moment(ts).locale(locale).format("DD MMMM YYYY") ===
        moment(new Date()).locale(locale).format("DD MMMM YYYY")
        ? replaceToday
        : moment(ts).locale(locale).format("DD MMMM YYYY")) +
      " " +
      t("getter_fe.timeline.common.at") +
      " " +
      moment(ts).locale(locale).format("h:mm A");
    return out;
  }
  static formatTimestampInNYTimezone(ts = Date.now(), locale = "en") {
    if (locale !== "en") {
      locale = returnMomentLocale(locale);
    }
    const timeZone = "America/New_York";
    const formattedDate = moment(ts)
      .locale(locale)
      .tz(timeZone)
      .format("DD MMMM YYYY hh:mm:ss A [(GMT-5)]");
    return formattedDate;
  }
  static AnalyticsMDDate(ts, locale = "en", hasYear = false) {
    if (locale !== "en") {
      locale = returnMomentLocale(locale);
    }
    let out;
    let md;
    if (locale === "en_us") {
      md = "MMM D";
    } else {
      md = "MMM Do";
    }
    if (ts) {
      out = moment(ts)
        .locale(locale)
        .format(hasYear ? `${md}, YYYY` : md);
    } else {
      out = "";
    }
    return out;
  }

  static AnalyticsDate(
    latest = {},
    last = {},
    locale = "en",
    currentDateType = "yesterday",
  ) {
    if (locale !== "en") {
      locale = returnMomentLocale(locale);
    }
    let out;
    let md;
    let latestDateText = "";
    let lastDateText = "";
    let latestStartDate = latest?.from;
    let latestEndDate = latest?.to;
    let lastStartDate = last?.from;
    let lastEndDate = last?.to;
    if (locale === "en_us") {
      md = "MMM D";
    } else {
      md = "MMM Do";
    }
    let isYesterday = currentDateType === "yesterday";
    if (moment(latestStartDate).isAfter(latestEndDate)) {
      out = moment(latestStartDate).locale(locale).format(md);
    } else {
      if (
        Object.keys(latest)?.length &&
        Object.keys(last)?.length &&
        !isYesterday
      ) {
        latestDateText = getLatestDateText(latest, md, locale);
        lastDateText = getLastDateText(last, md, locale);
        if (
          moment(latestStartDate).format("YYYY") !==
          moment(lastStartDate).format("YYYY")
        ) {
          latestDateText = `${moment(latestStartDate)
            .locale(locale)
            .format(`${md}, YYYY`)} - ${moment(latestEndDate)
            .locale(locale)
            .format(`${md}, YYYY`)}`;
          lastDateText = `${moment(lastStartDate)
            .locale(locale)
            .format(`${md}, YYYY`)} - ${moment(lastEndDate)
            .locale(locale)
            .format(`${md}, YYYY`)}`;
        }
        out = `${latestDateText}&nbsp;&nbsp;${t(
          "getter_fe.analytics.comparedTo",
        )}&nbsp;&nbsp;${lastDateText}`;
      } else if (isYesterday) {
        latestDateText = `${moment(latestStartDate)
          .locale(locale)
          .format(`${md}, YYYY`)}`;
        lastDateText = `${moment(lastStartDate)
          .locale(locale)
          .format(`${md}, YYYY`)}`;
        out = `${latestDateText}&nbsp;&nbsp;${t(
          "getter_fe.analytics.comparedTo",
        )}&nbsp;&nbsp;${lastDateText}`;
      } else {
        if (Object.keys(latest)?.length) {
          latestDateText = getLatestDateText(latest, md, locale);
          out = latestDateText;
        } else if (Object.keys(last)?.length) {
          lastDateText = getLastDateText(last, md, locale);
          out = lastDateText;
        } else {
          out = "";
        }
      }
    }
    return out;
  }

  static AnalyticsCustomDate(range, locale = "en") {
    if (locale !== "en") {
      locale = returnMomentLocale(locale);
    }
    let out;
    let md;
    if (locale === "en_us") {
      md = "MMM D";
    } else {
      md = "MMM Do";
    }
    if (range) {
      if (
        !range?.to ||
        moment(range?.from).format(`${md}, YYYY`) ===
          moment(range?.to).format(`${md}, YYYY`)
      ) {
        out = moment(range?.from).locale(locale).format(`${md}, YYYY`);
      } else {
        if (
          moment(range?.from).format("YYYY") !==
          moment(range?.to).format("YYYY")
        ) {
          out = `${moment(range?.from)
            .locale(locale)
            .format(`${md}, YYYY`)} - ${moment(range?.to)
            .locale(locale)
            .format(`${md}, YYYY`)}`;
        } else {
          out = `${moment(range?.from).locale(locale).format(md)} - ${moment(
            range?.to,
          )
            .locale(locale)
            .format(`${md}, YYYY`)}`;
        }
      }
    } else {
      out = "";
    }
    return out;
  }

  static ErrorMsgUntilTime(ts, locale = "en") {
    if (locale !== "en") {
      locale = returnMomentLocale(locale);
    }
    let out;
    if (ts) {
      out = moment(ts).locale(locale).format("YYYY/MM/DD HH:MM");
    } else {
      out = "";
    }
    return out;
  }

  static SinceTimeV4(ts1, ts2 = null) {
    if (ts2 == null) {
      ts2 = Date.now();
    }

    ts1 = new Date(ts1);
    ts2 = new Date(ts2);
    const years = ts1.getFullYear() - ts2.getFullYear();
    if (years === 0) {
      return false;
    }
    // the full date return true.
    return true;
  }

  /**
   *
   * @param {number} ts1
   * @param {number | null} ts2
   * @param {*} locale
   * @param {*} verbose
   * @param {*} ago
   * @returns
   */
  static SinceTimeV5(
    ts1,
    ts2 = null,
    locale = "en_us",
    verbose = false,
    ago = false,
  ) {
    if (ts2 == null) {
      ts2 = Date.now();
    }

    const t1 = moment(ts1);
    const t2 = moment(ts2);
    const timeLocale = returnMomentLocale(locale);

    const min = t2.diff(t1, "minutes");
    const seconds = parseInt(t2.diff(t1, "seconds"));
    if (min < 60) {
      if (timeLocale !== "en_us") {
        const _mins = t1.locale(timeLocale).fromNow(!verbose);
        return _mins;
      }
      if (seconds < 5) {
        return t("getter_fe.timeline.common.justNow");
      } else if (seconds < 60) {
        return seconds === 0 ? 1 + "s" : seconds + "s";
      }
      return ago
        ? t("getter_fe.timeline.common.minutesAgo", {minutes: min})
        : `${min}m`;
    }

    const hours = t2.diff(t1, "hours");

    if (hours < 24) {
      // in the same day
      if (timeLocale !== "en_us") {
        const _hours = t1.locale(timeLocale).fromNow(!verbose);
        return _hours;
      }
      return ago
        ? t("getter_fe.timeline.common.hoursAgo", {hours: hours})
        : `${hours}h`;
    }

    const years = t1.get("y") - t2.get("y");
    if (years === 0) {
      // in the same year
      if (timeLocale !== "en_us") {
        return t1.locale(timeLocale).format("MMM Do");
      }
      return t1.locale(timeLocale).format("MMM D");
    }

    return t1.locale(timeLocale).format("ll");
  }

  // type: day / week / month / year
  static GetRangeDate(type = "day") {
    const table = {
      any_date: "anyDate",
      today: "day",
      this_week: "week",
      this_month: "month",
      this_year: "year",
    };
    return {
      start: moment().startOf(table[type]).unix() * 1000,
      end: moment().endOf(table[type]).unix() * 1000,
    };
  }

  static FormatTimePoint(millisecond, hideZeroHour) {
    const seconds = Math.floor(millisecond);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    return [
      ...(hideZeroHour && hours === 0 ? [] : [`0${hours}`.slice(-2)]),
      `0${minutes % 60}`.slice(-2),
      `0${seconds % 60}`.slice(-2),
    ].join(":");
  }

  static GetHistoryDateTitle(date, locale) {
    const historyTime = moment(date);

    const todayStart = moment().startOf("day");
    const todayEnd = moment().endOf("day");

    const yesterdayStart = moment().subtract(1, "day").startOf("day");
    const yesterdayEnd = moment().subtract(1, "day").endOf("day");

    const sevenDaysStart = moment().subtract(7, "day").startOf("day");

    if (
      historyTime.isSame(todayStart, "d") ||
      historyTime.isSame(todayEnd, "d")
    ) {
      return t("getter_fe.livelist.history.text.today");
    } else if (
      historyTime.isSame(yesterdayStart, "d") ||
      historyTime.isSame(yesterdayEnd, "d")
    ) {
      return t("getter_fe.livelist.history.text.yesterday");
    } else if (historyTime.isAfter(sevenDaysStart, "d")) {
      return historyTime.locale(locale).format("dddd");
    } else {
      return this.SinceTimeV5(historyTime, null, locale);
    }
  }

  static FindLeapYear(year) {
    return year % 100 === 0 ? year % 400 === 0 : year % 4 === 0;
  }

  static globalMoment(unix_time, locale) {
    const lang = getLang();
    const defaultLocale = returnMomentLocale(lang);

    return moment(unix_time).locale(locale ?? defaultLocale);
  }
} // TimeUtil
