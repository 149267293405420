import GAxios from "src/util/GAxios";
import {isResponseOk} from "src/util/FeedUtils";

export const getMaestroHostsV1 = async () => {
  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/u/posts/v2/livenow/ma/hosts`,
  };

  let res = null;

  await GAxios(
    config,
    (response) => {
      if (isResponseOk(response)) {
        res = response;
      }
    },
    (err) => {
      res = err;
    },
  );

  return res;
};
