import {
  isResponseOk,
  parseItemStats,
  parseGTokFeed,
  parseUser,
} from "src/util/FeedUtils";
import GAxios from "src/util/GAxios";
import {timelineConstants} from "../../timeline/_constants";
import {updateGTok, updateRecommendGTok} from "../store";
import {SEARCH_APIS} from "src/app/components/search/_constant";
import {setPostsStats} from "src/store/modules/postStats";

const fetchSearch = async (
  phrase = "",
  cursor = 0,
  postV1Cursor = 0,
  api = SEARCH_APIS.POST,
) => {
  let resp = null;
  try {
    const decodedPhrase = decodeURIComponent(phrase);
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}${api}`,
      data: {
        content: {
          incl: "posts|postinfo|stats|userinfo|visions",
          q: decodedPhrase,
          max: timelineConstants.MAX_BATCH_SIZE,
          ...(api === SEARCH_APIS.POST && cursor && {cursor}),
          ...(api === SEARCH_APIS.POST_V1 &&
            postV1Cursor && {cursor: postV1Cursor}),
        },
      },
      ignoreErrors: api === SEARCH_APIS.POST,
    };

    await GAxios(
      config,
      (res) => {
        if (isResponseOk(res)) {
          const {aux, data} = res?.data?.result ?? {};
          const {list} = data ?? {};
          const {lks: likedPosts, removed, shrs: sharedPosts} = aux ?? {};
          const users = parseUser(aux);

          let posts = parseGTokFeed(list, aux);

          let count = posts?.length ?? 0;
          posts = posts?.filter((post) => post != null);
          count = count - (posts?.length ?? 0);
          const postStats = parseItemStats(aux);

          resp = {
            likedPosts,
            posts,
            removed: removed + count,
            users,
            sharedPosts,
            postStats,
            ...(api === SEARCH_APIS.POST && {cursor: aux?.cursor || 0}),
            ...(api === SEARCH_APIS.POST_V1 && {
              postV1Cursor: aux?.cursor || 0,
            }),
          };
        }
      },
      async (err) => {
        if (api === SEARCH_APIS.POST)
          await fetchSearch(phrase, cursor, postV1Cursor, SEARCH_APIS.POST_V1);

        console.error(err);
      },
    );

    if (resp.err) {
      console.error(resp.err);
    }
  } catch (error) {
    console.error(error);
  }
  return resp;
};

export const getSearch = async (
  phrase = "",
  {getState, dispatch},
  switcher = {},
) => {
  const {vision} = getState?.() ?? {};
  const {cursor = 0, postV1Cursor = 0} = vision ?? {};
  const {fallback = false, autoCompletion = true} = switcher;

  if (!autoCompletion) {
    const res = (await fetchSearch(phrase, cursor, postV1Cursor)) ?? {};
    res?.postStats && dispatch(setPostsStats(res?.postStats));
    return {
      ...res,
      fallback,
    };
  }

  let totalPosts = [];
  let currentCursor = cursor;
  let firstFetch = true;
  while (
    firstFetch ||
    (currentCursor &&
      totalPosts?.length < timelineConstants?.AUTO_COMPLETION_SIZE)
  ) {
    firstFetch = false;
    const res = await fetchSearch(phrase, currentCursor);
    const {posts = [], postStats = {}, cursor: resCursor} = res ?? {};

    let action = fallback ? updateRecommendGTok : updateGTok;
    dispatch(action(...res, fallback));

    postStats && dispatch(setPostsStats(postStats));

    totalPosts = totalPosts?.concat(posts);
    currentCursor = resCursor;
  }
};
