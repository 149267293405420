import {toNumber} from "./NumberUtil";
import {userConstants} from "src/app/components/auth/_constants";
import {loginRefresh} from "src/app/components/auth/store";
import {multiAccountsLoginSendEventTrack} from "src/util/trackEvent";

export const getInfluenceLevel = (userInfo) => {
  const level = userInfo?.infl;
  if (!level || level === -1) {
    return 0;
  }
  return toNumber(level) || 0;
};

export const isInfluencer = (userInfo) => getInfluenceLevel(userInfo) > 0;

//-------- MultiAccount --------//
export const removeAllAccounts = () => {
  localStorage.removeItem(userConstants.LS_SESSION_INFO);
  localStorage.removeItem(userConstants.LS_SESSION_INFOS);
};

export const getMultiAccount = () => {
  try {
    return (
      JSON.parse(localStorage.getItem(userConstants.LS_SESSION_INFOS)) || {}
    );
  } catch (error) {
    return {};
  }
};

export const hasMultiAccount = () => {
  const users = getMultiAccount();

  return users && Object.keys(users).length > 1;
};

export const getMultiAccountSize = () => {
  const users = getMultiAccount();
  if (users) {
    return {size: Object.keys(users).length, dt: new Date().getTime()};
  }
  return {size: 0, dt: new Date().getTime()};
};

function saveUser(user) {
  localStorage.setItem(userConstants.LS_SESSION_INFO, JSON.stringify(user));
  loginRefresh(user);
}

function saveUsers(users) {
  localStorage.setItem(userConstants.LS_SESSION_INFOS, JSON.stringify(users));
}

export function removeUser(user) {
  if (!user) return;
  let users = getMultiAccount();
  delete users[user.username];
  if (Object.entries(users).length) {
    const [[, value]] = Object.entries(users);
    saveUser(value);
    saveUsers(users);
  } else {
    removeAllAccounts();
  }
}

export function refreshUsername(preUsername, newUserInfo) {
  if (!preUsername || !newUserInfo) return;
  let users = getMultiAccount();

  if (users && users[preUsername]) {
    users[preUsername].userinfo = newUserInfo;
  }
  let newUser = {
    [newUserInfo.username]: users[preUsername],
  };
  delete users[preUsername];

  users = {...newUser, ...users};
  saveUsers(users);
}

export const setMultiAccount = (loginData) => {
  if (!loginData || !loginData.userinfo) return;
  const currentUser = JSON.parse(
    localStorage.getItem(userConstants.LS_SESSION_INFO),
  );
  saveUser(loginData);
  multiAccountsLoginSendEventTrack(loginData.userinfo);
  const newUser = {
    [loginData.userinfo.username]: loginData,
  };
  let users = getMultiAccount();
  if (!users && currentUser?.userinfo?.username) {
    users = {[currentUser.userinfo.username]: currentUser};
  }
  users = {...newUser, ...users};
  saveUsers(users);
};

export const updateUserInfo = (userinfo) => {
  if (!userinfo) return;
  let users = getMultiAccount();
  if (users) {
    const currentUser = users[userinfo.username] || users[userinfo._id];
    if (currentUser) {
      currentUser.userinfo = userinfo;
    }
    saveUsers(users);
  } else {
    const currentUser = JSON.parse(
      localStorage.getItem(userConstants.LS_SESSION_INFO),
    );
    if (currentUser?.userinfo?.username) {
      users = {[currentUser.userinfo.username]: currentUser};
      saveUsers(users);
    }
  }
};

export const updateUserConfirmEmail = (data) => {
  if (!data) return;
  let users = getMultiAccount();
  if (users && data.username && users[data.username]) {
    const userInfo = users[data.username].userinfo;
    users[data.username].userinfo = {...userInfo, ...data};
    saveUsers(users);
  }
};

export const changeAccount = (user) => {
  saveUser(user);
  setMultiAccount(user);
};

export const isUserLoggedIn = (username, isPopup) => {
  // if in the login popup, check the user, if in the login page, do not check, and return false
  if (!isPopup) return false;

  if (!username) return false;
  username = username.toLowerCase();
  const users = getMultiAccount();
  if (!users) return false;
  let result = false;
  Object.values(users).forEach(({userinfo}) => {
    if (userinfo?._id === username || userinfo?.username === username) {
      result = true;
    }
    if (result) return result;
  });
  return result;
};

export const isUserVerified = (user) =>
  Boolean(user && (user.infl || user.isVerified));

export function getUserBadgesInfo(userInfo) {
  let badgeStyle = {
    horizontal: "right",
    vertical: "bottom",
  };
  let type = null;
  let level = null;
  let bio = null;
  let isFeaturedUser = false;
  let isPopularUser = false;
  let isGoldLevel = false;
  let isSilverLevel = false;
  const iconBadges = userInfo?.badges;

  if (!(iconBadges && Array.isArray(iconBadges) && iconBadges[0]))
    return {
      badgeStyle,
      type,
      level,
      bio,
      isFeaturedUser,
      isPopularUser,
      isGoldLevel,
      isSilverLevel,
    };

  const badgesArray = iconBadges[0].split("_");

  let position = null;
  if (badgesArray) {
    position = badgesArray[0].split("");
    if (position) {
      badgeStyle = {
        horizontal: position[0] === "l" ? "left" : "right",
        vertical: position[1] === "t" ? "top" : "bottom",
      };
    }
    type = badgesArray[1];
    level = badgesArray[2];
    bio = badgesArray[3];
    isFeaturedUser = type === "featured";
    isPopularUser = type === "popular";
    isSilverLevel =
      level === "3" ||
      userInfo?.creatorLevel === 3 ||
      userInfo?.creators?.popular?.creator_level === 3;
    isGoldLevel =
      level === "4" ||
      userInfo?.creatorLevel === 4 ||
      userInfo?.creators?.popular?.creator_level === 4;
  }

  return {
    badgeStyle,
    type,
    level,
    bio,
    isFeaturedUser,
    isPopularUser,
    isGoldLevel,
    isSilverLevel,
  };
}
