import GAxios from "src/util/GAxios";
import {likeRepostErrorMessage} from "../../../../util/ErrorCallback";

export const userSharesComment = async (data) => {
  if (!data) return;

  let params = {content: {text: ""}};
  params = JSON.stringify(params);

  const config = {
    method: data.isUnShares ? "delete" : "post",
    url: `${process.env.REACT_APP_API_URL}/u/user/${data.userId}/shares/comment/${data.objId}`,

    data: params,
  };

  let payload = {response: null, err: null};

  await GAxios(
    config,
    (response) => {
      if (response?.data?.result) {
        payload.response = response.data.result;
      }
    },
    (err) => {
      payload.err = err;
      likeRepostErrorMessage(err);
    },
  );

  return payload;
};
