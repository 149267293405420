import GAxios from "src/util/GAxios";
import {adaptList} from "src/app/components/LiveList/LiveListSearch/utils";
import {getLang} from "src/i18n/utils";

export const fetchReplays = async (data) => {
  let {phrase, filter, cursor, count = 10} = data;

  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/u/search/v2/stream/replay`,
    data: {
      content: {
        phrase: phrase,
        incl: "userstats|userinfo|followings|followers|islive|hasnewlive|posts|poststats",
        ...(cursor ? {cursor} : {}),
        filter,
        count,
        lang: getLang(),
      },
    },
    ignoreTips: {t429: true},
  };

  let res = {};

  await GAxios(
    config,
    (response) => {
      if (response?.data?.result) {
        res = {
          list: adaptList(response?.data?.result),
          reset: !cursor,
          phrase,
          filter,
          cursor:
            !response?.data?.result?.data?.list?.length ||
            /^0$/.test(response?.data?.result?.aux?.cursor)
              ? ""
              : response?.data?.result?.aux?.cursor,
        };
      }
    },
    (err) => {
      res = err;
    },
  );

  return res;
};
