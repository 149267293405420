import {
  isResponseOk,
  newPostStats,
  parseItemStats,
  parseUser,
} from "src/util/FeedUtils";
import GAxios from "src/util/GAxios";

export const getPost = async (postId, authenticated) => {
  let resp = null;

  try {
    const config = {
      method: "get",
      url:
        process.env.REACT_APP_API_URL +
        `/u/post/${postId}?incl="poststats|userinfo${
          authenticated ? "|shared|liked|pvotes" : ""
        }"`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await GAxios(
      config,
      (res) => {
        if (isResponseOk(res)) {
          const posts = [];
          const {aux, data} = res?.data?.result ?? {};
          data && posts.push(data);
          const {lks: likedPosts, shrs: sharedPosts} = aux ?? {};
          const users = parseUser(aux);
          const newStats = newPostStats(data, aux.s_pst);
          const postStats = parseItemStats({
            s_pst: {
              [aux?.s_pst._id || postId]: aux.s_pst,
            },
            newPostStats: {
              [aux?.s_pst._id || postId]: newStats,
            },
          });

          resp = {
            likedPosts,
            posts,
            users,
            sharedPosts,
            postStats,
          };
        }
      },
      (err) => {
        console.error(err);
      },
    );
  } catch (error) {
    console.error(error);
  }

  return resp;
};
