// Colors as defined in Zeplin style guide as of Sep 19, 2021
// Do not add/change colors arbitrarily here. Always sync with Zepplin.
export const lightThemePalette = {
  lightThemePrimary: "#232255",
  lightThemeSecondary: "#c00",
  lightThemeLightblue: "#016edc",
  lightThemeRedLabel: "#f32727",
  lightThemeGreen: "#51b06c",
  lightThemeWhite: "#ffffff",

  lightTextNtfGrey: "#46485b",
  lightTextPrimary: "#000",
  lightTextLightGrey: "#a3a3ae",
  lightTextSecondary: "#5c7192",
  lightTextLinkClicked: "#763ca6",
  lightTextGrey: "#6e7187",
  lightTextGrey2: "#565656",
  lightTextTextWhite: "#fff",
  lightTextDisabled: "#e2e2e2",

  lightButtonTextBk: "#000",
  lightButtonTextWhite: "#fff",
  lightButtonTextBkDisabled: "#b9b9b9",
  lightButtonTextBkDisabled2: "#6f6f6f",
  lightButtonTextWhiteDisabled: "#e2e2e2",
  lightButtonTextWhiteDisabled2: "#f5f5f5",
  lightButtonBkText: "#fff",
  lightButtonBkBg: "#000",
  lightButtonBkBgHover: "#545454",
  lightButtonPrimary: "#232255",
  lightButtonPrimaryDisabled: "#bdbdcc",
  lightButtonPrimaryHover: "#3d3c7c",
  lightButtonOutlineBgHover: "#e4e4e4",
  lightButtonBlueHover: "#0157ad",
  lightButtonWhiteDisabled: "#909090",
  lightButtonGreyHover: "#dbdde3",
  lightButtonGrey: "#eeeff3",
  lightButtonWhiteOutlineBgHover: "rgba(255, 255, 255, 0.2)",
  lightButtonRedHover: "#bd0a06",
  lightButtonRed: "#c00",
  lightButtonGreyDisabled: "#e4e4e4",
  lightButtonRedOutlibeBgHover: "#ffe7e8",
  lightButtonRedDisable: "#e3cece",
  lightButtonTab: "#232255",
  lightButtonTabDisable: "#ffffff",

  lightIconPrimary: "#acacb9",
  lightIconGrey: "#585656",
  lightIconBgGrey: "#545454",
  lightIconBgDark: "#333131",
  lightIconDisabled: "#dedee3",
  lightIconBgGrey2: "#e4e4e4",
  lightIconBgGrey2Hover: "#d1d5e5",

  lightBgDefault: "#f7f8f9",
  lightBgTimelineHover: "#fcfcfc",
  lightBgWhiteHover: "#f9f9f9",
  lightBgRightPanel: "#fdfdfd",
  lightBgLight1: "#eeeff3",
  lightBgLight2: "#f2f2f2",
  lightBgGrey: "#f5f5f5",
  lightBgGrey2: "#bdc6d3",
  lightBgGrey3: "#e5e5e5",
  lightBgGrey4: "#e0e0e0",
  lightBgBlueHover: "#f5f9ff",
  lightBgBlueHover1: "#ebf3ff",
  lightBgBlueToast: "#f2f9ff",
  lightBgGreen: "#e2f3e7",
  lightBgRed: "#ffe7e8",
  lightBgWhite: "#fff",
  lightBgOrangeToast: "#fa7124",
  // lightBgTransIconHover: "#00000059",

  lightLineGrey1: "#dfe1ea",
  lightLineGrey2: "#e8e9ef",
  lightLineGrey3: "#bdc6d3",
  lightLineBlue: "#759fec",
  lightLineOrange: "#cb3505",

  lightOverlayDark1: "rgba(0, 0, 0, 0.9)",
  lightOverlayLight1: "#fff",
  lightOverlayGray: "rgba(235, 235, 235, 0.9)",
  lightOverlayDark2: "#010101",

  lightUniqNavText: "#6e7187",
  lightUniqHeaderIconPrimary: "#232255",
  lightUniqBannerBlack: "#000",
  lightUniqToasterTextBlue: "#016edc",
  lightUniqBannerPrimary: "#232255",
  lightUniqToasterRedBg: "#ffe7e8",
  lightUniqTextErrorBg: "#ffe7e8",
  lightUniqTextHighlight: "#e0f1ff",
  lightUniqIconBtnHighlight: "rgba(199, 230, 255, 0.6)",
  lightPopupOverlayBackground: "rgba(0, 0, 0, 0.9)",
  lightPopupOverlayBackground2: "rgba(0, 0, 0, 0.5)",
  lightPopupOverlayBackground3: "rgba(0, 0, 0, 0.76)",
  lightUniqChatBubble: "#2e6be6",
  lightUniqChatBubble2: "#e9ebed",
  lightScrollbarBackground: "transparent",
  lightScrollbarThumb: "#c1c7d7",
};

// New colors defined in Zeplin as of May 27, 2022
export const newLightColors = {
  red: {
    [25]: "#fffbf5",
    [50]: "#FFF7EB",
    [100]: "#ffeacc",
    [500]: "#fc455a",
    [200]: "#feafb8",
    [300]: "#FFC87A",
    [400]: "#FFB752",
    [600]: "#FF9500",
    [700]: "#E28605",
    [800]: "#ab1728",
    [900]: "#83121f",
  },
  neutral: {
    [25]: "#fafafa",
    [50]: "#f5f5f5",
    [100]: "#e4e4e4",
    [150]: "#cbcdd5",
    [200]: "#cacaca",
    [300]: "#b1b1b1",
    [400]: "#989898",
    [500]: "#7f7f7f",
    [600]: "#666",
    [700]: "#4c4c4c",
    [800]: "#333",
    [900]: "#1a1a1a",
  },
  systemRed: {
    [25]: "#fff6f6",
    [50]: "#ffebeb",
    [100]: "#fdd4d0",
    [200]: "#fcb2ab",
    [300]: "#fa9085",
    [400]: "#f96d60",
    [500]: "#f74b3a",
    [600]: "#f62915",
    [700]: "#cf2212",
    [800]: "#a71c0e",
    [900]: "#80150b",
  },
  systemLightBlue: {
    [25]: "#f5fcfe",
    [50]: "#ecf9fd",
    [100]: "#ccf2fb",
    [200]: "#a3e7f8",
    [300]: "#7adcf5",
    [400]: "#52d1f1",
    [500]: "#29c7ee",
    [600]: "#00bceb",
    [700]: "#009ec5",
    [800]: "#0080a0",
    [900]: "#00627a",
  },
  systemBlue: {
    [25]: "#f5faff",
    [50]: "#ebf5ff",
    [100]: "#a3cfff",
    [200]: "#7abaff",
    [300]: "#52a5ff",
    [400]: "#298fff",
    [500]: "#007aff",
    [600]: "#0066d6",
    [700]: "#0053ad",
    [800]: "#003f85",
    [900]: "#002c5c",
  },
  systemGreen: {
    [25]: "#f5fefa",
    [50]: "#ecfdf6",
    [100]: "#cdf9e4",
    [200]: "#a5f4ce",
    [300]: "#7cefb8",
    [400]: "#54eba3",
    [500]: "#2ce68d",
    [600]: "#04e177",
    [700]: "#03bd64",
    [800]: "#039951",
    [900]: "#02753e",
  },
  systemYellow: {
    [25]: "#fffdf5",
    [50]: "#fffbeb",
    [100]: "#fff5cc",
    [200]: "#ffeda3",
    [300]: "#ffe47a",
    [400]: "#ffdc52",
    [500]: "#ffd429",
    [600]: "#fc0",
    [700]: "#d6ab00",
    [800]: "#ad8b00",
    [900]: "#856a00",
  },
  systemOrange: {
    [25]: "#fffbf5",
    [50]: "#fff7eb",
    [100]: "#ffeacc",
    [200]: "#ffd9a3",
    [300]: "#ffc87a",
    [400]: "#ffb752",
    [500]: "#ffa629",
    [600]: "#ff9500",
    [700]: "#d67d00",
    [800]: "#ad6500",
    [900]: "#854d00",
  },
  systemPurple: {
    [25]: "#F9F5FF",
    [50]: "#F0E6FF",
    [100]: "#E6DAFF",
    [200]: "#D7C2FE",
    [300]: "#BC99FE",
    [400]: "#9E6CFF",
    [500]: "#8447FF",
    [600]: "#6A39CC",
    [700]: "#4F2B99",
    [800]: "#351C66",
    [900]: "#1A0E33",
  },
  systemPink: {
    [25]: "#fff7f9",
    [50]: "#ffebf0",
    [100]: "#ffd5e2",
    [200]: "#ffb3ca",
    [300]: "#ff92b3",
    [400]: "#ff709b",
    [500]: "#ff4f84",
    [600]: "#ff2d6c",
    [700]: "#d6265b",
    [800]: "#ad1f49",
    [900]: "#851738",
  },
  brightRed: {
    [25]: "#fff5f5",
    [50]: "#ffebeb",
    [100]: "#ffcfcf",
    [200]: "#ffa9a9",
    [300]: "#ff8383",
    [400]: "#ff5c5c",
    [500]: "#ff3636",
    [600]: "#ff1010",
    [700]: "#d60d0d",
    [800]: "#ad0b0b",
    [900]: "#850808",
  },
  backgroundBg: {
    [0]: "#fff",
    "0_elevated": "#fff",
    [1]: "#f9f9f9",
    "1_darkened": "#f9f9f9",
    "1_darkened_opacity": "rgba(249,249,249,.9)",
    [2]: "#f3f3f4",
    [3]: "#edeeee",
    [4]: "#e7e8e9",
    [5]: "#e1e2e3",
    [6]: "#363636",
    [7]: "#f4f4f4",
  },
  unique: {
    inverseBlack: "#000",
    inverseWhite: "#fff",
    dmGrayText: "#b1b1b1",
    dmGrayBg: "#565656",
    dmBubbleBg: "#ffebeb",
    dmInnerBubbleBg: "#fd7383",
    dmGrayBg2: "#e1e2e3",
    red50: "#ffebed",
    red600: "#FC223B",
    red400: "#fd697a",
    red100: "#fed3d8",
    red200: "#feafb8",
    red300: "#921422",
    gray300: "#B1B1B1",
    red700: "#D41D32",
    red25: "#fff6f7",
  },
};
