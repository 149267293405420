import {useCallback, useEffect, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core";
import {
  zoomImage,
  handleMediaUrl,
  getTiledImageStyleFromMetadata,
} from "src/util/imageUtils";
import clsx from "clsx";
import {imgOnError} from "src/util/ErrorCallback";

const useStyles = makeStyles((theme) => ({
  rootTiledImages: {
    width: "100%",
    display: ({single}) => (single ? "block" : "grid"),
    gridTemplateColumns: "50% 50%",
    gridGap: "2px",
    maxHeight: ({single}) => (single ? "25px" : "284px"),
    overflow: "hidden",
    [theme.breakpoints.down("xs")]: {
      gridGap: "5px",
    },
    "& img": {
      width: "100%",
      height: ({imgData}) => (imgData ? "auto" : "100%"),
      objectFit: "cover",
      position: "relative",
    },
    "& .img_item": {
      width: "100%",
      height: "141px",
      position: "relative",
      [theme.breakpoints.down("xs")]: {
        height: "75px",
        maxWidth: ({isFromReplies}) => isFromReplies && theme.spacing(20),
      },
    },
    "& .img_1_1": {
      height: 284,
    },
    "& .img_1_2, .img_2_2": {
      height: "284px",
      [theme.breakpoints.down("xs")]: {
        height: "155px !important",
      },
    },
    "& .img_1_2": {
      "& img": {
        borderRadius: ({isFromReplies}) => isFromReplies && "14px 0px 0px 14px",
      },
    },
    "& .img_2_2": {
      "& img": {
        borderRadius: ({isFromReplies}) => isFromReplies && "0px 14px 14px 0px",
      },
    },
    "& .img_1_4": {
      "& img": {
        borderRadius: ({isFromReplies}) => isFromReplies && "14px 0px 0px 0px",
      },
    },
    "& .img_2_4": {
      "& img": {
        borderRadius: ({isFromReplies}) => isFromReplies && "0px 14px 0px 0px",
      },
    },
    "& .img_3_4": {
      "& img": {
        borderRadius: ({isFromReplies}) => isFromReplies && "0px 0px 0px 14px",
      },
    },
    "& .img_4_4": {
      "& img": {
        borderRadius: ({isFromReplies}) => isFromReplies && "0px 0px 14px 0px",
      },
    },
    "& .img_1_3": {
      height: "284px",
      gridColumn: 1,
      gridRow: "1 / span 2",
      "& img": {
        borderRadius: ({isFromReplies}) => isFromReplies && "14px 0px 0px 14px",
      },
      [theme.breakpoints.down("xs")]: {
        height: "155px !important",
      },
    },
    "& .img_2_3": {
      "& img": {
        borderRadius: ({isFromReplies}) => isFromReplies && "0px 14px 0px 0px",
      },
    },
    "& .img_3_3": {
      "& img": {
        borderRadius: ({isFromReplies}) => isFromReplies && "0px 0px 14px 0px",
      },
    },
  },
  img_moreThan_4: {
    "& div:first-child": {
      "& img": {
        borderRadius: "14px 0px 0px 0px",
      },
    },
    "& div:nth-child(2)": {
      "& img": {
        borderRadius: "0px 14px 0px 0px",
      },
    },
    "& div:nth-child(3)": {
      "& img": {
        borderRadius: "0px 0px 0px 14px",
      },
    },
    "& div:last-child": {
      "& img": {
        borderRadius: "0px 0px 14px 0px",
      },
    },
  },
  extraImgsCount: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "#11111199",
    fontSize: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.utility.white,
    borderRadius: ({isFromReplies}) => isFromReplies && "0px 0px 14px 0px",
  },
}));

const TiledImages = ({
  imageURLs,
  handleClick,
  imgData,
  noZoom,
  isFromReplies = false,
}) => {
  const [imageContainersDimensions, setImageContainersDimensions] = useState(
    [],
  );
  const rootContainerRef = useRef(null);

  // We should keep all items of imageUrls because it will mess up the index for handleClick()
  const _imageURLs = imageURLs.map(
    (url) =>
      handleMediaUrl(
        process.env.REACT_APP_MEDIA_BASE,
        noZoom ? url : zoomImage(url, 768, 0),
      ) || "",
  );

  const classes = useStyles({
    single: _imageURLs.length === 1,
    imgData,
    isFromReplies,
  });

  useEffect(() => {
    if (!imgData) {
      return;
    }
    const imageContainers =
      rootContainerRef.current?.querySelectorAll(".img_item");

    const resizeObserver = new ResizeObserver((entries) => {
      setImageContainersDimensions(
        entries.map(({contentRect: {width, height}}) => ({
          width,
          height,
        })),
      );
    });
    setImageContainersDimensions(
      [...imageContainers].map((imageContainer) => {
        const {width, height} = imageContainer.getBoundingClientRect();
        resizeObserver.observe(imageContainer);
        return {width, height};
      }),
    );

    return () =>
      imageContainers.forEach((imageContainer) =>
        resizeObserver.unobserve(imageContainer),
      );
  }, []);

  const handleClickImage = (e, index) => {
    e.stopPropagation();
    if (handleClick) handleClick(index);
  };

  const handleImgStyle = useCallback((index) => {
    return imgData
      ? getTiledImageStyleFromMetadata(
          imgData[index],
          imageContainersDimensions[index]?.width,
          imageContainersDimensions[index]?.height,
        )
      : null;
  }, []);

  return (
    <div
      className={clsx(
        classes.rootTiledImages,
        imageURLs?.length > 4 && isFromReplies ? classes.img_moreThan_4 : "",
      )}
      ref={rootContainerRef}
    >
      {[0, 1, 2, 3].map(
        (index) =>
          _imageURLs.length > index && (
            <div
              key={_imageURLs[index]}
              className={`img_item img_${index + 1}_${_imageURLs.length}`}
            >
              <img
                src={_imageURLs[index]}
                onClick={(e) => _imageURLs[index] && handleClickImage(e, index)}
                alt={"img"}
                style={handleImgStyle(index)}
                onError={(event) => {
                  const isResizeSrc = event.target.src === _imageURLs[index];
                  imgOnError(event, isResizeSrc, imageURLs[index]);
                }}
                draggable="false"
              />
              {index === 3 && _imageURLs.length > 4 ? (
                <div
                  className={classes.extraImgsCount}
                  onClick={(e) =>
                    _imageURLs[index] && handleClickImage(e, index)
                  }
                >
                  <span>+{_imageURLs.length - 4}</span>
                </div>
              ) : null}
            </div>
          ),
      )}
    </div>
  );
};

export default TiledImages;
