import GAxios from "src/util/GAxios";

export const getLivestreamUnread = (_, {getState}) => {
  const {auth} = getState() ?? {};
  const {_id, username} = auth?.session?.userinfo ?? {};

  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/u/notif/count/action/ls`,
      params: {
        uname: username,
        objid: _id,
      },
      ignoreErrors: true,
    };
    GAxios(
      config,
      (res) => {
        const {rc, result} = res?.data ?? {};

        if (rc === "OK") {
          return resolve(result);
        }
      },
      (err) => {
        return reject(err);
      },
    );
  });
};
