import GAxios from "src/util/GAxios";

export const getModuleSwitch = async () => {
  let result = null;

  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/u/user/v2/switch`,
    date: {},
    ignoreErrors: true,
  };

  await GAxios(
    config,
    (res) => {
      result = res;
    },
    (err) => {
      result = null;
    },
  );

  return result;
};
