import GAxios from "src/util/GAxios";

export const getSummaryByDate = async (params) => {
  const {
    userId,
    token,
    startDate = null,
    endDate = null,
    errorCallback,
  } = params;

  let xAppAuth =
    userId === null
      ? `{"user": null, "token": null}`
      : `{"user": "${userId}", "token": "${token}"}`;
  const config = {
    method: "get",
    headers: {
      "x-app-auth": xAppAuth,
    },
    url: `${process.env.REACT_APP_IMPRESSION_API_URL}/api/user_daily/summaries`,
    params: {
      startDate: startDate, // default: 7 days ago
      endDate: endDate, // default: today
    },
  };

  let res = {response: null, err: false};

  await GAxios(
    config,
    (response) => {
      if (response?.data) {
        res.response = response.data;
      }
    },
    (err) => {
      res.err = true;
      if (errorCallback) errorCallback(err);
    },
  );
  return res;
};
