export const SUGGESTION_MODES = {
  RECENT: 1,
  RESULT: 2,
};

export const MAX_LIST_ITEMS = 5;

export const SUGGESTION_TYPES = {
  ALL: 0,
  RECENT: 1,
  HASHTAG: 2,
  USER: 3,
  SUGGESTION: 4,
  LIVE_SUGGESTION: 5,
};

export const NEW_LOCAL_STORAGE_HISTORY_RECENT_SEARCH =
  "newLocalStorageHistoryRecentSearch";

export const VIDEO_POSITION_ITEM_KEY_AFTER_FRESH_PAGE =
  "VIDEO_POSITION_ITEM_KEY_AFTER_FRESH_PAGE";
export const VIDEO_POSITION_ITEM_KEY = "VIDEO_POSITION_ITEM_KEY";
