import {socialConfig, socialConstants, userConstants} from "../_constants";
import GAxios from "src/util/GAxios";
import {getLang} from "src/i18n/utils";
import {setMultiAccount} from "src/util/userUtil";

export const socialSignup = async (params) => {
  if (!params) return;
  const {username, birth_year, token, pvrinfo, callbackHandle, idtoken} =
    params;
  const lang = getLang();
  const inviter = localStorage.getItem(userConstants.INVITE_UN);
  const pvr_key = (pvrinfo?.pvr_key || "").toLowerCase();
  const auth0Info = localStorage.getItem(
    socialConstants.LOCAL_STORAGE_AUTH0_INFO,
  );
  let authinfo = auth0Info;
  let data = null;
  let url = null;

  let userinfo = inviter
    ? {
        username,
        lang,
        inviter,
      }
    : {
        username,
        lang,
      };

  if (idtoken) {
    url = process.env.REACT_APP_API_URL + "/u/user/v2/idtokensignup";
    data = {
      content: {
        authinfo: {
          idtoken,
        },
        pvrinfo,
        userinfo,
        sainfo: {
          birth_year,
        },
      },
    };
  } else {
    url = process.env.REACT_APP_API_URL + "/v1/s/social/sign_up";
    if (auth0Info) {
      data = {
        content: {
          authinfo: {
            a0_token: token,
          },
          pvrinfo: {
            clientId: socialConfig.AUTH0.clientID,
            pvr_key: auth0Info,
            ver: "v1",
          },
          userinfo,
          sainfo: {
            birth_year,
          },
        },
      };
    } else {
      //   switch (pvr_key) {
      //     case socialConstants.GOOGLE_PVR_KEY:
      //       authinfo = {gg_token: token};
      //       break;
      //     case socialConstants.APPLE_PVR_KEY:
      //       authinfo = {ap_token: token};
      //       break;
      //     case socialConstants.TWITTER_PVR_KEY:
      //       authinfo = {tw_token: token};
      //       break;
      //     case socialConstants.FACEBOOK_PVR_KEY:
      //       authinfo = {fb_token: token};
      //       break;
      //   }
      //   data = {
      //     content: {
      //       pvrinfo,
      //       userinfo: {
      //         username,
      //         lang,
      //       },
      //       authinfo,
      //       sainfo: {
      //         birth_year,
      //       },
      //     },
      //   };
    }
  }

  let loginData = {
    pt: "",
    payload: null,
    authenticated: false,
    userinfo: null,
    err: null,
  };

  if (authinfo) {
    const config = {
      method: "post",
      url,
      headers: {
        "Content-Type": "application/json",
      },
      data,
    };

    const callback = (err, res) => {
      if (err) {
        const {response} = err;
        loginData.err = response?.data?.error
          ? response?.data?.error?.code
          : err.toString();
      } else {
        loginData.err = null;
        loginData.authenticated = !err;
        loginData.userinfo = res;

        if (loginData.authenticated && loginData.userinfo) {
          const sessionInfo = {
            authenticated: loginData.authenticated,
            userinfo: loginData.userinfo,
          };

          setMultiAccount(sessionInfo);
        }
      }

      callbackHandle && callbackHandle();
    };
    const processResult = (result) => {
      let err = null;
      let {pt, payload} = result;

      if (idtoken) {
        payload = result;
        pt = socialConstants.SIGN_IN_PAYLOAD_TYPE.SI_OK;
      }

      loginData.pt = pt;
      loginData.payload = payload;

      let authenticated = payload["token"];
      let loadedUserInfo = payload["user"];

      if (authenticated && loadedUserInfo) {
        loadedUserInfo.token = authenticated;
      } else {
        err = new Error("Auth failed");
      }

      callback(err, loadedUserInfo);
    };

    await GAxios(
      config,
      (response) => {
        processResult(response.data.result);
      },
      (err) => {
        console.error(err);
        callback(err);
      },
    );
  } else {
    loginData.err = "Missing auth token";
  }

  return loginData;
};
