import {tippingErrorConsts} from "src/app/components/gettrTip/constants";
import GAxios from "src/util/GAxios";

export const getTransactions = async (data) => {
  if (!data.userId || !data.token) {
    return null;
  }
  let url = `${process.env.REACT_APP_API_URL}/gc/transactions`;
  const {userId, token, startTime, event, status, endTime, skip, limit} = data;

  url += `?userId=${userId}&token=${token}&startTime=${startTime}&endTime=${endTime}&event=${event}&status=${status}&limit=${limit}&skip=${skip}`;
  if (data.searchQuery) {
    url += `&searchQuery=${encodeURIComponent(data.searchQuery)}`;
  }
  const config = {
    method: "GET",
    url,
    headers: {
      "Content-Type": "application/json",
    },
  };

  let res = null;
  await GAxios(config, ({data}) => {
    res = data?.result?.data;
  });
  return res;
};

export const getTransactionDetail = async (data) => {
  if (!data.transactionId) {
    return null;
  }
  const config = {
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/gc/transactions/${data.transactionId}`,
    data,
    headers: {
      "Content-Type": "application/json",
    },
  };

  let res = null;
  await GAxios(config, ({data}) => {
    res = data?.result?.data;
  });
  return res;
};

export const redeemToken = async (data, errorCallback) => {
  if (!data.email || !data.user || !data.currency || !data.amount) {
    return null;
  }
  const config = {
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/gc/redeem-reward`,
    data: {content: data},
    headers: {
      "Content-Type": "application/json",
    },
  };

  let res = null;
  try {
    await GAxios(
      config,
      ({data}) => {
        res = data?.result?.data;
      },
      (err) => {
        res = null;
        if (errorCallback) errorCallback(err.response.data.error);
      },
    );
  } catch (error) {
    return error;
  }
  return res;
};

export const getListOfRewards = async (data, errorCallback) => {
  if (!data.country) {
    return null;
  }
  let query = `country=${data.country}`;
  if (data.currency) {
    query = `${query}&currency=${data.currency}`;
  }
  const config = {
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/gc/rewards?${query}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  let res = null;
  try {
    await GAxios(
      config,
      ({data}) => {
        res = data?.result?.data;
      },
      (err) => {
        res = null;
        if (errorCallback) errorCallback(err.response.data.error);
      },
    );
  } catch (error) {
    return error;
  }

  return res;
};

export const createPaymentRequest = async (data) => {
  if (!data || !data.requester || !data.from || !data.amount) {
    return null;
  }
  const config = {
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/gc/payment-requests`,
    data: {content: data},
    headers: {
      "Content-Type": "application/json",
    },
  };

  let res = null;
  await GAxios(config, ({data}) => {
    res = data?.result?.data;
  });
  return res;
};

export const getPaymentRequestDetails = async (id) => {
  if (!id) {
    return null;
  }
  const config = {
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/gc/payment-requests/${id}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  let res = null;
  await GAxios(config, ({data}) => {
    res = data?.result?.data;
  });
  return res;
};

export const getPaymentRequests = async ({
  user,
  searchQuery,
  startTime,
  endTime,
  skip,
  limit,
}) => {
  if (!user) {
    return null;
  }
  const config = {
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/gc/payment-requests?user=${user}&searchQuery=${searchQuery}&startTime=${startTime}&endTime=${endTime}&skip=${skip}&limit=${limit}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  let res = null;
  await GAxios(config, ({data}) => {
    res = data?.result?.data;
  });
  return res;
};

export const rejectPaymentRequest = async (id) => {
  if (!id) {
    return null;
  }
  const config = {
    method: "PATCH",
    url: `${process.env.REACT_APP_API_URL}/gc/payment-requests/${id}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  let res = null;
  await GAxios(config, ({data}) => {
    res = data?.result?.data;
  });
  return res;
};

export const sendGTRToken = async (data, errorCallback) => {
  if (!data || !data.sender || !data.receiver || !data.amount) {
    return null;
  }
  const config = {
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/gc/send-token`,
    data: {content: data},
    headers: {
      "Content-Type": "application/json",
    },
  };

  let res = null;
  await GAxios(
    config,
    ({data}) => {
      res = data?.result?.data;
    },
    (err) => {
      res = null;
      if (errorCallback) errorCallback(err.response.data.error);
    },
  );
  return res;
};
export const getReedemHistory = async (
  {user, searchQuery, startTime, endTime, skip, limit},
  errorCallback,
) => {
  if (!user) {
    return null;
  }
  const config = {
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/gc/redeem-history?searchQuery=${searchQuery}&startTime=${startTime}&endTime=${endTime}&skip=${skip}&limit=${limit}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  let res = null;
  try {
    await GAxios(
      config,
      ({data}) => {
        res = data?.result?.data;
      },
      (err) => {
        res = null;
        if (errorCallback) errorCallback(err.response.data.error);
      },
    );
  } catch (error) {
    return error;
  }
  return res;
};

export const getTipData = async (data) => {
  if (!data || !data.receiver) {
    return null;
  }
  const config = {
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/gc/wallets/receive-tip`,
    data: {content: data},
    headers: {
      "Content-Type": "application/json",
    },
  };

  let res = null;
  await GAxios(
    config,
    ({data}) => {
      res = data?.result?.data;
    },
    (err) => {
      let errorData = err?.response?.data;
      if (errorData?.error?.code === "E_NOT_ALLOWED") {
        res = tippingErrorConsts.RECEIVER_PEP;
      } else {
        return errorData;
      }
    },
  );
  return res;
};
export const getStickers = async () => {
  const config = {
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/gc/tip-stickers`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  let res = null;
  try {
    await GAxios(config, ({data}) => {
      res = data?.result?.data;
    });
  } catch (error) {
    return error;
  }
  return res;
};

export const sendTip = async (data, postId, receiverId, isTipFromProfile) => {
  if (!data) {
    return null;
  }
  const config = {
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/gc/tip/${
      isTipFromProfile ? `user/${receiverId}` : `post/${postId}`
    }`,
    data: {content: data},
    headers: {
      "Content-Type": "application/json",
    },
  };

  let res = null;
  await GAxios(config, ({data}) => {
    res = data?.result?.data;
  });
  return res;
};

export const getRefferedUsers = async (data) => {
  if (!data.userId) {
    return null;
  }
  let url = `${process.env.REACT_APP_API_URL}/gc/u/referred-users`;
  const {userId, token, startTime, event, status, endTime, skip, limit} = data;

  url += `?userId=${userId}&token=${token}&startTime=${startTime}&endTime=${endTime}&event=${event}&limit=${limit}&skip=${skip}`;
  if (data.searchQuery) {
    url += `&searchQuery=${encodeURIComponent(data.searchQuery)}`;
  }
  if (data.status) {
    url += `&status=${status}`;
  }
  const config = {
    method: "GET",
    url,
    headers: {
      "Content-Type": "application/json",
    },
  };

  let res = null;
  await GAxios(config, ({data}) => {
    res = data?.result?.data;
  });
  return res;
};
export const getConfigDetails = async (data) => {
  const config = {
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/gc/u/config`,
    data,
    headers: {
      "Content-Type": "application/json",
    },
  };

  let res = null;
  await GAxios(config, ({data}) => {
    res = data?.result?.data;
  });
  return res;
};
