import {
  createWallet,
  getBalance,
  getConversionRate,
  getWalletUserDetails,
  checkEarlyAccess,
} from "./wallet";
import {
  getTransactions,
  redeemToken,
  getPaymentRequestDetails,
  sendGTRToken,
  createPaymentRequest,
  getTipData,
  getStickers,
  getConfigDetails,
} from "./transactions";
export const GettrPayApi = {
  createWallet,
  getBalance,
  getConversionRate,
  getWalletUserDetails,
  getTransactions,
  redeemToken,
  getPaymentRequestDetails,
  sendGTRToken,
  createPaymentRequest,
  checkEarlyAccess,
  getTipData,
  getStickers,
  getConfigDetails,
};
