import GAxios from "src/util/GAxios";
import {likeRepostErrorMessage} from "src/util/ErrorCallback";

export const toggleLike = async (
  {targetId, likeStatus, type, successCallback, errorCallback},
  {getState},
) => {
  const {auth} = getState() ?? {};
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/u/user/${
      auth.session?.userinfo?.ousername
    }/${likeStatus === "y" ? "unlike" : "likes"}/${
      type === "post" ? "post" : "comment"
    }/${targetId}`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  let result = undefined;
  let error = false;
  await GAxios(
    config,
    (response) => {
      if (response === null) {
        errorCallback && errorCallback();
        return null;
      }
      successCallback && successCallback();
      result = {
        postId: targetId,
        likeStatus: response.data.result[0],
        likes: response.data.result[1],
      };
      return [!likeStatus, targetId];
    },
    (err) => {
      errorCallback && errorCallback(err);
      likeRepostErrorMessage(err);
      error = true;
    },
  );
  if (error) {
    throw new Error("Failed to toggle like");
  }
  return result;
};
