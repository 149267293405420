import {getBlockedUsers} from "./getBlockedUsers";
import {getMutedUsers} from "./getMutedUsers";
import {currentUserBlockedUser} from "./currentUserBlockedUser";
import {currentUserMutesUser} from "./currentUserMutesUser";
import {currentUserFollowsUser} from "./currentUserFollowsUser";
import {currentUserRemoveUser} from "./currentUserRemoveUser";
import {toggleMute} from "./toggleMute";
import {toggleFollowing, follow, unfollow} from "./toggleFollowing";
import {toggleBlock} from "./toggleBlock";
import {turnedOffLiveNotification} from "./turnedOffLiveNotification";

export const StatusApi = {
  currentUserBlockedUser,
  currentUserFollowsUser,
  currentUserMutesUser,
  getBlockedUsers,
  getMutedUsers,
  toggleMute,
  toggleFollowing,
  toggleBlock,
  turnedOffLiveNotification,
  currentUserRemoveUser,
  follow,
  unfollow,
};
