import GAxios from "src/util/GAxios";
import {socialConstants} from "../_constants";
import {showToast} from "../../../../util/showToast";
import {t} from "src/i18n/utils";
import {handleErrorCodes} from "../../../../util/handleErrorCode";

export const socialDisconnect = async (params) => {
  const {pvrinfo, callback} = params;
  if (!pvrinfo) return;

  let res = {
    pt: "",
    status: null,
  };

  const data = JSON.stringify({
    content: {
      pvrinfo: pvrinfo,
    },
  });

  const config = {
    method: "post",
    url: process.env.REACT_APP_API_URL + "/v1/s/social/disconnect",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  await GAxios(
    config,
    (response) => {
      if (
        response &&
        response.data?.result?.pt ===
          socialConstants.SIGN_OUT_PAYLOAD_TYPE.SO_OK
      ) {
        res = response.data?.result;
        callback && callback();
      }
    },
    (err) => {
      res = null;
      let errorCode = err?.response?.data?.error?.code;
      if (errorCode === "E_BAD_PARAMS") {
        showToast(
          "warning",
          t("getter_fe.auth.errors.socialDisconnect_E_BAD_PARAMS"),
        );
      } else {
        handleErrorCodes(err);
      }
    },
  );

  return res;
};
