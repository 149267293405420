// Colors as defined in Zeplin style guide as of Sep 19, 2021
// Do not add/change colors arbitrarily here. Always sync with Zepplin.
export const darkThemePalette = {
  darkThemePrimary: "#135df1",
  darkThemeLightblue: "#1589fe",
  darkThemeSecondary: "#c00",
  darkThemeGreen: "#51b06c",
  darkThemeRedLabel: "#f32727",
  darkThemeWhite: "#ffffff",

  darkTextPrimary: "#fff",
  darkTextNtfGrey: "#777a8d",
  darkTextSecondary: "#9daabe",
  darkTextGrey: "#9698a6",
  darkTextGrey2: "#b5b5b5",
  darkTextGrey4: "#dadada",
  darkTextLightGrey: "#6d6d8d",
  darkTextLight2: "#f2f2f2",
  darkTextTextWhite: "#fff",
  darkTextLinkClicked: "#763ca6",
  darkKeywordMatched: "#d6dce2",

  darkButtonTextBk: "#000",
  darkButtonTextBkDisabled2: "#383838",
  darkButtonTextWhite: "#fff",
  darkButtonTextBkDisabled: "#575757",
  darkButtonTextWhiteDisabled2: "#909090",
  darkButtonTextWhiteDisabled: "#5a6b8e",
  darkButtonBkText: "#000",
  darkButtonPrimary: "#0e57e9",
  darkButtonBkBg: "#fff",
  darkButtonPrimaryHover: "#0b46bc",
  darkButtonBkBgHover: "#545454",
  darkButtonPrimaryDisabled: "#35486e",
  darkButtonBlueHover: "#0157ad",
  darkButtonOutlineBgHover: "#232832",
  darkButtonWhiteOutlineBgHover: "rgba(255, 255, 255, 0.2)",
  darkButtonWhiteDisabled: "#585858",
  darkButtonGrey: "#e4e4e4",
  darkButtonRedHover: "#a80905",
  darkButtonGreyHover: "#a0a0a2",
  darkButtonGreyDisabled: "#949494",
  darkButtonRedOutlibeBgHover: "#322323",
  darkButtonRed: "#c00",
  darkButtonRedDisable: "#8d5858",
  darkButtonTab: "#ffffff",
  darkButtonTabDisable: "#2c2f3f",

  darkIconPrimary: "#b5b5c1",
  darkIconGrey: "#585656",
  darkIconBgDark: "#333131",
  darkIconDisabled: "#283749",
  darkIconBgGrey: "#545454",
  darkIconBgGrey2: "#283543",
  darkIconBgGrey2Hover: "#35465a",

  darkBgDefault: "#121921",
  darkBgWhite: "#161e27",
  darkBgTimelineHover: "#182535",
  darkBgRightPanel: "#222b34",
  darkBgWhiteHover: "#182535",
  darkBgLight1: "#303f50",
  darkBgLight2: "#1d2835",
  darkBgGrey: "#1b232d",
  darkBgGrey2: "#3b5068",
  darkBgGrey4: "#0b0f14",
  darkBgBlueHover: "#3b4554",
  darkBgBlueHover1: "#3b4554",
  darkBgBlueToast: "#21384c",
  darkBgGreen: "#38423b",
  darkBgRed: "#653436",
  darkBgOrangeToast: "#fa7124",
  darkBgTransIconHover: "#00000059",

  darkLineGrey1: "#3f435a",
  darkLineGrey2: "#2c2f3f",
  darkLineGrey3: "#4c5663",
  darkLineBlue: "#759fec",
  darkLineOrange: "#cb3505",

  darkOverlayGray: "#000",
  darkOverlayDark1: "rgba(0, 0, 0, 0.9)",
  darkOverlayLight1: "#fff",

  darkUniqNavText: "#ebebeb",
  darkUniqHeaderIconPrimary: "#fff",
  darkUniqToasterTextBlue: "#fff",
  darkUniqToasterRedBg: "#e6a7aa",
  darkUniqTextErrorBg: "#8f0d12",
  darkUniqTextHighlight: "#004782",
  darkUniqIconBtnHighlight: "rgba(7, 104, 159, 0.6)",
  darkUniqBannerPrimary: "#203144",
  darkUniqBannerBlack: "#000",
  darkPopupOverlayBackground: "rgba(0, 0, 0, 0.9)",
  darkPopupOverlayBackground2: "rgba(0, 0, 0, 0.5)",
  darkPopupOverlayBackground3: "rgba(0, 0, 0, 0.76)",
  darkUniqChatBubble: "#3169d8",
  darkUniqChatBubble2: "#283039",
  darkScrollbarBackground: "transparent",
  darkScrollbarThumb: "#c1c7d7",
};

// New colors defined in Zeplin as of May 27, 2022
export const newDarkColors = {
  red: {
    [25]: "#180937",
    [50]: "#32264B",
    [100]: "#311765",
    [200]: "#6F0F1A",
    [300]: "#4d2897",
    [400]: "#5b30b1",
    [500]: "#D91D33",
    [600]: "#8447FF",
    [700]: "#532CA2",
    [800]: "#FD6072",
    [900]: "#FD7F8D",
  },
  neutral: {
    [25]: "#1A1A1A",
    [50]: "#333333",
    [100]: "#4C4C4C",
    [150]: "#505050",
    [200]: "#666666",
    [300]: "#7F7F7F",
    [400]: "#989898",
    [500]: "#B1B1B1",
    [600]: "#CACACA",
    [700]: "#E4E4E4",
    [800]: "#F5F5F5",
    [900]: "#FAFAFA",
  },
  systemRed: {
    [25]: "#2E0900",
    [50]: "#470D05",
    [100]: "#5A0F07",
    [200]: "#6C1209",
    [300]: "#8F180C",
    [400]: "#B11E0F",
    [500]: "#D42312",
    [600]: "#F62915",
    [700]: "#F74736",
    [800]: "#F96557",
    [900]: "#FA8377",
  },
  systemLightBlue: {
    [25]: "#00252E",
    [50]: "#003C4D",
    [100]: "#004B61",
    [200]: "#005970",
    [300]: "#006D8B",
    [400]: "#0087AC",
    [500]: "#00A2CE",
    [600]: "#00BCEF",
    [700]: "#24C5F1",
    [800]: "#47CFF3",
    [900]: "#6BD8F6",
  },
  systemBlue: {
    [25]: "#00172E",
    [50]: "#03264A",
    [100]: "#04305C",
    [200]: "#054585",
    [300]: "#075AAD",
    [400]: "#086FD6",
    [500]: "#0A84FF",
    [600]: "#3198FF",
    [700]: "#58ABFF",
    [800]: "#80BFFF",
    [900]: "#A7D3FF",
  },
  systemGreen: {
    [25]: "#002e12",
    [50]: "#024a27",
    [100]: "#015f33",
    [200]: "#026e3a",
    [300]: "#028345",
    [400]: "#03a256",
    [500]: "#03c266",
    [600]: "#04e177",
    [700]: "#27e58a",
    [800]: "#4ae99d",
    [900]: "#6deeb0",
  },
  systemYellow: {
    [25]: "#2e2500",
    [50]: "#493c04",
    [100]: "#5d4e04",
    [200]: "#705e04",
    [300]: "#947c06",
    [400]: "#b89a07",
    [500]: "#dbb809",
    [600]: "#ffd60a",
    [700]: "#ffdc2c",
    [800]: "#ffe14f",
    [900]: "#ffe771",
  },
  systemOrange: {
    [25]: "#2e1c00",
    [50]: "#492c04",
    [100]: "#5d3a04",
    [200]: "#704604",
    [300]: "#945c06",
    [400]: "#b87207",
    [500]: "#db8909",
    [600]: "#ff9f0a",
    [700]: "#ffac2c",
    [800]: "#ffba4f",
    [900]: "#ffc771",
  },
  systemPurple: {
    [25]: "#180937",
    [50]: "#24104E",
    [100]: "#311765",
    [200]: "#3F1F7E",
    [300]: "#4D2897",
    [400]: "#5B30B1",
    [500]: "#6A39CC",
    [600]: "#6A39CC",
    [700]: "#7651D3",
    [800]: "#8366D9",
    [900]: "#917ADF",
  },
  systemPink: {
    [25]: "#260817",
    [50]: "#410b21",
    [100]: "#520f2a",
    [200]: "#701439",
    [300]: "#941a4b",
    [400]: "#b8205e",
    [500]: "#db2770",
    [600]: "#ff2d82",
    [700]: "#ff4a94",
    [800]: "#ff68a5",
    [900]: "#ff85b7",
  },
  brightRed: {
    [25]: "#2e0000",
    [50]: "#470505",
    [100]: "#5b0606",
    [200]: "#700707",
    [300]: "#940909",
    [400]: "#b80c0c",
    [500]: "#db0e0e",
    [600]: "#ff1010",
    [700]: "#ff3131",
    [800]: "#ff5353",
    [900]: "#ff7474",
  },
  backgroundBg: {
    [0]: "#1f1f1f",
    "0_elevated": "#262626",
    [1]: "#282a2a",
    "1_darkened": "#161616",
    "1_darkened_opacity": "rgba(22,22,22,.9)",
    [2]: "#333",
    [3]: "#444",
    [4]: "#5a5a5b",
    [5]: "#717172",
    [6]: "#363636",
    [7]: "#444444",
    [8]: "#4d2897",
  },
  unique: {
    inverseWhite: "#000",
    inverseBlack: "#fff",
    dmBubbleBg: "#470505",
    dmInnerBubbleBg: "#87121f",
    dmGrayText: "#a8a8a8",
    dmGrayBg: "#292929",
    dmGrayBg2: "#444",
    red50: "#44080F",
    red600: "#FC223B",
    red400: "#B5182A",
    red100: "#560B14",
    red200: "#6F0F1A",
    red300: "#921422",
    gray300: "#B1B1B1",
    red700: "#FC4156",
    red25: "#260808",
  },
};
