export const DisposableList = [];

export class Disposable {
  constructor({name, disposeFn}) {
    this.name = name || "";
    this.disposeFn = disposeFn || null;
    this.isDisposed = false;
  }

  dispose() {
    if (!this.isDisposed) {
      this.disposeFn && this.disposeFn();
      this.isDisposed = true;
    }
  }
}

export const WssErrHistory = {
  index: 0,
  history: [],
  firstErrTime: 0,
  lastErrTime: 0,
  diffTime: 0,
};
