import {toast, Slide} from "react-toastify";
import {NotifMessage} from "src/app/components/notifications/NotifMessage";
import AppConst from "src/app/AppConsts";

export const showToast = (type, message, params = {}) => {
  let arr = [
    AppConst.NOTIF_SUCCESS,
    AppConst.NOTIF_ERROR,
    AppConst.NOTIF_WARNING,
  ];
  const {msgShort, custom, autoClose, scene} = params;
  const firstLetterCapitalize = params.firstLetterCapitalize !== false; // if undefined, consider as true
  for (let i = 0; i < arr.length; i++) {
    let r = arr[i];
    if (r === type) {
      toast(
        <NotifMessage
          message={message}
          firstLetterCapitalize={firstLetterCapitalize}
          scene={scene}
          alignCenter={params?.alignCenter}
        />,
        {
          position: toast.POSITION.TOP_CENTER,
          type: toast.TYPE[String(type).toUpperCase()],
          msgShort,
          custom,
          autoClose,
        },
      );
      return;
    }
  }
  toast(
    <NotifMessage
      message={message}
      firstLetterCapitalize={firstLetterCapitalize}
    />,
    {
      position: toast.POSITION.TOP_CENTER,
      type: toast.TYPE.DEFAULT,
      msgShort,
      custom,
    },
  );
};

export function customToast(msg) {
  toast(<NotifMessage message={msg} />, {
    position: toast.POSITION.TOP_CENTER,
    type: toast.TYPE.ERROR,
    icon: false,
    transition: Slide,
    autoClose: 5000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });

  // const Msg = ({closeToast, toastProps}) => (
  //   <div>
  //     {msg}
  //     <button onClick={closeToast}>Close</button>
  //   </div>
  // );
  // toast(<Msg />, {
  //   position: toast.POSITION.TOP_CENTER,
  //   type: toast.TYPE.DEFAULT,
  //   transition: Slide,
  //   autoClose: false,
  //   hideProgressBar: false,
  //   closeOnClick: true,
  //   pauseOnHover: true,
  //   draggable: true,
  //   progress: undefined,
  //   ...params,
  // });
}
