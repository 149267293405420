import {createAction, createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {AnalyticsApi} from "src/app/components/analytics/api";

const NS = "analytics";

export const dateTypeArray = [
  "yesterday",
  "last7Days",
  "last30Days",
  "thisMonth",
  "lastMonth",
  "custom",
];

const initialState = {
  userDataLoading: false,
  postDataLoading: false,
  userData: null,
  postData: null,
  showActivityData: null,
  currentDateType: dateTypeArray[0],
  postPopupOptions: {
    isOpen: false,
    handleClosePopupDialog: () => {},
    statusLineData: {},
    post: {},
    post_id: null,
  },
  liveListTopSlideIdArray: [],
  liveListHomeCategory: "all",
};

export const getSummaryByDate = createAsyncThunk(
  `${NS}/getSummaryByDate`,
  AnalyticsApi.getSummaryByDate,
);

export const getUserDataByDate = createAsyncThunk(
  `${NS}/getUserDataByDate`,
  AnalyticsApi.getUserDataByDate,
);

export const getUserDataByMonth = createAsyncThunk(
  `${NS}/getUserDataByMonth`,
  AnalyticsApi.getUserDataByMonth,
);

export const getPostDataByDate = createAsyncThunk(
  `${NS}/getPostDataByDate`,
  AnalyticsApi.getPostDataByDate,
);

export const setShowActivityData = createAction(`${NS}/setShowActivityData`);
export const setPostPopupOptions = createAction(`${NS}/setPostPopupOptions`);
export const setCurrentDateType = createAction(`${NS}/setCurrentDateType`);
export const setLiveListTopSlideIdArray = createAction(
  `${NS}/setLiveListTopSlideIdArray`,
);

export const setLiveListHomeCategory = createAction(
  `${NS}/setLiveListHomeCategory`,
);

export const analyticsSlice = createSlice({
  name: NS,
  initialState,
  reducers: {
    setShowActivityData: (state, {payload}) => {
      state.showActivityData = payload;
    },
    setPostPopupOptions: (state, {payload}) => {
      state.postPopupOptions = payload;
    },
    setCurrentDateType: (state, {payload}) => {
      state.currentDateType = payload;
    },
    setLiveListTopSlideIdArray: (state, {payload}) => {
      if (!state.liveListTopSlideIdArray.includes(payload)) {
        state.liveListTopSlideIdArray = [
          ...state.liveListTopSlideIdArray,
          payload,
        ];
      }
    },
    setLiveListHomeCategory: (state, {payload}) => {
      state.liveListHomeCategory = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSummaryByDate.pending, (state, {payload}) => {
      state.userDataLoading = true;
      state.userData = null;
    });
    builder.addCase(getSummaryByDate.fulfilled, (state, {payload}) => {
      if (!payload.err) {
        state.userData = payload.response;
      }
      state.userDataLoading = false;
    });
    builder.addCase(getSummaryByDate.rejected, (state, {payload}) => {
      state.userDataLoading = false;
      state.userData = null;
    });
    builder.addCase(getUserDataByDate.pending, (state, {payload}) => {
      state.userDataLoading = true;
      state.userData = null;
    });
    builder.addCase(getUserDataByDate.fulfilled, (state, {payload}) => {
      if (!payload.err) {
        state.userData = payload.response;
      }
      state.userDataLoading = false;
    });
    builder.addCase(getUserDataByDate.rejected, (state, {payload}) => {
      state.userDataLoading = false;
      state.userData = null;
    });
    builder.addCase(getUserDataByMonth.pending, (state, {payload}) => {
      state.userDataLoading = true;
      state.userData = null;
    });
    builder.addCase(getUserDataByMonth.fulfilled, (state, {payload}) => {
      if (!payload.err) {
        state.userData = payload.response;
      }
      state.userDataLoading = false;
    });
    builder.addCase(getUserDataByMonth.rejected, (state, {payload}) => {
      state.userDataLoading = false;
      state.userData = null;
    });
    builder.addCase(getPostDataByDate.pending, (state, {payload}) => {
      state.postDataLoading = true;
      state.postData = null;
    });
    builder.addCase(getPostDataByDate.fulfilled, (state, {payload}) => {
      if (!payload.err) {
        if (payload.response) {
          state.postData = payload.response;
        } else if (payload.response === false) {
          state.postData = "coming";
        }
      }
      state.postDataLoading = false;
    });
    builder.addCase(getPostDataByDate.rejected, (state, {payload}) => {
      state.postDataLoading = false;
      state.postData = null;
    });
  },
});
