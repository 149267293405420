import GAxios from "src/util/GAxios";
import {changeFollowingStatus} from "src/store/modules/status";
import {setFollowClick, setUnFollowClick} from "src/app/components/post/store";
import {
  removePostsForBanAndMute,
  removePostsFromProfileForBanAndMute,
} from "src/app/components/timeline/store";

export const toggleFollowing = (
  {targetId, successCallback, errorCallback, from = null},
  {dispatch, getState},
) => {
  return new Promise((resolve, reject) => {
    const {status, auth} = getState() ?? {};
    const userId = auth.session?.userinfo?.ousername;
    if (!userId || !targetId) {
      reject();
      return errorCallback();
    }
    const isFollowing = status?.following && status?.following[targetId];
    const isBlocked = status?.blocked && status?.blocked[targetId];
    const isMuted = status?.muted && status?.muted[targetId];

    const config = {
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/u/user/${userId}/${
        isFollowing ? "unfollows" : "follows"
      }/${targetId}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (from) {
      config.data = {from};
    }

    const handleError = (error) => {
      if (errorCallback) errorCallback(error);
      return reject(null);
    };

    GAxios(
      config,
      (response) => {
        if (response === null) {
          errorCallback && errorCallback();
          return resolve(null);
        }
        dispatch(
          changeFollowingStatus({
            userId: targetId,
            status: !isFollowing,
          }),
        );
        if (isFollowing) {
          dispatch(setUnFollowClick(true));
          dispatch(removePostsForBanAndMute({uid: targetId}));
          isBlocked &&
            isMuted &&
            dispatch(removePostsFromProfileForBanAndMute({uid: targetId}));
        } else {
          dispatch(setFollowClick(true));
        }
        successCallback(isFollowing);
        return resolve([!isFollowing, targetId]);
      },
      handleError,
    ).catch(handleError);
  });
};

export const follow = ({targetId, from = null}, {dispatch, getState}) => {
  return new Promise((resolve, reject) => {
    const {auth} = getState() ?? {};
    const userId = auth.session?.userinfo?.ousername;
    if (!userId || !targetId) return reject();

    const config = {
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/u/user/${userId}/follows/${targetId}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (from) {
      config.data = {from};
    }
    GAxios(
      config,
      (response) => {
        if (response === null) {
          return reject();
        }
        dispatch(
          changeFollowingStatus({
            userId: targetId,
            status: true,
          }),
        );
        dispatch(setFollowClick(true));
        resolve();
      },
      (err) => {
        return reject();
      },
      // reject,
    ).catch(reject);
  });
};

export const unfollow = ({targetId, from = null}, {dispatch, getState}) => {
  return new Promise((resolve, reject) => {
    const {auth} = getState() ?? {};
    const userId = auth.session?.userinfo?.ousername;
    if (!userId || !targetId) return reject();

    const config = {
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/u/user/${userId}/unfollows/${targetId}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (from) {
      config.data = {from};
    }
    GAxios(
      config,
      (response) => {
        if (response === null) {
          return reject();
        }
        dispatch(
          changeFollowingStatus({
            userId: targetId,
            status: false,
          }),
        );
        dispatch(setUnFollowClick(true));
        resolve();
      },
      (err) => {
        return reject();
      },
    ).catch(reject);
  });
};
