import GAxios from "src/util/GAxios";
import {CHAT_API} from "./index";

/**
 * Leave the group chat.
 * If the user is the owner of the group chat(who created the group chat), the group chat will be disabled.
 * @param {string} messageId group chat id
 */
export const deleteMessageById = async ({messageId}) => {
  const config = {
    method: "delete",
    url: `${process.env.REACT_APP_API_URL}/${CHAT_API}/channel/group/message`,
    data: {
      content: {
        message_id: messageId,
      },
    },
    ignoreErrors: true,
  };

  let res = null;

  await GAxios(
    config,
    (response) => {
      if (response?.data?.result) {
        res = response.data.result;
      }
    },
    (error) => {
      res = error;
    },
  );

  return res;
};
