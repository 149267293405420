import {ref, string, number} from "yup";
import {t} from "src/i18n/utils";
import {
  USER_MAX_LETTERS_NUMBER,
  USER_MIN_LETTERS_NUMBER,
  USER_LOGIN_MIN_LETTERS_NUMBER,
  USER_MIN_TIPPING_AMOUNT,
  USER_MAX_TIPPING_AMOUNT,
  LINK_MAX_LETTERS,
  LINK_MIN_LETTERS,
  USER_MAX_HOBBY_NUMBER,
} from "../app/AppConsts";
import {EMAIL_LINK_PATTERN_STRING} from "./regex";

export const passwordValidation = (passwordRequired) => {
  return string()
    .required(
      passwordRequired
        ? passwordRequired
        : t("getter_fe.auth.common.newPwdRequired"),
    )
    .min(6, t("getter_fe.auth.common.minCharact"))
    .max(128, t("getter_fe.settings.errors.passwordMinMax"))
    .matches(/^[^\s]*$/, t("getter_fe.settings.errors.spaceNotAllowed"))
    .matches(/[a-zA-Z]/, t("getter_fe.settings.errors.passwordHaveLetter"))
    .matches(
      /^(?=.*[a-zA-Z\d].*)[a-zA-Z\d!#$&()*+,\-.:;<=>?@[\]^_{|}~]{6,128}$/,
      t("getter_fe.settings.errors.passwordValidation"),
    );
};

export const newPasswordValidation = (passwordRequired) => {
  return string()
    .required(passwordRequired)
    .notOneOf(
      [ref("currentPass")],
      t("getter_fe.settings.errors.passwordSameToOldOne"),
    )
    .min(6, t("getter_fe.auth.common.minCharact"))
    .max(128, t("getter_fe.settings.errors.passwordMinMax"))
    .matches(/^[^\s]*$/, t("getter_fe.settings.errors.spaceNotAllowed"))
    .matches(/[a-zA-Z]/, t("getter_fe.settings.errors.passwordHaveLetter"))
    .matches(
      /^(?=.*[a-zA-Z\d].*)[a-zA-Z\d!#$&()*+,\-.:;<=>?@[\]^_{|}~]{6,128}$/,
      t("getter_fe.settings.errors.passwordValidation"),
    );
};

export const usernameValidation = (usernameRequired) => {
  return string()
    .required(
      usernameRequired
        ? usernameRequired
        : t("getter_fe.auth.errors.usernameRequired"),
    )
    .min(USER_MIN_LETTERS_NUMBER, t("getter_fe.auth.errors.usernameMinMax"))
    .max(USER_MAX_LETTERS_NUMBER, t("getter_fe.auth.errors.usernameMinMax"))
    .matches(/^[^\s]*$/, t("getter_fe.settings.errors.spaceNotAllowed"))
    .matches(
      /^[a-zA-Z0-9_]*$/,
      t("getter_fe.auth.errors.usernameLetterNumberUnderscore"),
    );
};

export const usernameLoginValidation = (usernameRequired) => {
  return string()
    .required(
      usernameRequired
        ? usernameRequired
        : t("getter_fe.auth.errors.usernameRequired"),
    )
    .min(
      USER_LOGIN_MIN_LETTERS_NUMBER,
      t("getter_fe.auth.errors.usernameMinMax"),
    )
    .max(USER_MAX_LETTERS_NUMBER, t("getter_fe.auth.errors.usernameMinMax"))
    .matches(/^[^\s]*$/, t("getter_fe.settings.errors.spaceNotAllowed"))
    .matches(
      /^[a-zA-Z0-9_]*$/,
      t("getter_fe.auth.errors.usernameLetterNumberUnderscore"),
    );
};

export const emailMatches =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const emailValidation = () => {
  return string()
    .required(t("getter_fe.auth.errors.emailRequired"))
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      t("getter_fe.auth.errors.emailInvalid"),
    )
    .max(320, t("getter_fe.auth.errors.emailMaxLength", {maxLength: 320}));
};
export const tippingAmountValidation = () => {
  return number()
    .required(t("getter_fe.gettrTip.errors.amountRequired"))
    .min(
      USER_MIN_TIPPING_AMOUNT,
      t("getter_fe.gettrTip.errors.tippingAmountMinMax"),
    )
    .max(
      USER_MAX_TIPPING_AMOUNT,
      t("getter_fe.gettrTip.errors.tippingAmountMinMax"),
    )
    .typeError(() => t("getter_fe.gettrTip.errors.validNumber"));
};

export const linkValidation = (required, key) => {
  return string()
    .required(
      required
        ? required
        : t(`getter_fe.shareAndEarn.onboarding.error.${key}Required`),
    )
    .min(
      LINK_MIN_LETTERS,
      t(`getter_fe.shareAndEarn.onboarding.error.${key}MinMax`),
    )
    .max(
      LINK_MAX_LETTERS,
      t(`getter_fe.shareAndEarn.onboarding.error.${key}MinMax`),
    );
};

export const fullNameValidation = (required, key) => {
  return string()
    .required(
      required
        ? required
        : t(`getter_fe.shareAndEarn.onboarding.error.${key}Required`),
    )
    .max(
      key === t("getter_fe.shareAndEarn.onboarding.labels.hobby")
        ? USER_MAX_HOBBY_NUMBER
        : USER_MAX_LETTERS_NUMBER,
      t(`getter_fe.shareAndEarn.onboarding.error.${key}MinMax`),
    );
};
export const personalDetailsNameValidation = (required, key) => {
  return string()
    .required(
      required
        ? required
        : t(`getter_fe.shareAndEarn.onboarding.error.${key}Required`),
    )
    .matches(
      /^[a-zA-Z\s]+$/,
      t("getter_fe.shareAndEarn.onboarding.error.invalidName"),
    )
    .max(
      USER_MAX_LETTERS_NUMBER,
      t(`getter_fe.shareAndEarn.onboarding.error.${key}MinMax`),
    );
};

export const emailValidationOnboarding = () => {
  return string()
    .required(t("getter_fe.auth.errors.emailRequired"))
    .matches(
      EMAIL_LINK_PATTERN_STRING,
      t("getter_fe.auth.errors.enterValidEmail"),
    )
    .max(50, t("getter_fe.auth.errors.emailMaxLength", {maxLength: 50}));
};
