import {createSlice, createAsyncThunk, createAction} from "@reduxjs/toolkit";
import {PostApi} from "../api";
import {isPlainObject, findIndex, times} from "lodash-es";
import {
  addLikedPosts,
  addSharedPosts,
  setBatchPostCounts,
} from "src/app/components/timeline/store";
import {GettrPayApi} from "src/store/modules/pay/api";
import {parseCommentFeed, parseUser} from "src/util/FeedUtils";

const readBaseUrl = process.env.REACT_APP_MEDIA_BASE;
const NS = "post";

const initialState = {
  postSubmit: {
    data: {},
    isLoading: false,
    error: false,
    success: false,
    popupDialogOpen: false,
    shareText: "",
  },
  editPostSubmit: {
    originPost: {},
    embededPost: {},
    embededUser: {},
    user: {},
    popupDialogOpen: false,
    scene: "",
    onEdit: () => {},
  },
  editLivePostSubmit: {
    originPost: {},
    embededPost: {},
    embededUser: {},
    user: {},
    popupDialogOpen: false,
    scene: "",
  },
  repostSubmit: {
    data: {},
    isLoading: false,
    error: false,
    success: false,
  },
  userSharesPost: {
    data: {},
    isLoading: false,
    error: false,
    success: false,
  },
  userSharesComment: {
    data: {},
    isLoading: false,
    error: false,
    success: false,
  },
  commentSubmit: {
    data: null,
    users: [],
    raw_items: null, // raw data list
    items: [], // show data list
    isLoading: false,
    error: false,
    success: false,
  },
  popupRepost: {
    popupDialogOpen: false,
    postId: "",
    item: {},
    sharedObj: {},
    user: {},
    originalUser: {},
    embededPost: {},
    embededUser: {},
    loggedinUserId: "",
    hideDropdown: () => {},
    toggleOpen: () => {},
  },
  popupComment: {
    popupDialogOpen: false,
    postId: "",
    item: {},
    sharedObj: {},
    user: {},
    users: [],
    originalUser: {},
    embededPost: {},
    embededUser: {},
    loggedinUserId: "",
    alreadyReposted: {},
    hideDropdown: () => {},
    toggleOpen: () => {},
  },
  popupImages: {
    open: false,
    postId: null,
    item: null,
    sharedObj: {},
    imageURLs: [],
    headerContent: {},
    currentIndex: null,
    type: "post",
    xStats: null,
    hidePostStatLine: false,
    onClose: () => {},
  },
  reportContent: {
    itemId: null,
  },
  stream: {
    data: {},
    datas: {},
  },
  downloadDialog: {
    open: false,
  },
  inviteDialog: {
    open: false,
  },
  pinPost: {
    updateSignal: 0,
  },
  deletedDictionary: {},
  userListDialogOptions: {},
  profile: {
    statFollows: null,
    statGettrFollowers: null,
    statFollowers: null,
    isFollowOptionClicked: false,
    isUnfollowOptionClicked: false,
  },
  imageEdit: {
    data: {},
    isLoading: false,
    error: false,
    success: false,
    refreshImg: false,
  },
  topicData: {
    isLoading: false,
    data: [],
  },
};

export const postSubmit = createAsyncThunk(
  "post/postSubmit",
  PostApi.postSubmit,
);

export const editPostSubmit = createAsyncThunk(
  "post/editPostSubmit",
  PostApi.editPostSubmit,
);

export const repostSubmit = createAsyncThunk(
  "post/repostSubmit",
  PostApi.repostSubmit,
);

export const userSharesPost = createAsyncThunk(
  "post/userSharesPost",
  PostApi.userSharesPost,
);

export const userSharesComment = createAsyncThunk(
  "post/userSharesComment",
  PostApi.userSharesComment,
);

export const commentSubmit = createAsyncThunk(
  "post/commentSubmit",
  PostApi.commentSubmit,
);

export const deletePost = createAsyncThunk(
  "post/deletePost",
  PostApi.deletePost,
);

export const deleteStreamPost = createAsyncThunk(
  "post/deleteStreamPost",
  PostApi.deleteStreamPost,
);

export const deletePostComment = createAsyncThunk(
  "post/deletePostComment",
  PostApi.deletePostComment,
);

export const reportPost = createAsyncThunk(
  "post/reportPost",
  PostApi.reportPost,
);

export const embed_Post = createAsyncThunk(
  "post/embedPost",
  PostApi.embed_Post,
);

export const getStream = createAsyncThunk("post/getStream", PostApi.getStream);

export const getMaStream = createAsyncThunk(
  "post/getMaStream",
  PostApi.getMaStream,
);
export const getStreams = createAsyncThunk(
  "post/getStreams",
  PostApi.getStreams,
);
export const votePoll = createAsyncThunk("post/votePoll", PostApi.votePoll);
export const getTopicData = createAsyncThunk(
  "post/getTopicData",
  PostApi.getTopicData,
);

export const handleImageEdit = createAsyncThunk(
  "post/handleImageEdit",
  PostApi.handleImageEdit,
);

export const fetchPostInfo = createAsyncThunk(
  "post/fetchPostInfo",
  PostApi.fetchPostInfo,
);

export const resetEditImageDetails = createAction(
  `${NS}/resetEditImageDetails`,
);
export const setStreamData = createAction(`${NS}/setStreamData`);
export const setStreamDatas = createAction(`${NS}/setStreamDatas`);
export const delStreamData = createAction(`${NS}/delStreamData`);

export const setPostSubmitShareText = createAction(
  `${NS}/setPostSubmitShareText`,
);
export const setPostSubmitPopupDialogOpen = createAction(
  `${NS}/setPostSubmitPopupDialogOpen`,
);
export const setDeletedDictionary = createAction(`${NS}/setDeletedDictionary`);
export const setUserListDialogOptions = createAction(
  `${NS}/setUserListDialogOptions`,
);
let cacheCursor = {};
export const fetchComments = createAsyncThunk(
  "post/fetchComments",
  async (payload, {dispatch, rejected, rejectWithValue}) => {
    try {
      const {postId, cursor} = payload;
      if (!cacheCursor[postId]) {
        cacheCursor[postId] = [];
      }
      cursor && cacheCursor[postId].push(cursor);
      const result = await PostApi.fetchComments(payload);

      if (result.err) {
        rejected(result.err);
      } else {
        return result.response;
      }
    } catch (error) {
      rejected(error);
    }
  },
  {
    condition: (payload, {getState}) => {
      const {postId, cursor} = payload;
      if (!cacheCursor[postId]) {
        cacheCursor = {};
        return true;
      } else {
        const existing = cacheCursor[postId].includes(cursor);
        return !existing;
      }
    },
  },
);

export const setEditPostSubmitPopupDialogOpen = createAction(
  `${NS}/setEditPostSubmitPopupDialogOpen`,
);
export const setEditLivePostPopupDialogOpen = createAction(
  `${NS}/setEditLivePostPopupDialogOpen`,
);
export const setComment = createAction(`${NS}/setComment`);
export const setCommentSubmitUsers = createAction(
  `${NS}/setCommentSubmitUsers`,
);
export const setCommentItems = createAction(`${NS}/setCommentItems`);
export const removeCommentItems = createAction(`${NS}/removeCommentItems`);
export const updateCommentItems = createAction(`${NS}/updateCommentItems`);
export const setPopupComment = createAction(`${NS}/setPopupComment`);
export const togglePopupComment = createAction(`${NS}/togglePopupComment`);
export const setPopupRepost = createAction(`${NS}/setPopupRepost`);
export const togglePopupRepost = createAction(`${NS}/togglePopupRepost`);
export const setPopupImages = createAction(`${NS}/setPopupImages`);
export const closePopupImages = createAction(`${NS}/closePopupImages`);
export const setReportContent = createAction(`${NS}/setReportContent`);
export const closeReportContent = createAction(`${NS}/closeReportContent`);
export const visibleDownloadDialog = createAction(
  `${NS}/visibleDownloadDialog`,
);
export const visibleInviteDialog = createAction(`${NS}/visibleInviteDialog`);
export const setPinPostUpdateSignal = createAction(
  `${NS}/setPinPostUpdateSignal`,
);
export const setStatFollows = createAction(`${NS}/setStatFollows`);
export const setStatFollowers = createAction(`${NS}/setStatFollowers`);
export const setStatGettrFollowers = createAction(
  `${NS}/setStatGettrFollowers`,
);
export const setFollowClick = createAction(`${NS}/setFollowClick`);
export const setUnFollowClick = createAction(`${NS}/setUnFollowClick`);

export const postSlice = createSlice({
  name: NS,
  initialState,
  reducers: {
    setPostSubmitShareText: (state, {payload}) => {
      state.postSubmit.popupDialogOpen = true;
      state.postSubmit.shareText = payload;
    },
    setPostSubmitPopupDialogOpen: (state, {payload}) => {
      state.postSubmit.popupDialogOpen = payload;
    },
    setEditPostSubmitPopupDialogOpen: (state, {payload}) => {
      state.editPostSubmit = {...state.editPostSubmit, ...payload};
    },
    setEditLivePostPopupDialogOpen: (state, {payload}) => {
      state.editLivePostSubmit = {...state.editLivePostSubmit, ...payload};
    },
    setComment: (state, {payload}) => {
      state.commentSubmit.data = payload;
    },
    setCommentItems: (state, {payload}) => {
      state.commentSubmit.items = payload;
    },
    removeCommentItems: (state, {payload}) => {
      const commentId = payload;
      state.commentSubmit.items = [
        ...state.commentSubmit.items.filter((item) => item.id !== commentId),
      ];
    },
    updateCommentItems(state, {payload}) {
      // find the edited Index
      const editedIndex = findIndex(
        state.commentSubmit.items,
        (item) => item.id === payload._id,
      );
      let comments = [...(state.commentSubmit.items || [])];
      if (editedIndex === -1) {
        comments = [{...payload, id: payload._id}, ...comments];
      } else {
        const {prevsrc, dsc, previmg, ttl, ...rest} = {
          ...comments[editedIndex],
        };
        comments.splice(editedIndex, 1, {...rest, ...payload});
      }

      state.commentSubmit.items = comments;
    },
    setPopupComment: (state, {payload}) => {
      state.popupComment = payload;
    },
    togglePopupComment: (state, {payload}) => {
      state.popupComment.popupDialogOpen = payload;
    },
    setPopupRepost: (state, {payload}) => {
      state.popupRepost = payload;
    },
    togglePopupRepost: (state, {payload}) => {
      state.popupRepost.popupDialogOpen = payload;
    },
    setPopupImages: (state, {payload}) => {
      state.popupImages = payload;
    },
    closePopupImages: (state) => {
      state.popupImages.open = false;
    },
    setReportContent: (state, {payload}) => {
      state.reportContent.itemId = payload;
    },
    closeReportContent: (state) => {
      state.reportContent.itemId = null;
    },
    setEmbedPostContent: (state, {payload}) => {
      state.embedPostContent.itemId = payload;
    },
    closeEmbedPostContent: (state) => {
      state.embedPostContent.itemId = null;
    },
    setStreamData: (state, {payload}) => {
      state.stream.data = {...state.stream.data, ...payload};
    },
    setStreamDatas: (state, {payload}) => {
      if (payload) {
        state.stream.datas = {...state.stream.datas, ...payload};
      }
    },
    delStreamData: (state, {payload}) => {
      state.stream.data = {};
    },
    visibleDownloadDialog: (state, {payload}) => {
      state.downloadDialog.open = payload;
    },
    visibleInviteDialog: (state, {payload}) => {
      state.inviteDialog.open = payload;
    },
    setPinPostUpdateSignal: (state) => {
      state.pinPost.updateSignal += 1;
    },
    setCommentSubmitUsers: (state, {payload}) => {
      state.commentSubmit.users = payload;
    },
    setDeletedDictionary: (state, {payload}) => {
      state.deletedDictionary = payload;
    },
    setUserListDialogOptions: (state, {payload}) => {
      state.userListDialogOptions = payload;
    },
    setSelectedTheme: (state, {payload}) => {
      state.embedPostContent.selectedTheme = payload;
    },
    setSelectedlang: (state, {payload}) => {
      state.embedPostContent.selectedLang = payload;
    },
    setCustomOptionDialog: (state, {payload}) => {
      state.embedPostContent.customOption = payload;
    },
    setStatFollows: (state, {payload}) => {
      state.profile.statFollows = payload;
    },
    setStatFollowers: (state, {payload}) => {
      state.profile.statFollowers = payload;
    },
    setStatGettrFollowers: (state, {payload}) => {
      state.profile.statGettrFollowers = payload;
    },
    setFollowClick: (state, {payload}) => {
      state.profile.isFollowOptionClicked = payload;
    },
    setUnFollowClick: (state, {payload}) => {
      state.profile.isUnfollowOptionClicked = payload;
    },
    resetEditImageDetails: (state, {payload}) => {
      state.imageEdit.data = {...payload.data};
      state.imageEdit.isLoading = false;
      state.imageEdit.error = false;
      state.imageEdit.success = false;
    },
  },
  extraReducers: (builder) => {
    // Doc: https://redux-toolkit.js.org/usage/usage-with-typescript#type-safety-with-extrareducers

    builder.addCase(postSubmit.pending, (state) => {
      state.postSubmit.success = false;
      state.postSubmit.isLoading = true;
      state.postSubmit.error = false;
    });

    builder.addCase(postSubmit.fulfilled, (state, {payload}) => {
      state.postSubmit.isLoading = false;

      if (payload?.response && !payload?.err) {
        state.postSubmit.error = false;
        state.postSubmit.success = true;
        state.postSubmit.data = payload;
      } else {
        state.postSubmit.error = true;
        state.postSubmit.success = false;
      }
    });

    builder.addCase(postSubmit.rejected, (state) => {
      state.postSubmit.success = false;
      state.postSubmit.isLoading = false;
      state.postSubmit.error = true;
    });

    builder.addCase(repostSubmit.pending, (state) => {
      state.repostSubmit.success = false;
      state.repostSubmit.isLoading = true;
      state.repostSubmit.error = false;
    });

    builder.addCase(repostSubmit.fulfilled, (state, {payload}) => {
      state.repostSubmit.isLoading = false;

      if (payload?.response && !payload?.err) {
        state.repostSubmit.error = false;
        state.repostSubmit.success = true;
        state.repostSubmit.data = payload;
      } else {
        state.repostSubmit.error = true;
        state.repostSubmit.success = false;
      }
    });

    builder.addCase(repostSubmit.rejected, (state) => {
      state.repostSubmit.success = false;
      state.repostSubmit.isLoading = false;
      state.repostSubmit.error = true;
    });

    builder.addCase(userSharesPost.pending, (state) => {
      state.userSharesPost.success = false;
      state.userSharesPost.isLoading = true;
      state.userSharesPost.error = false;
    });

    builder.addCase(userSharesPost.fulfilled, (state, {payload}) => {
      state.userSharesPost.isLoading = false;

      if (payload?.response && !payload?.err) {
        state.userSharesPost.error = false;
        state.userSharesPost.success = true;
        state.userSharesPost.data = payload;
      } else {
        state.userSharesPost.error = true;
        state.userSharesPost.success = false;
      }
    });

    builder.addCase(userSharesPost.rejected, (state) => {
      state.userSharesPost.success = false;
      state.userSharesPost.isLoading = false;
      state.userSharesPost.error = true;
    });

    builder.addCase(userSharesComment.pending, (state) => {
      state.userSharesComment.success = false;
      state.userSharesComment.isLoading = true;
      state.userSharesComment.error = false;
    });

    builder.addCase(userSharesComment.fulfilled, (state, {payload}) => {
      state.userSharesComment.isLoading = false;

      if (payload?.response && !payload?.err) {
        state.userSharesComment.error = false;
        state.userSharesComment.success = true;
        state.userSharesComment.data = payload;
      } else {
        state.userSharesComment.error = true;
        state.userSharesComment.success = false;
      }
    });

    builder.addCase(userSharesComment.rejected, (state) => {
      state.userSharesComment.success = false;
      state.userSharesComment.isLoading = false;
      state.userSharesComment.error = true;
    });

    builder.addCase(commentSubmit.fulfilled, (state, {payload}) => {});

    builder.addCase(reportPost.fulfilled, (state, {payload}) => {});

    builder.addCase(fetchComments.pending, (state, payload) => {
      state.commentSubmit.isLoading = true;
      state.commentSubmit.success = false;
      state.commentSubmit.error = false;
    });
    builder.addCase(fetchComments.fulfilled, (state, {payload}) => {
      state.commentSubmit.isLoading = false;

      const aux = payload.aux;
      const list = payload.list;

      const postsData = parseCommentFeed(list, aux) || [];
      const usersData = parseUser(aux);

      if (payload) {
        state.commentSubmit.raw_items = {aux, list};
        state.commentSubmit.users = {
          ...(state.commentSubmit.users ?? {}),
          ...(usersData ?? {}),
        };
        if (payload.cursor) {
          // Pagination
          state.commentSubmit.items = [
            ...(state.commentSubmit.items ?? []),
            ...(postsData ?? []),
          ];
        } else {
          // first request
          state.commentSubmit.items = [...(postsData ?? [])];
        }

        state.commentSubmit.success = true;
        state.commentSubmit.error = false;
      } else {
        state.commentSubmit.success = false;
        state.commentSubmit.error = payload.err;
      }
    });
    builder.addCase(fetchComments.rejected, (state, payload) => {
      state.commentSubmit.isLoading = false;
      state.commentSubmit.success = false;
      state.commentSubmit.error = payload?.err;
    });
    builder.addCase(embed_Post.fulfilled, (state, {payload}) => {});

    builder.addCase(handleImageEdit.pending, (state) => {
      state.imageEdit.success = false;
      state.imageEdit.isLoading = true;
      state.imageEdit.refreshImg = false;
      state.imageEdit.error = false;
    });

    builder.addCase(handleImageEdit.fulfilled, (state, {payload}) => {
      state.imageEdit.success = false;
      state.imageEdit.isLoading = false;
      state.imageEdit.error = false;

      const {res, type, upscale} = payload || {};
      if (!res) {
        state.imageEdit.refreshImg = true;
        return;
      }
      let imgSrc = readBaseUrl + "/" + res.img;
      if (type === "remove-background") {
        state.imageEdit.data["remove-background"] = imgSrc;
      } else if (type === "super-resolution") {
        state.imageEdit.data[upscale] = imgSrc;
      } else if (type === "in-painting") {
        state.imageEdit.data["in-painting"] = imgSrc;
      }
    });

    builder.addCase(handleImageEdit.rejected, (state) => {
      state.imageEdit.success = false;
      state.imageEdit.isLoading = false;
      state.imageEdit.refreshImg = true;
      state.imageEdit.error = true;
    });

    builder.addCase(getTopicData.pending, (state) => {
      state.topicData.isLoading = true;
    });

    builder.addCase(getTopicData.fulfilled, (state, {payload}) => {
      state.topicData.isLoading = false;
      state.topicData.data = payload?.length ? payload : [];
    });

    builder.addCase(getTopicData.rejected, (state) => {
      state.topicData.isLoading = false;
    });
  },
});
