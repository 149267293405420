import {getUserFeed} from "./getUserFeed";
import {getAnonFeed} from "./getAnonFeed";
import {getLiveNowFeed} from "./getLiveNowFeed";
import {toggleLike} from "./toggleLike";
import {getUserPostFeed} from "./getUserPostFeed";
import {getPeopleToFollow} from "./getPeopleToFollow";
import {getTrendingFeed} from "./getTrendingFeed";
import {getModuleSwitch} from "./getModuleSwitch";
import {getPopUserData} from "./getPopUserData";
import {getNewsTopics} from "./getNewsTopics";
// TODO: prevent type widening
export const TimelineApi = {
  getUserFeed,
  getAnonFeed,
  getLiveNowFeed,
  toggleLike,
  getUserPostFeed,
  getPeopleToFollow,
  getTrendingFeed,
  getModuleSwitch,
  getPopUserData,
  getNewsTopics,
};
