import {BaseStyles} from "./BaseStyles";
import {PostComposerStyles} from "./PostComposerStyles";
import {PopupDialogStyles} from "./PopupDialogStyles";

export const GlobalStyles = () => {
  return (
    <>
      <BaseStyles />
      <PostComposerStyles />
      <PopupDialogStyles />
    </>
  );
};
