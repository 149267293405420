import {AnalyticsConf, AnalyticsState, getUserId} from "./AnalyticsUtils";
import {useCallback, useMemo, useRef} from "react";
import AES from "crypto-js/aes";
import {random} from "lodash-es";
import {v4 as uuid} from "uuid";
import {
  differenceInMilliseconds,
  differenceInMinutes,
  parseISO,
} from "date-fns";
import ReactGA from "react-ga4";

import {useUserSession} from "src/util/useUserSession";

export const pageTrackTargetIdTable = {
  following: ["", "TIMELINE"],
  forYou: ["_4ytl", "FOR_YOU_TIMELINE"],
  chill: ["_chillmode", "TIMELINE"],
};

export const intervalsSeconds = (startts, endts) => {
  return Math.abs(
    differenceInMilliseconds(parseISO(endts) || 0, parseISO(startts) || 0),
  );
};

export const showInWindow = (id) => {
  let trackArray = JSON.parse(sessionStorage.getItem("tracking"));
  if (!trackArray) {
    sessionStorage.setItem("tracking", "[]");
    trackArray = JSON.parse(sessionStorage.getItem("tracking"));
  }
  if (!trackArray?.includes(id)) {
    trackArray?.push(id);
    sessionStorage.setItem("tracking", JSON.stringify(trackArray));
    p2fEventTrack(id);
  }
};

export const trackPVEvent = (target_id = "", startts = null, endts = null) => {
  let event = {
    event_id: `${target_id}_${new Date().getTime()}`,
    event_type: AnalyticsConf.impression.event.PAGE_VIEW,
    target_id,
    target_type: AnalyticsConf.impression.target.PAGE,
    location: AnalyticsConf.impression.location.NONE,
    startts,
    endts,
  };
  return event;
};

export const pushTrackPVEvent = (target_id = "", startts, endts) => {
  if (!startts) {
    startts = null;
  }
  if (!endts) {
    endts = null;
  }
  const event = trackPVEvent(target_id, startts, endts);
  if (intervalsSeconds(startts, endts) >= 350) {
    AnalyticsState.pushEvent(event);
  }
};

const pushP2fEvent = (id) => {
  let event = {
    event_id: id,
    event_type: AnalyticsConf.impression.event.IMPRESSION,
    target_id: id,
    target_type: AnalyticsConf.impression.target.USER,
    location: AnalyticsConf.impression.location.PEOPLE2_FOLLOW,
    startts: new Date().toISOString(),
    endts: null,
  };
  AnalyticsState.pushEvent(event);
};

export const p2fEventTrack = (id = null) => {
  if (id) {
    pushP2fEvent(id);
  } else {
    let trackArray = JSON.parse(sessionStorage.getItem("tracking"));
    trackArray?.length &&
      trackArray?.map((r) => {
        pushP2fEvent(r);
      });
  }
};

export const pageReloadSendEventTrack = () => {
  sessionStorage.removeItem("tracking");
  window.addEventListener("pagehide", () => {
    const data = AnalyticsState.getSentList();
    let endts = new Date().toISOString();
    if (data.length > 0) {
      sessionStorage.setItem("eventTrack", JSON.stringify(data));
    } else {
      sessionStorage.removeItem("eventTrack");
    }
    sessionStorage.setItem("pagehideTime", endts);
  });
  const eventTrack = sessionStorage.getItem("eventTrack");
  const pageTrack = sessionStorage.getItem("pageTrack");
  const dialogTrack = sessionStorage.getItem("dialogTrack");
  const pagehideTime = sessionStorage.getItem("pagehideTime");
  let endts = pagehideTime ? pagehideTime : null;
  let eventTrackArr = eventTrack ? JSON.parse(eventTrack) : [];
  if (dialogTrack) {
    let {target_id, startts} = JSON.parse(dialogTrack);
    let event = trackPVEvent(target_id, startts, endts);
    eventTrackArr.push(event);
  }
  if (pageTrack) {
    let {target_id, startts} = JSON.parse(pageTrack);
    let event = trackPVEvent(target_id, startts, endts);
    if (intervalsSeconds(startts, endts) >= 350) {
      // Remove the situation where the homePage auto jumps to the onboarding page
      eventTrackArr.push(event);
    }
  }
  AnalyticsState.client.eventTracking(eventTrackArr);
};

export const multiAccountsLoginSendEventTrack = (userInfo) => {
  const lastAccountTraceId = sessionStorage.getItem("lastAccountTraceId");
  if (lastAccountTraceId && userInfo?._id !== lastAccountTraceId) {
    let event = switchAccountEventTrack(
      2,
      "login_account",
      userInfo?._id,
      "login_account",
    );
    AnalyticsState.client.eventTracking([event]);
    sessionStorage.removeItem("lastAccountTraceId");
  }
};

export const generateCipherText = () => {
  const lower = 6585037763;
  const upper = 6588033769;
  const keyString = "Anounymous_ViSItor";
  const infoObj = {
    ts: new Date().getTime(),
    rn: random(lower, upper - 1),
    s: keyString,
  };
  const cipherText = AES.encrypt(
    JSON.stringify(infoObj),
    "T0pSeCret!",
  ).toString();
  return cipherText;
};

export const generateCipherTextForPasscode = (passcode) => {
  return AES.encrypt(passcode, "GTrWaLLEtcODe!!").toString();
};

export const unauthenticatedEventTrack = (
  step,
  button_name,
  livestreamId = "",
  step_name = "",
) => {
  if ([1, 2, 3].includes(step)) {
    let trace_id = `userLivestream|${Date.now()}|${livestreamId}|${uuid()}`;
    let livestream_id = livestreamId;
    let localStorageTraceId = localStorage.getItem("unauthenticated_trace_id");
    if (localStorageTraceId) {
      trace_id = localStorageTraceId;
      if (step === 3) {
        let trace_id_arr = trace_id.split("|");
        let time = Number(trace_id_arr[1]);
        livestream_id = trace_id_arr[2];
        const interval = differenceInMinutes(new Date(), time);
        localStorage.removeItem("unauthenticated_trace_id");
        if (interval > 60) {
          return;
        }
      }
    } else if ([1, 2].includes(step)) {
      localStorage.setItem("unauthenticated_trace_id", trace_id);
    } else {
      return;
    }
    let eventObj = {
      1: {
        event_id: uuid(),
        event_type: AnalyticsConf.impression.event.NORMAL_CLICK,
        target_id: "button_or_link_id1",
        target_type: AnalyticsConf.impression.target.BUTTON,
        location: "LIVE_VIDEO",
        extra_info: {
          normal_details: {
            button_name,
          },
          thread: {
            trace_id,
            trace_name: "livestream2signup",
            step_no: 1,
            step_name,
          },
        },
        startts: new Date().toISOString(),
        endts: null,
      },
      2: {
        event_id: uuid(),
        event_type: AnalyticsConf.impression.event.NORMAL_CLICK,
        target_id: "button_or_link_id2",
        target_type: AnalyticsConf.impression.target.BUTTON,
        location: "LIVE_VIDEO",
        extra_info: {
          normal_details: {
            button_name,
          },
          thread: {
            trace_id,
            trace_name: "livestream2signup",
            step_no: 2,
            step_name: "link2signup",
            more: {
              livestream_id,
            },
          },
        },
        startts: new Date().toISOString(),
        endts: null,
      },
      3: {
        event_id: uuid(),
        event_type: AnalyticsConf.impression.event.NORMAL_CLICK,
        target_id: "button_or_link_id3",
        target_type: AnalyticsConf.impression.target.BUTTON,
        location: "NONE",
        extra_info: {
          normal_details: {
            button_name: "finished_signup_button_name",
          },
          thread: {
            trace_id,
            trace_name: "livestream2signup",
            step_no: 3,
            step_name: "finish_signup",
            more: {
              livestream_id,
            },
          },
        },
        startts: new Date().toISOString(),
        endts: null,
      },
    };
    AnalyticsState.pushEvent(eventObj[step]);
  }
};

export const switchAccountEventTrack = (
  step,
  button_name,
  userId = "",
  step_name = "",
) => {
  if ([1, 2].includes(step)) {
    const sessionStorageTraceId = sessionStorage.getItem(
      "switchAccountTraceId",
    );
    let trace_id = `userSwitchAccount|${Date.now()}|${userId}|${uuid()}`;
    if (step === 1 || (step === 2 && !sessionStorageTraceId)) {
      sessionStorage.setItem("switchAccountTraceId", trace_id);
    } else {
      trace_id = sessionStorageTraceId;
    }
    const eventObj = {
      event_id: uuid(),
      event_type: AnalyticsConf.impression.event.NORMAL_CLICK,
      target_id: userId,
      target_type: AnalyticsConf.impression.target.USER,
      location: AnalyticsConf.impression.location.USER_PROFILE,
      extra_info: {
        normal_details: {
          button_name,
        },
        thread: {
          trace_id,
          trace_name: "switch_account",
          step_no: step,
          step_name,
        },
      },
      startts: new Date().toISOString(),
      endts: null,
    };
    return eventObj;
  }
};

export const searchEventTrack = (
  target_id,
  target_type,
  search_phrase,
  number_of_search_result,
  location = "SEARCH_RESULT",
) => {
  let eventObj = {
    event_id: uuid(),
    event_type: AnalyticsConf.impression.event.NORMAL_CLICK,
    target_id,
    target_type: AnalyticsConf.impression.target?.[target_type],
    location: AnalyticsConf.impression.location?.[location] ?? location,
    extra_info: {
      search_phrase,
    },
    startts: new Date().toISOString(),
    endts: null,
  };
  Number.isSafeInteger(number_of_search_result) &&
    (eventObj.extra_info["normal_details"] = {
      number_of_search_result,
    });
  AnalyticsState.pushEvent(eventObj);
};

export const uploadGTokEventTrack = (vision_id) => {
  let eventObj = {
    event_id: uuid(),
    event_type: AnalyticsConf.impression.event.NORMAL_CLICK,
    target_id: "post",
    target_type: AnalyticsConf.impression.target.BUTTON,
    location: AnalyticsConf.impression.location.CREATE_VISION,
    extra_info: {},
    startts: new Date().toISOString(),
    endts: null,
  };
  vision_id && (eventObj.extra_info = {normal_details: {vision: vision_id}});
  AnalyticsState.pushEvent(eventObj);
};

export const clickP2fAndPopularUsersEvent = (userId, section, button) => {
  let event = {
    event_id: userId,
    event_type: AnalyticsConf.impression.event.NORMAL_CLICK,
    target_id: userId,
    target_type: AnalyticsConf.impression.target.USER,
    location: AnalyticsConf.impression.location.PEOPLE2_FOLLOW,
    extra_info: {
      normal_details: {section, button},
    },
    startts: new Date().toISOString(),
    endts: null,
  };
  AnalyticsState.pushEvent(event);
};

export const livePlayerEventTrack = (post_id, button, option) => {
  let event = {
    event_id: post_id,
    event_type: AnalyticsConf.impression.event.NORMAL_CLICK,
    target_id: "live_player",
    target_type: AnalyticsConf.impression.target.NONE,
    location: AnalyticsConf.impression.location.LIVE_VIDEO,
    extra_info: {
      normal_details: {post_id, button, option},
    },
    startts: new Date().toISOString(),
    endts: null,
  };
  AnalyticsState.pushEvent(event);
};

export const clickSearchAutoCompletionEventTrack = (
  phrase,
  index,
  keyword,
  keyword_type,
) => {
  let event = {
    event_id: uuid(),
    event_type: AnalyticsConf.impression.event.NORMAL_CLICK,
    target_id: "search_recommend",
    target_type: AnalyticsConf.impression.target.NONE,
    location: AnalyticsConf.impression.location.SEARCH_RESULT,
    extra_info: {
      search_phrase: phrase,
      normal_details: {
        button: "search_recommend_item",
        index,
        keyword,
        keyword_type,
      },
    },
    startts: new Date().toISOString(),
    endts: null,
  };
  AnalyticsState.pushEvent(event);
};

export const watchTimeLivePlayerEventTrack = (
  trace_id,
  post_id,
  button,
  live,
  step_no,
  step_name,
  playing_duration,
  location,
  error,
) => {
  let event = {
    event_id: uuid(),
    event_type: AnalyticsConf.impression.event.NORMAL_CLICK,
    target_id: "live_player",
    target_type: AnalyticsConf.impression.target.NONE,
    location: location
      ? AnalyticsConf.impression.location?.[location]
      : AnalyticsConf.impression.location?.LIVE_VIDEO,
    extra_info: {
      normal_details: {
        post_id,
        button,
      },
      thread: {
        trace_id: `userLivePlayer|${trace_id}`,
        trace_name: "live_watching",
        step_no,
        step_name,
      },
    },
    startts: new Date().toISOString(),
    endts: null,
  };
  [true, false].includes(live) &&
    (event.extra_info.normal_details["live"] = live);
  button === "start_watching" &&
    (event.extra_info.normal_details["error"] = error ? error : null);
  typeof playing_duration === "number" &&
    (event.extra_info.normal_details["playing_duration"] = playing_duration);
  AnalyticsState.pushEvent(event);
};

export const useWatchEventTraker = (post_id, isLive, location) => {
  const {userInfo} = useUserSession();
  const trace_id = useMemo(
    () => `${userInfo?._id}|${Date.now()}|WEB|${uuid()}`,
    [userInfo?._id],
  );
  const stepRef = useRef(0);
  const track = useCallback(
    (evetName, playing_duration, error) => {
      stepRef.current++;
      watchTimeLivePlayerEventTrack(
        trace_id,
        post_id,
        evetName,
        isLive,
        stepRef.current,
        evetName,
        playing_duration,
        location,
        error,
      );
    },
    [post_id, isLive, location, trace_id],
  );
  return track;
};

export const popularHotSearchEventTrack = (type, list, item_name, index) => {
  let eventObj = {
    event_id: uuid(),
    target_type: AnalyticsConf.impression.target.NONE,
    location: AnalyticsConf.impression.location.TRENDING,
    extra_info: {},
    startts: new Date().toISOString(),
    endts: null,
  };
  const item_list_id = "popular_search";
  if (type === "impression") {
    eventObj.event_type = AnalyticsConf.impression.event.IMPRESSION;
    eventObj.target_id = item_list_id;
    eventObj.extra_info = {normal_details: {list}};
    ReactGA.gtag("event", "view_item_list", {
      item_list_id,
      items: list.map((item_name, index) => ({
        item_name,
        index,
      })),
    });
  } else if (type === "click") {
    ReactGA.gtag("event", "select_item", {
      item_list_id,
      items: [
        {
          item_name,
          index: index,
        },
      ],
    });
    eventObj.event_type = AnalyticsConf.impression.event.NORMAL_CLICK;
    eventObj.target_id = item_name;
    eventObj.extra_info = {
      search_phrase: item_name,
      normal_details: {button: "popular_search", list_id: index},
    };
  }
  eventObj && AnalyticsState.pushEvent(eventObj);
};

export const signUpEventTrack = async (
  signup_type,
  step_no,
  error_info = null,
) => {
  if ([1, 2, 3, 4].includes(step_no)) {
    const userId = await getUserId();
    const localStorageTraceId = localStorage.getItem("signup_trace_id");
    const trace_id = `userSignUp|${signup_type}|${userId}|${Date.now()}|WEB|${uuid()}`;
    let traceId = trace_id;
    if (step_no === 1) {
      localStorage.setItem("signup_trace_id", trace_id);
    } else if (localStorageTraceId) {
      traceId = localStorageTraceId;
    }
    const signupType = traceId.split("|")[1];
    const step_name_arr = [
      "sign_up_method",
      "sign_up_info",
      "sign_up_verify",
      "sign_up_password",
    ];
    let event = {
      event_id: uuid(),
      event_type: AnalyticsConf.impression.event.NORMAL_CLICK,
      target_id: "sign_up",
      target_type: AnalyticsConf.impression.target.NONE,
      location: AnalyticsConf.impression.location.NONE,
      extra_info: {
        normal_details: {
          signup_type: signupType,
          error_info,
        },
        thread: {
          trace_id: traceId,
          trace_name: "sign_up_flow",
          step_no,
          step_name: step_name_arr[step_no - 1],
        },
      },
      startts: new Date().toISOString(),
      endts: null,
    };
    step_no === 4 && !error_info && localStorage.removeItem("signup_trace_id");
    AnalyticsState.pushEvent(event);
  }
};

export const themeEventTrack = (theme_selected) => {
  let event = {
    event_id: uuid(),
    event_type: AnalyticsConf.impression.event.NORMAL_CLICK,
    target_id: "theme",
    target_type: AnalyticsConf.impression.target.NONE,
    location: AnalyticsConf.impression.location.NONE,
    extra_info: {
      normal_details: {button: "theme_item", theme_selected},
    },
    startts: new Date().toISOString(),
    endts: null,
  };
  AnalyticsState.pushEvent(event);
};

export const newLiveListTrack = (
  target_id,
  extra_info,
  target_type = "POST",
  location = "LIVE_NOW",
  event_type = "NORMAL_CLICK",
  startts = new Date().toISOString(),
) => {
  let event = {
    event_id: uuid(),
    event_type: AnalyticsConf.impression.event?.[event_type],
    target_id,
    target_type: AnalyticsConf.impression.target?.[target_type],
    location: location
      ? AnalyticsConf.impression.location?.[location]
      : AnalyticsConf.impression.location.LIVE_NOW,
    extra_info,
    startts,
    endts: null,
  };
  // extra_info: {
  //   search_phrase: "keyword in search box" (only requested in search_live)
  //   normal_details: {
  //     act_type: data that backend provide
  //     live_page: livenow | live_following | live_history | search_live
  //     category: all | any selected category
  //     video_list_type: card | list
  //     live_now : y | n (should be `y` if the livestream is live ,or `n`)
  //     number_of_top_host_search_result: xxx (the number of search result, if no result, should be 0)
  //     number_of_host_search_result: xxx (the number of search result, if no result, should be 0)
  //     number_of_live_search_result: xxx (the number of search result, if no result, should be 0)
  //     number_of_replay_search_result: xxx (the number of search result, if no result, should be 0)
  //     filter_upload_date: any_date | today | this_week | this_month | this_year (only requested in `search_live`)
  //     filter_duration: any_type | under_30_minutes | 30_60_minutes | over_60_minutes (only requested in `search_live`)
  //     filter_features: any | live_now | 4k | hd | subtitles_cc (only requested in `search_live`)
  //   }
  // },
  AnalyticsState.pushEvent(event);
};

export const liveListTopCardTrack = (
  isShowArr,
  isActive,
  postId,
  extra_info,
  callback,
) => {
  if (isActive && !isShowArr.includes(postId)) {
    let startts = new Date().toISOString();
    let liveListTrackTime = sessionStorage.getItem("liveListTrackTime");
    if (intervalsSeconds(liveListTrackTime, startts) >= 200) {
      sessionStorage.setItem("liveListTrackTime", startts);
      callback();
      newLiveListTrack(
        postId,
        extra_info,
        "POST",
        "LIVE_NOW",
        "IMPRESSION",
        startts,
      );
    }
  }
};

export const translationEventTrack = (
  target_id,
  target_type,
  location,
  source_lang,
  target_lang,
) => {
  let event = {
    event_id: uuid(),
    event_type: AnalyticsConf.impression.event.NORMAL_CLICK,
    target_id,
    target_type: AnalyticsConf.impression.target?.[target_type],
    location: AnalyticsConf.impression.location?.[location] ?? location,
    extra_info: {
      normal_details: {button_name: "translation", source_lang, target_lang},
    },
    startts: new Date().toISOString(),
    endts: null,
  };
  AnalyticsState.pushEvent(event);
};

export const postCommentErrorEventTrack = (target_type, post_error) => {
  if (
    (post_error?.response?.status && post_error?.response?.status >= 500) ||
    !post_error?.response?.status ||
    post_error?.response?.data?.error?.code === "E_METER_LIMIT_EXCEEDED"
  ) {
    let event = {
      event_id: uuid(),
      event_type: AnalyticsConf.impression.event.NORMAL_CLICK,
      target_id: "none",
      target_type: AnalyticsConf.impression?.target?.[target_type],
      location: AnalyticsConf.impression?.location.NONE,
      extra_info: {
        normal_details: {
          button_name: "post",
          post_error: post_error?.response ? post_error?.response : post_error,
        },
      },
      startts: new Date().toISOString(),
      endts: null,
    };
    AnalyticsState.pushEvent(event);
  }
};

export const emptyDataEventTrack = (location, error_msg) => {
  let event = {
    event_id: uuid(),
    event_type: AnalyticsConf.impression.event.NORMAL_CLICK,
    target_id: "none",
    target_type: AnalyticsConf.impression.target.NONE,
    location: AnalyticsConf.impression.location?.[location] ?? location,
    extra_info: {
      normal_details: {post_empty: "empty_data", error_msg},
    },
    startts: new Date().toISOString(),
    endts: null,
  };
  AnalyticsState.pushEvent(event);
};

export const popularCreatorHoverEventTrack = (location, user_id) => {
  let event = {
    event_id: uuid(),
    event_type: AnalyticsConf.impression.event.NORMAL_CLICK,
    target_id: "popular_creator",
    target_type: AnalyticsConf.impression.target.BUTTON,
    location: AnalyticsConf.impression.location?.[location] ?? location,
    extra_info: {
      normal_details: {user_id},
    },
    startts: new Date().toISOString(),
    endts: null,
  };
  AnalyticsState.pushEvent(event);
};

export const shareFeatureEventTrack = (
  target_id,
  target_type,
  location,
  share_button,
) => {
  let event = {
    event_id: uuid(),
    event_type: AnalyticsConf.impression.event.NORMAL_CLICK,
    target_id,
    target_type: AnalyticsConf.impression.target?.[target_type],
    location: AnalyticsConf.impression.location?.[location] ?? location,
    extra_info: {
      normal_details: {share_button},
    },
    startts: new Date().toISOString(),
    endts: null,
  };
  if (!AnalyticsConf.impression.location?.[location] && target_id) {
    if (target_id.startsWith("p")) {
      event.target_type = AnalyticsConf.impression.target.POST;
    } else if (target_id.startsWith("c")) {
      event.target_type = AnalyticsConf.impression.target.COMMENT;
    }
  }
  AnalyticsState.pushEvent(event);
};

export const chillModeEventTrack = (theme_selected) => {
  let event = {
    event_id: uuid(),
    event_type: AnalyticsConf.impression.event.NORMAL_CLICK,
    target_id: "theme",
    target_type: AnalyticsConf.impression.target.NONE,
    location: AnalyticsConf.impression.location.NONE,
    extra_info: {
      normal_details: {button: "theme_chillmode", theme_selected},
    },
    startts: new Date().toISOString(),
    endts: null,
  };
  AnalyticsState.pushEvent(event);
};

export const dmKeywordsEventTrack = (text) => {
  let keywords_list = text
    .slice(0, 20)
    .replace(/\s+/g, " ")
    .replace(/, | , |, /g, ",")
    .replace(/[\u2E00-\u2E7F\\'!"#$%&()*+\-.\/:;<=>?@\[\]^_`{|}~\s]/g, ",");
  let event = {
    event_id: uuid(),
    event_type: AnalyticsConf.impression.event.MESSAGE,
    target_id: "msg_keywords",
    target_type: AnalyticsConf.impression.target.NONE,
    location: AnalyticsConf.impression.location.MESSAGES,
    extra_info: {
      normal_details: {
        keywords_list,
      },
    },
    startts: new Date().toISOString(),
    endts: null,
  };
  AnalyticsState.pushEvent(event);
};
