import GAxios from "src/util/GAxios";
import {notificationConstants} from "../_constants";

export const getNotifications = (_, {getState}) => {
  const {auth, notifications} = getState() ?? {};
  const {_id} = auth?.session?.userinfo ?? {};
  const {lastSeenTimeStamp, allNotifications, showFromVerifiedUsers} =
    notifications ?? {};
  const {next} = allNotifications ?? {};
  const ifRefresh = next ? null : "true";

  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/u/user/${_id}/query_firebase_history`,
      params: {
        max: notificationConstants.MAX_BATCH_SIZE,
        action: notificationConstants.NOTIFICATIONS_ACTION_PARAM,
        tagUp: next,
        ifRefresh: ifRefresh,
        level: showFromVerifiedUsers ? 1 : 0,
      },
    };
    GAxios(
      config,
      (res) => {
        const {rc, result} = res?.data ?? {};
        const recordList = result?.recordList || [];
        result.recordList = recordList.map((record) => ({
          ...record,
          isSeen: lastSeenTimeStamp > record.cdate,
        }));

        if (rc === "OK") {
          return resolve(result);
        }
      },
      (err) => {
        return reject(err);
      },
    );
  });
};
