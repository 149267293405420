import {TimeUtil} from "src/util/TimeUtil";
import {UPDATE_DATE_TYPES, DURATION_TYPES} from "../_constant";

export const adaptList = (result) => {
  if (!result) return [];
  let responseData = result?.aux?.pinf || result?.aux?.post || {};
  let listData = result?.data?.list || [];
  let userData = result?.aux?.uinf || {};
  let subtitle = result?.aux?.subtitle || {};
  let progress = result?.aux?.progress;
  let data_arr = [];
  listData.forEach((a1) => {
    let pstId = a1.activity?.pstid;
    let userId = a1.activity?.uid || a1.activity?.tgt_oid;
    let post = responseData[pstId];
    let user = userData[userId];
    let subt = subtitle[pstId];
    data_arr.push({
      ...user,
      ...post,
      _id: userId,
      post_id: pstId,
      user_id: userId,
      post_cdate: post?.cdate,
      post_dsc: post?.dsc || "",
      type: post?._t,
      action: a1?.action || a1?.activity?.action,
      subt,
      progress: progress ? progress[pstId] : {},
    });
  });
  return data_arr;
};

export const adaptUpdateDate = (type) => {
  if (type === UPDATE_DATE_TYPES.ANY_DATE) return {};
  const {start, end} = TimeUtil.GetRangeDate(type);
  return {
    cdate_start: start,
    cdate_end: end,
  };
};
export const adaptDuration = (type) => {
  let result = {};
  if (type === DURATION_TYPES.UNDER_30) {
    result.s_len_max = 30;
  } else if (type === DURATION_TYPES.RANGE_30_60) {
    result.s_len_min = 30;
    result.s_len_max = 60;
  } else if (type === DURATION_TYPES.OVER_60) {
    result.s_len_min = 60;
  }
  return result;
};
export const adaptFeatures = ({
  live_now,
  // 4k,
  // hd,
  subtitles_cc,
}) => {
  const result = {};

  if (live_now) {
    result.live_state = true;
  }
  // if (4k) {
  //   result.format = ["4k"];
  // }
  // if (hd) {
  //   result.format = ["720p", "1080p", "2k", ...(result.format || [])];
  // }
  if (subtitles_cc) {
    result.caption = true;
  }
  return {features: result};
};
