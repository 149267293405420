import {
  parseItemStats,
  parseTimelineFeed,
  parseUser,
  timelineTypeTable,
  timelineViews,
} from "src/util/FeedUtils";
import GAxios from "src/util/GAxios";
import {timelineConstants} from "../_constants";
import {emptyDataEventTrack, pageTrackTargetIdTable} from "src/util/trackEvent";

export const getUserFeed = async (type = timelineViews[0], {getState}) => {
  const {auth, timelineNew} = getState() ?? {};
  const {authenticated, userinfo} = auth?.session ?? {};
  const userId = userinfo?._id;
  const {startTs, cursor} = timelineNew?.[timelineTypeTable[type]] ?? {};
  const timeout = 30000;

  let result = null;

  let params = {
    offset: 0,
    max: timelineConstants.MAX_BATCH_SIZE,
    dir: timelineConstants.DIRECTION_FORWARD,
    incl: "posts|stats|userinfo|shared|liked|pvotes",
    startTs: startTs,
    merge: timelineConstants.MERGE_SHARES,
  };

  if (cursor) {
    params = {...params, ...{cursor}};
  }

  if (authenticated && userId) {
    const config = {
      method: "get",
      url:
        type === timelineViews[1]
          ? `${process.env.REACT_APP_API_URL}/u/user/${userId}/timeline`
          : type === timelineViews[2]
          ? `${process.env.REACT_APP_API_URL}/u/user/v2/timeline/chillmode`
          : `${process.env.REACT_APP_API_URL}/u/user/v2/timeline/foryou`,
      params,
      timeout: timeout,
    };

    await GAxios(
      config,
      (res) => {
        if (res) {
          const {aux, data} = res?.data?.result ?? {};
          const {list} = data ?? {};
          const {
            lks: likedPosts,
            removed,
            shrs: sharedPosts,
            cursor,
          } = aux ?? {};
          const users = parseUser(aux);
          let posts = parseTimelineFeed(list, aux);
          let count = posts?.length ?? 0;
          count = count - (posts?.length ?? 0);

          const postStats = parseItemStats(aux);
          if (list?.length === 0 || !list) {
            emptyDataEventTrack(pageTrackTargetIdTable[type][1], res);
          }
          result = {
            type,
            likedPosts,
            posts,
            removed: removed + count,
            users,
            sharedPosts,
            postStats,
            cursor,
          };
        } else {
          emptyDataEventTrack(pageTrackTargetIdTable[type][1], res);
        }
      },
      (err) => {
        result = null;
        emptyDataEventTrack(pageTrackTargetIdTable[type][1], err);
      },
    );

    return result;
  }
};
