import {PayloadAction, createSlice} from "@reduxjs/toolkit";
import {isPlainObject} from "lodash-es";
import {
  commentSubmit,
  deletePostComment,
  fetchComments,
  repostSubmit,
  userSharesComment,
  userSharesPost,
  votePoll,
} from "src/app/components/post/store";
import {
  getAnonFeed,
  getLiveNowFeed,
  getUserFeed,
  getUserPostFeed,
  toggleLike,
} from "src/app/components/timeline/store";
import {parseItemStats} from "src/util/FeedUtils";
import {LegacyPostStats, PostStats} from "./types";
import {
  appendMut,
  appendPollStats,
  concatMut,
  deleteMut,
  updateSingleStats,
  updateStats,
} from "./utils";

/** Set to `true` to enable logging */
const DEBUG = false;

/**
 * Do not read this data directly, use one of the following hooks:
 * - `useIsLiked()`
 * - `useIsShared()`
 * - `usePostStats()`
 * - `usePollStats()`
 */
export interface PostStatsState {
  /** Stats per post id */
  postStats: Record<string, PostStats>;
  /** List of ids of posts liked by the current user */
  likedPosts: string[];
  /** List of ids of posts shared by the current user */
  sharedPosts: string[];
}

const initialState: PostStatsState = {
  postStats: {},
  likedPosts: [],
  sharedPosts: [],
};

const debug = (msg: string, args = {}) =>
  DEBUG &&
  console.log(
    `%c[STATS]: ${msg}`,
    "color: deepskyblue; font-weight: bold",
    args,
  );

/**
 * Represents response from the API
 */
type ExternalPostStatsData = {
  /** Ids of posts liked by current user */
  lks?: string[];
  /** Same as `lks`, used when `lks` is not present */
  likedPosts?: string[];
  /** Ids of posts shared by current user */
  shrs?: string[];
  /** Same as `shrs`, used when `shrs` is not present */
  sharedPosts?: string[];
  /** Rest of response fields */
  [key: string]: any;
};

export const postStatsSlice = createSlice({
  name: "postStats",
  initialState,
  reducers: {
    /**
     * Parses and updates `postStats`, `likedPosts` and `sharedPosts` at once.
     * Use when fetching data outside of redux.
     * Handle it in `extraReducers` whenever possible.
     * This action is preferred over other separate actions in most cases.
     * It takes care of everything and reduces probability of having only partial data.
     */
    setCombinedPostsStats(
      state,
      action: PayloadAction<ExternalPostStatsData | undefined>,
    ) {
      const aux = action.payload;
      const stats = (parseItemStats(aux) as any) || {};
      updateStats(state, stats);
      concatMut(state.likedPosts, aux?.lks || aux?.likedPosts);
      concatMut(state.sharedPosts, aux?.shrs || aux?.sharedPosts);
    },
    /**
     * Update `postStats` for many posts at once.
     * Use only when fetching data outside of redux.
     * Handle it in `extraReducers` whenever possible.
     * @see `setCombinedPostsStats` for more convenient method
     */
    setPostsStats(
      state,
      action: PayloadAction<Record<string, PostStats> | undefined>,
    ) {
      const stats = action.payload;
      debug("update many", {stats});
      updateStats(state, stats || {});
    },
    /**
     * Add posts to the `likedPosts` array.
     * Use only when fetching data outside of redux.
     * Handle it in `extraReducers` whenever possible.
     * @see `setCombinedPostsStats` for more convenient method
     */
    addLikedPosts(state, action: PayloadAction<string[] | undefined>) {
      const likes = action.payload;
      debug("add many likes", {likes});
      concatMut(state.likedPosts, likes);
    },
    /**
     * Add posts to the `sharedPosts` array.
     * Use only when fetching data outside of redux.
     * Handle it in `extraReducers` whenever possible.
     * @see `setCombinedPostsStats` for more convenient method
     */
    addSharedPosts(state, action: PayloadAction<string[] | undefined>) {
      const shares = action.payload;
      debug("add many shares", {shares});
      concatMut(state.sharedPosts, shares);
    },
  },
  extraReducers: (builder) => {
    // Likes

    builder.addCase(toggleLike.pending, (state, {meta}) => {
      const {targetId: postId, likeStatus} = meta.arg;
      debug("opt likes", {postId, likeStatus});
      const diff = likeStatus === "n" ? 1 : -1;
      updateSingleStats(state, postId, (post) => ({
        l: (post?.l || 0) + diff,
      }));
      if (likeStatus === "y") {
        deleteMut(state.likedPosts, postId);
      } else {
        appendMut(state.likedPosts, postId);
      }
    });

    builder.addCase(toggleLike.fulfilled, (state, {meta, payload}) => {
      const {targetId: postId} = meta.arg;
      const {likes} = payload as unknown as {likes: number};
      debug("sync likes", {likes, postId});
      updateSingleStats(state, postId, {l: likes || 0});
    });

    builder.addCase(toggleLike.rejected, (state, {meta}) => {
      const {targetId: postId, likeStatus} = meta.arg;
      debug("rollback likes", {postId, likeStatus});
      const diff = likeStatus === "n" ? -1 : 1;
      updateSingleStats(state, postId, (post) => ({
        l: (post?.l || 0) + diff,
      }));
      if (likeStatus === "y") {
        appendMut(state.likedPosts, postId);
      } else {
        deleteMut(state.likedPosts, postId);
      }
    });

    // Reposts

    builder.addCase(userSharesPost.pending, (state, {meta}) => {
      const {objId: postId, isUnShares} = meta.arg;
      debug("opt shares", {postId, isUnShares});
      const diff = isUnShares ? -1 : 1;
      updateSingleStats(state, postId, (post) => ({
        s: (post?.s || 0) + diff,
      }));
      if (isUnShares) {
        deleteMut(state.sharedPosts, postId);
      } else {
        appendMut(state.sharedPosts, postId);
      }
    });

    builder.addCase(userSharesPost.fulfilled, (state, {payload, meta}) => {
      const {objId: postId, isUnShares} = meta.arg;

      if (payload?.response) {
        const [shareStatus, shares] = payload.response as [
          string,
          number | null,
        ];
        debug("sync shares", {shareStatus, shares, postId});
        updateSingleStats(state, postId, {s: shares || 0});
      }

      if (payload?.err) {
        debug("rollback shares", {postId, isUnShares});
        const diff = isUnShares ? 1 : -1;
        updateSingleStats(state, postId, (post) => ({
          s: (post?.s || 0) + diff,
        }));
        if (isUnShares) {
          appendMut(state.sharedPosts, postId);
        } else {
          deleteMut(state.sharedPosts, postId);
        }
      }
    });

    builder.addCase(userSharesComment.pending, (state, {meta}) => {
      const {objId: postId, isUnShares} = meta.arg;
      debug("opt comment shares", {postId, isUnShares});
      const diff = isUnShares ? -1 : 1;
      updateSingleStats(state, postId, (post) => ({
        s: (post?.s || 0) + diff,
      }));
      if (isUnShares) {
        deleteMut(state.sharedPosts, postId);
      } else {
        appendMut(state.sharedPosts, postId);
      }
    });

    builder.addCase(userSharesComment.fulfilled, (state, {payload, meta}) => {
      const {objId: postId, isUnShares} = meta.arg;

      if (payload?.response) {
        const [shareStatus, shares] = payload.response as [
          string,
          number | null,
        ];
        debug("sync comment shares", {shareStatus, shares, postId});
        updateSingleStats(state, postId, {s: shares || 0});
      }

      if (payload?.err) {
        debug("rollback comment shares", {postId, isUnShares});
        const diff = isUnShares ? 1 : -1;
        updateSingleStats(state, postId, (post) => ({
          s: (post?.s || 0) + diff,
        }));
        if (isUnShares) {
          appendMut(state.sharedPosts, postId);
        } else {
          deleteMut(state.sharedPosts, postId);
        }
      }
    });

    builder.addCase(repostSubmit.pending, (state, {meta}) => {
      const [postId] = meta.arg.data.rpstIds;
      debug("opt repost submit", {postId});
      updateSingleStats(state, postId, (post) => ({
        s: (post?.s || 0) + 1,
      }));
      // This method does not affect `sharedPosts`
    });

    builder.addCase(repostSubmit.fulfilled, (state, {meta, payload}) => {
      if (payload?.err) {
        const [postId] = meta.arg.data.rpstIds;
        debug("rollback repost submit", {postId});
        updateSingleStats(state, postId, (post) => ({
          s: (post?.s || 0) - 1,
        }));
        // This method does not affect `sharedPosts`
      }
    });

    // Comments

    builder.addCase(commentSubmit.pending, (state, {meta}) => {
      const postId = meta.arg.pid;
      debug("opt comments", {postId});
      updateSingleStats(state, postId, (post) => ({
        c: (post?.c || 0) + 1,
      }));
    });

    builder.addCase(commentSubmit.fulfilled, (state, {meta, payload}) => {
      const postId = meta.arg.pid;
      if (payload?.err) {
        debug("rollback comments", {postId});
        updateSingleStats(state, postId, (post) => ({
          c: (post?.c || 0) - 1,
        }));
      }
    });

    builder.addCase(deletePostComment.pending, (state, {meta}) => {
      const {parentId: postId} = meta.arg;
      if (postId) {
        debug("opt delete comment", {postId});
        updateSingleStats(state, postId, (post) => ({
          c: (post?.c || 0) - 1,
        }));
      }
    });

    builder.addCase(deletePostComment.fulfilled, (state, {meta, payload}) => {
      const {parentId: postId} = meta.arg;
      if (postId && payload?.err) {
        debug("rollback delete comment", {postId});
        updateSingleStats(state, postId, (post) => ({
          c: (post?.c || 0) + 1,
        }));
      }
    });

    // Polls

    builder.addCase(votePoll.pending, (state, {meta}) => {
      const {postId, selections: pv} = meta.arg.content;
      debug("opt poll", {pv, postId});
      const [key] = pv;
      updateSingleStats(state, postId, (post) => ({
        pv,
        ps: {...post?.ps, [key]: (post?.ps?.[key] || 0) + 1},
      }));
    });

    builder.addCase(votePoll.fulfilled, (state, {meta, payload}) => {
      if (!payload?.response) {
        const {postId, selections: pv} = meta.arg.content;
        debug("rollback poll", {pv, postId});
        const [key] = pv;
        updateSingleStats(state, postId, (post) => ({
          pv: [],
          ps: {...post?.ps, [key]: post?.ps?.[key] ? post.ps[key] - 1 : 0},
        }));
      }
    });

    // Data loading

    builder.addCase(fetchComments.fulfilled, (state, action) => {
      const aux = (action.payload as any).aux;
      const {postId} = action.meta.arg as any;
      debug("load from comments", {aux});
      if (isPlainObject(aux.s_cmst)) {
        const postStats = parseItemStats(aux) as Record<
          string,
          LegacyPostStats
        >;
        // Delete parent post's stats as they are incomplete to prevent data loss
        delete postStats[postId];
        updateStats(state, postStats);
      }
      if (Array.isArray(aux.lks)) {
        concatMut(state.likedPosts, aux.lks);
      }
      if (Array.isArray(aux.shrs)) {
        concatMut(state.sharedPosts, aux.shrs);
      }
    });

    builder.addMatcher(
      (action) =>
        [
          getUserFeed.fulfilled.type,
          getLiveNowFeed.fulfilled.type, // Is this even used anywhere?
          getAnonFeed.fulfilled.type,
          getUserPostFeed.fulfilled.type,
        ].includes(action.type),
      (state, action) => {
        debug("load from feed", {type: action.type});
        const payload = action.payload as
          | {
              postStats: Record<string, LegacyPostStats>;
              posts: {id: string; poll?: {stats: Record<number, number>}}[];
              likedPosts?: string[];
              sharedPosts?: string[];
            }
          | undefined;
        const postStats = payload?.postStats;
        const posts = payload?.posts;
        if (posts && postStats) {
          updateStats(state, appendPollStats(posts, postStats));
        }
        concatMut(state.likedPosts, payload?.likedPosts);
        concatMut(state.sharedPosts, payload?.sharedPosts);
      },
    );
  },
});

export const {
  setCombinedPostsStats,
  setPostsStats,
  addLikedPosts,
  addSharedPosts,
} = postStatsSlice.actions;
