import {createSlice, createAction, createAsyncThunk} from "@reduxjs/toolkit";
import {trim, uniqBy, isNumber, filter} from "lodash-es";
import {LiveListHistoryApi} from "../api";
import {
  MAX_LIST_ITEMS,
  SUGGESTION_MODES,
  SUGGESTION_TYPES,
  NEW_LOCAL_STORAGE_HISTORY_RECENT_SEARCH,
} from "../_constant";

const NS = "liveListHistory";
let initRecentSearchList;

try {
  initRecentSearchList =
    JSON.parse(localStorage.getItem(NEW_LOCAL_STORAGE_HISTORY_RECENT_SEARCH)) ||
    [];
} catch (error) {
  initRecentSearchList = [];
}
const initialState = {
  selectedIndex: -1,
  currentSuggestionMode: SUGGESTION_MODES.RECENT,
  selectedSuggestion: null,
  recentSearchList: initRecentSearchList,
  history: {
    list: [],
    cursor: "",
  },
  searchHistory: {
    list: [],
    cursor: "",
  },
  loading: false,
  currentHistory: [],
  currentPhrase: "",
  isRateExceeded: false,
  newList: [],
  batchProgress: {},
};

export const fetchHistory = createAsyncThunk(
  "liveListHistory/fetchHistory",
  LiveListHistoryApi.fetchHistory,
);
export const fetchSearchHistory = createAsyncThunk(
  "liveListHistory/fetchSearchHistory",
  LiveListHistoryApi.fetchSearchHistory,
);
export const fetchBatchProgress = createAsyncThunk(
  "liveListHistory/fetchBatchProgress",
  LiveListHistoryApi.fetchBatchProgress,
);

export const setSelectedIndex = createAction(`${NS}/setSelectedIndex`);
export const resetSearch = createAction(`${NS}/resetSearch`);
export const setRecentSearchList = createAction(`${NS}/setRecentSearchList`);
export const resetRecentSearchList = createAction(
  `${NS}/resetRecentSearchList`,
);
export const deleteHistoryByFrontEnd = createAction(
  `${NS}/deleteHistoryByFrontEnd`,
);
export const clearHistoryByFrontEnd = createAction(
  `${NS}/clearHistoryByFrontEnd`,
);

export const liveListHistorySlice = createSlice({
  name: NS,
  initialState,
  reducers: {
    setSelectedIndex: (state, {payload}) => {
      let list =
        state.currentSuggestionMode === SUGGESTION_MODES.RECENT
          ? state.recentSearchList
          : [...state.suggestion, ...state.hashtagList, ...state.hostsList];

      if (
        state.currentSuggestionMode === SUGGESTION_MODES.RESULT &&
        list?.length
      )
        list.push({
          id: list?.length,
          type: SUGGESTION_TYPES.ALL,
        });

      const maxIndex = list?.length - 1;

      if (maxIndex < 0) {
        state.selectedIndex = initialState.selectedIndex;
      } else {
        const nextIndex = isNumber(payload?.id)
          ? payload.id
          : state.selectedIndex + payload;
        if (nextIndex < 0) state.selectedIndex = maxIndex;
        if (nextIndex > maxIndex) state.selectedIndex = 0;
        if (nextIndex >= 0 && nextIndex <= maxIndex)
          state.selectedIndex = nextIndex;
      }
      const selectedItem = list?.[state.selectedIndex] || null;

      if (selectedItem) {
        state.selectedSuggestion = {
          type: selectedItem?.type,
          phrase: selectedItem?.phrase,
        };
      } else {
        state.selectedSuggestion = initialState.selectedSuggestion;
        state.selectedIndex = initialState.selectedIndex;
      }
    },
    resetSearch: (state) => {
      state.selectedSuggestion = initialState.selectedSuggestion;
      state.currentHistory = [...state.history.list];
      state.currentPhrase = "";
    },
    setRecentSearchList: (state, {payload}) => {
      const phrase = trim(payload);
      state.currentPhrase = phrase;

      if (!phrase) {
        state.currentHistory = [...state.history.list];
      } else {
        const list = [
          {
            phrase,
            date: new Date().toISOString(),
          },
          ...state.recentSearchList,
        ]?.filter((item) => !!item);

        const uniqList = (
          uniqBy(list, "phrase")?.slice(0, MAX_LIST_ITEMS) || []
        )?.map((item, index) => ({
          ...item,
          id: index,
        }));

        state.recentSearchList = uniqList;

        localStorage.setItem(
          NEW_LOCAL_STORAGE_HISTORY_RECENT_SEARCH,
          JSON.stringify(uniqList),
        );
      }
    },
    resetRecentSearchList: (state) => {
      state.recentSearchList = [];
      localStorage.setItem(NEW_LOCAL_STORAGE_HISTORY_RECENT_SEARCH, null);
      state.selectedIndex = initialState.selectedIndex;
      state.selectedSuggestion = initialState.selectedSuggestion;
    },
    deleteHistoryByFrontEnd: (state, {payload}) => {
      const newCurrentHistory = [...state.currentHistory].filter((item) => {
        return item?.post_id !== payload;
      });
      const newHistoryList = [...state.history.list].filter((item) => {
        return item?.post_id !== payload;
      });
      state.currentHistory = newCurrentHistory;
      state.history.list = newHistoryList;
    },
    clearHistoryByFrontEnd: (state) => {
      state.currentHistory = [];
      state.history.list = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchHistory.pending, (state, {payload}) => {
      state.loading = true;
    });
    builder.addCase(fetchHistory.fulfilled, (state, {payload}) => {
      const {list, cursor, reset, checkNew} = payload;
      state.loading = false;
      state.isRateExceeded =
        state.isRateExceeded || payload?.response?.status === 429;
      if (!list) return;
      state.history.list = checkNew
        ? uniqBy([...list, ...state.history.list], "post_id")
        : reset
        ? list
        : uniqBy([...state.history.list, ...list], "post_id");
      state.history.cursor =
        checkNew && state.history.cursor ? state.history.cursor : cursor;
      state.newList = list;
      if (!state.currentPhrase) {
        state.currentHistory = [...state.history.list];
      }
    });
    builder.addCase(fetchHistory.rejected, (state, {payload}) => {
      state.history = {...initialState.history};
      state.loading = false;
    });
    builder.addCase(fetchSearchHistory.pending, (state, {payload}) => {
      state.loading = true;
    });
    builder.addCase(fetchSearchHistory.fulfilled, (state, {payload}) => {
      const {list, cursor, reset, phrase, checkNew} = payload;
      state.loading = false;
      state.isRateExceeded =
        state.isRateExceeded || payload?.response?.status === 429;
      if (!list) return;
      state.searchHistory.list = checkNew
        ? uniqBy([...list, ...state.searchHistory.list], "post_id")
        : reset
        ? list
        : uniqBy([...state.searchHistory.list, ...list], "post_id");
      state.searchHistory.cursor =
        checkNew && state.searchHistory.cursor
          ? state.searchHistory.cursor
          : cursor
          ? {[`p_${phrase}`]: cursor}
          : null;
      state.newList = list;
      if (state.currentPhrase) {
        state.currentHistory = [...state.searchHistory.list];
      }
    });
    builder.addCase(fetchSearchHistory.rejected, (state, {payload}) => {
      state.searchHistory = {...initialState.searchHistory};
      state.loading = false;
    });
    builder.addCase(fetchBatchProgress.pending, (state, {payload}) => {
      state.loading = true;
    });
    builder.addCase(fetchBatchProgress.fulfilled, (state, {payload = {}}) => {
      state.batchProgress = {...state.batchProgress, ...payload};
      state.loading = false;
    });
    builder.addCase(fetchBatchProgress.rejected, (state, {payload}) => {
      state.batchProgress = {...initialState.batchProgress};
      state.loading = false;
    });
  },
});
