import GAxios from "src/util/GAxios";

export const fetchUse = async (data) => {
  const config = {
    method: "post",
    url: process.env.REACT_APP_API_URL + `/v1/emojis/use`,
    data: {
      content: {
        packet_id: data.packet_id,
        emoji_id: data.emoji_id,
      },
    },
  };

  let res = {err: null, response: null};

  await GAxios(
    config,
    (response) => {
      if (response) {
        res.response = {...response.data.result, usedData: data};
      } else {
        res.err = true;
      }
    },
    (err) => {
      res.err = true;
    },
  );

  return res;
};
