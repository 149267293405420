import GAxios from "src/util/GAxios";
import {userAccessStatus, handleStatusLoader} from "src/store/modules/status";
import {mutatesuggestUser} from "src/app/components/gtok/store";
import {mutateChatUser} from "src/app/components/chat/store";
import {getStore} from "src/store";
import {
  setUserAdsBlackGadObj,
  setUserInfo,
} from "src/app/components/auth/store";

export const fetchUserInfo = async ({userId, incl}) => {
  if (!userId) return;
  const store = await getStore();
  store.dispatch(handleStatusLoader(true));
  let accessIncl = "";
  if (incl) accessIncl = "?incl=" + incl;
  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/s/uinf/${userId}${accessIncl}`,
  };

  let res = false;

  await GAxios(
    config,
    (response) => {
      if (response?.data?.result?.data) {
        res = response.data.result.data;
        store.dispatch(
          userAccessStatus({userId, status: response.data.result?.aux}),
        );
        store.dispatch(mutateChatUser(res));
        store.dispatch(mutatesuggestUser(res));
      }
      if (incl && incl.includes("blacklistgad")) {
        store.dispatch(
          setUserAdsBlackGadObj({
            userId,
            blacklistgad: response?.data?.result?.aux?.blacklistgad ?? false,
          }),
        );
      }
    },
    (err) => {
      let errorData = err?.response?.data;
      if (errorData?.error?.code === "E_NOT_ALLOWED") {
        res = "blocked";
      } else {
        if (errorData?.error?.code === "E_USER_NOTFOUND") {
          res = "account-doesnt-exist";
        } else if (errorData?.error?.code === "E_USER_BLOCKED") {
          res = "blocked";
        } else {
          res = "network-request-failed";
        }
      }
    },
  );

  return res;
};
