import {postConstants} from "../_constants";
import GAxios from "src/util/GAxios";

export const editPostSubmit = async (data) => {
  if (!data) return;

  const config = {
    method: "post",
    url: postConstants.SUBMIT_EDIT_POST_URL,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      content: {
        ...data,
      },
    },
  };

  let res = {err: null, response: null};

  await GAxios(
    config,
    (response) => {
      if (response.data.result) {
        res.response = response.data.result;
      } else {
        res.err = true;
      }
    },
    (err) => {
      res.err = err;
    },
  );

  return res;
};
