import {createSlice, createAsyncThunk, createAction} from "@reduxjs/toolkit";
import {GettrPayApi} from "src/store/modules/pay/api";
import {fetchTipStickersDefault} from "../api";
import {tippingErrorConsts} from "../constants";

const NS = "tipping";

const initialState = {
  tippingPopup: {
    loading: false,
    tab: 0,
    open: false,
    originalUser: {},
    embeddedPost: {},
    item: {},
    receiveResponse: {},
    customAmount: "",
    isSenderAllowed: false,
  },
  default: {
    list: [],
    loading: false,
  },
};
export const TIPPING_STICKER_BASE_URL = process.env.REACT_APP_STICKER_API_CDN
  ? process.env.REACT_APP_STICKER_API_CDN + `/tippings/v1`
  : "https://stickers.gettr.com/tippings/v1";
export const receiveTipData = createAsyncThunk(
  "post/getTipData",
  GettrPayApi.getTipData,
);

export const updateTippingPopupState = createAction(
  `${NS}/updateTippingPopupState`,
);
export const handleTippingPopupClose = createAction(
  `${NS}/handleTippingPopupClose`,
);
export const handleTippingPopupOpen = createAction(
  `${NS}/handleTippingPopupOpen`,
);
export const fetchTipStickers = createAsyncThunk(
  `${NS}/fetchTipStickers`,
  async () => {
    const result = await fetchTipStickersDefault();
    const responseData = result?.response?.packets[0];
    const _list = {
      categoryFullName: responseData.name.value,
      packetId: responseData.packet_id,
      type: responseData.type,
      list: responseData.tipping_stickers.map((sticker) => {
        return {
          ariaLabel: "",
          image: {
            pc: `${TIPPING_STICKER_BASE_URL}/${sticker.packet_id}/${sticker.tipping_sticker_id}/main_pc.webp`.toLowerCase(),
            mobile:
              `${TIPPING_STICKER_BASE_URL}/${sticker.packet_id}/${sticker.tipping_sticker_id}/main_mobile.webp`.toLowerCase(),
            thumb:
              `${TIPPING_STICKER_BASE_URL}/${sticker.packet_id}/${sticker.tipping_sticker_id}/thumbnail_mobile.webp`.toLowerCase(),
          },
          icon: null,
          title: "",
          type: "",
          height: sticker.height,
          width: sticker.width,
          packet_id: sticker.packet_id,
          tipping_sticker_id: sticker.tipping_sticker_id,
          price: sticker.price[0],
          name: sticker?.name?.value,
        };
      }),
    };

    return {
      list: _list,
    };
  },
);

export const tippingSlice = createSlice({
  name: NS,
  initialState,
  reducers: {
    updateTippingPopupState(state, action) {
      const {value, key} = action.payload;
      state.tippingPopup[key] = value;
    },
    handleTippingPopupOpen(state) {
      state.tippingPopup.open = true;
    },
    handleTippingPopupClose(state) {
      state.tippingPopup.open = false;
      state.tippingPopup.receiveResponse = {};
      state.tippingPopup.customAmount = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(receiveTipData.fulfilled, (state, {payload}) => {
      state.tippingPopup.loading = false;
      state.tippingPopup.receiveResponse =
        payload === tippingErrorConsts.RECEIVER_PEP
          ? {message: tippingErrorConsts.RECEIVER_PEP}
          : payload;
    });
    builder.addCase(fetchTipStickers.pending, (state, {payload}) => {
      state.default.loading = true;
    });
    builder.addCase(fetchTipStickers.fulfilled, (state, {payload}) => {
      state.default.list = payload.list;
      state.default.loading = false;
    });
    builder.addCase(fetchTipStickers.rejected, (state, {payload}) => {
      state.default.list = [];
      state.default.loading = false;
    });
  },
});
