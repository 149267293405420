import GAxios from "src/util/GAxios";

export const setEndorsement = async (data) => {
  const {userId, successCallback} = data;
  if (!userId) return;

  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/gid/endorsement/${userId}`,
    data: {
      content: {},
    },
  };

  let res = null;

  await GAxios(
    config,
    (response) => {
      res = response?.data?.result || null;
      successCallback && successCallback(res);
    },
    (err) => {
      res = null;
      console.log(err?.response?.data?.error?.code);
    },
  );

  return res;
};
