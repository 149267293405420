import GAxios from "src/util/GAxios";
import {userConstants} from "../_constants";
import {showToast} from "../../../../util/showToast";
import {t} from "src/i18n/utils";

export const sendVerifCode = async (data) => {
  const {token, email, errorCallback} = data ?? {};
  if (!email) return;
  const content = JSON.stringify({
    content: {
      token: token,
      email: email?.toLowerCase(),
      lang: "en", // [TODO]: will be replace when the current lang will be available in the Redux Store.
      reqtype: userConstants.SEND_VERIF_CODE_REQ_TYPE,
    },
  });

  const config = {
    method: "post",
    url: userConstants.API_SEND_VERIF_CODE_EMAIL,
    headers: {
      "Content-Type": "application/json",
    },
    data: content,
  };

  let res = null;

  await GAxios(
    config,
    (response) => {
      if (response && response.data?.result?.success) {
        res = email;
      } else {
        res = null;
      }
    },
    (err) => {
      let errorCode = err?.response?.data?.error?.code;
      if (errorCode === "E_EMAIL_PROVIDER_BLACKLISTED") {
        showToast(
          "warning",
          t("getter_fe.auth.errors.E_EMAIL_PROVIDER_BLACKLISTED"),
        );
        res = "alreadyShowToast";
      } else {
        res = null;
      }
      if (errorCallback) errorCallback(err);
    },
  );
  return res;
};
