import {first} from "lodash-es";
import {formatInTimeZone} from "date-fns-tz";

const LIKE_POST = "likes_pst";
const SHARES_POST = "shares_pst";
const SHARES_COMMENT = "shares_cm";
const OTHER_SHARES_COMMENT = "cm_sharedby";
const NOTIF_SHARES_COMMENT = "c";
const OTHER_SHARES_POST = "pst_sharedby";

export const MediaTypeEnum = Object.freeze({
  IMAGE: "IMAGE",
  VIDEO: "VIDEO",
  TEXT_ONLY: "TEXT_ONLY",
  UNKNOWN: "UNKNOWN",
});

export const timelineViews = ["forYou", "following", "chill"];
export const timelineTypeTable = {
  chill: "chillMode",
  following: "userFeed",
  forYou: "forYouUserFeed",
};
export const anonTimelineViews = ["explore", "chill"];
export const anonTimelineTypeTable = {
  trendingchill: "chillMode",
  trending: "anonFeed",
};

export const newPostStats = (postData, statsData) => {
  const newLikes = Number(postData?.lkbpst ?? postData?.lkbcm) || 0;
  const newComments = Number(postData?.cm);
  const newReposts = Number(postData?.shbpst ?? postData?.shbcm) || 0;

  const likes =
    newLikes > 0
      ? newLikes
      : Number(statsData?.lkbpst ?? statsData?.lkbcm) || 0;
  const comments = newComments > 0 ? newComments : Number(statsData?.cm);

  const reposts =
    newReposts > 0
      ? newReposts
      : Number(statsData?.shbpst ?? statsData?.shbcm) || 0;

  return {likes, comments, reposts};
};

export const parsePostFeed = (list, aux) => {
  if (!list?.length || !aux?.post || !aux?.s_pst) {
    return null;
  }

  const ids = [];
  return list
    .map((item) => {
      const auxData = aux.post[item.activity?.tgt_id];
      const statsData = aux.s_pst[item.activity?.tgt_id];
      const embedPost = aux.post[item.activity?.rpstIds];

      if (!auxData) {
        return null;
      }

      const {likes, comments, reposts} = newPostStats(auxData, statsData);

      let data = {
        rich_txt: auxData.rich_txt,
        ...(auxData.rich_txt ? {} : {txt: auxData.txt}),
        sticker_ids: auxData.sticker_ids,
        stickers: auxData.stickers,
        cdate: auxData.cdate,
        action: item.activity?.action,
        type: item.activity?.tgt_type,
        id: item.activity?.tgt_id,
        sid: auxData.sid,
        uid: parseUserID(item),
        originUser:
          item.activity?.action === SHARES_COMMENT
            ? item.activity?.tgt_oid
            : item.activity?.opuid ?? auxData.puid,
        replyingTo: item.activity?.opuid ?? auxData.puid,
        embedPost,
        embedUser: item.activity?.rusrIds,
        txt_lang: auxData.txt_lang,
        ttl: auxData.ttl,
        previmg: auxData.previmg,
        prevsrc: auxData.prevsrc,
        dsc: auxData.dsc,
        lv_categoryIds: auxData.lv_categoryIds,
        lv_lang: auxData.lv_lang,
        lv_location: auxData.lv_location,
        tags: auxData.htgs,
        mentions: auxData.utgs,
        imgs: auxData.imgs,
        vid: auxData.vid,
        ovid: auxData.ovid,
        pvid: auxData.pvid,
        nmvid: auxData.nmvid,
        main: auxData.main,
        vid_dur: auxData.vid_dur,
        vid_hgt: auxData.vid_hgt,
        vid_wid: auxData.vid_wid,
        meta: auxData.meta,
        p_type: auxData.p_type,
        sound_ids: auxData.sound_ids,
        likes,
        comments,
        reposts,
        vfpst: auxData?.vfpst ?? 0,
        poll: auxData?.poll,
        pvotes: auxData?.pvotes,
        premium: auxData?.premium,
        badges: auxData?.badges,
        txt_hist: auxData?.txt_hist,
        ma_vid: auxData?.ma_vid,
        ma_cid: auxData?.ma_cid,
        ma_sslg: auxData?.ma_sslg,
        ma_cslg: auxData?.ma_cslg,
      };
      if (data.type === "cmt") {
        delete data.embedPost;
        data.originPostId = item.activity?.opid;
      }

      Object.keys(data).forEach((k) => {
        if (data[k] == null) {
          delete data[k];
        }
      });

      //remove the duplicate post.
      if (
        ids.includes(data.id) &&
        data.action !== SHARES_POST &&
        data.action !== OTHER_SHARES_POST
      ) {
        return null;
      } else {
        ids.push(data.id);
        return data;
      }
    })
    .filter((item) => item);
};

export const parseUser = (aux) => {
  if (!aux?.uinf || !Object.keys(aux?.uinf)?.length) {
    return null;
  }

  let userSet = {};

  Object.keys(aux?.uinf).forEach((user) => {
    const userData = aux.uinf[user];

    let data = {
      cdate: userData?.cdate,
      id: userData?._id,
      nickname: userData?.nickname,
      username: userData?.username,
      ousername: userData?.ousername,
      premium: userData?.premium,
      badges: userData?.badges,
      dsc: userData?.dsc,
      status: userData?.status,
      pin: userData?.pinpsts,
      lang: userData?.lang,
      infl: userData?.infl,
      ico: userData?.ico,
      bgimg: userData?.bgimg,
      location: userData?.location,
      birthdate: userData?.birthdate,
      flw: userData?.flw,
      flg: userData?.flg,
      pvotes: aux?.pvotes,
    };

    Object.keys(data).forEach((k) => {
      if (data[k] == null) {
        delete data[k];
      }
    });

    if (userData?._id) {
      userSet[userData._id] = data;
    }
  });

  return userSet;
};

export const getUserName = (user) => {
  return user?.ousername || user?.username || user?.id || user?._id;
};
/**
 *
 * @param {*} user
 * @returns
 */
export const getDisplayName = (user) => {
  return user?.nickname ? user.nickname : getUserName(user);
};

export const parsePinnedPostID = (userInfo, returnAll) => {
  try {
    const ID = JSON.parse(userInfo?.pinpsts);
    return Array.isArray(ID) ? (returnAll ? ID.join(",") : ID[0]) : null;
  } catch (err) {
    return null;
  }
};

export const parsePost = (post, aux) => {
  const {
    rpstIds: embedId = null,
    rusrIds: embedUser = null,
    acl,
    udate,
    _id: id,
    rich_txt,
    ...rest
  } = post ?? {};
  const {cm: comments, lkbpst: likes, shbpst: reposts} = aux?.s_pst ?? {};
  const {shrdpst: embeddedPost = null} = aux ?? {};

  const data = {
    comments,
    id,
    likes,
    reposts,
    embedId,
    embedUser,
    embeddedPost,
    rich_txt,
    ...rest,
  };

  Object.keys(data).forEach((k) => {
    if (data[k] === null || data[k] === undefined) {
      delete data[k];
    }
  });

  return data;
};

export const parseTimelineFeed = (list, aux, type = "") => {
  const post = aux?.pinf || aux?.post;
  if (!list?.length || !aux?.post || !aux?.s_pst || !post) {
    return null;
  }
  const ids = [];
  const parsedFeed = list.map((item) => {
    const auxData = post[item.activity?.pstid];
    const statsData = aux?.s_pst[item.activity?.pstid];
    const embedPost = post[item.activity?.rpstIds];
    const pvotes = aux?.pvotes ? aux?.pvotes[item.activity?.pstid] : [];
    if (!auxData) {
      return null;
    }

    const data = {
      rich_txt: auxData.rich_txt,
      ...(auxData.rich_txt ? {} : {txt: auxData.txt}),
      impressionType: type,
      cdate: auxData.cdate,
      // item.activity?.action === SHARES_POST ||
      // item.activity?.action === SHARES_COMMENT ||
      // item.activity?.action === OTHER_SHARES_COMMENT ||
      // item.activity?.action === OTHER_SHARES_POST
      //   ? auxData.cdate
      //   : item.cdate,
      action: item.activity?.action,
      type: item.activity?.tgt_type,
      id: item.activity?.pstid,
      sid: auxData.sid,
      uid: parseUserID(item, "timeline"),
      originUser: parseOriginUser(item, auxData),
      replyingTo: item.activity?.opuid ?? auxData.puid,
      embedPost,
      embedUser: item.activity?.rusrIds,
      txt_lang: auxData.txt_lang,
      ttl: auxData.ttl,
      previmg: auxData.previmg,
      prevsrc: auxData.prevsrc,
      dsc: auxData.dsc,
      lv_categoryIds: auxData.lv_categoryIds,
      lv_lang: auxData.lv_lang,
      tags: auxData.htgs,
      mentions: auxData.utgs,
      imgs: auxData.imgs,
      vid: auxData.vid,
      ovid: auxData.ovid,
      pvid: auxData.pvid,
      nmvid: auxData.nmvid,
      main: auxData.main || first(auxData.imgs),
      vid_dur: auxData.vid_dur,
      vid_hgt: auxData.vid_hgt,
      vid_wid: auxData.vid_wid,
      meta: auxData.meta,
      p_type: auxData.p_type,
      likes: Number(statsData?.lkbpst ?? statsData?.lkbcm) || 0,
      comments: statsData?.cm,
      reposts: Number(statsData?.shbpst ?? statsData?.shbcm) || 0,
      key: item.activity?._id,
      txt_hist: auxData.txt_hist,
      poll: auxData.poll,
      pvotes,
      ma_vid: auxData.ma_vid,
      ma_cid: auxData.ma_cid,
      ma_sslg: auxData.ma_sslg,
      ma_cslg: auxData.ma_cslg,
      promoteType: auxData.promoteType,
      promoteHistory: auxData.promoteHistory,
      promoteStartDate: auxData.promoteStartDate,
      promoteEndDate: auxData.promoteEndDate,
      stickers: auxData.stickers,
    };

    Object.keys(data).forEach((k) => {
      if (data[k] === null || data[k] === undefined) {
        delete data[k];
      }
    });

    if (
      data.action === SHARES_COMMENT ||
      data.action === OTHER_SHARES_COMMENT
    ) {
      // can't have embed on comment
      delete data.embedPost;
      delete data.embedUser;
    }

    //remove the duplicate post.
    if (
      ids.includes(data.id) &&
      data.action !== SHARES_POST &&
      data.action !== OTHER_SHARES_POST
    ) {
      return null;
    } else {
      ids.push(data.id);
      return data;
    }
  });

  return parsedFeed
    ?.map((item) => {
      if (item?.action === OTHER_SHARES_POST) {
        const sharesPost = parsedFeed?.find(
          (feedItem) =>
            feedItem?.action === SHARES_POST && feedItem?.id === item?.id,
        );
        if (sharesPost) {
          const uids =
            item?.uid && typeof item.uid === "string" ? [item.uid] : item.uid;
          item.uid = [sharesPost?.uid, ...(uids ?? [])];
        }
      }
      if (item?.action === SHARES_POST) {
        const otherSharesPost = parsedFeed?.find(
          (feedItem) =>
            feedItem?.action === OTHER_SHARES_POST && feedItem?.id === item?.id,
        );
        if (otherSharesPost) {
          return null;
        }
      }
      return item;
    })
    ?.filter((item) => !!item);
};

export const parseGTokFeed = (list, aux) => {
  if (!list?.length || !aux?.pinf || !Object.keys(aux?.pinf)?.length) {
    return null;
  }
  const ids = [];
  const parsedFeed = list.map((item) => {
    const auxData = aux.pinf[item.activity?.pstid];
    const embedPost = aux.pinf[item.activity?.rpstIds];

    if (!auxData) {
      return null;
    }

    const data = {
      cdate: auxData.cdate,
      // item.activity?.action === SHARES_POST ||
      // item.activity?.action === SHARES_COMMENT ||
      // item.activity?.action === OTHER_SHARES_COMMENT ||
      // item.activity?.action === OTHER_SHARES_POST
      //   ? auxData.cdate
      //   : item.cdate,
      action: item.activity?.action,
      type: item.activity?.tgt_type,
      id: item.activity?.pstid,
      uid: parseUserID(item, "timeline"),
      originUser: parseOriginUser(item, auxData),
      replyingTo: item.activity?.opuid ?? auxData.puid,
      embedPost,
      embedUser: item.activity?.rusrIds,
      txt: auxData.txt,
      ttl: auxData.ttl,
      previmg: auxData.previmg,
      prevsrc: auxData.prevsrc,
      dsc: auxData.dsc,
      tags: auxData.htgs,
      mentions: auxData.utgs,
      imgs: auxData.imgs,
      vid: auxData.vid,
      ovid: auxData.ovid,
      pvid: auxData.pvid,
      nmvid: auxData.nmvid,
      main: auxData.main,
      vid_dur: auxData.vid_dur,
      vid_hgt: auxData.vid_hgt,
      vid_wid: auxData.vid_wid,
      sound_ids: auxData.sound_ids,
      sticker_ids: auxData.sticker_ids,
      meta: auxData.meta,
      p_type: auxData.p_type,
      likes: auxData.lkbpst,
      comments: auxData.cm,
      reposts: auxData.shbpst,
      key: item.activity?._id,
      vfpst: auxData.vfpst ?? 0,
      act_type: item.activity?.act_type,
      pvotes: auxData?.pvotes,
      premium: auxData?.premium,
      badges: auxData?.badges,
    };

    Object.keys(data).forEach((k) => {
      if (data[k] === null || data[k] === undefined) {
        delete data[k];
      }
    });

    if (
      data.action === SHARES_COMMENT ||
      data.action === OTHER_SHARES_COMMENT
    ) {
      // can't have embed on comment
      delete data.embedPost;
      delete data.embedUser;
    }

    //remove the duplicate post.
    if (
      ids.includes(data.id) &&
      data.action !== SHARES_POST &&
      data.action !== OTHER_SHARES_POST
    ) {
      return null;
    } else {
      ids.push(data.id);
    }

    return data;
  });

  return parsedFeed?.map((item) => {
    if (item?.action === OTHER_SHARES_POST) {
      const sharesPost = parsedFeed?.find(
        (feedItem) =>
          feedItem?.action === SHARES_POST && feedItem?.id === item?.id,
      );
      if (sharesPost) {
        const uids =
          item?.uid && typeof item.uid === "string" ? [item.uid] : item.uid;
        item.uid = [sharesPost?.uid, ...(uids ?? [])];
      }
    }
    if (item?.action === SHARES_POST) {
      const otherSharesPost = parsedFeed?.find(
        (feedItem) =>
          feedItem?.action === OTHER_SHARES_POST && feedItem?.id === item?.id,
      );
      if (otherSharesPost) {
        return null;
      }
    }
    return item;
  });
};

export const parseCommentFeed = (list, aux) => {
  if (!list?.length || !aux?.cmt) {
    return null;
  }

  return list.map((comment) => {
    const {
      acl,
      pid,
      puid: originUser,
      udate,
      _id: id,
      ...rest
    } = aux?.cmt[comment?._id] ?? {};
    const statsData = aux?.s_cmst ? aux?.s_cmst[id] : null;

    const data = {
      id,
      type: comment?._t,
      originUser,
      likes: statsData?.lkbcm,
      comments: statsData?.cm,
      reposts: statsData?.shbcm,
      ...rest,
    };

    Object.keys(data).forEach((k) => {
      if (data[k] === null || data[k] === undefined) {
        delete data[k];
      }
    });

    return data;
  });
};

export const getIsShared = (action) =>
  action === SHARES_COMMENT ||
  action === SHARES_POST ||
  action === OTHER_SHARES_COMMENT ||
  action === OTHER_SHARES_POST;

export const getIsReply = (item = {}) => {
  const {type, action, _t, act} = item;
  return (
    type === "cmt" ||
    action === OTHER_SHARES_COMMENT ||
    act === NOTIF_SHARES_COMMENT ||
    _t === "cmt"
  );
};

export const isResponseOk = (res) =>
  res?.data && res.data.rc === "OK" && res.data.result;

const parseOriginUser = (item, auxData) => {
  switch (item.activity?.action) {
    case SHARES_COMMENT:
      return item.activity?.tgt_oid;
    case OTHER_SHARES_COMMENT:
      return item.activity?.src_oid;
    default:
      return item.activity?.opuid ?? auxData.puid;
  }
};

const parseUserID = (item, scene) => {
  switch (item.activity?.action) {
    case SHARES_COMMENT:
      return item.activity?.init_id;
    case SHARES_POST:
      return item.activity?.src_id;
    case OTHER_SHARES_COMMENT:
      return item.activity?.tgt_id;
    case OTHER_SHARES_POST:
      return item.activity?.tgt_id;
    default:
      return scene === "timeline"
        ? item.activity?.uid
        : item.activity?.tgt_oid ?? item.activity?.init_id;
  }
};

export const parsePostDetail = (respData) => {
  // get post info
  const {
    rpstIds: embedId = null,
    rusrIds: embedUser = null,
    _id: id,
    _t: type,
    utgs: mentions,
    htgs: tags,
    acl,
    udate,
    uid,
    ...rest
  } = respData?.data ?? {};
  const {
    cm: comments,
    lkbpst: likes,
    shbpst: reposts,
  } = respData?.aux?.s_pst ?? {};
  const {pvotes} = respData?.aux ?? {};
  const {shrdpst: embededPost = null} = respData?.aux ?? {};
  const embededUser = embededPost ? respData?.aux?.uinf[embededPost.uid] : null;
  if (embededPost) {
    embededPost.id = embededPost._id;
    embededPost.type = embededPost._t;
  }
  if (embededUser) {
    embededUser.id = embededUser._id;
  }

  const post = {
    id,
    type,
    comments,
    likes,
    reposts,
    mentions,
    tags,
    embedId,
    embedUser,
    embededPost,
    embededUser,
    pvotes,
    ...rest,
  };

  Object.keys(post).forEach((k) => {
    if (post[k] === null || post[k] === undefined) {
      delete post[k];
    }
  });

  // get poster info
  const user = respData?.aux?.uinf ? respData?.aux?.uinf[uid] : null;
  if (user) {
    user.id = user._id;
  }

  return {post, user};
};

export const getEmbeddedPostMediaType = (embeddedPost) => {
  const {imgs, ovid, txt, vid} = embeddedPost ?? {};
  if (Array.isArray(imgs) && imgs.length > 0) {
    return MediaTypeEnum.IMAGE;
  } else if (ovid != null || vid != null) {
    return MediaTypeEnum.VIDEO;
  } else if (txt != null) {
    return MediaTypeEnum.TEXT_ONLY;
  }
  return MediaTypeEnum.UNKNOWN;
};

export const parseCommentDetail = (respData) => {
  // get post info
  const {
    _id: id,
    _t: type,
    utgs: mentions,
    htgs: tags,
    uid,
    ...rest
  } = respData?.data ?? {};
  const {
    cm: comments,
    lkbcm: likes,
    shbcm: reposts,
  } = respData?.aux?.s_cmst ?? {};

  // get comment user info
  const owner = respData?.aux?.uinf ? respData?.aux?.uinf[uid] : null;
  if (owner) {
    owner.id = owner._id;
  }

  // get parent post or parent comment
  const {post, user} = parsePostDetail(respData?.aux?.post);

  // build return data
  const comment = {
    id,
    type,
    comments,
    likes,
    reposts,
    mentions,
    tags,
    owner,
    ...rest,
  };
  comment.parent = {post: post, user: user};

  return comment;
};

export const parseItemStats = (aux) => {
  const postObject = (aux?.post ?? aux?.cmt) || {};
  const statsObject = (aux?.s_pst ?? aux?.s_cmst) || {};
  const newPostStats = (aux?.newPostStats ?? aux?.s_cmst) || {};
  const pvotesObject = aux?.pvotes || {};
  const pstatsObject = aux?.stats || {};
  const formattedStatsObject = {};

  //backend api changed
  //1 the postData has the stats using it first
  //2 the postData is null using the newPostStats
  //3 using the old s_pst data
  for (const key in postObject) {
    const postData = postObject[key];
    formattedStatsObject[key] = {
      numLikes: Number(postData?.lkbpst ?? postData?.lkbcm) || 0,
      numComments: Number(postData?.cm) || 0,
      numShares: Number(postData?.shbpst ?? postData?.shbcm) || 0,
    };
  }
  if (Object.keys(newPostStats).length > 0) {
    for (const key in newPostStats) {
      const statsData = newPostStats[key];
      if (!formattedStatsObject[key]) {
        formattedStatsObject[key] = {
          numLikes:
            Number(statsData?.likes ?? statsData?.lkbpst ?? statsData?.lkbcm) ||
            0,
          numComments: Number(statsData?.comments ?? statsData?.cm) || 0,
          numShares:
            Number(
              statsData?.reposts ?? statsData?.shbpst ?? statsData?.shbcm,
            ) || 0,
        };
      }
    }
  } else {
    for (const key in statsObject) {
      const statsData = statsObject[key];
      if (!formattedStatsObject[key]) {
        formattedStatsObject[key] = {
          numLikes: Number(statsData?.lkbpst ?? statsData?.lkbcm) || 0,
          numComments: Number(statsData?.cm) || 0,
          numShares: Number(statsData?.shbpst ?? statsData?.shbcm) || 0,
        };
      }
    }
  }
  for (const key in pvotesObject) {
    const pvotesData = pvotesObject[key];
    formattedStatsObject[key] = {
      ...(formattedStatsObject[key] || {}),
      pvotes: pvotesData,
    };
  }
  for (const key in pstatsObject) {
    const pstatsData = pstatsObject[key];
    formattedStatsObject[key] = {
      ...(formattedStatsObject[key] || {}),
      stats: pstatsData,
    };
  }

  return formattedStatsObject;
};

const USERS_TIME_ZONE = {
  miles: "America/New_York",
  milesguo: "America/New_York",
};

export const getPreFillText = (userId) => {
  const user = userId?.toLowerCase();
  return USERS_TIME_ZONE[user] !== undefined
    ? formatInTimeZone(new Date(), USERS_TIME_ZONE[user], "yyyy.MM.dd")
    : "";
};

export const getOriginalPost = (posts, currentPost = {}) => {
  if (!posts?.length)
    return {originalPost: null, originalId: currentPost?.originPostId};
  let originalId = currentPost?.originPostId || currentPost?.pid;
  let result = currentPost;
  if (originalId) {
    for (let i = 0; i < posts.length; i++) {
      const item = posts[i];
      if ((item.id || item._id) === originalId) {
        const {originalPost: oPost, originalId: oId} = getOriginalPost(posts, {
          ...item,
        });
        result = oPost;
        originalId = oId;
        break;
      }
    }
  }
  return {originalPost: result, originalId};
};
