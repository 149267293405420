import {userConstants} from "../_constants";
import GAxios from "src/util/GAxios";
import {t} from "src/i18n/utils";
import {emailMatches} from "../../../../util/validationSchemas";
import {showToast} from "../../../../util/showToast";
import {TimeUtil} from "../../../../util/TimeUtil";
import {setMultiAccount} from "src/util/userUtil";

export const otpLogin = async (params) => {
  if (!params) return;
  const reqtype = userConstants.VERIF_CODE_REQ_TYPE_LOGIN;
  const {email, phone, verifycode} = params;

  const whatsapp = localStorage.getItem("Whatsapp");
  const isWhatsapp = whatsapp === "true";

  const smsParam = !isWhatsapp ? {sms: "+" + phone} : {whatsapp: "+" + phone};

  let content = {
    email,
    ...smsParam,
    verifycode,
    reqtype,
  };

  const data = {
    content,
  };

  const config = {
    method: "post",
    url: userConstants.API_VERIF_CODE,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  let loginData = {
    authenticated: false,
    userinfo: null,
  };

  const callback = (err, res) => {
    localStorage.removeItem("Whatsapp");
    if (err) {
      let errorCode = err?.code;
      loginData.err = err;
      if (errorCode === "E_USER_BANNED_LOGIN") {
        loginData.userinfo = "E_USER_BANNED_LOGIN";
      }
    } else {
      loginData.authenticated = !err;
      loginData.userinfo = res;
      showToast(
        "success",
        t("getter_fe.auth.notification.welcome", {
          username: res?.ousername || "",
        }),
        {
          msgShort: true,
          scene: "login",
        },
      );

      if (loginData.authenticated && res) {
        setMultiAccount(loginData);
      }
    }
  };

  const processResult = (result) => {
    let err = null;

    let authenticated = result["token"];
    let loadedUserInfo = result["user"];

    if (!authenticated) {
      err = new Error("Auth failed");
    } else {
      loadedUserInfo.token = authenticated;
    }
    callback(err, loadedUserInfo);
  };

  await GAxios(
    config,
    (response) => {
      processResult(response.data.result);
    },
    (err) => {
      console.error(err);
      callback(err?.response?.data?.error);
    },
  );
  return loginData;
};

export const login = async (params) => {
  if (!params) return;

  const {pwd, email, username, sms} = params;
  let content = {pwd, sms};
  if (!sms) {
    if (username?.indexOf("@") === -1) {
      content = {
        username: username.toLowerCase(),
        ...content,
      };
    } else {
      content = {
        email: email.toLowerCase(),
        ...content,
      };
    }
  }

  const data = {
    content,
  };

  const config = {
    ignoreErrors: true,
    method: "post",
    url: userConstants.API_LOGIN_URL,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  let loginData = {
    authenticated: false,
    userinfo: null,
    err: null,
  };

  const callback = (err, res) => {
    if (err) {
      let errorCode = err?.code;
      let untilTime = TimeUtil.ErrorMsgUntilTime(err?.args[0]);
      loginData.err = err;
      let table = {
        E_AUTH: t("getter_fe.auth.errors.usernameInvalidLogin2"),
        E_NOT_ALLOWED: t("getter_fe.auth.errors.accountBanned"),
        E_USER_BANNED: t("getter_fe.auth.errors.E_USER_BANNED2", {
          time: untilTime,
        }),
        E_USER_BANNED_LOGIN: t("getter_fe.auth.errors.suspendedUserLogin2", {
          time: untilTime,
        }),
        E_USER_NOTFOUND: t("getter_fe.auth.errors.noAccount"),
        E_USER_SUSPENDED: t("getter_fe.auth.errors.E_USER_SUSPENDED"),
      };

      for (const key in table) {
        if (errorCode === key) {
          let toastMessage = table[key];
          showToast("warning", toastMessage, {msgShort: true, autoClose: 7000});
          return;
        }
      }
      showToast(
        "warning",
        emailMatches.test(email)
          ? t("getter_fe.auth.errors.emailInvalidLogin")
          : t("getter_fe.auth.errors.usernameInvalidLogin1"),
        {msgShort: true},
      );
    } else if (res) {
      loginData.authenticated = !err;
      loginData.userinfo = res;
      showToast(
        "success",
        t("getter_fe.auth.notification.welcome", {
          username: res?.ousername || "",
        }),
        {
          msgShort: true,
          scene: "login",
        },
      );
      if (loginData.authenticated && res) {
        setMultiAccount(loginData);
      }
    }
  };

  const processResult = (result) => {
    let err = null;

    let authenticated = result["token"];
    let loadedUserInfo = result["user"];

    if (!authenticated) {
      err = new Error("Auth failed");
    } else {
      loadedUserInfo.token = authenticated;
    }
    callback(err, loadedUserInfo);
  };

  await GAxios(
    config,
    (response) => {
      processResult(response?.data?.result);
    },
    (err) => {
      callback(err?.response?.data?.error);
    },
  );

  return loginData;
};
