import GAxios from "src/util/GAxios";

export const deletePinPost = async (data) => {
  if (!data) return;

  const {userId, postId, errorCallback} = data;

  try {
    const config = {
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}/u/user/${userId}/post/${postId}/unpin`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    let res;

    await GAxios(
      config,
      (response) => {
        res = response.data.result;
      },
      (err) => {
        res = null;
        if (errorCallback) errorCallback(err);
      },
    );

    return res;
  } catch (error) {
    console.error("the error :: ", error);
    return null;
  }
};
