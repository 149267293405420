import GAxios from "src/util/GAxios";

export const fetchIdentification = async (data) => {
  const {userId, callback} = data;
  if (!userId) return;

  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/gid/identification/${userId}`,
    ignoreErrors: true,
  };

  let res = null;

  await GAxios(
    config,
    (response) => {
      res = response?.data?.result || null;
    },
    (err) => {
      res = {
        names: {
          prompt_type: 0,
        },
      };
      console.log(err?.response?.data?.error?.code);
    },
  );
  callback(res);
  return res;
};
