import {
  createSlice,
  createAsyncThunk,
  createAction,
  current,
} from "@reduxjs/toolkit";
import {create} from "lodash-es";
import {
  videoConstraintsByFrameSize,
  videoCodecs,
  videoFrameSizes,
} from "../HostSide/main/camera/constants/PublishOptions";
import {WssErrHistory} from "../HostSide/main/camera/webrtc/common";

const NS = "liveStream";
const initialState = {
  userInfo: {
    _id: "",
    ico: "",
    nickname: "",
    lv_configs: {},
    roles: {},
  },
  replay: {
    hasChat: false,
    isPlay: false, // replay chat use refresh
    currentTime: 0,
    duration: 0,
    jumpTime: 0,
  },
  channels: {
    activeChannel: null,
    rtmpChannels: [],
    rtcChannels: [],
    rtmp4kChannels: [],
    normalChannels: [],
  },
  room: {
    data: {
      channelId: "",
      livePost: "",
      metadata: {},
      rtmConfig: {},
      liveHost: {},
      postData: {
        ttl: "",
        dsc: "",
      },
      liveConfigs: {
        isMuted: false,
      },
      broadcast: {
        isLive: false,
        viewsCount: 0,
      },
      globals: {
        rateLimit: {
          hearts: 0,
          stickers: 0,
          text: 0,
          limitType: 0,
        },
      },
    },
    end: false,
    chatOpen: true,
    modalShow: true,
    streamURL: "",
    status: "", // obs
    isConnected: false, // is pushing stream
    channelId: "",
    msgList: [],
    fullScreen: false,
    sliderValue: 0,
    chtselectImg: null,
    chkHasValue: null,
    removeAllExcessText: false,
  },
  commonForm: {
    confirm: false,
    loading: false,
    data: {
      ttl: "",
      dsc: "",
      img: "",
      autoStart: false,
      subEnabled: false,
      fromLang: "",
    },
  },
  obsForm: {
    resetLoading: false,
    data: {
      streamKey: "",
      rtmpUrl: "",
    },
  },
  cameraForm: {
    start: false,
    soundVolume: 0,
    signal: 0,
    closeAllMedia: false,
    media: {
      stream: undefined,
      canvas: undefined,
      audioTracksMap: {},
      videoTracksMap: {},
      cameraStreamsMap: {},
      displayScreenTrack: undefined,
      cameras: [],
      microphones: [],
      constraints: {video: videoConstraintsByFrameSize["default"], audio: true},
      gotPermissions: false,
      devicesLoaded: false,
      devicesLoading: false,
      userMediaLoaded: false,
      userMediaLoading: false,
    },
    settings: {
      layout: 0,
      signalingURL: "",
      applicationName: "",
      streamName: "",
      streamInfo: undefined,
      audioBitrate: "64",
      audioCodec: "opus",
      audioTrack: undefined,
      audioTrackDeviceId: "",
      videoBitrate: "3500",
      videoTrack: undefined,
      videoTrack1DeviceId: "",
      videoTrack2DeviceId: "",
      videoCodec: videoCodecs[0].value,
      videoFrameRate: "30",
      videoFrameSize: videoFrameSizes[0].value,
      userData: undefined,
      publishStart: false,
      publishStarting: false,
      publishStop: false,
      publishStopping: false,
      arEnabled: false,
      micMuted: false,
    },
    webrtcPublish: {
      websocket: undefined,
      peerConnection: undefined,
      peerConnectionVideoSender: undefined,
      peerConnectionAudioSender: undefined,
      connected: false,
    },
    whereLocation: null,
  },
};

export const resetLiveStream = createAction(`${NS}/resetLiveStream`);
export const setPubnubMsgList = createAction(`${NS}/setPubnubMsgList`);
export const banPubnubMsgList = createAction(`${NS}/banPubnubMsgList`);
export const clearPubnubMsgList = createAction(`${NS}/clearPubnubMsgList`);
export const setLiveUserInfo = createAction(`${NS}/setLiveUserInfo`);
export const setRoomData = createAction(`${NS}/setRoomData`);
export const setRoomEnd = createAction(`${NS}/setRoomEnd`);
export const setIsLive = createAction(`${NS}/setIsLive`);
export const setViewsCount = createAction(`${NS}/setViewsCount`);
export const setChatOpen = createAction(`${NS}/setChatOpen`);
export const setChatIsMuted = createAction(`${NS}/setChatIsMuted`);
export const setModalShow = createAction(`${NS}/setModalShow`);
export const setStreamURL = createAction(`${NS}/setStreamURL`);
export const setLiveStatus = createAction(`${NS}/setLiveStatus`);
export const setChannelId = createAction(`${NS}/setChannelId`);
export const setCommonFormData = createAction(`${NS}/setCommonFormData`);
export const setCommonFormConfirm = createAction(`${NS}/setCommonFormConfirm`);
export const setCommonFormLoading = createAction(`${NS}/setCommonFormLoading`);
export const setObsFormData = createAction(`${NS}/setObsFormData`);
export const setObsFormResetLoading = createAction(
  `${NS}/setObsFormResetLoading`,
);
export const setCameraCloseAllMedia = createAction(
  `${NS}/setCameraCloseAllMedia`,
);
export const setCameraStart = createAction(`${NS}/setCameraStart`);
export const setCameraSoundVolume = createAction(`${NS}/setCameraSoundVolume`);
export const setCameraSignal = createAction(`${NS}/setCameraSignal`);
export const setCameraFormMedia = createAction(`${NS}/setCameraFormMedia`);
export const setCameraFormSettings = createAction(
  `${NS}/setCameraFormSettings`,
);
export const setCameraFormWebrtcPublish = createAction(
  `${NS}/setCameraFormWebrtcPublish`,
);
export const setCameraFormVideoTracks = createAction(
  `${NS}/setCameraFormVideoTracks`,
);
export const setCameraFormAudioTracks = createAction(
  `${NS}/setCameraFormAudioTracks`,
);
export const setChannels = createAction(`${NS}/setChannels`);
export const setReplayIsPlay = createAction(`${NS}/setReplayIsPlay`);
export const setReplayHasChat = createAction(`${NS}/setReplayHasChat`);
export const setReplayCurrentTime = createAction(`${NS}/setReplayCurrentTime`);
export const setReplayJumpTime = createAction(`${NS}/setReplayJumpTime`);
export const setReplayDuration = createAction(`${NS}/setReplayDuration`);

export const setsliderValue = createAction(`${NS}/setsliderValue`);
export const setFullScreen = createAction(`${NS}/setFullScreen`);

export const setSelectedImg = createAction(`${NS}/setSelectedImg`);
export const setChkHasValue = createAction(`${NS}/setChkHasValue`);
export const setRemoveAllExcessText = createAction(
  `${NS}/setRemoveAllExcessText`,
);

export const setWhereLocation = createAction(`${NS}/setWhereLocation`);

export const livestream = createSlice({
  name: NS,
  initialState,
  reducers: {
    resetLiveStream: (state) => {
      state.room = initialState.room;
      state.replay = initialState.replay;
      state.commonForm = initialState.commonForm;
      state.obsForm = initialState.obsForm;
      state.cameraForm = initialState.cameraForm;
      WssErrHistory.index = 0;
      WssErrHistory.history = [];
      WssErrHistory.firstErrTime = 0;
      WssErrHistory.lastErrTime = 0;
      WssErrHistory.diffTime = 0;
      if (!window.location.pathname.startsWith("/streamHostSide")) {
        state.channels = initialState.channels;
      }
    },
    setPubnubMsgList: (state, {payload}) => {
      const newData = state.room.msgList.concat(payload);
      if (newData.length > 200) {
        state.room.msgList = newData.slice(-150);
      } else {
        state.room.msgList = newData;
      }
    },
    banPubnubMsgList: (state, {payload}) => {
      state.room.msgList = state.room.msgList.map((t) =>
        // should delete all message (include text and sticker) for ban user
        t.user.userId === payload ? {...t, delete: true} : t,
      );
    },
    clearPubnubMsgList: (state) => {
      state.room.msgList = [];
    },
    setLiveUserInfo: (state, {payload}) => {
      state.userInfo = payload;
    },
    setRoomData: (state, {payload}) => {
      state.room.data = {...state.room.data, ...payload};
      // TODO: temp fix GTISS-8044 that streaming page FeedItemEmbeddedContent gets stream info bug
      if (state.room.data.postData) {
        state.room.data.postData.p_type = "stream";
        state.room.data.postData.cdate =
          state.room.data.postData.cdate || state.room.data.broadcast.startAt;
      }
    },
    setChannels: (state, {payload}) => {
      state.channels = {...state.channels, ...payload};
    },
    setRoomEnd: (state, {payload}) => {
      state.room.end = payload;
    },
    setIsLive: (state, {payload}) => {
      state.room.data.broadcast.isLive = payload;
    },
    setChatIsMuted: (state, {payload}) => {
      state.room.data.liveConfigs.isMuted = payload;
    },
    setViewsCount: (state, {payload}) => {
      state.room.data.broadcast.viewsCount = payload;
    },
    setChatOpen: (state, {payload}) => {
      state.room.chatOpen = payload;
    },
    setModalShow: (state, {payload}) => {
      state.room.modalShow = payload;
    },
    setStreamURL: (state, {payload}) => {
      state.room.streamURL = payload;
    },
    setLiveStatus: (state, {payload}) => {
      state.room.status = payload.status;
      state.room.isConnected = payload.isConnected;
    },
    setChannelId: (state, {payload}) => {
      state.room.channelId = payload;
    },
    setCommonFormData: (state, {payload}) => {
      state.commonForm.data = {...state.commonForm.data, ...payload};
    },
    setCommonFormConfirm: (state, {payload}) => {
      state.commonForm.confirm = payload;
    },
    setCommonFormLoading: (state, {payload}) => {
      state.commonForm.loading = payload;
    },
    setObsFormData: (state, {payload}) => {
      state.obsForm.data = payload;
    },
    setObsFormResetLoading: (state, {payload}) => {
      state.obsForm.resetLoading = payload;
    },
    setCameraCloseAllMedia: (state, {payload}) => {
      state.cameraForm.closeAllMedia = payload;
    },
    setCameraStart: (state, {payload}) => {
      state.cameraForm.start = payload;
    },
    setCameraSoundVolume: (state, {payload}) => {
      state.cameraForm.soundVolume = payload;
    },
    setCameraSignal: (state, {payload}) => {
      state.cameraForm.signal = payload;
    },
    setCameraFormMedia: (state, {payload}) => {
      state.cameraForm.media = {...state.cameraForm.media, ...payload};
    },
    setCameraFormSettings: (state, {payload}) => {
      state.cameraForm.settings = {...state.cameraForm.settings, ...payload};
    },
    setCameraFormWebrtcPublish: (state, {payload}) => {
      state.cameraForm.webrtcPublish = {
        ...state.cameraForm.webrtcPublish,
        ...payload,
      };
    },
    setCameraFormVideoTracks: (state, {payload}) => {
      const {action, trackKey, videoTrack} = payload;
      if (action === "add") {
        state.cameraForm.media.videoTracksMap[trackKey] = videoTrack;
      } else {
        delete state.cameraForm.media.videoTracksMap[trackKey];
      }
    },
    setCameraFormAudioTracks: (state, {payload}) => {
      const {action, trackKey, audioTrack} = payload;
      if (action === "add") {
        state.cameraForm.media.audioTracksMap[trackKey] = audioTrack;
      } else {
        delete state.cameraForm.media.audioTracksMap[trackKey];
      }
    },
    setReplayHasChat: (state, {payload}) => {
      state.replay.hasChat = payload;
    },
    setReplayIsPlay: (state, {payload}) => {
      state.replay.isPlay = payload;
    },
    setReplayCurrentTime: (state, {payload}) => {
      state.replay.currentTime = payload;
    },
    setReplayJumpTime: (state, {payload}) => {
      state.replay.jumpTime = payload;
    },
    setReplayDuration: (state, {payload}) => {
      state.replay.duration = payload;
    },
    setsliderValue: (state, {payload}) => {
      state.room.sliderValue = payload;
    },
    setFullScreen: (state, {payload}) => {
      state.room.fullScreen = payload;
    },
    setSelectedImg: (state, {payload}) => {
      state.room.chtselectImg = payload;
    },
    setChkHasValue: (state, {payload}) => {
      state.room.chkHasValue = payload;
    },
    setRemoveAllExcessText: (state, {payload}) => {
      state.room.removeAllExcessText = payload;
    },
    setWhereLocation: (state, {payload}) => {
      state.whereLocation = payload;
    },
  },
});
