export const socialConstants = {
  LOCAL_STORAGE_SIGN_TYPE: "SOCIAL_SIGN_TYPE",
  LOCAL_STORAGE_PVR_INFO: "USED_SOCIAL_PVR_INFO",
  LOCAL_STORAGE_APPLE_AUTHED_INFO: "APPLE_AUTHED_INFO",
  LOCAL_STORAGE_GOOGLE_AUTHED_INFO: "GOOGLE_AUTHED_INFO",
  LOCAL_STORAGE_FACEBOOK_AUTHED_INFO: "FACEBOOK_AUTHED_INFO",
  LOCAL_STORAGE_AUTH0_INFO: "AUTH0_INFO",
  LOCAL_STORAGE_AUTH0_LOGIN: "IDTOKEN",

  LINKEDIN_PVR_KEY: "auth0_linkedin",
  LINKEDIN_CONNECTION_KEY: "linkedin",
  MICROSOFT_PVR_KEY: "auth0_windowslive",
  MICROSOFT_CONNECTION_KEY: "windowslive",
  TWITTER_PVR_KEY: "auth0_twitter",
  TWITTER_CONNECTION_KEY: "twitter",
  LINE_PVR_KEY: "auth0_line",
  LINE_CONNECTION_KEY: "line",
  AMAZON_PVR_KEY: "auth0_amazon",
  AMAZON_CONNECTION_KEY: "amazon",
  DISCORD_PVR_KEY: "auth0_discord",
  DISCORD_CONNECTION_KEY: "discord",
  TWITCH_PVR_KEY: "auth0_twitch",
  TWITCH_CONNECTION_KEY: "twitch",
  VIMEO_PVR_KEY: "auth0_vimeo",
  VIMEO_CONNECTION_KEY: "vimeo",
  GITHUB_PVR_KEY: "auth0_github",
  GITHUB_CONNECTION_KEY: "github",
  KAKAO_PVR_KEY: "auth0_kakao",
  KAKAO_CONNECTION_KEY: "kakao",

  GOOGLE_PVR_KEY: "auth0_google-oauth2",
  GOOGLE_CONNECTION_KEY: "google-oauth2",
  APPLE_PVR_KEY: "auth0_apple",
  APPLE_CONNECTION_KEY: "apple",
  FACEBOOK_PVR_KEY: "auth0_facebook",
  FACEBOOK_CONNECTION_KEY: "facebook",

  SIGN_IN_PAYLOAD_TYPE: {
    SI_OK: "si_ok",
    E_VERIFY: "e_verify",
    SI_NEW: "si_new",
  },
  LINK_SIGN_IN_PAYLOAD_TYPE: "la_ok",
  SIGNUP_PAYLOAD_TYPE: "su_ok",
  SIGN_OUT_PAYLOAD_TYPE: {
    SO_OK: "so_ok",
  },
  AUTH0: {enable: true},
};

export const socialConfig = {
  // GOOGLE: {
  //   clientId: process.env.REACT_APP_SSO_GOOGLE_CLIENT_ID || "",
  //   enable: true,
  //   jsUrl: "https://accounts.google.com/gsi/client",
  // },
  // APPLE: {
  //   clientId: process.env.REACT_APP_SSO_APPLE_CLIENT_ID || "",
  //   enable: true,
  //   jsUrl:
  //     "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js",
  // },
  // TWITTER: {
  //   enable: false,
  // },
  // FACEBOOK: {
  //   clientId: process.env.REACT_APP_SSO_FB_CLIENT_ID || "",
  //   enable: true,
  //   jsUrl: "https://connect.facebook.net/en_US/sdk.js",
  // },
  AUTH0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENTID,
    redirectUri:
      process.env.REACT_APP_URL + process.env.REACT_APP_AUTH0_REDIRECT_URI,
    responseType: process.env.REACT_APP_AUTH0_RESPONSE_TYPE,
  },
  AUTH0_LOGIN: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENTID,
    redirectUri:
      process.env.REACT_APP_URL + process.env.REACT_APP_AUTH0_REDIRECT_URI,
    responseType: process.env.REACT_APP_AUTH0_RESPONSE_TYPE,
  },

  AUTH0_LOGIN_DB: process.env.REACT_APP_AUTH0_DB,
};
