import {createSlice, createAsyncThunk, createAction} from "@reduxjs/toolkit";
import {settingsConstants} from "src/app/pages/settings/_constants";
import {SettingsApi} from "./api";

const NS = "settings";

const initialState = {
  deleteAccount: {
    step: settingsConstants.DELETE_ACCOUNT_INFO,
  },
  notifications: {
    settings: null,
    name: settingsConstants.NOTIF_NAME_GLOBAL,
  },
  visions: {
    safeMode: false,
  },
};

export const changeDeleteAccountStep = createAsyncThunk(
  "auth/changeDeleteAccountStep",
  (data) => {
    return data;
  },
);

export const changeNotifName = createAsyncThunk(
  "auth/changeNotifName",
  (data) => {
    return data;
  },
);

export const getNotifSettings = createAsyncThunk(
  "auth/getNotifSettings",
  SettingsApi.getNotifSettings,
);

export const updateNotifSettings = createAsyncThunk(
  "auth/updateNotifSettings",
  SettingsApi.updateNotifSettings,
);

export const updateGTokSettings = createAsyncThunk(
  "auth/updateGTokSettings",
  SettingsApi.updateGTokSettings,
);

export const refreshSettings = createAction(`${NS}/refreshSettings`);
export const resetSettings = createAction(`${NS}/resetSettings`);
export const setGTokSafeMode = createAction(`${NS}/setGTokSafeMode`);

export const settingsSlice = createSlice({
  name: NS,
  initialState,
  reducers: {
    refreshSettings: (state, {payload}) => {
      if (!payload) return;
      let settings = state.notifications.settings;

      if (!payload.list) {
        settings[payload.switch].state = payload.state;
      }

      if (payload.list) {
        settings.f = payload.state;
      }

      state.notifications.settings = settings;
    },
    resetSettings: (state) => {
      state.notifications.settings = null;
      state.notifications.name = settingsConstants.NOTIF_NAME_GLOBAL;
    },
    setGTokSafeMode: (state, {payload}) => {
      state.visions.safeMode = payload;
    },
  },
  extraReducers: (builder) => {
    // Doc: https://redux-toolkit.js.org/usage/usage-with-typescript#type-safety-with-extrareducers

    builder.addCase(changeDeleteAccountStep.fulfilled, (state, {payload}) => {
      if (payload) {
        state.deleteAccount.step = payload;
      }
    });

    builder.addCase(changeNotifName.fulfilled, (state, {payload}) => {
      if (payload) {
        state.notifications.name = payload;
      }
    });

    builder.addCase(getNotifSettings.fulfilled, (state, {payload}) => {
      if (payload) {
        state.notifications.settings = payload;
      }
    });

    builder.addCase(updateNotifSettings.fulfilled, (state, {payload}) => {
      if (payload && !payload.err && !payload.list) {
        let settings = state.notifications.settings;
        let settingElem = settings[payload?.switch];

        settingElem.scope = payload.scope;
        settingElem.state = payload.state;

        let newSettings = {...settings, ...settingElem};

        state.notifications.settings = newSettings;
      }

      if (payload?.list && !payload.err) {
        let settings = state.notifications.settings;
        settings.f = payload.state;

        state.notifications.settings = settings;
      }
    });

    builder.addCase(updateGTokSettings.fulfilled, (state, {payload}) => {
      state.visions.safeMode = !payload?.vis_configs?.sp;
    });
  },
});
