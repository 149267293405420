import GAxios from "src/util/GAxios";
import {userConstants} from "../_constants";
import {emailSmsErrorCallback} from "../../../../util/ErrorCallback";

export const sendVerifCodeSignup = async (
  params,
  asyncDefaultParam = 0,
  isLogin = false,
  errorCallback,
) => {
  let {email, reqtype, isAddEmail} = params;

  if (!reqtype) {
    reqtype = isLogin
      ? userConstants.VERIF_CODE_REQ_TYPE_LOGIN
      : userConstants.VERIF_CODE_REQ_TYPE_SIGNUP;
  }

  let url = userConstants.API_SEND_VERIF_CODE_EMAIL;

  let data = JSON.stringify({
    content: {
      email: email?.toLowerCase(),
      reqtype,
    },
  });

  if (isAddEmail) {
    url = userConstants.API_SEND_VERIF_LINK_EMAIL;
    data = JSON.stringify({
      content: {
        bindEmail: email?.toLowerCase(),
      },
    });
  }

  const config = {
    method: "post",
    url,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  let res;

  await GAxios(
    config,
    (response) => {
      if (response) {
        if (isAddEmail) res = {...response.data?.result};
        else res = {...response.data?.result, ...params};
      } else {
        res = null;
      }
    },
    (err) => {
      emailSmsErrorCallback(err, "email");
      res = null;
      if (errorCallback) errorCallback(err);
    },
  );

  return res;
};
