import GAxios from "src/util/GAxios";
// import moment from "moment";

export const getStreamBatch = async (streamData) => {
  let res = null;
  let param = streamData?.map((item) => item.sid)?.filter((item) => !!item);

  // const streamInfo = streamData.map((el) => {
  //   if (el.sid) {
  //     let username;
  //     if (el.nickname?.length > 25) {
  //       username = el.nickname.substring(0, 20) + "...";
  //     } else {
  //       username = el.nickname;
  //     }
  //     return {
  //       userId: el._id,
  //       uname: username,
  //       userimg: `${process.env.REACT_APP_MEDIA_BASE}/${el?.ico}`,
  //       udate: moment(JSON.parse(el?.udate)).format("MMMM D YYYY"),
  //       postId: el.post_id,
  //     };
  //   }
  // });
  const data = JSON.stringify({
    content: {
      streamIds: param,
    },
  });
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/u/live/stream/batch`,
    data,
  };

  await GAxios(
    config,
    (response) => {
      // let datas = [];
      const {cats} = response?.data?.result?.data || {};
      // if (cats) {
      //   let fetchResponse = Object.values(cats),
      //     broadCastData = fetchResponse.map((el) => {
      //       el.broadcast.viewsCount = nFormatter(el.broadcast.viewsCount);
      //       return el.broadcast;
      //     });
      //   broadCastData.forEach((el) => {
      //     let stream = streamData?.find((item) => item?.sid === el.streamId);
      //     let user = streamInfo?.find((item) => item?.userId === stream?.uid);
      //     datas.push({...el, ...stream, ...user});
      //   });
      // }
      if (cats) res = cats;
      // dispatch(loaderTriger(false));
      // cats && dispatch(boardCastAction(cats));
    },
    (error) => {
      console.log(error);
    },
  );
  return res;
};

export const getMaStreamBatch = async (streamData) => {
  let res = null;
  let param = streamData?.map((item) => item.post_id)?.filter((item) => !!item);

  const data = JSON.stringify({
    content: {
      postIds: param,
    },
  });
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/u/live/v2/ma/batch`,
    data,
  };
  await GAxios(
    config,
    (response) => {
      // let datas = [];
      const {cats} = response?.data?.result?.data || {};

      // if (cats) {
      //   streamData?.forEach((el) => {
      //     const streamInfo = cats?.[el.post_id];
      //     if (streamInfo) {
      //       if (streamInfo?.isLive)
      //         streamInfo.viewsCount = streamInfo?.concurrents || 0;
      //       datas.push({...el, ...streamInfo});
      //     }
      //   });
      // }
      if (cats) res = cats;

      // dispatch(loaderTriger(false));
      // cats && dispatch(boardCastAction(cats));
    },
    (error) => {
      console.log(error);
    },
  );
  return res;
};
