import GAxios from "src/util/GAxios";

export const fetchHosts = async (data) => {
  let {phrase, cursor, count = 10} = data;

  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/u/search/v2/stream/hosts`,
    data: {
      content: {
        incl: "userstats|userinfo|followings|followers|islive|hasnewlive|posts|poststats",
        phrase: phrase,
        ...(cursor ? {cursor} : {}),
        count,
      },
    },
    ignoreTips: {t429: true},
  };

  let res = null;

  await GAxios(
    config,
    (response) => {
      if (response?.data?.rc === "OK") {
        res = {
          ...response.data.result,
          reset: !cursor,
          phrase,
          cursor:
            !response.data.result?.data?.list?.length ||
            /^0$/.test(response?.data?.result?.aux?.cursor)
              ? ""
              : response.data.result?.aux?.cursor,
        };
      }
    },
    (err) => {
      res = err;
    },
  );

  return res;
};
