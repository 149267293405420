import GAxios from "src/util/GAxios";
import {CHAT_API} from "./index";

/**
 *
 * @param {*} channel_id
 * @returns
 */
export const getScopeUsers = async ({keyword, scope, channelId}) => {
  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/${CHAT_API}/users/search`,
    params: {
      keyword,
      scope,
      channel_id: channelId,
      includes: "following,followed",
    },
  };

  let res = null;

  await GAxios(
    config,
    (response) => {
      if (response?.data?.result) {
        res = {
          list: response.data.result,
          channelId,
          scope,
          keyword,
        };
      }
    },
    (error) => {
      res = error?.response || null;
    },
  );

  return res;
};
