import {
  isResponseOk,
  parseItemStats,
  parsePostFeed,
  parseUser,
} from "src/util/FeedUtils";
import GAxios from "src/util/GAxios";
import {timelineConstants} from "../_constants";

// fetch params for each feed view
// POST,REPLIES,MEDIAS,LIKES
const POST = "f_uo";
const REPLIES = "f_uc";
const LIVE = "f_ui";
const MEDIAS = "f_um";
const LIKES = "f_ul";
const VISION = "f_uv";
const FETCH_PARAMS = [POST, REPLIES, LIVE, VISION, MEDIAS, LIKES];

export const getUserPostFeed = ({userId, tabIndex}, {getState}) => {
  const {auth, timelineNew} = getState() ?? {};
  const {userinfo} = auth?.session ?? {};
  const currentUserId = userId ?? userinfo?._id;
  const {cursor} = timelineNew?.userPostFeed ?? {};

  let params = {
    offset: 0,
    max: timelineConstants.MAX_BATCH_SIZE,
    dir: timelineConstants.DIRECTION_FORWARD,
    incl: "posts|stats|userinfo|shared|liked|pvotes",
    fp: FETCH_PARAMS[tabIndex],
  };

  if (cursor) {
    params = {...params, ...{cursor}};
  }

  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/u/user/${currentUserId}/posts`,
      params,
    };
    GAxios(
      config,
      (res) => {
        if (isResponseOk(res)) {
          const list = res?.data?.result?.data?.list;
          const aux = res?.data?.result?.aux;
          const {
            lks: likedPosts,
            removed,
            shrs: sharedPosts,
            cursor,
          } = aux ?? {};
          let posts = parsePostFeed(list, aux);
          const users = parseUser(aux);
          const postStats = parseItemStats(aux);
          let count = posts?.length ?? 0;
          posts = posts?.filter((post) => post != null);
          if (!posts?.length) {
            posts = undefined;
          }
          count = count - (posts?.length ?? 0);

          return resolve({
            posts,
            users,
            likedPosts,
            sharedPosts,
            removed: removed + count,
            postStats,
            userId,
            cursor,
          });
        }

        return resolve(null);
      },
      (err) => {
        return reject({err});
      },
    );
  });
};
