import GAxios from "src/util/GAxios";

export const aiCheckPermission = async () => {
  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/copilot/v1/check-permission`,
    timeout: 60000,
  };

  let res = {response: null, err: null};

  await GAxios(
    config,
    (response) => {
      if (response?.data?.result) {
        res = response?.data?.result;
      }
    },
    (err) => {
      res = {
        error: err?.response?.data?.error,
      };
    },
  );

  return res;
};
