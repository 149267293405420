import GAxios from "src/util/GAxios";

export const getPopUserData = async () => {
  let result = null;

  const reqUrl =
    process.env.REACT_APP_API_URL +
    `/s/usertag/recommend?max=100&incl=userinfo&recommendTypes=all`;
  const config = {
    method: "get",
    url: reqUrl,
    headers: {
      "Content-Type": "application/json",
    },
  };

  await GAxios(
    config,
    (res) => {
      result = res?.data;
    },
    (err) => {
      result = null;
    },
  );
  return result;
};
