import {fetchHistory} from "./fetchHistory";
import {fetchSearchHistory} from "./fetchSearchHistory";
import {saveLivestream} from "./saveLivestream";
import {removeAllHistory} from "./removeAllHistory";
import {deleteHistory} from "./deleteHistory";
import {fetchBatchProgress} from "./fetchBatchProgress";

export const LiveListHistoryApi = {
  fetchHistory,
  fetchSearchHistory,
  saveLivestream,
  removeAllHistory,
  deleteHistory,
  fetchBatchProgress,
};
