export const timeFormatter = (totalSeconds: number) => {
  const secondsNumber = Math.round(totalSeconds);
  const hours = Math.floor(secondsNumber / 3600);
  let minutes: string | number = Math.floor(
    (secondsNumber - hours * 3600) / 60,
  );
  let seconds: string | number = secondsNumber - hours * 3600 - minutes * 60;
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  if (hours > 0) {
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    return hours + ":" + minutes + ":" + seconds;
  } else {
    return minutes + ":" + seconds;
  }
};
