import {
  isResponseOk,
  parseItemStats,
  parseGTokFeed,
  parseUser,
} from "src/util/FeedUtils";
import GAxios from "src/util/GAxios";
import {timelineConstants} from "src/app/components/timeline/_constants";
import {updateRecommendGTok} from "src/app/components/gtok/store";
import {addFollowingUserIds} from "src/store/modules/status";
import {getStore} from "src/store";
import {emptyDataEventTrack} from "src/util/trackEvent";
import {
  addLikedPosts,
  addSharedPosts,
  setPostsStats,
} from "src/store/modules/postStats";

const fetchRecommend = async (queryParams = {}, {dispatch}) => {
  let resp = null;
  const store = await getStore();
  const authenticated = store.getState().auth?.session?.authenticated;
  try {
    queryParams = queryParams || {};
    const params = {
      // the max number of items to be returned.
      max:
        //  timelineConstants.MAX_BATCH_SIZE
        timelineConstants.MAX_VISION_SIZE, // Make the recommend data full use bcz back-end will removed the data from recommend pool in every fetch
      // the number of items skipped from the beginning before fetching starts. Note: if cursor is used, this parameter will be ignored.
      cursor: 0,
      //  the include options specifies what information should be preloaded before returning result to front end. It is also used as flags in some scenarios to tell backend what type of data to return.
      incl: "visions|posts|postinfo|userinfo|stats|shared|liked|followings|followers",
      ...queryParams,
    };

    const config = {
      method: "get",
      url:
        process.env.REACT_APP_API_URL +
        (authenticated ? "/u/posts/recommend" : "/u/vision/v2/landing/visions"),
      params,
      ignoreErrors: true,
    };

    await GAxios(
      config,
      (res) => {
        if (isResponseOk(res)) {
          const {aux, data} = res?.data?.result ?? {};
          const {list} = data ?? {};
          const {lks: likedPosts, removed, shrs: sharedPosts} = aux ?? {};
          const users = parseUser(aux);

          let posts = parseGTokFeed(list, aux);
          let count = posts?.length ?? 0;
          posts = posts?.filter((post) => post != null);
          count = count - (posts?.length ?? 0);
          const postStats = parseItemStats(aux);
          dispatch(setPostsStats(postStats));
          if (list?.length === 0 || !list) {
            emptyDataEventTrack("VISION_EXPLORE", res);
          }
          resp = {
            likedPosts,
            posts,
            removed: removed + count,
            users,
            sharedPosts,
            postStats,
            cursor: aux?.cursor || 0,
            fws: aux?.fws ?? [],
            fwr: aux?.fwr ?? [],
          };
        } else {
          emptyDataEventTrack("VISION_EXPLORE", res);
        }
      },
      (err) => {
        console.error(err);
        emptyDataEventTrack("VISION_EXPLORE", err);
      },
    );
  } catch (error) {
    console.error(error);
  }

  return resp;
};

export const getRecommend = async (
  queryParams = {},
  {getState, dispatch},
  switcher = {},
) => {
  queryParams = queryParams || {};
  const {vision} = getState?.() ?? {};
  const {recommendCursor = 0} = vision ?? {};

  const {autoCompletion = true} = switcher;
  if (!autoCompletion) {
    const res = await fetchRecommend(
      {
        ...queryParams,
      },
      {getState, dispatch},
    );
    res?.postStats && dispatch(setPostsStats(res?.postStats));
    // if res.fws is array and it's length is greater than 0, then we need to update the followings
    const {likedPosts, fws, sharedPosts} = res;
    fws?.length && dispatch(addFollowingUserIds(res?.fws));
    likedPosts?.length && dispatch(addLikedPosts(likedPosts));
    sharedPosts?.length && dispatch(addSharedPosts(sharedPosts));

    return res;
  }

  let totalPosts = [];
  let currentCursor = recommendCursor;
  let count = 0;

  while (
    totalPosts?.length < timelineConstants?.AUTO_COMPLETION_SIZE &&
    count < 12
  ) {
    const res = await fetchRecommend(
      {
        ...queryParams,
        cursor: currentCursor,
      },
      {getState, dispatch},
    );
    const {posts = [], postStats = {}, cursor: resCursor, removed} = res ?? {};

    if (posts.length === 0 && !removed) {
      break;
    }

    count++;
    posts.length && dispatch(updateRecommendGTok(res));

    Object.keys(postStats).length && dispatch(setPostsStats(postStats));
    const {likedPosts, fws, sharedPosts} = res;
    fws?.length && dispatch(addFollowingUserIds(res?.fws));
    likedPosts?.length && dispatch(addLikedPosts(likedPosts));
    sharedPosts?.length && dispatch(addSharedPosts(sharedPosts));

    totalPosts = totalPosts?.concat(posts);
    currentCursor = resCursor;
  }
  return {
    posts: totalPosts,
    cursor: currentCursor,
  };
};
