import GAxios from "src/util/GAxios";
import {settingsConstants} from "src/app/pages/settings/_constants";

export const updateGTokSettings = async (params) => {
  const config = {
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/${settingsConstants.API_UPDATE_VISION_SETTING}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      content: {
        vis_configs: {sp: params},
      },
    }),
  };

  let res = null;

  await GAxios(
    config,
    (response) => {
      if (response?.data?.result?.data) {
        res = response?.data?.result?.data;
      }
    },
    (err) => {},
  );

  return res;
};
