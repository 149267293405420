import {getNotifications} from "./getNotifications";
import {getMentions} from "./getMentions";
import {getUnread} from "./getUnread";
import {getLivestreamUnread} from "./getLivestreamUnread";
import {getRingMeState} from "./getRingMeState";
import {updateRingMeState} from "./updateRingMeState";
import {getIsLiveUser} from "./getIsLiveUser";
import {deleteNotification} from "./deleteNotification";
import {getLivestreamNotifications} from "./getLivestreamNotifications";
import {getPollNotifications} from "./getPollNotification";
import {getPollUnread} from "./getPollUnread";

export const NotificationsApi = {
  getMentions,
  getNotifications,
  getLivestreamNotifications,
  getUnread,
  getLivestreamUnread,
  getRingMeState,
  updateRingMeState,
  getIsLiveUser,
  deleteNotification,
  getPollNotifications,
  getPollUnread,
};
