import GAxios from "src/util/GAxios";
import {addFollowingUserIds} from "src/store/modules/status";
import {getChatUsers} from "./getChatUsers";
import {getStore} from "src/store";
import {handleMediaUrl} from "src/util/imageUtils";

const MAX_PER_PAGE = 10;

/**
 * Get followers of a user.
 * @param {string} target target user id
 * @param {number} cursor cursor
 *  */
export const getFollowers = async (target, cursor = 0) => {
  if (!target) {
    return;
  }

  const store = await getStore();
  let params = {
    max: MAX_PER_PAGE,
    incl: "userstats|userinfo|followers",
  };

  if (cursor !== 0) {
    params = {...params, ...{cursor}};
  }

  const config = {
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/u/user/${target}/followers`,
    params,
    headers: {
      "Content-Type": "application/json",
    },
  };

  let result = null;
  await GAxios(
    config,
    async (res) => {
      const aux = res?.data?.result?.aux;
      const data = res?.data?.result?.data;
      const newCursor = aux?.cursor ?? 0;
      const chatUsers = await getChatUsers({targets: data.list});
      const streamUsers = Object.values(chatUsers ?? []).map((user) => {
        const user_id = user.id;
        return {
          id: user.chat_id,
          user_id,
          username: user.username,
          verified: user.verified,
          premium: aux?.uinf[user_id]?.premium,
          badges: aux?.uinf[user_id]?.badges,
          ousername: user.original_name,
          image: handleMediaUrl(process.env.REACT_APP_MEDIA_BASE, user.ico),
          nickname: user.nickname,
          name: user.nickname,
          banned: false,
          online: false,
          role: "user",
          shadow_banned: false,
        };
      });

      result = {
        reachEnd:
          data?.list?.length + (aux?.removed ?? 0) < MAX_PER_PAGE ||
          newCursor === 0,
        cursor: newCursor,
        data: streamUsers ?? [],
      };

      if (aux?.fws && Array.isArray(aux.fws)) {
        store.dispatch(addFollowingUserIds(aux.fws));
      }
    },
    (err) => {
      console.error(err);
    },
  );

  return result;
};
