import GAxios from "src/util/GAxios";

export const getIsLiveUser = async (params) => {
  const {userId, errorCallback} = params;
  if (!userId) return;

  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/u/user/${userId}/liveuser`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  let res = false;

  await GAxios(config, (response) => {
    if (response && response.data?.result) {
      res = response.data?.result;
    }
  });

  return res;
};
