import {cloneDeep, difference, isArray, isEmpty, isObject} from "lodash-es";
import {LegacyPostStats, PostStats} from "./types";

type OldPostStats = {
  numLikes: number;
  numShares: number;
  numComments: number;
  pvotes: number[];
  stats: Record<number, number>;
};

type StatsRecord = Record<string, PostStats>;

type SharedData = {
  postStats: StatsRecord;
};

const removeUnnecessaryProperties = <T extends {[key: string]: any}>(
  obj: T,
): T => {
  const copy = {...obj};
  removeUnnecessaryPropertiesMut(copy);
  return copy;
};

const removeUnnecessaryPropertiesMut = <T extends {[key: string]: any}>(
  obj: T,
) => {
  Object.keys(obj).forEach((key) => {
    const v = obj[key];
    if (!v || ((isObject(v) || isArray(v)) && isEmpty(v))) {
      delete obj[key];
    }
  });
};

// It is important to return only the properties that have a value
// otherwise they'll overwrite existing data with empty values during merge
const mapPostStats = (
  stats: Partial<PostStats & OldPostStats> | undefined,
): PostStats => {
  const s: PostStats = {};
  const likes = stats?.l ?? stats?.numLikes;
  if (likes !== undefined) {
    s.l = likes;
  }
  const shares = stats?.s ?? stats?.numShares;
  if (shares !== undefined) {
    s.s = shares;
  }
  const comments = stats?.c ?? stats?.numComments;
  if (comments !== undefined) {
    s.c = comments;
  }
  const pvotes = stats?.pv ?? stats?.pvotes;
  if (pvotes !== undefined) {
    s.pv = pvotes;
  }
  const pstats = stats?.ps ?? stats?.stats;
  if (pstats !== undefined) {
    s.ps = pstats;
  }
  return s;
};

const mergePostStatsObjects = (
  existingStats: StatsRecord,
  newStats: Record<string, PostStats | LegacyPostStats>,
): StatsRecord => {
  const stats = Object.fromEntries(
    Object.entries(newStats).map(([k, v]) => [
      k,
      removeUnnecessaryProperties({...existingStats[k], ...mapPostStats(v)}),
    ]),
  );
  return {...existingStats, ...stats};
};

/** Merges new stats record with existing stats record */
export const updateStats = (
  sharedData: SharedData,
  newStats: Record<string, PostStats | LegacyPostStats>,
): void => {
  sharedData.postStats = mergePostStatsObjects(sharedData.postStats, newStats);
};

/** Updates (or creates) stats for a single post, partial stats will be merged with existing value */
export const updateSingleStats = (
  sharedData: SharedData,
  postId: string,
  newStats: Partial<PostStats> | ((existingStats?: PostStats) => PostStats),
): void => {
  sharedData.postStats[postId] = removeUnnecessaryProperties(
    mapPostStats({
      ...sharedData.postStats[postId],
      ...(typeof newStats === "function"
        ? newStats(mapPostStats(sharedData.postStats[postId])) // Mapping ensures all fields are present for easier updates
        : mapPostStats(newStats)),
    }),
  );
};

export const appendPollStats = (
  posts: {id: string; poll?: {stats: Record<number, number>}}[],
  postStats: Record<string, Partial<PostStats & OldPostStats>>,
): Record<string, Partial<PostStats & OldPostStats>> => {
  postStats = cloneDeep(postStats);
  posts.forEach((p) => {
    if (p.poll?.stats && postStats[p.id]) {
      postStats[p.id].ps = p.poll.stats;
    }
  });
  return postStats;
};

export const appendMut = (arr: string[], item: string) => {
  if (!arr.includes(item)) {
    arr.push(item);
  }
};

export const deleteMut = (arr: string[], item: string) => {
  const idx = arr.indexOf(item);
  if (idx !== -1) {
    arr.splice(idx, 1);
  }
};

export const concatMut = (arr1: string[], arr2?: string[]) => {
  const diff = difference(arr2 || [], arr1);
  arr1.push(...diff);
};
