export const timelineConstants = {
  MAX_BATCH_SIZE: 20,
  MAX_VISION_SIZE: 20,
  LOAD_MORE_CUTOFF_SIZE: 15,
  AUTO_COMPLETION_SIZE: 10,
  HEADER_HEIGHT: 0,
  OTHERS_HEIGHT: 200,
  DIRECTION_FORWARD: "fwd",
  DIRECTION_REVERSED: "rev",
  BELOW: "below",
  ABOVE: "above",
  INSIDE: "inside",
  NUMBER_HIDDEN_ITEMS: 6,
  LOAD_MORE_DISPLAY_CUTOFF_SIZE: 4,
  SCROLL_TYPE_DOWN: "scroll_type_down",
  SCROLL_TYPE_UP: "scroll_type_up",
  extraHeight: 2000,
  FEED_ITEM_MARGIN_BOTTOM: 15,
  SCROLL_CUTOFF: 4500,
  START_TS: "startTs",
  MERGE_SHARES: "shares",
  RETRY: 3,
};
