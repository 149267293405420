import axios from "axios";
import axiosRetry from "axios-retry";
import {getLang, t} from "src/i18n/utils";
import {getStore} from "src/store";
import {
  setImpervaChallenge,
  setImpervaData,
  goToLogout,
} from "src/app/components/auth/store";
import {handleErrorCodes} from "./handleErrorCode";
import {showToast} from "./showToast";
import AppConsts from "src/app/AppConsts";
import {history} from "src/configs/history";
import {WEB_VERSION} from "src/constants/api";

const handle429 = async (response) => {
  if (response?.status === 429) {
    showToast("error", t("getter_fe.common.error429"));
  }
};

const handle423 = async (response) => {
  if (response?.status === 423) {
    showToast("error", t("getter_fe.common.error423"));
  }
};

const handleSessionExpired = async (response) => {
  if (response?.response?.data?.error?.code === "SESSION_EXPIRED") {
    showToast(
      AppConsts.NOTIF_WARNING,
      t("getter_fe.gettrPay.errors.WALLET_SESSION_EXPIRED"),
    );
    history.push("/wallet/logout");
  }
};

const handle401 = async (response, userId) => {
  const store = await getStore();
  if (
    response?.status === 401 &&
    response.data?.error?.code !== "E_USER_NOTFOUND"
  ) {
    userId && showToast("error", t("getter_fe.auth.common.youLoggedOut"));
    store.dispatch(goToLogout());
  }
};

const handleImpervaResponse = async (data, size = "sm") => {
  const store = await getStore();
  window._impervaPending = true;
  let queryStr = "",
    param1 = "",
    param2 = "";
  let host = window.location.protocol + "//" + window.location.host;
  let doc = data ? new DOMParser().parseFromString(data, "text/html") : null;

  if (
    doc?.getElementsByTagName("head")[0]?.getElementsByTagName("script")
      ?.length > 0
  ) {
    doc
      .getElementsByTagName("head")[0]
      .querySelectorAll("script")
      .forEach((element) => {
        if (element.src.indexOf("_Incapsula_Resource") !== -1) {
          param2 = window.btoa(element.src.replace(host, ""));
        } else {
          param1 = window.btoa(element.src.replaceAll(host, ""));
        }
      });
  }

  if (typeof doc?.getElementById("main-iframe")?.src !== "undefined") {
    queryStr = doc
      .getElementById("main-iframe")
      .src.replace(host + "/_Incapsula_Resource?", "");
  }

  store.dispatch(setImpervaData({queryStr, param1, param2, size}));
  store.dispatch(setImpervaChallenge(true));
};

const isImpervaComplete = async () => {
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      if (!window._impervaPending) {
        resolve("imperva");
        clearInterval(interval);
      }
    }, 600);
  });
};

axios.interceptors.request.use((config) => {
  const url = config.baseURL || config.url || "";
  if (url.startsWith(process.env.REACT_APP_API_URL)) {
    config.withCredentials = true;
  }
  return config;
});

axios.interceptors.response.use(
  async (res) => {
    if (
      typeof res?.data === "string" &&
      res?.data?.includes("/_Incapsula_Resource?") &&
      !res?.config?.ignoreImperva
    ) {
      await handleImpervaResponse(res?.data);
      await isImpervaComplete();

      return -1;
    }
    return res;
  },
  async (error) => {
    let isImperva = false;
    if (
      error.isImperva !== true &&
      error.response?.status >= 400 &&
      error.response?.data &&
      error.config?.url?.startsWith(process.env.REACT_APP_API_URL) &&
      typeof error.response.data === "string" &&
      error.response.data.includes("/_Incapsula_Resource?") &&
      !error.config?.ignoreImperva
    ) {
      isImperva = true;
      await handleImpervaResponse(error.response.data, "md");
      await isImpervaComplete();
    }
    return Promise.reject({isImperva, ...error});
  },
);

/**
 *
 * @param {*} customConfig
 * @param {Function} callBack
 * @param {Function=} errorCallback
 * @param {*} response
 * @returns
 */
export default async function GAxios(
  customConfig,
  callBack,
  errorCallback = null,
  response = null,
) {
  const store = await getStore();
  const {ignoreTips, ignoreErrors, url, userId, token, activity} = customConfig;

  if (response) {
    if (!url?.endsWith("s/logout")) {
      await handle401(response); //only handle the 401 logout.
    }
    if (!ignoreErrors) {
      await handle429(response); //only handle the 429.
    }
    await handle423(response);

    return;
  }

  const state = store?.getState();
  const reqUserId = userId || state?.auth?.session?.userinfo?._id;
  const reqToken = token || state?.auth?.session?.userinfo?.token;

  let xAuth = !reqUserId
    ? `{"user": null, "token": null}`
    : `{"user": "${reqUserId}", "token": "${reqToken}"}`;
  let headers = {
    "Content-Type": "application/json",
    "x-app-auth": xAuth,
    lan: getLang(),
    ver: WEB_VERSION,
  };

  const initConfig = {
    method: "get",
    headers: headers,
    timeout: 20000,
  };

  const config = {...initConfig, ...customConfig};
  headers = {...headers, ...customConfig.headers};

  config.headers = headers;

  axiosRetry(axios, {
    retries: 3,
    retryDelay: (retryNumber = 0) => {
      return Math.pow(2, retryNumber) * 1000;
    },
  });

  try {
    await axios(config)
      .then((res) => {
        if (res === -1) {
          if (window._impervaIgnore) {
            if (Date.now() - window._impervaIgnore > 2000) {
              delete window._impervaIgnore;
            } else {
              return false;
            }
          }
          return GAxios(customConfig, callBack, errorCallback, response);
        } else {
          return callBack && callBack(res);
        }
      })
      .catch((err) => {
        handleSessionExpired(err);
        const res = err?.response;
        if (res) {
          if (ignoreErrors || err.isImperva) {
            errorCallback && errorCallback(err);

            return false;
          }

          handle401(res, userId);

          if (ignoreTips?.t429 !== true && !ignoreErrors) {
            handle429(res);
          }
          handle423(res);

          if (errorCallback) {
            errorCallback(err);
          } else if (
            res?.status === 401 &&
            res.data?.error?.code === "E_BAD_TOKEN"
          ) {
            return false;
          } else {
            // Don't use error.emsg, use error.code uniformly
            handleErrorCodes(err);
            return false;
          }
        } else {
          activity && callBack && callBack(res);
          !ignoreErrors &&
            showToast("warning", t("getter_fe.auth.common.netWorkerror"));
        }
      });
  } catch (error) {
    console.error(error);
    if (errorCallback) {
      errorCallback(error);
    } else {
      handleErrorCodes(error);
    }
  }
}
