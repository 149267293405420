import {Dialog as MUIDialog, createStyles, makeStyles} from "@material-ui/core";
import React, {useEffect} from "react";
import clsx from "clsx";

const useStyles = makeStyles(({palette, spacing, breakpoints}) =>
  createStyles({
    backdrop: {
      backgroundColor: "transparent",
    },
    paperScrollPaper: {
      maxHeight: "100vh",
    },
    scrollPaper: {
      alignItems: "center",
      "&.top": {
        alignItems: "flex-start",
      },
      "&.popup-overlay": {
        backgroundColor: palette.background.popupOverlay3,
      },
    },
    paper: {
      backgroundColor: palette.colors_v2.modalWindowBg,
      boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.10)",
      borderRadius: spacing(1.25),
      overflow: "visible",
      padding: "0 18px",
      "& .MuiDialogContent-root": {
        padding: 0,
        "&::-webkit-scrollbar": {
          width: spacing(1),
          height: spacing(1),
          borderRadius: spacing(1.875),
        },
        "&::-webkit-scrollbar-thumb": {
          background: palette.colors_v2.element[60],
          borderRadius: spacing(1.875),
          height: spacing(15.5),
        },
      },
      [breakpoints.down("xs")]: {
        borderRadius: 0,
      },
    },
  }),
);

export const Popup = ({
  // trigger,
  // modal,
  overlayStyle,
  classes = {},
  className,
  contentStyle,
  open,
  closeOnDocumentClick,
  closeOnEscape,
  lockScroll,
  onOpen,
  onClose,
  position,
  children,
  ...restProps
}) => {
  const styles = useStyles();
  const childrenContent =
    typeof children === "function"
      ? children(() => {
          if (lockScroll) {
            document.body.style.overflow = "auto";
            document.body.style.removeProperty("padding-right");
          }
          onClose && onClose();
        })
      : children;

  useEffect(() => {
    if (open && onOpen) {
      onOpen();
    }
  }, [open]);

  return (
    <MUIDialog
      style={overlayStyle}
      open={open}
      fullWidth
      onClose={(_, reason) => {
        if (!closeOnDocumentClick && reason === "backdropClick") {
          return;
        }
        if (lockScroll) {
          document.body.style.overflow = "auto";
          document.body.style.removeProperty("padding-right");
        }
        onClose && onClose();
      }}
      disableEscapeKeyDown={!closeOnEscape}
      disableScrollLock={!lockScroll}
      classes={{
        ...classes,
        container: clsx(
          classes?.container,
          "popup-overlay",
          `${className}-overlay`,
        ),
        paper: clsx(
          styles.paper,
          classes?.paper,
          "popup-content",
          `${className}-content`,
        ),
        paperScrollPaper: clsx(styles.paperScrollPaper),
        scrollPaper: clsx(styles.scrollPaper, position),
      }}
      BackdropProps={{
        classes: {
          root: styles.backdrop,
        },
      }}
      PaperProps={{
        style: contentStyle,
        onClick: (e) => {
          e.stopPropagation();
        },
      }}
      {...restProps}
    >
      {childrenContent}
    </MUIDialog>
  );
};
