import GAxios from "src/util/GAxios";
import {CHAT_API} from "./index";

/**
 * @param {Array} targets
 * @param {string} channelId group chat id
 */
export const addGroupAdmin = async ({targets, channelId}) => {
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/${CHAT_API}/channel/group/admins/add`,
    data: {
      content: {
        targets,
        channel_id: channelId,
      },
    },
    ignoreErrors: true,
  };

  let res = null;

  await GAxios(
    config,
    (response) => {
      if (response?.data?.result) {
        res = response.data.result;
      }
    },
    (error) => {
      res = error;
    },
  );

  return res;
};
