import {
  isResponseOk,
  parseItemStats,
  parseGTokFeed,
  parseUser,
} from "src/util/FeedUtils";
import GAxios from "src/util/GAxios";
import {timelineConstants} from "src/app/components/timeline/_constants/timeline.constants";
import {SEARCH_APIS} from "src/app/components/search/_constant";
import {convertSearchFilters} from "../../SearchFilter/utils";
import {updateGTok} from "../store";
import {setPostsStats} from "src/store/modules/postStats";

export const getSearchGTok = async (
  data,
  {getState, dispatch},
  errorCallback,
  api = SEARCH_APIS.POST,
) => {
  const q = data.q;
  const {vision} = getState?.() ?? {};
  const {cursor = 0, postV1Cursor = 0} = vision ?? {};
  let resp = null;
  const filter = convertSearchFilters(data);
  try {
    const data = {
      incl: "posts|postinfo|stats|userinfo|visions|shared|liked",
      q,
      filter,
      offset: 0,
      max: timelineConstants.MAX_BATCH_SIZE,
    };
    if (api === SEARCH_APIS.POST && cursor) data.cursor = cursor;
    if (api === SEARCH_APIS.POST_V1 && postV1Cursor) data.cursor = postV1Cursor;

    await GAxios(
      {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}${api}`,
        data: {
          content: data,
        },
        ignoreErrors: api === SEARCH_APIS.POST,
      },
      (res) => {
        if (isResponseOk(res)) {
          const {aux, data} = res?.data?.result ?? {};
          const {list} = data ?? {};
          const {lks: likedPosts, removed, shrs: sharedPosts} = aux ?? {};
          const users = parseUser(aux);

          let posts = parseGTokFeed(list, aux);

          let count = posts?.length ?? 0;
          posts = posts?.filter((post) => post != null);
          count = count - (posts?.length ?? 0);
          const postStats = parseItemStats(aux);

          resp = {
            likedPosts,
            posts,
            removed: removed + count,
            users,
            sharedPosts,
            postStats,
            ...(api === SEARCH_APIS.POST && {cursor: aux?.cursor || 0}),
            ...(api === SEARCH_APIS.POST_V1 && {
              postV1Cursor: aux?.cursor || 0,
            }),
            params: q,
          };
        }
      },
      async (err) => {
        if (api === SEARCH_APIS.POST)
          await getSearchGTok(
            data,
            {getState, dispatch},
            errorCallback,
            SEARCH_APIS.POST_V1,
          );

        if (api === SEARCH_APIS.POST_V1 && errorCallback) errorCallback(err);
      },
    );
  } catch (error) {
    console.error(error);
  }

  const {postStats = {}} = resp ?? {};
  dispatch(updateGTok(resp));
  postStats && dispatch(setPostsStats(postStats));

  return resp;
};
