import GAxios from "src/util/GAxios";
import {CHAT_API} from "../index";

// ${CHAT_API}/channel/group/link/join   POST    "content": {     "code": "adfa2323fafd" }

export const postLinkJoinByInviteCode = async (invitation_code) => {
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/${CHAT_API}/channel/group/link/join`,
    data: {
      content: {
        code: invitation_code,
      },
    },
    ignoreErrors: true,
  };

  let res = null;

  await GAxios(
    config,
    (response) => {
      if (response?.data?.result) {
        res = response.data.result;
      }
    },
    (error) => {
      res = error;
    },
  );

  return res;
};
