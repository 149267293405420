import GAxios from "src/util/GAxios";

export const getAdsConfig = async () => {
  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/g/configs/v2?types=adconfs`,
  };

  let res = null;

  await GAxios(
    config,
    (response) => {
      if (response?.data?.result?.adconfs) {
        res = response.data.result.adconfs;
        delete res?.gam_app_units;
      }
    },
    (response) => {
      res = response;
    },
  );

  return res;
};
