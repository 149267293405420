import {subDays, subHours, subMonths} from "date-fns";

export const FILTER_TIME_TYPE = {
  ANY: "any",
  LAST_24_HOURS: "last_24_hours",
  LAST_7_DAYS: "last_7_days",
  LAST_6_MONTHS: "last_6_months",
};

export const FILTER_SORT_TYPE = {
  RELEVANCE: "relevance",
  TIME: "time",
  LIKES: "likes",
};

export const FILTER_TIME_KEY = "post_date";

export const FILTER_SORT_KEY = "sort";

export const convertSearchFilters = (searchFilters) => {
  const filters = {};
  const time = searchFilters[FILTER_TIME_KEY];
  const timestamp = new Date().valueOf();
  switch (time) {
    case FILTER_TIME_TYPE.LAST_24_HOURS:
      filters.cdate_start = subHours(timestamp, 24).valueOf();
      break;
    case FILTER_TIME_TYPE.LAST_7_DAYS:
      filters.cdate_start = subDays(timestamp, 7).valueOf();
      break;
    case FILTER_TIME_TYPE.LAST_6_MONTHS:
      filters.cdate_start = subMonths(timestamp, 6).valueOf();
      break;
    default:
      break;
  }
  if (filters.cdate_start) {
    filters.cdate_end = timestamp;
  }
  const sort = searchFilters[FILTER_SORT_KEY];
  switch (sort) {
    case FILTER_SORT_TYPE.LIKES:
      filters.sort = FILTER_SORT_TYPE.LIKES;
      break;
    case FILTER_SORT_TYPE.TIME:
      filters.sort = FILTER_SORT_TYPE.TIME;
      break;
    default:
      break;
  }
  return Object.keys(filters).length ? filters : undefined;
};
