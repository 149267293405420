// ----------------------- Application Specific Constants -------------------------

export const APP_NAME = "GETTR";
export const APP_WEB_NAME = "GETTR";

export const style_vlist_nobullet = {
  listStyleType: "none",
};

export const style_tr_align_top = {
  alignItems: "top",
};

/**
 * Constants. PROP_ for properties, STATE_ for states, etc.
 */
export class AppConsts {
  static get APP_NAME() {
    return APP_NAME;
  }
  static get APP_WEB_NAME() {
    return APP_WEB_NAME;
  }
  static get COMPOSER_MAX_TEXT_LENGTH() {
    return 777;
  }
  static get DM_INPUT_MAX_TEXT_LENGTH() {
    return 4999;
  }
  static get MEMBERS_SIZE_PER_BATCH() {
    return 30;
  }
  static get FRONT_END_FILTER_MAX_MEMBERS_SIZE() {
    return 90;
  }
  static get DM_MENTION_NUMBER_LIMIT() {
    return 25;
  }
  // static get USE_FORCE_LOGIN() {
  //   return false;
  // }
  // static get ANONYMOUS_FORCE_LOGIN() {
  //   return 100;
  // }
  static get URL_LANDING() {
    return "/onboarding";
  }
  static get URL_ONBOARDING() {
    return "/landing";
  }
  static get URL_LIVENOW() {
    return "/livenow";
  }
  static get URL_VISION() {
    return "/gtok";
  }

  static get URL_VISION_WATCH() {
    return "/gtok/";
  }

  static get URL_SIGNUP() {
    return "/signup";
  }
  static get URL_SIGNUPBUTTONS() {
    return "/signup?step_buttons";
  }
  static get URL_LOGIN() {
    return "/login";
  }
  static get URL_LOGOUT() {
    return "/logout";
  }
  static get URL_CLAIM() {
    return "/claim";
  }
  static get URL_CHANGE_PASSWORD() {
    return "/chgpwd";
  }
  static get URL_HOME() {
    return "/";
  }
  static get URL_DASHBOARD() {
    return "/";
  }
  static get URL_SEARCH_RESULT() {
    return "/search";
  }
  static get URL_HASHTAG() {
    return "/hashtag";
  }
  static get URL_SOCIAL_GOOGLE_CALLBACK() {
    return "/social/google/callback";
  }
  static get URL_SOCIAL_APPLE_CALLBACK() {
    return "/social/apple/callback";
  }
  static get URL_SOCIAL_FACEBOOK_CALLBACK() {
    return "/social/facebook/callback";
  }
  static get URL_SOCIAL_AUTH0_CALLBACK() {
    return "/oauth2/idpresponse";
  }
  static get URL_RESET_PASSWORD() {
    return "/reset-password";
  }
  static get URL_UNKNOWN_USER() {
    return "";
  }
  static get URL_SETTINGS_PRIVACY() {
    return "privacy";
  }
  static get URL_SETTINGS_INTEREST() {
    return "interest";
  }
  static get URL_SETTINGS_MUTEBLOCK() {
    return "mute-block";
  }
  static get URL_SETTINGS_DIRECT_MESSAGE() {
    return "direct-message";
  }
  static get URL_SETTINGS_NOTIFICATIONS() {
    return "notifications";
  }
  static get URL_SETTINGS_DISPLAY() {
    return "display";
  }
  static get URL_SETTINGS_CONNECT_ACCOUNT() {
    return "connect-account";
  }
  static get URL_SETTINGS_PREMIUM() {
    return "premium";
  }

  static get URL_SETTINGS_DELETE_ACCOUNT() {
    return "delete-account";
  }
  static get URL_SETTINGS_CHANGE_PASSWORD() {
    return "change-password";
  }
  static get URL_SETTINGS_CHANGE_USERNAME() {
    return "change-username";
  }
  static get URL_SETTINGS_INTERFACE_LANGUAGE() {
    return "interface-language";
  }
  static get URL_SETTINGS_MY_ACCOUNT() {
    return "my-account";
  }
  static get URL_SETTINGS_EMAIL() {
    return "email";
  }
  static get URL_SETTINGS_PHONE() {
    return "phone";
  }
  static get URL_SETTINGS_MOBILE_INDEX() {
    return "mobile";
  }
  static get URL_HELPCENTER_PRIVACYPOLICY() {
    return "privacy-policy";
  }
  static get URL_HELPCENTER_TERMSOFUSER() {
    return "terms-of-user";
  }
  static get URL_HELPCENTER_COINTERMS() {
    return "coin-terms";
  }
  static get URL_HELPCENTER_TIPPINGTERMS() {
    return "tipping-terms";
  }
  static get URL_HELPCENTER_PREMIUM() {
    return "premium-terms";
  }
  static get URL_HELPCENTER_LEGAL() {
    return "legal";
  }
  static get URL_HELPCENTER_DISCLAIMER() {
    return "disclaimer";
  }
  static get URL_HELPCENTER_COMMUNITYGUIDELINES() {
    return "community-guidelines";
  }
  static get URL_SHARE() {
    return "share";
  }
  static get URL_NOTIFICATIONS() {
    return "/notifications";
  }
  static get URL_CHAT() {
    return "/messages";
  }
  static get URL_PRIVACY_APP() {
    return "/privacy-app";
  }
  static get URL_TERMS_APP() {
    return "/terms-app";
  }

  static get INVITE_LINK() {
    return "https://gettr.com/aq8jiyz9";
  }
  static get URL_COIN_TERMS_APP() {
    return "/coin-terms-app";
  }
  static get URL_PEP_POP_APP() {
    return "/pep-popup-app";
  }
  static get URL_STREAMING() {
    return "/streaming";
  }
  static get URL_STREAMHOSTSIDE() {
    return "/streamHostSide";
  }
  static get TYPE_SEARCH_DELAY_MS() {
    return 350;
  }
  static get LOCAL_STORAGE_RECENT_SEARCH() {
    return "localStorageRecentSearch";
  }
  static get LOCAL_STORAGE_FIRST_TIME_VISIT() {
    return "FIRST_TIME_VISIT";
  }
  static get LOCAL_STORAGE_LAST_BROWSER_LANG() {
    return "LAST_BROWSER_LANG";
  }
  static get LOCAL_STORAGE_REDIRECT_AFTER_LOGIN() {
    return "REDIRECT_AFTER_LOGIN";
  }

  static LOCAL_STORAGE_POST_TRANSLATION_TARGET_LANG_CODE(userId) {
    return `POST_TRANSLATION_TARGET_LANG_CODE_${userId || "GUEST"}`;
  }

  static LOCAL_STORAGE_POST_TRANSLATION_RECENT_TARGET_LANG_CODE(userId) {
    return `POST_TRANSLATION_RECENT_TARGET_LANG_CODES_${userId || "GUEST"}`;
  }

  static get DESCRIPTION_MAX_CHARACT() {
    return 147;
  }

  static get USER_LIST_MUTED() {
    return "muted";
  }

  static get USER_LIST_BLOCKED() {
    return "blocked";
  }

  static get USER_LIST_FOLLOW() {
    return "follow";
  }

  static get NOTIF_SUCCESS() {
    return "success";
  }

  static get NOTIF_ERROR() {
    return "error";
  }

  static get NOTIF_WARNING() {
    return "warning";
  }

  static get NOTIF_MESSAGE_SUCCESS() {
    return "notif_message_success";
  }

  static get NOTIF_MESSAGE_ERROR() {
    return "notif_message_error";
  }

  static get NOTIF_MESSAGE_LIVE_END() {
    return "notif_message_live_end";
  }

  static get VISION_SOURCE_TIMELINE() {
    return "v_source_timeline";
  }

  static get VISION_SOURCE_FOLLOWED() {
    return "v_source_followed";
  }

  static get VISION_SOURCE_EXPLORE() {
    return "v_source_explore";
  }

  static get VISION_SOURCE_PROFILE() {
    return "v_source_profile";
  }

  static get VISION_SOURCE_SEARCH() {
    return "v_source_search";
  }

  static get VISION_SOURCE_TRACK() {
    return "v_source_track";
  }

  static get VISION_SOURCE_LEFT_SIDE_BAR() {
    return "v_source_left_side_bar";
  }

  static get VISION_SOURCE_TIMELINE_SLIDER() {
    return "v_source_timeline_slider";
  }

  static get VISION_LOAD_MORE_CUTOFF_SIZE() {
    return 3;
  }

  // -------------------------- STATUS -------------------------------------
  static get STATUS_SERVER_ERROR() {
    return "server_error";
  }

  static get STATUS_USER_EXPRESS() {
    return "es";
  }

  static get STATUS_USER_NORMAL() {
    return ["a", "qs", "ui", "uv", "rv"];
  }

  static get STATUS_USER_ABMORMAL() {
    return ["s", "d", "da", "uk"];
  }

  // -------------------------- LIMIT -------------------------------------
  static get LIMIT_MENTION_DAYS_TIME() {
    return 30 * 24 * 60 * 60 * 1000;
  }

  static get LIMIT_MENTION_LESS_TIMES() {
    return 5;
  }

  static get LIMIT_MENTION_MORE_TIMES() {
    return 10;
  }

  static get LIMIT_MENTION_MAX_TIMES() {
    return 30;
  }

  static get MASSIVE_GROUP_LIMIT() {
    return 50000;
  }

  static get URL_SETTINGS_GETTR_PAY() {
    return "pay";
  }

  // system account
  static get SYSTEM_ACCOUNT() {
    return "SYSTEM";
  }

  static get LOCAL_DM_DRAFT() {
    return "LOCAL_DM_DRAFT";
  }
}

export class UIStyleConsts {
  static get APP_PAGE() {
    return "app-page";
  }
  static get DASHBOARD() {
    return "dashboard";
  }
  static get DASHBOARD_COLUMN() {
    return "dashboard-column";
  }
  static get DASHBOARD_COLUMN_OVERFLOW() {
    return "column-overflow";
  }
  static get DASHBOARD_COLUMN_HEADER() {
    return "column-header";
  }
  static get DASHBOARD_PRIMARY_COLUMN() {
    return "primary-column";
  }
  static get DASHBOARD_SECONDARY_COLUMN() {
    return "secondary-column";
  }

  static get DROPDOWN_MENU_ITEM() {
    return "menu-item";
  }

  static get POST_FEED_ITEM_BUTTON() {
    return "post-feed-item-button";
  }

  static get REPOST_FEED_ITEM_BUTTON() {
    return "repost-feed-item-button";
  }

  static get SHARE_FEED_ITEM_BUTTON() {
    return "share-feed-item-button";
  }

  static get BUTTON_PILL() {
    return "button--pill panel-view__button";
  }
  static get BUTTON_COLOR_PILL() {
    return "button--color-pill panel-view__button";
  }
  static get BUTTON_PANEL() {
    return "button panel-view__button";
  }
  static get BUTTON_LINK() {
    return "cbutton button--link";
  }
  static get BUTTON_PANEL_DISABLED() {
    return "button-disabled panel-view__button";
  }

  static get MBUTTON_ACTION() {
    return "mbutton panel-view__mbutton";
  }
  static get MBUTTON_ACTION_DISABLED() {
    return "mbutton-disabled panel-view__mbutton";
  }
  static get MBUTTON_PANEL() {
    return "mbutton panel-view__mbutton";
  }
  static get MBUTTON_PANEL_DISABLED() {
    return "mbutton-disabled panel-view__mbutton";
  }

  static get SBUTTON_ACTION() {
    return "mbutton panel-view__sbutton";
  }
  static get SBUTTON_ACTION_DISABLED() {
    return "mbutton-disabled panel-view__sbutton";
  }

  static get INPUT_LABEL() {
    return "panel-view__inputLabel";
  }
  static get INPUT_COMP() {
    return "panel-view__input";
  }
  static get CHECKBOX_LABEL() {
    return "panel-view__checkboxLabel";
  }
  static get CHECKBOX_COMP() {
    return "panel-view__checkbox";
  }

  static get INPUT_SIZE_PASSWORD() {
    return "25";
  }
  static get STREAMING_SCENE() {
    return "streaming";
  }

  static get VISION_COMMENT() {
    return "vision-comment";
  }
}

// --------------------------- LABEL IDs --------------------------------

export const LABEL_WEBSITE = "WWW";
export const LABEL_APPNAME = "APPN";
export const LABEL_HOMEPAGE = "HMPG";
export const LABEL_ABOUT_US = "ABUS";
export const LABEL_TOS = "TOS";
export const LABEL_PRIVACY_POLICY = "PRVP";
export const LABEL_FEEDBACK = "FDBK";

export const LABEL_SUBMIT_CHANGE = "SBCH";

export const LABEL_EMAIL_ADDRESS = "EMLADR";
// export const LABEL_USERNAME = "USRN";
export const LABEL_DISPLAYNAME = "DSPN";

export const LABEL_EMAIL_ENTER = "INPEML";
export const LABEL_DISPLAYNAME_ENTER = "INPDSN";
// export const LABEL_USERNAME_ENTER = "INPUSN";
// export const LABEL_PASSWORD_ENTER = "INPPWD";
// export const LABEL_PASSWORD2_ENTER = "INPPW2";

export const LABEL_SELECT = "SEL";
export const LABEL_DELETE = "DEL";
export const LABEL_EDIT = "EDT";
export const LABEL_CANCEL = "CAN";
export const LABEL_CLONE = "CLN";
export const LABEL_CLOSE = "CLS";
export const LABEL_RESET = "RST";
export const LABEL_RETURN = "RET";
export const LABEL_SUBMIT_POST = "PST";
export const LABEL_SUBMIT_REPOST = "RPST";
export const LABEL_SUBMIT_COMMENT = "SCM";

export const LABEL_HOME = "HM";
export const LABEL_EXPLORE = "EPLR";
export const LABEL_NOTIFICATIONS = "NTFCT";
export const LABEL_MESSAGES = "MSG";
export const LABEL_PROFILE = "PF";

// --------------------------- MESSAGE IDs ------------------------------

// export const MSG_PASSWORD_OK = "M0101";

// export const MSG_PASSWORD_NOT_VALID = "E0101";
// export const MSG_PASSWORD_NOT_MATCHED = "E0102";
export const MSG_NICKNAME_NOT_VALID = "E0103";
export const MSG_EMAIL_NOT_VALID = "E0104";
export const MSG_EMAIL_NONE = "E0105";
export const MSG_INVITE_CODE_INVALID = "E0106";
export const MSG_INVITE_CODE_VERIFIED = "E0107";
// export const MSG_USERNAME_EXISTS = "E0108";
// export const MSG_USERNAME_AVAILABLE = "E0109";
// export const MSG_USERNAME_CHECK_ERROR = "E0110";
// export const MSG_USERNAME_NOT_VALID = "E0111";

export const MSG_CREATE_POST_LABEL = "M0112";

export const UIStyle = UIStyleConsts;

// copied from XMFollow
export const STATUS_FOLLOWING = "y"; // accepted
export const STATUS_NOT_FOLLOWING = "n"; // not accepted
export const STATUS_PENDING = "p";
export const STATUS_BLOCKED = "b";
export const STATUS_UNKNOWN = "u";

// copied from ErrorConsts
export const USER_MAX_LETTERS_NUMBER = 30;
export const USER_MIN_LETTERS_NUMBER = 5;
export const USER_LOGIN_MIN_LETTERS_NUMBER = 2;
export const LINK_MIN_LETTERS = 10;
export const LINK_MAX_LETTERS = 50;
export const USER_MAX_HOBBY_NUMBER = 50;

// copied from XMShare
export const STATUS_SHARED = "y";

// copied from ModelConsts
const ACTIVITY_USER_PUB_POST = "pub_pst";
const ACTIVITY_SHARES_POST = "shares_pst";
const ACTIVITY_POST_SHAREDBY = "pst_sharedby";
const ACTIVITY_POST_PUBLISHED = "pub_pst_by";
const ACTIVITY_SHARES_COMMENT = "shares_cm";
const ACTIVITY_POST_PINNEDBY = "pst_pinned_by";
export class ActivityLogProps {
  static get USER_PUB_POST() {
    return ACTIVITY_USER_PUB_POST;
  }
  static get SHARES_POST() {
    return ACTIVITY_SHARES_POST;
  }
  static get POST_SHAREDBY() {
    return ACTIVITY_POST_SHAREDBY;
  }
  static get POST_PUBLISHEDBY() {
    return ACTIVITY_POST_PUBLISHED;
  }
  static get SHARES_COMMENT() {
    return ACTIVITY_SHARES_COMMENT;
  }
  static get POST_PINNEDBY() {
    return ACTIVITY_POST_PINNEDBY;
  }
}
export class LanguageCodes {
  static get DEFAULT() {
    return "en_us";
  }
  static get ENGLISH() {
    return "en_us";
  }
  static get CHINESE_SIMPLIFIED() {
    return "zh_cn";
  }
  static get CHINESE_TRADITIONAL() {
    return "zh_hant_tw";
  }
  static get SPANISH() {
    return "es_es";
  }
  static get GERMAN() {
    return "de_de";
  }
  static get JAPANESE() {
    return "ja_jp";
  }
  static get HINDI() {
    return "hi_in";
  }
  static get PORTUGUESE_BRAZIL() {
    return "pt_br";
  }
  static get CHINESE_ALL() {
    return ["zh", "tw"];
  }
}

// copied from XMUserFeedback
export const TOPIC_BUG = "bug";
export const TOPIC_FEATURE = "fea";
export const TOPIC_USABILITY = "use";
export const TOPIC_INQUIRY = "info";
export const TOPIC_OTHER = "other";

// copied from ErrorConsts
// export const PASS_NONE = "E_PWD_NONE";

// Scene names
export const SCENE_VISION = "vision";
export const SCENE_VISION_EXPLORE = "vision_explore";
export const SCENE_VISION_FOLLOWED = "vision_following";
export const SCENE_VISION_WATCH = "vision_watch";

export default AppConsts;

// gettrPay
export const SHOW_TOOLTIP_MINIMUM_BALANCE = 10000;

// tipping consts
export const USER_MAX_TIPPING_AMOUNT = 100;
export const USER_MIN_TIPPING_AMOUNT = 1;

// thread lastest messages ids
export const THREAD_LASTEST_MESSAGES = "THREAD_LASTEST_MESSAGES";
export const STICKER_PRICE_DIVISOR = 100000;

// indent unit in diffrent device
export const INDENT_UNIT_PC = 2;
export const INDENT_UNIT_MOBILE = 1.05;
