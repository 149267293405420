import GAxios from "src/util/GAxios";

export const aiReduceText = async ({text, scope}) => {
  if (!text) return;

  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/copilot/v1/reduce-text`,
    data: {text},
    timeout: 60000,
  };

  let res = {response: null, err: null};

  await GAxios(
    config,
    (response) => {
      if (response?.data?.result) {
        res = {
          text,
          outputText: response?.data?.result?.outputText,
          scope,
          result: response?.data?.result,
        };
      }
    },
    (err) => {
      res = {
        error: err?.response?.data?.error,
      };
    },
  );

  return res;
};
