import GAxios from "src/util/GAxios";

export const reportUser = async (data) => {
  const {userId, userIdTarget, reasonId, callback} = data;

  if (!userId || !userIdTarget || !reasonId) return;

  const reason = `rsn${reasonId}`;

  const config = {
    ignoreTips: {t429: true},
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/u/user/${userId}/report/user/${userIdTarget}/${reason}`,
  };

  await GAxios(config, (response) => callback?.({status: response?.status}));
};
