import GAxios from "src/util/GAxios";

export const submitIdentification = async (data) => {
  if (!data) return;
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/gid/identification`,
    ignoreErrors: true,
    data: {
      content: {
        ...data,
      },
    },
  };

  let res = null;

  await GAxios(config, (response) => {
    if (response?.data?.result) {
      res = response.data.result;
    } else {
      res = null;
    }
  });

  return res;
};
