import {createAction, createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {StickerEmojiSettingApi} from "../api";
import {uniqBy, remove, unionBy, filter, findIndex} from "lodash-es";

const NS = "stickerEmojiSetting";

const initialState = {
  open: false,
  config: {},
};

export const fetchConfig = createAsyncThunk(
  `${NS}/fetchConfig`,
  StickerEmojiSettingApi.fetchConfig,
);
export const updateConfig = createAsyncThunk(
  `${NS}/updateConfig`,
  StickerEmojiSettingApi.updateConfig,
);
export const toggleOpen = createAction(`${NS}/toggleOpen`);

export const stickerEmojiSettingSlice = createSlice({
  name: NS,
  initialState,
  reducers: {
    toggleOpen: (state, {payload}) => {
      state.open = payload;
    },
  },
  extraReducers: (builder) => {
    // all contacts except streaming ones
    builder.addCase(fetchConfig.pending, (state) => {
      state.config.isLoading = true;
    });
    builder.addCase(fetchConfig.fulfilled, (state, {payload}) => {
      const {preview_enabled, suggestion_enabled} = payload?.result || {};
      state.config = {preview_enabled, suggestion_enabled};
    });
    builder.addCase(fetchConfig.rejected, (state, {payload}) => {
      state.config.isLoading = false;
    });
    builder.addCase(updateConfig.pending, (state) => {
      state.config.isLoading = true;
    });
    builder.addCase(updateConfig.fulfilled, (state, {payload}) => {
      const {preview_enabled, suggestion_enabled} = payload;
      state.config = {preview_enabled, suggestion_enabled};
    });
    builder.addCase(updateConfig.rejected, (state, {payload}) => {
      state.config.isLoading = false;
    });
  },
});
