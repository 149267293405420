import {
  isResponseOk,
  parseItemStats,
  parseTimelineFeed,
  parseUser,
} from "src/util/FeedUtils";
import GAxios from "src/util/GAxios";
import {timelineConstants} from "../_constants";

export const getAnonFeed = ({lang, max = 30, type}, {getState}) => {
  const {timelineNew} = getState() ?? {};
  const {offset} = timelineNew?.anonFeed ?? {};
  const timeout = 30000;

  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      url:
        type === "trending"
          ? `${process.env.REACT_APP_API_URL}/u/posts/trends`
          : `${process.env.REACT_APP_API_URL}/u/user/v2/timeline/chillmode`,
      params: {
        lang,
        offset,
        max,
        incl: "posts|stats|userinfo|shared|liked",
      },
      timeout: timeout,
    };
    GAxios(
      config,
      (res) => {
        if (isResponseOk(res)) {
          const {aux, data} = res?.data?.result ?? {};
          const {list} = data ?? {};
          const {lks: likedPosts, removed, shrs: sharedPosts} = aux ?? {};
          const users = parseUser(aux);
          let posts = parseTimelineFeed(list, aux, "trending");
          let count = posts?.length ?? 0;
          count = count - (posts?.length ?? 0);
          const postStats = parseItemStats(aux);

          return resolve({
            likedPosts,
            posts,
            removed: removed + count,
            users,
            sharedPosts,
            offset: offset + timelineConstants.MAX_BATCH_SIZE,
            postStats,
            type,
          });
        }

        return resolve(null);
      },
      (err) => {
        return reject({err, type});
      },
    );
  });
};
