import {createSlice} from "@reduxjs/toolkit";
import {INIT_GUIDE_SHOW_TIMES, USER_GUIDES_CACHE_KEY} from "./_constants";

const NS = "userGuides";

const getUserGuideData = () => {
  try {
    const userGuidesData =
      JSON.parse(localStorage.getItem(USER_GUIDES_CACHE_KEY)) || {};
    return userGuidesData;
  } catch (error) {
    return {};
  }
};

const initialState = getUserGuideData();

export const userGuidesSlice = createSlice({
  name: NS,
  initialState,
  reducers: {
    setUserGuide: (state, {payload}) => {
      if (!payload?.ignoreState && payload?.type)
        state[payload.type] = payload?.value || INIT_GUIDE_SHOW_TIMES;
      localStorage.setItem(
        USER_GUIDES_CACHE_KEY,
        JSON.stringify({
          ...state,
          [payload.type]: payload?.value || INIT_GUIDE_SHOW_TIMES,
        }),
      );
    },
  },
  extraReducers: (builder) => {},
});

export const {setUserGuide} = userGuidesSlice.actions;
