import GAxios from "src/util/GAxios";

export const deleteNotification = (notificationId) => {
  if (!notificationId) return;

  return new Promise((resolve, reject) => {
    const config = {
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}/u/notif/delete/${notificationId}`,
    };
    GAxios(
      config,
      (res) => {
        const {rc, result} = res?.data ?? {};

        if (rc === "OK") {
          return resolve(result);
        }
      },
      (err) => {
        return reject(err);
      },
    );
  });
};
