// ***********************************************************************
//
// This entry point seem to start from dynamic React server "start",
// and NOT from a pre-generated build.
//
// ***********************************************************************

import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import ResizeObserver from "resize-observer-polyfill";
import {store} from "./store";
import "./i18n";
import {App} from "./app/App";
import {MuiThemeProvider} from "src/app/base/UIContext";
import {GlobalStyles} from "src/styles/global";
import {StylesProvider} from "@material-ui/core/styles";
import HistoryRouter from "./libs/HistoryRouter/HistoryRouter";
import {LanguageProvider} from "src/contexts/LanguageContext/LanguageContext";
import "react-quill/dist/quill.snow.css";

import("./metrics");

if (!window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver;
}
/*eslint-env node*/
const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate;

renderMethod(
  <Provider store={store}>
    <HistoryRouter>
      <LanguageProvider>
        <MuiThemeProvider>
          <StylesProvider injectFirst>
            <GlobalStyles />
          </StylesProvider>
          <App />
        </MuiThemeProvider>
      </LanguageProvider>
    </HistoryRouter>
  </Provider>,
  document.getElementById("root"),
);
