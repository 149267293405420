import GAxios from "src/util/GAxios";

export const canBroadcastLive = async () => {
  const config = {
    method: "get",
    url: process.env.REACT_APP_API_URL + "/u/live/profile",
  };

  let res = false;

  await GAxios(
    config,
    (response) => {
      if (response?.data?.result) {
        res = true;
      } else {
        res = false;
      }
    },
    () => {},
  );

  return res;
};
