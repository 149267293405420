import GAxios from "src/util/GAxios";
import {CHAT_API} from "./index";

/**
 * {host}/v1/chat/channel/group?channel_id=***&includes=admins
 * @param {*} channel_id
 * @returns
 */
export const getGroupChatAdmin = async ({channel_id}) => {
  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/${CHAT_API}/channel/group`,
    params: {
      channel_id,
      includes: "admins",
    },
  };

  let res = null;

  await GAxios(
    config,
    (response) => {
      if (response?.data?.result) {
        res = response.data.result;
      }
    },
    (error) => {
      res = error?.response || null;
    },
  );

  return res;
};
