import {createAction, createSlice} from "@reduxjs/toolkit";
import {Host} from "../types";

const NS = "liveDashboard";

const initialState: {
  followedHosts: Host[];
  recommendedHosts: Host[];
  isFollowedHostsDialogOpen: boolean;
  isRecommendedHostsDialogOpen: boolean;
} = {
  followedHosts: [],
  recommendedHosts: [],
  isFollowedHostsDialogOpen: false,
  isRecommendedHostsDialogOpen: false,
};

export const liveDashboardSlice = createSlice({
  name: NS,
  initialState,
  reducers: {
    setFollowedHosts: (state, {payload}) => {
      state.followedHosts = payload;
    },
    removeFollowedHost: (state, {payload}) => {
      state.followedHosts = state.followedHosts.filter(
        (host) => host.userId !== payload,
      );
    },
    addFollowedHost: (state, {payload}) => {
      state.followedHosts = [...state.followedHosts, payload];
    },
    setRecommendedHosts: (state, {payload}) => {
      state.recommendedHosts = payload;
    },
    removeRecommendedHost: (state, {payload}) => {
      state.recommendedHosts = state.recommendedHosts.filter(
        (host) => host.userId !== payload,
      );
    },
    setFollowedHostsDialogOpen: (state, {payload}) => {
      state.isFollowedHostsDialogOpen = payload;
    },
    setRecommendedHostsDialogOpen: (state, {payload}) => {
      state.isRecommendedHostsDialogOpen = payload;
    },
  },
});

export const setFollowedHosts = createAction<Host[]>(`${NS}/setFollowedHosts`);
export const removeFollowedHost = createAction<string>(
  `${NS}/removeFollowedHost`,
);
export const addFollowedHost = createAction<Host>(`${NS}/addFollowedHost`);
export const setRecommendedHosts = createAction<Host[]>(
  `${NS}/setRecommendedHosts`,
);
export const removeRecommendedHost = createAction<string>(
  `${NS}/removeRecommendedHost`,
);
export const setFollowedHostsDialogOpen = createAction<boolean>(
  `${NS}/setFollowedHostsDialogOpen`,
);
export const setRecommendedHostsDialogOpen = createAction<boolean>(
  `${NS}/setRecommendedHostsDialogOpen`,
);
