import GAxios from "src/util/GAxios";

export const votePoll = async (data) => {
  if (!data) return;

  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/u/post/v2/vote`,
    data,
  };

  let res = {response: null, err: null};

  await GAxios(
    config,
    (response) => {
      if (response?.data?.result) {
        res.response = response.data.result;
      }
    },
    (err) => {
      res.err = true;
    },
  );

  return res;
};
