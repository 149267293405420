import {
  isResponseOk,
  parseItemStats,
  parseGTokFeed,
  parseUser,
} from "src/util/FeedUtils";
import GAxios from "src/util/GAxios";
import {timelineConstants} from "src/app/components/timeline/_constants/timeline.constants";
import {updateGTok} from "../store";
import {setPostsStats} from "src/store/modules/postStats";

const fetchProfileGTok = async (userId, cursor) => {
  let resp = null;

  if (!userId) {
    return null;
  }

  try {
    let params = {
      offset: 0,
      max: timelineConstants.MAX_BATCH_SIZE,
      dir: timelineConstants.DIRECTION_FORWARD,
      incl: "posts|postinfo|stats|userinfo|shared|liked",
      fp: "f_uv",
    };

    if (cursor) {
      params = {...params, cursor};
    }

    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/u/user/${userId}/posts`,
      params,
    };

    await GAxios(
      config,
      (res) => {
        if (isResponseOk(res)) {
          const {aux, data} = res?.data?.result ?? {};
          const {list} = data ?? {};
          const {lks: likedPosts, removed, shrs: sharedPosts} = aux ?? {};
          const users = parseUser(aux);

          let posts = parseGTokFeed(list, aux);

          let count = posts?.length ?? 0;
          posts = posts?.filter((post) => post != null);
          count = count - (posts?.length ?? 0);
          const postStats = parseItemStats(aux);

          resp = {
            likedPosts,
            posts,
            removed: removed + count,
            users,
            sharedPosts,
            postStats,
            cursor: aux?.cursor || 0,
            fallback: true,
            params: userId,
          };
        }
      },
      (err) => {
        console.error(err);
      },
    );
  } catch (error) {
    console.error(error);
  }

  return resp;
};

export const getProfileGTok = async (
  data,
  {getState, dispatch},
  switcher = {},
) => {
  const {vision} = getState?.() ?? {};
  const {cursor = 0, params: prevUserId} = vision ?? {};
  const currentUserId = data?.userId ?? prevUserId;
  const {autoCompletion = true} = switcher;

  if (!autoCompletion) {
    const res = (await fetchProfileGTok(currentUserId, cursor)) ?? {};
    res?.postStats && dispatch(setPostsStats(res?.postStats));
    return res;
  }

  let totalPosts = [];
  let currentCursor = cursor;
  let firstFetch = true;
  while (
    firstFetch ||
    (currentCursor &&
      totalPosts?.length < timelineConstants?.AUTO_COMPLETION_SIZE)
  ) {
    firstFetch = false;
    const res = await fetchProfileGTok(currentUserId, currentCursor);
    const {posts = [], postStats = {}, cursor: resCursor} = res ?? {};
    dispatch(updateGTok(res));
    postStats && dispatch(setPostsStats(postStats));

    totalPosts = totalPosts?.concat(posts);
    currentCursor = resCursor;
  }
};
