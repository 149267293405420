import {has} from "lodash-es";
import GAxios from "src/util/GAxios";

export const turnedOffLiveNotification = async (
  {uname, tname},
  errorCallback,
) => {
  if (!uname || !tname) {
    return;
  }
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/u/notif/subscribe/status`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      content: {
        uname: uname,
        tnames: [tname],
      },
    }),
  };

  let res = false;

  await GAxios(
    config,
    (response) => {
      if (response && response.data?.result?.message === "success") {
        const data = response.data.result.data;
        if (has(data, tname)) {
          res = {tname, ...data[tname]};
        }
      }
    },
    (err) => {
      res = null;
      if (errorCallback) errorCallback(err);
    },
  );

  return res;
};
