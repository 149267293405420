import {createSlice, createAsyncThunk, createAction} from "@reduxjs/toolkit";
import {StickerV2Api} from "../api";
import {forEach, intersectionBy} from "lodash-es";
import {getStickerAndEmojiUrl} from "src/util/UrlUtil";

const NS = "stickerV2";

const initialState = {
  defaultStickers: {
    isLoading: false,
    list: [],
    category: [],
    allStickers: null,
  },
  recentStickers: {
    isLoading: false,
    list: [],
    category: [],
  },
  searchStickers: {
    open: false,
    keyword: "",
    isLoading: false,
    list: [],
  },
  headlessSearchStickers: {
    isLoading: false,
    list: [],
    keyword: "",
  },
  cacheSearchResult: {},
};

export const fetchDefault = createAsyncThunk(
  "stickerV2/fetchDefault",
  StickerV2Api.fetchDefault,
);
export const fetchRecent = createAsyncThunk(
  "stickerV2/fetchRecent",
  StickerV2Api.fetchRecent,
);
export const fetchSearch = createAsyncThunk(
  "stickerV2/fetchSearch",
  StickerV2Api.fetchSearch,
);
export const fetchHeadlessSearch = createAsyncThunk(
  "stickerV2/fetchHeadlessSearch",
  StickerV2Api.fetchSearch,
);
export const fetchUse = createAsyncThunk(
  "stickerV2/fetchUse",
  StickerV2Api.fetchUse,
);
export const toggleSearch = createAction(`${NS}/toggleSearch`);
export const setKeyword = createAction(`${NS}/setKeyword`);
export const clearHeadlessSearchStickers = createAction(
  `${NS}/clearHeadlessSearchStickers`,
);
export const setHeadlessKeyword = createAction(`${NS}/setHeadlessKeyword`);
export const clearSearchStickers = createAction(`${NS}/clearSearchStickers`);

export const stickerV2Slice = createSlice({
  name: NS,
  initialState,
  reducers: {
    toggleSearch: (state, {payload}) => {
      state.searchStickers.open = payload;
    },
    setKeyword: (state, {payload}) => {
      state.searchStickers.keyword = payload;
    },
    clearHeadlessSearchStickers: (state) => {
      state.headlessSearchStickers.list = [];
    },
    setHeadlessKeyword: (state, {payload}) => {
      state.headlessSearchStickers.keyword = payload;
    },
    clearSearchStickers: (state) => {
      state.searchStickers.list = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDefault.pending, (state) => {
      state.defaultStickers.isLoading = true;
    });
    builder.addCase(fetchDefault.fulfilled, (state, {payload}) => {
      const _list = [];
      const _category = [];
      let allStickers = [];
      forEach(
        payload?.response?.packets?.filter((item) => item.is_enabled),
        (packet) => {
          const _stickers = packet.stickers?.map(
            ({packet_id, sticker_id, width, height, hints}) => {
              return {
                image: {
                  pc: getStickerAndEmojiUrl({
                    packet_id,
                    sticker_id,
                    device: "pc",
                  }),
                  mobile: getStickerAndEmojiUrl({
                    packet_id,
                    sticker_id,
                    device: "mobile",
                  }),
                  thumb: getStickerAndEmojiUrl({
                    packet_id,
                    sticker_id,
                    device: "thumb",
                  }),
                },
                packet_id,
                sticker_id,
                hints,
                height,
                width,
              };
            },
          );
          _list.push({
            categoryName: packet.author_name.value,
            categoryFullName: packet.name.value,
            list: _stickers,
          });
          _category.push({
            ariaLabel: packet.name.value,
            author: packet.author_name.value,
            description: packet.description.value,
            type: packet.type,
            image: {
              pc: getStickerAndEmojiUrl({
                packet_id: packet.packet_id,
                type: "stickers",
                device: "pc",
              }),
              mobile: getStickerAndEmojiUrl({
                packet_id: packet.packet_id,
                type: "stickers",
                device: "mobile",
              }),
              thumb: getStickerAndEmojiUrl({
                packet_id: packet.packet_id,
                type: "stickers",
                device: "thumb",
              }),
            },
            version: packet.version,
            packet_id: packet.packet_id,
            is_enabled: packet.is_enabled,
            width: packet.width,
            height: packet.height,
          });
          allStickers = [...allStickers, ..._stickers];
        },
      );
      state.defaultStickers = {
        list: _list,
        category: _category,
        isLoading: false,
        allStickers,
      };
    });
    builder.addCase(fetchDefault.rejected, (state) => {
      state.defaultStickers.isLoading = false;
    });
    builder.addCase(fetchRecent.pending, (state) => {
      state.recentStickers.isLoading = true;
    });
    builder.addCase(fetchRecent.fulfilled, (state, {payload}) => {
      const _stickers = intersectionBy(
        payload?.response?.stickers || [],
        state.defaultStickers.allStickers,
        "sticker_id",
      );
      const _list = [
        {
          categoryName: "Recent",
          categoryFullName: "Recent",
          list: _stickers.map(
            ({packet_id, sticker_id, width, height, type, hints}) => {
              return {
                image: {
                  pc: getStickerAndEmojiUrl({
                    packet_id,
                    sticker_id,
                    device: "pc",
                  }),
                  mobile: getStickerAndEmojiUrl({
                    packet_id,
                    sticker_id,
                    device: "mobile",
                  }),
                  thumb: getStickerAndEmojiUrl({
                    packet_id,
                    sticker_id,
                    device: "thumb",
                  }),
                },
                packet_id,
                sticker_id,
                type,
                height,
                width,
                hints,
              };
            },
          ),
        },
      ];
      state.recentStickers = {
        list: _list,
        isLoading: false,
      };
    });
    builder.addCase(fetchRecent.rejected, (state) => {
      state.recentStickers.isLoading = false;
    });
    builder.addCase(fetchSearch.pending, (state) => {
      state.searchStickers.isLoading = true;
    });
    builder.addCase(fetchSearch.fulfilled, (state, {payload}) => {
      if (state.searchStickers.keyword !== payload?.response?.keyword) return;
      const _stickers = intersectionBy(
        payload?.response?.stickers || [],
        state.defaultStickers.allStickers,
        "sticker_id",
      );
      const _list = _stickers.map(
        ({packet_id, sticker_id, width, height, type, hints}) => {
          return {
            image: {
              pc: getStickerAndEmojiUrl({packet_id, sticker_id, device: "pc"}),
              mobile: getStickerAndEmojiUrl({
                packet_id,
                sticker_id,
                device: "mobile",
              }),
              thumb: getStickerAndEmojiUrl({
                packet_id,
                sticker_id,
                device: "thumb",
              }),
            },
            packet_id,
            sticker_id,
            type,
            height,
            width,
            hints,
          };
        },
      );
      state.searchStickers.list = _list;
      state.searchStickers.isLoading = false;
      state.cacheSearchResult[state.searchStickers.keyword] = _list;
    });
    builder.addCase(fetchSearch.rejected, (state) => {
      state.searchStickers.isLoading = false;
    });
    builder.addCase(fetchUse.fulfilled, (state, {payload}) => {
      const {usedData} = payload.response;
      state.recentStickers.list[0] = {
        list: [{...usedData}, ...(state.recentStickers.list[0]?.list || [])],
      };
    });
    builder.addCase(fetchHeadlessSearch.pending, (state) => {
      state.headlessSearchStickers.isLoading = true;
    });
    builder.addCase(fetchHeadlessSearch.fulfilled, (state, {payload}) => {
      if (state.headlessSearchStickers.keyword !== payload?.response?.keyword)
        return;
      const _stickers = intersectionBy(
        payload?.response?.stickers || [],
        state.defaultStickers.allStickers,
        "sticker_id",
      );
      const _list = _stickers.map(({packet_id, sticker_id, type, hints}) => {
        return {
          image: {
            pc: getStickerAndEmojiUrl({packet_id, sticker_id, device: "pc"}),
            mobile: getStickerAndEmojiUrl({
              packet_id,
              sticker_id,
              device: "mobile",
            }),
            thumb: getStickerAndEmojiUrl({
              packet_id,
              sticker_id,
              device: "thumb",
            }),
          },
          packet_id,
          sticker_id,
          type,
          hints,
        };
      });
      state.headlessSearchStickers.list = _list;
      state.headlessSearchStickers.isLoading = false;
      state.cacheSearchResult[state.headlessSearchStickers.keyword] = _list;
    });
    builder.addCase(fetchHeadlessSearch.rejected, (state) => {
      state.headlessSearchStickers.isLoading = false;
    });
  },
});
