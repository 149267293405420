import {useEffect, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";

import {imgMaxHeight} from "src/app/components/post/comps/FeedItemContent/FeedItemImageContent";

const useStyles = makeStyles((theme) => ({
  gifImage: {
    position: "relative",
    width: "100%",
    maxHeight: ({imgMaxHeight}) => imgMaxHeight,
  },
  image: {
    position: "relative",
    width: "100%",
    objectFit: "cover",
    cursor: "pointer",
  },
  gifBadge: {
    position: "absolute",
    bottom: 8,
    left: 12,
    background: theme.palette.common.black,
    lineHeight: "130%",
    borderRadius: 3,
    fontSize: 15,
    fontWeight: "normal",
    padding: "3px 5px",
    textTransform: "uppercase",
    color: theme.palette.common.white,
  },
}));

const getRealHeight = (imageWidth, imageHeight, containerWidth) => {
  if (!imageWidth || !imageHeight || !containerWidth) {
    return;
  }

  return (containerWidth / imageWidth) * imageHeight;
};

export const GifImage = ({
  imageUrl,
  imageClassName,
  handleClick,
  height,
  width,
  setImgError,
}) => {
  const classes = useStyles({imgMaxHeight});
  const gifContainerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(null);

  useEffect(() => {
    setContainerWidth(gifContainerRef.current.getBoundingClientRect().width);
  }, []);

  return (
    <div
      ref={gifContainerRef}
      className={`${classes.gifImage} main-image`}
      onClick={(e) => handleClick && handleClick(e)}
    >
      <img
        height={getRealHeight(width, height, containerWidth)}
        src={imageUrl}
        alt={"gif"}
        className={`${classes.image} ${imageClassName}`}
        onError={(event) => {
          event.target.classList.add("error");
          setImgError(true);
        }}
        draggable="false"
      />
      <div className={classes.gifBadge}>gif</div>
    </div>
  );
};
