import {createSlice, createAction, createAsyncThunk} from "@reduxjs/toolkit";
import ReferEarnApi from "../../referAndEarn/api";

const NS = "referVerify";

const initialState = {
  stepsPopup: {
    open: false,
    totalVerifiedUsers: 0,
    totalUsersReferred: 0,
    loading: false,
    verificationStatus: "",
    verificationRejectionReason: "",
    verificationTimeStamp: "",
  },
  inviteFrdLiteVerification: {
    open: false,
  },
};

export const updateReferVerifyState = createAction(
  `${NS}/updateReferVerifyState`,
);
export const handleReferVerifyClose = createAction(
  `${NS}/handleReferVerifyClose`,
);
export const handleReferVerifyOpen = createAction(
  `${NS}/handleReferVerifyOpen`,
);
export const handleInviteFrdLiteOpen = createAction(
  `${NS}/handleInviteFrdLiteOpen`,
);
export const handleInviteFrdLiteClose = createAction(
  `${NS}/handleInviteFrdLiteClose`,
);
export const getOnboardingStatus = createAsyncThunk(
  "get/onboardingStatus",
  ReferEarnApi.getOnboardingStatus,
);

export const referVerifySlice = createSlice({
  name: NS,
  initialState,
  reducers: {
    updateReferVerifyState(state, action) {
      const {value, key} = action.payload;
      state.stepsPopup[key] = value;
    },
    handleReferVerifyOpen(state) {
      state.stepsPopup.open = true;
    },
    handleReferVerifyClose(state) {
      state.stepsPopup.open = false;
    },
    handleInviteFrdLiteOpen(state) {
      state.inviteFrdLiteVerification.open = true;
    },
    handleInviteFrdLiteClose(state) {
      state.inviteFrdLiteVerification.open = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOnboardingStatus.fulfilled, (state, {payload}) => {
      state.stepsPopup.verificationStatus = payload?.status;
      state.stepsPopup.verificationRejectionReason = payload?.reason;
      state.stepsPopup.verificationTimeStamp = payload?.addedOn;
    });
  },
});
