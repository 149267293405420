import GAxios from "../../../../../util/GAxios";

export const setPEPPopConfig = async (pop) => {
  if (!pop) {
    return false;
  }

  const {
    maestro_pep_selections,
    maestro_pep_udate,
    maestro_disclaimer_selections,
    maestro_disclaimer_udate,
  } = pop;
  const data = {
    content: {
      pop_configs: {
        maestro_pep: {
          saved_selections: maestro_pep_selections,
          pop_type: "maestro_pep",
          expiry: 0,
        },
        maestro_disclaimer: {
          saved_selections: maestro_disclaimer_selections,
          pop_type: "maestro_disclaimer",
          expiry: 0,
        },
      },
    },
  };

  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/u/user/v2/pop_config`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  let res = false;
  await GAxios(config, (response) => {
    if (response) {
      res = response.data.result.data;
    } else {
      res = false;
    }
  });
  return res;
};
