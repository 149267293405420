import {createStyles, makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    "@global": {
      html: {
        colorScheme: theme.palette.type,
      },
      body: {
        fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif`,
        fontSize: 14,
        height: "100vh",
        backgroundColor: theme.palette.newColors.backgroundBg["1_darkened"],
        "-webkit-font-smoothing": "antialiased",
        overflowY: "scroll",
      },

      "*": {
        boxSizing: "border-box", // gap around the full screen
        margin: 0,
        padding: 0,
      },

      "::placeholder, :-ms-input-placeholder": {
        fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif`,
        color: "rgb(170, 170, 170)",
        fontWeight: 100,
      },

      "#root ~ div": {
        maxWidth: "100%",
      },

      ".action-buttons": {
        display: "block",
        float: "right",
        marginTop: 30,
      },

      'input:not([type="checkbox"]):not([type="radio"]):not([class^="shaka-"]), select, textarea':
        {
          fontSize: 14,
          fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif`,
          border: "none",
          minHeight: 45,
          padding: "0 15px",
          "-moz-border-radius": "8px",
          "-webkit-border-radius": "8px",
          borderRadius: "8px",
          caretColor: ["light", "dark"].includes(theme.type)
            ? theme.palette.newColors.systemBlue[500]
            : theme.palette.newColors.red[600],
          "-webkit-transition": "background-color 100ms ease-in",
          "-moz-transition": "background-color 100ms ease-in",
          "-o-transition": "background-color 100ms ease-in",
          transition: "background-color 100ms ease-in",

          "&:focus": {
            outline: "none",
          },
        },

      // link
      a: {
        position: "relative",
        fontWeight: "bold",
        color: theme.palette.text.primary,
        textDecoration: "none",
      },
      // legacy default color
      //   svg: {
      //     color: "#6e7187",
      //   },
      // for overrite to currentColor
      "svg.currentColor": {
        color: "currentColor",
      },
      ".icon": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 40,
        height: 40,
        cursor: "pointer",
        "-webkit-transition": theme.palette.newColors.transition["02"],
        "-moz-transition": theme.palette.newColors.transition["02"],
        "-o-transition": theme.palette.newColors.transition["02"],
        transition: theme.palette.newColors.transition["02"],
      },

      ".text-link:hover": {
        textDecoration: "underline",
      },
      ".responsive-wrapper": {
        height: "233px !important",
        justifyContent: "center",
      },
      ".ot-floating-button": {
        display: "none",
      },
      ".intercom-1n4k6q0": {
        display: "none !important",
      },
      ".ssr-content": {
        display: "none",
      },
      [theme.breakpoints.down("xs")]: {
        ".chatBot, .chatBotTooltip, .chatBotTooltip ~ span": {
          display: "none !important",
        },
      },
    },
  }),
);

export const BaseStyles = () => {
  useStyles();
  return null;
};
