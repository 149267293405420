import {words as parseWords} from "lodash-es";
import AppConsts, {LanguageCodes} from "../app/AppConsts";
import {
  SPECIAL_SYMBOL,
  HASH_TAG_MATCH_PATTERN,
} from "src/styles/components/GMentionsConsts";
import {t} from "src/i18n/utils";
import {toast} from "react-toastify";
import {NotifMessage} from "src/app/components/notifications/NotifMessage";
import {SupportedLanguageList} from "src/app/AppMessages";

const SPECIAL_SYMBOL_REG = `[${SPECIAL_SYMBOL.slice(1).join("|")}]`;
const NON_SPECIAL_SYMBOL_REG = `[^${SPECIAL_SYMBOL.join("|")}]`;

export const isString = (s) => {
  return typeof s === "string";
};

export const stringIsEmpty = (s, trim = false, nullIsEmpty = true) => {
  if (s == null && nullIsEmpty === false) {
    return false;
  }
  return stringLength(s, trim) === 0;
};

export const stringLength = (s, trim = false) => {
  if (s == null) {
    return 0;
  }
  return trim === true ? s.trim().length : s.length;
};

export const descriptionFormat = (text) => {
  return text.length > AppConsts.DESCRIPTION_MAX_CHARACT
    ? `${text.substring(0, AppConsts.DESCRIPTION_MAX_CHARACT)}...`
    : text;
};

export const youtubeFormat = (url) => {
  const regex = new RegExp(
    /^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/i,
  );
  return regex.test(url);
};

export const twitterFormat = (url) => {
  const regex = new RegExp(/^(https?\:\/\/)?(www\.)?(twitter\.com)\/.+$/);

  return regex.test(url);
};

export const getYoutubeVideoID = (url) => {
  const regex = new RegExp(
    /(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w-_]+)/i,
  );
  return url.match(regex);
};

export const filterReg = (text) => {
  return text.replace(
    new RegExp(
      `^(\\s|^)([@#]${NON_SPECIAL_SYMBOL_REG}+)(${SPECIAL_SYMBOL_REG}+).*$`,
      "g",
    ),
    "$1$2",
  );
};

export const emailBlacklistReg = new RegExp(
  "^(?:" +
    [
      // "qq.com",
      // "163.com",
      // "126.com",
      // "yeah.net",
      // "aliyun.com",
      // "foxmail.com",
      // "wo.com.cn",
      // "wo.cn",
      // "139.com",
      // "cmtietong.com",
      // "sina.com",
      // "vip.sina.com",
      // "sina.cn",
      // "sohu.com",
      // "vip.sohu.com",
      // "sohu.net",
      // "189.cn",
      // "21cn.com",
      // "hainan.com",
      // "hainan.net",
      // "sunmail.cn",
      // "163.net",
      // "tom.com",
      // "vip.tom.com",
    ]
      .map((t) => t.replace(".", "\\."))
      .map((t) => "(?!.+@" + t + "$)")
      .join(",")
      .replace(/,/g, "") +
    ")",
);

export const checkIfSameText = (text, key, from, prevText) => {
  const sessionStorageValue = sessionStorage.getItem(key);
  const sessionStorageValueText = sessionStorageValue || "";
  const newText = text?.trim();
  if (from === "edit-post") {
    return (
      !!sessionStorageValue &&
      text &&
      (sessionStorageValueText === newText || prevText === text)
    );
  } else {
    return (
      !!sessionStorageValue &&
      text &&
      sessionStorageValueText.toLocaleLowerCase() ===
        newText.toLocaleLowerCase()
    );
  }
};

// export const handleSameText = (text, key, from) => {
//   if (checkIfSameText(text, key, from)) {
//     toast.info(
//       <NotifMessage message={t("getter_fe.post.text.samePostText")} />,
//       {
//         type: toast.TYPE.ERROR,
//       },
//     );

//     return true;
//   }
// };

export const savePrevPost = (key, text) => {
  sessionStorage.setItem(key, text);
};

export const handleSameTextGroup = (group = {}, from, prevText) => {
  let result = true;
  Object.keys(group).forEach((key) => {
    result = result && checkIfSameText(group[key], key, from, prevText);
  });

  if (result) {
    toast.info(
      <NotifMessage message={t("getter_fe.post.text.samePostText")} />,
      {
        type: toast.TYPE.ERROR,
      },
    );

    return true;
  }
};

export const handleClearSameText = (keys) => {
  keys.forEach((k) => sessionStorage.removeItem(k));
};

export const handlePrivacyEmail = (email, n = 2) => {
  const formatPrivacy = (t, n) => {
    let _s = "";
    if (t.length > n) {
      for (let i = n; i < t.length; i++) {
        _s += "*";
      }
      return t.substr(0, n) + _s;
    } else {
      for (let i = 1; i < t.length; i++) {
        _s += "*";
      }
      return t.substr(0, 1) + _s;
    }
  };

  if (String(email).indexOf("@") > 0) {
    let el = email.split("@");
    let eld = el[1].split(".");
    const eName = formatPrivacy(el[0], n);
    const edName = formatPrivacy(eld[0], n);
    return [eName, "@", edName, ".", eld[1]].join("");
  }
  return "";
};

export const obscureEmail = (email) => {
  if (!email) return "";

  const [name, domain] = email.split("@");
  const [domainName, extension] = domain.split(".");
  return `${name[0]}${new Array(name.length <= 20 ? name.length : 15).join(
    "*",
  )}@${domainName[0]}${new Array(domainName.length).join("*")}.${extension}`;
};

export const showFollower = (userInfo, platform) => {
  if (!userInfo) return 0;

  if (platform === "twitter") {
    // todo: is the user himself in the profile page, the socialsync_configs have datas.
    // if the other user view the profile page, the socialsync_configs have no datas.
    // so it's the backend issue.

    // if (userInfo?.socialsync_configs?.twitter?.flw_impd) {
    //   return userInfo?.socialsync_configs?.twitter?.socialsync_flr ?? 0;
    // }
    if (!userInfo?.claim_src) {
      return userInfo?.twt_flg ? userInfo?.twt_flg : 0;
    }
    if (userInfo?.claim_show_flw !== "false") {
      return userInfo?.twt_flg ? userInfo?.twt_flg : 0;
    }
  }

  if (platform === "instagram") {
    if (userInfo?.socialsync_configs?.instagram?.flw_impd) {
      return userInfo?.socialsync_configs?.instagram?.socialsync_flr ?? 0;
    }
  }

  return 0;
};

export const getAllFollowers = (userInfo) => {
  if (!userInfo) return 0;

  const flg = userInfo?.flg ?? 0;
  const twitter = showFollower(userInfo, "twitter");
  const instagram = showFollower(userInfo, "instagram");
  return flg * 1 + twitter * 1 + instagram * 1;
};

export const inviteLinkparse = (text) => {
  if (text?.includes("messages/invitations?code=")) {
    const regex = /https?:\/\/\S+/g;
    //
    const match = text.match(regex);
    let invite_link = "";
    if (match) {
      const url = match[0];
      match.some((ma) => {
        const regex1 = /https:\/\/.*\/messages\/invitations\?code\=*.{32}/;
        const url32Length = ma.match(regex1);
        if (url32Length) {
          invite_link = url32Length[0];
          return true;
        } else {
          return false;
        }
      });
    }
    if (invite_link) {
      text = text.replaceAll(invite_link, () => "");
    }
    return {
      invite_link: invite_link,
      message: text.trim(),
    };
  } else {
    return {
      invite_link: "",
      message: text.trim(),
    };
  }
};

export const parseMentions = (text) => {
  if (!text) return [];

  let usernames = [];

  let words = parseWords(text || "", /@\w+/gm);

  words.forEach((word) => {
    if (word.substring(0, 1) === "@") {
      word.replace(/[@]+[A-Za-z0-9-_]+/g, function (u) {
        let username = u.replace("@", "");
        usernames.push(username);
      });
    }
  });

  return usernames;
};

export const parseTags = (text) => {
  if (!text) return [];

  const words = parseWords(text || "", HASH_TAG_MATCH_PATTERN);
  return words?.map((word) =>
    word.replace(HASH_TAG_MATCH_PATTERN, "$2")?.slice(1),
  );
};

export const getUrlHashtagPage = (hashtag) => {
  if (hashtag[0] !== "#") {
    hashtag = "/hashtag/" + encodeURIComponent("#" + hashtag);
  } else {
    hashtag = "/hashtag/" + encodeURIComponent(hashtag);
  }
  return hashtag;
};

export const getUrlUsertagPage = (userId) => {
  if (userId[0] === "@") userId = userId.substring(1);
  return `/user/${userId}`;
};

/**
 * Given an object array, return the the object that
 * has a property name that matches the given value
 *
 * @param {object[]} list array of object
 * @param {string} label property name inside objects to check
 * @param {*} value value to compare (using ==)
 * @return {*} item in list that match the label == value
 */
export const getObjectFromArrayByValue = (list, label, value) => {
  const count = list ? list.length : 0;
  let result;
  let item;
  for (let i = 0; i < count; i++) {
    item = list[i];
    if (item && item[label] && item[label] === value) {
      result = item;
      break;
    }
  }
  return result;
};

/**
 * Return entire language list of supported languages, or just the
 * record of the desired language code
 *
 * @param {string} langCode
 * @param {string=} defaultVal optional backup language. Default will be English
 * @return {object[]} either full language map keyed off code, or the record
 * for the given code
 */
export const getSupportedLanguageList = (
  langCode = null,
  defaultVal = null,
) => {
  let langList = SupportedLanguageList;
  let result;
  if (langCode) {
    result = getObjectFromArrayByValue(langList, "code", langCode);
    if (result == null) {
      if (defaultVal == null) defaultVal = LanguageCodes.ENGLISH;
      result = getObjectFromArrayByValue(langList, "code", defaultVal);
    }
  } else result = langList;
  return result;
};

export const handleRightToLeft = (txt) => {
  if (!txt) return false;

  const rtl = (txt.match(/[\u0591-\u07FF]+/g) || []).join(" ").length;
  const prctg = (rtl * 100) / txt.length;
  return prctg > 40;
};

export const getValidMentionsCount = (text = "") => {
  // const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  // const splittedText = text?.split("\n");
  // let validMentionsCount = 0
  // splittedText?.map((txtVal) => {
  //   let lineTextValue = txtVal?.split(' ')
  //   lineTextValue?.map((lineTxt) => {
  //     let textvalue = lineTxt?.split('') || []
  //     if (textvalue.includes("@")) {
  //       let isValidMention = true
  //       let currentLetter = '';
  //       let previousLetter = '';
  //       textvalue?.map((txt) => {
  //         let isPrevTextSplChar = format.test(previousLetter);
  //         let isNextTextSplChar = format.test(txt);
  //         let isCurrentTextSplChar = format.test(currentLetter);
  //         previousLetter = currentLetter;
  //         currentLetter = txt;
  //         if (isCurrentTextSplChar && (isPrevTextSplChar || isNextTextSplChar)){
  //           isValidMention = false
  //         }
  //       })
  //       validMentionsCount += isValidMention ? 1 : 0;
  //     }
  //   })
  // })
  // return validMentionsCount
  return text.match(/@[^\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/g)?.length;
};

export const maskEmail = (email) => {
  // var email = "mail@gmail.com";
  if (!email) return null;
  let pre = "";
  let post = "";
  let prefix = email.substring(0, email.lastIndexOf("@"));
  let postfix = email.substring(email.lastIndexOf("@"));
  let domain = email.substring(email.lastIndexOf("."));

  for (let i = 0; i < prefix.length; i++) {
    if (i == 0 || i == prefix.length - 1) {
      pre = pre + prefix[i].toString();
    } else {
      pre = pre + "*";
    }
  }
  if (pre.length > 5) pre = pre.substring(0, 5) + pre.slice(-1);

  for (let i = 0; i < postfix.length; i++) {
    if (i == 0 || i == 1) {
      post = post + postfix[i].toString();
    } else {
      post = post + "*";
    }
  }

  post = post.substring(0, 6);

  return pre + post + domain;
};
