import clsx from "clsx";
import styles from "./Spinner.module.css";

function Spinner(props) {
  return (
    <svg
      width="24"
      height="24"
      {...props}
      className={clsx(styles.spinner, props.className)}
      viewBox="-100 -100 200 200"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        r="80"
        fillOpacity="0"
        strokeOpacity="1"
        strokeWidth="20"
        stroke="rgb(24,86,177)"
        strokeLinejoin="miter"
        strokeLinecap="round"
        strokeDasharray="32 502"
        transform="rotate(-90)"
      />
      <circle
        r="80"
        fillOpacity="0"
        strokeOpacity="1"
        strokeWidth="20"
        stroke="rgb(134,182,252)"
        strokeLinejoin="miter"
        strokeLinecap="round"
        strokeDasharray="32 502"
        transform="rotate(0)"
      />
      <circle
        r="80"
        fillOpacity="0"
        strokeOpacity="1"
        strokeWidth="20"
        stroke="rgb(24,86,177)"
        strokeLinejoin="miter"
        strokeLinecap="round"
        strokeDasharray="32 502"
        transform="rotate(90)"
      />
      <circle
        r="80"
        fillOpacity="0"
        strokeOpacity="1"
        strokeWidth="20"
        stroke="rgb(134,182,252)"
        strokeLinejoin="miter"
        strokeLinecap="round"
        strokeDasharray="32 502"
        transform="rotate(180)"
      />
    </svg>
  );
}

export default Spinner;
