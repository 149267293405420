import GAxios from "src/util/GAxios";
import axios from "axios";

let cancelTokenSourceForSearchStickers;

export const fetchSearch = async (keyword) => {
  if (cancelTokenSourceForSearchStickers) {
    cancelTokenSourceForSearchStickers.cancel();
  }
  cancelTokenSourceForSearchStickers = axios.CancelToken.source();
  const config = {
    method: "get",
    url:
      process.env.REACT_APP_API_URL + `/v1/stickers/search?keyword=${keyword}`,
    cancelToken: cancelTokenSourceForSearchStickers.token,
    ignoreErrors: true,
  };

  let res = {err: null, response: null};

  await GAxios(
    config,
    (response) => {
      if (response) {
        res.response = {...response.data.result, keyword};
      } else {
        res.err = true;
      }
    },
    (err) => {
      res.err = true;
    },
  );

  return res;
};
