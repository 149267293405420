import GAxios from "src/util/GAxios";
import {userConstants} from "../_constants";
import {t} from "src/i18n/utils";
import {emailSmsErrorCallback} from "../../../../util/ErrorCallback";
import {showToast} from "src/util/showToast";

export const sendSelfVerifCode = async (params) => {
  const {email, phone, errorCallback} = params;

  if (!email && !phone) return;

  const whatsapp = localStorage.getItem("Whatsapp");
  const isWhatsapp = whatsapp === "true";

  const contactType = email ? "email" : !isWhatsapp ? "sms" : "whatsapp";
  const data = JSON.stringify({
    content: {
      contactType,
    },
  });

  const config = {
    method: "post",
    url: userConstants.API_SELF_SEND_VERIF_CODE,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  let res;

  await GAxios(
    config,
    (response) => {
      if (response && response.data?.result?.success) {
        res = {...response.data?.result, ...params};
        showToast(
          "success",
          phone
            ? isWhatsapp
              ? t("getter_fe.settings.successes.whatsappVerifyCode")
              : t("getter_fe.settings.successes.phoneVerifyCode")
            : t("getter_fe.settings.successes.emailVerifyCode"),
        );
      } else {
        res = null;
      }
    },
    (err) => {
      emailSmsErrorCallback(err, "email");
      res = null;
      if (errorCallback) errorCallback(err);
    },
  );

  return res;
};
