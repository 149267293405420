import {createStyles, makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    "@global": {
      ".popup-content": {
        background: `${theme.palette.colors_v2.modalWindowBg} !important`,
        maxWidth: "80vw",
        borderRadius: 14,
        "& .content > h3": {
          color: theme.palette.newColors.neutral[900],
        },
        "& > div > .content > .post-composer": {
          border: "none",
          backgroundColor: "transparent",
        },
      },
      ".post-composer": {
        backgroundColor: theme.palette.newColors.backgroundBg["0_elevated"],
        width: "100%",
        display: "flex",
        padding: "0 20px",
        position: "relative",
        borderRadius: 10,
        flexDirection: "column",
        "&.is-in-popup": {
          paddingLeft: 0,
          paddingRight: 0,
          "& .post-avatar": {
            overflow: "visible",
            "& .avatar": {
              width: 44,
              height: 44,
            },
          },
          "& .post-box": {
            "& .post-preview-box": {
              "& .file": {
                marginBottom: 0,
                paddingBottom: 0,
              },
            },
          },
        },
        "&:not(.is-in-popup)": {
          "& .post-box:not(.comment-box,.repost-composer)": {
            width: "calc(100% - 54px)",
          },
        },
        "& .post-avatar": {
          cursor: "pointer",
          overflow: "hidden",
          left: 18,
          "& .avatar": {
            border: `1px solid ${theme.palette.newColors.avatarBorder}`,
            boxSizing: "border-box",
            "& svg": {
              height: "100%",
            },
          },
        },
        "& .action-bar": {
          "-webkit-tap-highlight-color": "transparent",
          display: "flex",
          flexDirection: "row",
          gap: theme.spacing(1.5),
          alignItems: "center",
          paddingBlock: "10px",
          height: "100%",
          maxHeight: theme.spacing(8.625),
          borderTop: `1px solid ${theme.palette.newColors.backgroundBg[3]}`,
          "& .action-buttons": {
            marginTop: 0,
            marginLeft: 20,
            "& .MuiButton-contained:not(:disabled)": {
              backgroundColor: ["dark", "light"].includes(theme.type)
                ? theme.palette.newColors.neutral[900]
                : theme.palette.newColors.red[600],
            },
            "& .MuiButton-contained:not(:disabled):hover": {
              backgroundColor: ["dark", "light"].includes(theme.type)
                ? theme.palette.newColors.neutral[700]
                : theme.palette.newColors.red[700],
            },
            "& button": {
              height: 36,
              minWidth: 66,
              borderRadius: 100,
              "&:disabled": {
                color: ["dark", "light"].includes(theme.type)
                  ? theme.palette.newColors.neutral[400]
                  : theme.palette.newColors.red[400],
              },
            },
          },
          "& .text-length-limit": {
            textAlign: "right",
            marginLeft: "auto",
            // textAlign: "right",
            // width: 75,
            // marginLeft: "auto",
            color: theme.palette.newColors.neutral[500],
            "&.is-exceed": {
              color: "#f34545",
            },
            [theme.breakpoints.only("xs")]: {
              width: "auto",
            },
          },
        },
        "& .excess-tips": {
          display: "flex",
          justifyContent: "space-between",
          height: 36,
          lineHeight: "36px",
          backgroundColor: theme.palette.newColors.systemRed[50],
          padding: "0 16px",
          color: theme.palette.newColors.systemRed[600],
          marginTop: 18,
          position: "relative",
          zIndex: 1,
          borderRadius: "6px",
          "& .btn-delete-excess": {
            cursor: "pointer",
            fontSize: 15,
            fontWeight: 800,
          },
        },
        "& .post-box": {
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          width: "100%",
          minWidth: 0,
          "& form": {
            display: "flex",
            flexDirection: "column",
          },
          "& textarea": {
            width: "100%",
            border: 0,
            resize: "none",
            backgroundColor: "transparent",
            "-webkit-box-shadow": "none !important",
            "-moz-box-shadow": "none !important",
            boxShadow: "none !important",
            margin: "0 10px",
            padding: 0,
            fontSize: 19,
            "&:focus": {
              outline: "none",
            },
          },
          "& .post-preview-box": {
            flex: "1 1 100%",
            borderRadius: 10,
            padding: "0 18px",
            minHeight: 53,
            lineHeight: "27px",
            [theme.breakpoints.down("xs")]: {
              width: "auto",
            },
            "& .post": {
              marginBottom: 18,
            },
          },
          "& .preview-content": {
            marginBottom: 12,
            marginLeft: 0,
            marginRight: 0,
          },
          "& .action-bar": {
            "-webkit-tap-highlight-color": "transparent",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "18px 5px 15px",
            marginLeft: 8,
            height: "100%",
            maxHeight: theme.spacing(8.625),
            "& .action-buttons": {
              marginTop: 0,
              marginLeft: 16,
              "& .MuiButton-contained:not(:disabled)": {
                backgroundColor: ["dark", "light"].includes(theme.type)
                  ? theme.palette.newColors.neutral[900]
                  : theme.palette.newColors.red[600],
              },
              "& .MuiButton-contained:not(:disabled):hover": {
                backgroundColor: ["dark", "light"].includes(theme.type)
                  ? theme.palette.newColors.neutral[700]
                  : theme.palette.newColors.red[700],
              },
              "& button": {
                height: 36,
                minWidth: 100,
                borderRadius: 100,
                "&:disabled": {
                  color: ["dark", "light"].includes(theme.type)
                    ? theme.palette.newColors.neutral[400]
                    : theme.palette.newColors.red[400],
                },
              },
            },
            "& .text-length-limit": {
              textAlign: "right",
              color: theme.palette.newColors.neutral[500],
              "&.is-exceed": {
                color: "#f34545",
              },
            },
          },
          "& .metadata": {
            position: "relative",
            width: "100%",
            border: "1px solid #e3e9ee",
            marginBottom: 20,
            "& .img-container": {
              position: "relative",
              "& img": {
                maxWidth: "100%",
              },
            },
            "& .content": {
              padding: "5px 10px",
              backgroundColor: theme.palette.colors_v2.modalWindowBg,
              "& .title": {
                fontSize: 15,
                margin: "5px 0px",
              },
              "& .description": {
                color: "#070808",
                wordBreak: "break-word",
                margin: "5px 0px",
              },
              "& .site-name": {
                color: "#070808",
                margin: "5px 0px",
              },
            },
            "& .remove": {
              position: "absolute",
              left: 5,
              top: 5,
              "& svg": {
                position: "absolute",
                fontSize: 28,
                "--fa-secondary-opacity": 1,
                "--fa-primary-color": "white",
                "--fa-secondary-color": "black",
              },
            },
          },
          "& .replay-tip": {
            color: "#3261d8",
            lineHeight: "19px",
            fontSize: 15,
            fontFamily: "Helvetica Neue",
            fontWeight: 400,
            margin: "0 0 10px 10px",
            display: "inline-block",
            "& img": {
              verticalAlign: "top",
              marginRight: 10,
            },
          },
        },
        [theme.breakpoints.down("xs")]: {
          "&.post-composer .post-box .action-bar ,&.post-composer .post-box .excess-tips":
            {
              marginLeft: -22,
              marginRight: -18,
              paddingLeft: "18px !important",
              paddingRight: "18px !important",
            },
          "&.comment-composer .post-box .action-bar, &.comment-composer .post-box .excess-tips":
            {
              marginLeft: -34,
              marginRight: -18,
              paddingLeft: 18,
              paddingRight: 18,
            },
          "&.post-composer .aiCopilotFeedbackWrapper, &.post-composer .aiCopilotWrapper.ai-has-written":
            {
              marginLeft: -6,
            },
        },
        "&.rich-editor-style": {
          "& .post-box .post-preview-box": {
            "& .ql-container": {
              padding: 0,
            },
          },
          "&.is-in-popup": {
            "& .post-box": {
              width: "auto",
            },
          },
        },
      },
      ".comment-composer .post-box .excess-tips": {
        marginTop: 18,
        marginBottom: -16,
        position: "relative",
        zIndex: 1,
      },
      ".comment-composer .post-box .action-bar": {
        marginLeft: 0,
        marginTop: "0px !important",
      },
      ".post-composer-divider": {
        background: "#f8f9fb",
        borderBottom: `1px solid ${theme.palette.line.grey1}`,
        height: 9,
      },
      ".popup-content .post-composer-divider": {
        display: "none",
      },
      ".mobile-GBackTitle-with-post": {
        height: `44px !important`,
        justifyContent: "space-between",
        paddingRight: "18px !important",
        "& > div:first-child": {
          marginLeft: "18px !important",
        },
        "& .action-buttons": {
          marginTop: 0,
          "& button": {
            minWidth: 48,
            borderRadius: 55,
            padding: "5px 10px",
            "& .MuiButton-label": {
              fontSize: 13,
              lineHeight: 1.38,
            },
            "&:disabled": {
              backgroundColor: theme.palette.button.primary.disabled,
            },
          },
        },
        "& .action-buttons.zh, & .action-buttons.tw": {
          minWidth: 80,
          textAlign: "right",
        },
        "&.backTitle": {
          paddingBottom: "0 !important",
          paddingRight: "18px !important",
        },
      },
      ".empty-space": {
        width: "100%",
      },
    },
  }),
);

export const PostComposerStyles = () => {
  useStyles();
  return null;
};
