import GAxios from "src/util/GAxios";
import {userConstants} from "../_constants";
import {showToast} from "../../../../util/showToast";
import {t} from "src/i18n/utils";

export const changeUsername = async (params) => {
  const {username, password, vtoken, successCallback, errorCallback} = params;
  if ((!vtoken && !password) || !username) return;

  const data = JSON.stringify({
    content: {
      username,
      password,
      vtoken,
    },
  });

  const config = {
    method: "post",
    url: userConstants.API_CHANGE_USERNAME,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  let res = false;

  await GAxios(
    config,
    (response) => {
      if (response?.status === 200 && response?.data?.result?.user) {
        res = response?.data?.result?.user;
        successCallback && successCallback();
      } else {
        showToast("warning", t("getter_fe.auth.errors.somethingWentWrong"));
      }
    },
    (err) => {
      if (errorCallback) errorCallback(err);
    },
  );

  return res;
};
