import {createSlice, createAsyncThunk, createAction} from "@reduxjs/toolkit";
import {GettrEmojiApi} from "../api";
import {forEach, find, uniqBy, intersectionBy} from "lodash-es";

const NS = "gettrEmoji";

const initialState = {
  defaultEmojis: {
    isLoading: false,
    list: [],
    category: [],
    allEmojis: null,
  },
  recentEmojis: {
    isLoading: false,
    list: [],
    category: [],
  },
  searchEmojis: {
    open: false,
    keyword: "",
    isLoading: false,
    list: [],
  },
  headlessSearchEmojis: {
    open: false,
    keyword: "",
    isLoading: false,
    list: [],
  },
  cacheSearchResult: {},
};

export const fetchDefault = createAsyncThunk(
  "gettrEmoji/fetchDefault",
  GettrEmojiApi.fetchDefault,
);
export const fetchRecent = createAsyncThunk(
  "gettrEmoji/fetchRecent",
  GettrEmojiApi.fetchRecent,
);
export const fetchSearch = createAsyncThunk(
  "gettrEmoji/fetchSearch",
  GettrEmojiApi.fetchSearch,
);
export const fetchHeadlessSearch = createAsyncThunk(
  "gettrEmoji/fetchHeadlessSearch",
  GettrEmojiApi.fetchSearch,
);
export const fetchUse = createAsyncThunk(
  "gettrEmoji/fetchUse",
  GettrEmojiApi.fetchUse,
);
export const toggleSearch = createAction(`${NS}/toggleSearch`);
export const setKeyword = createAction(`${NS}/setKeyword`);
export const clearHeadlessSearchEmojis = createAction(
  `${NS}/clearHeadlessSearchEmojis`,
);
export const setHeadlessKeyword = createAction(`${NS}/setHeadlessKeyword`);
export const clearSearchEmojis = createAction(`${NS}/clearSearchEmojis`);

export const gettrEmojiSlice = createSlice({
  name: NS,
  initialState,
  reducers: {
    toggleSearch: (state, {payload}) => {
      state.searchEmojis.open = payload;
    },
    setKeyword: (state, {payload}) => {
      state.searchEmojis.keyword = payload;
    },
    clearHeadlessSearchEmojis: (state) => {
      state.headlessSearchEmojis.list = [];
    },
    setHeadlessKeyword: (state, {payload}) => {
      state.headlessSearchEmojis.keyword = payload;
    },
    clearSearchEmojis: (state) => {
      state.searchEmojis.list = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDefault.pending, (state) => {
      state.defaultEmojis.isLoading = true;
    });
    builder.addCase(fetchDefault.fulfilled, (state, {payload}) => {
      const _list = [];
      const _category = [];
      let allEmojis = [];
      forEach(
        payload?.response?.packets?.filter((item) => item.is_enabled),
        (packet) => {
          const _emojis = packet.emojis?.map(({packet_id, emoji_id, code}) => {
            return {
              packet_id,
              emoji_id,
              code,
            };
          });
          _list.push({
            categoryName: packet.author_name.value,
            categoryFullName: packet.name.value,
            list: _emojis,
          });
          _category.push({
            ariaLabel: packet.name.value,
            author: packet.author_name.value,
            type: packet.type,
            version: packet.version,
            packet_id: packet.packet_id,
            is_enabled: packet.is_enabled,
            description: packet.description.value,
          });
          allEmojis = [...allEmojis, ..._emojis];
        },
      );
      state.defaultEmojis = {
        list: _list,
        category: _category,
        isLoading: false,
        allEmojis,
      };
    });
    builder.addCase(fetchDefault.rejected, (state) => {
      state.defaultEmojis.isLoading = false;
    });
    builder.addCase(fetchRecent.pending, (state) => {
      state.recentEmojis.isLoading = true;
    });
    builder.addCase(fetchRecent.fulfilled, (state, {payload}) => {
      const _emojis = intersectionBy(
        payload?.response?.emojis || [],
        state.defaultEmojis.allEmojis,
        "emoji_id",
      );
      const _list = [
        {
          categoryName: "Recent",
          categoryFullName: "Recent",
          list: _emojis.map(({packet_id, emoji_id, code}) => {
            return {
              packet_id,
              emoji_id,
              code,
            };
          }),
        },
      ];
      state.recentEmojis = {
        list: _list,
        isLoading: false,
      };
    });
    builder.addCase(fetchRecent.rejected, (state) => {
      state.recentEmojis.isLoading = false;
    });
    builder.addCase(fetchSearch.pending, (state) => {
      state.searchEmojis.isLoading = true;
    });
    builder.addCase(fetchSearch.fulfilled, (state, {payload}) => {
      if (state.searchEmojis.keyword !== payload?.response?.keyword) return;
      const _emojis = intersectionBy(
        payload?.response?.emojis || [],
        state.defaultEmojis.allEmojis,
        "emoji_id",
      );
      const _list = _emojis.map(({packet_id, emoji_id, code}) => {
        return {
          packet_id,
          emoji_id,
          code,
        };
      });
      state.searchEmojis.list = _list;
      state.searchEmojis.isLoading = false;
      state.cacheSearchResult[state.searchEmojis.keyword] = _list;
    });
    builder.addCase(fetchSearch.rejected, (state) => {
      state.searchEmojis.isLoading = false;
    });
    builder.addCase(fetchHeadlessSearch.pending, (state) => {
      state.headlessSearchEmojis.isLoading = true;
    });
    builder.addCase(fetchHeadlessSearch.fulfilled, (state, {payload}) => {
      if (state.headlessSearchEmojis.keyword !== payload?.response?.keyword)
        return;
      const _emojis = intersectionBy(
        payload?.response?.emojis || [],
        state.defaultEmojis.allEmojis,
        "emoji_id",
      );
      const _list = _emojis.map(({packet_id, emoji_id, code}) => {
        return {
          packet_id,
          emoji_id,
          code,
        };
      });
      state.headlessSearchEmojis.list = _list;
      state.headlessSearchEmojis.isLoading = false;
      state.cacheSearchResult[state.headlessSearchEmojis.keyword] = _list;
    });
    builder.addCase(fetchHeadlessSearch.rejected, (state) => {
      state.headlessSearchEmojis.isLoading = false;
    });
    builder.addCase(fetchUse.fulfilled, (state, {payload}) => {
      const {usedData} = payload.response;
      if (
        !find(
          state.recentEmojis.list[0]?.list || [],
          (r) => r.emoji_id === usedData.emoji_id,
        )
      ) {
        state.recentEmojis.list[0] = {
          list: uniqBy(
            [{...usedData}, ...(state.recentEmojis.list[0]?.list || [])],
            "emoji_id",
          ),
        };
      }
    });
  },
});
