import GAxios from "src/util/GAxios";

export const fetchHashtagListItems = async (
  phrase,
  hashtagOffset,
  max,
  callback,
  errorCallback,
) => {
  // Below-mentioned commented lines are removing the # and @ key  in the API payload
  // if (phrase.startsWith("#") || phrase.startsWith("@")) {
  //   phrase = phrase.substring(1);
  // }
  if (phrase.startsWith("%23") || phrase.startsWith("%40")) {
    phrase = phrase.substring(3);
  }

  await GAxios(
    {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/u/posts/srch/choices`,
      params: {
        phrase: phrase,
        offset: hashtagOffset,
        max,
      },
    },
    (res) => {
      callback(res);
    },
    (err) => {
      if (errorCallback) errorCallback(err);
    },
  );
};
