import GAxios from "src/util/GAxios";

export const deletePost = async (postId) => {
  if (!postId) return;

  let params = {content: {}};
  params = JSON.stringify(params);

  const config = {
    method: "delete",
    url: `${process.env.REACT_APP_API_URL}/u/post/${postId}`,
    data: params,
  };

  let res = {response: null, err: null};

  await GAxios(
    config,
    (response) => {
      if (response?.data?.result) {
        res.response = response.data.result;
      }
    },
    (err) => {
      res.err = err.response.data.error;
    },
  );

  return res;
};
