import {POST_TYPES} from "src/constants/live";

/**
 *
 * @param {string} postType
 * @returns
 */
export const isStream = (postType) =>
  postType === POST_TYPES.STREAM || postType === POST_TYPES.MAESTRO;

export const nFormatter = (num) => {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
};
