import GAxios from "src/util/GAxios";
import {userConstants} from "../_constants";

export const socialInitPassword = async (params) => {
  if (!params) {
    return;
  }
  const {email, vcode, newPass, errorCallback} = params;

  const data = {
    content: email
      ? {
          email: email,
          vcode: vcode,
          new_pwd: newPass,
        }
      : {
          new_pwd: newPass,
        },
  };

  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/v1/s/social/init_password`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  };

  let res = {result: null, err: null};

  await GAxios(
    config,
    (response) => {
      if (response && response?.data?.result) {
        res.result = response.data.result;
      } else {
        res = false;
      }
    },
    (err) => {
      res.err = err?.response;
      if (errorCallback) errorCallback(err);
    },
  );

  return res;
};

export const createPassword = async (params) => {
  if (!params) {
    return;
  }
  const {newPass, errorCallback} = params;

  const data = {
    content: {
      password: newPass,
    },
  };

  const config = {
    method: "post",
    url: userConstants.API_CREATE_PWD,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  };

  let res = {result: null, err: null};

  await GAxios(
    config,
    (response) => {
      if (response && response?.data?.result.success) {
        res.result = response.data.result.success;
      } else {
        res = false;
      }
    },
    (err) => {
      res.err = err?.response;
      if (errorCallback) errorCallback(err);
    },
  );

  return res;
};
