import {SupportedLanguageList} from "src/app/AppMessages";

export const getInterestCode = (code: string) =>
  SupportedLanguageList.find((lang) => lang.code === code)?.interestCode ||
  "en";

export const defaultLanguage = SupportedLanguageList[0].code;

export const getRequestedLanguage = (
  supportedLangs: readonly string[],
  requestedLangs: readonly string[],
) => {
  if (requestedLangs.length === 0 || supportedLangs.length === 0) {
    return defaultLanguage;
  }
  const scoreList = Array(supportedLangs.length).fill(0);
  for (let i = 0; i < requestedLangs.length; i++) {
    let requestLocale;
    try {
      requestLocale = new Intl.Locale(requestedLangs[i]);
    } catch (_) {
      continue;
    }
    for (let j = 0; j < supportedLangs.length; j++) {
      let currentLocale;
      try {
        currentLocale = new Intl.Locale(supportedLangs[j].replace(/_/g, "-"));
      } catch (_) {
        continue;
      }

      if (requestLocale.language === currentLocale.language) {
        scoreList[j] += 4;
      } else continue;
      scoreList[j] +=
        requestLocale.script && requestLocale.script === currentLocale.script
          ? 2
          : 0;
      scoreList[j] +=
        requestLocale.region && requestLocale.region === currentLocale.region
          ? 1
          : 0;
    }
    // find Max score
    let maxScore = 0;
    let maxScoreIndex = -1;
    for (let j = 0; j < scoreList.length; j++) {
      if (scoreList[j] > maxScore) {
        maxScore = scoreList[j];
        maxScoreIndex = j;
      }
    }
    if (maxScoreIndex !== -1) {
      return supportedLangs[maxScoreIndex];
    }
  }
  return defaultLanguage;
};
