import {login, otpLogin} from "./login";
import {getQrLoginStatus} from "./qrLogin";
import {logout} from "./logout";
import {loginRefresh} from "./loginRefresh";
import {sendVerifCode} from "./sendVerifCode";
import {sendVerifCodeSms} from "./sendVerifCodeSms";
import {changeUsername} from "./changeUsername";
import {verifCode, selfVerifCode} from "./verifCode";
import {changePassword} from "./changePassword";
import {changeLoginStep} from "./changeLoginStep";
import {
  checkUsername,
  checkUsernameSrc,
  checkUsernameExist,
} from "./checkUsername";
import {checkEmail} from "./checkEmail";
import {sendVerifCodeSignup} from "./sendVerifCodeSignup";
import {sendSelfVerifCode} from "./sendSelfVerifCode";
import {sendVerifCodeSignupSms} from "./sendVerifCodeSignupSms";
import {changeSignupStep} from "./changeSignupStep";
import {signup, expressSignup, getKeys} from "./signup";
import {addPinPost} from "./addPinPost";
import {deletePinPost} from "./deletePinPost";
import {quickSignup} from "./quickSignup";
import {sendVerifCodeRecoveryEmail} from "./sendVerifCodeRecoveryEmail";
import {sendVerifCodeRecoveryPhone} from "./sendVerifCodeRecoveryPhone";
import {bindEmail, bindEmailV2} from "./bindEmail";
import {bindSms, bindSmsV2} from "./bindSms";
import {changeEmail, changeEmailV2} from "./changeEmail";
import {changeSms, changeSmsV2} from "./changeSms";
import {canBroadcastLive} from "./canBroadcastLive";
import {
  changePasswordLoggedIn,
  changePasswordLoggedIn_V2,
} from "./changePasswordLoggedIn";
import {saveOusername} from "./saveOusername";
import {userFollowStatus} from "./userFollowStatus";
import {fetchUserInfo} from "./fetchUserInfo";
import {reportUser} from "./reportUser";
import {getPublicGlobals} from "./getPublicGlobals";
import {socialSignIn} from "./socialSignIn";
import {socialSignup} from "./socialSignup";
import {socialLinkSignIn} from "./socialLinkSignIn";
import {socialResendVerifyCode} from "./socialResendVcode";
import {getSocialStatus, getSocialConnections} from "./socialStatus";
import {socialDisconnect} from "./socialDisconnect";
import {socialInitPassword, createPassword} from "./socialInitPassword";
import {getAdsConfig} from "./getAdsConfig";
import {fetchIdentification} from "./fetchIdentification.js";
import {submitIdentification} from "./submitIdentification.js";
import {fetchEndorsement} from "./fetchEndorsement.js";
import {endorsementSize} from "./endorsementSize.js";
import {deleteEndorsement} from "./deleteEndorsement.js";
import {setEndorsement} from "./setEndorsement.js";

// TODO: prevent type widening
export const AuthApi = {
  login,
  otpLogin,
  getQrLoginStatus,
  logout,
  loginRefresh,
  sendVerifCode,
  sendVerifCodeSms,
  verifCode,
  selfVerifCode,
  changePassword,
  changeLoginStep,
  checkUsername,
  checkUsernameExist,
  checkUsernameSrc,
  checkEmail,
  sendVerifCodeSignup,
  sendSelfVerifCode,
  changeUsername,
  sendVerifCodeSignupSms,
  changeSignupStep,
  signup,
  addPinPost,
  deletePinPost,
  quickSignup,
  expressSignup,
  getKeys,
  sendVerifCodeRecoveryEmail,
  sendVerifCodeRecoveryPhone,
  bindEmail,
  bindEmailV2,
  bindSms,
  bindSmsV2,
  changeEmail,
  changeEmailV2,
  changeSms,
  changeSmsV2,
  canBroadcastLive,
  changePasswordLoggedIn,
  changePasswordLoggedIn_V2,
  saveOusername,
  userFollowStatus,
  fetchUserInfo,
  reportUser,
  getPublicGlobals,
  socialSignIn,
  socialSignup,
  socialLinkSignIn,
  socialResendVerifyCode,
  getSocialStatus,
  getSocialConnections,
  socialDisconnect,
  socialInitPassword,
  createPassword,
  getAdsConfig,
  fetchIdentification,
  submitIdentification,
  fetchEndorsement,
  endorsementSize,
  deleteEndorsement,
  setEndorsement,
};
