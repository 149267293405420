import GAxios from "src/util/GAxios";

export const aiCheckAllowed = async (userInfo) => {
  let aiAllowed = userInfo.premium.enabled;

  if (aiAllowed) {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/aiavatar/v1/version`,
      timeout: 60000,
      ignoreErrors: true,
      ignoreImperva: true,
    };

    await GAxios(config, (err) => {
      aiAllowed = err?.response?.data?.error?.code !== "E_NOT_ALLOWED";
    });
  }

  return {aiAllowed};
};
