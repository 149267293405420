import {remove} from "lodash-es";

export const initState = {
  fileUploadLimit: 10,
  imageNumberLimit: 10,
  disabledDragDrop: true,
  maxFileSize: 2 * 1024 * 1024,
  videoSizeLimit: 100 * 1024 * 1024,
  imageSizeLimit: 16 * 1024 * 1024,
  acceptTextFiles: true,
  files: [],
  prevFiles: [],
};

export const reducer = (state = initState, action) => {
  switch (action.type) {
    case "REST_FILEUPLOADER": {
      return {
        ...state,
        ...initState,
      };
    }
    case "CLEAN_FILES": {
      return {
        ...state,
        files: [],
      };
    }
    case "FILE_UPDATE": {
      const {files} = state;
      return {
        ...state,
        files: [...files, action.payload],
      };
    }
    case "CHANGE_FILES": {
      return {
        ...state,
        prevFiles: action.payload || [],
      };
      //   const filterFiles = action.payload;
      //   const {files} = state;
      //   const newFiles = [];
      //   files.map((file) => {
      //     const {name: _name, type: _type} = file.handle;
      //     for (var p in filterFiles) {
      //       const {name, type} = filterFiles[p];
      //       if (_name === name && type === _type) {
      //         newFiles.push(file);
      //         continue;
      //       }
      //     }
      //   });

      //   return {
      //     ...state,
      //     files: newFiles,
      //   };
    }
    case "ADD_FILE": {
      const file = action.payload;
      const {files} = state;
      return {
        ...state,
        files: [...files, file],
      };
    }
    case "REMOVE_FILE": {
      const fileName = action.payload;
      const {files} = state;

      const newFileList = remove(files, function (n) {
        if (n.name === fileName) {
          return true;
        }
      });

      return {
        ...state,
        files: newFileList,
      };
    }
    default:
      break;
  }
  return state;
};
