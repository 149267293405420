import Cookies from "js-cookie";
import {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {
  getChatToken,
  setChatClient,
  setUnreadCount,
  clearChat,
  setStreamChatStatus,
  setResetChannelsList,
} from "src/app/components/chat/store";
import {useUserSession} from "src/util/useUserSession";

const DM_CONNECT_EXPIRED_TIME = 1 * 24 * 60 * 60 * 1000; // 1 day
const DM_DOMAIN_FOR_GETTR = "https://chat.dm.gettr.com";
const DM_CONNECT_MIN_INTERVAL_TIME = 2000;
export function useStreamChat() {
  const dispatch = useDispatch();
  const {userInfo, authenticated} = useUserSession();
  const streamChatStatus = useSelector(
    (state) => state?.chat?.streamChatStatus,
  );
  const chatClient = useSelector((state) => state.chat.chatClient);
  const {pathname} = useLocation();
  const timeRef = useRef({
    offlineReconnecting: null,
    reload: null,
    latestConnectTime: 0,
  });

  const handleNewEvent = (event) => {
    const {total_unread_count, online, queriedChannels} = event;
    if (online === false) {
      clearTimeout(timeRef.current.offlineReconnecting);
      timeRef.current.offlineReconnecting = setTimeout(() => {
        dispatch(setStreamChatStatus("connecting"));
      }, 300);
      clearTimeout(timeRef.current.reload);
      timeRef.current.reload = setTimeout(() => {
        pathname === "/messages" && window.location.reload();
      }, 2500);
    }
    if (total_unread_count !== undefined) {
      dispatch(setUnreadCount({unreadCount: total_unread_count}));
    }
    if (queriedChannels?.channels) {
      clearTimeout(timeRef.current.reload);
    }
  };

  const saveTokenToCookie = (data) => {
    const ckey = userInfo._id + "_chatToken";
    if (data) {
      var inFifteenMinutes = new Date(
        new Date().getTime() + DM_CONNECT_EXPIRED_TIME,
      );
      Cookies.set(ckey, JSON.stringify(data), {
        expires: inFifteenMinutes,
      });
    } else {
      Cookies.remove(ckey);
    }
  };

  const getTokenFromCookie = () => {
    const ckey = userInfo._id + "_chatToken";
    const raw = Cookies.get(ckey);
    const payload = raw && JSON.parse(raw);
    return payload;
  };

  const connectToChat = async (StreamChat, apiKey, id, token) => {
    if (!apiKey || !id || !token) return null;
    const client = StreamChat.getInstance(apiKey, {
      timeout: 6000,
      baseURL: DM_DOMAIN_FOR_GETTR,
    });
    if (
      (client?.user && client?.user?.user_id !== userInfo._id) ||
      new Date().getTime() - timeRef.current.latestConnectTime <
        DM_CONNECT_MIN_INTERVAL_TIME
    ) {
      await disconnectChat(client).unwrap();
    }
    try {
      const response = await client.connectUser({id}, token);
      const unreadCount = response?.me?.total_unread_count;
      timeRef.current.latestConnectTime = new Date().getTime();
      dispatch(setChatClient({client, unreadCount}));
      return client;
    } catch (err) {
      console.error("Connection failed", err);
      return null;
    }
  };

  const connectByNewToken = async (StreamChat) => {
    const res = await dispatch(getChatToken()).unwrap();
    if (!res?.chat_id || !res?.token) {
      throw new Error("Init failed");
    }
    saveTokenToCookie(res);
    const client = await connectToChat(
      StreamChat,
      res?.key,
      res?.chat_id,
      res?.token,
    );
    if (!client) {
      console.error("Failed to connect with new token");
      saveTokenToCookie(null);
      pathname === "/messages" && window.location.reload();
    }
    return client;
  };

  const connectChat = () => {
    if (authenticated && userInfo) {
      const userIsQs = userInfo.status === "qs" || userInfo.status === "es";
      const userId = userInfo._id;
      // express user, no bind phone number or email
      if (!userId || userIsQs) {
        return;
      }
      (async () => {
        const {StreamChat} = await import("stream-chat");
        const payload = getTokenFromCookie();
        let client = payload
          ? await connectToChat(
              StreamChat,
              payload.key,
              payload.chat_id,
              payload.token,
            )
          : null;
        if (!client) {
          client = await connectByNewToken(StreamChat);
        }
        if (client) {
          client.on(handleNewEvent);
          dispatch(setStreamChatStatus("connected"));
          dispatch(setResetChannelsList(true));
        }
      })().catch((error) => {
        console.error("error:", error);
        saveTokenToCookie(null);
        pathname === "/messages" && window.location.reload();
      });
    }
  };

  const disconnectChat = async (client) => {
    if (!client) return;
    await client.disconnectUser();
    client.off(handleNewEvent);
    dispatch(clearChat());
  };

  useEffect(() => {
    if (streamChatStatus === "disconnected") {
      disconnectChat(chatClient);
    } else if (streamChatStatus === "connecting") {
      const timer = setTimeout(() => {
        connectChat();
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [authenticated, userInfo?._id, userInfo?.status, streamChatStatus]);
}
