import {fetchSearchChoices} from "./fetchSearchChoices";
import {fetchTopSearchResult} from "./fetchTopSearchResult";
import {fetchUserListItems} from "./fetchUserListItems";
import {fetchPhotoPostListItems} from "./fetchPhotoPostListItems";
import {fetchVideoPostListItems} from "./fetchVideoPostListItems";
import {fetchHashtagListItems} from "./fetchHashtagListItems";
import {fetchSuggestions} from "./fetchSuggestions";
import {fetchHashtagSuggestions} from "./fetchHashtagSuggestions";
import {fetchLiveStreamPostListItems} from "./fetchLiveStreamPostListItems";
import {fetchTrendingPostListItems} from "./fetchTrendingPostListItems";
import {fetchGTokListItems} from "./fetchGTokListItems";
import {fetchRecommendSearch} from "./fetchRecommendSearch";
import {fetchProfileSearchResult} from "./fetchProfileSearchResult";
import {fetchPopSearch} from "./fetchPopSearch";

// TODO: prevent type widening
export const SearchApi = {
  fetchSearchChoices,
  fetchTopSearchResult,
  fetchUserListItems,
  fetchPhotoPostListItems,
  fetchVideoPostListItems,
  fetchSuggestions,
  fetchHashtagListItems,
  fetchHashtagSuggestions,
  fetchLiveStreamPostListItems,
  fetchTrendingPostListItems,
  fetchGTokListItems,
  fetchRecommendSearch,
  fetchProfileSearchResult,
  fetchPopSearch,
};
