import {timelineConstants} from "src/app/components/timeline/_constants";
import GAxios from "src/util/GAxios";
import {isResponseOk} from "src/util/FeedUtils";

/**
 * /u/post/${postId}/comments
 * @param {*} postId
 * @returns
 */
export const fetchComments = async ({postId, cursor}) => {
  if (!postId) return null;
  if (postId === "null") return null;

  let params = {
    offset: 0,
    max: timelineConstants.MAX_BATCH_SIZE,
    dir: timelineConstants.DIRECTION_REVERSED,
    incl: "posts|stats|userinfo|shared|liked",
  };

  if (cursor !== 0) {
    params = {...params, ...{cursor}};
  }

  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/u/post/${postId}/comments`,
    params,
  };

  let res = {response: null, err: null};

  await GAxios(
    config,
    (response) => {
      if (response?.data?.result) {
        res.response = response.data.result;
      }
      if (isResponseOk(response)) {
        const list = response?.data?.result?.data?.list;
        const aux = response?.data?.result?.aux;
        res.response = {
          list,
          aux,
          cursor,
        };
      }
    },
    (err) => {
      res.err = err;
    },
  );

  return res;
};
