import {startsWith} from "lodash-es";
import {SUGGESTION_TYPES} from "src/app/components/search/_constant";

export const transformSearchChoices = (response: any) => {
  const list: string[] = response?.data?.list || [];
  const uinf: Record<string, any> = response?.aux?.uinf || [];
  const suggestion = list
    .filter(
      (item) =>
        !(
          startsWith(item, "#") ||
          (startsWith(item, "@") && uinf?.[item?.substring(1)])
        ),
    )
    .map((item, index) => {
      return {
        id: index,
        text: item,
        phrase: item,
        type: SUGGESTION_TYPES.SUGGESTION,
      };
    });
  const hashtagList = list
    .filter((item) => startsWith(item, "#"))
    .map((item, index) => ({
      id: index + suggestion?.length,
      name: item,
      phrase: item,
      type: SUGGESTION_TYPES.HASHTAG,
    }));
  const userList = list
    .filter((item) => startsWith(item, "@") && uinf?.[item?.substring(1)])
    .map((item, index) => {
      const userInfo = uinf[item.substring(1)];
      return {
        index: index + suggestion?.length + hashtagList?.length,
        id: userInfo?._id,
        isVerified: userInfo?.infl,
        nickname: userInfo?.nickname,
        ousername: userInfo?.ousername,
        username: userInfo?.username,
        desc: userInfo?.dsc,
        avatar: userInfo?.ico,
        premium: userInfo?.premium,
        badges: userInfo?.badges,
        phrase: userInfo?.ousername || userInfo?.username,
        type: SUGGESTION_TYPES.USER,
        userId: userInfo?._id,
      };
    });

  return {suggestion, hashtagList, userList};
};
