import GAxios from "src/util/GAxios";

export const getPublicGlobals = async () => {
  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/u/public_globals`,
  };

  let res = null;

  await GAxios(
    config,
    (response) => {
      if (response?.data?.result?.globals) {
        res = response.data.result.globals;
      }
    },
    (response) => {
      res = response;
    },
  );

  return res;
};
