import GAxios from "src/util/GAxios";
import {userConstants} from "../_constants";
import parsePhoneNumber from "libphonenumber-js";
import {emailSmsErrorCallback} from "../../../../util/ErrorCallback";

export const sendVerifCodeSignupSms = async (
  params,
  asyncDefaultParam = 0,
  isLogin = false,
  errorCallback,
) => {
  let {phone, reqtype} = params;
  if (!phone) return;

  const whatsapp = localStorage.getItem("Whatsapp");
  const isWhatsapp = whatsapp === "true";

  const smsParam = !isWhatsapp ? {sms: "+" + phone} : {whatsapp: "+" + phone};
  const url = !isWhatsapp
    ? userConstants.API_SEND_VERIF_CODE_SMS
    : userConstants.API_SEND_VERIF_CODE_WHATSAPP;

  if (!reqtype) {
    reqtype = isLogin
      ? userConstants.VERIF_CODE_REQ_TYPE_LOGIN
      : userConstants.VERIF_CODE_REQ_TYPE_SIGNUP;
  }
  const countrycode = "+" + parsePhoneNumber("+" + phone).countryCallingCode;
  const data = JSON.stringify({
    content: {
      ...smsParam,
      countrycode,
      reqtype,
    },
  });

  const config = {
    method: "post",
    url,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  let res = null;

  await GAxios(
    config,
    (response) => {
      if (response && response.data?.result?.success) {
        res = {...response.data?.result, ...params};
      } else {
        res = null;
      }
    },
    (err) => {
      res = emailSmsErrorCallback(err, "phone");
      if (errorCallback) errorCallback(err);
    },
  );
  return res;
};
