import GAxios from "src/util/GAxios";
import {userConstants} from "../_constants";

export const changePasswordLoggedIn = async (params) => {
  if (!params) {
    return;
  }

  const data = JSON.stringify({
    content: {
      cur_pwd: params.currentPass,
      new_pwd: params.newPass,
    },
  });

  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/u/user/${params.userId}/pwdchg?clear=true`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  let res = {result: null, err: null};

  await GAxios(
    config,
    (response) => {
      if (response && response?.data?.result) {
        res.result = response.data.result;
      } else {
        res = false;
      }
    },
    (err) => {
      res.err = err;
    },
  );

  return res;
};

export const changePasswordLoggedIn_V2 = async (params) => {
  const {cur_pwd, new_pwd, vtoken, successCallback, errorCallback} = params;
  if (!cur_pwd && !vtoken) {
    return;
  }

  const data = JSON.stringify({
    content: {
      cur_pwd,
      new_pwd,
      vtoken,
    },
  });

  const config = {
    method: "post",
    url: userConstants.API_CHANGE_PWD_V2,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  let res = {result: null, err: null};

  await GAxios(
    config,
    (response) => {
      if (response && response?.data?.result) {
        res.result = response.data.result;
      } else {
        res = false;
      }
      successCallback && successCallback(res);
    },
    (err) => {
      res.err = err;
      errorCallback && errorCallback(err);
    },
  );

  return res;
};
