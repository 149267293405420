// Colors as defined in Zeplin style guide as of Sep 19, 2021
// Do not add/change colors arbitrarily here. Always sync with Zepplin.
export const sharedThemePalette = {
  utilityWarning: "#c00",
  utilityWhite: "#fff",
  utilityBlack: "#000",
  utilityBlkLighten: "#2c2c2c",
  utilityGray: "#b0b0b0",
  utilityGreen: "#30d85f",
  utilityGray2: "#ebebeb",
  utilityAvatarBorder: "rgba(0, 0, 0, 0.04)",
  utilityRedHoverBg: "rgba(204, 0, 0, 0.4)",
  utilityBlueHoverBg: "rgba(1, 110, 220, 0.4)",
  utilityLightboxIconBgHover: "rgba(255, 255, 255, 0.25)",
  utilityGreenHoverBg: "rgba(81, 176, 108, 0.4)",
};

// New colors defined in Zeplin as of May 27, 2022
export const newStaticColors = {
  white: "#fff",
  offWhite: "#fafafa",
  black: "#000",
  offBlack: "#050505",
  hoverGey: "#4C4C4C",
  avatarBorder: "rgba(173, 173, 173, 0.33)",
  transition: {
    ["02"]: "all 0.2s ease-out",
    ["03"]: "all 0.3s ease-out",
    ["opacity"]: {
      ["03"]: "opacity 0.3s ease-out",
    },
    ["fillOpacity"]: {["03"]: "fill-opacity 0.3s ease-out"},
    ["border"]: {["02"]: "border 0.2s ease-out"},
    ["background"]: {
      ["02"]: "background 0.2s ease-out",
      ["03"]: "background 0.3s ease-out",
      ["5000"]: "background 5000s ease-out",
    },
    ["padding"]: {
      ["03"]: "padding 0.3s ease-out",
    },
    ["transform"]: {
      ["03"]: "transform 0.3s ease-out",
    },
    ["height"]: {
      ["05"]: "transform 0.5s ease-out",
    },
    ["width"]: {
      ["02"]: "width 0.2s cubic-bezier(0.4, 0, 1, 1)",
      ["1"]: "width 1s cubic-bezier(0.4, 0, 1, 1)",
    },
  },
  backgroundSbg: {
    [0]: "#1f1f1f",
    [1]: "#282a2a",
    [2]: "#333",
    [3]: "#444",
    [4]: "#5a5a5b",
    [5]: "#717172",
    [6]: "#8c8c8d",
    [7]: "#e1e2e3",
    [8]: "#e7e8e9",
    [9]: "#edeeee",
    [10]: "#f3f3f4",
    [11]: "#f9f9f9",
  },
  overlayGray: {
    [12]: "rgba(100, 100, 100, 0.12)",
    [24]: "rgba(100, 100, 100, 0.24)",
    [30]: "rgba(100, 100, 100, 0.3)",
    [40]: "rgba(100, 100, 100, 0.4)",
    [50]: "rgba(100, 100, 100, 0.5)",
    [66]: "rgba(100, 100, 100, 0.66)",
    [76]: "rgba(100, 100, 100, 0.76)",
    [82]: "rgba(100, 100, 100, 0.82)",
    [90]: "rgba(100, 100, 100, 0.9)",
    [97]: "rgba(100, 100, 100, 0.9)",
  },
  overlayBlack: {
    [12]: "rgba(0, 0, 0, 0.12)",
    [20]: "rgba(0, 0, 0, 0.2)",
    [24]: "rgba(0, 0, 0, 0.24)",
    [30]: "rgba(0, 0, 0, 0.3)",
    [40]: "rgba(0, 0, 0, 0.4)",
    [50]: "rgba(0, 0, 0, 0.5)",
    [66]: "rgba(0, 0, 0, 0.66)",
    [76]: "rgba(0, 0, 0, 0.76)",
    [82]: "rgba(0, 0, 0, 0.82)",
    [90]: "rgba(0, 0, 0, 0.9)",
    [97]: "rgba(0, 0, 0, 0.97)",
  },
  overlayWhite: {
    [12]: "rgba(255, 255, 255, 0.12)",
    [20]: "rgba(255, 255, 255, 0.2)",
    [24]: "rgba(255, 255, 255, 0.24)",
    [30]: "rgba(255, 255, 255, 0.3)",
    [40]: "rgba(255, 255, 255, 0.4)",
    [50]: "rgba(255, 255, 255, 0.5)",
    [66]: "rgba(255, 255, 255, 0.66)",
    [76]: "rgba(255, 255, 255, 0.76)",
    [82]: "rgba(255, 255, 255, 0.82)",
    [90]: "rgba(255, 255, 255, 0.9)",
    [97]: "rgba(255, 255, 255, 0.97)",
  },
};
