import GAxios from "src/util/GAxios";
import {userConstants} from "../_constants";
import parsePhoneNumber from "libphonenumber-js";
import {emailSmsErrorCallback} from "../../../../util/ErrorCallback";

export const sendVerifCodeSms = async (data, errorCallback) => {
  const {phone, token} = data;
  if (!phone) return;

  const whatsapp = localStorage.getItem("Whatsapp");
  const isWhatsapp = whatsapp === "true";

  const smsParam = !isWhatsapp ? {sms: "+" + phone} : {whatsapp: "+" + phone};
  const url = !isWhatsapp
    ? userConstants.API_SEND_VERIF_CODE_SMS
    : userConstants.API_SEND_VERIF_CODE_WHATSAPP;

  const content = JSON.stringify({
    content: {
      token: token,
      ...smsParam,
      countrycode: "+" + parsePhoneNumber("+" + phone).countryCallingCode,
      lang: "en", // [TODO]: will be replace when the current lang will be available in the Redux Store.
      reqtype: userConstants.SEND_VERIF_CODE_REQ_TYPE,
    },
  });

  const config = {
    method: "post",
    url,
    headers: {
      "Content-Type": "application/json",
    },
    data: content,
  };

  let res = null;

  await GAxios(
    config,
    (response) => {
      if (response && response.data?.result?.success) {
        res = phone;
      } else {
        res = null;
      }
    },
    (err) => {
      res = emailSmsErrorCallback(err, "phone");
      if (errorCallback) errorCallback(err);
    },
  );
  return res;
};
