import GAxios from "src/util/GAxios";

// Do not remove `parentId`, even if it appears unused it's necessary for updating post stats
export const deletePostComment = async ({commentId, parentId}) => {
  if (!commentId) return;

  let params = {content: {}};
  params = JSON.stringify(params);

  const config = {
    method: "delete",
    url: `${process.env.REACT_APP_API_URL}/u/comment/${commentId}`,
    data: params,
  };

  let res = {response: null, err: null};

  await GAxios(
    config,
    (response) => {
      if (response?.data?.result) {
        res.response = response.data.result;
      }
    },
    (err) => {
      res.err = true;
    },
  );

  return res;
};
