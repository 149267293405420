import GAxios from "src/util/GAxios";
import {userConstants} from "../_constants";

export const changePassword = async (params) => {
  const email = params.email;
  const phone = params.phone;
  const code = params.code;
  const newPwd = params.newPwd;

  if (!newPwd || (!email && !phone) || !code) {
    return;
  }

  const emailContent = {email};

  const whatsapp = localStorage.getItem("Whatsapp");
  const isWhatsapp = whatsapp === "true";
  const phoneContent = !isWhatsapp ? {sms: phone} : {whatsapp: phone};

  const data = JSON.stringify({
    content: {
      password: newPwd,
      verifycode: code,
      ...(email ? emailContent : phoneContent),
    },
  });

  const config = {
    method: "post",
    url: userConstants.API_CHANGE_PWD,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  let res = false;

  await GAxios(config, (response) => {
    localStorage.removeItem("Whatsapp");
    if (response && response.data?.result?.success) {
      res = true;
    } else {
      res = false;
    }
  });

  return res;
};
