export const postConstants = {
  PROP_ON_SUBMIT: "onSubmit",
  PROP_ON_CANCEL: "onCancel",
  PROP_IMAGE_FILES: "imgfspec",
  PROP_POPUP: "isPopup",
  PROP_TRIGGER: "trigger",
  COMPOSER_MAX_TEXT_LENGTH: 777,
  VISION_COMMENT_MAX_TEXT_LENGTH: 200,
  ACL_PERM_READ: 4,
  VISTYPE_PUBLIC: "p",
  POST_TYPE: "post",
  COMMENT_TYPE: "cmt",
  FILE_TYPE_GIF: "gif",
  FILE_TYPE_STICKER: "sticker",
  FILE_TYPE_IMAGE: "image",
  FILE_TYPE_VIDEO: "video",
  SUBMIT_POST_URL: `${process.env.REACT_APP_API_URL}/u/post`,
  SUBMIT_REPOST_URL: `${process.env.REACT_APP_API_URL}/u/repost`,
  SUBMIT_EDIT_POST_URL: `${process.env.REACT_APP_API_URL}/u/post/v2/change/text`,
  LS_REPOST_ID: "repostId",
  PROP_TEXT_COMP: "textComp",
  PROP_SCENE: "scene",
  STATE_IMAGES_OPEN: "imageURLsOpen",
  PROP_TEXT: "text",
  PROP_ORIGIN_COMP: "originPost",
  DELETEPOST: "DELETEPOST",
  TAB_LIKE: "tab_like",
};
