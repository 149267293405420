import {createAction, createSlice} from "@reduxjs/toolkit";

const NS = "dashboard";

const initialState = {
  isNewsDialogOpen: false,
  isLivestreamDialogOpen: false,
  isPopularUsersDialogOpen: false,
};

export const dashboardSlice = createSlice({
  name: NS,
  initialState,
  reducers: {
    setNewsDialogOpen: (state, {payload}) => {
      state.isNewsDialogOpen = payload;
    },
    setLivestreamDialogOpen: (state, {payload}) => {
      state.isLivestreamDialogOpen = payload;
    },
    setPopularUsersDialogOpen: (state, {payload}) => {
      state.isPopularUsersDialogOpen = payload;
    },
  },
});

export const setNewsDialogOpen = createAction<boolean>(
  `${NS}/setNewsDialogOpen`,
);

export const setLivestreamDialogOpen = createAction<boolean>(
  `${NS}/setLivestreamDialogOpen`,
);

export const setPopularUsersDialogOpen = createAction<boolean>(
  `${NS}/setPopularUsersDialogOpen`,
);
