import {fetchLiveSearchChoices} from "./fetchLiveSearchChoices";
import {fetchBigV} from "./fetchBigV";
import {fetchHosts} from "./fetchHosts";
import {fetchLive} from "./fetchLive";
import {fetchReplays} from "./fetchReplays";
import {fetchStreamData} from "./fetchStreamData";

export const LiveListSearchApi = {
  fetchLiveSearchChoices,
  fetchBigV,
  fetchHosts,
  fetchLive,
  fetchReplays,
  fetchStreamData,
};
