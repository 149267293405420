export const notificationConstants = {
  LIKE: "l",
  FOLLOWED: "f",
  REPOSTED: "r",
  MENTIONED: "m",
  COMMENT: "c",
  NEW_LOGIN: "lg",
  SHARED: "s",
  LIVE_STREAM: "ls",
  POLL_POST: "pp",
  MENTIONS_VISION: "vtg",
  MAX_BATCH_SIZE: 20,
  CHECK_NEW_BATCH_SIZE: 1,
  MENTIONS_ACTION_PARAM: "mc",
  NOTIFICATIONS_ACTION_PARAM: "exceptlspl",
  CHECK_NEW_NOTIFICATION_INTERVAL: 1000 * 60 * 5, // 5 mins
  POLL_NOTIFICATION: "poll",
  postTypes: {
    COMMENT: "cm",
    SHARE_COMMENT: "shares_cm",
    LIKE_COMMENT: "likes_cm",
    POST: "pst",
    SHARE_POST: "shares_pst",
    LIKE_POST: "likes_pst",
  },
};

export const POST_ID_PREFIX = "p";
