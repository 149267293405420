import {lazy} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import PrivateRoute from "src/app/components/PriviateRoute";
const EmptyAppLayout = lazy(() =>
  import("src/app/components/layout/EmptyAppLayout"),
);
const AuthLayout = lazy(() => import("src/app/components/layout/AuthLayout"));
const AnonDashboardRoute = lazy(() => import("./route-anondashboard"));
const NewDashboard = lazy(() => import("./route-dashboard"));
const NewNotFound = lazy(() => import("./route-notfound"));
const Privacy = lazy(() => import("./route-privacy"));
const NewSearchResults = lazy(() => import("./route-search"));
const SearchProfileResults = lazy(() => import("./route-search-profile"));
const NewHashtag = lazy(() => import("./route-tag"));
const Camera = lazy(() =>
  import("src/app/components/live/HostSide/main/camera"),
);
const Obs = lazy(() => import("src/app/components/live/HostSide/main/obs"));
const Rtmp4k = lazy(() =>
  import("src/app/components/live/HostSide/main/rtmp4k"),
);
const RedirectApp = lazy(() => import("src/app/pages/auth/RedirectApp"));
const GTokRedirect = lazy(() => import("src/app/routes/route-gtok-redirect"));
const EmbedPostDialog = lazy(() => import("../components/embedPost"));
const GettrPayActivationRoute = lazy(() =>
  import("./gettr-pay-routes/activation"),
);
const WalletLogout = lazy(() => import("./gettr-pay-routes/logout"));
const PaymentRoute = lazy(() => import("./gettr-pay-routes/payment"));
const PendingTransactionRoute = lazy(() =>
  import("./gettr-pay-routes/pending-transactions"),
);
const QrDetailsRoute = lazy(() => import("./gettr-pay-routes/qr-details"));
const RedeemRoute = lazy(() => import("./gettr-pay-routes/redeem"));
const RedeemHistoryRoute = lazy(() =>
  import("./gettr-pay-routes/redeemHistory"),
);
const RedemptionRoute = lazy(() => import("./gettr-pay-routes/redemption"));
const SelectUserRoute = lazy(() => import("./gettr-pay-routes/select-user"));
const TransactionDetailRoute = lazy(() =>
  import("./gettr-pay-routes/transaction-details"),
);
const WalletRoute = lazy(() => import("./gettr-pay-routes/wallet"));
const AnalyticsRoute = lazy(() => import("./route-analytics"));
const RouteChat = lazy(() => import("./route-chat"));
const CoinTerms = lazy(() => import("./route-coinTerms"));
const NewComment = lazy(() => import("./route-comment"));
const NewHelpCenter = lazy(() => import("./route-help-center-index"));
const InviteFriends = lazy(() => import("./route-invite-friends"));
const Invites = lazy(() => import("./route-invites"));
const NewLanding = lazy(() => import("./route-landing"));
const LiveMaestro = lazy(() => import("./route-live-maestro"));
const NewLogin = lazy(() => import("./route-login"));
const NewLogout = lazy(() => import("./route-logout"));
const NetworkFailed = lazy(() => import("./route-network-request-failed"));
const Notifications = lazy(() => import("./route-notifications"));
const OpenInApp = lazy(() => import("./route-open-app"));
const PepApp = lazy(() => import("./route-pepApp"));
const NewPost = lazy(() => import("./route-post"));
const PostHistory = lazy(() => import("./route-post-history"));
const ResetPassword = lazy(() => import("./route-reset-password"));
const NewSettings = lazy(() => import("./route-settings-index"));
const Share = lazy(() => import("./route-share"));
const NewSignup = lazy(() => import("./route-signup"));
const SocialCallback = lazy(() => import("./route-social-callback"));
const Streaming = lazy(() => import("./route-stream"));
const Terms = lazy(() => import("./route-terms"));
const UserFollowsPage = lazy(() => import("./route-user-follows"));
const UserInfoPage = lazy(() => import("./route-userinfo"));
const GTok = lazy(() => import("./route-gtok"));
const GTokFeedRoute = lazy(() => import("./route-gtok-feed"));
const GTokUnavailableRoute = lazy(() => import("./route-gtok-unavailable"));
const HomeLayout = lazy(() => import("src/app/components/layout/HomeLayout"));
const LiveListLayout = lazy(() =>
  import("src/app/components/LiveList/LiveListLayout"),
);
const LiveListSearchSubPageLayout = lazy(() =>
  import(
    "src/app/components/LiveList/LiveListSearch/LiveListSearchSubPageLayout"
  ),
);
const MainLayout = lazy(() => import("src/app/components/layout/MainLayout"));
const WalletLayout = lazy(() =>
  import("src/app/components/layout/WalletLayout"),
);
const LiveListFollowingRoute = lazy(() =>
  import("./livelist-routes/following"),
);
const LiveListHistoryRoute = lazy(() => import("./livelist-routes/history"));
const LiveListHomeRoute = lazy(() => import("./livelist-routes/home"));
const LiveListSearchHomeRoute = lazy(() => import("./livelist-routes/search"));
const LiveListSearchHostRoute = lazy(() =>
  import("./livelist-routes/search-host"),
);
const LiveListSearchNowRoute = lazy(() =>
  import("./livelist-routes/search-now"),
);
const LiveListUserRoute = lazy(() => import("./livelist-routes/user"));
const PremiumTerms = lazy(() => import("./route-premiumTerms"));
const LivePolicy = lazy(() => import("./route-livePolicy"));
// const ReferEarnRoute = lazy(() => import("./route-referAndEarn"));
// const ReferralStatusRoute = lazy(() => import("./route-referralStatus"));
const TopicsRoute = lazy(() => import("./route-topics"));
const Interest = lazy(() => import("src/app/components/auth/comps/Interest"));
const UserRoute = lazy(() => import("./route-usr"));
const StreamHostSideLayout = lazy(() => import("./route-streamHostSide"));
const MoreIndex = lazy(() => import("./route-more-index.js"));

// gettr pay routes
const gettrPayRoutes = [
  {path: "/wallet/activation", component: GettrPayActivationRoute},
  {path: "/wallet/qr-details", component: QrDetailsRoute},
  {path: "/wallet/GTR", component: WalletRoute},
  {path: "/wallet/select-user", component: SelectUserRoute},
  {
    path: "/wallet/transactions/:transactionId",
    component: TransactionDetailRoute,
  },
  {path: "/wallet/pending-requests", component: PendingTransactionRoute},
  {path: "/wallet/redeem", component: RedeemRoute},
  {path: "/wallet/send", component: PaymentRoute},
  {path: "/wallet/receive", component: PaymentRoute},
  {path: "/wallet/logout", component: WalletLogout},
  {path: "/wallet/redeem/history", component: RedeemHistoryRoute},
  {path: "/wallet/redeem/redemption", component: RedemptionRoute},
];

// refer and earn routes
// const referAndEarnRoutes = [
// {path: "/refer-earn", component: ReferEarnRoute},
// {path: "/referral-status", component: ReferralStatusRoute},
// ];

// Routes with regularLayout
const regularRoutes = [
  {path: "/payment/success", component: NewDashboard},
  {path: "/payment/cancel", component: NewDashboard},
  {path: "/hasht/:id", component: NewHashtag},
  {path: "/network-request-failed", component: NetworkFailed},
  {path: "/i/:id", component: Invites},
  {path: "/i", component: Invites},
  {path: "/gtok404", component: GTokUnavailableRoute},
  {path: "/embed-post/:id", component: EmbedPostDialog},
  {path: "/more", component: MoreIndex},
];

// Routes with new HomeLayout
const routesWithNewLayout = [
  {path: "/post/:id", component: NewPost},
  {path: "/comment/:id", component: NewComment},
  {path: "/history/:type/:id", component: PostHistory},
];

// notification pages
const notificationRoutes = [
  {
    path: "/notifications/mentions",
    element: <Notifications view="mentions" />,
  },
  {
    path: "/notifications/livestream",
    element: <Notifications view="livestream" />,
  },
  {
    path: "/notifications/polls",
    element: <Notifications view="polls" />,
  },
  {path: "/notifications", element: <Notifications view="" />},
  {path: "/notifications/verified", element: <Notifications view="" />},
];

// user profile pages
const userRoutes = [
  {index: true, element: <UserInfoPage />},
  {
    path: "comments",
    element: <UserInfoPage view="comments" />,
  },
  {
    path: "live",
    element: <UserInfoPage view="live" />,
  },
  {
    path: "gtok",
    element: <UserInfoPage view="gtok" />,
  },
  {
    path: "medias",
    element: <UserInfoPage view="medias" />,
  },
  {
    path: "likes",
    element: <UserInfoPage view="likes" />,
  },
  {
    path: "following",
    element: <UserFollowsPage view="following" />,
  },
  {
    path: "followers",
    element: <UserFollowsPage view="followers" />,
  },
  {
    path: "recommend",
    element: <UserFollowsPage view="suggested" />,
  },
];

// Routes with authLayout
const authRoutes = [
  {path: "/login", component: NewLogin},
  {path: "/signup", component: NewSignup},
  {path: "/open-app", component: OpenInApp},
  {path: "/redirect-app", component: RedirectApp},
  {path: "/social/google/callback", component: SocialCallback},
  {path: "/social/apple/callback", component: SocialCallback},
  {path: "/social/facebook/callback", component: SocialCallback},
  {path: "/oauth2/idpresponse", component: SocialCallback},
];

const protectedRoutes = [
  {path: "/messages", component: RouteChat},
  {path: "/messages/:id", component: RouteChat},
];

// Routes with no standard layout
export const miscRoutes = [
  {path: "/terms", component: NewHelpCenter},
  {path: "/coin-terms", component: NewHelpCenter},
  {path: "/tipping-terms", component: NewHelpCenter},
  {path: "/premium-terms", component: NewHelpCenter},
  {path: "/live-policy", component: NewHelpCenter},
  {path: "/monthly-giveaway-rules", component: NewHelpCenter},
  {path: "/privacy", component: NewHelpCenter},
  {path: "/legal", component: NewHelpCenter},
  {path: "/disclaimer", component: NewHelpCenter},
  {path: "/community-guidelines", component: NewHelpCenter},
];

// Routes with empty layout for app
const emptyAppRoutes = [
  {path: "/coin-terms-app", element: <CoinTerms />},
  {path: "/terms-app", element: <Terms />},
  {path: "/premium-terms-app", element: <PremiumTerms />},
  {path: "/privacy-app", element: <Privacy />},
  {path: "/pep-popup-app", element: <PepApp />},
  {path: "/live-policy-app", element: <LivePolicy />},
];

// Routes without navbar
const noNavBarRoutes = [
  {path: "/landing", component: NewLanding},
  {path: "/onboarding", component: () => <Navigate to="/landing" />},
  {path: "/logout", component: NewLogout},
];

// Routes with no Layout
const skipRoutes = [
  {path: "/vision/:id", component: () => <GTokRedirect isVideo={true} />},
  {path: "/vision", component: GTokRedirect},
  {path: "/live/:slug", component: LiveMaestro},
  {path: "/live/:slug/:channel", component: LiveMaestro},
];

export const AppRoutes = () => {
  return (
    <Routes>
      <Route
        element={
          <HomeLayout>
            <PrivateRoute />
          </HomeLayout>
        }
      >
        <Route path="/" element={<NewDashboard tab="explore" />} />
        <Route path="/explore" element={<Navigate to="/" />} />
        <Route path="/following" element={<NewDashboard />} />
        <Route path="/chill" element={<NewDashboard tab="chill" />} />
      </Route>
      <Route element={<HomeLayout />}>
        <Route
          path="/trending"
          element={<AnonDashboardRoute tab="trending" />}
        />
        <Route
          path="/trendingchill"
          element={<AnonDashboardRoute tab="trendingchill" />}
        />
        <Route path="/share" element={<Share />} />
      </Route>
      <Route element={<HomeLayout hideHeaderOnMobile />}>
        {routesWithNewLayout.map(
          ({path, component: Component, element}, idx) => {
            return (
              <Route path={path} element={element ?? <Component />} key={idx} />
            );
          },
        )}
      </Route>
      <Route element={<HomeLayout />}>
        {regularRoutes.map(({path, component: Component, element}, idx) => {
          return (
            <Route path={path} element={element ?? <Component />} key={idx} />
          );
        })}
      </Route>
      <Route path="/user/:id" element={<HomeLayout />}>
        {userRoutes.map((props, idx) => {
          return <Route {...props} key={idx} />;
        })}
      </Route>
      <Route element={<HomeLayout paddingTop />}>
        {notificationRoutes.map(({path, element}, idx) => {
          return <Route path={path} element={element} key={idx} />;
        })}
      </Route>
      <Route element={<HomeLayout />}>
        <Route path="/search">
          <Route index element={<NewSearchResults />} />
          <Route path="host" element={<LiveListSearchHostRoute />} />
          <Route path="now" element={<LiveListSearchNowRoute />} />
        </Route>
        <Route path="/search/profile" element={<SearchProfileResults />} />
      </Route>
      <Route
        element={
          <HomeLayout hideLeftSide hideRightSide>
            <WalletLayout>
              <PrivateRoute />
            </WalletLayout>
          </HomeLayout>
        }
      >
        {
          //referAndEarnRoutes
          //.concat(gettrPayRoutes)
          gettrPayRoutes.map(({path, component: Component}, idx) => (
            <Route path={path} element={<Component />} key={idx} />
          ))
        }
      </Route>

      <Route
        element={
          <MainLayout noHeader bg="auth">
            <AuthLayout />
          </MainLayout>
        }
      >
        <Route element={<PrivateRoute />}>
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/step_interest" element={<Interest />} />
        </Route>
        {authRoutes.map(({path, component: Component}, idx) => (
          <Route path={path} element={<Component />} key={idx} />
        ))}
      </Route>
      <Route element={<HomeLayout hideLeftSide hideRightSide sence="dm" />}>
        {protectedRoutes.map(({path, component: Component}, idx) => (
          <Route path={path} element={<Component />} key={idx} />
        ))}
      </Route>
      <Route element={<MainLayout noHeader />}>
        <Route path="/usr/magicemail" element={<UserRoute />} />
        <Route path="/gtok/:id" element={<GTok />} />
      </Route>
      {/* Routes without left and right sections */}
      <Route element={<HomeLayout hideLeftSide hideRightSide />}>
        <Route path="/gtok" element={<GTokFeedRoute />} />
        <Route path="/settings/:tab" element={<NewSettings />} />
      </Route>
      <Route
        path="/analytics"
        element={
          <HomeLayout hideLeftSide hideRightSide>
            <AnalyticsRoute />
          </HomeLayout>
        }
      />
      <Route element={<HomeLayout hideLeftSide hideRightSide />}>
        <Route path="/hashtag/:id" element={<TopicsRoute />} />
        <Route path="/hashtag" element={<TopicsRoute />} />
      </Route>
      <Route element={<LiveListLayout />}>
        <Route path="/lives" element={<Navigate to="/livenow" />} />
        <Route path="/livelist" element={<Navigate to="/livenow" />} />
        <Route path="/livenow">
          <Route index element={<LiveListHomeRoute />} />
          <Route path="search">
            <Route path="" element={<LiveListSearchHomeRoute />} />
            <Route element={<LiveListSearchSubPageLayout />}>
              <Route path="host" element={<LiveListSearchHostRoute />} />
              <Route path="now" element={<LiveListSearchNowRoute />} />
            </Route>
          </Route>
          <Route path="user/:userId" element={<LiveListUserRoute />} />
          <Route element={<PrivateRoute />}>
            <Route path="following" element={<LiveListFollowingRoute />} />
            <Route path="history" element={<LiveListHistoryRoute />} />
          </Route>
        </Route>
        <Route path="/streaming/:postId" element={<Streaming />} />
      </Route>
      <Route
        path="/streamHostSide"
        element={
          <MainLayout isLive>
            <PrivateRoute>
              <StreamHostSideLayout />
            </PrivateRoute>
          </MainLayout>
        }
      >
        <Route index element={<Obs />} />
        <Route path="camera" element={<Camera />} />
        <Route path="rtmp4k" element={<Rtmp4k />} />
      </Route>
      <Route element={<MainLayout noHeader />}>
        {noNavBarRoutes.map(({path, component: Component}, idx) => (
          <Route path={path} element={<Component />} key={idx} />
        ))}
        {skipRoutes.map(({path, component: Component}, idx) => (
          <Route path={path} element={<Component />} key={idx} />
        ))}
      </Route>
      <Route element={<HomeLayout hideLeftSide hideRightSide />}>
        <Route element={<PrivateRoute />}>
          <Route path="/invite-friends" element={<InviteFriends />} />
        </Route>
        {miscRoutes.map(({path, component: Component}, idx) => (
          <Route path={path} element={<Component />} key={idx} />
        ))}
      </Route>
      <Route element={<EmptyAppLayout />}>
        {emptyAppRoutes.map(({path, element}, idx) => (
          <Route path={path} element={element} key={idx} />
        ))}
      </Route>
      <Route
        path="*"
        element={
          <HomeLayout hideRightSide hideLeftSide>
            <NewNotFound />
          </HomeLayout>
        }
      />
    </Routes>
  );
};
