import {socialConstants, userConstants} from "../_constants";
import GAxios from "src/util/GAxios";

let loginData = {
  pt: "",
  payload: null,
  authenticated: false,
  userinfo: null,
  err: null,
};

export const socialLinkSignIn = async (params) => {
  if (!params) return;
  const {vcode, token, pvrinfo} = params;
  const pvr_key = (pvrinfo?.pvr_key || "").toLowerCase();
  let authinfo = null;

  switch (pvr_key) {
    case socialConstants.GOOGLE_PVR_KEY:
      authinfo = {gg_token: token};
      break;
    case socialConstants.APPLE_PVR_KEY:
      authinfo = {ap_token: token};
      break;
    case socialConstants.TWITTER_PVR_KEY:
      authinfo = {tw_token: token};
      break;
    case socialConstants.FACEBOOK_PVR_KEY:
      authinfo = {fb_token: token};
      break;
  }

  if (authinfo) {
    const data = {
      content: {
        pvrinfo: pvrinfo,
        authinfo: authinfo,
        sainfo: {
          vcode: vcode,
        },
      },
    };
    const config = {
      method: "post",
      url: process.env.REACT_APP_API_URL + "/v1/s/social/link_sign_in",
      headers: {
        "Content-Type": "application/json",
      },
      data,
    };

    const callback = (err, res) => {
      if (err) {
        const {response} = err;
        loginData.err = response?.data?.error
          ? response?.data?.error?.code
          : err.toString();
      } else {
        loginData.err = null;
        loginData.authenticated = !err;
        loginData.userinfo = res;

        if (loginData.authenticated && loginData.userinfo) {
          const sessionInfo = {
            authenticated: loginData.authenticated,
            userinfo: loginData.userinfo,
          };
          localStorage.setItem(
            userConstants.LS_SESSION_INFO,
            JSON.stringify(sessionInfo),
          );
        }
      }
    };
    const processResult = (result) => {
      let err = null;
      const {pt, payload} = result;

      loginData.pt = pt;
      loginData.payload = payload;

      let authenticated = payload["token"];
      let loadedUserInfo = payload["user"];

      if (authenticated && loadedUserInfo) {
        loadedUserInfo.token = authenticated;
      } else {
        err = new Error("Auth failed");
      }

      callback(err, loadedUserInfo);
    };

    await GAxios(
      config,
      (response) => {
        processResult(response.data.result);
      },
      (err) => {
        console.error(err);
        callback(err);
      },
    );
  } else {
    loginData.err = "Missing auth token";
  }

  return loginData;
};
