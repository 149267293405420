import GAxios from "src/util/GAxios";
import {CHAT_API} from "../index";

// ${CHAT_API}/channel/group/link        GET     ?channel_id=g6300e4349db9012402bdd7e4

export const getLinkByChannelId = async (channelId) => {
  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/${CHAT_API}/channel/group/link?channel_id=${channelId}`,
    ignoreErrors: true,
  };

  let res = null;

  await GAxios(
    config,
    (response) => {
      if (response?.data?.result) {
        res = response.data.result;
      }
    },
    (error) => {
      res = error;
    },
  );

  return res;
};
