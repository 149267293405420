import {fetchStickersDefault} from "./fetchStickersDefault";
import {fetchStickerRecent} from "./fetchStickerRecent";
import {fetchStickersSearch} from "./fetchStickersSearch";
import {fetchStickersStatus} from "./fetchStickersStatus";
import {fetchStickersMine} from "./fetchStickersMine";
import {fetchStickersUse} from "./fetchStickersUse";

export const StickerApi = {
  fetchStickersDefault,
  fetchStickerRecent,
  fetchStickersSearch,
  fetchStickersStatus,
  fetchStickersMine,
  fetchStickersUse,
};
