import React, {useEffect, useState} from "react";
import {ThemeProvider} from "@material-ui/core";

import getTheme from "src/styles/themes";

export const DEFAULT_THEME = "light";
export const DARK_THEME = "dark";
export const LOCAL_THEME = "theme";
export const SELECTED_THEME = "selected_theme";
const ORANGE_LIGHT_THEME = "orange_light";
const ORANGE_DARK_THEME = "orange_dark";
const GREEN_LIGHT_THEME = "green_light";
const GREEN_DARK_THEME = "green_dark";
const PURPLE_LIGHT_THEME = "purple_light";
const PURPLE_DARK_THEME = "purple_dark";

export const getThemeOptions = [
  DEFAULT_THEME,
  DARK_THEME,
  ORANGE_LIGHT_THEME,
  ORANGE_DARK_THEME,
  GREEN_LIGHT_THEME,
  GREEN_DARK_THEME,
  PURPLE_LIGHT_THEME,
  PURPLE_DARK_THEME,
];

export const getSelectedTheme = () => {
  const localTheme = localStorage.getItem(LOCAL_THEME);
  const savedTheme = localStorage.getItem(SELECTED_THEME);
  if (getThemeOptions?.includes(savedTheme)) {
    return savedTheme;
  } else if (getThemeOptions?.includes(localTheme)) {
    return localTheme;
  } else {
    return window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
      ? DARK_THEME
      : DEFAULT_THEME;
  }
};

export const getCurrentTheme = () => {
  const savedTheme = localStorage.getItem(LOCAL_THEME);
  if (getThemeOptions.includes(savedTheme)) {
    return savedTheme;
  } else {
    return window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
      ? DARK_THEME
      : DEFAULT_THEME;
  }
};

/**
 * @typedef {import("react")} React
 */

/**
 * @typedef {object} UIContext
 * @property {string} currentTheme
 * @property {React.Dispatch<string>} setTheme
 */

/**
 * @type {React.Context<UIContext>}
 */
export const UIContext = React.createContext({
  currentTheme: getCurrentTheme(),
  setTheme: () => {},
});

export const MuiThemeProvider = ({children}) => {
  const [themeName, _setThemeName] = useState(getCurrentTheme());
  const [theme, setTheme] = useState(getTheme(themeName));

  const setThemeName = (name) => {
    localStorage.setItem(LOCAL_THEME, name);
    localStorage.setItem(SELECTED_THEME, name);
    _setThemeName(name);
    setTheme(getTheme(name));
  };

  let contextValue = {
    currentTheme: themeName,
    setTheme: setThemeName,
  };

  useEffect(() => {
    if (theme?.palette?.type === "dark") {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
  }, [theme]);

  return (
    <UIContext.Provider value={contextValue}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </UIContext.Provider>
  );
};

export default UIContext;
