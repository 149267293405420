import GAxios from "src/util/GAxios";
import {convertSearchFilters} from "src/app/components/SearchFilter/utils";
import {SEARCH_APIS} from "src/app/components/search/_constant";

export const fetchTopSearchResult = async (
  data,
  cursor,
  max,
  callback,
  errorCallback,
  sort,
  api = SEARCH_APIS.POST,
) => {
  const q = data.q;
  const filter = convertSearchFilters(data);
  const content = {
    incl: "poststats|shared|liked|posts|userinfo|followings|followers",
    q,
    filter,
    offset: 0,
    max,
    sort,
  };

  if (cursor?.[api]) {
    content.cursor = cursor?.[api];
  }

  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}${api}`,
    data: {
      content,
    },
    ignoreErrors: api === SEARCH_APIS.POST,
  };
  await GAxios(
    config,
    (res) => {
      res.api = api;
      callback?.(res);
    },
    async (err) => {
      if (api === SEARCH_APIS.POST)
        await fetchTopSearchResult(
          data,
          cursor,
          max,
          callback,
          errorCallback,
          sort,
          SEARCH_APIS.POST_V1,
        );
      if (api === SEARCH_APIS.POST_V1 && errorCallback) errorCallback(err);
    },
  );
};
