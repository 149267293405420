import {lazy, Suspense, useEffect, useMemo, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {parse} from "qs";
import Cookies from "js-cookie";
import "react-day-picker/dist/style.css";

import {cacheI18nT} from "src/i18n/utils";
import {AppRoutes} from "src/app/routes";
import {useUserSession} from "src/util/useUserSession";
import {getDeviceType} from "src/util/BrowserUtils";
import {gettrAndroidPlay, gettrAppleStore} from "src/constants/downloads";
import {pageReloadSendEventTrack} from "src/util/trackEvent";

import useCommonConfigs from "src/hooks/useCommonConfigs";
import {GHelmet} from "src/styles/components/GHelmet";
import {GLoader} from "src/styles/components/GLoader";
import {useStreamChat} from "src/hooks/useStreamChat";
import {homePagePaths} from "src/constants/home";

const AppLazy = lazy(() => import("src/app/AppLazy"));

export const App = () => {
  const {pathname, state} = useLocation();
  const navigate = useNavigate();
  const {isAndroid, isIos} = getDeviceType();
  const {userInfo, authenticated} = useUserSession();
  const [initIntercom, setInitIntercom] = useState(false);

  useCommonConfigs();
  useStreamChat();

  const {t} = useTranslation();
  cacheI18nT(t);

  useEffect(() => {
    if (authenticated && process.env.REACT_APP_HOST_ENV === "production") {
      const imperva = Cookies.get("reese84");
      if (!imperva) {
        navigate("/logout?refresh=1");
      }
    }
  }, [authenticated, userInfo?._id]);

  const downloadLink = () => {
    const query = parse(location.search.slice(1)).app;
    if (query === "1") {
      if (isAndroid) {
        location.replace(gettrAndroidPlay);
      } else if (isIos) {
        location.replace(gettrAppleStore);
      }
    }
  };

  useEffect(() => {
    downloadLink();
    pageReloadSendEventTrack(userInfo);
  }, []);

  useEffect(() => {
    if (!homePagePaths.includes(pathname)) {
      const isUserProfile = pathname.startsWith("/user/");
      if (!isUserProfile || !state?.fromTabs) {
        window.scrollTo(0, 0);
      }
    }
    if (window.Intercom) {
      window.Intercom("update");
    }
  }, [pathname]);

  useEffect(() => {
    if (window.Intercom) {
      setTimeout(() => {
        setInitIntercom(true);
      }, 700);
    }
  }, [window.Intercom]);

  useEffect(() => {
    if (initIntercom) {
      if (window.Intercom && authenticated && userInfo?._id) {
        window.Intercom("boot", {
          api_base: "https://api-iam.intercom.io",
          app_id: "eh9byr4a",
          user_id: userInfo._id,
          name: userInfo.ousername || userInfo.username,
          created_at: userInfo.cdate,
          hide_default_launcher: true,
          horizontal_padding: 60,
        });
      } else if (window.Intercom) {
        window.Intercom("boot", {
          api_base: "https://api-iam.intercom.io",
          app_id: "eh9byr4a",
          hide_default_launcher: true,
          horizontal_padding: 60,
        });
      }
    }
  }, [initIntercom]);

  const helmet = useMemo(() => <GHelmet type="global" />, []);

  return (
    <>
      {helmet}
      <Suspense fallback={<GLoader size={48} />}>
        <AppRoutes />
      </Suspense>
      <Suspense fallback={null}>
        <AppLazy />
      </Suspense>
    </>
  );
};
