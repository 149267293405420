import {createAction, createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {NotificationsApi} from "../api";
import {uniqBy, isBoolean} from "lodash-es";
import {notificationConstants} from "../_constants";

const SHOW_FROM_VERIFIED_USERS = "showNotificationsFromVerifiedUsers";
const NS = "notifications";

const initialState = {
  allNotifications: {
    loadedOnce: false,
    isLoading: false,
    next: null,
    notificationsList: null,
    isDone: false,
    userInfos: null,
    postInfos: null,
  },
  livestreamNotifications: {
    loadedOnce: false,
    isLoading: false,
    next: null,
    list: null,
    isDone: false,
    userInfos: null,
  },
  mentions: {
    isLoading: false,
    next: null,
    mentionsList: null,
    isDone: false,
    userInfos: null,
    postInfos: null,
  },
  unread: 0,
  livestreamUnread: 0,
  lastSeenTimeStamp: Date.now(),
  notifRingMe: {
    isLiveUser: false,
    initialed: false,
    ringMeIcon: false,
    ringMeLs: false,
    ringMePost: false,
  },
  showFromVerifiedUsers:
    localStorage.getItem(SHOW_FROM_VERIFIED_USERS) === "true",
  pollNotifications: {
    loadedOnce: false,
    isLoading: false,
    next: null,
    list: null,
    isDone: false,
    userInfos: null,
  },
  pollUnread: 0,
  notifUserList: [],
};

export const getNotifications = createAsyncThunk(
  `${NS}/getNotifications`,
  NotificationsApi.getNotifications,
);
export const getLivestreamNotifications = createAsyncThunk(
  `${NS}/getLivestreamNotifications`,
  NotificationsApi.getLivestreamNotifications,
);
export const getMentions = createAsyncThunk(
  `${NS}/getMentions`,
  NotificationsApi.getMentions,
);
export const getUnread = createAsyncThunk(
  `${NS}/getUnread`,
  NotificationsApi.getUnread,
);
export const getLivestreamUnread = createAsyncThunk(
  `${NS}/getLivestreamUnread`,
  NotificationsApi.getLivestreamUnread,
);
export const updateLastSeenTimeStamp = createAction(
  `${NS}/updateLastSeenTimeStamp`,
);
export const resetNotifications = createAction(`${NS}/resetNotifications`);

export const getIsLiveUser = createAsyncThunk(
  `${NS}/getIsLiveUser`,
  NotificationsApi.getIsLiveUser,
);
export const getRingMeState = createAsyncThunk(
  `${NS}/getRingMeState`,
  NotificationsApi.getRingMeState,
);
export const updateRingMeState = createAsyncThunk(
  `${NS}/updateRingMeState`,
  NotificationsApi.updateRingMeState,
);

export const removeNotification = createAction(`${NS}/removeNotification`);

export const toggleFromVerifiedUsers = createAction(
  `${NS}/toggleFromVerifiedUsers`,
);

export const notifUserListAction = createAction(`${NS}/notifUserListAction`);

export const deleteNotification = createAsyncThunk(
  "post/deleteNotification",
  NotificationsApi.deleteNotification,
);

export const getPollNotifications = createAsyncThunk(
  `${NS}/getPollNotifications`,
  NotificationsApi.getPollNotifications,
);

export const getPollUnread = createAsyncThunk(
  `${NS}/getPollUnread`,
  NotificationsApi.getPollUnread,
);

export const resetRingMeState = createAction(`${NS}/resetRingMeState`);

export const notificationSlice = createSlice({
  name: NS,
  initialState,
  reducers: {
    updateLastSeenTimeStamp: (state) => {
      return {...state, lastSeenTimeStamp: Date.now()};
    },
    resetNotifications: (state, action) => {
      const showFromVerifiedUsers = action.payload?.reset
        ? false
        : state.showFromVerifiedUsers;
      localStorage.setItem(SHOW_FROM_VERIFIED_USERS, showFromVerifiedUsers);
      return {
        ...initialState,
        // Prevent unread reset.
        livestreamUnread: state.livestreamUnread,
        unread: state.unread,
        showFromVerifiedUsers,
        lastSeenTimeStamp: Date.now(),
      };
    },
    resetRingMeState: (state) => {
      state.notifRingMe = initialState.notifRingMe;
    },
    removeNotification: (state, action) => {
      const {id, action: notificationAction} = action.payload;
      if (
        notificationAction === notificationConstants.COMMENT ||
        notificationAction === notificationConstants.MENTIONED
      ) {
        state.mentions.mentionsList = state.mentions.mentionsList?.filter(
          (notificaiton) => notificaiton?.msgId !== id,
        );
      }
      if (notificationAction === notificationConstants.LIVE_STREAM) {
        state.livestreamNotifications.list =
          state.livestreamNotifications?.list?.filter(
            (notificaiton) => notificaiton?.msgId !== id,
          );
      }
      state.allNotifications.notificationsList =
        state.allNotifications?.notificationsList?.filter(
          (notificaiton) => notificaiton?.msgId !== id,
        );
      state.pollNotifications.list = state.pollNotifications?.list?.filter(
        (notificaiton) => notificaiton?.msgId !== id,
      );
    },
    toggleFromVerifiedUsers: (state, action) => {
      localStorage.setItem(SHOW_FROM_VERIFIED_USERS, action.payload);
      return {
        ...initialState,
        showFromVerifiedUsers: action.payload,
        lastSeenTimeStamp: Date.now(),
      };
    },
    notifUserListAction: (state, action) => {
      state.notifUserList = action.payload;
    },
  },
  extraReducers: (builder) => {
    // all notifications except streaming ones
    builder.addCase(getNotifications.pending, ({allNotifications}) => {
      allNotifications.isLoading = true;
    });
    builder.addCase(getNotifications.fulfilled, (state, {payload}) => {
      const {recordList, isDone, next, postInfos, userInfos} = payload;
      const temp = [];
      var postInfoArr = [];
      if (postInfos) {
        Object.keys(postInfos).forEach((key) =>
          temp.push({
            postInfo: postInfos[key],
          }),
        );
        postInfoArr =
          temp && temp.length
            ? temp.map((data) => {
                return data.postInfo;
              })
            : [];
      }
      const allList = state.allNotifications.notificationsList || [];
      const mergedList = uniqBy([...allList, ...recordList], "msgId");
      const sortedList = mergedList.sort((a, b) => b.cdate - a.cdate);
      return {
        ...state,
        allNotifications: {
          ...state.allNotifications,
          loadedOnce: true,
          isLoading: false,
          notificationsList: sortedList,
          userInfos: {...userInfos},
          next,
          isDone: isBoolean(isDone) ? isDone : true,
          postInfos: postInfoArr,
        },
      };
    });
    builder.addCase(
      getNotifications.rejected,
      ({allNotifications}, {payload}) => {
        allNotifications.isDone = true;
        allNotifications.isLoading = false;
      },
    );
    // getLivestreamNotifications
    builder.addCase(
      getLivestreamNotifications.pending,
      ({livestreamNotifications}) => {
        livestreamNotifications.isLoading = true;
      },
    );
    builder.addCase(
      getLivestreamNotifications.fulfilled,
      (state, {payload}) => {
        const {recordList, isDone, next, userInfos} = payload;
        const list = state.livestreamNotifications?.list || [];
        const mergedList = uniqBy([...list, ...recordList], "msgId");
        const sortedList = mergedList.sort((a, b) => b.cdate - a.cdate);

        state.livestreamNotifications = {
          loadedOnce: true,
          isLoading: false,
          list: sortedList,
          next,
          isDone: isBoolean(isDone) ? isDone : true,
          userInfos,
        };
      },
    );
    builder.addCase(
      getLivestreamNotifications.rejected,
      ({livestreamNotifications}) => {
        livestreamNotifications.isDone = true;
        livestreamNotifications.isLoading = false;
      },
    );
    // mentions
    builder.addCase(getMentions.fulfilled, (state, {payload}) => {
      const {recordList, isDone, next, postInfos, userInfos} = payload;
      const temp = [];
      var postInfoArr = [];
      if (postInfos) {
        Object.keys(postInfos).forEach((key) =>
          temp.push({
            postInfo: postInfos[key],
          }),
        );
        postInfoArr =
          temp && temp.length
            ? temp.map((data) => {
                return data.postInfo;
              })
            : [];
      }
      return {
        ...state,
        mentions: {
          ...state.mentions,
          isLoading: false,
          mentionsList: uniqBy(
            [...(state.mentions.mentionsList || []), ...recordList],
            "msgId",
          ),
          next,
          isDone: isBoolean(isDone) ? isDone : true,
          userInfos: userInfos,
          postInfos: postInfoArr,
        },
      };
    });
    builder.addCase(getMentions.pending, ({mentions}) => {
      mentions.isLoading = true;
    });
    builder.addCase(getMentions.rejected, ({mentions}, {payload}) => {
      console.error({payload});
    });
    // unread
    builder.addCase(getUnread.fulfilled, (state, {payload}) => {
      const {data} = payload;
      return {
        ...state,
        unread: data?.unread,
      };
    });

    builder.addCase(getLivestreamUnread.fulfilled, (state, {payload}) => {
      const {data} = payload;
      state.livestreamUnread = data?.unread || 0;
    });

    // get is live user
    builder.addCase(getIsLiveUser.fulfilled, (state, {payload}) => {
      state.notifRingMe.isLiveUser = payload;
    });

    // get ring me state
    builder.addCase(getRingMeState.fulfilled, (state, {payload}) => {
      const {ringme_ls, ringme_post} = payload;
      if (ringme_ls === 1 || ringme_post === 1) {
        state.notifRingMe.ringMeIcon = true;
      }
      state.notifRingMe.ringMeLs = ringme_ls === 1;
      state.notifRingMe.ringMePost = ringme_post === 1;
      state.notifRingMe.initialed = true;
    });

    // update ring me state
    builder.addCase(updateRingMeState.fulfilled, (state, {payload}) => {
      const {notifSwitch, notifState} = payload;
      if (notifSwitch === "ringme_ls") {
        state.notifRingMe.ringMeLs = ["1", 1].includes(notifState);
      }
      if (notifSwitch === "ringme_post") {
        state.notifRingMe.ringMePost = ["1", 1].includes(notifState);
      }
      if (state.notifRingMe.ringMeLs || state.notifRingMe.ringMePost) {
        state.notifRingMe.ringMeIcon = true;
      } else {
        state.notifRingMe.ringMeIcon = false;
      }
    });

    // getPollNotifications
    builder.addCase(getPollNotifications.pending, ({pollNotifications}) => {
      pollNotifications.isLoading = true;
    });

    builder.addCase(getPollNotifications.fulfilled, (state, {payload}) => {
      const {recordList, isDone, next, userInfos} = payload;
      const allList = state.pollNotifications.list || [];
      const mergedList = uniqBy([...allList, ...recordList], "msgId");
      const sortedList = mergedList.sort((a, b) => b.cdate - a.cdate);
      state.pollNotifications = {
        loadedOnce: true,
        isLoading: false,
        list: sortedList,
        next,
        userInfos: userInfos,
        isDone: isBoolean(isDone) ? isDone : true,
      };
    });

    builder.addCase(getPollNotifications.rejected, ({pollNotifications}) => {
      pollNotifications.isDone = true;
      pollNotifications.isLoading = false;
    });

    // getPollUnreadCount
    builder.addCase(getPollUnread.fulfilled, (state, {payload}) => {
      const {data} = payload;
      state.pollUnread = data?.poll || 0;
    });
  },
});
