import moment from "moment";
import {getLang, returnMomentLocale, t} from "src/i18n/utils";
import {TimeUtil} from "./TimeUtil";
import {showToast} from "./showToast";
import AppConsts, {STICKER_PRICE_DIVISOR} from "src/app/AppConsts";

export const Currencies = [
  {
    name: "United States",
    symbol: "USD",
  },
  {
    name: "China",
    symbol: "CNY",
  },
  // {
  //   name: "United Kingdom",
  //   symbol: "GBP",
  // },
  // {
  //   name: "Vietnam",
  //   symbol: "VND",
  // },
  // {
  //   name: "Netherlands",
  //   symbol: "EUR",
  // },
];

export const transactionTypes = [
  {id: "ALL", value: "All"},
  {id: "SEND", value: "Send"},
  {id: "RECEIVE", value: "Receive"},
  {id: "AIRDROP", value: "Airdrop"},
  {id: "REDEEM", value: "Redeem"},
  {id: "TIP", value: "Tip"},
  {id: "REFER_REWARD", value: "refer_reward"},
];

export const transactionStatus = [
  {id: "ALL", value: "All"},
  {id: "COMPLETED", value: "Success"},
  {id: "PENDING", value: "Pending"},
  {id: "FAILED", value: "Failed"},
  // {id: "REJECTED", value: "Rejected"},
];

export const coins = {
  GTR: "GTR",
};

export const TippingAmountTabs = [10, 20, 50, "custom"];
export const TransactionsTabs = [
  "all",
  "today",
  "thisWeek",
  "last30Days",
  "last3Months",
  "last6Months",
];
export const RefferedStatusTabs = ["ALL", "COMPLETED", "PENDING"];
export const genericConstants = {
  CURRENCY: "CURRENCY",
  TIMEOUT: "TIMEOUT",
  TIMEOUTID: "TIMEOUTID",
  WALLET: "WALLET",
  COUNTRY: "COUNTRY",
  PAY_RECENT_SEARCH: "PAY_RECENT_SEARCH",
  REDEEM_COUNTRY: "REDEEM_COUNTRY",
  REDEEM_STATE: "REDEEM_STATE",
  IS_COUNTRY_SELECTED: "IS_COUNTRY_SELECTED",
  EVENT: "event",
  STATUS: "status",
};
export const StatusConstants = {
  REJECTED: "REJECTED",
  PENDING: "PENDING",
  DELIVERY_FAILED: "DELIVERY_FAILED",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
  ACCEPTED: "ACCEPTED",
  APPROVED: "APPROVED",
  NOT_SUBMITTED: "NOT_SUBMITTED",
  SUBMITTED: "SUBMITTED",
  VERIFIED: "VERIFIED",
  ALREADY_VERIFIED: "ALREADY_VERIFIED",
  REVIEWED: "Reviewed",
  DECLINED: "Declined",
  PENDING_STATUS: "Pending",
  LITE_VERIFICATION: "LITE_VERIFICATION",
  WALLET_ONBOARDING: "WALLET_ONBOARDING",
};

export const TransactionEvents = {
  SEND: "SEND",
  BUY: "BUY",
  RECEIVE: "RECEIVE",
  REWARD: "REWARD",
  ALL: "ALL",
  REDEEM: "REDEEM",
  AIRDROP: "AIRDROP",
  AUTO_AIRDROP: "AUTO_AIRDROP",
  TIP: "TIP",
  TIP_STICKER: "TIP_STICKER",
  REFER_REWARD: "REFER_REWARD",
  ACTION_REWARD: "ACTION_REWARD",
};

export const PendingTransactionType = {
  RECEIVE: "RECEIVE_REQUEST",
  SEND: "SEND_REQUEST",
};

export const sentenceCase = (string) => {
  if (!string) {
    return "";
  }
  return string.slice(0, 1) + string.slice(1, string.length).toLowerCase();
};
export const getDateFormat = (date, isTxn = true) => {
  if (!date || isNaN(Number(date))) {
    return "";
  }
  date = Number(date);
  let diff = moment().diff(moment(date), "seconds");
  if (diff < 60) {
    return `${moment(date).format("m")} ${t(
      "getter_fe.gettrPay.timings.seconds",
    )} ${t("getter_fe.gettrPay.timings.ago")}`;
  }
  diff = moment().diff(moment(date), "minutes");
  if (diff <= 5) {
    return `${diff} ${
      diff > 1
        ? t("getter_fe.gettrPay.timings.minutes")
        : t("getter_fe.gettrPay.timings.minute")
    } ${t("getter_fe.gettrPay.timings.ago")}`;
  }
  let locale = getLang();
  if (locale !== "en") {
    locale = returnMomentLocale(locale);
  }
  return moment(date).locale(locale).format("MMM Do, YYYY · hh:mm A");
};

export const getDateFormatNew = (date) => {
  return moment(date).format("MM/DD/YYYY");
};

export const getFormattedName = (name) => {
  return (
    String(name).slice(0, 1).toUpperCase() + String(name).slice(1).toLowerCase()
  );
};

export const shorten = (b, amountL = 30, amountR = 5, stars = 3) => {
  return `${b.slice(0, amountL)}${".".repeat(stars)}${b.slice(
    b.length - 5,
    b.length,
  )}`;
};
export function fixedDecimal(value, fractionDigits) {
  const fixed = parseFloat(value).toFixed(fractionDigits).toString();
  if (fixed.length < parseFloat(value).toString().length) value = fixed;
  return value;
}
export function checkNumberInput(e, invalidChars = ["-", "+", "e", "E"]) {
  const regex = new RegExp(
    /(^\d*\.?\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight|Enter)/,
  );
  if (invalidChars.includes(e.key) || !e.key.match(regex)) {
    showToast(
      AppConsts.NOTIF_WARNING,
      t("getter_fe.gettrPay.errors.NUMERIC_VALUE_ALLOWED"),
    );
    e.preventDefault();
    e.target.blur();
  }
}
export const getStartAndEndTime = (data) => {
  let startTime = 0;
  switch (data) {
    case "today":
      startTime = moment().startOf("d").valueOf();
      break;
    case "week":
      startTime = moment().subtract(7, "d").startOf("d").valueOf();
      break;
    case "thisWeek":
      startTime = moment().startOf("week").startOf("d").valueOf();
      break;
    case "last30Days":
      startTime = moment().subtract(30, "d").startOf("d").valueOf();
      break;
    case "last3Months":
      startTime = moment().subtract(3, "M").startOf("d").valueOf();
      break;
    case "last6Months":
      startTime = moment().subtract(6, "M").startOf("d").valueOf();
      break;
    default:
      startTime = "";
  }
  return {
    startTime,
    endTime: data === "all" ? "" : moment().endOf("d").valueOf(),
  };
};
export const codes = {
  // AS: {currency: "USD", country: "ASM", symbol: "$"},
  // AD: {currency: "EUR", country: "AND", symbol: "€"},
  AF: {currency: "AFN", country: "AFG", symbol: "؋"},
  AR: {currency: "ARS", country: "ARG", symbol: "$"},
  AU: {currency: "AUD", country: "AUS", symbol: "$"},
  AT: {currency: "EUR", country: "AUT", symbol: "€"},
  // AZ: {currency: "AZN", country: "AZE", symbol: "₼"},
  // BS: {currency: "BSD", country: "BHS", symbol: "B$"},
  // BH: {currency: "BHD", country: "BHR", symbol: "د.ب"},
  // BD: {currency: "BDT", country: "BGD", symbol: "৳"},
  BE: {currency: "EUR", country: "BEL", symbol: "€"},
  // BJ: {currency: "XOF", country: "BEN", symbol: "CFA"},
  // BM: {currency: "BMD", country: "BMU", symbol: "$"},
  // BT: {currency: "BTN", country: "BTN", symbol: "Nu."},
  // BO: {currency: "BOB", country: "BOL", symbol: "Bs"},
  // BW: {currency: "BWP", country: "BWA", symbol: "P"},
  BR: {currency: "BRL", country: "BRA", symbol: "R$"},
  // BN: {currency: "BND", country: "BRN", symbol: "B$"},
  // BG: {currency: "BGN", country: "BGR", symbol: "лв"},
  // KH: {currency: "KHR", country: "KHM", symbol: "៛"},
  CA: {currency: "CAD", country: "CAN", symbol: "$"},
  // KY: {currency: "KYD", country: "CYM", symbol: "$"},
  // CL: {currency: "CLP", country: "CHL", symbol: "$"},
  // CN: {currency: "CNY", country: "CHN", symbol: "¥"},
  // CO: {currency: "COP", country: "COL", symbol: "$"},
  // CR: {currency: "CRC", country: "CRI", symbol: "₡"},
  // HR: {currency: "EUR", country: "HRV", symbol: "€"},
  // CW: {currency: "ANG", country: "CUW", symbol: "NAƒ"},
  // CY: {currency: "CYP", country: "CYP", symbol: "£"},
  CZ: {currency: "CZK", country: "CZE", symbol: "Kč"},
  DK: {currency: "DKK", country: "DNK", symbol: "kr."},
  // DO: {currency: "DOP", country: "DOM", symbol: "RD$"},
  // EC: {currency: "USD", country: "ECU", symbol: "$"},
  // EG: {currency: "EGP", country: "EGY", symbol: "E£"},
  // EE: {currency: "EUR", country: "EST", symbol: "€"},
  // FO: {currency: "DKK", country: "FRO", symbol: "kr."},
  // FJ: {currency: "FJD", country: "FJI", symbol: "FJ$"},
  FI: {currency: "EUR", country: "FIN", symbol: "€"},
  FR: {currency: "EUR", country: "FRA", symbol: "€"},
  // PF: {currency: "CFP", country: "PYF", symbol: "F"},
  // GE: {currency: "GEL", country: "GEO", symbol: "ლ"},
  DE: {currency: "EUR", country: "DEU", symbol: "€"},
  // GH: {currency: "GHS", country: "GHA", symbol: "GH₵"},
  // GI: {currency: "GIP", country: "GIB", symbol: "£"},
  GR: {currency: "EUR", country: "GRC", symbol: "€"},
  // GL: {currency: "DKK", country: "GRL", symbol: "kr"},
  // GD: {currency: "XCD", country: "GRD", symbol: "$"},
  // GU: {currency: "USD", country: "GUM", symbol: "$"},
  // GT: {currency: "GTQ", country: "GTM", symbol: "Q"},
  // HN: {currency: "HNL", country: "HND", symbol: "L"},
  HK: {currency: "HKD", country: "HKG", symbol: "HK$"},
  HU: {currency: "HUF", country: "HUN", symbol: "Ft"},
  // IS: {currency: "ISK", country: "ISL", symbol: "kr"},
  IN: {currency: "INR", country: "IND", symbol: "₹"},
  ID: {currency: "IDR", country: "IDN", symbol: "Rp"},
  // IR: {currency: "IRR", country: "IRN", symbol: "﷼"},
  IE: {currency: "EUR", country: "IRL", symbol: "€"},
  // IL: {currency: "ILS", country: "ISR", symbol: "₪"},
  IT: {currency: "EUR", country: "ITA", symbol: "€"},
  JP: {currency: "JPY", country: "JPN", symbol: "¥"},
  // JO: {currency: "JOD", country: "JOR", symbol: "د.أ"},
  // KZ: {currency: "KZT", country: "KAZ", symbol: "₸"},
  // KE: {currency: "KES", country: "KEN", symbol: "/-"},
  // KW: {currency: "KWD", country: "KWT", symbol: "د.ك"},
  // LA: {currency: "LAK", country: "LAO", symbol: "₭"},
  // LV: {currency: "EUR", country: "LVA", symbol: "€"},
  // LB: {currency: "LBP", country: "LBN", symbol: "ل.ل"},
  // LI: {currency: "CHF", country: "LIE", symbol: "Fr"},
  // LT: {currency: "EUR", country: "LTU", symbol: "€"},
  LU: {currency: "EUR", country: "LUX", symbol: "€"},
  // MO: {currency: "MOP", country: "MAC", symbol: "MOP$"},
  MY: {currency: "MYR", country: "MYS", symbol: "RM"},
  // MT: {currency: "EUR", country: "MLT", symbol: "€"},
  // MU: {currency: "MUR", country: "MUS", symbol: "₨"},
  MX: {currency: "MXN", country: "MEX", symbol: "MEX$"},
  // MN: {currency: "MNT", country: "MNG", symbol: "₮"},
  // MA: {currency: "MAD", country: "MAR", symbol: ".د.م"},
  // MM: {currency: "MMK", country: "MMR", symbol: "K"},
  // NA: {currency: "NAD", country: "NAM", symbol: "N$"},
  // NP: {currency: "NPR", country: "NPL", symbol: "Rs"},
  NL: {currency: "EUR", country: "NLD", symbol: "€"},
  // NC: {currency: "CFP", country: "NCL", symbol: "F"},
  NZ: {currency: "NZD", country: "NZL", symbol: "$"},
  // NI: {currency: "NIO", country: "NIC", symbol: "C$"},
  // NG: {currency: "NGN", country: "NGA", symbol: "₦"},
  // MP: {currency: "USD", country: "MNP", symbol: "$"},
  NO: {currency: "NOK", country: "NOR", symbol: "kr"},
  // OM: {currency: "OMR", country: "OMN", symbol: "ر.ع."},
  // PK: {currency: "PKR", country: "PAK", symbol: "Rs"},
  // PA: {currency: "PAB", country: "PAN", symbol: "B/"},
  // PY: {currency: "PYG", country: "PRY", symbol: "₲"},
  // PE: {currency: "PEN", country: "PER", symbol: "S/"},
  PH: {currency: "PHP", country: "PHL", symbol: "₱"},
  PL: {currency: "PLN", country: "POL", symbol: "zł"},
  PT: {currency: "EUR", country: "PRT", symbol: "€"},
  // PR: {currency: "USD", country: "PRI", symbol: "$"},
  QA: {currency: "QAR", country: "QAT", symbol: "﷼"},
  // MD: {currency: "MDL", country: "MDA", symbol: "L"},
  // RE: {currency: "EUR", country: "REU", symbol: "€"},
  RO: {currency: "RON", country: "ROU", symbol: "lei"},
  // RU: {currency: "RUB", country: "RUS", symbol: "₽"},
  SA: {currency: "SAR", country: "SAU", symbol: "ر.س"},
  // RS: {currency: "RSD", country: "SRB", symbol: "din"},
  SG: {currency: "SGD", country: "SGP", symbol: "S$"},
  // SK: {currency: "SKK", country: "SVK", symbol: "Sk"},
  SI: {currency: "EUR", country: "SVN", symbol: "€"},
  // ZA: {currency: "ZAR", country: "ZAF", symbol: "R"},
  // KR: {currency: "KRW", country: "KOR", symbol: "₩"},
  ES: {currency: "EUR", country: "ESP", symbol: "€"},
  // LK: {currency: "LKR", country: "LKA", symbol: "₨"},
  // SD: {currency: "SDG", country: "SDN", symbol: "ج.س."},
  // SR: {currency: "SRD", country: "SUR", symbol: "$"},
  // SZ: {currency: "SZL", country: "SWZ", symbol: "E"},
  SE: {currency: "SEK", country: "SWE", symbol: "kr"},
  CH: {currency: "CHF", country: "CHE", symbol: "Fr"},
  // SY: {currency: "SYP", country: "SYR", symbol: "SP"},
  TW: {currency: "TWD", country: "TWN", symbol: "NT$"},
  TH: {currency: "THB", country: "THA", symbol: "฿"},
  // TT: {currency: "TTD", country: "TTO", symbol: "TT$"},
  // TR: {currency: "TRY", country: "TUR", symbol: "₺"},
  // UG: {currency: "UGX", country: "UGA", symbol: "USh"},
  // UA: {currency: "UAH", country: "UKR", symbol: "₴"},
  AE: {currency: "AED", country: "ARE", symbol: "د.إ"},
  GB: {currency: "GBP", country: "GBR", symbol: "£"},
  US: {currency: "USD", country: "USA", symbol: "$"},
  // UY: {currency: "UYU", country: "URY", symbol: "$U"},
  // UZ: {currency: "UZS", country: "UZB", symbol: "лв"},
  // VE: {currency: "VEF", country: "VEN", symbol: "Bs"},
  VN: {currency: "VND", country: "VNM", symbol: "₫"},
  // VI: {currency: "USD", country: "VIR", symbol: "$"},
};

export const getValueInSession = (key, type) => {
  try {
    let data = sessionStorage.getItem(key);
    if (type === "string") {
      return data ? data : "";
    }
    if (type === "object" && data) {
      return JSON.parse(data);
    }
  } catch (error) {
    return type === "string" ? "" : {};
  }
};
export const storeDataInSession = (key, value) => {
  sessionStorage.setItem(key, value);
};
export const truncateText = (text, maxLength) => {
  if (text?.length > maxLength) {
    return text.slice(0, maxLength) + "...";
  }
  return text;
};
export const capitalizeFirstLetter = (word) => {
  return typeof word === "string" && word.length > 0
    ? word.charAt(0).toUpperCase() + word.slice(1)
    : "";
};
export const getName = (name) => {
  return name ? (name.length > 15 ? name.slice(0, 15) + "..." : name) : "";
};
export const getDateAndYear = (timestamp) => {
  let locale = getLang();
  if (locale !== "en") {
    locale = returnMomentLocale(locale);
  }
  return moment(timestamp).locale(locale).format("MMM D, YYYY");
};
export const checkContentOverflow = (ref) => {
  if (ref.current) {
    return (
      ref.current.scrollWidth > ref.current.clientWidth ||
      ref.current.scrollHeight > ref.current.clientHeight
    );
  }
  return false;
};

export const getStickerPrice = (price) => {
  return Number(price) / STICKER_PRICE_DIVISOR;
};

export const getGTRPrice = (price) => {
  return Number(price) * 10 ** 5;
};
