import GAxios from "src/util/GAxios";
import {SEARCH_APIS} from "../_constant";

export const fetchGTokListItems = async (
  phrase,
  visionPostOffset,
  max,
  callback,
  errorCallback,
  api = SEARCH_APIS.POST,
) => {
  try {
    const decodedPhrase = decodeURIComponent(phrase);
    await GAxios(
      {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}${api}`,
        data: {
          content: {
            incl: "posts|stats|userinfo|visions",
            q: decodedPhrase,
            offset: visionPostOffset,
            max,
          },
        },
        ignoreErrors: api === SEARCH_APIS.POST,
      },
      (res) => {
        res.api = api;
        callback(res);
      },
      async (err) => {
        if (api === SEARCH_APIS.POST)
          await fetchGTokListItems(
            phrase,
            visionPostOffset,
            max,
            callback,
            errorCallback,
            SEARCH_APIS.POST_V1,
          );

        if (api === SEARCH_APIS.POST_V1 && errorCallback) errorCallback(err);
      },
    );
  } catch (err) {
    console.error(err);
  }
};
