import {createSlice, createAsyncThunk, createAction} from "@reduxjs/toolkit";
import {LivenowStreamApi} from "../api";
import {findIndex, map, uniqBy} from "lodash-es";
import {POST_TYPES} from "src/constants/live";
import {nFormatter} from "src/util/LiveUtils";

const NS = "livestream_poc";
const initialState = {
  boardCast: [],
  offset: 0,
  lastOffset: -1,
  maxCount: 36,
  loadingStream: false.valueOf,
  userList: [],
  isEnd: false,
  streamData: [],
  langTab: {id: "highlights", name: "Highlights"},
  categories: [
    {id: "highlights", name: "Highlights"},
    {id: "all", name: "All"},
    // {id: "maestro", name: "Maestro"},
  ],
  tabLoader: false,
  maestroAuth: {
    data: null,
    loading: false,
  },
  maestroHostsV1: {
    data: [],
    loading: false,
  },
};

export const getStreamLivenow = createAsyncThunk(
  `${NS}/getStreamLivenow`,
  LivenowStreamApi.getStreamLivenow,
);
export const getStreamBatch = createAsyncThunk(
  `${NS}getStreamBatch`,
  LivenowStreamApi.getStreamBatch,
);

export const getCategories = createAsyncThunk(
  `${NS}/getCategories`,
  LivenowStreamApi.getCategories,
);

export const getMaestroToken = createAsyncThunk(
  `${NS}/getMaestroToken`,
  LivenowStreamApi.getMaestroToken,
);

export const getMaestroHostsV1 = createAsyncThunk(
  `${NS}/getMaestroHostsV1`,
  LivenowStreamApi.getMaestroHostsV1,
);

export const boardCastAction = createAction(`${NS}/boardCastAction`);
export const setboardCastEnd = createAction(`${NS}/setboardCastEnd`);
export const setOffsetAction = createAction(`${NS}/setOffsetAction`);
export const resetBroadCastAction = createAction(`${NS}/resetBroadCastAction`);
export const resetBroadCast = createAction(`${NS}/resetBroadCast`);
export const loaderTriger = createAction(`${NS}/loaderTriger`);
// export const userListAction = createAction(`${NS}/userListAction`);
// export const resetUserListAction = createAction(`${NS}/resetUserListAction`);
export const setStreamData = createAction(`${NS}/setStreamData`);
export const setLangTab = createAction(`${NS}/setLangTab`);
export const setCategories = createAction(`${NS}/setCategories`);
export const setTabloader = createAction(`${NS}/setTabloader`);
export const updateBoardCast = createAction(`${NS}/updateBoardCast`);
export const resetMaestroAuth = createAction(`${NS}/resetMaestroAuth`);

export const livestream_poc = createSlice({
  name: NS,
  initialState,
  reducers: {
    boardCastAction: (state, {payload}) => {
      const {streamsInfo, maStreamsInfo} = payload;

      // let postData = [...state.boardCast, ...payload];
      // Because backend side have sort the data list so they suggest frontend side could not re-sort the data.
      // const list =
      //   [
      //     ...uniqBy(
      //       postData?.filter((item) => item.p_type === POST_TYPES.MAESTRO) ||
      //         [],
      //       "postId",
      //     ),
      //     ...uniqBy(
      //       postData?.filter((item) => item.p_type === POST_TYPES.STREAM) || [],
      //       "streamId",
      //     ),
      //   ]?.sort((a, b) => {
      //     if (a.isLive && !b.isLive) return -1;
      //     if (!a.isLive && b.isLive) return 1;

      //     if (Boolean(a.isLive) === Boolean(b.isLive)) {
      //       if (a.udate > b.udate) return -1;
      //       if (a.udate < b.udate) return 1;
      //     }
      //     return 0;
      //   }) || [];
      const newBoardCast =
        state.streamData
          ?.map((item) => {
            let streamInfo = null;
            if (item.p_type === POST_TYPES.STREAM)
              streamInfo = streamsInfo?.[item.sid]?.broadcast;

            if (item.p_type === POST_TYPES.MAESTRO)
              streamInfo = maStreamsInfo?.[item.post_id];

            if (streamInfo?.isLive && streamInfo?.concurrents)
              streamInfo.viewsCount = streamInfo?.concurrents || 0;

            if (streamInfo?.viewsCount)
              streamInfo.viewsCount = nFormatter(streamInfo?.viewsCount);

            return streamInfo ? {...item, ...streamInfo} : null;
          })
          ?.filter((item) => !!item) || [];

      state.boardCast = [...state.boardCast, ...newBoardCast];

      state.userList = uniqBy(state.boardCast, "uid") || [];
      state.loadingStream = false;
      state.tabLoader = false;
    },
    // userListAction: (state, {payload}) => {
    //   let userData = [...state.userList, ...payload];
    //   const date = Math.round(new Date().getTime() / 1000);
    //   const uniqueIds = [],
    //     unique = userData
    //       ?.sort((a, b) => {
    //         const name1 = a.isLive;
    //         const name3 = a.udate;
    //         let comparison = 0;
    //         if (name1 && name3 < date) {
    //           comparison = 1;
    //         } else if (name1 && name3 < date) {
    //           comparison = -1;
    //         }
    //         return comparison;
    //       })
    //       ?.filter((element) => {
    //         const isDuplicate = uniqueIds?.includes(element?.userId);
    //         if (!isDuplicate) {
    //           uniqueIds.push(element?.userId);
    //           return true;
    //         }
    //       });
    //   state.userList = unique;
    //   state.tabLoader = false;
    // },
    // resetUserListAction: (state) => {
    //   state.userList = [];
    // },
    setOffsetAction: (state, {payload}) => {
      state.offset = state.offset + payload;
      state.loadingStream = true;
    },
    resetBroadCastAction: (state) => {
      state.offset = 0;
      state.lastOffset = -1;
      state.boardCast = [];
      state.streamData = [];
      state.userList = [];
      state.loadingStream = false;
      state.isEnd = false;
    },
    resetBroadCast: (state) => {
      state.boardCast = [];
      state.streamData = [];
      state.loadingStream = false;
    },
    loaderTriger: (state, {payload}) => {
      state.loadingStream = payload;
    },
    setboardCastEnd: (state) => {
      state.isEnd = true;
      state.loadingStream = true;
    },
    setStreamData: (state, {payload}) => {
      state.streamData = payload;
      state.offset = state.offset + state.maxCount;
      state.loadingStream = true;
    },
    setLangTab: (state, {payload}) => {
      state.langTab = payload;
    },
    setCategories: (state, {payload}) => {
      state.categories = payload;
    },
    setTabloader: (state, {payload}) => {
      state.tabLoader = payload;
    },
    updateBoardCast(state, {payload}) {
      const posts = [...state.boardCast];
      // find the edited Index
      const editedIndex = findIndex(
        posts,
        (item) => item.post_id === payload.id,
      );
      const {prevsrc, dsc, previmg, ttl, ...rest} = {...posts[editedIndex]};
      const newPost = {
        ...rest,
        ...payload,
        txt: payload.txt || "",
        post_dsc: payload.dsc,
      };
      posts.splice(editedIndex, 1, newPost);

      state.boardCast = map(posts, (item) => {
        return item;
      });

      state.refreshProfileFeed = true;
    },
    resetMaestroAuth: (state) => {
      state.maestroAuth.data = null;
      state.maestroAuth.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMaestroToken.pending, (state) => {
      state.maestroAuth.loading = true;
    });
    builder.addCase(getMaestroToken.fulfilled, (state, {payload}) => {
      if (payload?.response?.data?.result) {
        // set data
        state.maestroAuth.data = payload?.response?.data?.result;
      }
      state.maestroAuth.loading = false;
    });
    builder.addCase(getMaestroToken.rejected, (state) => {
      state.maestroAuth.loading = false;
    });
    builder.addCase(getMaestroHostsV1.pending, (state) => {
      state.maestroHostsV1.loading = true;
    });
    builder.addCase(getMaestroHostsV1.fulfilled, (state, {payload}) => {
      if (payload?.data?.result) {
        // set data
        state.maestroHostsV1.data = payload?.data?.result;
      }
      state.maestroHostsV1.loading = false;
    });
    builder.addCase(getMaestroHostsV1.rejected, (state) => {
      state.maestroHostsV1.loading = false;
    });

    builder.addCase(getStreamLivenow.pending, (state) => {
      // if (state.offset === 0) {
      //   state.lastOffset === -1;
      // }
      state.isLoading = true;
    });
    builder.addCase(getStreamLivenow.fulfilled, (state, {payload}) => {
      state.isLoading = false;
      state.lastOffset = state.offset;
    });
    builder.addCase(getStreamLivenow.rejected, (state) => {
      state.isLoading = false;
    });
  },
});
