import GAxios from "src/util/GAxios";
import {settingsConstants} from "src/app/pages/settings/_constants";

export const updateNotifSettings = async (params) => {
  if (!params) return;

  const data = JSON.stringify({
    content: {
      settings: params,
    },
  });

  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/${settingsConstants.API_UPDATE_NOTIF_SETTING}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  let res = null;

  await GAxios(
    config,
    (response) => {
      if (response?.data?.result?.data?.result === "success") {
        res = params;
      }
    },
    (err) => {},
  );

  return res;
};
