import {createSlice, createAsyncThunk, createAction} from "@reduxjs/toolkit";
import {AuthApi} from "../api";
import {socialConstants, userConstants} from "../_constants";
import AppConsts from "../../../AppConsts";
import {updateUserInfo} from "src/util/userUtil";

const NS = "auth";
const LOCAL_THEME = "theme";

let user = JSON.parse(localStorage.getItem(userConstants.LS_SESSION_INFO));

if (user?.authenticated && !user.userinfo) {
  user = null;
}

const initialState = {
  session: user
    ? user
    : {
        authenticated: false,
        userinfo: {},
        error: false,
        isSuspended: false,
        isLoading: false,
        errInfo: {},
        logged: false,
      },
  signup: {
    birthyear: 0,
    birthday: 0,
    error: false,
    success: false,
    isLoading: false,
  },
  sendVerifCode: {
    data: {},
    isLoading: false,
    error: false,
    success: false,
    defaultMode: "phone",
  },
  sendSelfVerifCode: {
    isLoading: false,
    error: false,
    success: false,
    defaultMode: "phone",
    changeUsernameData: null,
  },
  verifCode: {
    code: null,
    isLoading: false,
    error: false,
    success: false,
  },
  changePassword: {
    isLoading: false,
    error: false,
    success: false,
  },
  loginStep: {
    step: userConstants.STEP_LOGIN,
  },
  signupStep: {
    step: userConstants.STEP_SIGNUP,
    // step: userConstants.STEP_USERNAME,
  },
  claimStep: {
    imPost: true,
    username: "",
    claimed: false,
    autoConnect: true,
  },
  changeUsernameStep: {
    step: userConstants.STEP_CHANGE_USERNAME,
  },
  emailPhoneDialogStep: {
    step: userConstants.STEP_FIRST_TYPING,
  },
  broadcast: {
    canBroadcast: false,
    initialized: false,
  },
  userId: "",
  ousername: "",
  publicGlobals: {},
  adsConfig: {
    ad_banner_web_enabled: true,
    ad_preroll_web_enabled: undefined,
  },
  isLoggingOut: false,
  socialProviders: [],
  socialAuthType: "", // login | signup
  socialAuth: {
    pt: "",
    payload: {},
    isLoading: false,
    error: false,
    signup: {
      username: "",
      birth_year: "",
    },
    linkSignIn: {
      error: false,
      resendErr: false,
    },
  },
  socialAuthStatus: {
    password: false,
    apple: false,
    google: false,
  },
  socialAuthConnections: null,

  impervaChallenge: {
    open: false,
    impervaData: null,
  },
  multiAccountLogin: {
    popupDialogOpen: false,
    qrLoginPopup: false,
    auth0Popup: false,
  },
  premiumPopup: false,
  gettrPremium: false,
  subGettrPremium: false,
  premiumSubscribed: false,
  subPopupData: {},
  premiumSuccess: false,
  mode: localStorage.getItem(LOCAL_THEME),
  userInfoLoading: null,
  popularCreatorPopup: false,
  userInfoPopup: false,
  isVerifyEmailSent: false,
  showSubscription: false,
  userAdsBlackGadObj: {},
  getterId: {
    loading: false,
    isEndorsed: false,
    endorsedSize: 0,
    names: {
      prompt_type: 3,
    },
  },
  clickBackToTopButton: false,
};

export const login = createAsyncThunk("auth/login", AuthApi.login);
export const otpLogin = createAsyncThunk("auth/otplogin", AuthApi.otpLogin);
export const getQrLoginStatus = createAsyncThunk(
  "auth/getQrLoginStatus",
  AuthApi.getQrLoginStatus,
);

export const loginRefresh = createAsyncThunk(
  "auth/loginRefresh",
  AuthApi.loginRefresh,
);

export const sendVerifCode = createAsyncThunk(
  "auth/sendVerifCode",
  AuthApi.sendVerifCode,
);

export const sendVerifCodeSms = createAsyncThunk(
  "auth/sendVerifCodeSms",
  AuthApi.sendVerifCodeSms,
);

export const sendVerifCodeSignupSms = createAsyncThunk(
  "auth/sendVerifCodeSignupSms",
  AuthApi.sendVerifCodeSignupSms,
);

export const sendVerifCodeSignup = createAsyncThunk(
  "auth/sendVerifCodeSignup",
  AuthApi.sendVerifCodeSignup,
);

export const sendSelfVerifCode = createAsyncThunk(
  "auth/sendSelfVerifCode",
  AuthApi.sendSelfVerifCode,
);

export const changeUsername = createAsyncThunk(
  "auth/changeUsername",
  AuthApi.changeUsername,
);

export const changePassword = createAsyncThunk(
  "auth/changePassword",
  AuthApi.changePassword,
);

export const changePasswordLoggedIn = createAsyncThunk(
  "auth/changePasswordLoggedIn",
  AuthApi.changePasswordLoggedIn,
);

export const changePasswordLoggedIn_V2 = createAsyncThunk(
  "auth/changePasswordLoggedIn_V2",
  AuthApi.changePasswordLoggedIn_V2,
);

export const verifCode = createAsyncThunk("auth/verifCode", AuthApi.verifCode);
export const selfVerifCode = createAsyncThunk(
  "auth/selfVerifCode",
  AuthApi.selfVerifCode,
);

export const changeLoginStep = createAsyncThunk(
  "auth/changeLoginStep",
  AuthApi.changeLoginStep,
);

export const getPublicGlobals = createAsyncThunk(
  "auth/getPublicGlobals",
  AuthApi.getPublicGlobals,
);

export const getAdsConfig = createAsyncThunk(
  "auth/getAdsConfig",
  AuthApi.getAdsConfig,
);

export const changeSignupStep = createAsyncThunk(
  "auth/changeSignupStep",
  AuthApi.changeSignupStep,
);

export const checkUsername = createAsyncThunk(
  "auth/checkUsername",
  AuthApi.checkUsername,
);

export const checkUsernameExist = createAsyncThunk(
  "auth/checkUsernameExist",
  AuthApi.checkUsernameExist,
);

export const checkUsernameSrc = createAsyncThunk(
  "auth/checkUsernameSrc",
  AuthApi.checkUsernameSrc,
);

export const checkEmail = createAsyncThunk(
  "auth/checkEmail",
  AuthApi.checkEmail,
);

export const addPinPost = createAsyncThunk(
  "auth/addPinPost",
  AuthApi.addPinPost,
);

export const deletePinPost = createAsyncThunk(
  "auth/deletePinPost",
  AuthApi.deletePinPost,
);

export const signup = createAsyncThunk("auth/signup", AuthApi.signup);

export const quickSignup = createAsyncThunk(
  "auth/quickSignup",
  AuthApi.quickSignup,
);

export const expressSignup = createAsyncThunk(
  "auth/expressSignup",
  AuthApi.expressSignup,
);

export const getKeys = createAsyncThunk("auth/getKeys", AuthApi.getKeys);

export const canBroadcastLive = createAsyncThunk(
  "auth/canBroadcastLive",
  AuthApi.canBroadcastLive,
);

export const saveOusername = createAsyncThunk(
  "auth/saveOusername",
  AuthApi.saveOusername,
);

export const userFollowStatus = createAsyncThunk(
  "auth/userFollowStatus",
  AuthApi.userFollowStatus,
);

export const fetchUserInfo = createAsyncThunk(
  "auth/fetchUserInfo",
  AuthApi.fetchUserInfo,
);

export const reportUser = createAsyncThunk(
  "auth/reportUser",
  AuthApi.reportUser,
);

export const fetchIdentification = createAsyncThunk(
  "auth/fetchIdentification",
  AuthApi.fetchIdentification,
);

export const submitIdentification = createAsyncThunk(
  "auth/submitIdentification",
  AuthApi.submitIdentification,
);

export const updateIdNickNames = createAction("auth/updateIdNickNames");

export const fetchEndorsement = createAsyncThunk(
  "auth/fetchEndorsement",
  AuthApi.fetchEndorsement,
);

export const endorsementSize = createAsyncThunk(
  "auth/endorsementSize",
  AuthApi.endorsementSize,
);

export const setEndorsement = createAsyncThunk(
  "auth/setEndorsement",
  AuthApi.setEndorsement,
);

export const deleteEndorsement = createAsyncThunk(
  "auth/deleteEndorsement",
  AuthApi.deleteEndorsement,
);

export const logout = createAsyncThunk("auth/logout", AuthApi.logout);
export const goToLogout = createAction("auth/goToLogout");
export const resetSession = createAction("auth/resetSession");
export const auth0SendSMS = createAction("auth/auth0SendSMS");

export const resetSessionStatus = createAction(`${NS}/resetSessionStatus`);
export const claimSessionStatus = createAction(`${NS}/claimSessionStatus`);

export const resetSendVerifCodeStatus = createAction(
  `${NS}/resetSendVerifCodeStatus`,
);

export const changeUsernameStep = createAction(`${NS}/changeUsernameStep`);
export const changeEmailPhoneDialogStep = createAction(
  `${NS}/changeEmailPhoneDialogStep`,
);

export const resetVerifCodeStatus = createAction(`${NS}/resetVerifCodeStatus`);

export const resetchangePasswordStatus = createAction(
  `${NS}/resetchangePasswordStatus`,
);
export const startLogin = createAction(`${NS}/startLogin`);

export const setBirthYear = createAction(`${NS}/setBirthYear`);
export const setBirthDay = createAction(`${NS}/setBirthDay`);
export const changeImpost = createAction(`${NS}/changeImpost`);
export const changeAutoConnect = createAction(`${NS}/changeAutoConnect`);
export const setClaimUsername = createAction(`${NS}/setClaimUsername`);
export const setClaimed = createAction(`${NS}/setClaimed`);
export const refreshToken = createAction(`${NS}/refreshToken`);

export const setSendVerifCodeData = createAction(`${NS}/setSendVerifCodeData`);
export const setOnlySendVerifCodeData = createAction(
  `${NS}/setOnlySendVerifCodeData`,
);
export const setSendVerifCodeDefaultMode = createAction(
  `${NS}/setSendVerifCodeDefaultMode`,
);
export const removePinnedPostFromSession = createAction(
  `${NS}/removePinnedPostFromSession`,
);

export const setSessionUserLanguage = createAction(
  `${NS}/setSessionUserLanguage`,
);

export const resetItemsOfSendVerifCodeData = createAction(
  `${NS}/resetItemsOfSendVerifCodeData`,
);

export const sendVerifCodeRecoveryEmail = createAsyncThunk(
  "auth/sendVerifCodeRecoveryEmail",
  AuthApi.sendVerifCodeRecoveryEmail,
);

export const sendVerifCodeRecoveryPhone = createAsyncThunk(
  "auth/sendVerifCodeRecoveryPhone",
  AuthApi.sendVerifCodeRecoveryPhone,
);

export const sendRecoveryBindEmail = createAsyncThunk(
  "auth/sendRecoveryBindEmail",
  AuthApi.bindEmail,
);

export const sendRecoveryBindPhone = createAsyncThunk(
  "auth/sendRecoveryBindPhone",
  AuthApi.bindSms,
);

export const bindSmsV2 = createAsyncThunk("auth/bindSmsV2", AuthApi.bindSmsV2);

export const bindEmailV2 = createAsyncThunk(
  "auth/bindEmailV2",
  AuthApi.bindEmailV2,
);

export const sendChangeEmail = createAsyncThunk(
  "auth/sendChangeEmail",
  AuthApi.changeEmail,
);

export const changeEmailV2 = createAsyncThunk(
  "auth/changeEmailV2",
  AuthApi.changeEmailV2,
);

export const sendChangePhone = createAsyncThunk(
  "auth/sendChangePhone",
  AuthApi.changeSms,
);

export const changeSmsV2 = createAsyncThunk(
  "auth/changeSmsV2",
  AuthApi.changeSmsV2,
);

export const bypassVerifCode = createAction(`${NS}/bypassVerifCode`);

export const socialInitPassword = createAsyncThunk(
  "auth/socialInitPassword",
  AuthApi.socialInitPassword,
);

export const createPassword = createAsyncThunk(
  "auth/createPassword",
  AuthApi.createPassword,
);

export const getSocialStatus = createAsyncThunk(
  `${NS}/getSocialStatus`,
  AuthApi.getSocialStatus,
);
export const getSocialConnections = createAsyncThunk(
  `${NS}/getSocialConnections`,
  AuthApi.getSocialConnections,
);
export const socialDisconnect = createAsyncThunk(
  `${NS}/socialDisconnect`,
  AuthApi.socialDisconnect,
);
export const socialSignIn = createAsyncThunk(
  "auth/socialSignIn",
  AuthApi.socialSignIn,
);
export const socialSignup = createAsyncThunk(
  "auth/socialSignup",
  AuthApi.socialSignup,
);
export const socialLinkSignIn = createAsyncThunk(
  "auth/socialLinkSignIn",
  AuthApi.socialLinkSignIn,
);
export const socialResendVerifyCode = createAsyncThunk(
  "auth/socialResendVerifyCode",
  AuthApi.socialResendVerifyCode,
);
export const setSocialSignupBirthYear = createAction(
  `${NS}/setSocialSignupBirthYear`,
);
export const setSocialSignupUsername = createAction(
  `${NS}/setSocialSignupUsername`,
);
export const setSocialProviders = createAction(`${NS}/setSocialProviders`);
export const setSocialAuthType = createAction(`${NS}/setSocialAuthType`);
export const resetSocialState = createAction(`${NS}/resetSocialState`);
export const resetAllState = createAction(`${NS}/resetAllState`);
export const setToResetPasswordStep = createAction(
  `${NS}/setToResetPasswordStep`,
);
export const updateSocialPasswordState = createAction(
  `${NS}/updateSocialPasswordState`,
);
export const setChangeUsernameData = createAction(
  `${NS}/setChangeUsernameData`,
);
export const setImpervaChallenge = createAction(`${NS}/setImpervaChallenge`);
export const setImpervaData = createAction(`${NS}/setImpervaData`);
export const resetQrLoginStatus = createAction(`${NS}/resetQrLoginStatus`);

export const setLoginPopupDialogOpen = createAction(
  `${NS}/setLoginPopupDialogOpen`,
);
export const setQrLoginPopup = createAction(`${NS}/setQrLoginPopup`);
export const setAuth0Popup = createAction(`${NS}/setAuth0Popup`);
export const setPremiumPopup = createAction(`${NS}/setPremiumPopup`);
export const setGettrPremium = createAction(`${NS}/setGettrPremium`);
export const setSubGettrPremium = createAction(`${NS}/setSubGettrPremium`);
export const setPremiumSubscribed = createAction(`${NS}/setPremiumSubscribed`);
export const setSubPopupData = createAction(`${NS}/setSubPopupData`);
export const setPremiumSuccess = createAction(`${NS}/setPremiumSuccess`);
export const resetBroadcast = createAction(`${NS}/resetBroadcast`);
export const setThemeMode = createAction(`${NS}/setThemeMode`);
export const setShowSubscription = createAction(`${NS}/setShowSubscription`);
export const setPopularCreatorPopup = createAction(
  `${NS}/setPopularCreatorPopup`,
);
export const setUserInfoPopup = createAction(`${NS}/setUserInfoPopup`);
export const setIsVerifyEmailSent = createAction(`${NS}/setIsVerifyEmailSent`);
export const setUserInfoLoading = createAction(`${NS}/setUserInfoLoading`);
export const setUserInfo = createAction(`${NS}/setUserInfo`);
export const setUserAdsBlackGadObj = createAction(
  `${NS}/setUserAdsBlackGadObj`,
);

export const setClickBackToTopButton = createAction(
  `${NS}/setClickBackToTopButton`,
);

export const authSlice = createSlice({
  name: NS,
  initialState,
  reducers: {
    resetAllState: (state) => {
      const globalState = state.publicGlobals;
      const adsConfig = state.adsConfig;
      state = {...initialState};
      state.publicGlobals = globalState;
      state.adsConfig = adsConfig;
      state.isLoggingOut = false;
    },
    resetBroadcast: (state) => {
      state.broadcast = {};
      state.broadcast.initialized = false;
      state.broadcast.canBroadcast = false;
      state.isLoggingOut = false;
    },
    setLoginPopupDialogOpen: (state, {payload}) => {
      state.multiAccountLogin.popupDialogOpen = payload;
    },
    setAuth0Popup: (state, {payload}) => {
      state.multiAccountLogin.auth0Popup = payload;
    },
    setPremiumPopup: (state, {payload}) => {
      state.premiumPopup = payload;
    },
    setGettrPremium: (state, {payload}) => {
      state.gettrPremium = payload;
    },
    setSubGettrPremium: (state, {payload}) => {
      state.subGettrPremium = payload;
    },
    setPremiumSubscribed: (state, {payload}) => {
      state.premiumSubscribed = payload;
    },
    setSubPopupData: (state, {payload}) => {
      state.subPopupData = payload;
    },
    setPremiumSuccess: (state, {payload}) => {
      state.premiumSuccess = payload;
    },
    auth0SendSMS: (state, {payload}) => {
      state.sendVerifCode.data = payload;
      state.sendVerifCode.success = true;
      state.sendVerifCode.error = false;

      state.sendVerifCode.isLoading = false;
    },
    setQrLoginPopup: (state, {payload}) => {
      state.multiAccountLogin.qrLoginPopup = payload;
    },
    setChangeUsernameData: (state, {payload}) => {
      state.sendSelfVerifCode.changeUsernameData = payload;
    },
    setOnlySendVerifCodeData: (state, {payload}) => {
      state.sendVerifCode.data = payload;
    },
    setSendVerifCodeDefaultMode: (state, {payload}) => {
      state.sendVerifCode.defaultMode = payload;
    },
    setSendVerifCodeData: (state, {payload}) => {
      state.sendVerifCode.data = payload;
      state.sendVerifCode.success = true;
    },
    setBirthYear: (state, {payload}) => {
      state.signup.birthyear = payload.birthyear;
    },
    goToLogout: (state) => {
      if (
        state.session.authenticated === true &&
        state.isLoggingOut === false
      ) {
        state.isLoggingOut = true;
      }
    },
    resetSession: (state) => {
      state.session = {
        authenticated: false,
        userinfo: {},
        error: false,
        isSuspended: false,
        isLoading: false,
        errInfo: {},
        logged: false,
      };
      state.isLoggingOut = false;
    },
    setBirthDay: (state, {payload}) => {
      state.signup.birthday = payload.birthday;
    },
    refreshToken: (state, {payload}) => {
      state.session.userinfo.token = payload.newToken;
      localStorage.setItem(
        userConstants.LS_SESSION_INFO,
        JSON.stringify(state.session),
      );
    },
    changeImpost: (state, {payload}) => {
      state.claimStep.imPost = payload.imPost;
    },
    changeAutoConnect: (state, {payload}) => {
      state.claimStep.autoConnect = payload.autoConnect;
    },
    setClaimUsername: (state, {payload}) => {
      state.claimStep.username = payload.username;
    },
    setClaimed: (state, {payload}) => {
      state.claimStep.claimed = payload.claimed;
    },
    resetSessionStatus: (state) => {
      const authenticated = state.session.authenticated;
      const userinfo = state.session.userinfo;
      state.session.error = false;
      state.session.isLoading = false;
      state.session.isSuspended = false;
      state.session.authenticated = authenticated;
      state.session.userinfo = userinfo;
    },
    resetSendVerifCodeStatus: (state) => {
      state.verifCode.code = null;
      state.sendVerifCode.error = false;
      state.sendVerifCode.isLoading = false;
      state.sendVerifCode.success = false;
    },
    resetVerifCodeStatus: (state) => {
      state.verifCode.error = false;
      state.verifCode.isLoading = false;
      state.verifCode.success = false;
    },
    resetchangePasswordStatus: (state) => {
      state.changePassword.error = false;
      state.changePassword.isLoading = false;
      state.changePassword.success = false;
    },
    refreshUserInfo: (state, {payload}) => {
      if (payload) {
        payload.userinfo.token = state.session?.userinfo?.token;
        state.session.userinfo = payload.userinfo;
        localStorage.setItem(
          userConstants.LS_SESSION_INFO,
          JSON.stringify(state.session),
        );
      }
    },
    refreshUserConfirmEmail: (state, {payload}) => {
      if (payload) {
        state.session.userinfo = {...state.session.userinfo, ...payload};
        localStorage.setItem(
          userConstants.LS_SESSION_INFO,
          JSON.stringify(state.session),
        );
      }
    },
    startLogin: (state, payload) => {
      state.session.isLoading = true;
    },
    changeUsernameStep: (state, {payload}) => {
      state.changeUsernameStep.step = payload;
    },
    changeEmailPhoneDialogStep: (state, {payload}) => {
      state.emailPhoneDialogStep.step = payload;
    },
    setSessionUserLanguage: (state, {payload}) => {
      state.session.userinfo.lang = payload;
      localStorage.setItem(
        userConstants.LS_SESSION_INFO,
        JSON.stringify(state.session),
      );
      localStorage.setItem(AppConsts.LOCAL_STORAGE_LAST_BROWSER_LANG, payload);
    },

    claimSessionStatus: (state, {payload}) => {
      state.session.isLoading = false;

      if (payload) {
        state.session.authenticated = payload?.authenticated;
        state.session.userinfo = payload?.userinfo;
      }
    },

    removePinnedPostFromSession: (state, action) => {
      const {postId} = action.payload;
      let userInfo = {...state.session.userinfo};
      let existingPinPosts = userInfo?.pinpsts
        ? JSON.parse(userInfo?.pinpsts)?.join(",")?.split(",")
        : [];
      let postIdIndex = existingPinPosts?.includes(postId)
        ? existingPinPosts.indexOf(postId)
        : 0;
      existingPinPosts?.length > 0 && existingPinPosts.splice(postIdIndex, 1);
      let pinnedPosts =
        existingPinPosts.length > 0 ? JSON.stringify(existingPinPosts) : "";
      userInfo.pinpsts = pinnedPosts;
      state.session.userinfo = userInfo;

      localStorage.setItem(
        userConstants.LS_SESSION_INFO,
        JSON.stringify(state.session),
      );
    },

    resetItemsOfSendVerifCodeData: (state, {payload}) => {
      if (payload) {
        for (let i = 0; i < payload.length; i++) {
          state.sendVerifCode.data[payload[i]] = "";

          if (payload[i] === "phone") {
            state.sendVerifCode.defaultMode = "phone";
          } else if (payload[i] === "email") {
            state.sendVerifCode.defaultMode = "email";
          }
        }
      } else {
        state.sendVerifCode.data = {};
      }
    },

    bypassVerifCode: (state, {payload}) => {
      if (payload?.code) {
        state.verifCode.code = payload.code;
      }
      state.verifCode.success = true;
    },

    setSocialProviders: (state, {payload}) => {
      state.socialProviders = payload;
    },
    setSocialAuthType: (state, {payload}) => {
      state.socialAuthType = payload;
      localStorage.setItem(
        socialConstants.LOCAL_STORAGE_SIGN_TYPE,
        state.socialAuthType,
      );
    },
    setSocialSignupBirthYear: (state, {payload}) => {
      state.socialAuth.signup.birth_year = payload.birth_year;
    },
    setSocialSignupUsername: (state, {payload}) => {
      state.socialAuth.signup.username = payload.username;
    },
    resetSocialState: (state) => {
      state.socialAuth = {
        pt: "",
        payload: {},
        isLoading: false,
        error: false,
        signup: {
          username: "",
          birth_year: "",
        },
        linkSignIn: {
          error: false,
          resendErr: false,
        },
      };
      localStorage.removeItem(socialConstants.LOCAL_STORAGE_PVR_INFO);
      localStorage.removeItem(socialConstants.LOCAL_STORAGE_GOOGLE_AUTHED_INFO);
      localStorage.removeItem(socialConstants.LOCAL_STORAGE_APPLE_AUTHED_INFO);
      localStorage.removeItem(
        socialConstants.LOCAL_STORAGE_FACEBOOK_AUTHED_INFO,
      );
      localStorage.removeItem(socialConstants.LOCAL_STORAGE_AUTH0_INFO);
    },
    setToResetPasswordStep: (state) => {
      state.loginStep.step = userConstants.STEP_CHANGE_PASSWORD;
    },
    updateSocialPasswordState: (state, {payload}) => {
      state.socialAuthStatus.password = payload;
    },
    setImpervaChallenge: (state, {payload}) => {
      state.impervaChallenge.open = payload;
    },
    setImpervaData: (state, {payload}) => {
      state.impervaChallenge.impervaData = payload;
    },

    resetQrLoginStatus: (state, {payload}) => {
      state.session.errInfo = {};
      state.session.logged = false;
      state.session.errInfo.lastUUID = payload;
      state.session.isLoading = false;
      state.session.error = true;
    },

    setThemeMode: (state, {payload}) => {
      state.mode = payload;
    },

    setShowSubscription: (state, {payload}) => {
      state.showSubscription = payload;
    },

    setPopularCreatorPopup: (state, {payload}) => {
      state.popularCreatorPopup = payload;
    },
    setUserInfoPopup: (state, {payload}) => {
      state.userInfoPopup = payload;
    },

    setIsVerifyEmailSent: (state, {payload}) => {
      state.isVerifyEmailSent = payload;
    },

    setUserInfoLoading: (state, {payload}) => {
      state.userInfoLoading = payload;
    },
    setUserInfo: (state, {payload}) => {
      if (state.session.userInfo?._id === payload?._id) {
        const newUserInfo = {...state.session.userInfo, ...payload};
        state.session.userInfo = newUserInfo;
        let sessionInfo = JSON.parse(
          localStorage.getItem(userConstants.LS_SESSION_INFO),
        );
        if (Object.keys(sessionInfo).includes("userinfo")) {
          sessionInfo.userinfo = newUserInfo;
          localStorage.setItem(
            userConstants.LS_SESSION_INFO,
            JSON.stringify(sessionInfo),
          );
        }
      }
    },
    setUserAdsBlackGadObj: (state, {payload}) => {
      let obj = {...state.userAdsBlackGadObj};
      obj[payload?.userId] = payload?.blacklistgad;
      state.userAdsBlackGadObj = obj;
    },
    setClickBackToTopButton: (state, {payload}) => {
      state.clickBackToTopButton = payload;
    },
    updateIdNickNames: (state, {payload}) => {
      if (payload?.length) {
        payload.map((nickname, idx) => {
          let nicknameKey = `nickname_${idx + 1}`;
          state.getterId.names[nicknameKey] = nickname;
        });
      }
    },
  },
  extraReducers: (builder) => {
    // Doc: https://redux-toolkit.js.org/usage/usage-with-typescript#type-safety-with-extrareducers

    builder.addCase(login.pending, (state) => {
      state.session.isLoading = true;
      // state.session.authenticated = false;
      state.session.error = false;
    });

    builder.addCase(login.fulfilled, (state, {payload}) => {
      if (!payload || !payload?.userinfo) {
        state.session.isLoading = false;
        state.session.error = true;
        return;
      }

      if (payload && payload.userinfo === "E_USER_BANNED_LOGIN") {
        // state.session.authenticated = false;
        state.session.isLoading = false;
        state.session.isSuspended = true;
        return;
      }

      if (payload && !payload.authenticated) {
        state.session.isLoading = false;
        state.session.error = true;
        return;
      }

      state.session.authenticated = payload?.userinfo
        ? payload?.authenticated
        : false;
      if (payload) {
        state.session.userinfo = payload?.userinfo;
      }
      state.multiAccountLogin.popupDialogOpen = false;
      state.multiAccountLogin.qrLoginPopup = false;
    });

    builder.addCase(login.rejected, (state) => {
      state.session.authenticated = false;
      state.session.isLoading = false;
      state.session.error = true;
    });

    builder.addCase(otpLogin.pending, (state) => {
      state.session.isLoading = true;
      // state.session.authenticated = false;
      state.session.error = false;
    });

    builder.addCase(otpLogin.fulfilled, (state, {payload}) => {
      if (!payload) {
        state.session.isLoading = false;
        state.session.error = true;
        return;
      }

      if (payload && payload.userinfo === "E_USER_BANNED_LOGIN") {
        state.session.isLoading = false;
        state.session.isSuspended = true;
        return;
      }

      if (payload && !payload.authenticated) {
        state.session.isLoading = false;
        state.session.error = true;
        return;
      }

      if (payload) {
        state.session.authenticated = payload?.authenticated;
        state.session.userinfo = payload?.userinfo;
      }
      state.multiAccountLogin.popupDialogOpen = false;
      state.multiAccountLogin.qrLoginPopup = false;
    });

    builder.addCase(otpLogin.rejected, (state) => {
      // state.session.authenticated = false;
      state.session.isLoading = false;
      state.session.error = true;
    });

    builder.addCase(getQrLoginStatus.pending, (state) => {
      state.session.isLoading = true;
      // state.session.authenticated = false;
      state.session.error = false;
    });

    builder.addCase(getQrLoginStatus.fulfilled, (state, {payload}) => {
      if (!payload) {
        state.session.isLoading = false;
        state.session.error = true;
        return;
      }

      if (payload && payload.userinfo === "E_USER_SUSPENDED") {
        state.session.isLoading = false;
        state.session.isSuspended = true;
        return;
      }

      if (payload && payload.err) {
        state.session.isLoading = false;
        state.session.error = true;
        const count = state.session.errInfo?.count ?? 0;
        state.session.errInfo = payload.err;
        state.session.errInfo.count = count + 1;
        state.session.errInfo.lastUUID = payload.uuid;

        return;
      }

      if (payload && !payload.authenticated && !payload.err) {
        state.session.isLoading = false;
        state.session.error = true;
        return;
      }

      if (payload) {
        state.session.authenticated = payload.authenticated;
        state.session.userinfo = payload.userinfo;
      }
      state.session.logged = true;
      state.multiAccountLogin.popupDialogOpen = false;
      state.multiAccountLogin.qrLoginPopup = false;
    });

    builder.addCase(getQrLoginStatus.rejected, (state) => {
      state.session.isLoading = false;
      state.session.error = true;
    });

    builder.addCase(loginRefresh.fulfilled, (state, {payload}) => {
      if (payload) {
        state.session.authenticated = payload?.authenticated;
        state.session.userinfo = payload?.userinfo;
      }
    });

    builder.addCase(sendVerifCode.pending, (state) => {
      state.sendVerifCode.success = false;
      state.sendVerifCode.error = false;
      state.sendVerifCode.isLoading = true;
    });

    builder.addCase(sendVerifCode.fulfilled, (state, {payload}) => {
      if (payload && payload !== "alreadyShowToast") {
        state.sendVerifCode.data.email = payload;
        state.sendVerifCode.success = true;
      } else {
        state.sendVerifCode.error = true;
      }
      state.sendVerifCode.isLoading = false;
    });

    builder.addCase(sendVerifCode.rejected, (state) => {
      state.sendVerifCode.success = false;
      state.sendVerifCode.error = true;
      state.sendVerifCode.isLoading = false;
    });

    builder.addCase(sendVerifCodeSms.pending, (state) => {
      state.sendVerifCode.success = false;
      state.sendVerifCode.error = false;
      state.sendVerifCode.isLoading = true;
    });

    builder.addCase(sendVerifCodeSms.fulfilled, (state, {payload}) => {
      if (payload && payload !== "alreadyShowToast") {
        state.sendVerifCode.data.phone = payload;
        state.sendVerifCode.success = true;
      } else {
        state.sendVerifCode.error = true;
      }
      state.sendVerifCode.isLoading = false;
    });

    builder.addCase(sendVerifCodeSms.rejected, (state) => {
      state.sendVerifCode.success = false;
      state.sendVerifCode.error = true;
      state.sendVerifCode.isLoading = false;
    });

    builder.addCase(sendVerifCodeSignup.pending, (state) => {
      state.sendVerifCode.success = false;
      state.sendVerifCode.error = false;
      state.sendVerifCode.isLoading = true;
    });

    builder.addCase(sendVerifCodeSignup.fulfilled, (state, {payload}) => {
      if (payload && payload !== "alreadyShowToast") {
        state.sendVerifCode.data = payload;
        state.sendVerifCode.success = true;
      } else {
        state.sendVerifCode.error = true;
      }

      state.sendVerifCode.isLoading = false;
    });

    builder.addCase(sendVerifCodeSignup.rejected, (state) => {
      state.sendVerifCode.success = false;
      state.sendVerifCode.error = true;
      state.sendVerifCode.isLoading = false;
    });

    builder.addCase(sendVerifCodeSignupSms.pending, (state) => {
      state.sendVerifCode.success = false;
      state.sendVerifCode.error = false;
      state.sendVerifCode.isLoading = true;
    });

    builder.addCase(sendVerifCodeSignupSms.fulfilled, (state, {payload}) => {
      if (payload && payload !== "alreadyShowToast") {
        state.sendVerifCode.data = payload;
        state.sendVerifCode.success = true;
      } else {
        state.sendVerifCode.error = true;
      }

      state.sendVerifCode.isLoading = false;
    });

    builder.addCase(sendVerifCodeSignupSms.rejected, (state) => {
      state.sendVerifCode.success = false;
      state.sendVerifCode.error = true;
      state.sendVerifCode.isLoading = false;
    });

    builder.addCase(sendRecoveryBindEmail.pending, (state) => {
      state.sendVerifCode.success = false;
      state.sendVerifCode.error = false;
      state.sendVerifCode.isLoading = true;
    });

    builder.addCase(sendRecoveryBindEmail.fulfilled, (state, {payload}) => {
      if (payload) {
        state.sendVerifCode.data = payload;
        state.sendVerifCode.success = true;
      } else {
        state.sendVerifCode.error = true;
      }

      state.sendVerifCode.isLoading = false;
    });

    builder.addCase(sendRecoveryBindEmail.rejected, (state) => {
      state.sendVerifCode.success = false;
      state.sendVerifCode.error = true;
      state.sendVerifCode.isLoading = false;
    });

    builder.addCase(sendRecoveryBindPhone.pending, (state) => {
      state.sendVerifCode.success = false;
      state.sendVerifCode.error = false;
      state.sendVerifCode.isLoading = true;
    });

    builder.addCase(sendRecoveryBindPhone.fulfilled, (state, {payload}) => {
      if (payload) {
        state.sendVerifCode.data = payload;
        state.sendVerifCode.success = true;
      } else {
        state.sendVerifCode.error = true;
      }

      state.sendVerifCode.isLoading = false;
    });

    builder.addCase(sendRecoveryBindPhone.rejected, (state) => {
      state.sendVerifCode.success = false;
      state.sendVerifCode.error = true;
      state.sendVerifCode.isLoading = false;
    });

    ////////////////////////////////

    builder.addCase(bindEmailV2.pending, (state) => {
      state.verifCode.success = false;
      state.verifCode.error = false;
      state.verifCode.isLoading = true;
    });

    builder.addCase(bindEmailV2.fulfilled, (state, {payload}) => {
      if (payload) {
        state.verifCode.code = payload;
        state.verifCode.success = true;
      } else {
        state.verifCode.error = true;
      }

      state.verifCode.isLoading = false;
    });

    builder.addCase(bindEmailV2.rejected, (state) => {
      state.verifCode.success = false;
      state.verifCode.error = true;
      state.verifCode.isLoading = false;
    });
    /////////// LOGOUT //////////////

    ////////////////////////////////

    builder.addCase(bindSmsV2.pending, (state) => {
      state.verifCode.success = false;
      state.verifCode.error = false;
      state.verifCode.isLoading = true;
    });

    builder.addCase(bindSmsV2.fulfilled, (state, {payload}) => {
      if (payload) {
        state.verifCode.code = payload;
        state.verifCode.success = true;
      } else {
        state.verifCode.error = true;
      }

      state.verifCode.isLoading = false;
    });

    builder.addCase(bindSmsV2.rejected, (state) => {
      state.verifCode.success = false;
      state.verifCode.error = true;
      state.verifCode.isLoading = false;
    });
    /////////// LOGOUT //////////////

    builder.addCase(sendChangeEmail.pending, (state) => {
      state.sendVerifCode.success = false;
      state.sendVerifCode.error = false;
      state.sendVerifCode.isLoading = true;
    });

    builder.addCase(sendChangeEmail.fulfilled, (state, {payload}) => {
      if (payload) {
        state.sendVerifCode.data = payload;
        state.sendVerifCode.success = true;
      } else {
        state.sendVerifCode.error = true;
      }

      state.sendVerifCode.isLoading = false;
    });

    builder.addCase(sendChangeEmail.rejected, (state) => {
      state.sendVerifCode.success = false;
      state.sendVerifCode.error = true;
      state.sendVerifCode.isLoading = false;
    });

    builder.addCase(sendChangePhone.pending, (state) => {
      state.sendVerifCode.success = false;
      state.sendVerifCode.error = false;
      state.sendVerifCode.isLoading = true;
    });

    builder.addCase(sendChangePhone.fulfilled, (state, {payload}) => {
      if (payload) {
        state.sendVerifCode.data = payload;
        state.sendVerifCode.success = true;
      } else {
        state.sendVerifCode.error = true;
      }

      state.sendVerifCode.isLoading = false;
    });

    builder.addCase(sendChangePhone.rejected, (state) => {
      state.sendVerifCode.success = false;
      state.sendVerifCode.error = true;
      state.sendVerifCode.isLoading = false;
    });

    builder.addCase(verifCode.pending, (state) => {
      state.verifCode.success = false;
      state.verifCode.error = false;
      state.verifCode.isLoading = true;
    });

    builder.addCase(verifCode.fulfilled, (state, {payload}) => {
      if (payload && payload !== "alreadyShowToast") {
        state.verifCode.code = payload;
        state.verifCode.success = true;
      } else {
        state.verifCode.error = true;
      }

      state.verifCode.isLoading = false;
    });

    builder.addCase(verifCode.rejected, (state) => {
      state.verifCode.success = false;
      state.verifCode.error = true;
      state.verifCode.isLoading = false;
    });

    builder.addCase(sendSelfVerifCode.pending, (state) => {
      state.sendSelfVerifCode.success = false;
      state.sendSelfVerifCode.error = false;
      state.sendSelfVerifCode.isLoading = true;
    });

    builder.addCase(sendSelfVerifCode.fulfilled, (state, {payload}) => {
      if (payload) {
        state.sendSelfVerifCode.code = payload;
        state.sendSelfVerifCode.success = true;
      } else {
        state.sendSelfVerifCode.error = true;
      }

      state.sendSelfVerifCode.isLoading = false;
    });

    builder.addCase(sendSelfVerifCode.rejected, (state) => {
      state.sendSelfVerifCode.success = false;
      state.sendSelfVerifCode.error = true;
      state.sendSelfVerifCode.isLoading = false;
    });

    builder.addCase(selfVerifCode.pending, (state) => {
      state.verifCode.success = false;
      state.verifCode.error = false;
      state.verifCode.isLoading = true;
    });

    builder.addCase(selfVerifCode.fulfilled, (state, {payload}) => {
      if (
        payload &&
        payload !== "alreadyShowToast" &&
        payload !== "E_BAD_VCODE"
      ) {
        state.verifCode.code = payload;
        state.verifCode.success = true;
      } else {
        state.verifCode.error = true;
      }

      state.verifCode.isLoading = false;
    });

    builder.addCase(selfVerifCode.rejected, (state) => {
      state.verifCode.success = false;
      state.verifCode.error = true;
      state.verifCode.isLoading = false;
    });

    builder.addCase(changePassword.pending, (state) => {
      state.changePassword.success = false;
      state.changePassword.error = false;
      state.changePassword.isLoading = true;
    });

    builder.addCase(changePassword.fulfilled, (state, {payload}) => {
      payload
        ? (state.changePassword.success = true)
        : (state.changePassword.error = true);

      state.changePassword.isLoading = false;
    });

    builder.addCase(changePassword.rejected, (state) => {
      state.changePassword.success = false;
      state.changePassword.error = true;
      state.changePassword.isLoading = false;
    });

    builder.addCase(changeLoginStep.fulfilled, (state, {payload}) => {
      if (payload) {
        state.loginStep.step = payload;
      }
    });

    builder.addCase(changeSignupStep.fulfilled, (state, {payload}) => {
      if (payload) {
        state.signupStep.step = payload;
      }
    });

    builder.addCase(signup.pending, (state) => {
      state.signup.success = false;
      state.signup.error = false;
      state.signup.isLoading = true;
      state.session.error = false;
    });

    builder.addCase(signup.fulfilled, (state, {payload}) => {
      state.signup.isLoading = false;
      if (payload) {
        state.signup.success = true;
      } else {
        state.signup.error = true;
      }

      if (payload?.err === "E_BAD_VCODE") {
        state.verifCode.success = false;
        state.verifCode.error = true;
      }

      if (payload && !payload.authenticated) {
        state.session.error = true;
        state.signup.error = true;
        return;
      }

      if (payload) {
        state.session.authenticated = payload.authenticated;
        state.session.userinfo = payload.userinfo;
      }
      state.multiAccountLogin.popupDialogOpen = false;
      state.multiAccountLogin.qrLoginPopup = false;

      state.signup.isLoading = false;
    });

    builder.addCase(signup.rejected, (state) => {
      state.signup.success = false;
      state.signup.error = true;
      state.signup.isLoading = false;
      state.session.error = true;
    });

    builder.addCase(quickSignup.pending, (state) => {
      state.signup.success = false;
      state.signup.error = false;
      state.signup.isLoading = true;
      state.session.error = false;
    });

    builder.addCase(quickSignup.fulfilled, (state, {payload}) => {
      if (payload) {
        state.signup.success = true;
      } else {
        state.signup.error = true;
      }

      if (payload && !payload.authenticated) {
        state.session.error = true;
        return;
      }

      if (payload) {
        state.session.authenticated = payload?.authenticated;
        state.session.userinfo = payload?.userinfo;
      }
      state.multiAccountLogin.popupDialogOpen = false;
      state.multiAccountLogin.qrLoginPopup = false;

      state.signup.isLoading = false;
    });

    builder.addCase(quickSignup.rejected, (state) => {
      state.signup.success = false;
      state.signup.error = true;
      state.signup.isLoading = false;
      state.session.error = true;
    });

    builder.addCase(expressSignup.pending, (state) => {
      state.signup.success = false;
      state.signup.error = false;
      state.signup.isLoading = true;
    });

    builder.addCase(expressSignup.fulfilled, (state, {payload}) => {
      if (payload) {
        state.signup.success = true;
      } else {
        state.signup.error = true;
      }

      if (payload && !payload.authenticated) {
        state.session.error = true;
        state.signup.error = true;
        state.signup.isLoading = false;
        return;
      }

      if (payload) {
        state.session.authenticated = payload?.authenticated;
        state.session.userinfo = payload?.userinfo;
      }
      state.multiAccountLogin.popupDialogOpen = false;
      state.multiAccountLogin.qrLoginPopup = false;

      state.signup.isLoading = false;
    });

    builder.addCase(expressSignup.rejected, (state) => {
      state.signup.success = false;
      state.signup.error = true;
      state.signup.isLoading = false;
    });

    builder.addCase(addPinPost.fulfilled, (state, {payload}) => {
      if (Array.isArray(payload)) {
        let pinnedPosts = payload.toString();
        pinnedPosts = '["' + pinnedPosts + '"]';

        let userInfo = {...state.session.userinfo};
        userInfo.pinpsts = pinnedPosts;
        state.session.userinfo = userInfo;

        localStorage.setItem(
          userConstants.LS_SESSION_INFO,
          JSON.stringify(state.session),
        );
      }
    });

    builder.addCase(deletePinPost.fulfilled, (state, {payload}) => {
      if (Array.isArray(payload)) {
        let pinnedPosts = payload.toString();
        pinnedPosts = pinnedPosts ? '["' + pinnedPosts + '"]' : "";

        let userInfo = {...state.session.userinfo};
        userInfo.pinpsts = pinnedPosts;
        state.session.userinfo = userInfo;

        localStorage.setItem(
          userConstants.LS_SESSION_INFO,
          JSON.stringify(state.session),
        );
      }
    });

    builder.addCase(canBroadcastLive.fulfilled, (state, {payload}) => {
      state.broadcast.canBroadcast = payload;
      state.broadcast.initialized = true;
    });

    builder.addCase(canBroadcastLive.rejected, (state) => {
      state.broadcast.canBroadcast = false;
      state.broadcast.initialized = true;
    });

    builder.addCase(saveOusername.fulfilled, (state, {payload}) => {
      if (payload) {
        state.ousername = payload;
      }
    });

    builder.addCase(userFollowStatus.fulfilled, (state, {payload}) => {});
    builder.addCase(fetchUserInfo.pending, (state, {payload}) => {
      state.userInfoLoading = true;
    });
    builder.addCase(fetchUserInfo.fulfilled, (state, {payload}) => {
      state.userInfoLoading = false;
      let userInfo = {...state.session.userinfo};
      if (userInfo?._id === payload?._id) {
        const newUserInfo = {...state.session.userinfo, ...payload};
        state.session.userinfo = newUserInfo;
        let sessionInfo = JSON.parse(
          localStorage.getItem(userConstants.LS_SESSION_INFO),
        );
        sessionInfo.userinfo = newUserInfo;
        localStorage.setItem(
          userConstants.LS_SESSION_INFO,
          JSON.stringify(sessionInfo),
        );
        updateUserInfo(newUserInfo);
      }
    });
    builder.addCase(fetchUserInfo.rejected, (state, {payload}) => {
      state.userInfoLoading = false;
    });
    builder.addCase(reportUser.fulfilled, (state, {payload}) => {});

    builder.addCase(getPublicGlobals.fulfilled, (state, {payload}) => {
      if (payload) {
        state.publicGlobals = payload;
      }
    });

    builder.addCase(getAdsConfig.fulfilled, (state, {payload}) => {
      if (payload) {
        state.adsConfig = typeof payload !== "object" ? {} : payload;

        let ad_timeline_web_freq = parseInt(
          state.adsConfig.ad_timeline_web_freq,
        );
        let ad_search_web_freq = parseInt(state.adsConfig.ad_search_web_freq);
        let ad_timeline_m_freq = parseInt(state.adsConfig.ad_timeline_m_freq);
        let ad_detail_web_freq = parseInt(state.adsConfig.ad_detail_web_freq);
        let ad_profile_web_freq = parseInt(state.adsConfig.ad_profile_web_freq);

        if (ad_timeline_web_freq && ad_timeline_web_freq > 2) {
          state.adsConfig.ad_timeline_web_freq = ad_timeline_web_freq - 1;
        } else {
          state.adsConfig.ad_timeline_web_freq = null;
        }
        if (ad_search_web_freq && ad_search_web_freq > 2) {
          state.adsConfig.ad_search_web_freq = ad_search_web_freq - 1;
        } else {
          state.adsConfig.ad_search_web_freq = null;
        }
        if (ad_timeline_m_freq && ad_timeline_m_freq > 2) {
          state.adsConfig.ad_timeline_m_freq = ad_timeline_m_freq - 1;
        } else {
          state.adsConfig.ad_timeline_m_freq = null;
        }
        if (ad_detail_web_freq && ad_detail_web_freq > 2) {
          state.adsConfig.ad_detail_web_freq = ad_detail_web_freq - 1;
        } else {
          state.adsConfig.ad_detail_web_freq = null;
        }
        if (ad_profile_web_freq && ad_profile_web_freq > 2) {
          state.adsConfig.ad_profile_web_freq = ad_profile_web_freq - 1;
        } else {
          state.adsConfig.ad_profile_web_freq = null;
        }
      }
    });

    builder.addCase(logout.pending, (state) => {
      resetAllState(state);
      state.session.authenticated = false;
      state.session.userinfo = {};
      state.session.errInfo = {};
      state.isLoggingOut = false;
    });

    builder.addCase(socialSignIn.pending, (state) => {
      state.socialAuth.isLoading = true;
      state.socialAuth.error = false;
    });

    builder.addCase(socialSignIn.fulfilled, (state, {payload}) => {
      if (payload.pt === socialConstants.SIGN_IN_PAYLOAD_TYPE.SI_NEW) {
        state.socialAuth.pt = payload.pt;
        state.socialAuth.payload = payload.payload;
        state.socialAuth.isLoading = false;
        state.socialAuth.error = false;
        return;
      }

      if (!payload) {
        state.socialAuth.isLoading = false;
        state.socialAuth.error = true;
        return;
      }

      if (payload && payload.userinfo === "E_USER_BANNED_LOGIN") {
        state.socialAuth.isLoading = false;
        state.socialAuth.error = false;
        state.session.isSuspended = true;
        return;
      }

      if (payload && payload.authenticated && payload.userinfo?._id) {
        state.session.authenticated = payload.authenticated;
        state.session.userinfo = payload.userinfo;
        state.socialAuth.pt = payload.pt; //"e_verify";
        state.socialAuth.payload = payload.payload;
        state.socialAuth.isLoading = false;
        state.socialAuth.error = false;
      } else if (payload && payload.authenticated && !payload?.userinfo?._id) {
        state.socialAuth.pt = payload.pt;
        state.socialAuth.payload = payload.payload;
        state.socialAuth.isLoading = false;
        state.socialAuth.error = false;
      } else {
        state.socialAuth.isLoading = false;
        state.socialAuth.error = true;
      }
    });

    builder.addCase(socialSignIn.rejected, (state) => {
      state.socialAuth.isLoading = false;
      state.socialAuth.error = true;
    });

    builder.addCase(socialSignup.pending, (state) => {
      state.socialAuth.isLoading = true;
      state.socialAuth.error = false;
    });

    builder.addCase(socialSignup.fulfilled, (state, {payload}) => {
      if (!payload) {
        state.socialAuth.isLoading = false;
        state.socialAuth.error = true;
        return;
      }

      if (payload && payload.err) {
        state.socialAuth.isLoading = false;
        state.socialAuth.error = true;
        return;
      }

      if (payload && !payload.authenticated) {
        state.socialAuth.isLoading = false;
        state.socialAuth.error = true;
        return;
      }

      if (payload) {
        state.session.authenticated = payload?.authenticated;
        state.session.userinfo = payload?.userinfo;
      }
      state.socialAuth.pt = payload.pt;
      state.socialAuth.payload = payload.payload;
      state.socialAuth.signup.birth_year = "";
      state.socialAuth.signup.username = "";
      state.socialAuth.isLoading = false;
      state.socialAuth.error = false;
      state.multiAccountLogin.popupDialogOpen = false;
      state.multiAccountLogin.qrLoginPopup = false;
    });

    builder.addCase(socialSignup.rejected, (state) => {
      state.socialAuth.isLoading = false;
      state.socialAuth.error = true;
    });

    builder.addCase(socialLinkSignIn.pending, (state) => {
      state.socialAuth.linkSignIn.isLoading = true;
      state.socialAuth.linkSignIn.error = false;
    });

    builder.addCase(socialLinkSignIn.fulfilled, (state, {payload}) => {
      if (!payload) {
        state.socialAuth.linkSignIn.isLoading = false;
        state.socialAuth.linkSignIn.error = true;
        return;
      }

      if (payload && payload.err) {
        state.socialAuth.linkSignIn.isLoading = false;
        state.socialAuth.linkSignIn.error = true;
        return;
      }

      if (payload && !payload.authenticated) {
        state.socialAuth.linkSignIn.isLoading = false;
        state.socialAuth.linkSignIn.error = true;
        return;
      }

      if (payload) {
        state.session.authenticated = payload?.authenticated;
        state.session.userinfo = payload?.userinfo;
      }
      state.socialAuth.pt = payload.pt;
      state.socialAuth.payload = payload.payload;
      state.socialAuth.linkSignIn.isLoading = false;
      state.socialAuth.linkSignIn.error = false;
      state.multiAccountLogin.popupDialogOpen = false;
      state.multiAccountLogin.qrLoginPopup = false;
    });

    builder.addCase(socialLinkSignIn.rejected, (state) => {
      state.socialAuth.linkSignIn.isLoading = false;
      state.socialAuth.linkSignIn.error = true;
    });

    builder.addCase(socialResendVerifyCode.pending, (state) => {
      state.socialAuth.linkSignIn.resendErr = false;
    });

    builder.addCase(socialResendVerifyCode.fulfilled, (state, {payload}) => {
      if (!payload) {
        state.socialAuth.linkSignIn.resendErr = true;
        return;
      }

      if (payload && payload.err) {
        state.socialAuth.linkSignIn.resendErr = true;
        return;
      }

      state.socialAuth.pt = payload.pt;
      state.socialAuth.payload = payload.payload;
    });

    builder.addCase(socialResendVerifyCode.rejected, (state) => {
      state.socialAuth.linkSignIn.resendErr = true;
    });

    builder.addCase(getSocialStatus.fulfilled, (state, {payload}) => {
      if (payload) {
        state.socialAuthStatus = {...state.socialAuthStatus, ...payload};
      }
    });

    builder.addCase(getSocialConnections.fulfilled, (state, {payload}) => {
      if (payload?.data?.list) {
        state.socialAuthConnections = {
          ...state.socialAuthConnections,
          ...payload.data.list,
        };
      }
    });

    builder.addCase(socialDisconnect.fulfilled, (state, {payload}) => {
      if (payload && payload?.status) {
        state.socialAuthStatus = {...state.socialAuthStatus, ...payload.status};
      }
    });

    builder.addCase(fetchIdentification.pending, (state) => {
      state.getterId.loading = true;
    });

    builder.addCase(fetchIdentification.fulfilled, (state, {payload}) => {
      state.getterId.loading = false;
      state.getterId.names = payload?.data;
    });

    builder.addCase(fetchIdentification.rejected, (state, {payload}) => {
      state.getterId.loading = false;
    });
    builder.addCase(submitIdentification.pending, (state) => {
      state.getterId.loading = true;
    });

    builder.addCase(submitIdentification.fulfilled, (state, {payload}) => {
      state.getterId.loading = false;
      state.getterId.names = payload?.data;
    });

    builder.addCase(submitIdentification.rejected, (state) => {
      state.getterId.loading = false;
    });

    builder.addCase(fetchEndorsement.pending, (state) => {});

    builder.addCase(fetchEndorsement.fulfilled, (state, {payload}) => {
      state.getterId.isEndorsed = payload?.data?.list;
    });

    builder.addCase(fetchEndorsement.rejected, (state) => {});
    builder.addCase(endorsementSize.pending, (state) => {});

    builder.addCase(endorsementSize.fulfilled, (state, {payload}) => {
      state.getterId.endorsedSize = payload;
    });

    builder.addCase(endorsementSize.rejected, (state) => {});
    builder.addCase(deleteEndorsement.pending, (state) => {
      state.getterId.loading = true;
    });

    builder.addCase(deleteEndorsement.fulfilled, (state, {payload}) => {
      state.getterId.loading = false;
      state.getterId.isDeleted = payload?.data;
      state.getterId.isEndorsed = [];
    });

    builder.addCase(deleteEndorsement.rejected, (state) => {
      state.getterId.loading = true;
    });
    builder.addCase(setEndorsement.pending, (state) => {});

    builder.addCase(setEndorsement.fulfilled, (state, {payload}) => {
      state.getterId.isEndorsed = [payload?.data?.uid];
    });

    builder.addCase(setEndorsement.rejected, (state) => {});
  },
});

export const {refreshUserInfo, refreshUserConfirmEmail} = authSlice.actions;
