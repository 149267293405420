import {getNotifSettings} from "./getNotifSettings";
import {updateNotifSettings} from "./updateNotifSettings";
import {updateGTokSettings} from "./updateGTokSettings";

// TODO: prevent type widening
export const SettingsApi = {
  getNotifSettings,
  updateNotifSettings,
  updateGTokSettings,
};
