import {postSubmit} from "./postSubmit";
import {editPostSubmit} from "./editPostSubmit";
import {repostSubmit} from "./repostSubmit";
import {commentSubmit} from "./commentSubmit";
import {userSharesPost} from "./userSharesPost";
import {userSharesComment} from "./userSharesComment";
import {deletePost} from "./deletePost";
import {deleteStreamPost} from "./deleteStreamPost";
import {deletePostComment} from "./deletePostComment";
import {reportPost} from "./reportPost";
import {getStreams, getMaStream, getStream} from "./getStream";
import {translatePost} from "./translatePost";
import {votePoll} from "./votePoll";
import {fetchComments} from "./getComments";
import {handleImageEdit} from "./handleImageEdit";
import {getTopicData} from "./getTopicData";
import {fetchPostInfo} from "./fetchPostInfo";

// TODO: prevent type widening
export const PostApi = {
  postSubmit,
  editPostSubmit,
  repostSubmit,
  commentSubmit,
  userSharesPost,
  userSharesComment,
  deletePost,
  deleteStreamPost,
  deletePostComment,
  reportPost,
  getStream,
  getMaStream,
  getStreams,
  translatePost,
  votePoll,
  fetchComments,
  handleImageEdit,
  getTopicData,
  fetchPostInfo,
};
