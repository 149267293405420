import GAxios from "src/util/GAxios";
import {userConstants} from "../_constants";
import {t} from "src/i18n/utils";
import {showToast} from "../../../../util/showToast";

export const sendVerifCodeRecoveryEmail = async (params) => {
  const {email, password, token, hasEmail, callback, errorCallback} = params;

  const getErrorMsg = (code) => {
    if (hasEmail) {
      return t("getter_fe.auth.errors.recoveryEmailShutDown2");
    }

    return t("getter_fe.auth.errors.recoveryEmailShutDown");
  };

  if (!email) return;

  let reqtype = userConstants.VERIF_CODE_REQ_TYPE_EMAIL;
  let data = null;
  if (password) {
    reqtype = userConstants.REQUEST_TYPE_CHANGE_EMAIL;
    data = JSON.stringify({
      content: {
        email: email.toLowerCase(),
        password: password,
        lang: "en", // [TODO]: will be replace when the current lang will be available in the Redux Store.
        reqtype: reqtype,
        token: token,
      },
    });
  } else {
    data = JSON.stringify({
      content: {
        email: email.toLowerCase(),
        lang: "en", // [TODO]: will be replace when the current lang will be available in the Redux Store.
        reqtype: reqtype,
        token: token,
      },
    });
  }

  const config = {
    method: "post",
    url: userConstants.API_SEND_VERIF_CODE_EMAIL,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  let res;

  await GAxios(
    config,
    (response) => {
      if (response && response.data?.result?.success) {
        res = params;
      } else {
        res = null;
      }
      callback(response);
    },
    (err) => {
      let errorCode = err?.response.data?.error?.code;
      if (errorCode) {
        showToast("error", getErrorMsg(errorCode));
      }
      res = null;
      if (errorCallback) errorCallback(err);
    },
  );

  return res;
};
