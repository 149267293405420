import {createSlice, createAsyncThunk, createAction} from "@reduxjs/toolkit";
import DOMPurify from "dompurify";
import {AICopilotApi} from "../api";

const NS = "aiCopilot";

const scopeTemplate = {
  originalText: "",
  outputText: "",
  fn_aiCommand: () => {},
  feedbackStatus: "",
  prevText: "",
  prevTextHistory: [],
};

const initialState = {
  aiAllowed: null,
  isLoading: false,
  feedbackIsLoading: false,
  ...scopeTemplate,
};

export const checkAIAllowed = createAsyncThunk(
  "aiCopilot/checkAIAllowed",
  AICopilotApi.aiCheckPermission,
);
export const aiAddText = createAsyncThunk(
  "aiCopilot/aiAddText",
  AICopilotApi.aiAddText,
);
export const aiReduceText = createAsyncThunk(
  "aiCopilot/aiReduceText",
  AICopilotApi.aiReduceText,
);
export const aiCorrectGrammar = createAsyncThunk(
  "aiCopilot/aiCorrectGrammar",
  AICopilotApi.aiCorrectGrammar,
);
export const aiAddRelatedHashtags = createAsyncThunk(
  "aiCopilot/aiAddRelatedHashtags",
  AICopilotApi.aiAddRelatedHashtags,
);
export const aiRephrase = createAsyncThunk(
  "aiCopilot/aiRephrase",
  AICopilotApi.aiRephrase,
);
export const aiFeedback = createAsyncThunk(
  "aiCopilot/aiFeedback",
  AICopilotApi.aiFeedback,
);

export const resetState = createAction(`${NS}/resetState`);
export const undo = createAction(`${NS}/undo`);
export const setAICommand = createAction(`${NS}/setAICommand`);

const handleFulFilled = (state, payload) => {
  if (!payload || !!payload?.error) return;
  const {text, outputText, scope} = payload;
  const _outputText = DOMPurify.sanitize(outputText);
  const _state = getStateScope(state, scope);
  _state.outputText = _outputText + " ";
  _state.prevText = text;
  if (!_state.originalText) {
    _state.originalText = text;
  }
};

const getStateScope = (state, scope) => {
  let _state;
  if (!scope) {
    _state = state;
  } else if (state[scope]) {
    _state = state[scope];
  } else {
    state[scope] = {...scopeTemplate};
    _state = state[scope];
  }
  return _state;
};

export const aiCopilotSlice = createSlice({
  name: NS,
  initialState,
  reducers: {
    resetState: (state, {payload = {}}) => {
      const {scope} = payload;
      const _state = getStateScope(state, scope);
      _state.outputText = "";
      _state.originalText = "";
    },
    undo: (state, {payload = {}}) => {
      const {scope} = payload;
      const _state = getStateScope(state, scope);
      _state.outputText = _state.originalText;
      _state.originalText = "";
    },
    setAICommand: (state, {payload = {}}) => {
      const {callback, scope} = payload;
      const _state = getStateScope(state, scope);
      _state.fn_aiCommand = callback;
      _state.prevTextHistory.push(_state.prevText);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkAIAllowed.fulfilled, (state, {payload}) => {
      state.aiAllowed = payload?.status === "ok";
    });

    builder.addCase(aiAddText.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(aiAddText.fulfilled, (state, {payload}) => {
      handleFulFilled(state, payload);
      state.isLoading = false;
    });
    builder.addCase(aiAddText.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(aiReduceText.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(aiReduceText.fulfilled, (state, {payload}) => {
      handleFulFilled(state, payload);
      state.isLoading = false;
    });
    builder.addCase(aiReduceText.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(aiCorrectGrammar.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(aiCorrectGrammar.fulfilled, (state, {payload}) => {
      handleFulFilled(state, payload);
      state.isLoading = false;
    });
    builder.addCase(aiCorrectGrammar.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(aiAddRelatedHashtags.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(aiAddRelatedHashtags.fulfilled, (state, {payload}) => {
      handleFulFilled(state, payload);
      state.isLoading = false;
    });
    builder.addCase(aiAddRelatedHashtags.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(aiRephrase.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(aiRephrase.fulfilled, (state, {payload}) => {
      handleFulFilled(state, payload);
      state.isLoading = false;
    });
    builder.addCase(aiRephrase.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(aiFeedback.pending, (state) => {
      state.feedbackIsLoading = true;
    });
    builder.addCase(aiFeedback.fulfilled, (state, {payload}) => {
      const {status, scope} = payload;
      const _state = getStateScope(state, scope);
      _state.feedbackStatus = status;
      state.feedbackIsLoading = false;
    });
    builder.addCase(aiFeedback.rejected, (state) => {
      state.feedbackIsLoading = false;
    });
  },
});
