import axios from "axios";
import {toast} from "react-toastify";
import {NotifMessage} from "src/app/components/notifications/NotifMessage";
import {t} from "src/i18n/utils";

const uploadBaseUrl = process.env.REACT_APP_MEDIA_UPLOAD;

const toaster = () =>
  toast(
    <NotifMessage message={t("getter_fe.post.editImage.image_too_big")} />,
    {
      position: toast.POSITION.TOP_CENTER,
      type: toast.TYPE.WARNING,
    },
  );

export const handleImageEdit = async (imgData) => {
  const {type, image, upscale, mask, fileType} = imgData;
  if (!image || !type) return;

  let data = {
    ori: image?.ori,
    operation: "rb",
  };

  if (type === "super-resolution") {
    data.operation = "sr";
    data.factor = upscale;
  } else if (type === "in-painting") {
    data.operation = "ip";
    data.mask = mask?.ori;
  }

  const userInfo = JSON.parse(
    localStorage.getItem("LS_SESSION_INFO"),
  )?.userinfo;

  let res;
  await axios({
    method: "post",
    url: `${uploadBaseUrl}/media/imgen`,
    headers: {
      Authorization: userInfo?.token,
      userid: userInfo?._id,
      filename: "editedImage." + fileType?.replace("image/", ""),
    },
    data,
  })
    .then((response) => {
      if (response.data.status !== 0) {
        res = false;
        toaster();
      } else {
        res = response;
      }
    })
    .catch(() => {
      res = false;
      toaster();
    });
  return {res: res ? res.data : false, type, upscale};
};
