import {isResponseOk, parsePostDetail} from "src/util/FeedUtils";
import GAxios from "src/util/GAxios";

export const fetchPostInfo = async (originalId, originPost) => {
  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/u/post/${
      originalId || originPost?.originPostId
    }?incl="poststats|userinfo|shared|liked|pvotes"`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  const successCallback = (response) => {
    if (isResponseOk(response)) {
      try {
        const {post, user} = parsePostDetail(response?.data?.result) ?? {};
        if (post && user) {
          res = post;
        }
      } catch (e) {
        console.warn(e);
      }
    }
  };

  const errorCallback = (error) => {
    console.error(error);
  };

  let res = {};
  await GAxios(config, successCallback, errorCallback);

  return res;
};
