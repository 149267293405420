import {createAction, createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {findIndex, has, map} from "lodash-es";
import {TimelineApi} from "src/app/components/timeline/api";
import {timelineConstants} from "src/app/components/timeline/_constants";
import {ActivityLogProps} from "src/app/AppConsts";
import {
  anonTimelineTypeTable,
  timelineTypeTable,
  timelineViews,
} from "src/util/FeedUtils";

let timelineTab;
try {
  timelineTab = JSON.parse(localStorage.getItem("timelineTabValue"));
} catch {
  timelineTab = null;
}

const NS = "timelineNew";
const initialState = {
  userFeed: {
    data: [],
    offset: 0,
    cursor: null,
    isLoading: false,
    error: false,
    reachEnd: false,
    nbrRemoved: 0,
    users: {},
    startTs: Date.now(),
    videoKey: null,
    scrollIndex: 0,
    isScroll: false,
    isBackTop0: false,
    scrollTop: 0,
    isScrollTop: false,
  },
  forYouUserFeed: {
    data: [],
    offset: 0,
    cursor: null,
    isLoading: false,
    error: false,
    reachEnd: false,
    nbrRemoved: 0,
    users: {},
    startTs: Date.now(),
    videoKey: null,
    scrollIndex: 0,
    isScroll: false,
    isBackTop0: false,
    scrollTop: 0,
    isScrollTop: false,
  },
  chillMode: {
    data: [],
    offset: 0,
    cursor: null,
    isLoading: false,
    error: false,
    reachEnd: false,
    nbrRemoved: 0,
    users: {},
    startTs: Date.now(),
    videoKey: null,
    scrollIndex: 0,
    isScroll: false,
    isBackTop0: false,
    scrollTop: 0,
    isScrollTop: false,
  },
  anonFeed: {
    data: [],
    offset: 0,
    isLoading: false,
    error: false,
    reachEnd: false,
    nbrRemoved: 0,
    users: {},
  },
  liveNowFeed: {
    data: [],
    offset: 0,
    isLoading: false,
    error: false,
    reachEnd: false,
    nbrRemoved: 0,
    users: {},
  },
  refreshProfileFeed: false,
  pinnedPosts: [],
  popularUsers: {
    data: [],
    isLoading: false,
    init: false,
  },
  userPostFeed: {
    posts: [],
    users: {},
    offset: 0,
    cursor: null,
    nbrRemoved: 0,
    isLoading: false,
    error: false,
    reachEnd: false,
    startTs: Date.now(),
    userId: null,
    requestId: null,
    tabIndex: 0,
  },
  tabValue: 0,
  theme74PopupOpen: false,
  theme74PopupInit: false,
  timelineTabValue: [0, 1].includes(timelineTab) ? timelineTab : 0, // Following: 0, For you : 1
  clickTryIt: false,
  clickPoll: false,
  peopleToFollow: {
    data: [],
    isLoading: false,
    followsYou: [],
    youAreFollowing: [],
    supportUsers: {},
    displayedIds: [],
    cursor: null,
    stopFetch: false,
  },
  originalPosts: {
    data: [],
  },
  trendingFeed: {
    data: null,
    users: {},
    error: false,
    completed: false,
  },
  moduleSwitch: {},
  currentDisplayPostId: 0,
  newsTopics: {
    data: [],
    init: false,
  },
  popularUserData: null,
  editedPosts: {},
};

export const getUserFeed = createAsyncThunk(
  "timeline/getUserFeed",
  TimelineApi.getUserFeed,
);

export const getAnonFeed = createAsyncThunk(
  "timeline/getAnonFeed",
  TimelineApi.getAnonFeed,
);

export const getTrendingFeed = createAsyncThunk(
  "timeline/getTrendingFeed",
  TimelineApi.getTrendingFeed,
);

export const getLiveNowFeed = createAsyncThunk(
  "timeline/getLiveNowFeed",
  TimelineApi.getLiveNowFeed,
);

export const toggleLike = createAsyncThunk(
  "timeline/toggleLike",
  TimelineApi.toggleLike,
);

export const getUserPostFeed = createAsyncThunk(
  "timeline/getUserPostFeed",
  TimelineApi.getUserPostFeed,
);

export const getPeopleToFollow = createAsyncThunk(
  "timeline/getPeopleToFollow",
  TimelineApi.getPeopleToFollow,
);

export const getModuleSwitch = createAsyncThunk(
  "timeline/getModuleSwitch",
  TimelineApi.getModuleSwitch,
);

export const getPopUserData = createAsyncThunk(
  "timeline/getPopUserData",
  TimelineApi.getPopUserData,
);

export const resetTimelineStatus = createAction(`${NS}/resetTimelineStatus`);
export const resetTimelineData = createAction(`${NS}/resetTimelineData`);
export const addPost = createAction(`${NS}/addPost`);
export const addUserPost = createAction(`${NS}/addUserPost`); // switch profile page, and then click create post. the data will push in  timelineNew.userPostFeed index 0
export const removeUserPost = createAction(`${NS}/removeUserPost`);
export const updatePost = createAction(`${NS}/updatePost`);
export const addUsers = createAction(`${NS}/addUsers`);
export const setVideoKey = createAction(`${NS}/setVideoKey`);
// export const setScrollIndex = createAction(`${NS}/setScrollIndex`);
// export const setIsScroll = createAction(`${NS}/setIsScroll`);
// export const setIsBackTop0 = createAction(`${NS}/setIsBackTop0`);
// export const setScrollTop = createAction(`${NS}/setScrollTop`);
// export const setIsScrollTop = createAction(`${NS}/setIsScrollTop`);
export const setClickTryIt = createAction(`${NS}/setClickTryIt`);
export const removePost = createAction(`${NS}/removePost`);
export const removePostsForBanAndMute = createAction(
  `${NS}/removePostsForBanAndMute`,
);
export const removeRepost = createAction(`${NS}/removeRepost`);
export const resetRefreshProfileFeed = createAction(
  `${NS}/resetRefreshProfileFeed`,
);
export const setRefreshProfileFeed = createAction(
  `${NS}/setRefreshProfileFeed`,
);
export const resetPinnedPost = createAction(`${NS}/resetPinnedPost`);
export const removePinnedPost = createAction(`${NS}/removePinnedPost`);
export const resetPuStatus = createAction(`${NS}/resetPuStatus`);
export const setPuIsLoading = createAction(`${NS}/setPuIsLoading`);
export const setPuData = createAction(`${NS}/setPuData`);
export const removePuByUserId = createAction(`${NS}/removePuByUserId`);
export const updatePuByUserId = createAction(`${NS}/updatePuByUserId`);
export const removePostFromProfile = createAction(
  `${NS}/removePostFromProfile`,
);
export const removePostsFromProfileForBanAndMute = createAction(
  `${NS}/removePostsFromProfileForBanAndMute`,
);
export const updatePostFromProfile = createAction(
  `${NS}/updatePostFromProfile`,
);
export const resetUserPostFeed = createAction(`${NS}/resetUserPostFeed`);
export const setTabValue = createAction(`${NS}/setTabValue`);
export const setTheme74PopupOpen = createAction(`${NS}/setTheme74PopupOpen`);
export const setTimelineTabValue = createAction(`${NS}/setTimelineTabValue`);
export const setPtfIsLoading = createAction(`${NS}/setPtfIsLoading`);
export const setPtfFollowYou = createAction(`${NS}/setPtfFollowYou`);
export const setPtfSupportUsers = createAction(`${NS}/setPtfSupportUsers`);
export const setPtfData = createAction(`${NS}/setPtfData`);
export const setPtfDisplayedIds = createAction(`${NS}/setPtfDisplayedIds`);
export const resetPtfStatus = createAction(`${NS}/resetPtfStatus`);
export const addOriginalPost = createAction(`${NS}/addOriginalPost`);
export const setCurrentDisplayPostId = createAction(
  `${NS}/setCurrentDisplayPostId`,
);
export const setNewsTopics = createAction(`${NS}/setNewsTopics`);

export const timelineSliceNew = createSlice({
  name: NS,
  initialState,
  reducers: {
    resetTimelineStatus: (state) => {
      state.userFeed.error = false;
      state.userFeed.isLoading = false;
      state.userFeed.success = false;
      state.userFeed.reachEnd = false;
      state.userFeed.cursor = null;
      state.forYouUserFeed.error = false;
      state.forYouUserFeed.isLoading = false;
      state.forYouUserFeed.success = false;
      state.forYouUserFeed.reachEnd = false;
      state.forYouUserFeed.cursor = null;
      state.chillMode.error = false;
      state.chillMode.isLoading = false;
      state.chillMode.success = false;
      state.chillMode.reachEnd = false;
      state.chillMode.cursor = null;
      state.anonFeed.error = false;
      state.anonFeed.isLoading = false;
      state.anonFeed.success = false;
      state.anonFeed.reachEnd = false;
      state.liveNowFeed.error = false;
      state.liveNowFeed.isLoading = false;
      state.liveNowFeed.success = false;
      state.liveNowFeed.reachEnd = false;
      state.userPostFeed.error = false;
      state.userPostFeed.isLoading = false;
      state.userPostFeed.success = false;
      state.userPostFeed.reachEnd = false;
      state.userPostFeed.cursor = null;
      state.userPostFeed.tabIndex = 0;
      state.theme74PopupOpen = false;
      state.theme74PopupInit = false;
    },
    resetTimelineData: (state) => {
      state.userFeed.data = [];
      state.userFeed.cursor = null;
      state.userFeed.nbrRemoved = 0;
      state.userFeed.startTs = Date.now();
      state.userFeed.videoKey = null;
      state.userFeed.scrollIndex = 0;
      state.userFeed.isScroll = false;
      state.userFeed.isBackTop0 = false;
      state.userFeed.scrollTop = 0;
      state.userFeed.isScrollTop = false;
      state.forYouUserFeed.data = [];
      state.forYouUserFeed.cursor = null;
      state.forYouUserFeed.nbrRemoved = 0;
      state.forYouUserFeed.startTs = Date.now();
      state.forYouUserFeed.videoKey = null;
      state.forYouUserFeed.scrollIndex = 0;
      state.forYouUserFeed.isScroll = false;
      state.forYouUserFeed.isBackTop0 = false;
      state.forYouUserFeed.scrollTop = 0;
      state.forYouUserFeed.isScrollTop = false;
      state.chillMode.data = [];
      state.chillMode.cursor = null;
      state.chillMode.nbrRemoved = 0;
      state.chillMode.startTs = Date.now();
      state.chillMode.videoKey = null;
      state.chillMode.scrollIndex = 0;
      state.chillMode.isScroll = false;
      state.chillMode.isBackTop0 = false;
      state.chillMode.scrollTop = 0;
      state.chillMode.isScrollTop = false;
      state.chillMode.offset = 0;
      state.userPostFeed.posts = [];
      state.userPostFeed.users = {};
      state.userPostFeed.cursor = null;
      state.userPostFeed.startTs = Date.now();
      state.userPostFeed.tabIndex = 0;
      state.anonFeed.data = [];
      state.anonFeed.offset = 0;
      state.anonFeed.nbrRemoved = 0;
      state.liveNowFeed.data = [];
      state.liveNowFeed.offset = 0;
    },
    addPost(state, action) {
      let data = JSON.parse(JSON.stringify(state.userFeed.data));
      // update the Index
      let posts = [action.payload, ...data];
      state.userFeed.data = posts.map((item, idx) => {
        return {
          ...item,
          index: idx,
        };
      });

      state.refreshProfileFeed = true;
    },
    addUserPost(state, action) {
      state.userPostFeed.posts = [action.payload, ...state.userPostFeed.posts];
    },
    removeUserPost(state, action) {
      const {postId, postAction} = action.payload;
      const data = [...state.userPostFeed.posts];
      state.userPostFeed.posts = data.filter((post) => {
        return post.id !== postId;
      });
    },
    updatePost(state, action) {
      const posts = [...JSON.parse(JSON.stringify(state.userFeed.data))];
      // find the edited Index
      const editedIndex = findIndex(
        posts,
        (item) => item.id === action.payload.id,
      );
      const {prevsrc, dsc, previmg, ttl, ...rest} = {...posts[editedIndex]};
      const newPost = {
        ...rest,
        ...action.payload,
        txt: action.payload.txt || "",
      };
      posts.splice(editedIndex, 1, newPost);

      state.userFeed.data = map(posts, (item) => {
        if ((item?.embedPost?._id || item?.embedPost?.id) === newPost?.id) {
          item.embedPost = newPost;
        }
        return item;
      });

      state.refreshProfileFeed = true;

      newPost?.id && (state.editedPosts[newPost.id] = newPost);
    },
    addUsers(state, action) {
      let users = {...action.payload, ...state.userFeed.users};
      state.userFeed.users = users;
    },
    setClickTryIt(state, {payload}) {
      state.clickTryIt = payload;
    },
    setVideoKey(state, action) {
      state.userFeed.videoKey = action?.payload || null;
    },
    setScrollIndex(state, {payload}) {
      let name = payload?.type;
      state[name].scrollIndex = payload?.scrollIndex;
    },
    setIsScroll(state, {payload}) {
      let name = payload?.type;
      state[name].isScroll = payload?.isScroll;
    },
    setIsBackTop0(state, {payload}) {
      let name = payload?.type;
      state[name].isBackTop0 = payload?.isBackTop0;
    },
    setScrollTop(state, {payload}) {
      let name = payload?.type;
      state[name].scrollTop = payload?.scrollTop;
    },
    setIsScrollTop(state, {payload}) {
      let name = payload?.type;
      state[name].isScrollTop = payload?.isScrollTop;
    },
    removePost(state, action) {
      const {postId, postAction} = action.payload;

      const data = [...state.userFeed.data];

      state.userFeed.data = data.filter((post) => {
        if (postAction) {
          return !(post.id === postId && post.action === postAction);
        } else {
          return post.id !== postId;
        }
      });
    },
    removePostsForBanAndMute(state, action) {
      const {uid} = action.payload;

      const data = [...state.userFeed.data];

      state.userFeed.data = data.filter((post) => {
        if (post?.originUser) return post.originUser !== uid;
        return post.uid !== uid;
      });
    },
    removeRepost(state, action) {
      const postId = action.payload;

      const data = [...state.userFeed.data];

      const newData = data.filter((post) => {
        const postItemAction = post.action;

        if (
          postItemAction === ActivityLogProps.SHARES_POST ||
          postItemAction === ActivityLogProps.SHARES_COMMENT
        ) {
          return post.id !== postId;
        } else {
          return true;
        }
      });

      state.userFeed.data = newData;
    },
    resetRefreshProfileFeed(state) {
      state.refreshProfileFeed = false;
    },
    setRefreshProfileFeed(state) {
      state.refreshProfileFeed = true;
    },
    resetPinnedPost(state, action) {
      state.pinnedPosts = [];
    },
    removePinnedPost(state, action) {
      const pstId = action.payload;

      let posts = [...state.pinnedPosts];

      let newPosts = posts.filter((post) => post.id !== pstId);

      state.pinnedPosts = newPosts;
    },
    resetPuStatus(state) {
      // reset to intial state
      state.popularUsers = {
        data: [],
        query: {
          max: 100,
          incl: "userinfo|followings",
        },
        isLoading: false,
        init: false,
      };
    },
    setPuIsLoading(state, action) {
      state.popularUsers.isLoading = action.payload;
      state.popularUsers.init = true;
    },
    setPuData(state, action) {
      const popularUsers = action.payload.filter(
        (user) =>
          !state.popularUsers.data.some((_user) => _user._id === user._id),
      );
      state.popularUsers.data = [...state.popularUsers.data, ...popularUsers];
    },
    removePuByUserId(state, action) {
      const newData = state.popularUsers.data.filter(
        ({_id}) => action.payload !== _id,
      );
      state.popularUsers.data = newData;
    },
    updatePuByUserId(state, action) {
      const newData = state.popularUsers.data.filter(
        ({_id}) => action.payload === _id,
      );
      state.popularUsers.data = [...newData, ...state.popularUsers.data];
    },
    removePostFromProfile(state, action) {
      const {id} = action.payload ?? {};

      let posts = [...state.userPostFeed.posts];
      state.userPostFeed.posts = posts.filter((post) => post.id !== id);
    },
    removePostsFromProfileForBanAndMute(state, action) {
      const {uid} = action.payload ?? {};

      let posts = [...state.userPostFeed.posts];
      state.userPostFeed.posts = posts.filter((post) => {
        if (post.originUser) return post.originUser !== uid;
        return post.uid !== uid;
      });
    },
    updatePostFromProfile(state, action) {
      // find the edited Index
      const editedIndex = findIndex(
        state.userPostFeed.posts,
        (item) => item.id === action.payload.id,
      );
      const posts = [...state.userPostFeed.posts];
      const {prevsrc, dsc, previmg, ttl, ...rest} = {...posts[editedIndex]};
      posts.splice(editedIndex, 1, {...rest, ...action.payload});

      state.userPostFeed.posts = posts;
    },
    resetUserPostFeed(state, action) {
      state.userPostFeed = initialState.userPostFeed;
    },
    setTabValue(state, action) {
      state.tabValue = action.payload;
    },
    setTheme74PopupOpen(state, action) {
      state.theme74PopupOpen = action.payload;
      state.theme74PopupInit = true;
    },
    setTimelineTabValue(state, action) {
      state.timelineTabValue = action.payload;
    },
    setPtfIsLoading(state, action) {
      state.peopleToFollow.isLoading = action.payload;
    },
    setPtfFollowYou(state, action) {
      state.peopleToFollow.followsYou = action.payload;
    },
    setPtfSupportUsers(state, action) {
      state.peopleToFollow.supportUsers = action.payload;
    },
    setPtfData(state, action) {
      state.peopleToFollow.data = action.payload;
    },
    setPtfDisplayedIds(state, action) {
      if (state.peopleToFollow.data.length > action.payload.length) {
        state.peopleToFollow.displayedIds = action.payload;
      } else {
        state.peopleToFollow.displayedIds = [];
      }
    },
    resetPtfStatus(state) {
      state.peopleToFollow.data = [];
      state.peopleToFollow.isLoading = false;
      state.peopleToFollow.followsYou = [];
      state.peopleToFollow.youAreFollowing = [];
      state.peopleToFollow.supportUsers = {};
    },
    addOriginalPost(state, action) {
      state.originalPosts.data = [action.payload, ...state.originalPosts.data];
    },
    setCurrentDisplayPostId(state, {payload}) {
      state.currentDisplayPostId = payload;
    },
    setNewsTopics(state, {payload}) {
      state.newsTopics.data = payload;
      state.newsTopics.init = true;
    },
  },
  extraReducers: (builder) => {
    // Doc: https://redux-toolkit.js.org/usage/usage-with-typescript#type-safety-with-extrareducers

    builder.addCase(getUserFeed.pending, (state, action) => {
      const name = timelineTypeTable[action?.meta?.arg];
      state[name].isLoading = true;
      state[name].error = false;
      state[name].reachEnd = false;
    });

    builder.addCase(getUserFeed.fulfilled, (state, {payload}) => {
      if (!payload) {
        let name = timelineTypeTable[timelineViews[state.timelineTabValue]];
        state[name].isLoading = false;
        state[name].error = true;
        state[name].reachEnd = true;
      } else {
        const {type, posts, removed, users, cursor} = payload;

        if (posts) {
          state[timelineTypeTable[type]].error = false;
          state[timelineTypeTable[type]].success = true;

          // let count = posts?.length ?? 0;
          // let nbrDupPsts = posts.filter(
          //   (post, index, self) =>
          //     index === self.findIndex((t) => t.key === post.key),
          // );
          // count = count - (nbrDupPsts?.length ?? 0);

          let newPosts = [...state[timelineTypeTable[type]].data, ...posts];
          // ?.filter(
          //   (post, index, self) =>
          //     index === self.findIndex((t) => t.key === post.key),
          // );

          state[timelineTypeTable[type]].data = newPosts;
          state[timelineTypeTable[type]].users = {
            ...state[timelineTypeTable[type]].users,
            ...users,
          };
          state[timelineTypeTable[type]].timestamp = payload.timestamp;
        } else if (removed) {
          state[timelineTypeTable[type]].error = false;
          state[timelineTypeTable[type]].success = true;
          state[timelineTypeTable[type]].timestamp = payload.timestamp;
        } else {
          state[timelineTypeTable[type]].error = false;
          state[timelineTypeTable[type]].success = false;
        }
        state[timelineTypeTable[type]].nbrRemoved = removed;
        state[timelineTypeTable[type]].cursor = cursor;
        state[timelineTypeTable[type]].isLoading = false;
        if (!cursor) {
          state[timelineTypeTable[type]].reachEnd = true;
        }
      }
    });

    builder.addCase(getUserFeed.rejected, (state, action) => {
      const name = timelineTypeTable[action?.meta?.arg];
      state[name].isLoading = false;
      state[name].error = true;
      state[name].reachEnd = false;
    });

    builder.addCase(getLiveNowFeed.fulfilled, (state, {payload}) => {
      if (payload) {
        const {posts, removed, users, offset} = payload;
        if (posts?.length + removed < timelineConstants.MAX_BATCH_SIZE) {
          state.liveNowFeed.reachEnd = true;
        }
        if (posts) {
          state.liveNowFeed.error = false;
          state.liveNowFeed.data = [...state.liveNowFeed.data, ...posts];
          state.liveNowFeed.users = {...state.liveNowFeed.users, ...users};
          state.liveNowFeed.offset = offset;
          state.liveNowFeed.nbrRemoved = removed;
        } else if (removed) {
          state.liveNowFeed.error = false;
          state.liveNowFeed.success = true;
          state.liveNowFeed.offset = offset;
          state.liveNowFeed.nbrRemoved = removed;
        } else {
          state.liveNowFeed.error = true;
          state.liveNowFeed.success = false;
          state.liveNowFeed.reachEnd = false;
        }
      }

      state.liveNowFeed.isLoading = false;
    });

    builder.addCase(getLiveNowFeed.rejected, (state) => {
      state.liveNowFeed.isLoading = false;
      state.liveNowFeed.error = true;
      state.liveNowFeed.reachEnd = false;
    });

    builder.addCase(getLiveNowFeed.pending, (state) => {
      state.liveNowFeed.isLoading = true;
      state.liveNowFeed.error = false;
      state.liveNowFeed.reachEnd = false;
    });

    builder.addCase(getAnonFeed.fulfilled, (state, {payload}) => {
      if (payload) {
        const {posts, removed, users, offset, type} = payload;
        const currentType = anonTimelineTypeTable[type] || "anonFeed";
        if (posts?.length + removed < timelineConstants.MAX_BATCH_SIZE) {
          state[currentType].reachEnd = true;
        }
        if (posts) {
          state[currentType].error = false;
          state[currentType].data = [...state[currentType].data, ...posts];
          state[currentType].users = {
            ...state[currentType].users,
            ...users,
          };
          state[currentType].offset = offset;
          state[currentType].nbrRemoved = removed;
        } else if (removed) {
          state[currentType].error = false;
          state[currentType].success = true;
          state[currentType].offset = offset;
          state[currentType].nbrRemoved = removed;
        } else {
          state[currentType].error = true;
          state[currentType].success = false;
          state[currentType].reachEnd = false;
        }
      }

      state.anonFeed.isLoading = false;
      state.chillMode.isLoading = false;
    });

    builder.addCase(getAnonFeed.rejected, (state, action) => {
      const currentType = action?.arg?.type || action.meta.arg.type;
      const type = anonTimelineTypeTable[currentType] || "anonFeed";
      state[type].isLoading = false;
      state[type].error = true;
      state[type].reachEnd = false;
    });

    builder.addCase(getAnonFeed.pending, (state, action) => {
      const currentType = action?.arg?.type || action.meta.arg.type;
      const type = anonTimelineTypeTable[currentType] || "anonFeed";
      state[type].isLoading = true;
      state[type].error = false;
      state[type].reachEnd = false;
    });

    builder.addCase(getTrendingFeed.fulfilled, (state, {payload}) => {
      const {posts, users} = payload ?? {};
      if (posts) {
        state.trendingFeed.data = [...posts];
        state.trendingFeed.users = {...users};
      } else {
        state.trendingFeed.data = [];
        state.trendingFeed.users = {};
        state.trendingFeed.error = true;
      }
      state.trendingFeed.completed = true;
    });

    builder.addCase(getTrendingFeed.rejected, (state) => {
      state.trendingFeed.data = [];
      state.trendingFeed.users = {};
      state.trendingFeed.completed = true;
      state.trendingFeed.error = true;
    });

    builder.addCase(getTrendingFeed.pending, (state) => {
      state.trendingFeed.completed = false;
      state.trendingFeed.error = false;
    });

    builder.addCase(getUserPostFeed.fulfilled, (state, {payload, meta}) => {
      // Avoid race conditions by handling only the matching request
      if (payload && state.userPostFeed.requestId === meta.requestId) {
        const {posts, removed, users, userId, cursor} = payload;

        state.userPostFeed.userId = userId;
        if (!cursor) {
          state.userPostFeed.reachEnd = true;
        }

        if (posts) {
          state.userPostFeed.error = false;
          state.userPostFeed.posts = [...state.userPostFeed.posts, ...posts];
          state.userPostFeed.users = {...state.userPostFeed.users, ...users};
        } else if (removed) {
          state.userPostFeed.error = false;
          state.userPostFeed.success = true;
        } else {
          state.userPostFeed.error = false;
          state.userPostFeed.success = false;
        }
        state.userPostFeed.isLoading = false;
        state.userPostFeed.nbrRemoved = removed;
        state.userPostFeed.cursor = cursor;
      }
    });

    builder.addCase(getUserPostFeed.rejected, (state, {meta}) => {
      // Avoid race conditions by handling only the matching request
      if (state.userPostFeed.requestId === meta.requestId) {
        state.userPostFeed.isLoading = false;
        state.userPostFeed.error = true;
        state.userPostFeed.reachEnd = false;
      }
    });

    builder.addCase(getUserPostFeed.pending, (state, {meta}) => {
      state.userPostFeed.requestId = meta.requestId;
      state.userPostFeed.tabIndex = meta.arg.tabIndex;
      state.userPostFeed.isLoading = true;
      state.userPostFeed.error = false;
      state.userPostFeed.reachEnd = false;
    });

    builder.addCase(getPeopleToFollow.pending, (state) => {
      state.peopleToFollow.isLoading = true;
    });

    builder.addCase(getPeopleToFollow.fulfilled, (state, {payload}) => {
      if (!payload) {
        state.peopleToFollow.isLoading = false;
      } else {
        let dataArr = [];
        const aux = payload.data?.result?.aux;
        const uinf = aux?.uinf;
        const data = payload.data?.result?.data;
        state.peopleToFollow.cursor = aux?.cursor;

        if (aux?.fwr) {
          state.peopleToFollow.followsYou = [
            ...state.peopleToFollow.followsYou,
            ...aux.fwr,
          ];
        }

        if (aux?.fws) {
          state.peopleToFollow.youAreFollowing = [
            ...state.peopleToFollow.youAreFollowing,
            ...aux.fws,
          ];
        }

        if (uinf && data.size > 0) {
          Object.keys(data.cats).forEach((key, idx) => {
            if (has(uinf, key)) {
              const the_user = uinf[key];

              if (
                state.peopleToFollow.data.filter(
                  ({_id}) => the_user?._id === _id,
                ).length
              ) {
                // return if the id already exits in the popularUsers.data array
              } else if (the_user?.nickname?.toLowerCase() === "unknown user") {
                //
              } else {
                dataArr.push(the_user);
              }
            }

            // get support users
            const cat = data.cats[key];
            if (cat && cat?.support_users) {
              const suList = [];
              cat.support_users.forEach((key, idx) => {
                if (uinf[key]) {
                  suList.push(uinf[key]);
                }
              });
              state.peopleToFollow.supportUsers[key] = suList;
            } else {
              state.peopleToFollow.supportUsers[key] = [];
            }
          });
        }

        if (dataArr.length) {
          state.peopleToFollow.data = [
            ...state.peopleToFollow.data,
            ...dataArr,
          ];
          state.peopleToFollow.stopFetch = false;
        } else {
          state.peopleToFollow.stopFetch = true;
        }
        state.peopleToFollow.isLoading = false;
      }
    });

    builder.addCase(getPeopleToFollow.rejected, (state) => {
      state.peopleToFollow.isLoading = false;
    });

    builder.addCase(getModuleSwitch.fulfilled, (state, {payload}) => {
      state.moduleSwitch = payload?.data?.result;
    });
    builder.addCase(getPopUserData.pending, (state) => {
      state.popularUsers.isLoading = true;
    });
    builder.addCase(getPopUserData.fulfilled, (state, {payload}) => {
      state.popularUserData = payload;
      state.popularUsers.isLoading = false;
    });
  },
});
