import {getTimeline} from "./getTimeline";
import {getSearch} from "./getSearch";
import {getRecommend} from "./getRecommend";
import {getPost} from "./getPost";
import {getProfileGTok} from "./getProfileGTok";
import {getSearchGTok} from "./getSearchGTok";
import {recommendFallback} from "./recommendFallback";
import {timelineFallback} from "./timelineFallback";
import {putGTokViewed} from "./putGTokViewed";
import {getSuggestUsers} from "./getSuggestUsers";
import {getTrendingGTok} from "./getTrendingGTok";
import {changeGTok} from "./changeGTok";

export const visionApi = {
  getTimeline,
  getSearch,
  getRecommend,
  getPost,
  getProfileGTok,
  getSearchGTok,
  recommendFallback,
  timelineFallback,
  putGTokViewed,
  getSuggestUsers,
  getTrendingGTok,
  changeGTok,
};
