import GAxios from "src/util/GAxios";

export const getChatConfig = async () => {
  const config = {
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/v1/chat/config`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  let result = null;
  await GAxios(
    config,
    async (res) => {
      result = {
        ...(res.data?.result || {}),
      };
    },
    (err) => {
      console.error(err);
    },
  );

  return result;
};
