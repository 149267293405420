import GAxios from "src/util/GAxios";

export const fetchLiveStreamPostListItems = async (
  offset,
  max,
  callback,
  errorCallback,
) => {
  try {
    await GAxios(
      {
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/u/posts/v2/livenow`,
        params: {
          incl: "posts|stats|userinfo|shared|liked",
          lang: "all",
          offset,
          max,
        },
      },
      (res) => {
        callback(res);
      },
      (err) => {
        if (errorCallback) errorCallback(err);
      },
    );
  } catch (err) {
    console.error(err);
  }
};
