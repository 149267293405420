import i18next from "i18next";
import AppConsts from "src/app/AppConsts";
import {SupportedLanguageList} from "../../app/AppMessages";
import {getObjectFromArrayByValue} from "src/util/TextUtil";
import enUS from "date-fns/locale/en-US";
import es from "date-fns/locale/es";
import de from "date-fns/locale/de";
import hi from "date-fns/locale/hi";
import ja from "date-fns/locale/ja";
import ptBR from "date-fns/locale/pt-BR";
import zhCN from "date-fns/locale/zh-CN";
import zhTW from "date-fns/locale/zh-TW";
import {parse} from "qs";

const DEFAULT_LANG = "en_us";
const CATCH_I18N_T = "getter-i18n-t";

// https://github.com/moment/moment/tree/develop/locale
const langTable = {
  de_de: "de", // German
  es_es: "es", // Spanish
  ja_jp: "ja", // Japanese
  hi_in: "hi", // Hindi
  pt_br: "pt-br", // Brazilian Portugese
  zh_cn: "zh-cn", // Simplified
  zh_hant_tw: "zh-tw", // Traditional
};

export const dateFnsTable = {
  en_us: enUS,
  de_de: de, // German
  es_es: es, // Spanish
  ja_jp: ja, // Japanese
  hi_in: hi, // Hindi
  pt_br: ptBR, // Brazilian Portugese
  zh_cn: zhCN, // Simplified
  zh_hant_tw: zhTW, // Traditional
};

export function setLang(nextLang, callBack) {
  localStorage.setItem(AppConsts.LOCAL_STORAGE_LAST_BROWSER_LANG, nextLang);
  i18next.changeLanguage(nextLang).then((t) => {});
  callBack && callBack();
}

export function getLang() {
  const queryString = window.location.search.slice(1);
  const urlParams = parse(queryString);
  const urlLang = urlParams.lang;
  let curLang =
    urlLang ?? localStorage.getItem(AppConsts.LOCAL_STORAGE_LAST_BROWSER_LANG);
  curLang = curLang?.toLocaleLowerCase();

  const isWhitelistedLang =
    curLang &&
    getObjectFromArrayByValue(SupportedLanguageList, "code", curLang);

  const browserLang = getBrowserLang();

  const defaultLang = browserLang || DEFAULT_LANG;

  const lang = isWhitelistedLang
    ? isWhitelistedLang.code === curLang
      ? curLang
      : "en_us"
    : defaultLang;
  return lang;
}

export function returnMomentLocale(locale) {
  return langTable[locale] ? langTable[locale] : locale;
}

export function cacheI18nT(t) {
  window[CATCH_I18N_T] = t;
}

/**
 * @deprecated
 *
 * For better bundle size,
 * please use useTranslation instead.
 *
 * @example
 * import {useTranslation} from 'react-i18next';
 *
 * function Component () {
 *   const {t} = useTranslation();
 *   t("path.to.key")
 * }
 */
export function t(key, opt) {
  const t = window[CATCH_I18N_T];
  return t ? t(key, opt) : null;
}

export function getBrowserLang() {
  const browserLang = navigator.language.toLowerCase().replace("-", "_");

  const langExist = SupportedLanguageList.find(
    (lng) => lng.code === browserLang,
  );

  let finalLang = langExist ? browserLang : null;

  if (!langExist) {
    finalLang = SupportedLanguageList.find((lng) =>
      lng.code.startsWith(browserLang.slice(0, 2)),
    );

    finalLang = finalLang?.code;

    if (browserLang.startsWith("zh") && browserLang !== "zh-cn") {
      finalLang = "zh_hant_tw";
    }
  }

  return finalLang;
}

const isDayOrMoment = (output) => !!output?.isSame;

const isDate = (output) => !!output?.getMonth;

const isNumberOrString = (output) =>
  typeof output === "string" || typeof output === "number";

export function getDateString({
  calendar,
  format,
  formatDate,
  messageCreatedAt,
  tDateTimeParser,
}) {
  if (
    !messageCreatedAt ||
    (typeof messageCreatedAt === "string" && !Date.parse(messageCreatedAt))
  ) {
    console.warn(
      "MessageTimestamp was called without a message, or message has invalid created_at date.",
    );
    return null;
  }

  if (typeof formatDate === "function") {
    return formatDate(new Date(messageCreatedAt));
  }

  if (!tDateTimeParser) {
    console.warn(
      "MessageTimestamp was called but there is no datetime parsing function available",
    );
    return null;
  }

  const parsedTime = tDateTimeParser(messageCreatedAt);

  if (isDayOrMoment(parsedTime)) {
    /**
     * parsedTime.calendar is guaranteed on the type but is only
     * available when a user calls dayjs.extend(calendar)
     */
    return calendar && parsedTime.calendar
      ? parsedTime.calendar()
      : parsedTime.format(format);
  }

  if (isDate(parsedTime)) {
    return parsedTime.toDateString();
  }

  if (isNumberOrString(parsedTime)) {
    return parsedTime;
  }

  return null;
}
