import GAxios from "src/util/GAxios";

export const fetchUserListItems = async (
  phrase,
  userOffset,
  max,
  callback,
  errorCallback,
  incl = "userinfo|followings|followers",
) => {
  try {
    const decodedPhrase = decodeURIComponent(phrase);
    await GAxios(
      {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/u/users/srch/phrase`,
        data: {
          content: {
            incl,
            q: decodedPhrase,
            offset: userOffset,
            max,
          },
        },
      },
      (res) => {
        callback(res);
      },
      (err) => {
        if (errorCallback) errorCallback(err);
      },
    );
  } catch (err) {
    console.error(err);
  }
};
