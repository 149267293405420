import {createSlice, createAction, createAsyncThunk} from "@reduxjs/toolkit";
import {LiveListSearchApi} from "../api";
import {SearchApi} from "src/app/components/search/api";
import {keyBy, trim, uniqBy, isNumber} from "lodash-es";
import {
  MAX_LIST_ITEMS,
  NEW_LOCAL_STORAGE_LIVE_RECENT_SEARCH,
  SUGGESTION_MODES,
  SUGGESTION_TYPES,
  UPDATE_DATE_TYPES,
  DURATION_TYPES,
} from "../_constant";
import {v4 as uuid} from "uuid";
import {
  adaptUpdateDate,
  adaptDuration,
  adaptFeatures,
} from "src/app/components/LiveList/LiveListSearch/utils";

const NS = "liveListSearch";

let initRecentSearchList;

try {
  initRecentSearchList =
    JSON.parse(localStorage.getItem(NEW_LOCAL_STORAGE_LIVE_RECENT_SEARCH)) ||
    [];
} catch (error) {
  initRecentSearchList = [];
}
export const initialFilter = {
  currentFilter: {},
  proxyFilter: {
    updateDate: UPDATE_DATE_TYPES.ANY_DATE,
    duration: DURATION_TYPES.ANY_TYPE,
    any: true,
    live_now: false,
    // 4k: false,
    // hd: false,
    subtitles_cc: false,
  },
};
const initialState = {
  hashtagList: [],
  hostsList: [],
  recentSearchList: initRecentSearchList,
  selectedIndex: -1,
  currentSuggestionMode: SUGGESTION_MODES.RECENT,
  searchLoading: "",
  recoSearchLoading: "",
  selectedSuggestion: null,
  popList: [],
  popListStatus: "",
  currentPhrase: "",
  ...initialFilter,
  liveSearch: {
    suggestionList: [],
    loading: true,
  },
  bigV: {
    user: {},
    list: [],
    loading: true,
    phrase: "",
  },
  hosts: {
    list: [],
    loading: true,
    cursor: "",
    phrase: "",
  },
  liveNow: {
    list: [],
    loading: true,
    cursor: "",
    phrase: "",
    filter: {},
  },
  replays: {
    list: [],
    loading: true,
    cursor: "",
    newList: [],
    phrase: "",
    filter: {},
  },
  isRateExceeded: false,
  streamData: {
    infos: {},
    loading: true,
  },
};

export const fetchSearchChoices = createAsyncThunk(
  "liveListSearch/fetchSearchChoices",
  SearchApi.fetchSearchChoices,
);
export const fetchLiveSearchChoices = createAsyncThunk(
  "liveListSearch/fetchLiveSearchChoices",
  LiveListSearchApi.fetchLiveSearchChoices,
);
export const fetchBigV = createAsyncThunk(
  "liveListSearch/fetchBigV",
  LiveListSearchApi.fetchBigV,
);
export const fetchHosts = createAsyncThunk(
  "liveListSearch/fetchHosts",
  LiveListSearchApi.fetchHosts,
);
export const fetchLive = createAsyncThunk(
  "liveListSearch/fetchLive",
  LiveListSearchApi.fetchLive,
);
export const fetchReplays = createAsyncThunk(
  "liveListSearch/fetchReplays",
  LiveListSearchApi.fetchReplays,
);
export const fetchStreamData = createAsyncThunk(
  "liveListSearch/fetchStreamData",
  LiveListSearchApi.fetchStreamData,
);

export const resetSearch = createAction(`${NS}/resetSearch`);
export const setSelectedIndex = createAction(`${NS}/setSelectedIndex`);
export const resetSelectedIndex = createAction(`${NS}/resetSelectedIndex`);
export const setRecentSearchList = createAction(`${NS}/setRecentSearchList`);
export const resetRecentSearchList = createAction(
  `${NS}/resetRecentSearchList`,
);
export const setProxyFilter = createAction(`${NS}/setProxyFilter`);
export const resetProxyFilter = createAction(`${NS}/resetProxyFilter`);
export const resetIsRateExceeded = createAction(`${NS}/resetIsRateExceeded`);

export const liveListSearchSlice = createSlice({
  name: NS,
  initialState,
  reducers: {
    resetSearch: (state) => {
      // state.hashtagList = initialState.hashtagList;
      // state.hostsList = initialState.hostsList;
      // (state.suggestion = initialState.suggestion),
      //   (state.searchLoading = initialState.searchLoading);
      state.selectedIndex = initialState.selectedIndex;
      state.currentSuggestionMode = initialState.currentSuggestionMode;
      state.selectedSuggestion = initialState.selectedSuggestion;
      state.liveSearch = {...initialState.liveSearch};
    },
    setSelectedIndex: (state, {payload}) => {
      let list =
        state.currentSuggestionMode === SUGGESTION_MODES.RECENT
          ? state.recentSearchList
          : [...state.suggestion, ...state.hashtagList, ...state.hostsList];

      if (
        state.currentSuggestionMode === SUGGESTION_MODES.RESULT &&
        list?.length
      )
        list.push({
          id: list?.length,
          type: SUGGESTION_TYPES.ALL,
        });

      const maxIndex = list?.length - 1;

      if (maxIndex < 0) {
        state.selectedIndex = initialState.selectedIndex;
      } else {
        const nextIndex = isNumber(payload?.id)
          ? payload.id
          : state.selectedIndex + payload;
        if (nextIndex < 0) state.selectedIndex = maxIndex;
        if (nextIndex > maxIndex) state.selectedIndex = 0;
        if (nextIndex >= 0 && nextIndex <= maxIndex)
          state.selectedIndex = nextIndex;
      }
      const selectedItem = list?.[state.selectedIndex] || null;

      if (selectedItem) {
        state.selectedSuggestion = {
          type: selectedItem?.type,
          phrase: selectedItem?.phrase,
        };
      } else {
        state.selectedSuggestion = initialState.selectedSuggestion;
        state.selectedIndex = initialState.selectedIndex;
      }
    },
    resetSelectedIndex: (state) => {
      state.selectedIndex = initialState.selectedIndex;
      state.selectedSuggestion = initialState.selectedSuggestion;
    },
    setRecentSearchList: (state, {payload}) => {
      const list = [
        {
          phrase: trim(payload),
          uuid: uuid(),
          date: new Date().toISOString(),
          type: SUGGESTION_TYPES.RECENT,
        },
        ...state.recentSearchList,
      ]?.filter((item) => !!item);

      const uniqList = (
        uniqBy(list, "phrase")?.slice(0, MAX_LIST_ITEMS) || []
      )?.map((item, index) => ({
        ...item,
        id: index,
      }));

      state.recentSearchList = uniqList;
      state.currentPhrase = trim(payload);

      localStorage.setItem(
        NEW_LOCAL_STORAGE_LIVE_RECENT_SEARCH,
        JSON.stringify(uniqList),
      );
    },
    resetRecentSearchList: (state) => {
      state.recentSearchList = [];
      localStorage.setItem(NEW_LOCAL_STORAGE_LIVE_RECENT_SEARCH, null);
      state.selectedIndex = initialState.selectedIndex;
      state.selectedSuggestion = initialState.selectedSuggestion;
    },
    setProxyFilter: (state, {payload}) => {
      state.proxyFilter = payload;
      state.currentFilter = {
        ...adaptUpdateDate(payload.updateDate),
        ...adaptDuration(payload.duration),
        ...adaptFeatures(payload),
      };
    },
    resetProxyFilter: (state) => {
      state.proxyFilter = {...initialFilter.proxyFilter};
      state.currentFilter = {...initialFilter.currentFilter};
    },
    resetIsRateExceeded: (state) => {
      state.isRateExceeded = false;
    },
  },
  extraReducers: (builder) => {
    // Doc: https://redux-toolkit.js.org/usage/usage-with-typescript#type-safety-with-extrareducers
    builder.addCase(fetchLiveSearchChoices.pending, (state, {payload}) => {
      state.liveSearch.loading = true;
      state.currentSuggestionMode = SUGGESTION_MODES.RESULT;
    });
    builder.addCase(fetchLiveSearchChoices.fulfilled, (state, {payload}) => {
      const {list} = payload;
      state.liveSearch.suggestionList = list;
      state.liveSearch.loading = false;
      state.isRateExceeded = payload?.response?.status === 429;
    });
    builder.addCase(fetchLiveSearchChoices.rejected, (state, {payload}) => {
      state.liveSearch = {...initialState.liveSearch};
    });
    builder.addCase(fetchBigV.pending, (state, {payload}) => {
      state.bigV.loading = true;
    });
    builder.addCase(fetchBigV.fulfilled, (state, {payload}) => {
      const {user = {}, list = [], phrase} = payload;
      state.bigV.loading = false;
      state.isRateExceeded = payload?.response?.status === 429;
      if (state.currentPhrase !== phrase) return;
      state.bigV.user = user;
      state.bigV.list = list;
      state.bigV.phrase = phrase;
    });
    builder.addCase(fetchBigV.rejected, (state, {payload}) => {
      state.bigV = {...initialState.bigV};
      state.bigV.loading = false;
    });
    builder.addCase(fetchHosts.pending, (state, {payload}) => {
      state.hosts.loading = true;
    });
    builder.addCase(fetchHosts.fulfilled, (state, {payload}) => {
      const {data, aux, reset, cursor, phrase} = payload;
      state.hosts.loading = false;
      state.isRateExceeded = payload?.response?.status === 429;
      if (state.currentPhrase !== phrase) return;
      const livecount = keyBy(aux?.livecount || [], "uid");
      const list =
        data?.list?.map((host) => {
          return {
            ...aux.uinf[host],
            isLive: aux.islive.includes(host),
            livecount: livecount?.[host]?.count,
          };
        }) || [];
      state.hosts.list = reset ? list : [...state.hosts.list, ...list];
      state.hosts.cursor = cursor ? {[`p_${phrase}`]: cursor} : null;
      state.hosts.phrase = phrase;
    });
    builder.addCase(fetchHosts.rejected, (state, {payload}) => {
      state.hosts = {...initialState.hosts};
      state.hosts.loading = false;
    });
    builder.addCase(fetchLive.pending, (state, {payload}) => {
      state.liveNow.loading = true;
    });
    builder.addCase(fetchLive.fulfilled, (state, {payload}) => {
      const {list = [], cursor, reset, phrase, filter = {}} = payload;
      state.liveNow.loading = false;
      state.isRateExceeded = payload?.response?.status === 429;
      if (state.currentPhrase !== phrase) return;
      state.liveNow.list = reset ? list : [...state.liveNow.list, ...list];
      state.liveNow.cursor = cursor
        ? {[`p_${phrase}_${JSON.stringify(filter)}`]: cursor}
        : null;
      state.liveNow.phrase = phrase;
      state.liveNow.filter = filter;
    });
    builder.addCase(fetchLive.rejected, (state, {payload}) => {
      state.liveNow = {...initialState.liveNow};
      state.liveNow.loading = false;
    });
    builder.addCase(fetchReplays.pending, (state, {payload}) => {
      state.replays.loading = true;
    });
    builder.addCase(fetchReplays.fulfilled, (state, {payload}) => {
      const {list = [], cursor, reset, phrase, filter = {}} = payload;
      state.replays.loading = false;
      state.isRateExceeded = payload?.response?.status === 429;
      if (state.currentPhrase !== phrase) return;
      state.replays.list = reset ? list : [...state.replays.list, ...list];
      state.replays.cursor = cursor
        ? {[`p_${phrase}_${JSON.stringify(filter)}`]: cursor}
        : null;
      state.replays.newList = list;
      state.replays.phrase = phrase;
      state.replays.filter = filter;
    });
    builder.addCase(fetchReplays.rejected, (state, {payload}) => {
      state.replays = {...initialState.replays};
      state.replays.loading = false;
    });
    builder.addCase(fetchStreamData.pending, (state, {payload}) => {
      state.streamData.loading = true;
    });
    builder.addCase(fetchStreamData.fulfilled, (state, {payload}) => {
      state.streamData.loading = false;
      const {streamsInfo = {}, maStreamsInfo = {}} = payload;
      const newBoardCast = {};
      Object.keys(streamsInfo || {})?.map((item) => {
        newBoardCast[item] = streamsInfo[item].broadcast || {};
      });

      state.streamData.infos = {
        ...state.streamData.infos,
        ...newBoardCast,
        ...maStreamsInfo,
      };
    });
    builder.addCase(fetchStreamData.rejected, (state, {payload}) => {
      state.streamData = {...initialState.streamData};
    });
  },
});
