import {getTimeline} from "./getTimeline";
import {getSearch} from "./getSearch";

let fallback = false;
export const timelineFallback = async (userId, thunkAPI) => {
  try {
    if (fallback) return await getSearch("", thunkAPI);
    const res = await getTimeline(userId, thunkAPI);
    if (res?.posts?.length && !res?.cursor) {
      fallback = true;
    }
    if (!res?.posts?.length) {
      fallback = true;
      return await getSearch("", thunkAPI);
    }
    return res;
  } catch (error) {
    console.error(error);
  }
};
