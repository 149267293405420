import {
  isResponseOk,
  parseItemStats,
  parseTimelineFeed,
  parseUser,
} from "src/util/FeedUtils";
import GAxios from "src/util/GAxios";
import {timelineConstants} from "src/app/components/timeline/_constants/timeline.constants";
import {addFollowingUserIds} from "src/store/modules/status";
import {updateFollowingGTok, updateGTok} from "src/app/components/gtok/store";
import {emptyDataEventTrack} from "src/util/trackEvent";
import {
  addLikedPosts,
  addSharedPosts,
  setPostsStats,
} from "src/store/modules/postStats";

const fetchTimeline = async (queryParams = {}) => {
  const {userId, ...rest} = queryParams;
  if (!userId) {
    return null;
  }
  let resp = null;
  try {
    const params = {
      // the max number of items to be returned.
      max: timelineConstants.MAX_BATCH_SIZE,
      // the number of items skipped from the beginning before fetching starts. Note: if cursor is used, this parameter will be ignored.
      // cursor: 0,
      dir: "fwd",
      //  the include options specifies what information should be preloaded before returning result to front end. It is also used as flags in some scenarios to tell backend what type of data to return.
      incl: "posts|stats|userinfo|shared|liked|followings|followers",
      ...rest,
    };

    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/u/user/${userId}/visions`,
      params,
    };

    await GAxios(
      config,
      (res) => {
        if (isResponseOk(res)) {
          const {aux, data} = res?.data?.result ?? {};
          const {list} = data ?? {};
          const {lks: likedPosts, removed, shrs: sharedPosts} = aux ?? {};
          const users = parseUser(aux);

          let posts = parseTimelineFeed(list, aux);

          let count = posts?.length ?? 0;
          count = count - (posts?.length ?? 0);
          const postStats = parseItemStats(aux);
          if (list?.length === 0 || !list) {
            emptyDataEventTrack("VISION_FOLLOWING", res);
          }
          resp = {
            likedPosts,
            posts,
            removed: removed + count,
            users,
            sharedPosts,
            offset: queryParams.offset + timelineConstants.MAX_BATCH_SIZE,
            postStats,
            fws: aux?.fws ?? [],
            // cursor: aux?.cursor || 0,
          };
        } else {
          emptyDataEventTrack("VISION_FOLLOWING", res);
        }
      },
      (err) => {
        console.error(err);
        emptyDataEventTrack("VISION_FOLLOWING", err);
      },
    );
  } catch (error) {
    console.error(error);
  }

  return resp;
};

export const getTimeline = async (
  userId,
  {getState, dispatch},
  switcher = {},
) => {
  try {
    if (!userId) {
      return null;
    }

    const {vision} = getState?.() ?? {};
    const {offset = 0} = vision ?? {};

    const {autoCompletion = true} = switcher;
    if (!autoCompletion) {
      const res = await fetchTimeline({
        userId,
        offset,
      });
      const {fws, sharedPosts, likedPosts, postStats} = res ?? {};
      postStats && dispatch(setPostsStats(res?.postStats));
      fws && dispatch(addFollowingUserIds(fws));
      sharedPosts?.length && dispatch(addSharedPosts(sharedPosts));
      likedPosts?.length && dispatch(addLikedPosts(likedPosts));

      return res;
    }

    let totalPosts = [];
    let currentOffset = offset;
    let firstFetch = true;
    let reachedEnd = false;

    while (
      firstFetch ||
      (!reachedEnd &&
        totalPosts?.length < timelineConstants?.AUTO_COMPLETION_SIZE)
    ) {
      firstFetch = false;
      const res = await fetchTimeline({
        // cursor: currentCursor,
        userId,
        offset: currentOffset,
      });
      const {
        posts = [],
        postStats = {},
        offset: resOffset,
        users,
        fws,
        sharedPosts,
        likedPosts,
      } = res ?? {};

      dispatch(updateFollowingGTok(res));
      dispatch(updateGTok(res));
      postStats && dispatch(setPostsStats(postStats));
      fws && dispatch(addFollowingUserIds(fws));
      sharedPosts?.length && dispatch(addSharedPosts(sharedPosts));
      likedPosts?.length && dispatch(addLikedPosts(likedPosts));

      if (posts?.length < timelineConstants.MAX_BATCH_SIZE) {
        reachedEnd = true;
      }

      totalPosts = posts?.concat(totalPosts);
      currentOffset = resOffset;
    }
    return {
      posts: totalPosts,
      offset: currentOffset,
    };
  } catch (e) {
    console.error(e);
  }
};
