import {createSlice, createAsyncThunk, createAction} from "@reduxjs/toolkit";
import {uniqBy} from "lodash-es";
import AppConsts from "src/app/AppConsts";
import {visionApi} from "../api/index";
import {addFollowingUserIds} from "src/store/modules/status";
import {parseUser} from "src/util/FeedUtils";

const NS = "vision";

const DEFAULT_VOLUME = 0;

const initialState = {
  muted: true,
  volume: DEFAULT_VOLUME,
  seek: 0,
  vIndex: 0,
  startIndex: 0,
  offset: 0,
  source: null,
  params: null,
  isLoading: false,
  cursor: 0,
  portV1Cursor: 0,
  data: [],
  // postStats: {},
  dataStatus: [],
  users: {},
  reachEnd: false,
  chatInputFocus: false,

  // Recommend state parts
  recommendData: [],
  recommendIndex: 0,
  recommendStartIndex: 0,
  recommendReachEnd: false,
  recommendCursor: 0,
  recommendOffset: 0,

  // Followed state parts
  followedData: [],
  followedIndex: 0,
  followedStartIndex: 0,

  // Popular users state parts
  suggestUsers: {},
  isSuggestUsersLoading: false,

  // 0: for the recommend scrollY
  // 1: for the followded scrollY
  visionScrollPosition: [0, 0],

  // vision landing page state
  currentPlayingVideo: null,
  switchAndCloseVision: "",
  // vision composer state
  upload: {
    visionUploadPopupOpen: false,
    postSuccessPopupOpen: false,
  },
  trendingVisionData: null,
  trendingCompleted: false,

  // edit vision state
  editVision: null,
  editVisionDialogOpen: false,
};

export const closeEditGTokDialog = createAction(`${NS}/closeEditGTokDialog`);
export const setEditGTokDialogOpen = createAction(
  `${NS}/setEditGTokDialogOpen`,
);
export const editGTokPost = createAction(`${NS}/editGTokPost`);
export const setGTokPostSuccessPopupOpen = createAction(
  `${NS}/setGTokPostSuccessPopupOpen`,
);
export const setGTokUploadPopupOpen = createAction(
  `${NS}/setGTokUploadPopupOpen`,
);
export const setCurrentPlayingVideo = createAction(
  `${NS}/setCurrentPlayingVideo`,
);
export const updateVolume = createAction(`${NS}/updateVolume`);
export const resetGTok = createAction(`${NS}/resetGTok`);
export const resetFollowedGTok = createAction(`${NS}/resetFollowedGTok`);
export const updateVIndex = createAction(`${NS}/updateVIndex`);
export const updateFollowedVIndex = createAction(`${NS}/updateFollowedVIndex`);
export const updateRecommendVIndex = createAction(
  `${NS}/updateRecommendVIndex`,
);
export const updateVidSeek = createAction(`${NS}/updateVidSeek`);
export const updateGTok = createAction(`${NS}/updateGTok`);
export const updateMuted = createAction(`${NS}/updateMuted`);
export const updateSeek = createAction(`${NS}/updateSeek`);
export const updateSource = createAction(`${NS}/updateSource`);
export const delGTok = createAction(`${NS}/delGTok`);
export const delFollowedGTok = createAction(`${NS}/delFollowedGTok`);
export const updateChatInputFocus = createAction(`${NS}/updateChatInputFocus`);
export const endSession = createAction(`${NS}/endSession`);
export const updateStartIndex = createAction(`${NS}/updateStartIndex`);
export const nextGTokIndex = createAction(`${NS}/nextGTokIndex`);
export const updateFollowingGTok = createAction(`${NS}/updateFollowingGTok`);
export const setGTokScrollPosition = createAction(
  `${NS}/setGTokScrollPosition`,
);
export const updateFollowedStartIndex = createAction(
  `${NS}/updateFollowedStartIndex`,
);
export const updateRecommendStartIndex = createAction(
  `${NS}/updateRecommendStartIndex`,
);
export const mutatesuggestUser = createAction(`${NS}/mutatesuggestUser`);
// export const getUserById = createAction(`${NS}/getUserById`)

// Recommend state parts
export const resetRecommendGTok = createAction(`${NS}/resetRecommendGTok`);
export const updateRecommendGTok = createAction(`${NS}/updateRecommendGTok`);
export const delRecommendGTok = createAction(`${NS}/delRecommendGTok`);
export const updateRecommendIndex = createAction(`${NS}/updateRecommendIndex`);
export const setSwitchAndCloseGTok = createAction(
  `${NS}/setSwitchAndCloseGTok`,
);
export const updateTrendingGTok = createAction(`${NS}/updateTrendingGTok`);
export const notInterestedGTok = createAction(`${NS}/notInterestedGTok`);

export const changeGTok = createAsyncThunk(
  `${NS}/changeGTok`,
  visionApi.changeGTok,
);

export const getRecommend = createAsyncThunk(
  `${NS}/getRecommend`,
  visionApi.getRecommend,
);

export const getPost = createAsyncThunk(`${NS}/getPost`, visionApi.getPost);

export const getSuggestUsersAsync = createAsyncThunk(
  `${NS}/getSuggestUsersAsync`,
  ({userId, token}, {dispatch, getState}) => {
    return visionApi.getSuggestUsers({userId, token}).then((res) => {
      // update followers
      if (res?.fws?.length) {
        dispatch(addFollowingUserIds(res?.fws));
      }
      return res;
    });
  },
);
export const endGTokSessionAsync = createAsyncThunk(
  `${NS}/endGTokSession`,
  (_, {dispatch, getState}) => {
    // dispatch endSession
    // dispatch(endSession());
    // check if vision recommend data is less than 6, then fetch another 10 recommend data
    const state = getState();
    const {vision} = state;
    const {recommendData} = vision;
    if (recommendData.length <= 6) {
      return dispatch(getRecommend());
    }
    return Promise.resolve();
  },
);

// export const getUserById = createAsyncThunk(
//   `${NS}/getUserById`,
//   async (id, {getState}) => {
//     const {vision} = getState() ?? {};
//     const {users} = vision;

//     return users[id];
//   },
// );

export const recommendFallback = createAsyncThunk(
  `${NS}/recommendFallback`,
  visionApi.recommendFallback,
);

export const getSearch = createAsyncThunk(
  `${NS}/getSearch`,
  visionApi.getSearch,
);

export const getProfileGTok = createAsyncThunk(
  `${NS}/getProfileGTok`,
  visionApi.getProfileGTok,
);

export const getSearchGTok = createAsyncThunk(
  `${NS}/getSearchGTok`,
  visionApi.getSearchGTok,
);

export const putGTokViewed = createAsyncThunk(
  `${NS}/putGTokViewed`,
  visionApi.putGTokViewed,
);

export const getTimeline = createAsyncThunk(
  `${NS}/getTimeline`,
  visionApi.getTimeline,
);

export const getTrendingGTok = createAsyncThunk(
  `${NS}/getTrendingGTok`,
  visionApi.getTrendingGTok,
);

export const visionSlice = createSlice({
  name: NS,
  initialState,
  reducers: {
    closeEditGTokDialog: (state) => {
      state.editVisionDialogOpen = false;
      state.editVision = null;
    },
    setEditGTokDialogOpen: (state, action) => {
      state.editVisionDialogOpen = action.payload;
    },
    editGTokPost: (state, action) => {
      state.editVisionDialogOpen = true;
      state.editVision = action.payload;
    },
    setGTokPostSuccessPopupOpen: (state, action) => {
      state.upload.postSuccessPopupOpen = action.payload;
    },
    setGTokUploadPopupOpen: (state, action) => {
      state.upload.visionUploadPopupOpen = action.payload;
    },
    mutatesuggestUser: (state, {payload}) => {
      if (payload) {
        const originUser = state.suggestUsers[payload._id || payload.id];
        const userInfo = parseUser({uinf: [payload]});
        if (originUser) {
          state.suggestUsers = {
            ...state.suggestUsers,
            ...userInfo,
          };
        }
      }
    },
    setCurrentPlayingVideo: (state, {payload}) => {
      state.currentPlayingVideo = payload;
    },
    updateVolume: (state, {payload}) => {
      state.volume = payload;
    },
    updateVIndex: (state, {payload}) => {
      state.vIndex = parseInt(payload) ?? 0;
    },
    updateFollowedVIndex: (state, {payload}) => {
      state.followedIndex = parseInt(payload) ?? 0;
    },
    updateStartIndex: (state, {payload}) => {
      state.startIndex = parseInt(payload) ?? 0;
    },
    setGTokScrollPosition: (state, {payload}) => {
      state.visionScrollPosition = payload;
    },
    updateFollowedStartIndex: (state, {payload}) => {
      state.followedStartIndex = parseInt(payload) ?? 0;
    },
    updateRecommendStartIndex: (state, {payload}) => {
      state.recommendStartIndex = parseInt(payload) ?? 0;
    },
    nextGTokIndex: (state) => {
      const isRecommmendMode = ![
        AppConsts.VISION_SOURCE_PROFILE,
        AppConsts.VISION_SOURCE_SEARCH,
        AppConsts.VISION_SOURCE_FOLLOWED,
      ].includes(state.source);
      // followed mode
      if (
        state.source === AppConsts.VISION_SOURCE_FOLLOWED &&
        state.followedIndex < state.followedData.length - 1
      ) {
        state.followedIndex += 1;
      } else if (
        // recommend mode
        isRecommmendMode &&
        state.recommendIndex < state.recommendData.length - 1
      ) {
        state.recommendIndex += 1;
      } else if (state.vIndex < state.data.length - 1) {
        // vision mode
        state.vIndex += 1;
      }
    },
    updateVidSeek: (state, {payload}) => {
      let dStatus = [...state.dataStatus];
      const vStatus = dStatus[state.vIndex];
      const newVStatus = {...vStatus, ...{seek: parseFloat(payload)}};

      dStatus[state.vIndex] = newVStatus;

      state.dataStatus = dStatus;
    },
    resetGTok: (state, _) => {
      state.data = [];
      state.vIndex = 0;
      state.offset = 0;
      state.cursor = 0;
      state.portV1Cursor = 0;
      // state.source = null;
      state.params = null;
      state.reachEnd = false;
      state.visionScrollPosition = [0, 0];
    },
    updateGTok: (state, {payload}) => {
      mutateVisionState(state, payload);
    },
    updateFollowingGTok: (state, {payload}) => {
      mutateVisionState(state, payload, "followedData");
    },
    updateMuted(state, {payload}) {
      state.muted = payload;
    },
    updateSeek(state, {payload}) {
      state.seek = payload;
    },
    updateSource(state, {payload}) {
      state.source = payload;
    },
    delGTok(state, {payload}) {
      let posts = state.data;
      const delGTokIndex = state.data.findIndex(
        (item) => item.id === payload || item._id === payload,
      );
      if (delGTokIndex > -1 && delGTokIndex <= state.vIndex) {
        state.vIndex = Math.max(state.vIndex - 1, 0);
      }
      posts = posts.filter(
        (post) => post.id !== payload && post._id !== payload,
      );
      state.data = posts;
    },
    delFollowedGTok(state, {payload}) {
      const delGTokIndex = state.followedData.findIndex(
        (item) => item.id === payload || item._id === payload,
      );
      if (delGTokIndex > -1 && delGTokIndex <= state.followedIndex) {
        state.followedIndex = Math.max(state.followedIndex - 1, 0);
      }
      let posts = state.followedData;
      posts = posts.filter(
        (post) => post.id !== payload && post._id !== payload,
      );
      state.followedData = posts;
    },
    // getUserById(state, {payload}) {
    // const {users} = state ?? {};

    // return users?.[payload] ?? null;
    // },
    updateChatInputFocus(state, {payload}) {
      state.chatInputFocus = payload;
    },

    // Recommend state parts
    resetRecommendGTok(state) {
      state.recommendData = [];
      state.recommendIndex = 0;
      state.recommendOffset = 0;
      state.recommendCursor = 0;
      state.source = null;
      state.params = null;
      state.recommendReachEnd = false;
      state.visionScrollPosition = [0, 0];
    },
    updateRecommendGTok(state, {payload}) {
      mutateRecommendGTokState(state, payload);
    },
    updateTrendingGTok(state, {payload}) {
      mutateTrendingVisionState(state, payload);
    },
    notInterestedGTok(state, {payload}) {
      state.recommendData = state.recommendData.filter(
        (item) => item.id !== payload && item._id !== payload,
      );
      state.followedData = state.followedData.filter(
        (item) => item.id !== payload && item._id !== payload,
      );
      state.data = state.data.filter(
        (item) => item.id !== payload && item._id !== payload,
      );
    },
    delRecommendGTok(state, {payload}) {
      let posts = state.recommendData;
      const delGTokIndex = state.recommendData.findIndex(
        (item) => item.id === payload || item._id === payload,
      );
      if (delGTokIndex > -1 && delGTokIndex <= state.recommendIndex) {
        state.recommendIndex = Math.max(state.recommendIndex - 1, 0);
      }
      posts = posts.filter(
        (post) => post.id !== payload && post._id !== payload,
      );
      state.recommendData = posts;
    },
    updateRecommendIndex(state, {payload}) {
      state.recommendIndex = parseInt(payload) ?? 0;
    },
    endSession(state, _) {
      visionSlice.caseReducers.resetGTok(state, {});
      // remove played vision videos
      // state.recommendData = state.recommendData.slice(state.recommendIndex + 1);
      state.recommendIndex = 0;
      state.recommendOffset = 0;
      state.recommendCursor = 0;
      // state.source = null;
      state.params = null;
    },
    // followed state parts
    resetFollowedGTok(state) {
      state.followedData = [];
      state.followedIndex = 0;
      visionSlice.caseReducers.resetGTok(state, {});
    },
    setSwitchAndCloseGTok(state, {payload}) {
      state.switchAndCloseVision = payload;
    },
  },
  extraReducers: (builder) => {
    // Doc: https://redux-toolkit.js.org/usage/usage-with-typescript#type-safety-with-extrareducers

    // builder.addCase(timelineFallback.fulfilled, (state, {payload}) => {
    //   mutateVisionState(state, payload);
    // });

    builder.addCase(changeGTok.fulfilled, (state, {payload}) => {
      if (!payload) return;
      const {err, response} = payload;
      if (err) return;
      const {data} = response || {};
      if (!data) return;
      const postId = data.id || data._id;
      if (!postId) return;
      const newCover = data.main;
      const updateGTokCover = (visionArr) => {
        if (visionArr) {
          visionArr.forEach((vision) => {
            if (vision.id === postId || vision._id === postId) {
              vision.main = newCover;
            }
          });
        }
      };
      updateGTokCover(state.data);
      updateGTokCover(state.recommendData);
      updateGTokCover(state.followedData);
    });

    builder.addCase(endGTokSessionAsync.pending, (state, {payload}) => {});
    builder.addCase(endGTokSessionAsync.rejected, (state, {payload}) => {});
    builder.addCase(endGTokSessionAsync.fulfilled, (state, {payload}) => {});

    // `getRecommend` case
    builder.addCase(getRecommend.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getRecommend.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getRecommend.fulfilled, (state, {payload}) => {
      mutateRecommendGTokState(state, payload);
    });

    // `getPost` case
    builder.addCase(getPost.fulfilled, (state, {payload}) => {
      if (state.source === AppConsts.VISION_SOURCE_FOLLOWED) {
        mutateVisionState(state, payload, "followedData");
      } else {
        mutateRecommendGTokState(state, payload, true);
        state.recommendIndex = 0;
      }
    });

    // `recommendFallback` case
    builder.addCase(recommendFallback.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(recommendFallback.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(recommendFallback.fulfilled, (state, {payload}) => {
      mutateRecommendGTokState(state, payload);
    });

    // `getSearch` case
    builder.addCase(getSearch.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getSearch.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSearch.fulfilled, (state, {payload}) => {
      mutateVisionState(state, payload);
    });

    // getProfileGTok case
    builder.addCase(getProfileGTok.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getProfileGTok.pending, (state) => {
      state.isLoading = true;
      state.source = AppConsts.VISION_SOURCE_PROFILE;
    });
    builder.addCase(getProfileGTok.fulfilled, (state, {payload}) => {
      mutateVisionState(state, payload);
    });

    // `getSearchGTok` case
    builder.addCase(getSearchGTok.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getSearchGTok.pending, (state) => {
      state.isLoading = true;
      state.source = AppConsts.VISION_SOURCE_SEARCH;
    });
    builder.addCase(getSearchGTok.fulfilled, (state, {payload}) => {
      mutateVisionState(state, payload);
    });

    // `getTimeline` case
    builder.addCase(getTimeline.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getTimeline.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTimeline.fulfilled, (state, {payload}) => {
      state.isLoading = false;
      if (payload?.posts?.length) {
        mutateVisionState(state, payload, "followedData");
      } else {
        state.reachEnd = true;
      }
    });
    // `getPopularUser` case
    builder.addCase(getSuggestUsersAsync.rejected, (state) => {
      state.isSuggestUsersLoading = false;
    });
    builder.addCase(getSuggestUsersAsync.pending, (state) => {
      state.isSuggestUsersLoading = true;
    });
    builder.addCase(getSuggestUsersAsync.fulfilled, (state, {payload}) => {
      state.isSuggestUsersLoading = false;
      if (payload?.data) {
        state.suggestUsers = payload.data;
      }
    });

    // `getTrendingGTok` case
    builder.addCase(getTrendingGTok.rejected, (state) => {
      state.isLoading = false;
      state.trendingCompleted = true;
    });
    builder.addCase(getTrendingGTok.pending, (state) => {
      state.isLoading = true;
      state.trendingCompleted = false;
    });
    builder.addCase(getTrendingGTok.fulfilled, (state, {payload}) => {
      state.trendingVisionData = payload;
      state.trendingCompleted = true;
    });
  },
});

function mutateVisionState(state, payload, stateListName = "data") {
  if (!state || !payload) {
    state.isLoading = false;
  } else {
    const {users, posts, offset, cursor, portV1Cursor, fallback, params} =
      payload || {};
    state[stateListName] = uniqBy(
      [...state[stateListName], ...(posts || [])],
      (post) => post?.id || post?._id,
    );

    if (users) {
      const prevUsers = state.users;
      state.users = {...prevUsers, ...users};
    }

    if (offset) {
      state.offset = offset;
    }

    if (cursor) {
      state.cursor = cursor;
    }

    // portV1Cursor is temporarily for search post api fallback logic
    if (portV1Cursor !== undefined) {
      state.portV1Cursor = portV1Cursor;
    }

    if (
      fallback &&
      ((cursor !== undefined && !cursor) ||
        (portV1Cursor !== undefined && !portV1Cursor))
    ) {
      state.reachEnd = true;
    } else if (
      ((cursor !== undefined && !cursor) ||
        (portV1Cursor !== undefined && !portV1Cursor)) &&
      (state.source === AppConsts.VISION_SOURCE_PROFILE ||
        state.source === AppConsts.VISION_SOURCE_SEARCH)
    ) {
      state.reachEnd = true;
    }

    if (params) {
      state.params = params;
    }
    state.isLoading = false;
  }
  // @workaround: We store and using post stats in `timeline/store`
  // if (postStats) {
  //   state.postStats = {
  //     ...state?.postStats,
  //     ...postStats,
  //   };
  // }
}

function mutateRecommendGTokState(state, payload, addBefore) {
  if (!state || !payload) {
    state.isLoading = false;
  } else {
    const {users, posts, offset, cursor, fallback, params} = payload || {};

    if (addBefore) {
      state.recommendData = uniqBy(
        [...(posts || []), ...state.recommendData],
        (post) => post?.id || post?._id,
      );
    } else {
      state.recommendData = uniqBy(
        [...state.recommendData, ...(posts || [])],
        (post) => post?.id || post?._id,
      );
    }

    if (users) {
      const prevUsers = state.users;
      state.users = {...prevUsers, ...users};
    }

    if (offset) {
      state.recommendOffset = offset;
    }

    if (cursor) {
      state.recommendCursor = cursor;
    }

    if (posts && posts.length === 0) {
      state.recommendReachEnd = true;
    }

    // if (fallback && !cursor) {
    //   state.recommendReachEnd = true;
    // } else if (
    //   !cursor &&
    //   (state.source === AppConsts.VISION_SOURCE_PROFILE ||
    //     state.source === AppConsts.VISION_SOURCE_SEARCH)
    // ) {
    //   state.recommendReachEnd = true;
    // }

    if (params) {
      state.params = params;
    }

    state.isLoading = false;
  }
}
function mutateTrendingVisionState(state, payload) {
  if (!state || !payload) {
    state.trendingVisionData = [];
    state.isLoading = false;
  } else {
    const {users, posts} = payload || {};

    state.trendingVisionData = uniqBy(
      [...(posts || [])],
      (post) => post?.id || post?._id,
    );

    if (users) {
      const prevUsers = state.users;
      state.users = {...prevUsers, ...users};
    }

    state.isLoading = false;
    state.trendingCompleted = true;
  }
}
